import React, { useState, useMemo } from "react";
import { fetcherWithToken } from "../../utils/fetcher";
import useSWR from "swr";
import useUser from "../../hooks/useUser";
import WizardReview from "../../components/data-connection-wizard/WizardReview";
import DashboardLayoutWizard from "../../components/DashboardLayoutWizard";
import { colors } from "../../values/colors";
import {
  PlacementContext,
  PlacementWizardHelper,
} from "../../helpers/PlacementWizardHelper";
import { Spin } from "antd";

const DataConnection = (props) => {
  const { token } = useUser();
  const { id } = props.match.params;
  const [, updator] = useState({});

  let { data: project } = useSWR(
    id ? [`/api/data-placements/editable/${id}`, token] : null,
    fetcherWithToken
  );

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const helper = new PlacementWizardHelper(
      () => updator({}),
      id,
      token,
      project
    );
    helper.fromProject(project);

    return helper;
  }, [project]);

  if (!project)
    return (
      <div
        style={{
          backgroundColor: "#f9faff",
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <PlacementContext.Provider value={helper}>
        <DashboardLayoutWizard
          project={project}
          isWizardReview={true}
          withBorder
          bodyColor={colors["grey_1"]}
        >
          <WizardReview project={project} />
        </DashboardLayoutWizard>
      </PlacementContext.Provider>
      <style>
        {`
          #root {
            background-color: white;
          }
        `}
      </style>
    </>
  );
};

export default DataConnection;
