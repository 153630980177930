import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsOrderedListEnglish,
  TermsAndConditionsOrderedListItemEnglish,
} from '../../styles/termsAndConditions';

const TerminationTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        T
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ERMINATION
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner flexWrap>
          <TermsAndConditionsListSection width="95%">
            <TermsAndConditionsParagraph>
              Each party may terminate the Agreement, by operation of law and
              with immediate effect, without prior notice being required and
              without judicial intervention, by the mere sending of a registered
              letter to the other party:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsOrderedListEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  in case the other party has requested a postponement of
                  payment, is declared bankrupt, files for bankruptcy or has an
                  involuntary petition in bankruptcy filed against it, has
                  suspended its payments or admits its inability to pay its
                  debts as they mature, has a receiver appointed over its
                  assets, has an unstable credit or is manifestly insolvent, to
                  the extent permitted by the applicable law;
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  in case of dissolution and/or liquidation of the other party’s
                  company;
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  in case the other party’s assets or a part of the other
                  party’s assets are executive and/or precautionary seized at
                  the request of a creditor, or in case of other executive or
                  precautionary measures against the assets of the said party;
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  in the event of proof or serious suspicion of fraud committed
                  by the other party.
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
          </TermsAndConditionsOrderedListEnglish>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Each party may terminate the Agreement if the other party commits
              a demonstrated material fault or material contractual shortcoming
              and fails to remedy such fault or shortcoming within a period of
              thirty (30) days after being notified by registered letter of
              default by the party invoking the fault or shortcoming.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner flexWrap>
          <TermsAndConditionsListSection width="95%">
            <TermsAndConditionsParagraph>
              Mobito shall also be entitled to terminate the Agreement by
              operation of law and with immediate effect, without prior notice
              being required and without judicial intervention, by the mere
              sending of a registered letter:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsOrderedListEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  in case of serious or repeated violation by the Client of the
                  terms set out in article 3 (Access right);
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  in case of a violation of the warranties as set out in article
                  4 (Warranties).
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
          </TermsAndConditionsOrderedListEnglish>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default TerminationTerms;
