import React from "react";
import { Typography } from "antd";
import { toast } from "react-toastify";

import { Flexcol, Flexrow } from "../../components/Flexbox";
import Notification from "../../components/Notification";
import CopyLinkSrc from "../../images/copy.svg";
import ShareSrc from "../../images/layer2.svg";

const { Text } = Typography;

const shareLink = () => {
  navigator.clipboard.writeText(window.location.href);
  toast.success(<Notification type="success" text="Copied to clipboard" />);
};

const sendMail = (listingName, req) => {
  let link =
    "mailto:?" +
    "&subject=" +
    encodeURIComponent(
      `Mobito Marketplace Data ${req ? "Request" : "Product"}: ${listingName}`
    ) +
    "&body=" +
    encodeURIComponent(`
    Hey,

    Check out this very interesting Data ${
      req ? "Request" : "Product"
    } that is listed in Mobito’s Data Marketplace:
    
    ${window.location.href}
    
    Feel free to contact Mobito directly if you have any questions at support@mobito.io
    
    Thanks,
    `);

  window.location.href = link;
};

const ShareListing = ({ listingName, req, row }) => (
  <Flexcol
    center_align
    id="share-listing"
    style={{ flexDirection: row && "row" }}
  >
    <Flexrow
      center_align
      style={{
        marginTop: row ? "0px" : "24px",
        marginRight: row && "40px",
        cursor: "pointer",
      }}
      onClick={shareLink}
    >
      <img
        src={CopyLinkSrc}
        style={{
          height: "18px",
          marginRight: "10px",
          color: "#182A33",
        }}
        alt="Copy link"
      />
      <Text style={{ color: "#182A33", fontSize: "14px" }}>Copy link</Text>
    </Flexrow>
    <Flexrow
      center_align
      style={{
        marginTop: row ? "0px" : "8px",
        cursor: "pointer",
      }}
      onClick={() => sendMail(listingName, req)}
    >
      <img
        src={ShareSrc}
        style={{
          height: "18px",
          marginRight: "10px",
          color: "#182A33",
        }}
        alt="Share via email"
      />
      <Text style={{ color: "#182A33", fontSize: "14px" }}>
        Share via email
      </Text>
    </Flexrow>
  </Flexcol>
);

export default ShareListing;
