import React, { useContext, useMemo, useState, useEffect } from "react";
import { Form, Input, Select, Upload, Typography, Modal, Button } from "antd";
import styled from "styled-components";

import TitleAndDesc from "../../wizards-components/TitleAndDesc";
import SubsectionTitle from "../../wizards-components/SubsectionTitle";
import { PublishedSectionTitle } from "../../wizards-components/SectionTitle";

import { strings } from "../values/strings";
import { DataProductContext } from "../../../helpers/DataProductWizardHelper";
import { SectionCardContainer } from "../../wizards-components/styled-components";
import { Flexrow, Flexcol } from "../../Flexbox";
import useCategories from "../../../hooks/useCategories";
import { getThumbnailURL } from "../../../iso-shared/utils/getThumbnailUrl";
import EditModeImgSrc from "../../../images/edit_mode.svg";

const { Text } = Typography;

const name_form_rules = [
  {
    required: true,
    message: "Please input project name!",
  },
];

const EditModeWrap = styled.div`
  border: ${(props) => props.editing && "3px solid #FF7F19"};
  position: ${(props) => props.editing && "relative"};
  padding: ${(props) => props.editing && "0px 32px 32px"};
  background-color: ${(props) => props.editing && "#fff"};
  z-index: ${(props) => props.editing && 2};
  margin: ${(props) => props.editing && "0 -42px -42px"};
`;

const EditModeInfo = styled.div`
  background-color: #fff;
  position: sticky;
  top: 20px;
  transform: ${(props) => props.toRight && `translateX(${props.toRight}px)`};
  width: 320px;
  padding: 20px;
  &:before {
    content: "";
    position: absolute;
    height: 0px;
    width: 0px;
    top: 15px;
    left: -22px;
    border-width: 15px;
    border-color: transparent white transparent transparent;
    border-style: solid;
  }
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #344ef380;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 1500px;
`;

const ProceedButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #344ef3;
  color: #fff;
  font-size: 14px;
  padding: 6px 12px;
  height: initial;
`;

const CancelButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #fff;
  color: #344ef3;
  font-size: 14px;
  padding: 6px 12px;
  height: initial;
`;

const PublishedBasicProductDetails = () => {
  const helper = useContext(DataProductContext);
  const form_fields = [{ name: ["project_name"], value: helper.name.value }];
  const { categories } = useCategories();
  const hide_edits = helper.for_review || helper.submitting;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [width, setWidth] = useState(false);

  const categories_options = useMemo(
    () =>
      categories.map((category) => {
        return {
          label: category.name,
          value: category.id,
        };
      }),
    [categories]
  );

  const imageUploadRequest = async (p) => {
    const data = new FormData();
    data.append("image", p.file);
    const url = URL.createObjectURL(p.file);
    helper.thumbnail.set({ url, data });
  };

  const cancelDetails = () => {
    helper.sections_map.details.cancelChanges();
  };

  const saveDetails = async () => {
    helper.sections_map.details.saveChanges();
  };

  const selected_category_option = categories_options.find(
    (e) => e.value == helper.category.value
  );

  const showModal = () => {
    if (helper.sections_map.details.edited()) setIsModalVisible(true);
    else cancelDetails();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    cancelDetails();
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let basicSection = document.getElementById(
        "basic-card-container"
      );
      setWidth(basicSection.clientWidth - 21);
    }
  }, []);

  return (
    <>
      <Modal
        title={
          <Text style={{ color: "#182429", fontSize: "21px" }}>
            Are you sure?
          </Text>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        zIndex={1000001}
        footer={[
          <ProceedButton key="submit" type="primary" onClick={handleOk}>
            No, continue
          </ProceedButton>,
          <CancelButton key="back" onClick={handleCancel}>
            Yes, cancel
          </CancelButton>,
        ]}
      >
        <Text style={{ color: "#64737f", fontSize: "14px" }}>
          Your changes are not saved. Are you sure you want to cancel?
        </Text>
      </Modal>
      {helper.sections_map.details.editing && <Overlay />}
      <SectionCardContainer id="basic-card-container">
        <Form fields={form_fields}>
          <TitleAndDesc
            title="Required details"
            desc={strings.basic_section_desc}
          />
          <EditModeWrap editing={helper.sections_map.details.editing}>
            {helper.sections_map.details.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              title="Basic details"
              saving={helper.sections_map.details.saving}
              tooltip={strings.basic_title_tooltip}
              editing={helper.sections_map.details.editing}
              startEditing={() => helper.sections_map.details.setEditing()}
              saveChanges={saveDetails}
              hideEdit={hide_edits}
              edited={helper.sections_map.details.edited()}
              handleCancel={showModal}
            />
            {helper.sections_map.details.editing ? (
              <>
                <SubsectionTitle tooltip="Short name of the Listing">
                  Name
                </SubsectionTitle>
                <Form.Item name="project_name" rules={name_form_rules}>
                  <Input
                    onChange={(e) => helper.name.set(e.target.value)}
                    placeholder="Enter listing's name"
                  />
                </Form.Item>
                <Flexrow top="16px" />
                <SubsectionTitle tooltip={strings.data_cat_tooltip}>
                  Data category
                </SubsectionTitle>
                <Select
                  options={categories_options}
                  value={
                    categories_options.length ? helper.category.value : null
                  }
                  onChange={(v) => helper.category.set(v)}
                  style={{ width: "100%" }}
                />
                <Flexrow top="16px" />
                <SubsectionTitle title="Image" tooltip={strings.img_tooltip}>
                  Image
                </SubsectionTitle>
                <Upload
                  customRequest={imageUploadRequest}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                >
                  <img
                    src={getThumbnailURL(helper.thumbnail.value.url)}
                    alt="avatar"
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      padding: "12px",
                    }}
                  />
                </Upload>
              </>
            ) : (
              <>
                <SubsectionTitle tooltip="Short name of the Listing">
                  Name
                </SubsectionTitle>
                <Text>{helper.name.value}</Text>
                <Flexrow top="16px" />
                <SubsectionTitle tooltip={strings.data_cat_tooltip}>
                  Data category
                </SubsectionTitle>
                <Text>
                  {selected_category_option && selected_category_option.label}
                </Text>
                <Flexrow top="16px" />
                <SubsectionTitle title="Image" tooltip={strings.img_tooltip}>
                  Image
                </SubsectionTitle>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  disabled
                >
                  <img
                    src={getThumbnailURL(helper.thumbnail.value.url)}
                    alt="avatar"
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      padding: "12px",
                    }}
                  />
                </Upload>
              </>
            )}
          </EditModeWrap>
        </Form>
      </SectionCardContainer>
    </>
  );
};

export default PublishedBasicProductDetails;
