import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, Button, Modal, Tabs, Input, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import axios from 'axios';

import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';
import { QuestionCircleFill } from '@styled-icons/bootstrap/QuestionCircleFill';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';

import Notification from './Notification';
import { DataProductContext } from '../helpers/DataProductWizardHelper';
import { DataRequestContext } from '../helpers/DataRequestWizardHelper';
import { AccountContext } from '../hooks/AccountContext';
import { DeclareInterestModal } from '../page-components/ProductListing/DeclareInterestModal';
import { LoginRedirector } from '../services/LoginRedirector';
import { getThumbnailURL } from '../iso-shared/utils/getThumbnailUrl';
import { Flexcol, Flexrow } from './Flexbox';
import useCategories from '../hooks/useCategories';
import { AppContext } from '../hooks/AppContextProvider';

const { Text } = Typography;
const { TabPane } = Tabs;

const StickyWrap = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  height: ${(props) => (props.show ? '60px' : '0px')};
  transition: all 0.3s ease-in-out;
  background-color: ${(props) => (props.show ? '#fff' : 'transparent')};
  width: 100%;
  padding: ${(props) => (props.show ? '0px 150px' : '0px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 4px 12px 0px #182a330f;
`;

const CancelButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background: #344ef3 !important;
    border: 1px solid #344ef3 !important;
    color: #fff !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    background-color: #fff !important;
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    cursor: not-allowed;
  }
`;

export const ProductStickyHeader = (props) => {
  const { show, name, setReload, reload } = props;
  const helper = useContext(DataProductContext);
  const context = useContext(AppContext);
  const [declared_interest, setDeclaredInterest] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTab, setModalTab] = useState('1');
  const [consumptionName, setConsumptionName] = useState('');
  const show_light = !helper.previewing && helper.published_step == 2;
  const { account } = useContext(AccountContext) || {};
  const is_logged_in = account || null;
  const history = useHistory();
  const interest_message = useMemo(() => ({ message: '' }));
  const { categories } = useCategories();
  const category = categories.find((e) => e.id === helper.category.value);
  const imageUrl = getThumbnailURL(helper.thumbnail.value.url);
  const token = Cookies.get('jwt');
  const [accessRequired, setAccessRequired] = useState(false);
  const is_s3_data = helper.placement_type === 's3_data';

  useEffect(() => {
    context.setDashboardProps({
      ...context.dashboard_props,
      accessRequired: false,
    });
  }, []);

  useEffect(() => {
    if (!is_logged_in || (!show_light && !is_s3_data)) {
      return;
    }
    const fetch = async () => {
      const res = await axios.get(
        `/api/get-interest?placement_id=${helper.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setDeclaredInterest(res.data);
    };
    fetch();
  }, [is_logged_in, reload]);

  const onDeclareInterest = async () => {
    const body = {
      placement_id: helper.id,
      message: interest_message.message,
    };
    if (!is_logged_in) {
      history.push(`/data-consumption-wizard?selected_listing=${helper.id}`);
    } else {
      try {
        await axios.post(
          `/api/${
            is_s3_data
              ? `access-requests/project/${props.dataProductId}`
              : 'create-interest'
          }`,
          !is_s3_data && body,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        toast.success(<Notification type="success" text="Request sent" />);
        setDeclaredInterest(true);
        setReload(!reload);
      } catch (error) {
        console.error(error);
        toast.error(
          <Notification type="error" text={error.response.data.message} />,
        );
      }
    }
  };

  const onDeclareInterestModal = () => {
    if (is_logged_in) {
      interest_message.message = '';
      Modal.confirm({
        title: 'Declare interest',
        icon: null,
        content: (
          <DeclareInterestModal
            onMessage={(v) => (interest_message.message = v)}
          />
        ),
        okText: 'Send',
        cancelText: 'Cancel',
        width: 800,
        closable: true,
        centered: true,
        bodyStyle: { height: '400px' },
        onOk: onDeclareInterest,
      });
    } else {
      LoginRedirector.toLogin(history);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onAccessCard = () => {
    if (is_logged_in) {
      setIsModalVisible(true);
    } else {
      LoginRedirector.toLogin(history);
    }
  };

  const onAccessRequest = async () => {
    if (consumptionName === '') {
      toast.error(
        <Notification
          type="error"
          text="Consumption project name is required"
        />,
      );
      return;
    }

    try {
      await axios.post(
        `/api/data-consumptions`,
        {
          category: category && category.name,
          listing_ids: [helper.id],
          listings: [],
          name: consumptionName,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success(
        <Notification
          type="success"
          text={
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#fff',
              }}
            >
              Your request has been sent and added to your{' '}
              <Text
                onClick={() =>
                  history.push({ pathname: `/dashboard`, state: { key: '2' } })
                }
                style={{
                  textDecoration: 'underline',
                  color: '#344ef3',
                  cursor: 'pointer',
                }}
              >
                dashboard.
              </Text>
            </Text>
          }
        />,
        {
          autoClose: 12000,
          closeOnClick: true,
          closeButton: true,
        },
      );
      setIsModalVisible(false);
      setAccessRequired(true);
      context.setDashboardProps({
        ...context.dashboard_props,
        accessRequired: true,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Could not create consumption project"
        />,
      );
    }
  };

  useEffect(() => {
    if (context?.dashboard_props?.accessRequired) {
      setAccessRequired(context.dashboard_props?.accessRequired);
    } else {
      setAccessRequired(false);
    }
  }, [context?.dashboard_props?.accessRequired]);

  return (
    <StickyWrap show={show}>
      <Modal
        title={
          <Text
            style={{
              color: '#182429',
              fontSize: '18px',
              fontWeight: '600',
              margin: '10px 0px',
              display: 'block',
            }}
          >
            {modalTab === '1' ? (
              'Before you proceed...'
            ) : (
              <Flexrow center_align>
                <ArrowBack
                  style={{
                    color: '#182429',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalTab('1')}
                />
                <Text style={{ marginLeft: '30px' }}>
                  What is a consumption project?
                </Text>
              </Flexrow>
            )}
          </Text>
        }
        visible={isModalVisible}
        onOk={onAccessRequest}
        onCancel={handleCancel}
        width={840}
        bodyStyle={{ height: '250px' }}
        footer={
          modalTab === '1'
            ? [
                <CancelButton key="back" onClick={handleCancel}>
                  Cancel
                </CancelButton>,
                <SubmitButton
                  key="submit"
                  onClick={onAccessRequest}
                  disabled={!consumptionName}
                >
                  OK, send request
                </SubmitButton>,
              ]
            : [
                <CancelButton key="back" onClick={() => setModalTab('1')}>
                  Back
                </CancelButton>,
              ]
        }
      >
        <Flexcol>
          <Tabs
            defaultActiveKey="1"
            activeKey={modalTab}
            tabBarStyle={{ display: 'none' }}
          >
            <TabPane key="1">
              <Text style={{ color: '#64737F', fontSize: '16px' }}>
                In order to request access, you need first to create a
                consumption project. You can manage your project later on at
                your dashboard.
              </Text>
              <Flexcol top="15px" />
              <Flexrow center_align>
                <QuestionCircleFill
                  style={{
                    color: '#344EF3',
                    height: '16px',
                  }}
                />
                <Text
                  style={{
                    marginLeft: '8px',
                    color: '#344EF3',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalTab('2')}
                >
                  What is a consumption project?
                </Text>
              </Flexrow>
              <Flexcol top="45px" />
              <Flexrow center_align>
                <Tooltip
                  title="Give a short (internal) name to your project. The name will not be shared with anyone outside your organisation."
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                >
                  <InfoCircle
                    style={{
                      color: '#182429',
                      height: '16px',
                    }}
                  />
                </Tooltip>
                <Text
                  style={{
                    marginLeft: '8px',
                    color: '#182429',
                    fontSize: '15px',
                  }}
                >
                  Consumption project name
                </Text>
              </Flexrow>
              <Flexcol top="10px" />
              <Input
                onChange={(e) => setConsumptionName(e.target.value)}
                value={consumptionName}
                placeholder="e.g. Engineering team data consumptions for vehicle trip simulations"
              />
              <Flexcol top="25px" />
            </TabPane>
            <TabPane key="2">
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '16px',
                  width: '85%',
                  display: 'block',
                  marginBottom: '25px',
                }}
              >
                Creating a data consumption project allows you to group together
                the consumption of various data products (e.g. per use case or
                per internal department).
              </Text>
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '16px',
                  width: '85%',
                  display: 'block',
                }}
              >
                This will allow you to manage your consumptions in an easier
                way.
              </Text>
            </TabPane>
          </Tabs>
        </Flexcol>
      </Modal>
      {show && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={imageUrl}
              alt="Mobito"
              style={{
                width: '46px',
                marginRight: '24px',
              }}
            />
            <Text
              style={{ color: '#182429', fontSize: '20px', fontWeight: '600' }}
            >
              {name}
            </Text>
          </div>
          {!helper.previewing &&
            !show_light &&
            !is_s3_data &&
            (!accessRequired ? (
              <Button
                onClick={onAccessCard}
                style={{
                  backgroundColor: '#ff7f19',
                  color: 'white',
                  fontSize: '15px',
                }}
                size="large"
                id="request-access-btn"
              >
                Request Access
              </Button>
            ) : (
              <Text
                style={{
                  fontSize: '22px',
                  fontWeight: 'bold',
                  color: '#7E7E7E',
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                Request sent
              </Text>
            ))}
          {((!helper.previewing && is_s3_data && !declared_interest) ||
            (show_light && !declared_interest)) && (
            <Button
              onClick={onDeclareInterestModal}
              style={{
                backgroundColor: '#ff7f19',
                color: 'white',
                fontSize: '15px',
              }}
              size="large"
            >
              Declare interest
            </Button>
          )}
          {declared_interest && (
            <Text style={{ textAlign: 'center', fontSize: '14px' }}>
              You have declared interest <br /> for this listing
            </Text>
          )}
        </>
      )}
    </StickyWrap>
  );
};

export const RequestStickyHeader = (props) => {
  const { show, name } = props;
  const helper = useContext(DataRequestContext);
  const [declared_interest, setDeclaredInterest] = useState(false);
  const { account } = useContext(AccountContext) || {};
  const is_logged_in = account || null;
  const history = useHistory();
  const interest_message = useMemo(() => ({ message: '' }));
  const token = Cookies.get('jwt');

  useEffect(() => {
    if (!is_logged_in) {
      return;
    }
    const fetch = async () => {
      const res = await axios.get(
        `/api/get-request-interest?data_request_id=${helper.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setDeclaredInterest(res.data);
    };
    fetch();
  }, [is_logged_in]);

  const onDeclareInterest = async () => {
    if (!is_logged_in) {
      history.push(`/data-consumption-wizard?selected_listing=${helper.id}`);
    } else {
      try {
        await axios.post(
          `/api/create-interest`,
          {
            placement_id: helper.id,
            message: interest_message.message,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        toast.success(<Notification type="success" text="Request sent" />);
        setDeclaredInterest(true);
      } catch (error) {
        console.error(error);
        toast.error(
          <Notification
            type="error"
            text="Could not send request, please try again later"
          />,
        );
      }
    }
  };

  const onDeclareInterestModal = () => {
    if (is_logged_in) {
      interest_message.message = '';
      Modal.confirm({
        title: 'Declare interest',
        icon: null,
        content: (
          <DeclareInterestModal
            onMessage={(v) => (interest_message.message = v)}
          />
        ),
        okText: 'Send',
        cancelText: 'Cancel',
        width: 800,
        closable: true,
        centered: true,
        bodyStyle: { height: '400px' },
        onOk: onDeclareInterest,
      });
    } else {
      LoginRedirector.toLogin(history);
    }
  };

  return (
    <StickyWrap show={show}>
      {show && (
        <>
          <Text
            style={{ color: '#182429', fontSize: '20px', fontWeight: '600' }}
          >
            {name}
          </Text>
          {!helper.previewing && !declared_interest && (
            <Button
              onClick={onDeclareInterestModal}
              style={{
                backgroundColor: '#ff7f19',
                color: 'white',
                fontSize: '15px',
              }}
              size="large"
            >
              Declare Data Availability
            </Button>
          )}
          {declared_interest && (
            <Text style={{ textAlign: 'center', fontSize: '14px' }}>
              You have declared data availability <br /> for this data request
            </Text>
          )}
        </>
      )}
    </StickyWrap>
  );
};
