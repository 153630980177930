import Cookies from 'js-cookie';
import { useEffect, useState, createContext } from 'react';

import instance from '../services/TokenValidator';

export const AccountContext = createContext();
const deploymentDomain =
  process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

const getTokenDuration = async (token, setToken) => {
  if (!token) {
    return null;
  }

  try {
    const exp = JSON.parse(window.atob(token.split('.')[1])).exp * 1000;

    if (isNaN(exp)) {
      return null;
    }

    const refreshToken = async () => {
      const res = await instance.post(
        `${process.env.RAZZLE_ACCOUNT_URL}/auth/refresh`,
        {
          refreshToken: Cookies.get('refreshToken'),
        },
      );

      if (res.status === 200) {
        Cookies.set('jwt', res.data.jwt, { domain: deploymentDomain });
        setToken(res.data.jwt);
        return;
      }

      if (res.status === 401 || res.status === 404) {
        setToken(null);
        Cookies.remove('jwt', { domain: deploymentDomain });
        Cookies.remove('user_id', { domain: deploymentDomain });
        Cookies.remove('email', { domain: deploymentDomain });
        Cookies.remove('organization_id', { domain: deploymentDomain });
        Cookies.remove('firstname', { domain: deploymentDomain });
        Cookies.remove('lastname', { domain: deploymentDomain });
        Cookies.remove('role', { domain: deploymentDomain });
        Cookies.remove('account', { domain: deploymentDomain });
        Cookies.remove('primaryContact', { domain: deploymentDomain });
        Cookies.remove('primeEmail', { domain: deploymentDomain });
        Cookies.remove('companyName', { domain: deploymentDomain });
        return null;
      }
    };

    const now = Date.now();

    return now < exp ? exp - now : refreshToken();
  } catch (error) {}
  return null;
};

export const AccountProvider = (props) => {
  const [account, setAccount] = useState(null);
  const [isComplete, setIsComplete] = useState({
    personal: true,
    company: true,
  });
  const [token, setToken] = useState(Cookies.get('jwt'));
  const [primeContact, setPrimeContact] = useState(null);
  const MINUTE_MS = 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      getTokenDuration(token, setToken);
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(async () => {
    setAccount(Cookies.get('account') && JSON.parse(Cookies.get('account')));

    let canceled = false;

    if (!canceled && account) {
      if (!Cookies.get('primaryContact') && Cookies.get('account')) {
        try {
          const res = await instance.get(
            `${process.env.RAZZLE_ACCOUNT_URL}/organizations/primary-contact`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          if (res) {
            Cookies.set('primaryContact', res.data.id, {
              domain: deploymentDomain,
            });
            Cookies.set('primeEmail', res.data.email, {
              domain: deploymentDomain,
            });
            setPrimeContact(res.data);
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (
        !account.user.businessRole ||
        !account.user.firstname ||
        !account.user.lastname
      )
        setIsComplete((prevState) => ({ ...prevState, personal: false }));

      if (
        !account.organization.address ||
        !account.organization.country ||
        !account.organization.name ||
        !account.organization.vat
      )
        setIsComplete((prevState) => ({ ...prevState, company: false }));
    }

    return () => {
      canceled = true;
    };
  }, [token]);

  return (
    <AccountContext.Provider
      value={{
        account: token ? account : null,
        setToken,
        token,
        setAccount,
        isComplete,
        setIsComplete,
        primeContact,
        setPrimeContact,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
