import React, { useState, useEffect } from 'react';
import { Steps, Typography } from 'antd';

import DatasetDetails from './dataset-details/DatasetDetails';
import { Flexrow, Flexcol } from '../Flexbox';
import {
  StepperHeaderWrap,
  StepperContentWrap,
  StepperButtonsWrap,
  StepperButton,
} from './assets/styled-components';
import { strings } from './assets/strings';
import { user_types } from '../../iso-shared/values/global';
import { CreateRequest } from './create-requests/CreateRequest';

const { Text } = Typography;

const { Step } = Steps;

const steps = (userType) => {
  if (userType === user_types.CONSUMER) {
    return [
      {
        title: strings.consumer_steps_strings.step_one,
        content: (
          <DatasetDetails title={strings.consumer_steps_strings.step_one} />
        ),
      },
      {
        title: strings.consumer_steps_strings.step_two,
        content: (
          <DatasetDetails
            title={strings.consumer_steps_strings.step_two}
            selectable
            headline={strings.clarifications.consumer_title}
            description={strings.clarifications.consumer_description}
            userType={userType}
          />
        ),
      },
      {
        title: strings.consumer_steps_strings.step_three,
        content: (
          <CreateRequest
            title={strings.create_requests.title}
            headline={strings.create_requests.headline}
            description={strings.create_requests.description}
          />
        ),
      },
    ];
  }

  if (userType === user_types.PROVIDER) {
    return [
      {
        title: strings.provider_steps_strings.step_one,
        content: (
          <DatasetDetails
            title={strings.provider_steps_strings.step_one}
            selectable
            readOnly
            headline={strings.clarifications.provider_title}
            description={strings.clarifications.provider_description}
            userType={userType}
          />
        ),
      },
      {
        title: strings.provider_steps_strings.step_two,
        content: 'Pending...',
      },
    ];
  }
};

const Stepper = (props) => {
  const { userType } = props;
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const prev = () => {
    setCurrent(current - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <StepperHeaderWrap>
        <Steps current={current}>
          {steps(userType).map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </StepperHeaderWrap>
      <StepperContentWrap>
        {steps(userType)[current].content}
      </StepperContentWrap>
      <StepperButtonsWrap
        center_align
        justify={current === 0 ? 'end' : 'space-between'}
      >
        {current > 0 && (
          <Flexrow center_align>
            <Flexcol space_between>
              <Text
                style={{ color: '#757575', marginBottom: 1, fontSize: '12px' }}
              >
                {strings.steps_strings.previous_step}
              </Text>
              <Text
                style={{ color: '#182a33', marginBottom: 1, fontSize: '12px' }}
              >
                {steps(userType)[current - 1].title}
              </Text>
            </Flexcol>
            <StepperButton onClick={() => prev()}>
              {strings.steps_strings.previous}
            </StepperButton>
          </Flexrow>
        )}
        {current < steps(userType).length - 1 && (
          <Flexrow center_align>
            <Flexcol space_between>
              <Text
                style={{ color: '#757575', marginBottom: 1, fontSize: '12px' }}
              >
                {strings.steps_strings.next_step}
              </Text>
              <Text
                style={{ color: '#182a33', marginBottom: 1, fontSize: '12px' }}
              >
                {steps(userType)[current + 1].title}
              </Text>
            </Flexcol>

            <StepperButton type="primary" onClick={() => next()}>
              {strings.steps_strings.next}
            </StepperButton>
          </Flexrow>
        )}
        {current === steps(userType).length - 1 && (
          <StepperButton type="primary" disabled>
            {strings.steps_strings.submit}
          </StepperButton>
        )}
      </StepperButtonsWrap>
    </>
  );
};

export default Stepper;
