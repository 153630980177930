import React, { useState, createRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Steps,
  Tooltip,
  InputNumber,
  Divider,
  Spin,
  Modal,
} from 'antd';

import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { Bulb } from '@styled-icons/ionicons-outline/Bulb';
import { Lock } from '@styled-icons/evil/Lock';
import { LoadingOutlined } from '@ant-design/icons';

import LicenseAndAgreement from './components/LicenseAndAgreement';
import RequestPaymentPlan from './components/RequestPaymentPlan';
import Payment from './components/Payment';
import Completion from './components/Completion';
import { Flexrow, Flexcol } from '../Flexbox';
import LayoutRow from '../LayoutRow';
import Step from './Step';
import StripeLogoSrc from '../../images/stripe_logo.svg';
import { strings } from './components/values/strings';
import MaskedRulesModal from './MaskedRulesModal';

const { Text } = Typography;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />
);

const ButtonsWrap = styled(Flexrow)`
  position: relative;
  width: 65%;
  margin: auto;
  justify-content: ${(props) => props.justify && props.justify};
  height: 60px;
  align-items: center;
`;

const StepperButton = styled.button`
  border-radius: 8px;
  border: ${(props) =>
    props.disabled || props.next ? 'none' : '1px solid #344ef3'};
  height: 41px;
  width: 89px;
  color: ${(props) =>
    props.disabled ? '#c1c2c2' : props.next ? '#fff' : '#344ef3'};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: ${(props) =>
    props.disabled ? '#f3f3f4 ' : props.next ? '#344ef3' : '#fff'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) =>
      props.disabled ? '#f3f3f4' : props.next ? '#0c28dd' : '#fff'};
    box-shadow: ${(props) => !props.disabled && '2px 4px 12px 0px #182a331f'};
  }
`;

const PayStepperButton = styled.button`
  border-radius: 8px;
  border: ${(props) => (props.disabled ? 'none' : '1px solid #5FDF3E')};
  height: 41px;
  width: 180px;
  color: ${(props) => (props.disabled ? '#c1c2c2' : '#fff')};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: ${(props) => (props.disabled ? '#f3f3f4 ' : '#5FDF3E')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) => (props.disabled ? '#f3f3f4' : '#5acf3c')};
    box-shadow: ${(props) => !props.disabled && '2px 4px 12px 0px #182a331f'};
  }
`;

const SelectedPlanWrap = styled(Flexrow)`
  background-color: #edeffb;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 22px 30px;
  margin-bottom: 30px;
`;

const Backdrop = styled.div`
  background-color: #344ef354;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1002;
`;

const toPrice = (a) => parseFloat(a.toFixed(2));

export function Checkout(props) {
  const { min_requests_purchased, price } = props.pricing_bundle;
  const [request, setRequest] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [activateLicense, setActivateLicense] = useState(false);
  const [current, setCurrent] = useState(0);
  const [minRequests, setMinRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [paymentResult, setPaymentResult] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const endofPageRef = createRef();
  const no_decimals = /\.(.*?[0]*)/g;
  const hasMaskedRules = !!Object.keys(props.savedRules).length;

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 200 < e.target.clientHeight;
    if (bottom) {
      setReachedBottom(true);
    } else {
      setReachedBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleLicense = () => {
    setActivateLicense((prevState) => !prevState);
  };

  useEffect(() => {
    if (min_requests_purchased) {
      setMinRequests(min_requests_purchased);
      setRequest(min_requests_purchased);
    } else {
      setMinRequests(null);
      setRequest(null);
    }
  }, [min_requests_purchased]);

  const updateRequests = (value) => {
    setRequest(value);
    setMinRequests(value);
    if (!value && !min_requests_purchased) {
      setError('Must be more than 0 and less than 999.999 requests');
    } else if (no_decimals.test(value)) {
      setError('No decimals allowed');
    } else if (value < 0) {
      setError('Only positive integer numbers should be accepted');
    } else if (!value || value < min_requests_purchased || value > 999999) {
      setError(
        'Must be more than the minimum purchase limit and less than 999.999 requests',
      );
    } else {
      setError(null);
    }
  };

  const handleClick = () => {
    endofPageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    setReachedBottom(false);
  };

  const handleOk = () => {
    props.setReqPayModalVisible(false);
  };

  const handleCancel = () => {
    props.setReqPayModalVisible(false);
    if (paymentResult === 'succeeded') {
      props.setApiKey(true);
    }
  };

  const steps = [
    hasMaskedRules && {
      id: 0,
      title: 'View data',
      content: (
        <MaskedRulesModal
          imageUrl={props.imageUrl}
          name={props.source.name}
          setVisible={props.setVisible}
          visible={props.visible}
          openCheckout={props.openCheckout}
          status={props.source.status_name}
          endpoints={props.endpoints}
          savedRules={props.savedRules}
          notModal
        />
      ),
    },
    {
      id: hasMaskedRules ? 1 : 0,
      title: 'Licence',
      content: (
        <LicenseAndAgreement
          license={props.source.license_type}
          handleScroll={handleScroll}
          reachedBottom={reachedBottom}
          activateLicense={activateLicense}
          handleClick={handleClick}
          handleLicense={handleLicense}
          endofPageRef={endofPageRef}
          setReachedBottom={setReachedBottom}
        />
      ),
      helperTitle: strings.step_one_title,
      helperDesc: strings.step_one_desc,
    },
    {
      id: hasMaskedRules ? 2 : 1,
      title: 'Plan',
      content: (
        <div style={{ position: 'relative', width: '100%' }}>
          <RequestPaymentPlan _package={props.pricing_bundle} />
          <SelectedPlanWrap center_align space_between>
            <Flexcol start_align>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '13px',
                  fontWeight: '600',
                }}
              >
                How many requests would you like to purchase?
              </Text>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '13px',
                }}
              >{`Minimum purchase: ${
                min_requests_purchased
                  ? min_requests_purchased + ' requests'
                  : 'No limit'
              }`}</Text>
            </Flexcol>
            <Flexrow style={{ width: '22%' }} space_between center_align>
              <InputNumber
                value={minRequests}
                onChange={updateRequests}
                autoFocus={true}
              />
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '13px',
                  fontWeight: '700',
                }}
              >
                requests
              </Text>
            </Flexrow>
            <Text
              style={{
                position: 'absolute',
                bottom: '35px',
                fontSize: '12px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 'fit-content',
                color: '#FD5F5D',
              }}
            >
              {error && error}
            </Text>
            <Divider
              type="vertical"
              style={{ height: '60px', borderLeft: '1px solid#64737f' }}
            />
            <Flexcol end_align right="30px">
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '13px',
                }}
              >
                Total cost:
              </Text>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '13px',
                }}
              >
                {request !== 0
                  ? (price * request).toLocaleString('el-GR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (price * min_requests_purchased).toLocaleString('el-GR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                €
              </Text>
            </Flexcol>
          </SelectedPlanWrap>
        </div>
      ),
      helperTitle: strings.step_two_per_request_title,
      helperDesc: strings.step_two_per_request_desc,
    },
    {
      id: hasMaskedRules ? 3 : 2,
      title: 'Payment',
      content: (
        <Payment
          sourceName={props.source.name ? props.source.name : 'No name'}
          packageName={props.pricing_bundle.name}
          minRequests={minRequests}
          totalCost={
            request !== 0
              ? toPrice(price * request)
              : toPrice(price * min_requests_purchased)
          }
          projectId={props.project_id}
          resourceConnectionId={props.source.resource_connection_id}
          request={request === 0 ? min_requests_purchased : request}
          setDisableButton={setDisableButton}
          submit={submit}
          next={next}
          setIsLoading={setIsLoading}
          setPaymentResult={setPaymentResult}
          setEmail={setEmail}
          requestPlan
        />
      ),
      helperTitle: strings.step_three_title,
      helperDesc: strings.step_three_desc,
    },
    {
      id: hasMaskedRules ? 4 : 3,
      title: 'Completion',
      content: (
        <Completion
          paymentResult={paymentResult && paymentResult}
          sourceName={props.source.name ? props.source.name : 'No name'}
          minRequests={minRequests}
          totalCost={
            request !== 0 ? price * request : price * min_requests_purchased
          }
          email={email && email}
          prev={prev}
          setSubmit={setSubmit}
          requestPlan
          closeModal={handleCancel}
          setApiKey={props.setApiKey}
        />
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={
          <Flexcol left="50px">
            <Flexrow center_align style={{ paddingTop: '10px' }}>
              <img
                style={{ marginRight: '10px' }}
                width="40"
                height="40"
                alt="mobility-module-image"
                src={props.imageUrl}
              />
              <Text style={{ color: '#192A33', fontSize: '18px' }}>
                {props.source.name ? props.source.name : 'No name'}
              </Text>
            </Flexrow>
            <Text
              style={{
                marginTop: '18px',
                color: '#192A33',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Payment process
            </Text>
          </Flexcol>
        }
        visible={props.reqPayModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        style={{ paddingBottom: '0px' }}
        bodyStyle={{
          backgroundColor: '#F9FAFF',
          padding: '0px',
          height: '70vh',
          overflowY: 'auto',
        }}
        width="100%"
        destroyOnClose={true}
        footer={[
          current !== (hasMaskedRules ? 4 : 3) && (
            <LayoutRow background="#fff">
              <ButtonsWrap justify={current === 0 ? 'end' : 'space-between'}>
                {current > 0 && (
                  <StepperButton onClick={() => prev()}>
                    <Flexrow center_align space_around>
                      <ArrowLeftShort style={{ height: '20px' }} /> Back
                    </Flexrow>
                  </StepperButton>
                )}
                {current === (hasMaskedRules ? 3 : 2) && (
                  <Flexrow
                    center_align
                    style={{ position: 'absolute', right: '200px' }}
                  >
                    <Lock style={{ height: '24px' }} />
                    <Text style={{ color: '#6C7377', fontSize: '9px' }}>
                      Secure payment process handled by
                    </Text>
                    <img src={StripeLogoSrc} style={{ marginLeft: '4px' }} />
                    <Text style={{ color: '#6C7377', fontSize: '9px' }}>
                      . Feel safe.
                    </Text>
                  </Flexrow>
                )}
                {current < steps.length - 1 && (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="top"
                    title={
                      current === (hasMaskedRules ? 1 : true) &&
                      !activateLicense
                        ? 'Please accept terms in order to proceed'
                        : null
                    }
                    zIndex={999999999}
                  >
                    <div>
                      {current === (hasMaskedRules ? 3 : 2) ? (
                        <PayStepperButton
                          disabled={!disableButton}
                          onClick={() => setSubmit(true)}
                        >
                          {isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <Flexrow center_align center_content>
                              <Lock style={{ height: '32px' }} /> Pay{' '}
                              {request !== 0
                                ? (price * request).toLocaleString('el-GR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : (
                                    price * min_requests_purchased
                                  ).toLocaleString('el-GR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}{' '}
                              €
                            </Flexrow>
                          )}
                        </PayStepperButton>
                      ) : (
                        <StepperButton
                          onClick={() => next()}
                          next
                          disabled={
                            (current === (hasMaskedRules ? 1 : 0) &&
                              !activateLicense) ||
                            (current === (hasMaskedRules ? 2 : 1) &&
                              (error || !minRequests))
                          }
                        >
                          <Flexrow center_align space_around>
                            Next <ArrowRightShort style={{ height: '20px' }} />
                          </Flexrow>
                        </StepperButton>
                      )}
                    </div>
                  </Tooltip>
                )}
              </ButtonsWrap>
            </LayoutRow>
          ),
        ]}
        zIndex={1000000}
      >
        {isLoading && <Backdrop />}
        <LayoutRow
          background={current === (hasMaskedRules ? 4 : 3) ? '#fff' : '#F9FAFF'}
        >
          <Flexrow style={{ width: '50%', margin: '20px auto' }}>
            <Steps current={current}>
              <Flexrow space_between center_align style={{ width: '100%' }}>
                {!hasMaskedRules
                  ? steps
                      .slice(1)
                      .map((item) => (
                        <Step
                          key={item.title}
                          title={item.title}
                          index={item.id + 1}
                          total={steps.length - 1}
                          current={current}
                          licenseValid={activateLicense ? 1 : null}
                          planValid={current >= 2 && 2}
                          paymentValid={paymentResult && 3}
                          completionValid={paymentResult === 'succeeded' && 4}
                        />
                      ))
                  : steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                        index={item.id + 1}
                        total={steps.length}
                        current={current}
                        maskedValid={
                          hasMaskedRules && (current >= 1 ? 1 : null)
                        }
                        licenseValid={
                          activateLicense ? (hasMaskedRules ? 2 : 1) : null
                        }
                        planValid={current >= 3 && (hasMaskedRules ? 3 : 2)}
                        paymentValid={paymentResult && (hasMaskedRules ? 4 : 3)}
                        completionValid={
                          paymentResult === 'succeeded' &&
                          (hasMaskedRules ? 5 : 4)
                        }
                      />
                    ))}
              </Flexrow>
            </Steps>
          </Flexrow>
          <Flexrow
            style={{
              margin: 'auto',
              width: current === 0 && hasMaskedRules ? '100%' : '65%',
            }}
          >
            {hasMaskedRules
              ? steps[current].content
              : steps[current + 1].content}
            {(hasMaskedRules
              ? steps[current].helperTitle
              : steps[current + 1].helperTitle) && (
              <Flexcol
                start_align
                style={{
                  position: 'absolute',
                  right: '30px',
                  width: '20%',
                }}
              >
                <Bulb
                  style={{
                    color: '#64737F',
                    height: '30px',
                    marginBottom: '12px',
                  }}
                />
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '13px',
                    fontWeight: '600',
                    marginBottom: '12px',
                  }}
                >
                  {hasMaskedRules
                    ? steps[current].helperTitle
                    : steps[current + 1].helperTitle}
                </Text>
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '13px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {hasMaskedRules
                    ? steps[current].helperDesc
                    : steps[current + 1].helperDesc}
                </Text>
              </Flexcol>
            )}
          </Flexrow>
        </LayoutRow>
      </Modal>
      <style>
        {`
        .ant-switch-checked { background-color: #5FDF3E; } 
        .ant-input-number-input { position: initial; transform: initial; top: initial; }
        `}
      </style>
    </div>
  );
}

export default Checkout;
