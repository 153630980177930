import React, {
  useContext,
  useRef,
  useState,
  Fragment,
  useEffect,
} from "react";
import {
  Form,
  Input,
  Switch,
  Tooltip,
  Row,
  Col,
  Select,
  Button,
  Typography,
  Modal,
} from "antd";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlusSquareFilled,
  MinusSquareOutlined,
} from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import { Editor } from "@tinymce/tinymce-react";
import styled from "styled-components";

import {
  SectionCardContainer,
  Container,
  Title,
  Desc,
  StepperButton,
} from "../../../wizards-components/styled-components";
import { PublishedSectionTitle } from "../../../wizards-components/SectionTitle";
import SubsectionTitle from "../../../wizards-components/SubsectionTitle";
import TitleAndDesc from "../../../wizards-components/TitleAndDesc";
import { strings } from "../../values/strings";
import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";
import { Flexrow, Flexcol } from "../../../Flexbox";
import { tinyMCEEditorAPIKey } from "../../../../values/tinyMCEEditorAPIKey";
import { tinyInitConfig } from "../../values/tinyInitConfig";
import { TreeTraversor } from "../../../../utils/TreeTraversor";
import { EndpointTreeSelect } from "../../components/EndpointTreeSelect";
import { getUnique } from "../../../../iso-shared/utils/getUnique";
import { PropertyTag } from "../../components/PropertyTag";
import {
  SampleBodyArea,
  PublishedSampleRequestQueryArea,
} from "../../../SampleBodyArea";
import { scrollStyles } from "../../../../styles/scrollStyles";
import EditModeImgSrc from "../../../../images/edit_mode.svg";

const { Text } = Typography;

const authOptions = [
  {
    label: "Bearer",
    value: "Bearer",
  },
  {
    label: "Basic",
    value: "Basic",
  },
  {
    label: "API-KEY",
    value: "API-KEY",
  },
  {
    label: "X-API-KEY",
    value: "X-API-KEY",
  },
  {
    label: "Query String",
    value: "QS",
  },
  {
    label: "Append param",
    value: "Appended-Param",
  },
];

const verbOptions = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
];

const LicenseContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  ${scrollStyles}
`;

const EditModeWrap = styled.div`
  border: ${(props) => props.editing && "3px solid #FF7F19"};
  position: ${(props) => props.editing && "relative"};
  padding: ${(props) => props.editing && "0px 32px 32px"};
  background-color: ${(props) => props.editing && "#fff"};
  z-index: ${(props) => props.editing && 2};
  margin: ${(props) => props.editing && `0 -42px ${props.marginBottom}`};
`;

const EditModeInfo = styled.div`
  background-color: #fff;
  position: sticky;
  top: 20px;
  transform: ${(props) => props.toRight && `translateX(${props.toRight}px)`};
  width: 320px;
  padding: 20px;
  &:before {
    content: "";
    position: absolute;
    height: 0px;
    width: 0px;
    top: 15px;
    left: -22px;
    border-width: 15px;
    border-color: transparent white transparent transparent;
    border-style: solid;
  }
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #344ef380;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 1500px;
`;

const ProceedButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #344ef3;
  color: #fff;
  font-size: 14px;
  padding: 6px 12px;
  height: initial;
`;

const CancelButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #fff;
  color: #344ef3;
  font-size: 14px;
  padding: 6px 12px;
  height: initial;
`;

const ProxyPublishedFullVersion = () => {
  const form_ref = useRef();
  const helper = useContext(DataProductContext);
  const isPublic = helper.api_key.value == null;
  const [usedTags, setUsedTags] = useState([]);
  const [pacakageToggle, setPackageToggle] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [width, setWidth] = useState(false);
  const packages = helper.packages.value;
  const hide_edits = helper.for_review || helper.submitting;
  const form_fields = [
    {
      name: ["domain"],
      value: helper.domain.value,
    },
    {
      name: ["documentation_link"],
      value: helper.api_documentation_link.value,
    },
    {
      name: ["username"],
      value: helper.api_key.value?.split(":")[0] || "",
    },
    {
      name: ["password"],
      value: helper.api_key.value?.split(":")[1] || "",
    },
    {
      name: ["api_key"],
      value: helper.api_key.value,
    },
    {
      name: [`${`endpoint_` + helper.endpoints.value.form_id}`],
      value: helper.endpoints.value.endpoint,
    },
    {
      name: ["price"],
      value: (packages && packages[0] && packages[0].price) || "",
    },
  ];

  packages.forEach((p) => {
    form_fields.push(
      {
        name: [`package_name_` + p.form_id],
        value: p.name,
      },
      {
        name: [`package_price_` + p.form_id],
        value: p.price,
      }
    );
  });

  helper.endpoints.value.forEach((e) => {
    form_fields.push({ name: [`endpoint_` + e.form_id], value: e.endpoint });
  });

  if (helper.api_key.value != null) {
    form_fields.push(
      { name: ["api_key"], value: helper.api_key.value },
      { name: ["username"], value: helper.api_key.value.split(":")[0] || "" },
      { name: ["password"], value: helper.api_key.value.split(":")[1] || "" }
    );
  }

  if (!packages.length) {
    packages.push({
      charge_type: "per_request",
      form_id: uuid(),
    });
  }

  const charge_type = packages[0].charge_type;

  const getChargeType = (type) => {
    let chargeType;

    if (type === "per_month") {
      return (chargeType = "Per package");
    } else if (type === "per_request") {
      return (chargeType = "Per request");
    } else {
      return (chargeType = "Free");
    }
  };

  const resetApiForms = () => {
    form_ref.current.setFieldsValue({
      username: "",
      password: "",
    });
  };

  const onSwitchChange = (checked) => {
    helper.api_key.set(checked ? null : "");
    helper.auth_method.set(checked ? null : "");

    resetApiForms();
  };

  const onChangeUsername = async (e) => {
    const value = e.target.value;
    if (value.indexOf(":") >= 0) {
      return;
    }
    let password = helper.api_key.value.split(":")[1] || "";
    helper.api_key.set(value + ":" + password);
    helper.auth_method.trigger();
  };

  const onChangePassword = async (e) => {
    const value = e.target.value;
    if (value.indexOf(":") >= 0) {
      return;
    }
    let username = helper.api_key.value.split(":")[0] || "";
    helper.api_key.set(username + ":" + value);
    helper.auth_method.trigger();
  };

  const onAuthMethodChange = (v) => {
    if (helper.auth_method.value == "Basic" || v == "Basic") {
      resetApiForms();
      helper.api_key.set("");
      helper.auth_method.trigger();
    }
    helper.auth_method.set(v);
  };

  const newEndpoint = () => ({
    form_id: uuid(),
    endpoint: "",
    sample_data: "",
    sample_request_query: "",
    show: false,
    http_method: "GET",
    description: "",
  });

  const showApiEndpoint = !!helper.endpoints.value.length;

  const hasEndpoint = () => {
    if (showApiEndpoint) {
      helper.endpoints.set([]);
    } else {
      helper.endpoints.set([newEndpoint()]);
    }
  };

  const showCodeEditor = (index) => {
    let endpointsOpen = helper.endpoints.value.filter(
      (endpoint) => endpoint.show !== false
    );

    if (endpointsOpen) {
      helper.endpoints.value[index].show = !helper.endpoints.value[index].show;
      endpointsOpen = endpointsOpen.map((endpoint) => (endpoint.show = false));
    } else {
      helper.endpoints.value[index].show = !helper.endpoints.value[index].show;
    }

    helper.refresh();
  };

  const setApplyingTags = (applying_tags, index) => {
    const list = helper.endpoints.value;
    list[index].applying_tags = applying_tags;
    if (applying_tags) {
      list[index].endpoint_tree = new TreeTraversor(
        JSON.parse(list[index].sample_data)
      );
      list[index].tag_props_unsaved = [];
      list[index].tags_unsaved = [];
    }
    helper.refresh();
  };

  const setUnsavedTagProps = (tag_props_unsaved, index) => {
    helper.endpoints.value[index].tag_props_unsaved = tag_props_unsaved;
    helper.refresh();
  };

  const setUnsavedTags = (tags_unsaved, index) => {
    helper.endpoints.value[index].tags_unsaved = tags_unsaved;
    helper.refresh();
  };

  const removeEndpointTag = (tagEl, index) => {
    const applied_tags = helper.endpoints.value[index].applied_tags;
    if (typeof tagEl == "string") {
      applied_tags.endpoint = applied_tags.endpoint.filter((e) => e != tagEl);
    } else {
      applied_tags.data = applied_tags.data.filter((e) => e != tagEl);
    }
    helper.endpoints.trigger();
  };

  const onChangeEndpoint = (e, index) => {
    let inputValue = e.target.value;
    if (inputValue && inputValue.match(/./)[0] === "/") {
      inputValue = inputValue.substring(1);
    }
    helper.endpoints.value[index].endpoint = inputValue;
    helper.endpoints.trigger();
  };

  const onChangeVerb = (e, index) => {
    helper.endpoints.value[index].http_method = e;
    helper.endpoints.trigger();
  };

  const onChangeDescription = (e, index) => {
    helper.endpoints.value[index].description = e.target.value;
    helper.endpoints.trigger();
  };

  const handleSampleRequestQueryChange = (e, index) => {
    const list = helper.endpoints.value;
    list[index].sample_request_query = e.target.value;
    helper.endpoints.trigger();
  };

  const handleSampleRequestBodyChange = (e, index) => {
    const list = helper.endpoints.value;
    list[index].sample_request_body = e.target.value;
    helper.endpoints.trigger();
  };

  const saveEndpointTags = (index) => {
    const list = helper.endpoints.value;
    const tags_unsaved = list[index].tags_unsaved;
    let tag_props_unsaved = list[index].tag_props_unsaved;
    const has_endpoint_tag = tag_props_unsaved.find((e) => e == "/");
    tag_props_unsaved = tag_props_unsaved.filter((e) => e != "/");
    const applied_tags = list[index].applied_tags || {
      endpoint: [],
      data: [],
    };
    for (let i = 0; i < tags_unsaved.length; i++) {
      const tag = tags_unsaved[i];
      if (has_endpoint_tag) {
        applied_tags.endpoint.push(tag);
      }
      tag_props_unsaved.forEach((prop) => {
        applied_tags.data.push({
          property: prop,
          tag,
        });
      });
    }
    applied_tags.endpoint = getUnique(applied_tags.endpoint);
    applied_tags.data = getUnique(
      applied_tags.data,
      (e) => e.property + "__" + e.tag
    );
    list[index].applied_tags = applied_tags;
    list[index].applying_tags = false;
    helper.endpoints.trigger();

    setUsedTags(getUnique([...tags_unsaved, ...usedTags]));
  };

  const handleSampleResponseChange = (e, index) => {
    const list = helper.endpoints.value;

    const sample_text = e.target.value;
    list[index].sample_data = sample_text;
    let can_apply_tags = false;
    try {
      const sample_json = JSON.parse(sample_text);
      if (TreeTraversor.canParse(sample_json)) {
        can_apply_tags = true;
      }
    } catch (error) {
      // do nothing
    }
    list[index].can_apply_tags = can_apply_tags;
    helper.endpoints.trigger();
  };

  const handleRemoveClick = (form_id) => {
    helper.endpoints.set(
      helper.endpoints.value.filter((e) => e.form_id != form_id)
    );
  };

  const handleAddClick = () => {
    helper.endpoints.set([...helper.endpoints.value, newEndpoint()]);
  };

  const onChangePrice = async (e, index) => {
    const price = e.target.value;
    packages[index].price = price;
    helper.packages.trigger();
  };

  const onChangeMinPurchase = async (e) => {
    const min_purchase = e.target.value;
    packages[0].min_requests_purchased = parseFloat(min_purchase);
    helper.packages.trigger();
  };

  const onChangeLimitsValue = (e, time, index) => {
    const limit = e.target.value;
    packages[index][time] = parseInt(limit);
    helper.packages.trigger();
  };

  const onChangePackageName = async (e, index) => {
    packages[index].name = e.target.value;
    helper.packages.trigger();
  };

  const onChangeMinPackagePurchase = async (e, index) => {
    packages[index].min_packages_purchased = parseInt(e.target.value);
    helper.packages.trigger();
  };

  const onChangeMonthlyAllowance = async (e, index) => {
    packages[index].requests = parseInt(e.target.value);
    helper.packages.trigger();
  };

  const handleAddPackageClick = async () => {
    packages.push({
      charge_type: "per_month",
      form_id: uuid(),
    });
    helper.packages.trigger();
  };

  const handleRemovePackageClick = async (form_id) => {
    helper.packages.set(packages.filter((e) => e.form_id != form_id));
  };

  const showPackageDetailsEditor = (index, boolValue) => {
    setPackageToggle((prevState) => {
      let packagesOpened = Object.values(prevState).filter(
        (_package) => _package === true
      );

      if (packagesOpened) {
        packagesOpened.map((_package) => (_package = false));

        return {
          packagesOpened,
          [index]: !boolValue,
        };
      } else {
        return {
          ...prevState,
          [index]: !boolValue,
        };
      }
    });
  };

  useEffect(() => {
    helper.setStepAccessed(3);
  }, []);

  const showModal = (helperValue) => {
    if (helperValue.edited()) setIsModalVisible(true);
    else helperValue.cancelChanges();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (helper.sections_map.apiDetails.editing) {
      helper.sections_map.apiDetails.cancelChanges();
    } else if (helper.sections_map.apiAuthentication.editing) {
      helper.sections_map.apiAuthentication.cancelChanges();
    } else if (helper.sections_map.endpoints.editing) {
      helper.sections_map.endpoints.cancelChanges();
    } else if (helper.sections_map.packages.editing) {
      helper.sections_map.packages.cancelChanges();
    } else if (helper.sections_map.license.editing) {
      helper.sections_map.license.cancelChanges();
    } else if (helper.sections_map.contact.editing) {
      helper.sections_map.contact.cancelChanges();
    }
  };

  const hide_price_view = (
    <Container flex align="center">
      <Tooltip
        title={strings.hide_price_in_public_tooltip}
        color="#1a337d"
        overlayInnerStyle={{
          boxShadow: "2px 4px 12px 0px #182a331f",
          fontSize: "13px",
          borderRadius: "4px",
        }}
        placement="bottom"
      >
        <InfoCircleOutlined />
      </Tooltip>
      {showApiEndpoint && (
        <Switch
          style={{ marginLeft: "8px" }}
          checked={helper.hide_pricing.value}
          onChange={(v) => helper.hide_pricing.set(v)}
        />
      )}

      {!showApiEndpoint && (
        <Switch style={{ marginLeft: "8px" }} onChange={hasEndpoint} />
      )}
      <Title size={14} margin={"0 0 0 8px"} weight="normal">
        Hide price in public api page
      </Title>
    </Container>
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      let fullProxySection = document.getElementById(
        "full-proxy-version-card-container"
      );
      setWidth(fullProxySection.clientWidth - 21);
    }
  }, []);

  return (
    <>
      <Modal
        title={
          <Text style={{ color: "#182429", fontSize: "21px" }}>
            Are you sure?
          </Text>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        zIndex={1000001}
        footer={[
          <ProceedButton key="submit" type="primary" onClick={handleOk}>
            No, continue
          </ProceedButton>,
          <CancelButton key="back" onClick={handleCancel}>
            Yes, cancel
          </CancelButton>,
        ]}
      >
        <Text style={{ color: "#64737f", fontSize: "14px" }}>
          Your changes are not saved. Are you sure you want to cancel?
        </Text>
      </Modal>
      {(helper.sections_map.apiDetails.editing ||
        helper.sections_map.apiAuthentication.editing ||
        helper.sections_map.endpoints.editing ||
        helper.sections_map.packages.editing ||
        helper.sections_map.license.editing ||
        helper.sections_map.contact.editing) && <Overlay />}
      <SectionCardContainer id="full-proxy-version-card-container">
        <Form ref={form_ref} fields={form_fields}>
          <TitleAndDesc title="Full version" desc={strings.full_version_desc} />
          <EditModeWrap
            editing={helper.sections_map.apiDetails.editing}
            marginBottom="-14px"
          >
            {helper.sections_map.apiDetails.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.apiDetails.saving}
              title="API details"
              tooltip={strings.api_details_tooltip}
              editing={helper.sections_map.apiDetails.editing}
              startEditing={() => helper.sections_map.apiDetails.setEditing()}
              saveChanges={() => helper.sections_map.apiDetails.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.apiDetails.edited()}
              handleCancel={() => showModal(helper.sections_map.apiDetails)}
            />
            {helper.sections_map.apiDetails.editing ? (
              <>
                <SubsectionTitle tooltip={strings.api_domain_tooltip}>
                  API domain
                </SubsectionTitle>
                <Text>{helper.domain.value}</Text>
                <Flexrow top="16px" />
                <SubsectionTitle
                  tooltip={strings.api_documentation_link_tooltip}
                >
                  API documentation link
                </SubsectionTitle>
                <Form.Item
                  name="documentation_link"
                  rules={[
                    {
                      required: true,
                      message: "Please input documentation link!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      helper.api_documentation_link.set(e.target.value)
                    }
                    placeholder="myapi.domain.com/docs"
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <SubsectionTitle tooltip={strings.api_domain_tooltip}>
                  API domain
                </SubsectionTitle>
                <Text>{helper.domain.value}</Text>
                <Flexrow top="16px" />
                <SubsectionTitle
                  tooltip={strings.api_documentation_link_tooltip}
                >
                  API documentation link
                </SubsectionTitle>
                <Text>{helper.api_documentation_link.value}</Text>
              </>
            )}
          </EditModeWrap>
          <Flexrow top="16px" />
          <EditModeWrap
            editing={helper.sections_map.apiAuthentication.editing}
            marginBottom="-14px"
          >
            {helper.sections_map.apiAuthentication.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.apiAuthentication.saving}
              title="Authentication"
              tooltip={strings.api_authentication_title_tooltip}
              editing={helper.sections_map.apiAuthentication.editing}
              startEditing={() =>
                helper.sections_map.apiAuthentication.setEditing()
              }
              saveChanges={() =>
                helper.sections_map.apiAuthentication.saveChanges()
              }
              hideEdit={hide_edits}
              edited={helper.sections_map.apiAuthentication.edited()}
              handleCancel={() =>
                showModal(helper.sections_map.apiAuthentication)
              }
            />
            {helper.sections_map.apiAuthentication.editing ? (
              <>
                <Flexrow top="16px" />
                <Container flex align="center">
                  <Tooltip
                    title={strings.public_api_tooltip}
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: "2px 4px 12px 0px #182a331f",
                      fontSize: "13px",
                      borderRadius: "4px",
                    }}
                    placement="bottom"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Switch
                    onChange={onSwitchChange}
                    checked={isPublic}
                    style={{ marginLeft: "8px" }}
                    disabled
                  />
                  <Title size={14} margin={"0 0 0 8px"} weight="normal">
                    Public API
                  </Title>
                </Container>
                <Flexrow top="16px" />
                {!isPublic && (
                  <Row>
                    <Col span={15}>
                      <SubsectionTitle tooltip={strings.api_key_tooltip}>
                        API Key
                      </SubsectionTitle>
                      <Row>
                        {helper.auth_method.value === "Basic" ? (
                          <>
                            <Col span={11}>
                              <Form.Item
                                name="username"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input username!",
                                  },
                                ]}
                              >
                                <Input
                                  onChange={onChangeUsername}
                                  placeholder="Username"
                                />
                              </Form.Item>
                            </Col>

                            <Col style={{ marginLeft: 4 }} span={11}>
                              <Form.Item
                                name="password"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input password!",
                                  },
                                ]}
                              >
                                <Input
                                  onChange={onChangePassword}
                                  placeholder="Password"
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <Col span={24}>
                            <Form.Item
                              name="api_key"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input API key value!",
                                },
                              ]}
                            >
                              <Input
                                onChange={(e) =>
                                  helper.api_key.set(e.target.value)
                                }
                                placeholder="abcdef12345"
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col span={8} offset={1}>
                      <SubsectionTitle tooltip={strings.method_tooltip}>
                        Method
                      </SubsectionTitle>
                      <Select
                        name="auth_method"
                        options={authOptions}
                        onChange={onAuthMethodChange}
                        placeholder="Select"
                        value={helper.auth_method.value || null}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                {!isPublic ? (
                  <>
                    <Container flex align="center">
                      <Tooltip
                        title={strings.public_api_tooltip}
                        color="#1a337d"
                        overlayInnerStyle={{
                          boxShadow: "2px 4px 12px 0px #182a331f",
                          fontSize: "13px",
                          borderRadius: "4px",
                        }}
                        placement="bottom"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                      <Title size={15} margin={"0 0 0 8px"} weight="normal">
                        Public API is not enabled.
                      </Title>
                    </Container>
                    <Flexrow top="16px" />
                    <SubsectionTitle tooltip={strings.api_key_tooltip}>
                      API Key
                    </SubsectionTitle>
                    <Text>{helper.api_key.value}</Text>
                    <Flexrow top="16px" />
                    <SubsectionTitle tooltip={strings.method_tooltip}>
                      Method
                    </SubsectionTitle>
                    <Text>{helper.auth_method.value}</Text>
                  </>
                ) : (
                  <Container flex align="center">
                    <Tooltip
                      title={strings.public_api_tooltip}
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: "2px 4px 12px 0px #182a331f",
                        fontSize: "13px",
                        borderRadius: "4px",
                      }}
                      placement="bottom"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Title size={15} margin={"0 0 0 8px"} weight="normal">
                      Public API is enabled.
                    </Title>
                  </Container>
                )}
              </>
            )}
          </EditModeWrap>
          <Flexrow top="16px" />
          <EditModeWrap
            editing={helper.sections_map.endpoints.editing}
            marginBottom="-14px"
          >
            {helper.sections_map.endpoints.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.endpoints.saving}
              title="API endpoints"
              tooltip={
                <Desc pre color="#fff">
                  {strings.api_endpoints_tooltip}
                </Desc>
              }
              editing={helper.sections_map.endpoints.editing}
              startEditing={() => helper.sections_map.endpoints.setEditing()}
              cancelEditing={() =>
                helper.sections_map.endpoints.cancelChanges()
              }
              saveChanges={() => helper.sections_map.endpoints.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.endpoints.edited()}
              handleCancel={() => showModal(helper.sections_map.endpoints)}
            />
            {helper.sections_map.endpoints.editing ? (
              <>
                <Flexrow top="16px" />
                <Container flex align="center">
                  <Tooltip
                    title={strings.public_api_tooltip}
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: "2px 4px 12px 0px #182a331f",
                      fontSize: "13px",
                      borderRadius: "4px",
                    }}
                    placement="bottom"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                  {showApiEndpoint && (
                    <Switch
                      style={{ marginLeft: "8px" }}
                      defaultChecked
                      onChange={hasEndpoint}
                      disabled
                    />
                  )}

                  {!showApiEndpoint && (
                    <Switch
                      style={{ marginLeft: "8px" }}
                      onChange={hasEndpoint}
                      disabled
                    />
                  )}
                  <Title size={14} margin={"0 0 0 8px"} weight="normal">
                    API with endpoints
                  </Title>
                </Container>
                <Flexrow top="16px" />
                {showApiEndpoint && (
                  <>
                    {helper.endpoints.value.map((item, index) => (
                      <Fragment key={item.form_id}>
                        <div
                          style={{
                            borderRadius: item.show ? "8px" : "4px",
                            border: `1px solid ${
                              item.show ? "#1d1d1f" : "#f7f7f8"
                            }`,
                            padding: "10px",
                            position: "relative",
                            background: item.show ? "#eaeaeb" : "#f7f7f8",
                          }}
                        >
                          <Row>
                            <Col span={16}>
                              <SubsectionTitle
                                tooltip={strings.api_endpoint_name_tooltip}
                              >
                                Endpoint name
                              </SubsectionTitle>
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                              {item.show ? (
                                <Title
                                  cursor="pointer"
                                  color="#182429"
                                  size={13}
                                  onClick={() => showCodeEditor(index)}
                                >
                                  Hide details{" "}
                                  <MinusSquareOutlined
                                    style={{ fontSize: "16px" }}
                                  />
                                </Title>
                              ) : (
                                <Title
                                  cursor="pointer"
                                  color="#344ef3"
                                  size={13}
                                  onClick={() => showCodeEditor(index)}
                                >
                                  Show details{" "}
                                  <PlusSquareFilled
                                    style={{ fontSize: "16px" }}
                                  />
                                </Title>
                              )}
                            </Col>
                            <Col span={17}>
                              <Form.Item
                                name={`${`endpoint_` + item.form_id}`}
                                style={{ marginBottom: "0px" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter endpoint",
                                  },
                                ]}
                              >
                                <Input
                                  onChange={(e) => onChangeEndpoint(e, index)}
                                  disabled={!!item.in_origin}
                                  placeholder="e.g. v1/data/"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6} offset={1}>
                              <Select
                                options={verbOptions}
                                disabled={!!item.in_origin}
                                name="resource_method"
                                placeholder="Http verb"
                                onChange={(e) => onChangeVerb(e, index)}
                                value={
                                  item.http_method
                                    ? item.http_method
                                    : "Select verb"
                                }
                                style={{ width: "100%" }}
                              />
                            </Col>
                          </Row>
                          {item.show && <Flexrow top="16px" />}
                          {item.show && (
                            <Row>
                              <Col span={24}>
                                <SubsectionTitle
                                  tooltip={
                                    strings.api_endpoint_short_desc_tooltip
                                  }
                                >
                                  Short description
                                </SubsectionTitle>
                              </Col>
                              <Col span={24}>
                                <Input
                                  name="resource_description"
                                  onChange={(e) =>
                                    onChangeDescription(e, index)
                                  }
                                  suffix={100 - (item.description?.length || 0)}
                                  maxLength={100}
                                  value={item.description}
                                  placeholder="e.g. This endpoint is about xxx"
                                />
                                <Flexrow top="16px" />
                              </Col>
                              <Col span={24}>
                                <SubsectionTitle
                                  tooltip={
                                    strings.api_endpoint_sample_request_tooltip
                                  }
                                >
                                  Sample request query
                                </SubsectionTitle>
                              </Col>
                              <Col span={24}>
                                <Input
                                  onChange={(e) =>
                                    handleSampleRequestQueryChange(e, index)
                                  }
                                  placeholder="e.g. ?page=6"
                                  value={item.sample_request_query}
                                  style={{
                                    backgroundColor: "#000",
                                    color: "#0098ff",
                                  }}
                                />
                                <Flexrow top="16px" />
                              </Col>
                              {item.http_method == "POST" && (
                                <>
                                  <Col span={24}>
                                    <SubsectionTitle
                                      tooltip={
                                        strings.api_endpoint_sample_request_body_tooltip
                                      }
                                    >
                                      Sample request body
                                    </SubsectionTitle>
                                  </Col>
                                  <Col span={24}>
                                    <SampleBodyArea
                                      onChange={(e) =>
                                        handleSampleRequestBodyChange(e, index)
                                      }
                                      placeholder="Place a sample json object"
                                      value={item.sample_request_body}
                                    />
                                    <Flexrow top="16px" />
                                  </Col>
                                </>
                              )}
                              <Col span={24}>
                                <SubsectionTitle
                                  tooltip={
                                    strings.api_endpoint_sample_response_tooltip
                                  }
                                >
                                  Sample response
                                </SubsectionTitle>
                              </Col>
                              <Col span={24}>
                                <SampleBodyArea
                                  disabled={item.applying_tags}
                                  onChange={(e) =>
                                    handleSampleResponseChange(e, index)
                                  }
                                  placeholder="Place a sample json object"
                                  value={item.sample_data}
                                />
                                <Flexrow top="16px" />
                              </Col>
                              <Col span={24}>
                                <SubsectionTitle
                                  tooltip={strings.api_endpoints_tags_tooltip}
                                >
                                  Tags
                                </SubsectionTitle>
                              </Col>
                              <Col span={24}>
                                <Tooltip
                                  title={
                                    !item.can_apply_tags &&
                                    "You need to insert a valid json file to apply tags"
                                  }
                                  color="#1a337d"
                                  overlayInnerStyle={{
                                    boxShadow: "2px 4px 12px 0px #182a331f",
                                    fontSize: "13px",
                                    borderRadius: "4px",
                                  }}
                                  placement="bottom"
                                >
                                  <Button
                                    style={{
                                      color: "#344ef3",
                                      fontSize: "13px",
                                      borderRadius: "4px",
                                      border: "1px solid #344ef3",
                                    }}
                                    onClick={() => setApplyingTags(true, index)}
                                    disabled={
                                      !item.can_apply_tags || item.applying_tags
                                    }
                                    block
                                  >
                                    Add Tag
                                  </Button>
                                </Tooltip>
                                <Flexrow top="16px" />
                              </Col>
                              {item.applying_tags && (
                                <>
                                  <Col span={24}>
                                    <Container flex align="center" bottom="16">
                                      <Tooltip
                                        title=""
                                        color="#1a337d"
                                        overlayInnerStyle={{
                                          boxShadow:
                                            "2px 4px 12px 0px #182a331f",
                                          fontSize: "13px",
                                          borderRadius: "4px",
                                        }}
                                        placement="bottom"
                                      >
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                      <Title
                                        color="#182429"
                                        size={12}
                                        weight={500}
                                        margin={"0 0 0 8px"}
                                      >
                                        Select endpoint properties
                                      </Title>
                                    </Container>
                                    <EndpointTreeSelect
                                      valuer={item.tag_props_unsaved}
                                      onChange={(value) =>
                                        setUnsavedTagProps(value, index)
                                      }
                                      traversor={item.endpoint_tree}
                                    />
                                    <Flexrow top="16px" />
                                  </Col>
                                  <Col span={24}>
                                    <Container flex align="center" bottom="16">
                                      <Tooltip
                                        title={strings.select_tags_tooltip}
                                        color="#1a337d"
                                        overlayInnerStyle={{
                                          boxShadow:
                                            "2px 4px 12px 0px #182a331f",
                                          fontSize: "13px",
                                          borderRadius: "4px",
                                        }}
                                        placement="bottom"
                                      >
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                      <Title
                                        color="#182429"
                                        size={12}
                                        weight={500}
                                        margin={"0 0 0 8px"}
                                      >
                                        Select tags
                                      </Title>
                                    </Container>
                                    <Select
                                      mode="tags"
                                      placeholder="Select applied tags"
                                      defaultValue={item.tags_unsaved}
                                      onChange={(v) => setUnsavedTags(v, index)}
                                      style={{ width: "100%" }}
                                    >
                                      {usedTags.map((e) => (
                                        <Option key={e}>{e}</Option>
                                      ))}
                                    </Select>
                                    <Flexrow top="16px" />
                                  </Col>
                                  <Col span={24}>
                                    <Row justify="center">
                                      <Col
                                        span={11}
                                        style={{ textAlign: "center" }}
                                      >
                                        <StepperButton
                                          disabled={
                                            !item.tag_props_unsaved.length ||
                                            !item.tags_unsaved.length
                                          }
                                          valid={
                                            item.tag_props_unsaved.length ||
                                            item.tags_unsaved.length
                                          }
                                          onClick={() =>
                                            saveEndpointTags(index)
                                          }
                                        >
                                          Add Tags
                                        </StepperButton>
                                        <Flexrow top="16px" />
                                      </Col>
                                      <Col
                                        span={11}
                                        style={{ textAlign: "center" }}
                                      >
                                        <StepperButton
                                          onClick={() =>
                                            setApplyingTags(false, index)
                                          }
                                          valid
                                        >
                                          Cancel
                                        </StepperButton>
                                        <Flexrow top="16px" />
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                              <Col span={6}>
                                <Title color="#182429" size={12} weight={500}>
                                  TAGS APPLIED:
                                </Title>
                              </Col>
                              <Col span={18}>
                                {item.applied_tags &&
                                  item.applied_tags.endpoint.map((e) => (
                                    <PropertyTag
                                      key={e}
                                      onClose={() =>
                                        removeEndpointTag(e, index)
                                      }
                                      tag={e}
                                      property="/"
                                    />
                                  ))}
                                {item.applied_tags &&
                                  item.applied_tags.data.map((e) => (
                                    <PropertyTag
                                      key={e.tag + e.property}
                                      onClose={() =>
                                        removeEndpointTag(e, index)
                                      }
                                      tag={e.tag}
                                      property={e.property}
                                    />
                                  ))}
                              </Col>
                            </Row>
                          )}
                          {!item.in_origin && (
                            <DeleteOutlined
                              onClick={() => handleRemoveClick(item.form_id)}
                              style={{
                                position: "absolute",
                                right: "-31px",
                                top: "10px",
                              }}
                            />
                          )}
                        </div>
                        <Flexrow top="16px" />
                      </Fragment>
                    ))}
                    <Flexrow top="16px" />
                    <Row>
                      <Col span={24}>
                        {showApiEndpoint && (
                          <Title
                            size={12}
                            color="#344ef3"
                            cursor="pointer"
                            onClick={() => handleAddClick()}
                          >
                            <PlusOutlined /> Add new endpoint
                          </Title>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <>
                {showApiEndpoint ? (
                  <>
                    <Container flex align="center">
                      <Tooltip
                        title={strings.public_api_tooltip}
                        color="#1a337d"
                        overlayInnerStyle={{
                          boxShadow: "2px 4px 12px 0px #182a331f",
                          fontSize: "13px",
                          borderRadius: "4px",
                        }}
                        placement="bottom"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                      <Title size={14} margin={"0 0 0 8px"} weight="normal">
                        API with endpoints is enabled.
                      </Title>
                    </Container>
                    <Flexrow top="16px" />
                    {helper.endpoints.value.map((item, index) => (
                      <Fragment key={item.form_id}>
                        <div
                          style={{
                            borderRadius: "4px",
                            border: "1px solid #6a6d6e",
                            padding: "10px",
                            position: "relative",
                            background: "#fff",
                          }}
                        >
                          <SubsectionTitle
                            tooltip={strings.api_endpoint_name_tooltip}
                          >
                            Endpoint name
                          </SubsectionTitle>
                          <Text>
                            {item.http_method}, {item.endpoint}
                          </Text>
                          <Flexrow top="16px" />
                          <SubsectionTitle
                            tooltip={strings.api_endpoint_short_desc_tooltip}
                          >
                            Short description
                          </SubsectionTitle>
                          <Text>{item.description}</Text>
                          <Flexrow top="16px" />
                          <SubsectionTitle
                            tooltip={
                              strings.api_endpoint_sample_request_tooltip
                            }
                          >
                            Sample request query
                          </SubsectionTitle>
                          {item.sample_request_query && (
                            <PublishedSampleRequestQueryArea>
                              <Text>{item.sample_request_query}</Text>
                            </PublishedSampleRequestQueryArea>
                          )}
                          <Flexrow top="16px" />
                          {item.http_method == "POST" && (
                            <>
                              <SubsectionTitle
                                tooltip={
                                  strings.api_endpoint_sample_request_body_tooltip
                                }
                              >
                                Sample request body
                              </SubsectionTitle>
                              {item.sample_request_body && (
                                <PublishedSampleRequestQueryArea>
                                  <Text>{item.sample_request_body}</Text>
                                </PublishedSampleRequestQueryArea>
                              )}
                              <Flexrow top="16px" />
                            </>
                          )}
                          <SubsectionTitle
                            tooltip={
                              strings.api_endpoint_sample_response_tooltip
                            }
                          >
                            Sample response
                          </SubsectionTitle>
                          {item.sample_data && (
                            <PublishedSampleRequestQueryArea>
                              {item.sample_data}
                            </PublishedSampleRequestQueryArea>
                          )}
                          <Flexrow top="16px" />
                          <SubsectionTitle
                            tooltip={strings.api_endpoints_tags_tooltip}
                          >
                            Tags
                          </SubsectionTitle>
                          <Row>
                            <Col span={6}>
                              <Title color="#182429" size={12} weight={600}>
                                TAGS APPLIED:
                              </Title>
                            </Col>
                            <Col span={18}>
                              {item.applied_tags &&
                                item.applied_tags.data.map((e, index) => (
                                  <>
                                    <Text style={{ fontWeight: "bold" }}>
                                      {e.tag.charAt(0).toUpperCase() +
                                        e.tag.slice(1) +
                                        " "}
                                    </Text>
                                    <Text>
                                      {e.property.charAt(0).toUpperCase() +
                                        e.property.slice(1) +
                                        (index + 1 !==
                                        item.applied_tags.data.length
                                          ? ", "
                                          : "")}
                                    </Text>
                                  </>
                                ))}
                            </Col>
                          </Row>
                        </div>
                        <Flexrow top="16px" />
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <Container flex align="center">
                    <Tooltip
                      title={strings.public_api_tooltip}
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: "2px 4px 12px 0px #182a331f",
                        fontSize: "13px",
                        borderRadius: "4px",
                      }}
                      placement="bottom"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <Title size={14} margin={"0 0 0 8px"} weight="normal">
                      API with endpoints is disabled.
                    </Title>
                  </Container>
                )}
              </>
            )}
          </EditModeWrap>
          <Flexrow top="16px" />
          <EditModeWrap
            editing={helper.sections_map.packages.editing}
            marginBottom="-32px"
          >
            {helper.sections_map.packages.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.packages.saving}
              title="Pricing"
              tooltip={strings.pricing_tooltip}
              editing={helper.sections_map.packages.editing}
              startEditing={() => helper.sections_map.packages.setEditing()}
              saveChanges={() => helper.sections_map.packages.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.packages.edited()}
              handleCancel={() => showModal(helper.sections_map.packages)}
            />
            {helper.sections_map.packages.editing ? (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.pricing_method_tooltip}
                    </Desc>
                  }
                >
                  Pricing method
                </SubsectionTitle>
                <Title color="#182429" size={15} display="block">
                  {getChargeType(charge_type)}
                </Title>
                <Flexrow top="16px" />
                {charge_type === "per_request" && (
                  <>
                    <Row justify="space-between">
                      <Col span={24}>
                        <Title color="#182429" size={15} weight="bold">
                          Pricing per request:
                        </Title>
                        <Flexrow top="16px" />
                      </Col>
                      <Col span={11}>
                        <SubsectionTitle
                          tooltip={strings.pricing_per_request_price_tooltip}
                        >
                          Price
                        </SubsectionTitle>
                      </Col>
                      <Col span={12} offset={1}>
                        <SubsectionTitle
                          tooltip={
                            strings.pricing_per_request_minimum_purchase_tooltip
                          }
                        >
                          Minimum purchase
                        </SubsectionTitle>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item
                          name="price"
                          rules={[
                            {
                              required: true,
                              message: "Please input price!",
                            },
                            {
                              pattern: /^[+]?([.]\d+|\d+[.]?\d*)$/,
                              message: "Price must be a positive number!",
                            },
                          ]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input
                            type="number"
                            onChange={(e) => onChangePrice(e, 0)}
                            placeholder="Set your price"
                            min={0}
                          />
                        </Form.Item>
                        <Flexrow top="16px" />
                      </Col>
                      <Col span={12} offset={1}>
                        <Input
                          name="min_purchase"
                          type="number"
                          onChange={onChangeMinPurchase}
                          placeholder="e.g. 100 requests"
                          value={packages[0].min_requests_purchased || ""}
                          min={0}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <SubsectionTitle
                        tooltip={strings.pricing_per_request_limits_tooltip}
                      >
                        Limits
                      </SubsectionTitle>
                    </Row>
                    <Row>
                      <Col span={10}>
                        <Input
                          name="limit_minute"
                          type="number"
                          onChange={(e) =>
                            onChangeLimitsValue(e, "limit_minute", 0)
                          }
                          placeholder="e.g. 10 requests"
                          value={packages[0].limit_minute || ""}
                          min={0}
                        />
                      </Col>
                      <Col span={9} offset={1}>
                        <Title color="#182a33" size={15}>
                          per minute
                        </Title>
                      </Col>
                    </Row>
                    <Flexrow top="16px" />
                    <Row>
                      <Col span={10}>
                        <Input
                          name="limit_hour"
                          type="number"
                          onChange={(e) =>
                            onChangeLimitsValue(e, "limit_hour", 0)
                          }
                          placeholder="e.g. 10 requests"
                          value={packages[0].limit_hour || ""}
                          min={0}
                        />
                      </Col>
                      <Col span={9} offset={1}>
                        <Title color="#182a33" size={15}>
                          per hour
                        </Title>
                      </Col>
                    </Row>
                    <Flexrow top="16px" />
                    <Row>
                      <Col span={10}>
                        <Input
                          name="limit_day"
                          type="number"
                          onChange={(e) =>
                            onChangeLimitsValue(e, "limit_day", 0)
                          }
                          placeholder="e.g. 10 requests"
                          value={packages[0].limit_day || ""}
                          min={0}
                        />
                      </Col>
                      <Col span={9} offset={1}>
                        <Title color="#182a33" size={15}>
                          per day
                        </Title>
                      </Col>
                    </Row>
                    <Flexrow top="16px" />
                    {charge_type === "per_month" && hide_price_view}
                  </>
                )}
                {charge_type === "per_month" && (
                  <>
                    <Row>
                      <Col span={24}>
                        <Title color="#182429" size={15} weight="bold">
                          Pricing per package:
                        </Title>
                        <Flexrow top="16px" />
                      </Col>
                    </Row>
                    {packages.map((item, index) => (
                      <Fragment key={item.form_id || "new-package"}>
                        <div
                          style={{
                            borderRadius: pacakageToggle[index] ? "8px" : "4px",
                            border: `1px solid ${
                              pacakageToggle[index] ? "#1d1d1f" : "#f7f7f8"
                            }`,
                            padding: "10px",
                            position: "relative",
                            background: pacakageToggle[index]
                              ? "#eaeaeb"
                              : "#f7f7f8",
                          }}
                        >
                          <Row>
                            <Col span={16}>
                              <SubsectionTitle
                                tooltip={
                                  strings.pricing_per_package_name_tooltip
                                }
                              >
                                Package name
                              </SubsectionTitle>
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                              {pacakageToggle[index] ? (
                                <Title
                                  cursor="pointer"
                                  color="#182429"
                                  size={13}
                                  onClick={() =>
                                    showPackageDetailsEditor(
                                      index,
                                      pacakageToggle[index]
                                    )
                                  }
                                >
                                  Hide details{" "}
                                  <MinusSquareOutlined
                                    style={{ fontSize: "16px" }}
                                  />
                                </Title>
                              ) : (
                                <Title
                                  cursor="pointer"
                                  color="#344ef3"
                                  size={13}
                                  onClick={() =>
                                    showPackageDetailsEditor(
                                      index,
                                      pacakageToggle[index]
                                    )
                                  }
                                >
                                  Show details{" "}
                                  <PlusSquareFilled
                                    style={{ fontSize: "16px" }}
                                  />
                                </Title>
                              )}
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name={`${`package_name_` + item.form_id}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input name!",
                                  },
                                ]}
                                style={{ marginBottom: "0px" }}
                              >
                                <Input
                                  onChange={(e) =>
                                    onChangePackageName(e, index)
                                  }
                                  suffix={40 - (item.name?.length || 0)}
                                  maxLength={40}
                                  placeholder="e.g. Enterprise premium"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {pacakageToggle[index] && <Flexrow top="16px" />}
                          {pacakageToggle[index] && (
                            <>
                              <Row>
                                <Col span={10}>
                                  <SubsectionTitle
                                    tooltip={
                                      strings.pricing_per_package_price_tooltip
                                    }
                                  >
                                    Price
                                  </SubsectionTitle>
                                </Col>
                                <Col span={13} offset={1}>
                                  <SubsectionTitle
                                    tooltip={
                                      strings.pricing_per_package_minimum_purchase_tooltip
                                    }
                                  >
                                    Minimum purchase
                                  </SubsectionTitle>
                                </Col>
                                <Col span={10}>
                                  <Form.Item
                                    name={`${`package_price_` + item.form_id}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input price!",
                                      },
                                      {
                                        pattern: /^[+]?([.]\d+|\d+[.]?\d*)$/,
                                        message:
                                          "Price must be a positive number!",
                                      },
                                    ]}
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <Input
                                      type="number"
                                      onChange={(e) => onChangePrice(e, index)}
                                      placeholder="e.g. 1000 euro"
                                      min={0}
                                    />
                                  </Form.Item>
                                  <Flexrow top="16px" />
                                </Col>
                                <Col span={13} offset={1}>
                                  <Input
                                    name="min_package_purchase"
                                    key={index}
                                    type="number"
                                    onChange={(e) =>
                                      onChangeMinPackagePurchase(e, index)
                                    }
                                    placeholder="e.g. 3 months"
                                    value={item.min_packages_purchased}
                                    min={0}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <SubsectionTitle
                                    tooltip={
                                      strings.pricing_per_package_monthly_allowance_tooltip
                                    }
                                  >
                                    Monthly allowance
                                  </SubsectionTitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={10}>
                                  <Input
                                    name="monthly_allowance"
                                    key={index}
                                    type="number"
                                    onChange={(e) =>
                                      onChangeMonthlyAllowance(e, index)
                                    }
                                    placeholder="e.g. 100 requests"
                                    value={item.requests}
                                    min={0}
                                  />
                                  <Flexrow top="16px" />
                                </Col>
                              </Row>
                              <Row>
                                <SubsectionTitle
                                  tooltip={
                                    strings.pricing_per_request_limits_tooltip
                                  }
                                >
                                  Limits
                                </SubsectionTitle>
                              </Row>
                              <Row>
                                <Col span={10}>
                                  <Input
                                    name="package_limit_minute"
                                    key={index}
                                    type="number"
                                    onChange={(e) =>
                                      onChangeLimitsValue(
                                        e,
                                        "limit_minute",
                                        index
                                      )
                                    }
                                    placeholder="e.g. 10 requests"
                                    value={item.limit_minute}
                                    min={0}
                                  />
                                </Col>
                                <Col span={9} offset={1}>
                                  <Title color="#182a33" size={15}>
                                    per minute
                                  </Title>
                                </Col>
                              </Row>
                              <Flexrow top="16px" />
                              <Row>
                                <Col span={10}>
                                  <Input
                                    name="package_limit_hour"
                                    type="number"
                                    key={index}
                                    onChange={(e) =>
                                      onChangeLimitsValue(
                                        e,
                                        "limit_hour",
                                        index
                                      )
                                    }
                                    placeholder="e.g. 10 requests"
                                    value={item.limit_hour}
                                    min={0}
                                  />
                                </Col>
                                <Col span={9} offset={1}>
                                  <Title color="#182a33" size={15}>
                                    per hour
                                  </Title>
                                </Col>
                              </Row>
                              <Flexrow top="16px" />
                              <Row>
                                <Col span={10}>
                                  <Input
                                    name="package_limit_day"
                                    type="number"
                                    key={index}
                                    onChange={(e) =>
                                      onChangeLimitsValue(e, "limit_day", index)
                                    }
                                    placeholder="e.g. 10 requests"
                                    value={item.limit_day}
                                    min={0}
                                  />
                                </Col>
                                <Col span={9} offset={1}>
                                  <Title color="#182a33" size={15}>
                                    per day
                                  </Title>
                                </Col>
                              </Row>
                            </>
                          )}
                          {index != 0 && (
                            <DeleteOutlined
                              onClick={() =>
                                handleRemovePackageClick(item.form_id)
                              }
                              style={{
                                position: "absolute",
                                right: "-31px",
                                top: "10px",
                              }}
                            />
                          )}
                        </div>
                        <Flexrow top="16px" />
                      </Fragment>
                    ))}
                  </>
                )}
                <>
                  {charge_type === "per_month" && (
                    <>
                      <Row>
                        <Col span={24}>
                          {showApiEndpoint && (
                            <Title
                              size={12}
                              color="#344ef3"
                              cursor="pointer"
                              onClick={() => handleAddPackageClick()}
                            >
                              <PlusOutlined /> Add package
                            </Title>
                          )}
                        </Col>
                      </Row>
                      <Flexrow top="16px" />
                      <span>{hide_price_view}</span>
                    </>
                  )}
                  {charge_type === "per_request" && (
                    <span>{hide_price_view}</span>
                  )}
                </>
                {charge_type === "free" && (
                  <>
                    <Row style={{ marginTop: "8px", marginBottom: "8px" }}>
                      <SubsectionTitle
                        tooltip={strings.pricing_per_request_limits_tooltip}
                      >
                        Limits
                      </SubsectionTitle>
                    </Row>
                    <Row>
                      <Col span={10}>
                        <Input
                          name="limit_minute_free"
                          type="number"
                          onChange={(e) =>
                            onChangeLimitsValue(e, "limit_minute", 0)
                          }
                          placeholder="e.g. 10 requests"
                          value={packages[0].limit_minute}
                          min={0}
                        />
                      </Col>
                      <Col span={9} offset={1}>
                        <Title color="#182a33" size={15}>
                          per minute
                        </Title>
                      </Col>
                    </Row>
                    <Flexrow top="16px" />
                    <Row>
                      <Col span={10}>
                        <Input
                          name="limit_hour_free"
                          type="number"
                          onChange={(e) =>
                            onChangeLimitsValue(e, "limit_hour", 0)
                          }
                          placeholder="e.g. 10 requests"
                          value={packages[0].limit_hour}
                          min={0}
                        />
                      </Col>
                      <Col span={9} offset={1}>
                        <Title color="#182a33" size={15}>
                          per hour
                        </Title>
                      </Col>
                    </Row>
                    <Flexrow top="16px" />
                    <Row>
                      <Col span={10}>
                        <Input
                          name="limit_day_free"
                          type="number"
                          onChange={(e) =>
                            onChangeLimitsValue(e, "limit_day", 0)
                          }
                          placeholder="e.g. 10 requests"
                          value={packages[0].limit_day}
                          min={0}
                        />
                      </Col>
                      <Col span={9} offset={1}>
                        <Title color="#182a33" size={15}>
                          per day
                        </Title>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : charge_type === "per_request" ? (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.pricing_method_tooltip}
                    </Desc>
                  }
                >
                  Pricing method
                </SubsectionTitle>
                <Title color="#182429" size={15} display="block">
                  Per request
                </Title>
                <Flexrow top="16px" />
                <SubsectionTitle
                  tooltip={strings.pricing_per_request_price_tooltip}
                >
                  Price
                </SubsectionTitle>
                <Text>{packages[0].price && packages[0].price + " euro"}</Text>
                <Flexrow top="16px" />
                <SubsectionTitle
                  tooltip={strings.pricing_per_request_minimum_purchase_tooltip}
                >
                  Minimum purchase
                </SubsectionTitle>
                <Text>
                  {packages[0].min_requests_purchased &&
                    packages[0].min_requests_purchased + "  requests"}
                </Text>
                <Flexrow top="16px" />
                <SubsectionTitle
                  tooltip={strings.pricing_per_request_limits_tooltip}
                >
                  Limits
                </SubsectionTitle>
                <Text>
                  {packages[0].limit_minute &&
                    packages[0].limit_minute + " requests per minute"}
                </Text>
                <Flexrow top="16px" />
                <Text>
                  {packages[0].limit_hour &&
                    packages[0].limit_hour + " requests per hour"}
                </Text>
                <Flexrow top="16px" />
                <Text>
                  {packages[0].limit_day &&
                    packages[0].limit_day + " requests per day"}
                </Text>
                <Flexrow top="16px" />
                <Container flex align="center">
                  <Tooltip
                    title={strings.hide_price_in_public_tooltip}
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: "2px 4px 12px 0px #182a331f",
                      fontSize: "13px",
                      borderRadius: "4px",
                    }}
                    placement="bottom"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Title size={14} margin={"0 0 0 8px"} weight="normal">
                    {helper.hide_pricing.value
                      ? "Your pricing is not public"
                      : "Your pricing is public"}
                  </Title>
                </Container>
              </>
            ) : charge_type === "per_month" ? (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.pricing_method_tooltip}
                    </Desc>
                  }
                >
                  Pricing method
                </SubsectionTitle>
                <Title color="#182429" size={15} display="block">
                  Per package
                </Title>
                <Flexrow top="16px" />
                {packages.map((item, index) => (
                  <Fragment key={item.form_id || "new-package"}>
                    <div
                      style={{
                        borderRadius: "4px",
                        border: "1px solid #6a6d6e",
                        padding: "10px",
                        position: "relative",
                        background: "#fff",
                        marginBottom: "16px",
                      }}
                    >
                      <SubsectionTitle
                        tooltip={strings.pricing_per_package_name_tooltip}
                      >
                        Package name
                      </SubsectionTitle>
                      <Text>{item.name}</Text>
                      <Flexrow top="16px" />
                      <SubsectionTitle
                        tooltip={strings.pricing_per_package_price_tooltip}
                      >
                        Price
                      </SubsectionTitle>
                      <Text>{item.price + " euro"}</Text>
                      <Flexrow top="16px" />
                      <SubsectionTitle
                        tooltip={
                          strings.pricing_per_package_minimum_purchase_tooltip
                        }
                      >
                        Minimum purchase
                      </SubsectionTitle>
                      <Text>{item.min_packages_purchased + " months"}</Text>
                      <Flexrow top="16px" />
                      <SubsectionTitle
                        tooltip={
                          strings.pricing_per_package_monthly_allowance_tooltip
                        }
                      >
                        Monthly allowance
                      </SubsectionTitle>
                      <Text>{item.requests + " requests"}</Text>
                      <Flexrow top="16px" />
                      <SubsectionTitle
                        tooltip={strings.pricing_per_request_limits_tooltip}
                      >
                        Limits
                      </SubsectionTitle>
                      <Text>
                        {item.limit_minute &&
                          item.limit_minute + " requests per minute"}
                      </Text>
                      <Flexrow top="16px" />
                      <Text>
                        {item.limit_hour &&
                          item.limit_hour + " requests per hour"}
                      </Text>
                      <Flexrow top="16px" />
                      <Text>
                        {item.limit_day && item.limit_day + " requests per day"}
                      </Text>
                      <Flexrow top="16px" />
                    </div>
                    <Container flex align="center">
                      <Tooltip
                        title={strings.hide_price_in_public_tooltip}
                        color="#1a337d"
                        overlayInnerStyle={{
                          boxShadow: "2px 4px 12px 0px #182a331f",
                          fontSize: "13px",
                          borderRadius: "4px",
                        }}
                        placement="bottom"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                      <Title size={14} margin={"0 0 0 8px"} weight="normal">
                        {helper.hide_pricing.value
                          ? "Your pricing is not public"
                          : "Your pricing is public"}
                      </Title>
                    </Container>
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.pricing_method_tooltip}
                    </Desc>
                  }
                >
                  Pricing method
                </SubsectionTitle>
                <Title color="#182429" size={15} display="block">
                  Free
                </Title>
                <Flexrow top="16px" />
                <SubsectionTitle
                  tooltip={strings.pricing_per_request_limits_tooltip}
                >
                  Limits
                </SubsectionTitle>
                <Text>
                  {packages[0].limit_minute &&
                    packages[0].limit_minute + " requests per minute"}
                </Text>
                <Flexrow top="16px" />
                <Text>
                  {packages[0].limit_hour &&
                    packages[0].limit_hour + " requests per hour"}
                </Text>
                <Flexrow top="16px" />
                <Text>
                  {packages[0].limit_day &&
                    packages[0].limit_day + " requests per day"}
                </Text>
              </>
            )}
          </EditModeWrap>
          <Flexrow top="32px" />
          <EditModeWrap
            editing={helper.sections_map.license.editing}
            marginBottom="-32px"
          >
            {helper.sections_map.license.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.license.saving}
              title="License"
              tooltip={
                <Desc pre color="#fff">
                  {strings.license_tooltip}
                </Desc>
              }
              editing={helper.sections_map.license.editing}
              startEditing={() => helper.sections_map.license.setEditing()}
              saveChanges={() => helper.sections_map.license.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.license.edited()}
              handleCancel={() => showModal(helper.sections_map.license)}
            />
            {helper.sections_map.license.editing ? (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.license_tooltip}
                    </Desc>
                  }
                >
                  License
                </SubsectionTitle>
                <Editor
                  apiKey={tinyMCEEditorAPIKey}
                  value={helper.license_type.value}
                  onEditorChange={(v) => helper.license_type.set(v)}
                  init={tinyInitConfig}
                />
              </>
            ) : (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.license_tooltip}
                    </Desc>
                  }
                >
                  License
                </SubsectionTitle>
                <LicenseContainer
                  dangerouslySetInnerHTML={{
                    __html: helper.license_type.value,
                  }}
                ></LicenseContainer>
              </>
            )}
          </EditModeWrap>
          <Flexrow top="32px" />
          <EditModeWrap
            editing={helper.sections_map.contact.editing}
            marginBottom="-42px"
          >
            {helper.sections_map.contact.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.contact.saving}
              title="Technical contact details"
              tooltip={
                <Desc pre color="#fff">
                  {strings.technical_contact_details_tooltip}
                </Desc>
              }
              editing={helper.sections_map.contact.editing}
              startEditing={() => helper.sections_map.contact.setEditing()}
              saveChanges={() => helper.sections_map.contact.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.contact.edited()}
              handleCancel={() => showModal(helper.sections_map.contact)}
            />
            {helper.sections_map.contact.editing ? (
              <Row justify="space-between">
                <Col span={11}>
                  <SubsectionTitle
                    tooltip={strings.technical_contact_details_name_tooltip}
                  >
                    {`Name & Surname`}
                  </SubsectionTitle>
                </Col>
                <Col span={11} offset={1}>
                  <SubsectionTitle
                    tooltip={strings.technical_contact_details_email_tooltip}
                  >
                    Email
                  </SubsectionTitle>
                </Col>
                <Col span={11}>
                  <Input
                    name="contact-name"
                    onChange={(e) => helper.contactName.set(e.target.value)}
                    value={helper.contactName.value}
                    placeholder="e.g. John Doe"
                  />
                </Col>
                <Col span={11} offset={1}>
                  <Input
                    name="contact-email"
                    onChange={(e) => helper.contactEmail.set(e.target.value)}
                    value={helper.contactEmail.value}
                    placeholder="e.g. your@email.com"
                  />
                </Col>
              </Row>
            ) : (
              <>
                <Row justify="space-between">
                  <Col span={11}>
                    <SubsectionTitle
                      tooltip={strings.technical_contact_details_name_tooltip}
                    >
                      {`Name & Surname`}
                    </SubsectionTitle>
                  </Col>
                  <Col span={11} offset={1}>
                    <SubsectionTitle
                      tooltip={strings.technical_contact_details_email_tooltip}
                    >
                      Email
                    </SubsectionTitle>
                  </Col>
                  <Col span={11}>
                    <Text>{helper.contactName.value}</Text>
                  </Col>
                  <Col span={11} offset={1}>
                    <Text>{helper.contactEmail.value}</Text>
                  </Col>
                </Row>
              </>
            )}
          </EditModeWrap>
        </Form>
      </SectionCardContainer>
    </>
  );
};

export default ProxyPublishedFullVersion;
