import React, { useMemo, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Spin } from 'antd';
import LayoutRow from '../LayoutRow';
import Header from '../Header';
import { KeyboardArrowLeft } from 'styled-icons/material';
import { colors } from '../../values/colors';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useSWR from 'swr';
import { fetcherWithToken } from '../../utils/fetcher';
import Cookies from 'js-cookie';
import { Select } from 'antd';
import { AppContext } from '../../hooks/AppContextProvider';
import { Flexcol, Flexrow } from '../Flexbox';
import { data_placement_status_ids } from '../../iso-shared/values/global';

const ProjectTabs = styled(Tabs)`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-bottom: 52;
  flex: 1;
`;

const ProjectTabList = styled(TabList)`
  list-style-type: none;
  margin: 0;
  padding: 16px 20px;
`;

const ProjectTab = styled(Tab)`
  margin-top: 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #bfc8d5;
  border-radius: 8px;
  border: 1px solid ${colors['grey_1']};
  background: #ffffff;

  &.react-tabs__tab--selected {
    border-color: ${colors['tirq_6']};
    color: ${colors['grey_7']};
    background: ${colors['tirq_4']};
  }
`;

const ProjectLayout = ({ project, children }) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const hasTab = queryParams.get('tab');
  const [tabIndex, setTabIndex] = useState(hasTab ? parseInt(hasTab) : 0);

  useEffect(() => {
    context.setDashboardProps({
      ...context.dashboard_props,
      setTabIndex: setTabIndex,
    });
  }, []);

  const tabs = children.filter((child) => child.props.name === 'data-tabs')[0];
  const tab_contents = children.filter(
    (child) => child.props.name === 'data-tab-contents',
  )[0];

  const token = Cookies.get('jwt') || '';
  const { data: projects } = useSWR(
    [
      window.location.pathname.indexOf('data-connection') >= 0
        ? `/api/data-connections`
        : `/api/data-consumptions`,
      token,
    ],
    fetcherWithToken,
  );

  const published_projects = useMemo(() => {
    if (!projects) {
      return [];
    }
    return projects.filter(
      (p) =>
        p.status_id == data_placement_status_ids.active ||
        p.status_id == data_placement_status_ids.pending,
    );
  }, [projects]);

  if (!projects)
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

  const onProjectSelect = (id) => {
    history.push('/project/data-connection/' + id);
  };

  const goToDashboard = () => {
    history.push({
      pathname: `/dashboard`,
      state: {
        key:
          window.location.pathname.indexOf('data-connection') >= 0 ? '1' : '2',
      },
    });
  };

  return (
    <>
      <LayoutRow background={'#ffffff'} withBorder>
        <Header
          stepsFor="PUBLISHED_DATA_PRODUCT"
          isReview={project.status_id !== data_placement_status_ids.active}
        />
      </LayoutRow>
      <LayoutRow
        background={'#ffffff'}
        withBorder
        style={{ padding: '12px 0px' }}
      >
        <Flexrow center_align style={{ padding: 12 }}>
          <KeyboardArrowLeft
            size="32"
            style={{ color: '#a8afb3', cursor: 'pointer', marginRight: '12px' }}
            onClick={goToDashboard}
          />
          <Select
            style={{ width: 300 }}
            value={project.id}
            onChange={onProjectSelect}
          >
            {published_projects.map((p) => (
              <Select.Option value={p.id} key={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
          <Flexcol grow />
          <Flexrow id="placement-view-navbar"></Flexrow>
        </Flexrow>
      </LayoutRow>
      <ProjectTabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <Row
          id="project-scroller"
          style={{
            height: '100%',
            marginLeft: 'unset',
            marginRight: 'unset',
          }}
        >
          <Col
            style={{
              boxShadow: `2px 4px 12px 0px rgba(24, 42, 51, 0.12)`,
              backgroundColor: '#ffffff',
              height: '100%',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              minWidth: 'fit-content',
            }}
            xs={1}
          >
            <ProjectTabList>
              {tabs.props.children.map((tab, index) => {
                return (
                  <ProjectTab data-id={tab.props['data-id']} key={index}>
                    {tab}
                  </ProjectTab>
                );
              })}
            </ProjectTabList>
          </Col>
          <Col style={{ flex: 1 }}>
            {tab_contents.props.children.map((tab_content, index) => {
              return (
                <TabPanel key={index}>
                  <div>{tab_content}</div>
                </TabPanel>
              );
            })}
          </Col>
        </Row>
      </ProjectTabs>
      <style jsx global>
        {`
          body {
            background-color: ${colors['ghost_white']};
          }
        `}
      </style>
    </>
  );
};

export default ProjectLayout;
