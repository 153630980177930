import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Tabs, Typography, Tooltip } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { subMonths } from 'date-fns';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { toast } from 'react-toastify';

import { Lock } from '@styled-icons/evil/Lock';
import { InfoCircle } from '@styled-icons/boxicons-solid/InfoCircle';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';

import LayoutRow from '../LayoutRow';
import { Flexcol, Flexrow } from '../Flexbox';
import NoPlansImgSource from '../../images/noPlans.svg';
import PayToActivateImgSource from '../../images/PayToActivate.webp';
import Notification from '../../components/Notification';

import 'react-circular-progressbar/dist/styles.css';

const { TabPane } = Tabs;
const { Text } = Typography;

const CardWrap = styled.div`
  width: 70%;
  margin: ${(props) => (props.top ? `${props.top} auto` : 'auto')};
`;

const BuyMoreButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const PayToActivateButton = styled(Button)`
  background-color: #ff7f19;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background-color: #e56b0a;
    color: #fff;
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const StatusTag = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 14px;
  padding: 4px 11px;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '10px')};
  width: fit-content;
  height: 30px;
`;

const NoPlansWrap = styled.div`
  background: #64737f00;
  border: 1px dashed #64737f;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  padding: 50px 0px;
  width: 70%;
  margin: 70px auto;
  &:hover {
    background: #64737f0a;
  }
`;

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const ConsumptionModalPackage = (props) => {
  const [data, setData] = useState(null);
  const [currentMonthDifference, setCurrentMonthDifference] = useState(null);
  const [consumedDays, setConsumedDays] = useState(null);
  const [progressRemaining, setProgressRemaining] = useState(null);
  const [modalTab, setModalTab] = useState('1');
  const [monthsPercentage, setMonthsPercentage] = useState(null);
  const [requestsPercentage, setRequestsPercentage] = useState(null);

  const {
    imageUrl,
    name,
    setIsPackageModalVisible,
    isPackageModalVisible,
    resource_connection_id,
    openPackageCheckout,
    status,
  } = props;

  const token = Cookies.get('jwt') || '';
  const currentUserRole = Cookies.get('role');

  const MINUTE_MS = 4000;

  useEffect(() => {
    const fetchEndpoints = async () => {
      let res = await axios
        .get(
          `/api/data-consumption-details?conn_id=${resource_connection_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .catch((error) => {
          if (error.response.status === 404) {
            toast.error(
              <Notification type="error" text="Unauthorized Access" />,
            );
            history.push('/not-found');
          }
        });

      setData(res.data);
      differenceInDays(res.data);
      setMonthsPercentage(
        res.data && res.data.current_plan && res.data.current_plan.total
          ? (
              (res.data.current_plan.spent * 100) /
              res.data.current_plan.total
            ).toFixed()
          : 0,
      );
      setRequestsPercentage(
        res.data &&
          res.data.requests &&
          res.data.requests.total &&
          res.data.requests.spent
          ? (
              (res.data.requests.spent * 100) /
              res.data.requests.total
            ).toFixed()
          : 0,
      );
    };

    fetchEndpoints();
  }, []);

  useEffect(() => {
    if (isPackageModalVisible) {
      const interval = setInterval(() => {
        const fetchEndpoints = async () => {
          let res = await axios
            .get(
              `/api/data-consumption-details?conn_id=${resource_connection_id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
            .catch((error) => {
              if (error.response.status === 404) {
                toast.error(
                  <Notification type="error" text="Unauthorized Access" />,
                );
                history.push('/not-found');
              }
            });

          setData(res.data);
          differenceInDays(res.data);
          setMonthsPercentage(
            res.data && res.data.current_plan && res.data.current_plan.total
              ? (
                  (res.data.current_plan.spent * 100) /
                  res.data.current_plan.total
                ).toFixed()
              : 0,
          );
          setRequestsPercentage(
            res.data &&
              res.data.requests &&
              res.data.requests.total &&
              res.data.requests.spent
              ? (
                  (res.data.requests.spent * 100) /
                  res.data.requests.total
                ).toFixed()
              : 0,
          );
        };
        fetchEndpoints();
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [isPackageModalVisible]);

  const handleOk = () => {
    setIsPackageModalVisible(false);
  };

  const handleCancel = () => {
    setIsPackageModalVisible(false);
    setModalTab('1');
  };

  const differenceInDays = (response) => {
    if (response && response.current_plan) {
      const todayEndStartDate =
        new Date().getTime() <=
          new Date(response.current_plan.end_date).getTime() &&
        new Date().getTime() >=
          new Date(response.current_plan.start_date).getTime();

      const sub = subMonths(new Date(response.current_plan.end_date), 1);

      const subTimeCurrentStart =
        sub.getTime() <= new Date().getTime() &&
        sub.getTime() >= new Date(response.current_plan.start_date).getTime();

      const getDays = (v) => {
        return Math.round(Math.abs(v / (24 * 60 * 60 * 1000)));
      };

      const diffEnd = new Date(response.current_plan.end_date) - new Date(sub);
      const diffEndStart =
        new Date(response.current_plan.end_date) -
        new Date(response.current_plan.start_date);

      if (
        (todayEndStartDate && subTimeCurrentStart) ||
        (todayEndStartDate && getDays(diffEndStart) <= getDays(diffEnd))
      ) {
        const difference =
          new Date(response.current_plan.end_date) - new Date();

        const differenceInDays = getDays(difference);

        const consumed =
          todayEndStartDate && subTimeCurrentStart
            ? new Date() - new Date(sub)
            : new Date() - new Date(response.current_plan.start_date);

        const differenceInConsumedDays = getDays(consumed);

        setProgressRemaining(differenceInDays + differenceInConsumedDays);

        setCurrentMonthDifference(differenceInDays);
        setConsumedDays(differenceInConsumedDays);
      } else {
        setCurrentMonthDifference(null);
        setConsumedDays(null);
      }
    }
  };

  const formatDate = (date) => {
    let current_datetime = new Date(date);
    let formatted_date =
      current_datetime.getDate() +
      ' ' +
      months[current_datetime.getMonth()] +
      ' ' +
      current_datetime.getFullYear();
    return formatted_date;
  };

  const getKeyHandler = (key) => {
    setModalTab(key);
  };

  return (
    <Modal
      title={
        <LayoutRow>
          <Flexcol>
            <Flexrow center_align style={{ paddingTop: '10px' }}>
              <Flexrow center_align>
                <img
                  style={{ marginRight: '10px' }}
                  width="40"
                  height="40"
                  alt="mobility-module-image"
                  src={imageUrl}
                />
                <Text style={{ color: '#192A33', fontSize: '18px' }}>
                  {name ? name : 'No name'}
                </Text>
              </Flexrow>
            </Flexrow>
            <Flexrow center_align space_between>
              <Text
                style={{
                  marginTop: '18px',
                  color: '#192A33',
                  fontSize: '20px',
                  fontWeight: '600',
                }}
              >
                Consumption Analytics
              </Text>
              <Flexcol>
                {status === 'ACTIVE' ? (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                    zIndex={99999999}
                  >
                    <BuyMoreButton
                      onClick={() => openPackageCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Buy more / Upgrade
                    </BuyMoreButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                    zIndex={99999999}
                  >
                    <PayToActivateButton
                      onClick={() => openPackageCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Pay to activate
                    </PayToActivateButton>
                  </Tooltip>
                )}
                <Flexrow center_align top="10px">
                  <Lock style={{ height: '24px' }} />
                  <Text
                    style={{
                      color: '#6C7377',
                      fontSize: '9px',
                    }}
                  >
                    Secure payment process. Feel safe.
                  </Text>
                </Flexrow>
              </Flexcol>
            </Flexrow>
          </Flexcol>
        </LayoutRow>
      }
      visible={isPackageModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      style={{ paddingBottom: '0px' }}
      bodyStyle={{
        backgroundColor: '#F9FAFF',
        padding: '0px',
        height: '75vh',
        overflowY: 'auto',
      }}
      width="100%"
      footer={null}
      zIndex={1000000}
    >
      {status === 'ACTIVE' ? (
        <Tabs
          defaultActiveKey="1"
          activeKey={modalTab}
          onChange={getKeyHandler}
          tabBarStyle={{ backgroundColor: '#fff', padding: '0 8.5%' }}
        >
          <TabPane tab="Your current plan" key="1">
            <LayoutRow>
              <CardWrap top="20px">
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Your current plan
                </Text>
                <Card
                  style={{
                    marginTop: '12px',
                    borderRadius: '6px',
                    boxShadow: '2px 4px 12px 0px #182a330f',
                  }}
                  headStyle={{ borderBottom: 'none' }}
                  bodyStyle={{ padding: '0px 24px 24px' }}
                  bordered={false}
                  title={
                    data && data.current_plan && data.current_plan.name ? (
                      <Flexrow center_align>
                        <Text
                          style={{
                            fontSize: '24px',
                            color: '#192A33',
                            fontWeight: '600',
                            marginRight: '12px',
                          }}
                        >
                          {data.current_plan.name}
                        </Text>
                        {monthsPercentage >= '90' && (
                          <StatusTag
                            background="#ffe9e9"
                            borderColor="#fd5f5d"
                            bottom="0px"
                          >
                            <ClockFill
                              style={{
                                height: '16px',
                                color: '#fd5f5d',
                                paddingRight: '7px',
                              }}
                            />
                            Expires soon
                          </StatusTag>
                        )}
                      </Flexrow>
                    ) : (
                      <Flexrow center_align>
                        <Text
                          style={{
                            fontSize: '24px',
                            color: '#192A33',
                            fontWeight: '600',
                            marginRight: '12px',
                          }}
                        >
                          No name
                        </Text>
                        {monthsPercentage >= '90' && (
                          <StatusTag
                            background="#ffe9e9"
                            borderColor="#fd5f5d"
                            bottom="0px"
                          >
                            <ClockFill
                              style={{
                                height: '16px',
                                color: '#fd5f5d',
                                paddingRight: '7px',
                              }}
                            />
                            Expires soon
                          </StatusTag>
                        )}
                      </Flexrow>
                    )
                  }
                >
                  <Flexrow>
                    <Flexcol style={{ width: '50%' }}>
                      <Card
                        title={
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#64737F',
                              fontWeight: '500',
                            }}
                          >
                            Total months
                          </Text>
                        }
                        extra={
                          <Tooltip
                            color="#1a337d"
                            overlayInnerStyle={{
                              boxShadow: '2px 4px 12px 0px #182a331f',
                              fontSize: '13px',
                              borderRadius: '4px',
                            }}
                            placement="bottom"
                            title="Your current active plan: the number of months of access that you have purchased and the specific start and end dates of this plan"
                            zIndex={1000001}
                          >
                            <InfoCircle
                              style={{
                                height: '18px',
                                color: '#64737F',
                              }}
                            />
                          </Tooltip>
                        }
                        headStyle={{
                          backgroundColor: '#F6F6F6',
                          borderTopLeftRadius: '6px',
                          borderTopRightRadius: '6px',
                        }}
                        style={{
                          borderRadius: '6px',
                          border: '1px solid #C9CED1',
                          marginRight: '20px',
                          marginBottom: '20px',
                          height: '-webkit-fill-available',
                        }}
                        bordered={false}
                      >
                        <Text
                          style={{
                            fontSize: '14px',
                            color: '#64737F',
                            display: 'block',
                          }}
                        >
                          {`From ${
                            data &&
                            data.current_plan &&
                            data.current_plan.start_date
                              ? formatDate(data.current_plan.start_date)
                              : `No date`
                          } until ${
                            data &&
                            data.current_plan &&
                            data.current_plan.end_date
                              ? formatDate(data.current_plan.end_date)
                              : `No date`
                          }`}
                        </Text>
                        <Text
                          style={{
                            fontSize: '24px',
                            color: '#192A33',
                          }}
                        >{`${
                          data && data.current_plan && data.current_plan.total
                            ? data.current_plan.total
                            : 0
                        } months `}</Text>
                      </Card>
                      <Card
                        title={
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#64737F',
                              fontWeight: '500',
                            }}
                          >
                            Total cost
                          </Text>
                        }
                        extra={
                          <Tooltip
                            color="#1a337d"
                            overlayInnerStyle={{
                              boxShadow: '2px 4px 12px 0px #182a331f',
                              fontSize: '13px',
                              borderRadius: '4px',
                            }}
                            placement="bottom"
                            title="The total cost of your current active plan"
                            zIndex={1000001}
                          >
                            <InfoCircle
                              style={{
                                height: '18px',
                                color: '#64737F',
                              }}
                            />
                          </Tooltip>
                        }
                        headStyle={{
                          backgroundColor: '#F6F6F6',
                          borderTopLeftRadius: '6px',
                          borderTopRightRadius: '6px',
                        }}
                        style={{
                          borderRadius: '6px',
                          border: '1px solid #C9CED1',
                          marginRight: '20px',
                          height: '-webkit-fill-available',
                        }}
                        bordered={false}
                      >
                        {status === 'ACTIVE' && (
                          <StatusTag background="#cefac3" borderColor="#5fdf3e">
                            <CheckCircleFill
                              style={{
                                height: '16px',
                                color: '#5fdf3e',
                                paddingRight: '7px',
                              }}
                            />
                            Paid
                          </StatusTag>
                        )}
                        {data &&
                        data.current_plan &&
                        data.current_plan.price ? (
                          <Text
                            style={{
                              fontSize: '24px',
                              color: '#192A33',
                            }}
                          >
                            {data.current_plan.price.toLocaleString()} €
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontSize: '24px',
                              color: '#192A33',
                            }}
                          >
                            0 €{' '}
                          </Text>
                        )}
                      </Card>
                    </Flexcol>
                    <Card
                      title={
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#64737F',
                            fontWeight: '500',
                          }}
                        >
                          Consumption progress
                        </Text>
                      }
                      extra={
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          placement="bottom"
                          title="The remaining months of your current plan until it ends. Remember to top-up your plan in time before it ends"
                          zIndex={1000001}
                        >
                          <InfoCircle
                            style={{
                              height: '18px',
                              color: '#64737F',
                            }}
                          />
                        </Tooltip>
                      }
                      headStyle={{
                        backgroundColor: '#F6F6F6',
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                      }}
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #C9CED1',
                        width: '50%',
                      }}
                      bordered={false}
                    >
                      {currentMonthDifference === null && (
                        <>
                          <div
                            style={{
                              width: '220px',
                              height: '220px',
                              margin: 'auto',
                            }}
                          >
                            <CircularProgressbarWithChildren
                              styles={buildStyles({
                                pathColor:
                                  monthsPercentage >= '90'
                                    ? '#fd5f5d'
                                    : monthsPercentage >= '45'
                                    ? '#FFC540'
                                    : '#5FDF3E',
                                trailColor: '#F6F6F6',
                              })}
                              maxValue={
                                data &&
                                data.current_plan &&
                                data.current_plan.total
                              }
                              value={
                                data &&
                                data.current_plan &&
                                data.current_plan.total
                                  ? data.current_plan.total -
                                    data.current_plan.spent
                                  : 0
                              }
                            >
                              <Text
                                style={{
                                  fontSize: '24px',
                                  color: '#192A33',
                                  display: 'block',
                                }}
                              >
                                {data &&
                                data.current_plan &&
                                data.current_plan.total
                                  ? data.current_plan.total -
                                    data.current_plan.spent
                                  : 0}
                              </Text>
                              <Text
                                style={{
                                  fontSize: '16px',
                                  color: '#192A33',
                                  display: 'block',
                                }}
                              >
                                months
                              </Text>
                              <Text
                                style={{
                                  fontSize: '16px',
                                  color: '#192A33',
                                }}
                              >
                                remaining
                              </Text>
                            </CircularProgressbarWithChildren>
                          </div>
                          <Text
                            style={{
                              fontSize: '14px',
                              color: '#64737F',
                              display: 'block',
                              marginTop: '20px',
                              fontWeight: '600',
                              textAlign: 'center',
                            }}
                          >
                            So far:
                          </Text>
                          <Text
                            style={{
                              fontSize: '14px',
                              color: '#64737F',
                              textAlign: 'center',
                              display: 'block',
                            }}
                          >
                            {` ${
                              data &&
                              data.current_plan &&
                              data.current_plan.spent
                                ? data.current_plan.spent +
                                  ` months consumed (${monthsPercentage}%)`
                                : data &&
                                  data.current_plan &&
                                  data.current_plan.days_spent +
                                    ' days consumed'
                            } `}
                          </Text>
                        </>
                      )}

                      {currentMonthDifference != null && (
                        <>
                          <div
                            style={{
                              width: '220px',
                              height: '220px',
                              margin: 'auto',
                            }}
                          >
                            <CircularProgressbarWithChildren
                              styles={buildStyles({
                                pathColor: '#5FDF3E',
                                trailColor: '#F6F6F6',
                              })}
                              maxValue={
                                currentMonthDifference
                                  ? currentMonthDifference
                                  : 0
                              }
                              value={
                                currentMonthDifference
                                  ? currentMonthDifference
                                  : 0
                              }
                            >
                              <Text
                                style={{
                                  fontSize: '24px',
                                  color: '#192A33',
                                  display: 'block',
                                }}
                              >
                                {currentMonthDifference
                                  ? currentMonthDifference
                                  : 0}
                              </Text>
                              <Text
                                style={{
                                  fontSize: '16px',
                                  color: '#192A33',
                                  display: 'block',
                                }}
                              >
                                days
                              </Text>
                              <Text
                                style={{
                                  fontSize: '16px',
                                  color: '#192A33',
                                }}
                              >
                                remaining
                              </Text>
                            </CircularProgressbarWithChildren>
                          </div>
                          <Text
                            style={{
                              fontSize: '14px',
                              color: '#64737F',
                              display: 'block',
                              marginTop: '20px',
                              fontWeight: '600',
                              textAlign: 'center',
                            }}
                          >
                            So far:
                          </Text>
                          <Text
                            style={{
                              fontSize: '14px',
                              color: '#64737F',
                              textAlign: 'center',
                              display: 'block',
                            }}
                          >
                            {` ${
                              consumedDays ? consumedDays : 0
                            } days consumed (${(
                              (consumedDays * 100) /
                              progressRemaining
                            ).toFixed()}%)`}
                          </Text>
                        </>
                      )}
                    </Card>
                  </Flexrow>
                </Card>
              </CardWrap>
              <CardWrap top="50px">
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Your monthly allowance
                </Text>
                <Card
                  style={{
                    marginTop: '12px',
                    borderRadius: '6px',
                    boxShadow: '2px 4px 12px 0px #182a330f',
                  }}
                  headStyle={{ borderBottom: 'none' }}
                  bodyStyle={{ padding: '0px 24px 24px' }}
                  bordered={false}
                  title={
                    data && data.requests && data.requests.total ? (
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                          fontWeight: '600',
                        }}
                      >
                        {data.requests.total} requests/month
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                          fontWeight: '600',
                        }}
                      >
                        Unlimited
                      </Text>
                    )
                  }
                >
                  <Card
                    title={
                      <Text
                        style={{
                          fontSize: '16px',
                          color: '#64737F',
                          fontWeight: '500',
                        }}
                      >
                        This month's consumption progress
                      </Text>
                    }
                    extra={
                      <Tooltip
                        color="#1a337d"
                        overlayInnerStyle={{
                          boxShadow: '2px 4px 12px 0px #182a331f',
                          fontSize: '13px',
                          borderRadius: '4px',
                        }}
                        placement="bottom"
                        title="The current month’s consumption summary"
                        zIndex={1000001}
                      >
                        <InfoCircle
                          style={{
                            height: '18px',
                            color: '#64737F',
                          }}
                        />
                      </Tooltip>
                    }
                    headStyle={{
                      backgroundColor: '#F6F6F6',
                      borderTopLeftRadius: '6px',
                      borderTopRightRadius: '6px',
                    }}
                    style={{
                      borderRadius: '6px',
                      border: '1px solid #C9CED1',
                      marginRight: '20px',
                    }}
                    bordered={false}
                  >
                    {data && data.requests && data.requests.total ? (
                      <>
                        <Text
                          style={{
                            fontSize: '14px',
                            color: '#64737F',
                            display: 'block',
                            textAlign: 'center',
                          }}
                        >
                          {`From ${
                            data &&
                            data.current_plan &&
                            data.current_plan.month &&
                            data.current_plan.month.start_date
                              ? formatDate(data.current_plan.month.start_date)
                              : `No date`
                          } until ${
                            data &&
                            data.current_plan &&
                            data.current_plan.month &&
                            data.current_plan.month.end_date
                              ? formatDate(data.current_plan.month.end_date)
                              : `No date`
                          }`}
                        </Text>
                        <div
                          style={{
                            width: '250px',
                            height: '250px',
                            margin: '12px auto',
                          }}
                        >
                          <CircularProgressbarWithChildren
                            styles={buildStyles({
                              pathColor:
                                requestsPercentage >= '90'
                                  ? '#fd5f5d'
                                  : requestsPercentage >= '45'
                                  ? '#FFC540'
                                  : '#5FDF3E',
                              trailColor: '#F6F6F6',
                            })}
                            maxValue={
                              data && data.requests && data.requests.total
                            }
                            value={
                              data && data.requests && data.requests.total
                                ? data.requests.total - data.requests.spent
                                : 0
                            }
                          >
                            <Text
                              style={{
                                fontSize: '24px',
                                color: '#192A33',
                                display: 'block',
                              }}
                            >
                              {data && data.requests && data.requests.total
                                ? data.requests.total - data.requests.spent
                                : 0}
                            </Text>
                            <Text
                              style={{
                                fontSize: '16px',
                                color: '#192A33',
                                display: 'block',
                              }}
                            >
                              requests
                            </Text>
                            <Text
                              style={{
                                fontSize: '16px',
                                color: '#192A33',
                              }}
                            >
                              remaining
                            </Text>
                          </CircularProgressbarWithChildren>
                        </div>
                        <Text
                          style={{
                            fontSize: '14px',
                            color: '#64737F',
                            display: 'block',
                            marginTop: '20px',
                            fontWeight: '600',
                            textAlign: 'center',
                          }}
                        >
                          So far:
                        </Text>
                        <Text
                          style={{
                            fontSize: '14px',
                            color: '#64737F',
                            textAlign: 'center',
                            display: 'block',
                          }}
                        >
                          {` ${
                            data && data.requests && data.requests.spent
                              ? data.requests.spent
                              : 0
                          } requests consumed (${requestsPercentage}%)`}
                        </Text>
                      </>
                    ) : (
                      <div
                        style={{
                          width: '250px',
                          height: '250px',
                          margin: 'auto',
                        }}
                      >
                        <CircularProgressbarWithChildren
                          styles={buildStyles({
                            pathColor: '#5FDF3E',
                            trailColor: '#5FDF3E',
                            backgroundColor: '#5FDF3E',
                          })}
                          background={true}
                          value={
                            data && data.requests ? data.requests.spent : 0
                          }
                        >
                          <Text
                            style={{
                              fontSize: '24px',
                              color: '#fff',
                              display: 'block',
                            }}
                          >
                            {data && data.requests ? data.requests.spent : 0}
                          </Text>
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#fff',
                              display: 'block',
                            }}
                          >
                            requests
                          </Text>
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#fff',
                            }}
                          >
                            consumed
                          </Text>
                        </CircularProgressbarWithChildren>
                      </div>
                    )}
                  </Card>
                </Card>
              </CardWrap>
              <CardWrap top="50px">
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Other limits
                </Text>
                <Card
                  style={{
                    marginTop: '12px',
                    borderRadius: '6px',
                    boxShadow: '2px 4px 12px 0px #182a330f',
                  }}
                  headStyle={{ borderBottom: 'none' }}
                  bordered={false}
                  title={null}
                >
                  <Flexrow>
                    <Card
                      title={
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#64737F',
                            fontWeight: '500',
                          }}
                        >
                          Per minute
                        </Text>
                      }
                      extra={
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          overlayStyle={{ maxWidth: 'fit-content' }}
                          placement="bottom"
                          title={
                            <div>
                              <strong>Total</strong>: The maximum total number
                              of API requests that you are allowed to do within
                              a minute (static)
                              <br />
                              <strong>Consumed</strong>: The number of API
                              requests that you have consumed over the last 60
                              seconds (auto-updated)
                            </div>
                          }
                          zIndex={1000001}
                        >
                          <InfoCircle
                            style={{
                              height: '18px',
                              color: '#64737F',
                            }}
                          />
                        </Tooltip>
                      }
                      headStyle={{
                        backgroundColor: '#F6F6F6',
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                      }}
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #C9CED1',
                        marginRight: '20px',
                        width: '33%',
                      }}
                      bordered={false}
                    >
                      <Text
                        style={{
                          fontSize: '14px',
                          color: '#64737F',
                          display: 'block',
                        }}
                      >
                        Total:
                      </Text>
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                        }}
                      >
                        {data && data.limits && data.limits.minute.limit
                          ? data.limits.minute.limit
                          : `Unlimited`}
                      </Text>
                      <Text
                        style={{
                          fontSize: '14px',
                          color: '#64737F',
                          display: 'block',
                        }}
                      >
                        Consumed:
                      </Text>
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                        }}
                      >
                        {data &&
                        data.limits &&
                        data.limits.minute &&
                        data.limits.minute.spent
                          ? data.limits.minute.spent
                          : `None`}
                      </Text>
                    </Card>
                    <Card
                      title={
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#64737F',
                            fontWeight: '500',
                          }}
                        >
                          Per hour
                        </Text>
                      }
                      extra={
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          overlayStyle={{ maxWidth: 'fit-content' }}
                          placement="bottom"
                          title={
                            <div>
                              <strong>Total</strong>: The maximum total number
                              of API requests that you are allowed to do within
                              an hour (static)
                              <br />
                              <strong>Consumed</strong>: The number of API
                              requests that you have consumed over the last 60
                              minutes (auto-updated)
                            </div>
                          }
                          zIndex={1000001}
                        >
                          <InfoCircle
                            style={{
                              height: '18px',
                              color: '#64737F',
                            }}
                          />
                        </Tooltip>
                      }
                      headStyle={{
                        backgroundColor: '#F6F6F6',
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                      }}
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #C9CED1',
                        marginRight: '20px',
                        width: '33%',
                      }}
                      bordered={false}
                    >
                      <Text
                        style={{
                          fontSize: '14px',
                          color: '#64737F',
                          display: 'block',
                        }}
                      >
                        Total:
                      </Text>
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                        }}
                      >
                        {data && data.limits && data.limits.hour.limit
                          ? data.limits.hour.limit
                          : `Unlimited`}
                      </Text>
                      <Text
                        style={{
                          fontSize: '14px',
                          color: '#64737F',
                          display: 'block',
                        }}
                      >
                        Consumed:
                      </Text>
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                        }}
                      >
                        {data &&
                        data.limits &&
                        data.limits.hour &&
                        data.limits.hour.spent
                          ? data.limits.hour.spent
                          : `None`}
                      </Text>
                    </Card>
                    <Card
                      title={
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#64737F',
                            fontWeight: '500',
                          }}
                        >
                          Per day
                        </Text>
                      }
                      extra={
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          overlayStyle={{ maxWidth: 'fit-content' }}
                          placement="bottom"
                          title={
                            <div>
                              <strong>Total</strong>: The maximum total number
                              of API requests that you are allowed to do within
                              a day (static)
                              <br />
                              <strong>Consumed</strong>: The number of API
                              requests that you have consumed over the last 24
                              hours (auto-updated)
                            </div>
                          }
                          zIndex={1000001}
                        >
                          <InfoCircle
                            style={{
                              height: '18px',
                              color: '#64737F',
                            }}
                          />
                        </Tooltip>
                      }
                      headStyle={{
                        backgroundColor: '#F6F6F6',
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                      }}
                      style={{
                        borderRadius: '6px',
                        border: '1px solid #C9CED1',
                        marginRight: '20px',
                        width: '33%',
                      }}
                      bordered={false}
                    >
                      <Text
                        style={{
                          fontSize: '14px',
                          color: '#64737F',
                          display: 'block',
                        }}
                      >
                        Total:
                      </Text>
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                        }}
                      >
                        {data && data.limits && data.limits.day.limit
                          ? data.limits.day.limit
                          : `Unlimited`}
                      </Text>
                      <Text
                        style={{
                          fontSize: '14px',
                          color: '#64737F',
                          display: 'block',
                        }}
                      >
                        Consumed:
                      </Text>
                      <Text
                        style={{
                          fontSize: '24px',
                          color: '#192A33',
                        }}
                      >
                        {data &&
                        data.limits &&
                        data.limits.day &&
                        data.limits.day.spent
                          ? data.limits.day.spent
                          : `None`}
                      </Text>
                    </Card>
                  </Flexrow>
                </Card>
              </CardWrap>
            </LayoutRow>
          </TabPane>
          <TabPane tab="Your next plans" key="2">
            <LayoutRow>
              {data && data.next_plans?.length > 0 ? (
                <CardWrap top="20px">
                  <Text
                    style={{
                      color: '#64737F',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    Your next plans
                  </Text>
                  <Card
                    style={{
                      marginTop: '12px',
                      borderRadius: '6px',
                      boxShadow: '2px 4px 12px 0px #182a330f',
                    }}
                    headStyle={{ borderBottom: 'none' }}
                    bordered={false}
                    title={null}
                  >
                    {data.next_plans.map((v, index) => (
                      <Card
                        key={index}
                        title={
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#64737F',
                              fontWeight: '500',
                            }}
                          >
                            {v.name}
                          </Text>
                        }
                        extra={
                          <Tooltip
                            color="#1a337d"
                            overlayInnerStyle={{
                              boxShadow: '2px 4px 12px 0px #182a331f',
                              fontSize: '13px',
                              borderRadius: '4px',
                            }}
                            placement="bottom"
                            title="The next plans that you have already purchased and their respective start and end dates"
                            zIndex={1000001}
                          >
                            <InfoCircle
                              style={{
                                height: '18px',
                                color: '#64737F',
                              }}
                            />
                          </Tooltip>
                        }
                        headStyle={{
                          backgroundColor: '#F6F6F6',
                          borderTopLeftRadius: '6px',
                          borderTopRightRadius: '6px',
                        }}
                        style={{
                          borderRadius: '6px',
                          border: '1px solid #C9CED1',
                          marginRight: '20px',
                          marginBottom: '20px',
                          height: '-webkit-fill-available',
                        }}
                        bordered={false}
                      >
                        <Flexrow>
                          <Flexcol
                            style={{
                              borderRight: '1px solid #eee',
                              width: ' 50%',
                            }}
                            right="20px"
                          >
                            <Text
                              style={{
                                fontSize: '14px',
                                color: '#64737F',
                                display: 'block',
                              }}
                            >
                              Starts:
                            </Text>
                            <Text
                              style={{
                                fontSize: '24px',
                                color: '#192A33',
                              }}
                            >
                              {formatDate(v.start_date)}
                            </Text>
                            <Text
                              style={{
                                fontSize: '14px',
                                color: '#64737F',
                                display: 'block',
                              }}
                            >
                              Ends:
                            </Text>
                            <Text
                              style={{
                                fontSize: '24px',
                                color: '#192A33',
                              }}
                            >
                              {formatDate(v.end_date)}
                            </Text>
                          </Flexcol>
                          <Flexcol>
                            <Text
                              style={{
                                fontSize: '16px',
                                color: '#64737F',
                                display: 'block',
                              }}
                            >
                              Total cost:
                            </Text>
                            <Text
                              style={{
                                fontSize: '14px',
                                color: '#fff',
                                visibility: 'hidden',
                              }}
                            >
                              placeholder
                            </Text>
                            <StatusTag
                              background="#cefac3"
                              borderColor="#5fdf3e"
                            >
                              <CheckCircleFill
                                style={{
                                  height: '16px',
                                  color: '#5fdf3e',
                                  paddingRight: '7px',
                                }}
                              />
                              Paid
                            </StatusTag>
                            <Text
                              style={{
                                fontSize: '24px',
                                color: '#192A33',
                              }}
                            >
                              {v.price.toLocaleString()} €
                            </Text>
                          </Flexcol>
                        </Flexrow>
                      </Card>
                    ))}
                  </Card>
                </CardWrap>
              ) : (
                <NoPlansWrap>
                  <img
                    src={NoPlansImgSource}
                    alt="no plans"
                    style={{ marginBottom: '16px' }}
                  />
                  <Text
                    style={{
                      color: '#64737f',
                      fontSize: '30px',
                      display: 'block',
                    }}
                  >
                    No next plans
                  </Text>
                </NoPlansWrap>
              )}
            </LayoutRow>
          </TabPane>
        </Tabs>
      ) : (
        <LayoutRow>
          <CardWrap top="20px">
            <Text
              style={{
                color: '#64737F',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Your current plan
            </Text>
            <div style={{ position: 'relative', margin: 'auto', width: '80%' }}>
              <Flexcol
                center_align
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                  width: 'fit-content',
                }}
              >
                <Lock
                  style={{
                    height: '100px',
                    marginBottom: '26px',
                    color: '#fff',
                  }}
                />
                <Text
                  style={{
                    color: '#fff',
                    fontSize: '20px',
                    fontWeight: '700',
                    marginBottom: '18px',
                    display: 'block',
                  }}
                >
                  You have no consumption plans yet
                </Text>
                <Text
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: '600',
                    marginBottom: '26px',
                    display: 'block',
                  }}
                >
                  Please pay to activate
                </Text>
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title={
                    currentUserRole === 'MEMBER' &&
                    'You do not have the access rights to perform this action.'
                  }
                  zIndex={99999999}
                >
                  <PayToActivateButton
                    onClick={() => openPackageCheckout()}
                    disabled={currentUserRole === 'MEMBER'}
                  >
                    Pay to activate
                  </PayToActivateButton>
                </Tooltip>
              </Flexcol>
              <img
                src={PayToActivateImgSource}
                alt="pay to activate"
                style={{
                  marginTop: '20px',
                  filter: 'brightness(0.5) blur(3px)',
                  width: '100%',
                }}
              />
            </div>
          </CardWrap>
        </LayoutRow>
      )}
    </Modal>
  );
};

export default ConsumptionModalPackage;
