import axios from 'axios';
import { createContext } from 'react';
import { v4 as uuid } from 'uuid';
import { data_placement_status_ids } from '../iso-shared/values/global';
import { AbstractWizardHelper } from './AbstractWizardHelper';
import Cookies from 'js-cookie';

const nonEmptyArrayValidator = (value) => !value.length;

export const DataRequestContext = createContext(null);

export class DataRequestWizardHelper extends AbstractWizardHelper {
  previewing = false;

  constructor(refresh, id, token, project, reset) {
    super();
    this.refresh = refresh;
    this.reset = reset;
    this.id = id;
    this.token = token;
    this.project = project;
    this.published = false;
    this.cloned = false;
    this.for_review = false;
    this.submitting = false;
  }

  onChange() {
    if (this.published && !this.cloned) {
      this.cloned = true;
      this.refresh();
    }
  }

  togglePreview = () => {
    this.previewing = !this.previewing;
    this.refresh();
  };

  


  basicInfoTrigger = this.makeApiTrigger('details', async () => {
    const token = Cookies.get('jwt');
    await axios.put(
      `/api/data-requests/editable/${this.id}`,
      {
        title: this.title.value,
        category_id: this.category.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  title = this.makeInput({
    init: '',
    required: true,
    name: 'title',
    apiTrigger: this.basicInfoTrigger,
  });

  category = this.makeInput({
    init: null,
    required: true,
    apiTrigger: this.basicInfoTrigger,
    name: 'category',
  });

  usecaseTrigger = this.makeApiTrigger('details', async () => {
    const token = Cookies.get('jwt');
    await axios.put(
      `/api/data-requests/editable/${this.id}`,
      {
        usecase_description: this.usecaseDescription.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  usecaseDescription = this.makeInput({
    init: '',
    required: true,
    name: 'usecase description',
    apiTrigger: this.usecaseTrigger,
  });

  dataSpecsTrigger = this.makeApiTrigger('dataSpecs', async () => {
    const token = Cookies.get('jwt');
    const data_specs = {
      sources: this.sources.value,
      data_volume: this.dataVolume.value,
      availability: this.availability.value,
      geo_coverage: this.geoCoverage.value,
      language: this.languages.value,
      params: this.parameters.value.map((e) => ({
        name: e.name,
        frequency: e.frequency,
      })),
    };
    await axios.put(
      `/api/data-requests/editable/${this.id}`,
      { data_specs },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  sources = this.makeInput({
    init: [],
    getCustomError: nonEmptyArrayValidator,
    name: 'data sources',
    apiTrigger: this.dataSpecsTrigger,
  });

  dataVolume = this.makeInput({
    init: '',
    name: 'data volume',
    required: true,
    apiTrigger: this.dataSpecsTrigger,
  });

  availability = this.makeInput({
    init: {},
    apiTrigger: this.dataSpecsTrigger,
  });

  geoCoverage = this.makeInput({
    init: [],
    getCustomError: nonEmptyArrayValidator,
    name: 'geo coverage',
    apiTrigger: this.dataSpecsTrigger,
  });

  languages = this.makeInput({
    init: [],
    name: 'languages',
    getCustomError: nonEmptyArrayValidator,
    apiTrigger: this.dataSpecsTrigger,
  });

  parameters = this.makeInput({
    init: [],
    apiTrigger: this.dataSpecsTrigger,
  });

  accessSpecsTrigger = this.makeApiTrigger('dataSpecs', async () => {
    const token = Cookies.get('jwt');
    const access_specs = {
      access_type: this.accessType.value,
      budget: this.budget.value,
      delivery_method: this.deliveryMethod.value,
      delivery_format: this.deliveryFormat.value,
    };

    await axios.put(
      `/api/data-requests/editable/${this.id}`,
      { access_specs },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  accessType = this.makeInput({
    init: null,
    required: true,
    name: 'access type',
    apiTrigger: this.accessSpecsTrigger,
  });

  budget = this.makeInput({
    init: '',
    required: true,
    name: 'budget',
    apiTrigger: this.accessSpecsTrigger,
  });

  deliveryMethod = this.makeInput({
    init: null,
    required: true,
    name: 'delivery method',
    apiTrigger: this.accessSpecsTrigger,
  });

  deliveryFormat = this.makeInput({
    init: [],
    getCustomError: nonEmptyArrayValidator,
    name: 'delivery format',
    apiTrigger: this.accessSpecsTrigger,
  });

  additionalInfoTrigger = this.makeApiTrigger('dataSpecs', async () => {
    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-requests/editable/${this.id}`,
      { additional_description: this.additionalInfo.value },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  additionalInfo = this.makeInput({
    init: '',
    apiTrigger: this.additionalInfoTrigger,
  });

  listingSpecsTrigger = this.makeApiTrigger('dataSpecs', async () => {
    const token = Cookies.get('jwt');

    const listing_specs = {
      is_public: this.isPublic.value,
    };
    await axios.put(
      `/api/data-requests/editable/${this.id}`,
      { listing_specs },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  isPublic = this.makeInput({
    init: false,
    apiTrigger: this.listingSpecsTrigger,
  });

  fromProject(project) {
    const { request } = project;

    this.id = request.id;
    this.project = project;

    this.cloned = !!request.clone_id;

    if (this.cloned || request.status_id == data_placement_status_ids.active) {
      this.published = true;
    }

    if (request.status_id == data_placement_status_ids.pending) {
      this.for_review = true;
    }

    this.title.value = request.title;
    this.category.value = request.category_id;
    this.usecaseDescription.value = request.usecase_description;

    const data_specs = request.data_specs || {};

    this.sources.value = (data_specs.sources && data_specs.sources) || [];
    this.dataVolume.value = data_specs.data_volume || '';
    this.availability.value = data_specs.availability || {};
    this.geoCoverage.value = data_specs.geo_coverage || [];
    this.languages.value = data_specs.language || [];
    if (!data_specs.params || !data_specs.params.length) {
      data_specs.params = [];
    }
    this.parameters.value =
      data_specs.params.map((e) => ({
        name: e.name,
        frequency: e.frequency,
        id: uuid(),
      })) || [];

    const access_specs = request.access_specs || {};

    this.accessType.value = access_specs.access_type || null;
    this.budget.value = access_specs.budget;
    this.deliveryMethod.value = access_specs.delivery_method;
    this.deliveryFormat.value = access_specs.delivery_format || [];

    const listing_specs = request.listing_specs || {};
    this.isPublic.value = !!listing_specs.is_public;
    this.additionalInfo.value = request.additional_description;
    this.inputs.forEach((e) => e.checkErrors());
  }
}
