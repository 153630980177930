import styled, { css } from 'styled-components';
import { colors } from '../../values/colors';
import { Alarm } from 'styled-icons/material';
import { timestampConverter } from '../../utils/time';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { access_key_status_ids } from '../../iso-shared/values/global';
import Cookies from 'js-cookie';
import {
  Button,
  Typography,
  Menu,
  Tag,
  message as notifyMessage,
  Row,
  Col,
  Tooltip,
} from 'antd';
import instance from '../../services/TokenValidator';

//TODO create a parent component for all cards, looks like they have same styling.
//TODO split to smaller components, lots of these are used by other cards as well.
//const { Title: ATitle } = Typography

const { SubMenu } = Menu;

const Title = styled(Typography.Title)`
  line-height: unset !important;
  margin-bottom: unset !important;
`;

const { Text } = Typography;

const SAlarmIcon = styled(Alarm)`
  color: white;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-bottom: 2px;
`;

const Wrap = styled(Col)`
  background-color: #ffffff;
  padding: 20px 28px;
  border-bottom: 2px solid ${colors['grey_1']};
  > * .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
  }
  ${(p) =>
    p.hide_arrow &&
    css`
      > * .ant-menu-submenu-arrow {
        color: transparent !important;
      }
    `}
`;

const S3AccessRequestCard = (props) => {
  const token = Cookies.get('jwt');
  const currentUserRole = Cookies.get('role');
  const { request } = props;
  const [requestName, setRequestName] = useState('');

  const timestamp = new Date(request.created_at);
  const converted_timestamp = timestampConverter(timestamp);

  const [key_status, setKeyStatus] = useState(
    request['platform_resource_access_key.status'],
  );

  const is_review = key_status == access_key_status_ids.review;

  const is_accepted =
    key_status == access_key_status_ids.pending_offline_process ||
    key_status == access_key_status_ids.active;
  const is_rejected = key_status == access_key_status_ids.rejected;

  useEffect(() => {
    const fetchOrganization = async () => {
      const res = await instance.get(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations?organizationId=${request.organization_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res.status === 200) {
        setRequestName(res.data.name);
      }
    };
    fetchOrganization();
  }, []);

  const accessRequest = (status_id) =>
    axios.put(
      '/api/s3-access-request',
      {
        id: request['platform_resource_access_key.id'],
        status: status_id,
        email: request['email'],
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

  const changeStatus = (status_id) => async () => {
    try {
      await accessRequest(status_id);
      setKeyStatus(status_id);
      notifyMessage.success('Project status updated successfully');
    } catch (error) {
      notifyMessage.error('Could not update access key status');
    }
  };

  const TitleView = (
    <Row align="middle" justify="space-between">
      <Col span={4}>
        <Title
          style={{ color: colors.orange_4, textDecoration: 'underline' }}
          level={5}
        >
          {requestName}
        </Title>
      </Col>
      <Col span={4}>
        <Tag color="#2db7f5">
          <SAlarmIcon />
          {converted_timestamp}
        </Tag>
      </Col>

      <Col span={5}>
        {is_review && (
          <Row>
            <Col>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                overlayStyle={{ width: '100%' }}
                placement="bottom"
                title={
                  currentUserRole === 'MEMBER' &&
                  'You do not have the access rights to perform this action.'
                }
              >
                <Button
                  onClick={changeStatus(
                    access_key_status_ids.pending_offline_process,
                  )}
                  type="primary"
                  disabled={currentUserRole === 'MEMBER'}
                >
                  {'Accept'}
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                overlayStyle={{ width: '100%' }}
                placement="bottom"
                title={
                  currentUserRole === 'MEMBER' &&
                  'You do not have the access rights to perform this action.'
                }
              >
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={changeStatus(access_key_status_ids.rejected)}
                  type="danger"
                  disabled={currentUserRole === 'MEMBER'}
                >
                  Reject
                </Button>
              </Tooltip>
            </Col>
          </Row>
        )}
        {is_accepted && <Title level={5}>Accepted</Title>}
        {is_rejected && <Title level={5}>Rejected</Title>}
      </Col>
    </Row>
  );
  return (
    <Wrap hide_arrow={true} span={24}>
      <Menu mode="inline">
        <SubMenu key="sub1" title={TitleView} />
      </Menu>
    </Wrap>
  );
};

export default S3AccessRequestCard;
