import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Spin, Divider } from 'antd';
import { toast } from 'react-toastify';

import { ListingContent } from '../RequestListing/ListingContent';
import LayoutRow from '../../components/LayoutRow';
import {
  DataRequestWizardHelper,
  DataRequestContext,
} from '../../helpers/DataRequestWizardHelper';
import useUser from '../../hooks/useUser';
import { colors } from '../../values/colors';
import { PublishedBasicRequestDetails } from '../../components/data-request-wizard/views/PublishedBasicRequestDetails';
import PublishedHeaderWizard from '../../components/data-request-wizard/views/PublishedHeaderWizard';
import Header from '../../components/Header';
import { Flexrow } from '../../components/Flexbox';
import Notification from '../../components/Notification';

const DataRequest = (props) => {
  const { token } = useUser();
  const [, updator] = useState({});
  const { id } = props.match.params;
  const history = useHistory();

  const [project, setProject] = useState(null);

  useEffect(() => {
    if (!project) {
      const fetcher = async () => {
        const res = await axios
          .get(`/api/data-requests/editable/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .catch((error) => {
            if (error.response.status === 404) {
              toast.error(
                <Notification type="error" text="Unauthorized Access" />,
              );
              history.push('/not-found');
            }
          });

        if (res) setProject(res.data);
      };
      fetcher();
    }
  }, [project]);

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const reset = () => setProject(null);
    const refresh = () => updator({});

    const helper = new DataRequestWizardHelper(
      refresh,
      id,
      token,
      project,
      reset,
    );
    helper.onError = (error) =>
      toast.error(<Notification type="error" text={error.message} />);
    helper.fromProject(project);

    return helper;
  }, [!!project]);

  if (!project) return <Spin />;

  return (
    <DataRequestContext.Provider value={helper}>
      <LayoutRow background={'#fff'} withShadow={true}>
        <Header
          stepsFor="PUBLISHED_DATA_REQUEST"
          isReview={helper.for_review}
        />
      </LayoutRow>
      <div style={{ backgroundColor: '#fff' }}>
        <Divider style={{ margin: '0 0 8px 0' }} />
      </div>
      <LayoutRow background={'#fff'} sticky>
        <PublishedHeaderWizard sticky name={project?.request.title} />
      </LayoutRow>
      {helper.previewing ? (
        <ListingContent helper={helper} />
      ) : (
        <LayoutRow>
          <Flexrow top="24px" />
          <PublishedBasicRequestDetails />
        </LayoutRow>
      )}
      <style>{`body { background-color: ${colors.ghost_white};}`}</style>
    </DataRequestContext.Provider>
  );
};

export default DataRequest;
