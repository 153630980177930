import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

export const TermsPubliclyAvailble = () => {
  return (
    <TermsAndConditionsOrderedList>
      <TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderLarge marginLeft smaller>
          M
        </TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>
          ARKETPLACE
        </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedList>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Certain parts of the Platform are publicly available for you to
                find out more about Mobito, the Platform and services, and
                certain data listings. You can only make available for licensing
                data as a Data Provider (i.e. the legal entity wishing to make
                use of the Platform to upload data for licensing and transfer to
                the Data Customers) or license data as a Data Customer (i.e.
                legal entity purchasing the Data offered by the Data Provider)
                when you have signed up for the Platform and accepted the
                agreement.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Mobito cannot be held liable for any incorrect information or
                any issues or problems with the data. Solely the Data Provider
                is responsible for the placement of data on the Platform and
                solely the Data Customer is responsible for its use of the data.
                Mobito only acts as a marketplace to facilitate the licensing
                and transferring of data.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                All warranties are excluded to the extent permitted by the
                applicable law. In addition, Mobito does not guarantee the
                uninterrupted availability of the Platform and its contents.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                The contents of the Platform may not be used by you to build up
                a database of your own and may not be used elsewhere in an
                already existing database. You may not resell or re-list any
                part of the data listings you find on the Platform. The linking
                of another database is expressly prohibited.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
        </TermsAndConditionsOrderedList>
      </TermsAndConditionsOrderedListItem>
      <TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderLarge marginLeft smaller>
          I
        </TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>
          NTELLECTUAL{' '}
        </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>P</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>ROPERTY</TermsAndConditionsHeaderMedium>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedList>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                All intellectual property rights, including all copyrights and
                related rights, software rights, database rights, trade secrets,
                know-how, trademarks, trade names, service marks, domain names,
                patents, design rights, and other rights, including any kinds of
                developments, amendments, customizations, documentation, data
                and testing methodologies created or used on or with the
                Platform and including the right to register or apply for any of
                the foregoing, related to the Platform shall be the exclusive
                property of Mobito or its licensors. Under no circumstances is
                there any transfer of intellectual property rights to you.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Mobito grants you a limited, non-exclusive, non-transferable,
                non-sublicensable, revocable and royalty-free right to view the
                publicly accessible parts of the Platform. You may not copy,
                translate, duplicate, store, spread or otherwise use any part of
                the Platform.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                All intellectual property rights in the Data, if any, shall
                remain vested in the Data Customer. In the event the Data is not
                subject to intellectual property rights, the Data Provider shall
                still be considered the owner of the Data. The Data Customer
                only receives a license as per the license agreement provided by
                the Data Provider.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
        </TermsAndConditionsOrderedList>
      </TermsAndConditionsOrderedListItem>
      <TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderLarge marginLeft smaller>
          D
        </TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>ATA </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>P</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>
          ROTECTION
        </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedList>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Mobito is committed to protecting personal data in a way that is
                compliant with the applicable data protection laws, such as the
                Regulation (EU) 2016 of the European Parliament and of the
                Council from 27 April 2016 on the protection of natural persons
                with regard to the processing of personal data and on the free
                movement of such data and the repeal of Directive 95/46/EC
                (GDPR). Mobito will process your personal data in accordance
                with the privacy policy and cookie policy.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
        </TermsAndConditionsOrderedList>
      </TermsAndConditionsOrderedListItem>
      <TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderLarge marginLeft smaller>
          C
        </TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>
          OMPETENT{' '}
        </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>C</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>OURT </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>ND </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>
          PPLICABLE{' '}
        </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>L</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>AW</TermsAndConditionsHeaderMedium>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedList>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                These terms and conditions shall be construed in accordance with
                and governed by the laws of Belgium.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                In the event of disputes concerning the conclusion, execution,
                termination and/or interpretation of the terms and conditions
                which cannot be resolved amicably, only the courts of Brussels
                (Dutch speaking courts) will have jurisdiction to settle the
                dispute.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
        </TermsAndConditionsOrderedList>
      </TermsAndConditionsOrderedListItem>
    </TermsAndConditionsOrderedList>
  );
};
