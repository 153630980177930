/**
 * Convert millis to time elapsed string in sec/min/hr or days
 *
 * @param {number} timestamp in millis
 * @public
 */
export const timestampConverter = (timestamp) => {
  let currentDate = Date.now();

  let time_unit = "seconds";
  let interval = (currentDate - timestamp) / 1000;

  if (interval > 60) {
    interval /= 60;
    time_unit = "minutes";
  }
  if (interval > 60 && time_unit === "minutes") {
    interval /= 60;
    time_unit = "hours";
  }
  if (interval > 24 && time_unit === "hours") {
    interval /= 24;
    time_unit = "days";
  }

  return `${Math.round(interval)} ${time_unit} ago`;
};
