import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useRef,
  createContext,
} from 'react';
import {
  Form,
  Checkbox,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Upload,
  Modal,
  Button,
  Popconfirm,
  Table,
  AutoComplete,
  Tooltip,
} from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import {
  PlusOutlined,
  InboxOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';

import TitleAndDesc from '../../wizards-components/TitleAndDesc';
import { SectionTitle } from '../../wizards-components/SectionTitle';
import SubsectionTitle from '../../wizards-components/SubsectionTitle';
import { Flexrow, Flexcol } from '../../Flexbox';
import { strings } from '../values/strings';
import {
  Container,
  SectionCardContainer,
  Desc,
} from '../../wizards-components/styled-components';
import { DataProductContext } from '../../../helpers/DataProductWizardHelper';
import { tinyMCEEditorAPIKey } from '../../../values/tinyMCEEditorAPIKey';
import { tinyInitConfig } from '../values/tinyInitConfig';
import { SampleFileRow } from '../components/SampleFileRow';
import { useStringOptions } from '../../../hooks/useStringOptions';
import {
  DATA_FORMATS,
  TYPE_OPTIONS,
  CAPTURING_AND_TRANSMISSION_DROPDOWN_OPTIONS,
} from '../../../iso-shared/values/global';
import Notification from '../../Notification';
import TrashCanWhiteSrc from '../../../images/trash_can_white.svg';

const { Text } = Typography;

const { TextArea } = Input;

const { Option } = Select;

const { Dragger } = Upload;

const dragger_children = (
  <>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Choose a file or drag it here</p>
  </>
);

const EditViewDataAttributes = styled.button`
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
`;

const CancelButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background: #344ef3 !important;
    border: 1px solid #344ef3 !important;
    color: #fff !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    background-color: #fff !important;
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    cursor: not-allowed;
  }
`;

const initial_state = () => {
  let initial_data = [];

  for (let index = 0; index < 5; index++) {
    initial_data.push({
      id: uuid(),
      key: index + 1,
      name: '',
      type: '',
      description: '',
      unit_precision: '',
      example: '',
    });
  }

  return initial_data;
};

const set_attributes = (attributes, count) => {
  const updatedAttributesLength =
    attributes.length % 5 !== 0 && 5 - (attributes.length % 5);

  for (let index = 0; index < attributes.length; index++) {
    if (attributes[index].key === undefined) {
      attributes[index].id = uuid();
      attributes[index].key = index;
    }
  }

  let updatedAttributes = [...attributes];

  if (updatedAttributesLength)
    for (let index = 0; index < updatedAttributesLength; index++) {
      updatedAttributes.push({
        id: uuid(),
        key: count + index,
        name: '',
        type: '',
        description: '',
        unit_precision: '',
        example: '',
      });
    }

  return updatedAttributes;
};

const capturing_and_transmission =
  CAPTURING_AND_TRANSMISSION_DROPDOWN_OPTIONS.map((type, index) => (
    <Option key={index} value={type}>
      {type}
    </Option>
  ));

const LightVersion = ({ isS3 }) => {
  const helper = useContext(DataProductContext);
  const [other_sources_preselect, setOtherSourcesPreselect] = useState(false);
  const [other_formats_preselect, setOtherFormatsPreselect] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [, setCount] = useState(
    !helper.attributes.value.length
      ? 6
      : helper.attributes.value.slice(-1)[0].key + 1,
  );
  const [dataSource, setDataSource] = useState(
    !helper.attributes.value.length
      ? initial_state()
      : set_attributes(
          helper.attributes.value,
          helper.attributes.value.slice(-1)[0].key + 1,
        ),
  );
  const [isValid, setIsValid] = useState(false);
  const [continents, setContinents] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const res = await axios.get('/api/countries');
      const continents = [...new Set(res.data.map((item) => item.continent))];
      setCountries(res.data);
      setContinents(continents);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const isAttributesEmpty = Object.values(dataSource).every(
      (x) => x.name === '',
    );

    for (let index = 0; index < dataSource.length; index++) {
      if (
        ((dataSource[index].type !== '' ||
          dataSource[index].description !== '' ||
          dataSource[index].unit_precision !== '' ||
          dataSource[index].example !== '') &&
          dataSource[index].name === '') ||
        isAttributesEmpty
      ) {
        return setIsValid(false);
      }
    }

    setIsValid(true);
  }, [dataSource]);

  const EditableContext = createContext(null);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    const type_options = TYPE_OPTIONS.map((type, index) => (
      <Option key={index} value={type}>
        {type}
      </Option>
    ));

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: dataIndex === 'name' && true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === 'name' ? (
            <AutoComplete
              ref={inputRef}
              filterOption
              onBlur={save}
              placeholder="Select"
            >
              {DATA_ATTRIBUTES.map((e) => (
                <AutoComplete.Option key={e} value={e}>
                  {e}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          ) : dataIndex === 'type' ? (
            <Select onChange={save} ref={inputRef}>
              {type_options}
            </Select>
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    helper.attributes.set(helper.attributes.value.filter((e) => e.key !== key));
  };

  const defaultColumns = [
    {
      title: 'ATTRIBUTE',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      editable: true,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'UNIT/PRECISION',
      dataIndex: 'unit_precision',
      editable: true,
    },
    {
      title: 'EXAMPLE',
      dataIndex: 'example',
      editable: true,
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
            zIndex={1000001}
            destroyTooltipOnHide={true}
          >
            <img src={TrashCanWhiteSrc} style={{ cursor: 'pointer' }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    let newData = [];

    for (let index = 0; index < 5; index++) {
      newData.push({
        id: uuid(),
        key: dataSource.slice(-1)[0].key + index + 1,
        name: '',
        type: '',
        description: '',
        unit_precision: '',
        example: '',
      });
    }

    setCount(dataSource.slice(-1)[0].key + 5);
    setDataSource([...dataSource, ...newData]);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    helper.setStepAccessed(2);
  }, []);

  const DATA_SOURCES = useStringOptions('data_source');
  const DATA_ATTRIBUTES = useStringOptions('data_attribute');
  const LANGUAGES = useStringOptions('languages');

  // #region data sources

  const DATA_SOURCES_SET = useMemo(() => {
    const out = new Set();
    DATA_SOURCES.forEach((e) => out.add(e));
    return out;
  }, [DATA_SOURCES]);

  const sources_set = new Set();
  const data_sources = helper.data_specs_sources.value;
  data_sources.forEach((v) => sources_set.add(v));
  const other_data_sources = data_sources.filter(
    (e) => !DATA_SOURCES_SET.has(e),
  );

  const onSourceSelect = (v, checked) => () => {
    let new_sources = [...data_sources];
    if (checked) {
      new_sources.push(v);
    } else {
      new_sources = new_sources.filter((e) => e != v);
    }
    helper.data_specs_sources.set(new_sources);
  };

  const onOtherSourcesPreselectChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setOtherSourcesPreselect(true);
    } else {
      setOtherSourcesPreselect(false);
      helper.data_specs_sources.set(
        data_sources.filter((e) => DATA_SOURCES_SET.has(e)),
      );
    }
  };

  const onOtherSourcesChange = (v) => {
    helper.data_specs_sources.set([
      ...data_sources.filter((e) => DATA_SOURCES_SET.has(e)),
      ...v,
    ]);
  };

  const other_sources_checked =
    !!other_data_sources.length || other_sources_preselect;

  //#endregion

  // #region data formats

  const DATA_FORMATS_SET = useMemo(() => {
    const out = new Set();
    DATA_FORMATS.forEach((e) => out.add(e));
    return out;
  }, [DATA_FORMATS]);

  const formats_set = new Set();
  const data_formats = helper.delivery_specs_format.value;
  data_formats.forEach((v) => formats_set.add(v));
  const other_data_formats = data_formats.filter(
    (e) => !DATA_FORMATS_SET.has(e),
  );

  const onFormatSelect = (v, checked) => () => {
    let new_formats = [...data_formats];
    if (checked) {
      new_formats.push(v);
    } else {
      new_formats = new_formats.filter((e) => e != v);
    }
    helper.delivery_specs_format.set(new_formats);
  };

  const onOtherFormatsPreselectChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setOtherFormatsPreselect(true);
    } else {
      setOtherFormatsPreselect(false);
      helper.delivery_specs_format.set(
        data_formats.filter((e) => DATA_FORMATS_SET.has(e)),
      );
    }
  };

  const onOtherFormatsChange = (v) => {
    helper.delivery_specs_format.set([
      ...data_formats.filter((e) => DATA_FORMATS_SET.has(e)),
      ...v,
    ]);
  };

  const other_formats_checked =
    !!other_data_formats.length || other_formats_preselect;

  //#endregion

  const form_fields = [
    {
      name: ['size-of-data-volume'],
      value: helper.data_specs_data_volume.value,
    },
    {
      name: ['geo-coverage'],
      value: helper.data_specs_geo_coverage.value,
    },
    {
      name: ['languages'],
      value: helper.data_specs_language.value,
    },
    {
      name: [`${`capture_key_` + helper.capture_frequency.value.id}`],
      value: helper.capture_frequency.value.key,
    },
    {
      name: [`${`capture_value_` + helper.capture_frequency.value.id}`],
      value: helper.capture_frequency.value.value,
    },
    {
      name: [`${`transmission_key_` + helper.transmission_frequency.value.id}`],
      value: helper.transmission_frequency.value.key,
    },
    {
      name: [
        `${`transmission_value_` + helper.transmission_frequency.value.id}`,
      ],
      value: helper.transmission_frequency.value.value,
    },
  ];

  helper.capture_frequency.value.forEach((e) => {
    form_fields.push({ name: [`capture_key_` + e.id], value: e.key });
    form_fields.push({
      name: [`capture_value_` + e.id],
      value: e.value ? e.value : 'Select',
    });
  });

  helper.transmission_frequency.value.forEach((e) => {
    form_fields.push({ name: [`transmission_key_` + e.id], value: e.key });
    form_fields.push({
      name: [`transmission_value_` + e.id],
      value: e.value ? e.value : 'Select',
    });
  });

  const getAvailability = (type) => helper.data_specs_availability.value[type];
  const isAvailabilityChecked = (type) => getAvailability(type) != null;

  const onAvailabilityCheck = (type) => (e) => {
    helper.data_specs_availability.set({
      ...helper.data_specs_availability.value,
      [type]: e.target.checked ? '' : null,
    });
  };

  const onAvailabilityInput = (type) => (e) => {
    helper.data_specs_availability.set({
      ...helper.data_specs_availability.value,
      [type]: e.target.value,
    });
  };

  const sampleDataUploadRequest = (p) => {
    if (p.file.size > 52428800) {
      toast.error(
        <Notification
          type="error"
          text="File must not exceed the limit of 50MB!"
        />,
      );
    } else {
      const form_data = new FormData();
      form_data.append('file', p.file);
      const entry = {
        id: p.file.uid,
        form_data,
        file_name: p.file.name,
        uploading: true,
      };
      helper.delivery_specs_sample_files.set([
        ...helper.delivery_specs_sample_files.value,
        entry,
      ]);
    }
  };

  const deleteSampleData = (e) => () => {
    helper.delivery_specs_sample_files.set(
      helper.delivery_specs_sample_files.value.filter((s) => s.id !== e.id),
    );
    axios.delete(
      `/api/data-placements/editable/${helper.id}/sample-data?file_id=${e.file_id}`,
      {
        headers: { Authorization: `Bearer ${helper.token}` },
      },
    );
  };

  const onSampleDataDescription = (e) => (v) => {
    e.edited = true;
    e.description = v;
    helper.delivery_specs_sample_files.trigger();
  };

  const handleOk = () => {
    let filteredData = [];

    for (let index = 0; index < dataSource.length; index++) {
      if (
        dataSource[index].name !== '' ||
        dataSource[index].type !== '' ||
        dataSource[index].description !== '' ||
        dataSource[index].unit_precision !== '' ||
        dataSource[index].example !== ''
      ) {
        filteredData.push(dataSource[index]);
      }
    }

    setDataSource(
      set_attributes(filteredData, filteredData.slice(-1)[0].key + 1),
    );
    helper.attributes.set(filteredData);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDataSource(
      set_attributes(
        helper.attributes.value,
        helper.attributes.value.slice(-1)[0].key + 1,
      ),
    );
  };

  useEffect(() => {
    if (!dataSource.length) setDataSource(initial_state());
  }, [dataSource.length]);

  const nameTrimHandler = (attributeNames) => {
    const maxAttributeNames = 10;

    const filteredAttributeNames = attributeNames.slice(0, maxAttributeNames);

    if (attributeNames.length > maxAttributeNames) {
      return (
        filteredAttributeNames.join(', ') +
        ' + ' +
        (attributeNames.length - maxAttributeNames) +
        ' more ...'
      );
    } else {
      return filteredAttributeNames.join(', ');
    }
  };

  const onCaptureNumber = (freq) => (e) => {
    freq.key = e.target.value;
    helper.capture_frequency.trigger();
  };

  const onCaptureValue = (freq) => (e) => {
    freq.value = e;
    helper.capture_frequency.trigger();
  };

  const onCaptureComment = (freq) => (e) => {
    freq.comment = e.target.value;
    helper.capture_frequency.trigger();
  };

  const onCaptureDelete = (freq) => () => {
    helper.capture_frequency.set(
      helper.capture_frequency.value.filter((e) => e.id != freq.id),
    );
  };

  const onAddCaptureFrequency = () => {
    helper.capture_frequency.set([
      ...helper.capture_frequency.value,
      {
        id: uuid(),
        key: '',
        value: '',
        comment: '',
      },
    ]);
  };

  const onTransmissionNumber = (trans) => (e) => {
    trans.key = e.target.value;
    helper.transmission_frequency.trigger();
  };

  const onTransmissionValue = (trans) => (e) => {
    trans.value = e;
    helper.transmission_frequency.trigger();
  };

  const onTransmissionComment = (trans) => (e) => {
    trans.comment = e.target.value;
    helper.transmission_frequency.trigger();
  };

  const onTransmissionDelete = (trans) => () => {
    helper.transmission_frequency.set(
      helper.transmission_frequency.value.filter((e) => e != trans),
    );
  };

  const onAddTransmissionFrequency = () => {
    helper.transmission_frequency.set([
      ...helper.transmission_frequency.value,
      {
        id: uuid(),
        key: '',
        value: '',
        comment: '',
      },
    ]);
  };

  useEffect(() => {
    if (!helper.capture_frequency.value.length) {
      onAddCaptureFrequency();
    }

    if (!helper.transmission_frequency.value.length) {
      onAddTransmissionFrequency();
    }
  }, []);

  const continentStringFormat = (string) => {
    let splitString = string.toLowerCase().replace('_', ' ').split(' ');

    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join(' ');
  };

  return (
    <>
      <SectionCardContainer>
        <Modal
          title={
            <Text
              style={{
                fontWeight: 600,
                color: '#182429',
                fontSize: 17,
                paddingLeft: 16,
              }}
            >
              Data attributes
            </Text>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          style={{ paddingBottom: '0px' }}
          bodyStyle={{
            backgroundColor: '#F9FAFF',
            padding: '0px',
            height: '80vh',
            overflowY: 'auto',
          }}
          width="100%"
          destroyOnClose={true}
          footer={[
            <CancelButton key="back" onClick={handleCancel}>
              Cancel
            </CancelButton>,
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              placement="top"
              title={!isValid && 'Attribute field is required'}
              zIndex={999999999}
            >
              <SubmitButton key="submit" onClick={handleOk} disabled={!isValid}>
                Save
              </SubmitButton>
            </Tooltip>,
          ]}
          zIndex={1000000}
        >
          <Flexcol style={{ padding: '16px 40px' }}>
            <Text style={{ marginBottom: 40, fontSize: 15, color: '#182429' }}>
              View and edit your data attributes. Add more rows if you wish.
            </Text>
            <div>
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
              <Button
                onClick={handleAdd}
                type="link"
                style={{
                  marginTop: 18,
                }}
              >
                <PlusOutlined /> Add 5 rows
              </Button>
            </div>
          </Flexcol>
        </Modal>
        <Form fields={form_fields}>
          <TitleAndDesc
            title={isS3 ? 'Full Version' : 'Light version'}
            desc={isS3 ? strings.full_version_desc : strings.light_version_desc}
          />
          <SectionTitle
            saving={
              helper.sections_map.dataSpecs.saving ||
              helper.sections_map.dataAttributes.saving ||
              helper.sections_map.captureFrequency.saving ||
              helper.sections_map.transmissionFrequency.saving
            }
            title="Data specs"
            tooltip={strings.data_specs_title_tooltip}
          />
          <SubsectionTitle tooltip={strings.data_specs_sources_tooltip}>
            Sources of your data
          </SubsectionTitle>
          {!!DATA_SOURCES.length && (
            <Container>
              <Row>
                {DATA_SOURCES.map((v) => (
                  <Col key={v} span={12}>
                    <Checkbox
                      checked={sources_set.has(v)}
                      key={v}
                      onChange={onSourceSelect(v, !sources_set.has(v))}
                    >
                      {v}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
              <Flexrow>
                <Checkbox
                  checked={other_sources_checked}
                  onChange={onOtherSourcesPreselectChange}
                >
                  Other
                </Checkbox>
              </Flexrow>
              {other_sources_checked && (
                <Flexrow top="8px">
                  <Select
                    value={other_data_sources}
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={onOtherSourcesChange}
                    tokenSeparators={[',']}
                  ></Select>
                </Flexrow>
              )}
            </Container>
          )}
          <Flexrow top="16px" />
          <SubsectionTitle tooltip={strings.data_specs_data_volume_tooltip}>
            Size of data volume
          </SubsectionTitle>
          <Form.Item
            name="size-of-data-volume"
            rules={[
              {
                required: true,
                message: 'Please input size of data volume!',
              },
            ]}
          >
            <Input
              onChange={(e) =>
                helper.data_specs_data_volume.set(e.target.value)
              }
              placeholder="e.g. 1M vehicles, 1M daily active app users"
            />
          </Form.Item>
          <Flexrow top="16px" />
          <SubsectionTitle tooltip={strings.data_specs_availability_tooltip}>
            Temporal availability
          </SubsectionTitle>
          <Checkbox
            checked={isAvailabilityChecked('real_time')}
            onChange={onAvailabilityCheck('real_time')}
          >
            Real-time/Near real-time
          </Checkbox>
          {isAvailabilityChecked('real_time') && (
            <>
              <Flexrow top="8px" />
              <Input
                value={getAvailability('real_time')}
                onChange={onAvailabilityInput('real_time')}
                placeholder="e.g. 4 days"
              />
            </>
          )}
          <Flexrow top="12px" />
          <Checkbox
            checked={isAvailabilityChecked('historical')}
            onChange={onAvailabilityCheck('historical')}
          >
            Historical
          </Checkbox>
          {isAvailabilityChecked('historical') && (
            <>
              <Flexrow top="8px" />
              <Input
                value={getAvailability('historical')}
                onChange={onAvailabilityInput('historical')}
                placeholder="e.g. 4 days"
              />
            </>
          )}
          <Flexrow top="12px" />
          <Checkbox
            checked={isAvailabilityChecked('forecast')}
            onChange={onAvailabilityCheck('forecast')}
          >
            Forecast
          </Checkbox>
          {isAvailabilityChecked('forecast') && (
            <>
              <Flexrow top="8px" />
              <Input
                value={getAvailability('forecast')}
                onChange={onAvailabilityInput('forecast')}
                placeholder="e.g. 4 days"
              />
            </>
          )}
          <Flexrow top="28px" />
          <SubsectionTitle tooltip={strings.data_specs_geo_coverage_tooltip}>
            Geographical coverage
          </SubsectionTitle>
          <Form.Item
            name="geo-coverage"
            rules={[
              {
                required: true,
                message: 'Please select geographical coverage!',
              },
            ]}
          >
            <Select
              mode="multiple"
              onChange={helper.data_specs_geo_coverage.set}
              style={{ width: '100%' }}
              placeholder="Select"
            >
              {continents.map((item) => (
                <Select.Option
                  key={item}
                  value={item}
                  style={{ fontSize: '14px', fontWeight: '400' }}
                >
                  {continentStringFormat(item)}
                </Select.Option>
              ))}
              {countries.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.name}
                  style={{ fontSize: '14px', fontWeight: '400' }}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Flexrow top="16px" />
          <SubsectionTitle tooltip={strings.data_specs_language_tooltip}>
            Language
          </SubsectionTitle>
          <Form.Item
            name="languages"
            rules={[{ required: true, message: 'Please select language!' }]}
          >
            <Select
              mode="multiple"
              onChange={helper.data_specs_language.set}
              style={{ width: '100%' }}
              placeholder="Select"
            >
              {LANGUAGES.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Flexrow top="16px" />
          <SubsectionTitle tooltip={strings.data_specs_params_tooltip}>
            Data attributes
          </SubsectionTitle>
          <Flexrow style={{ width: '60%' }}>
            <Text
              style={{
                color: '#182429',
                fontSize: 14,
              }}
            >
              {nameTrimHandler(
                helper.attributes.value.map((dataSpec) => dataSpec.name),
              )}
            </Text>
          </Flexrow>
          <Flexrow top="16px" />
          <EditViewDataAttributes
            type="button"
            onClick={() => setIsModalVisible(true)}
          >
            <Flexrow center_align>
              Edit/View data attributes
              <ArrowRightOutlined style={{ marginLeft: 12 }} />
            </Flexrow>
          </EditViewDataAttributes>
          <Flexrow top="24px" />
          <SubsectionTitle
            tooltip={
              <Desc pre color="#fff">
                {strings.data_frequency_tooltip}
              </Desc>
            }
          >
            Data frequency
          </SubsectionTitle>
          <Flexcol>
            <Text style={{ color: '#757575', fonrWeight: 700, fontSize: 13 }}>
              CAPTURING FREQUENCY
            </Text>
            <Flexrow top="8px" />
            <Flexrow space_between start_align bottom="8px">
              <Form.Item
                name={`capture_key_${helper.capture_frequency.value[0]?.id}`}
                rules={[
                  {
                    required: true,
                    message: 'Capturing Frequency is required!',
                  },
                ]}
                style={{ width: '25%', marginBottom: 0 }}
                validateFirst={true}
              >
                <Input
                  onChange={onCaptureNumber(helper.capture_frequency.value[0])}
                  placeholder="e.g. 1-10"
                />
              </Form.Item>
              <Form.Item
                name={`capture_value_${helper.capture_frequency.value[0]?.id}`}
                rules={[
                  {
                    required: true,
                    message: 'Capturing Frequency is required!',
                  },
                ]}
                style={{ width: '30%', marginBottom: 0 }}
                validateFirst={true}
              >
                <Select
                  onChange={onCaptureValue(helper.capture_frequency.value[0])}
                >
                  {capturing_and_transmission}
                </Select>
              </Form.Item>
              <div style={{ width: '35%' }}>
                <TextArea
                  allowClear
                  autoSize={true}
                  placeholder="Comment..."
                  onChange={onCaptureComment(helper.capture_frequency.value[0])}
                  value={helper.capture_frequency.value[0]?.comment}
                />
              </div>
              <div style={{ width: 15 }} />
            </Flexrow>
            {helper.capture_frequency.value.slice(1).map((freq, index) => (
              <Flexrow space_between start_align key={index} bottom="8px">
                <Form.Item
                  name={`capture_key_${freq.id}`}
                  rules={[
                    {
                      required: true,
                      message: 'Capturing Frequency is required!',
                    },
                  ]}
                  style={{ width: '25%', marginBottom: 0 }}
                  validateFirst={true}
                >
                  <Input
                    onChange={onCaptureNumber(freq)}
                    placeholder="e.g. Once"
                  />
                </Form.Item>
                <Form.Item
                  name={`capture_value_${freq.id}`}
                  rules={[
                    {
                      required: true,
                      message: 'Capturing Frequency is required!',
                    },
                  ]}
                  style={{ width: '30%', marginBottom: 0 }}
                  validateFirst={true}
                >
                  <Select onChange={onCaptureValue(freq)}>
                    {capturing_and_transmission}
                  </Select>
                </Form.Item>
                <div style={{ width: '35%' }}>
                  <TextArea
                    allowClear
                    autoSize={true}
                    placeholder="Comment..."
                    value={freq.comment}
                    onChange={onCaptureComment(freq)}
                  />
                </div>
                <img
                  src={TrashCanWhiteSrc}
                  style={{ cursor: 'pointer', position: 'relative', top: 8 }}
                  onClick={onCaptureDelete(freq)}
                />
              </Flexrow>
            ))}
            <Button
              type="link"
              style={{
                marginTop: 6,
                width: 'fit-content',
                padding: 0,
              }}
              onClick={onAddCaptureFrequency}
            >
              <PlusOutlined /> Add more
            </Button>
          </Flexcol>
          <Flexrow top="16px" />
          <Flexcol>
            <Text style={{ color: '#757575', fonrWeight: 700, fontSize: 13 }}>
              TRANSMISSION FREQUENCY
            </Text>
            <Flexrow top="8px" />
            <Flexrow space_between start_align bottom="8px">
              <Form.Item
                name={`transmission_key_${helper.transmission_frequency.value[0]?.id}`}
                rules={[
                  {
                    required: true,
                    message: 'Transmission Frequency is required!',
                  },
                ]}
                style={{ width: '25%', marginBottom: 0 }}
                validateFirst={true}
              >
                <Input
                  onChange={onTransmissionNumber(
                    helper.transmission_frequency.value[0],
                  )}
                  placeholder="e.g. Once"
                />
              </Form.Item>
              <Form.Item
                name={`transmission_value_${helper.transmission_frequency.value[0]?.id}`}
                rules={[
                  {
                    required: true,
                    message: 'Transmission Frequency is required!',
                  },
                ]}
                style={{ width: '30%', marginBottom: 0 }}
                validateFirst={true}
              >
                <Select
                  onChange={onTransmissionValue(
                    helper.transmission_frequency.value[0],
                  )}
                >
                  {capturing_and_transmission}
                </Select>
              </Form.Item>
              <div style={{ width: '35%' }}>
                <TextArea
                  allowClear
                  autoSize={true}
                  placeholder="Comment..."
                  onChange={onTransmissionComment(
                    helper.transmission_frequency.value[0],
                  )}
                  value={helper.transmission_frequency.value[0]?.comment}
                />
              </div>
              <div style={{ width: 15 }} />
            </Flexrow>
            {helper.transmission_frequency.value
              .slice(1)
              .map((trans, index) => (
                <Flexrow space_between start_align key={index} bottom="8px">
                  <Form.Item
                    name={`transmission_key_${trans.id}`}
                    rules={[
                      {
                        required: true,
                        message: 'Transmission Frequency is required!',
                      },
                    ]}
                    style={{ width: '25%', marginBottom: 0 }}
                    validateFirst={true}
                  >
                    <Input
                      onChange={onTransmissionNumber(trans)}
                      placeholder="e.g. Once"
                    />
                  </Form.Item>
                  <Form.Item
                    name={`transmission_value_${trans.id}`}
                    rules={[
                      {
                        required: true,
                        message: 'Transmission Frequency is required!',
                      },
                    ]}
                    style={{ width: '30%', marginBottom: 0 }}
                    validateFirst={true}
                  >
                    <Select onChange={onTransmissionValue(trans)}>
                      {capturing_and_transmission}
                    </Select>
                  </Form.Item>
                  <div style={{ width: '35%' }}>
                    <TextArea
                      allowClear
                      autoSize={true}
                      placeholder="Comment..."
                      value={trans.comment}
                      onChange={onTransmissionComment(trans)}
                    />
                  </div>
                  <img
                    src={TrashCanWhiteSrc}
                    style={{ cursor: 'pointer', position: 'relative', top: 8 }}
                    onClick={onTransmissionDelete(trans)}
                  />
                </Flexrow>
              ))}
            <Button
              type="link"
              style={{
                marginTop: 6,
                width: 'fit-content',
                padding: 0,
              }}
              onClick={onAddTransmissionFrequency}
            >
              <PlusOutlined /> Add more
            </Button>
          </Flexcol>
          <Flexrow top="16px" />
          <SectionTitle
            saving={helper.sections_map.dataDelivery.saving}
            title="Data delivery"
            tooltip={strings.delivery_specs_title_tooltip}
          />
          <SubsectionTitle
            tooltip={
              <Desc pre color="#fff">
                {strings.delivery_specs_method_tooltip}
              </Desc>
            }
          >
            Delivery method
          </SubsectionTitle>
          <Text>
            {helper.placement_type === 'api' ? 'Proxy API' : 'S3 Bucket'}
          </Text>
          <Flexrow top="16px" />
          <SubsectionTitle tooltip={strings.delivery_specs_format_tooltip}>
            Delivery format
          </SubsectionTitle>
          <Container>
            <Row>
              {DATA_FORMATS.map((v) => (
                <Col span={12} key={v}>
                  <Checkbox
                    checked={formats_set.has(v)}
                    key={v}
                    onChange={onFormatSelect(v, !formats_set.has(v))}
                  >
                    {v}
                  </Checkbox>
                </Col>
              ))}
            </Row>
            <Flexrow>
              <Checkbox
                checked={other_formats_checked}
                onChange={onOtherFormatsPreselectChange}
              >
                Other
              </Checkbox>
            </Flexrow>
            {other_formats_checked && (
              <Flexrow top="8px">
                <Select
                  value={other_data_formats}
                  style={{ width: '100%' }}
                  mode="tags"
                  onChange={onOtherFormatsChange}
                  tokenSeparators={[',']}
                ></Select>
              </Flexrow>
            )}
          </Container>
          <Flexrow top="16px" />
          <SubsectionTitle
            tooltip={strings.delivery_specs_pricing_model_tooltip}
          >
            Pricing model
          </SubsectionTitle>
          <Editor
            apiKey={tinyMCEEditorAPIKey}
            value={helper.pricing_model.value}
            onEditorChange={(v) => helper.pricing_model.set(v)}
            init={tinyInitConfig}
          />
          <Flexrow top="16px" />
          <SubsectionTitle tooltip={strings.delivery_specs_data_sample_tooltip}>
            Provide a relevant data sample
          </SubsectionTitle>
          <Desc size={14}>
            This allows to assess data compatibility with relevant requests.
          </Desc>
          <Container>
            <Dragger fileList={[]} customRequest={sampleDataUploadRequest}>
              {dragger_children}
            </Dragger>
          </Container>
          {helper.delivery_specs_sample_files.value
            .filter((e) => !e.deleted)
            .map((e) => (
              <SampleFileRow
                key={e.id}
                uploading={e.uploading}
                onDelete={deleteSampleData(e)}
                onDescriptionChange={onSampleDataDescription(e)}
                description={e.description}
                name={e.file_name}
              />
            ))}
          <Flexrow top="16px" />
          <SubsectionTitle
            tooltip={strings.delivery_specs_general_desc_tooltip}
          >
            General description
          </SubsectionTitle>
          <Desc size={14}>
            This can help you to differentiate from competion.
          </Desc>
          <Editor
            apiKey={tinyMCEEditorAPIKey}
            value={helper.general_description.value}
            onEditorChange={(v) => helper.general_description.set(v)}
            init={tinyInitConfig}
          />
        </Form>
      </SectionCardContainer>
      <style>
        {`.editable-cell {
                position: relative;
              }

              .editable-cell-value-wrap {
                padding: 5px 12px;
                cursor: pointer;
                min-height: 32px; 
              }

              .editable-row:hover .editable-cell-value-wrap {
                padding: 4px 11px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
              }

              [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
                border: 1px solid #434343;
              }

              .ant-select-dropdown {
                 z-index: 1000001 !important;
              }
        `}
      </style>
    </>
  );
};

export default LightVersion;
