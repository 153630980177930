import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Typography, Divider, Tooltip } from 'antd';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import { mutate } from 'swr';
import { toast } from 'react-toastify';

import { QuestionCircleFill } from '@styled-icons/bootstrap/QuestionCircleFill';
import { Check } from '@styled-icons/entypo/Check';

import { Flexcol, Flexrow } from '../../Flexbox';
import CVCSrc from '../../../images/cvc_card.svg';
import Notification from '../../../components/Notification';
import { AccountContext } from '../../../hooks/AccountContext';

const { Text } = Typography;

const Wrap = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const SummaryWrap = styled(Flexrow)`
  background-color: #edeffb;
  border-radius: 8px;
  margin: 22px auto 40px;
  padding: 14px 16px;
  width: 95%;
`;

const PaymentDetailsWrap = styled.div`
  margin: 22px auto 40px;
  width: 95%;
`;

const InputWrap = styled.div`
  padding: 8px;
  border: ${(props) =>
    props.error
      ? '1px solid #FD5F5D'
      : props.valid
      ? '1px solid #5FDF3E'
      : '1px solid #64737f'};
  height: 36px;
  position: relative;
  :hover,
  :focus {
    border: ${(props) =>
      props.error
        ? '1px solid #FD5F5D'
        : props.valid
        ? '1px solid #5FDF3E'
        : '1px solid #40a9ff'};
    box-shadow: ${(props) =>
      props.error
        ? ' 0px 0px 2px #FD5F5D'
        : props.valid
        ? '0px 0px 2px #5FDF3E'
        : ' 0px 0px 2px #40a9ff'};
  }
`;

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '15px',
      color: '#192A33',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#C1C2C2',
      },
    },
    invalid: {
      color: '#FD5F5D',
    },
  },
};

const only_characters = /^[a-zA-Z\s]*$/;

const Payment = (props) => {
  const {
    sourceName,
    packageName,
    months,
    totalCost,
    projectId,
    resourceConnectionId,
    request,
    setDisableButton,
    submit,
    next,
    setIsLoading,
    setPaymentResult,
    setEmail,
    minRequests,
    requestPlan,
  } = props;

  const token = Cookies.get('jwt');

  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [valid, setValid] = useState(false);
  const { primeContact } = useContext(AccountContext) || {};

  const errorHandler = (event, type) => {
    if (event.error) {
      setError((prevState) => ({
        ...prevState,
        [type]: event.error.message,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        [type]: null,
      }));
    }

    if (!event.error && event.complete && !event.empty) {
      setValid((prevState) => ({
        ...prevState,
        [type]: true,
      }));
    } else {
      setValid((prevState) => ({
        ...prevState,
        [type]: false,
      }));
    }

    if (type === 'cardHolder') {
      if (only_characters.test(event.target.value)) {
        setName(event.target.value);
        if (event.target.value.length > 0) {
          setValid((prevState) => ({
            ...prevState,
            ['cardHolder']: true,
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            ['cardHolder']: 'The cardholder’s name is incomplete.',
          }));
          setValid((prevState) => ({
            ...prevState,
            ['cardHolder']: false,
          }));
        }
      } else {
        setError((prevState) => ({
          ...prevState,
          ['cardHolder']: 'This must be letters only.',
        }));
        setValid((prevState) => ({
          ...prevState,
          ['cardHolder']: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (
      Object.keys(valid).length === 4 &&
      Object.values(valid).every((v) => v === true)
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [valid]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setDisableButton(true);

    if (!stripe || !elements) {
      setDisableButton(false);
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }
    const primeEmail = primeContact? primeContact?.email : Cookies.get('primeEmail');
    const res = await axios.post(
      requestPlan ? '/api/source-payment' : '/api/source-package-payment',
      requestPlan
        ? {
            request,
            price: totalCost,
            id: projectId,
            resource_connection_id: resourceConnectionId,
            project_name: sourceName,
          }
        : {
            request,
            price: totalCost,
            package_name: packageName,
            id: projectId,
            resource_connection_id: resourceConnectionId,
            project_name: sourceName,
          },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const clientSecret = res.data['client_secret'];

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
        billing_details: { name, email: primeEmail },
      },
    });

    setEmail(primeEmail);

    if (result.error) {
      setDisableButton(false);
      setIsLoading(false);
      setPaymentResult(result.error.message);
      next();
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setDisableButton(false);
        setIsLoading(false);
        next();
        setPaymentResult('succeeded');
        await axios.post(
          '/api/source',
          {
            resource_connection_id: resourceConnectionId,
            project_name: sourceName,
          },
          { headers: { Authorization: `Bearer ${token}` } },
        );
      }
    }

    setTimeout(() => {
      try {
        mutate([
          `/api/data-consumption-metadata?project_id=${projectId}`,
          token,
        ]);
      } catch (error) {
        if (error?.response.status === 404) {
          toast.error(<Notification type="error" text="Unauthorized Access" />);
          history.push('/not-found');
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if (submit) handleSubmit();
  }, [submit]);

  return (
    <Wrap>
      <SummaryWrap center_align space_between>
        <Flexcol>
          <Text
            style={{
              color: '#192A33',
              fontSize: '16px',
              fontWeight: '700',
              marginBottom: '18px',
            }}
          >
            Summary
          </Text>
          <Text
            style={{ color: '#192A33', fontSize: '14px', fontWeight: '600' }}
          >
            Data product:{' '}
            <Text style={{ fontWeight: '400' }}>{sourceName}</Text>
          </Text>
          <Text
            style={{ color: '#192A33', fontSize: '14px', fontWeight: '600' }}
          >
            Plan:{' '}
            <Text style={{ fontWeight: '400' }}>
              {requestPlan ? 'Pricing per request' : packageName}
            </Text>
          </Text>
          <Text
            style={{ color: '#192A33', fontSize: '14px', fontWeight: '600' }}
          >
            {requestPlan ? 'Requests' : 'Months'}:{' '}
            <Text style={{ fontWeight: '400' }}>
              {requestPlan ? minRequests : months}
            </Text>
          </Text>
        </Flexcol>
        <Flexrow center_align space_between right="30px">
          <Divider
            type="vertical"
            style={{ height: '100px', borderLeft: '1px solid#64737f' }}
          />
          <Flexcol end_align left="50px">
            <Text
              style={{
                color: '#192A33',
                fontSize: '13px',
                fontWeight: '700',
              }}
            >
              Total cost:
            </Text>
            <Text
              style={{
                color: '#192A33',
                fontSize: '22px',
              }}
            >
              {totalCost.toLocaleString('el-GR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              €
            </Text>
          </Flexcol>
        </Flexrow>
      </SummaryWrap>
      <PaymentDetailsWrap>
        <Text
          style={{
            color: '#192A33',
            fontSize: '16px',
            fontWeight: '700',
            display: 'block',
            marginBottom: '18px',
          }}
        >
          Payment details
        </Text>
        <Flexrow space_between>
          <Flexcol style={{ width: '60%' }} bottom="12px">
            <Text
              style={{
                color: '#192A33',
                fontSize: '11px',
                fontWeight: '600',
                marginBottom: '5px',
              }}
            >
              CARD NUMBER
            </Text>
            <InputWrap
              error={error && error['cardNumber']}
              valid={valid && valid['cardNumber']}
            >
              <CardNumberElement
                options={ELEMENT_OPTIONS}
                onChange={(e) => errorHandler(e, e.elementType)}
              />
              {valid && valid['cardNumber'] && (
                <Check
                  style={{
                    height: '18px',
                    marginRight: '10px',
                    color: '#5FDF3E',
                    position: 'absolute',
                    right: '-38px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              )}
            </InputWrap>
            {error && (
              <Text style={{ color: '#FD5F5D', fontSize: '12px' }}>
                {error['cardNumber']}
              </Text>
            )}
          </Flexcol>
          <Flexcol style={{ width: '25%' }} right="30px">
            <Text
              style={{
                color: '#192A33',
                fontSize: '11px',
                fontWeight: '600',
                marginBottom: '5px',
              }}
            >
              EXPIRES
            </Text>
            <InputWrap
              error={error && error['cardExpiry']}
              valid={valid && valid['cardExpiry']}
            >
              <CardExpiryElement
                options={ELEMENT_OPTIONS}
                onChange={(e) => errorHandler(e, e.elementType)}
              />
              {valid && valid['cardExpiry'] && (
                <Check
                  style={{
                    height: '18px',
                    marginRight: '10px',
                    color: '#5FDF3E',
                    position: 'absolute',
                    right: '-38px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              )}
            </InputWrap>
            {error && (
              <Text style={{ color: '#FD5F5D', fontSize: '12px' }}>
                {error['cardExpiry']}
              </Text>
            )}
          </Flexcol>
        </Flexrow>
        <Flexcol>
          <Flexrow space_between>
            <Flexcol style={{ width: '60%' }}>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '11px',
                  fontWeight: '600',
                  marginBottom: '5px',
                }}
              >
                CARDHOLDER
              </Text>
              <div style={{ position: 'relative' }}>
                <input
                  type="text"
                  placeholder="e.g. JOHN DOE"
                  onChange={(e) => errorHandler(e, 'cardHolder')}
                  style={{
                    fontSize: '15px',
                    height: '36px',
                    padding: '8px',
                    width: '100%',
                    border:
                      error && error['cardHolder']
                        ? '1px solid #FD5F5D'
                        : valid && valid['cardHolder']
                        ? '1px solid #5FDF3E'
                        : '1px solid #64737f',
                  }}
                />
                {valid && valid['cardHolder'] && (
                  <Check
                    style={{
                      height: '18px',
                      marginRight: '10px',
                      color: '#5FDF3E',
                      position: 'absolute',
                      right: '-38px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                )}
              </div>
              {error && (
                <Text style={{ color: '#FD5F5D', fontSize: '12px' }}>
                  {error['cardHolder']}
                </Text>
              )}
            </Flexcol>
            <Flexcol style={{ width: '25%' }} right="30px">
              <Flexrow>
                <Text
                  style={{
                    color: '#192A33',
                    fontSize: '11px',
                    fontWeight: '600',
                    marginBottom: '5px',
                  }}
                >
                  CVC
                </Text>
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title="The three- or four-digit number printed directly on the credit card, usually on the signature strip or the front of the card"
                  zIndex={999999999}
                >
                  <QuestionCircleFill
                    style={{
                      color: '#C1C2C2',
                      height: '16px',
                      marginLeft: '10px',
                    }}
                  />
                </Tooltip>
              </Flexrow>
              <InputWrap
                error={error && error['cardCvc']}
                valid={valid && valid['cardCvc']}
              >
                <CardCvcElement
                  options={ELEMENT_OPTIONS}
                  onChange={(e) => errorHandler(e, e.elementType)}
                />
                <img
                  src={CVCSrc}
                  style={{
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
                {valid && valid['cardCvc'] && (
                  <Check
                    style={{
                      height: '18px',
                      marginRight: '10px',
                      color: '#5FDF3E',
                      position: 'absolute',
                      right: '-38px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                )}
              </InputWrap>
              {error && (
                <Text style={{ color: '#FD5F5D', fontSize: '12px' }}>
                  {error['cardCvc']}
                </Text>
              )}
            </Flexcol>
          </Flexrow>
        </Flexcol>
      </PaymentDetailsWrap>
      <style>
        {`
        input::placeholder { color: #C1C2C2; font-size: 15px; letter-spacing: 0.025em; }
        input:hover, input:focus { border: ${
          error && error['cardHolder']
            ? '1px solid #FD5F5D !important; box-shadow: 0px 0px 2px #FD5F5D;'
            : valid && valid['cardHolder']
            ? '1px solid #5FDF3E !important; box-shadow: 0px 0px 2px #5FDF3E;'
            : '1px solid #40a9ff !important; box-shadow: 0px 0px 2px #40a9ff;'
        }
        `}
      </style>
    </Wrap>
  );
};

export default Payment;
