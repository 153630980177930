import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const Term = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        T
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ERM</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsListSection paddingLeft>
        <TermsAndConditionsParagraph>
          The Agreement is concluded for (i) the term as set out in the Data
          Agreement or in the Order Form or (ii) if no term is set out in the
          Data Agreement or Order Form, for an indefinite period as from the
          moment of acceptance of this Agreement. Unless otherwise indicated in
          an Order Form, each party shall be entitled to terminate the Agreement
          (i) at any time by providing three (3) months prior written notice of
          its intention to terminate the Agreement, or (ii) in accordance with
          article 10 (Termination).
        </TermsAndConditionsParagraph>
      </TermsAndConditionsListSection>
    </TermsAndConditionsOrderedListItem>
  );
};

export default Term;
