import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsHeaderSmall,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const MarkeplaceAndServicesTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        M
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ARKETPLACE
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        A
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ND</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        S
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ERVICES</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsHeaderSmall>General</TermsAndConditionsHeaderSmall>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              By making Data available on the Platform, the Data Provider grants
              the right to Mobito to list the Data as part of the Platform
              Services as set out in article 2.5, and to provide the Data
              Preparation Services to the Data Customer as set out in article
              2.8.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito shall provide any of the Services in complete independence
              and shall plan its activities as it sees fit.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Platform is provided ‘as-is’, therefore with all visible and
              hidden defects. All warranties relating to the Platform and
              Services are excluded to the extent permitted by the applicable
              law. Mobito does not warrant that the Data and any other
              information provided by Mobito on the Platform is up-to-date,
              complete and correct and does not guarantee the uninterrupted
              availability of the Platform and its contents, nor that the
              Platform is error free. Mobito shall undertake best efforts to
              keep the Data secure on the Platform and shall use the appropriate
              security practices and systems if hosting, storing, or using (in
              accordance with this Agreement) the Data in any way. For the
              avoidance of doubt, Mobito cannot be held liable for the
              authenticity, accuracy, completeness, or correctness of Data.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Customer is solely responsible for any compliance with
              the Data Agreement, or use it makes of the Data. Mobito cannot be
              held liable for any misuse, abuse or unauthorized access to Data
              or use of the Data by the Data Customer or third parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderSmall>
          Platform Services
        </TermsAndConditionsHeaderSmall>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito developed the Platform as a marketplace for the purpose of
              (i) facilitating the licensing of external Data for the Data
              Customer, and (ii) providing the Data Customer access to Data from
              the Data Provider.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito aspires to be a neutral marketplace in order to ensure the
              licensing of Data for the Data Customer and the selling of Data
              for the Data Provider. Therefore, Mobito may provide Data quality
              checks to make sure the Data is in accordance with the
              requirements as set out in article 4.4 (without, however, being
              obligated to check the Data content). Mobito can also provide an
              option for user generated reviews of the Data Listings by the Data
              Customer or Mobito and may include other elements that support the
              exchange of high-quality data between Data Providers and Data
              Customers.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              For Data to be accessed through the Platform, where applicable,
              the Data Customer will first be required to enter into a Data
              Agreement with the Data Provider. The Data Provider can opt to
              approve or reject the transfer of Data to a particular Data
              Customer.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderSmall>
          Data Preparation Services
        </TermsAndConditionsHeaderSmall>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Preparation Services may be requested by the Data
              Customer to pre-process, analyse and generate derivative insights
              from one or multiple Data sources. In order for Mobito to provide
              the Data Preparation Services, the Data Provider grants Mobito the
              right to license and buy the Data, upon request, to prepare and
              resell all or any portion of the Data to the Data Customer, by
              whatever means. If the Data Provider wishes to impose any
              limitations on such reselling of the Data by Mobito, it must do so
              in writing in an agreement concluded between the parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderSmall>
          Promotional Services
        </TermsAndConditionsHeaderSmall>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Promotional Services may be requested by the Data
              Provider in order to promote the Data.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsEmptySpace />
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default MarkeplaceAndServicesTerms;
