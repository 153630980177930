import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ContentStepperHeader from '../ContentStepperHeader';
import ProductDescription from './ProductDescription';
import { Attributes } from '../components/Attributes';
import DatasetProperties from '../components/DatasetProperties';
import { Attachments } from '../components/Attachments';

import SelectItemsExplanation from '../clarifications/SelectItemsExplanation';
import Drawer from '../clarifications/Drawer';
import { datasetDetailsMapper } from '../helpers/dataset-details-helper';
import { selectedItemsMapper } from '../helpers/selected-items-mapper';
import {
  markSelectedItems,
  markSelectedAttributes,
} from '../helpers/mark-selected-items';
import { byTitle } from '../helpers/sort-by-utils';
import { GeocoverageOverall } from '../components/GeocoverageOverall';
import Cookies from 'js-cookie';

const initial_state = {
  Attributes: [],
  Geocoverage: [],
  DatasetProperties: [],
  Attachments: [],
  GeneralAttributes: [],
  GeneralGeocoverage: [],
  GeneralDatasetProperties: [],
};

const DatasetDetails = (props) => {
  const { title, selectable, readOnly, headline, description, userType } =
    props;
  const { data_room_id } = useParams();
  const [datasetDetails, setDatasetDetails] = useState({});
  // Mapped discussion threads using selectedItemsMapper
  const [discussionThreads, setDiscussionThreads] = useState(initial_state);
  const [refresh, setRefresh] = useState(false);

  //Attributes state
  const [generalAttributes, setGeneralAttributes] = useState([]);
  const [attributesSelections, setAttributesSelections] = useState([]);

  //Geocoverage state
  const [generalGeocoverage, setGeneralGeocoverage] = useState([]);
  const [geocoverageSelections, setGeocoverageSelections] = useState([]);
  const [geoDiscussionThreadsIds, setGeoDiscussionThreadsIds] = useState([]);
  const [geocoverageCheckedState, setGeocoverageCheckedState] = useState([]);

  //DatasetProperties state
  const [generalDatasetProperties, setGeneralDatasetProperties] = useState([]);
  const [datasetPropertiesSelections, setDatasetPropertiesSelections] =
    useState([]);
  const [datasetPropertiesCheckedState, setDatasetPropertiesCheckedState] =
    useState([]);
  const [
    datasetPropertiesDiscussionThreadsIds,
    setDatasetPropertiesDiscussionThreadsIds,
  ] = useState([]);

  //Attachments State
  const [generalAttachments, setGeneralAttachments] = useState([]);
  const token = Cookies.get('jwt');

  useEffect(() => {
    const fetcher = async () => {
      setDatasetDetails(await datasetDetailsMapper(data_room_id));
      setDiscussionThreads(await selectedItemsMapper(token, data_room_id));
    };
    fetcher().catch(console.error);
  }, [refresh]);

  useEffect(() => {
    setAttributesSelections(
      markSelectedAttributes(
        discussionThreads.Attributes,
        datasetDetails.attributes,
      ),
    );

    setGeocoverageSelections(
      markSelectedItems(
        discussionThreads.Geocoverage,
        datasetDetails.geocoverage,
      ).sort(byTitle),
    );

    setDatasetPropertiesSelections(
      markSelectedItems(
        discussionThreads.DatasetProperties,
        datasetDetails.datasetProperties,
      ),
    );

    setGeneralAttributes(discussionThreads.GeneralAttributes);
    setGeneralAttachments(discussionThreads.Attachments);
    setGeneralGeocoverage(discussionThreads.GeneralGeocoverage);
    setGeneralDatasetProperties(discussionThreads.GeneralDatasetProperties);
  }, [discussionThreads, datasetDetails]);

  useEffect(() => {
    if (geocoverageSelections) {
      setGeocoverageCheckedState(
        geocoverageSelections?.map((item) => item.checked),
      );
      setGeoDiscussionThreadsIds(
        geocoverageSelections?.map((item) => item.discussionThreadId),
      );
    }
  }, [geocoverageSelections]);

  useEffect(() => {
    if (datasetPropertiesSelections) {
      setDatasetPropertiesCheckedState(
        datasetPropertiesSelections?.map((item) => item.checked),
      );
      setDatasetPropertiesDiscussionThreadsIds(
        datasetPropertiesSelections?.map((item) => item.discussionThreadId),
      );
    }
  }, [datasetPropertiesSelections]);

  return (
    <>
      <ContentStepperHeader title={title} />
      {selectable ? (
        <SelectItemsExplanation headline={headline} description={description} />
      ) : (
        <ProductDescription
          productDescription={datasetDetails.productDescription}
        />
      )}
      <Attributes
        selectable={selectable}
        readOnly={readOnly}
        generalSelections={generalAttributes}
        attributesSelections={attributesSelections}
        dataRoomId={data_room_id}
        setRefresh={setRefresh}
      />
      <GeocoverageOverall
        selectable={selectable}
        readOnly={readOnly}
        generalSelections={generalGeocoverage}
        geocoverageSelections={geocoverageSelections}
        dataRoomId={data_room_id}
        setRefresh={setRefresh}
        geoDiscussionThreadsIds={geoDiscussionThreadsIds}
        geocoverageCheckedState={geocoverageCheckedState}
      />
      <DatasetProperties
        selectable={selectable}
        readOnly={readOnly}
        generalSelections={generalDatasetProperties}
        datasetPropertiesSelections={datasetPropertiesSelections}
        dataRoomId={data_room_id}
        datasetPropertiesCheckedState={datasetPropertiesCheckedState}
        datasetPropertiesDiscussionThreadsIds={
          datasetPropertiesDiscussionThreadsIds
        }
        setRefresh={setRefresh}
      />
      <Attachments
        selectable={selectable}
        readOnly={readOnly}
        generalSelections={generalAttachments}
        attachments={datasetDetails.attachments}
        dataRoomId={data_room_id}
        setRefresh={setRefresh}
      />
      {selectable && (
        <Drawer
          discussionThreads={discussionThreads}
          dataRoomId={data_room_id}
          userType={userType}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default DatasetDetails;
