import React, { useState, useEffect } from 'react';
import { Button, Divider, Tabs, Typography } from 'antd';
import { strings } from '../assets/strings';
import { Flexrow } from '../../Flexbox';
import { CreateRequestStep } from './CreateRequestStep';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './Tabs.css';

const { TabPane } = Tabs;
const { Title } = Typography;

export const RequestWizard = ({ onCancelRequest }) => {
  const [activeKey, setActiveKey] = useState('1');
  const [tabs, setTabs] = useState([]);
  const [headerTitle, setHeaderTitle] = useState(
    strings.create_request_wizard.header,
  );

  const onChangeTab = (activeKey) => {
    setActiveKey(activeKey);
    setHeaderTitle(tabs[parseInt(activeKey) - 1]?.name);
  };

  const handleOnCancelModal = () => {
    onCancelRequest();
  };

  useEffect(() => {
    const tabsData = [
      {
        name: strings.create_request_wizard.header,
        key: '1',
        content: <CreateRequestStep goToTab={onChangeTab}></CreateRequestStep>,
      },
      {
        name: (
          <>
            <ArrowLeftOutlined />
            {strings.create_request_wizard.geocoverage.header}
          </>
        ),
        key: '2',
        content: 'Content of Add location',
      },
      {
        name: (
          <>
            <ArrowLeftOutlined />
            {strings.create_request_wizard.attributes.header}
          </>
        ),
        key: '3',
        content: 'Content of Add location',
      },
      {
        name: (
          <>
            <ArrowLeftOutlined />
            {strings.create_request_wizard.volume.header}
          </>
        ),
        key: '4',
        content: 'Content of Add location',
      },
      {
        name: (
          <>
            <ArrowLeftOutlined />
            {strings.create_request_wizard.timeframes.header}
          </>
        ),
        key: '5',
        content: 'Content of Add location',
      },
    ];
    setTabs(tabsData);
  }, [tabs.length]);

  return (
    <>
      <Flexrow className="wizard-header">
        <Title
          level={5}
          style={{
            margin: '-20px 0 10px',
            color: '#182429',
          }}
        >
          <span
            style={{ cursor: 'pointer' }}
            onClick={
              activeKey === '1'
                ? undefined
                : () => onChangeTab((activeKey - 1).toString())
            }
          >
            {headerTitle}
          </span>
        </Title>
      </Flexrow>
      <Divider style={{ margin: 0 }} />
      {tabs.length > 0 && (
        <Tabs
          onChange={onChangeTab}
          activeKey={activeKey}
          className="wizard-tabs"
        >
          {tabs.map(({ name, key, content }) => (
            <TabPane tab={name} key={key}>
              {content}
            </TabPane>
          ))}
        </Tabs>
      )}
      <Divider />
      <Flexrow className="wizard-actions">
        <Button
          type="default"
          style={{ marginLeft: 'auto' }}
          onClick={handleOnCancelModal}
        >
          {strings.create_request_wizard.cancel}
        </Button>
        <Button type="primary" disabled style={{ marginLeft: '25px' }}>
          {strings.create_request_wizard.create}
        </Button>
      </Flexrow>
    </>
  );
};
