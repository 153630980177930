import React, { useContext } from "react";
import { CloudCheck } from "@styled-icons/bootstrap/CloudCheck";
import { Extension } from "@styled-icons/material/Extension";
import styled from "styled-components";

import { Flexcol, Flexrow } from "../../../../components/Flexbox";
import { Chip } from "../../../../components/listing-components/Chip";
import ItemCard from "../../../../components/listing-components/ItemCard";
import { DataRequestContext } from "../../../../helpers/DataRequestWizardHelper";

const Chips = styled(Flexrow)`
  flex-wrap: wrap;
`;

export const IntegrationDetailsTab = () => {
  const helper = useContext(DataRequestContext);

  const delivery_format = helper.deliveryFormat.value;
  const delivery_method = helper.deliveryMethod.value;

  return (
    <Flexcol>
      {delivery_method && (
        <ItemCard title="Delivery method" icon={<CloudCheck />}>
          {delivery_method == "_api_" ? "API" : "File sharing"}
        </ItemCard>
      )}
      {!!delivery_format.length && (
        <ItemCard title="Format" icon={<Extension />}>
          <Chips>
            {delivery_format.map((v) => (
              <Chip key={v}>{v}</Chip>
            ))}
          </Chips>
        </ItemCard>
      )}
    </Flexcol>
  );
};
