import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Typography, Form, Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { Cancel } from '@styled-icons/material/Cancel';

import FormLayout from '../components/FormLayout';
import { Flexcol, Flexrow } from '../components/Flexbox';
import SubsectionTitle from '../components/wizards-components/SubsectionTitle';
import validate from '../components/validators/ForgotPasswordWizardValidator';

const { Text } = Typography;

const default_data = {
  password: '',
  password_repeat: '',
};

const ResetPasswordButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 36px;
  background-color: #344ef3;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: none;
  &:disabled {
    background-color: #fff;
    color: #c1c2c2;
    border: 1px solid #f3f3f4;
    cursor: not-allowed;
  }
  &:hover,
  :active,
  :focus {
    background-color: #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
    color: #fff;
  }
`;

const TogglePasswordButton = styled(Button)`
  position: absolute;
  top: -30px;
  right: 0;
  border: 0;
  box-shadow: none;
  padding: 0;
  height: initial;
  color: #344ef3;
  font-size: 13px;
  width: 60px;
  &:hover,
  :active,
  :visited,
  :focus {
    color: #344ef3;
    background: #fff;
  }
`;

const PasswordList = styled.ul`
  padding-inline-start: 16px;
  color: #344ef3;
`;

const PasswordMessageWrap = styled.div`
  background-color: ${(props) => props.background && props.background};
  color: #64737f;
  margin-top: -20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
`;

export const PasswordRecovery = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false);
  const [form_data, setForm] = useState(default_data);
  const [errors, setErrors] = useState({});
  const { verification_token } = useParams();
  const history = useHistory();
  const step_id = 'password';

  const handleSubmit = async (password) => {
    setErrors(validate(form_data, step_id));

    if (Object.keys(errors).length === 1) {
      const response = await axios.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/auth/password/recover/${verification_token}`,
        { password },
      );

      response.status === 200 && history.push('/password-updated');
    }
  };

  const onInputChange = (event) => {
    setForm({
      ...form_data,
      [event.target.name]: event.target.value,
    });
  };

  const checkPasswordErrors = (value) => {
    let isValid = false;
    if (errors) {
      errors.passwordErrors.forEach((error) => {
        if (error === value) {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  let hasPasswordErrors = !!errors && errors.passwordErrors?.length > 0;
  let hasRepeatPasswordError = !!errors && errors.password_repeat;
  let hasError =
    !!errors && Object.keys(errors).length === 1 && !hasPasswordErrors;

  useEffect(() => {
    setErrors(validate(form_data, step_id));
  }, [form_data]);

  return (
    <FormLayout
      title="Welcome to Mobito"
      subtitle="Your data ecosystem partner"
    >
      <Flexcol>
        <Text
          style={{
            color: '#182429',
            fontSize: '21px',
            textAlign: 'center',
            marginTop: '31px',
          }}
        >
          Reset your password
        </Text>
        <Text
          style={{
            color: '#64737f',
            fontSize: '15px',
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          Enter your new password to recover your account.
        </Text>
        <Form>
          <SubsectionTitle tooltip="Account password">Password</SubsectionTitle>
          <Form.Item>
            <div style={{ position: 'relative' }}>
              <Input
                name="password"
                value={form_data.password}
                type={showPassword ? 'text' : 'password'}
                placeholder="Type your password"
                onChange={onInputChange}
                size="large"
                style={{
                  borderRadius: '4px',
                  border: '1px solid #c4c4c4',
                }}
              />
              <TogglePasswordButton
                onClick={() => setShowPassword(!showPassword)}
                type="text"
              >
                {showPassword ? (
                  <span>
                    <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
                  </span>
                ) : (
                  <span>
                    <EyeOutlined style={{ color: '#344ef3' }} /> Show
                  </span>
                )}
              </TogglePasswordButton>
            </div>
          </Form.Item>
          {hasPasswordErrors ? (
            <Flexrow>
              <PasswordList style={{ marginRight: '110px' }}>
                <li
                  style={{ color: !checkPasswordErrors('upper') && '#c1c2c2' }}
                >
                  <Text
                    style={{
                      color: checkPasswordErrors('upper')
                        ? '#182429'
                        : '#c1c2c2',
                      fontSize: '13px',
                    }}
                  >
                    One uppercase character
                  </Text>
                </li>
                <li
                  style={{
                    color: !checkPasswordErrors('special') && '#c1c2c2',
                  }}
                >
                  <Text
                    style={{
                      color: checkPasswordErrors('special')
                        ? '#182429'
                        : '#c1c2c2',
                      fontSize: '13px',
                    }}
                  >
                    One special character
                  </Text>
                </li>
              </PasswordList>
              <PasswordList>
                <li style={{ color: !checkPasswordErrors('num') && '#c1c2c2' }}>
                  <Text
                    style={{
                      color: checkPasswordErrors('num') ? '#182429' : '#c1c2c2',
                      fontSize: '13px',
                    }}
                  >
                    One number
                  </Text>
                </li>
                <li
                  style={{ color: !checkPasswordErrors('eight') && '#c1c2c2' }}
                >
                  <Text
                    style={{
                      color: checkPasswordErrors('eight')
                        ? '#182429'
                        : '#c1c2c2',
                      fontSize: '13px',
                    }}
                  >
                    8 characters minimum
                  </Text>
                </li>
              </PasswordList>
            </Flexrow>
          ) : (
            <PasswordMessageWrap background="#cefac3">
              <CheckCircleFill
                style={{
                  height: '16px',
                  color: '#5fdf3e',
                  paddingRight: '8.5px',
                }}
              />
              Nice! Your password is strong and secure.
            </PasswordMessageWrap>
          )}
          <SubsectionTitle tooltip="Repeat password">
            Verify password
          </SubsectionTitle>
          <Form.Item>
            <div style={{ position: 'relative' }}>
              <Input
                name="password_repeat"
                value={form_data.password_repeat}
                type={showRepeatPassword ? 'text' : 'password'}
                placeholder="Type again your password"
                onChange={onInputChange}
                onBlur={() => setRepeatPasswordTouched(true)}
                size="large"
                style={{
                  borderRadius: '4px',
                  border: '1px solid #c4c4c4',
                }}
              />
              <TogglePasswordButton
                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                type="text"
              >
                {showRepeatPassword ? (
                  <span>
                    <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
                  </span>
                ) : (
                  <span>
                    <EyeOutlined style={{ color: '#344ef3' }} /> Show
                  </span>
                )}
              </TogglePasswordButton>
            </div>
          </Form.Item>
          {!hasPasswordErrors &&
            hasRepeatPasswordError &&
            repeatPasswordTouched && (
              <PasswordMessageWrap background="#ffe9e9">
                <Cancel
                  style={{
                    height: '16px',
                    color: '#fd5f5d',
                    paddingRight: '8.5px',
                  }}
                />
                Oops! Passwords do not match.
              </PasswordMessageWrap>
            )}
        </Form>
        <ResetPasswordButton
          onClick={() => handleSubmit(form_data.password)}
          disabled={!hasError}
          size="large"
        >
          Reset password
        </ResetPasswordButton>
      </Flexcol>
    </FormLayout>
  );
};
