import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Typography, Input, Select, Button, Form, Divider } from 'antd';
import Cookies from 'js-cookie';
import { mutate } from 'swr';
import { toast } from 'react-toastify';

import { UndoOutlined } from '@ant-design/icons';

import { AccountContext } from '../hooks/AccountContext';
import Notification from '../components/Notification';
import { Flexcol, Flexrow } from '../components/Flexbox';
import BackArrowSrc from '../images/back_arrow.svg';
import SubsectionTitle from '../components/wizards-components/SubsectionTitle';
import { COUNTRIES, INDUSTRIES } from '../iso-shared/values/global';
import instance from '../services/TokenValidator';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const Wrap = styled(Form)`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 32px;
  height: fit-content;
  width: 65%;
  margin-top: 26px;
`;

const SaveButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const country_options = COUNTRIES.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

const industry_options = INDUSTRIES.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

const required_rule = { required: true, message: 'Required field' };
const no_empty_space_and_min_length_rule = {
  pattern: /(.*[a-z]){3}/i,
  message: 'The value must be at least three characters long',
};

export const CompanyAccountDetails = (p) => {
  const { user, setIsUpdated, setCanSave, canSave, reset, onSetTab } = p;
  const organizations = user.organization;
  const { account, setAccount, setIsComplete } = useContext(AccountContext);
  const [updates, updator] = useState({});
  const [saving, setSaving] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [form] = Form.useForm();
  const token = Cookies.get('jwt');
  const currentUserRole = Cookies.get('role');
  const {
    company_name,
    company_address,
    country,
    other_country,
    vat,
    industry,
    other_industry,
    description,
  } = form.getFieldsValue();

  useEffect(() => {
    let industry;
    let other_industry = '';
    let country;
    let other_country = '';

    if (INDUSTRIES.includes(organizations.industry)) {
      industry = organizations.industry;
    } else if (!organizations.industry) {
      industry;
    } else {
      industry = 'Other';
      other_industry = organizations.industry;
    }

    if (COUNTRIES.includes(organizations.country)) {
      country = organizations.country;
    } else if (!organizations.country) {
      country;
    } else {
      country = 'Other';
      other_country = organizations.country;
    }

    form.setFieldsValue({
      company_name: organizations.name,
      company_address: organizations.address,
      country,
      other_country,
      vat: organizations.vat,
      industry,
      other_industry,
      description: organizations.description ? organizations.description : '',
    });
    updator({});

    setIsOther({
      industry: industry === 'Other',
      country: country === 'Other',
    });

    if (
      !organizations.name ||
      !organizations.address ||
      !organizations.country ||
      !organizations.vat
    )
      toast.warning(
        <Notification
          type="warning"
          text="You will need to fill in your company account details."
        />,
      );
  }, []);

  const can_save =
    updates.changed &&
    company_name &&
    company_address &&
    vat &&
    country &&
    (country !== 'Other' || other_country) &&
    (company_name !== organizations.name ||
      company_address !== organizations.address ||
      vat !== organizations.vat ||
      description !== organizations.description ||
      (country !== 'Other'
        ? country !== organizations.country
        : other_country !== organizations.country) ||
      (industry !== 'Other'
        ? industry !== organizations.industry
        : other_industry !== organizations.industry));

  useEffect(() => {
    setIsUpdated((prevState) => ({ ...prevState, profile: can_save }));
  }, [can_save]);

  useEffect(() => {
    if (currentUserRole === 'MEMBER')
      toast.info(
        <Notification
          type="info"
          text="You do not have the access rights to perform any action."
        />,
      );
  }, []);

  const onSave = async () => {
    setSaving(true);
    const update_obj = {
      name: company_name,
      address: company_address,
      vat,
      description,
      industry: industry != 'Other' ? industry : other_industry,
      country: country != 'Other' ? country : other_country,
    };
    const deploymentDomain =
      process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';
    try {
      await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations`,
        update_obj,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      mutate([`${process.env.RAZZLE_ACCOUNT_URL}/organizations`, token]);
      toast.success(<Notification type="success" text="Changes saved" />);
      setAccount({
        ...account,
        organization: {
          ...organizations,
          ...update_obj,
        },
      });
      Cookies.set('companyName', update_obj.name, { domain: deploymentDomain });
      Cookies.set(
        'account',
        {
          ...account,
          organization: {
            ...organizations,
            ...update_obj,
          },
        },
        { domain: deploymentDomain },
      );
      if (company_name && company_address && country && vat)
        setIsComplete((prevState) => ({ ...prevState, company: true }));
      updator({});
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Something went wrong, please try again later"
        />,
      );
    }
    setIsOther({
      industry: industry === 'Other',
      country: country === 'Other',
    });
    setSaving(false);
    setIsUpdated((prevState) => ({ ...prevState, profile: false }));
    setCanSave((prevState) => ({ ...prevState, profile: false }));
  };

  useEffect(() => {
    if (canSave.profile) {
      onSave();
    }
  }, [canSave.profile]);

  useEffect(() => {
    if (reset.profile) {
      resetHandler();
    }
  }, [reset.profile]);

  const resetHandler = () => {
    form.setFieldsValue({
      company_name: organizations.name,
      company_address: organizations.address,
      country: organizations.country,
      vat: organizations.vat,
      description: organizations.description,
    });

    if (isOther.industry) {
      form.setFieldsValue({
        industry: 'Other',
        other_industry: organizations.industry,
      });
    } else {
      form.setFieldsValue({
        industry: organizations.industry,
      });
    }

    if (isOther.country) {
      form.setFieldsValue({
        country: 'Other',
        other_country: organizations.country,
      });
    } else {
      form.setFieldsValue({
        country: organizations.country,
      });
    }

    updator({ changed: false });
  };

  return (
    <>
      <Flexcol style={{ width: '65%' }} center_align>
        <Flexcol style={{ width: '100%', position: 'relative' }} top="72px">
          <Flexrow
            onClick={() => onSetTab('companySettings')()}
            center_align
            style={{ position: 'absolute', top: '-30px', cursor: 'pointer' }}
          >
            <img src={BackArrowSrc} style={{ width: '22px' }} />
            <button
              style={{
                color: '#182429',
                fontSize: '11px',
                fontWeight: '500',
                paddingLeft: '8px',
              }}
            >
              BACK
            </button>
          </Flexrow>
          <Text style={{ color: '#182429', fontSize: '28px' }}>
            Company account details
          </Text>
          <Text style={{ color: '#64737F', fontSize: '15px' }}>
            Here you can view and edit the details of your company account.
          </Text>
          <Divider />
        </Flexcol>
        <Wrap
          onFieldsChange={() => updator({ changed: true })}
          form={form}
          initialValues={{
            company_name: organizations.name,
            company_address: organizations.address,
            country: organizations.country,
            industry: organizations.industry,
            vat: organizations.vat,
            description: organizations.description,
          }}
        >
          <SubsectionTitle tooltip="Provide the full legal name of your company">
            Full company name
          </SubsectionTitle>
          <Form.Item
            validateFirst
            name="company_name"
            rules={[
              required_rule,
              no_empty_space_and_min_length_rule,
            ]}
            validateStatus={!organizations.name && !company_name && 'error'}
          >
            <Input
              disabled={saving || currentUserRole === 'MEMBER'}
              placeholder="Type your company name"
              size="large"
            />
          </Form.Item>
          <SubsectionTitle tooltip="Provide the full address where your company is incorporated and registered">
            Full company address
          </SubsectionTitle>
          <Form.Item
            validateFirst
            name="company_address"
            rules={[required_rule]}
            validateStatus={
              !organizations.address && !company_address && 'error'
            }
          >
            <Input
              disabled={saving || currentUserRole === 'MEMBER'}
              placeholder="e.g. Panepistimiou 61, Athina 105 64"
              size="large"
            />
          </Form.Item>
          <SubsectionTitle tooltip="Provide the country where your company is incorporated and registered">
            Country
          </SubsectionTitle>
          <Form.Item
            validateFirst
            name="country"
            rules={[required_rule]}
            validateStatus={!organizations.country && !country && 'error'}
          >
            <Select
              disabled={saving || currentUserRole === 'MEMBER'}
              size="large"
              showSearch
              placeholder="Search to select country"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {country_options}
            </Select>
          </Form.Item>
          {country == 'Other' && (
            <Form.Item
              validateFirst
              name="other_country"
              rules={[required_rule, no_empty_space_and_min_length_rule]}
              validateStatus={
                !organizations.country && !other_country && 'error'
              }
            >
              <Input
                placeholder="Please specify"
                disabled={saving || currentUserRole === 'MEMBER'}
                size="large"
              />
            </Form.Item>
          )}
          <SubsectionTitle tooltip="Provide your company's VAT number">
            VAT
          </SubsectionTitle>
          <Form.Item
            validateFirst
            name="vat"
            rules={[required_rule]}
            validateStatus={!organizations.vat && !vat && 'error'}
          >
            <Input
              disabled={saving || currentUserRole === 'MEMBER'}
              placeholder="Enter company's VAT number"
              size="large"
            />
          </Form.Item>
          <SubsectionTitle tooltip="Provide your company's activity industry sector">
            Industry (optional)
          </SubsectionTitle>
          <Form.Item validateFirst name="industry">
            <Select
              disabled={saving || currentUserRole === 'MEMBER'}
              placeholder="Select industry"
              size="large"
            >
              {industry_options}
            </Select>
          </Form.Item>
          {industry == 'Other' && (
            <Form.Item
              validateFirst
              name="other_industry"
              rules={[no_empty_space_and_min_length_rule]}
            >
              <Input
                placeholder="Please specify"
                disabled={saving || currentUserRole === 'MEMBER'}
                size="large"
              />
            </Form.Item>
          )}
          <SubsectionTitle tooltip="Provide a short description of your company describing your main activity">
            Company description (optional)
          </SubsectionTitle>
          <Form.Item validateFirst name="description">
            <TextArea
              rows={4}
              disabled={saving || currentUserRole === 'MEMBER'}
            />
          </Form.Item>
          <Flexrow
            id="functional-buttons"
            center_align
            space_between
            top="26px"
          >
            {can_save ? (
              <Text
                id="reset-changes"
                style={{
                  color: '#344EF3',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={resetHandler}
              >
                <UndoOutlined />
                &nbsp; RESET CHANGES
              </Text>
            ) : (
              <Flexrow grow />
            )}
            <SaveButton
              id="save-changes"
              disabled={!can_save}
              onClick={onSave}
              loading={saving}
            >
              Save changes
            </SaveButton>
          </Flexrow>
        </Wrap>
        <div style={{ padding: '42px' }} />
      </Flexcol>
      <style>{`.ant-form-item-explain { color: #ff4d4f !important}`}</style>
    </>
  );
};
