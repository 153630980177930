import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const CompetentCourtLawTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        C
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>OMPETENT </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>C</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>OURT </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ND </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        PPLICABLE{' '}
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>L</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>AW</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsListSection paddingLeft>
        <TermsAndConditionsParagraph>
          The Agreement shall be construed in accordance with and governed by
          the laws of Belgium. In the event of disputes concerning the
          conclusion, execution, termination and/or interpretation of the
          Agreement which cannot be resolved amicably, only the French speaking
          courts of Brussels will have jurisdiction to settle the dispute.
        </TermsAndConditionsParagraph>
      </TermsAndConditionsListSection>
    </TermsAndConditionsOrderedListItem>
  );
};

export default CompetentCourtLawTerms;
