import { useState } from "react";

const useModal = () => {
  let [modal, setModal] = useState(false);
  let [modalBottomReached, setModalBottomReached] = useState(false);
  let [modalContent, setModalContent] = useState();

  let handleModal = (content = false) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return {
    modal,
    handleModal,
    modalContent,
    modalBottomReached,
    setModalBottomReached,
  };
};

export default useModal;
