import React, { useState, useEffect } from 'react';
import { Form, Typography, Input, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { Cancel } from '@styled-icons/material/Cancel';
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';

import validate from '../validators/SignUpWizardValidator';
import SubsectionTitle from '../wizards-components/SubsectionTitle';
import { Flexrow } from '../Flexbox';
import Notification from '../Notification';

const { Text } = Typography;

const RECAPTCHA_SITE_KEY = process.env.RAZZLE_RECAPTCHA_SITE_KEY;

const NextStepButton = styled(Button)`
  width: 50%;
  margin-top: 43px;
  margin-bottom: 36px;
  background-color: #fff;
  color: #344ef3;
  border: 1px solid #344ef3;
  border-radius: 8px;
  font-size: 15px;
  float: right;
  box-shadow: none;
  &:disabled {
    background-color: #fff;
    color: #c1c2c2;
    border: 1px solid #f3f3f4;
    cursor: not-allowed;
  }
  &:hover,
  :active,
  :focus {
    border: 1px solid #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
    color: #0c28dd;
  }
`;

const TogglePasswordButton = styled(Button)`
  position: absolute;
  top: -30px;
  right: 0;
  border: 0;
  box-shadow: none;
  padding: 0;
  height: initial;
  color: #344ef3;
  font-size: 13px;
  width: 60px;
  &:hover,
  :active,
  :visited,
  :focus {
    color: #344ef3;
    background: #fff;
  }
`;

const PasswordList = styled.ul`
  padding-inline-start: 22px;
  color: #344ef3;
`;

const PasswordMessageWrap = styled.div`
  background-color: ${(props) => props.background && props.background};
  color: #64737f;
  margin-top: -20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
`;

const Email = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { step_id, errors, setErrors, setForm, form_data, navigation } = props;
  const {
    company_name,
    email,
    privacy_policy,
    consent,
    password,
    password_repeat,
  } = form_data;
  const { next } = navigation;

  const queryParams = new URLSearchParams(window.location.search);
  const invitation_id = queryParams.get('invitation');

  useEffect(() => {
    if (invitation_id) {
      setDisabled(true);
      const fetchUserInfo = async () => {
        await axios
          .get(`${process.env.RAZZLE_ACCOUNT_URL}/invitations/${invitation_id}`)
          .then((res) => {
            setForm({
              ...form_data,
              email: res.data.email,
              company_name: res.data.organization.name,
            });
          });
      };
      fetchUserInfo();
    } else {
      setDisabled(false);
      setForm({
        ...form_data,
        email: '',
        company_name: '',
      });
    }
  }, [invitation_id]);

  const onCheckboxChange = (event) => {
    setForm({
      ...form_data,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setErrors(validate(form_data, step_id));
    let user_exists = await axios
      .get(
        `${
          process.env.RAZZLE_ACCOUNT_URL
        }/users/exists?email=${encodeURIComponent(email)}`,
      )
      .then((r) => r.data);

    if (user_exists.exists) {
      setErrors({
        ...errors,
        email: 'User already exists',
      });
      toast.error(<Notification type="error" text="User already exists!" />);
    }
    if (Object.keys(errors).length === 1 && !user_exists.exists) next();
  };

  const checkPasswordErrors = (value) => {
    let isValid = false;
    if (errors) {
      errors.passwordErrors.forEach((error) => {
        if (error === value) {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  useEffect(() => {
    setErrors(validate(form_data, step_id));
  }, [form_data]);

  let hasPasswordErrors = !!errors && errors.passwordErrors?.length > 0;
  let hasRepeatPasswordError = !!errors && errors.password_repeat;
  let hasError =
    !!errors && Object.keys(errors).length === 1 && !hasPasswordErrors;

  return (
    <Form>
      <SubsectionTitle tooltip="Provide the full legal name of your company">
        Full company name
      </SubsectionTitle>
      <Form.Item>
        <Input
          name="company_name"
          value={company_name}
          placeholder="Company"
          onChange={(event) => {
            setForm({
              ...form_data,
              [event.target.name]: event.target.value,
            });
          }}
          onBlur={() => setCompanyNameTouched(true)}
          size="large"
          style={{
            borderRadius: '4px',
            border: '1px solid #c4c4c4',
          }}
          disabled={disabled}
        />
        {errors && errors.company_name && companyNameTouched && (
          <Text style={{ color: '#ff4d4f' }}>{errors.company_name}</Text>
        )}
      </Form.Item>
      <SubsectionTitle tooltip="Work email">Work email</SubsectionTitle>
      <Form.Item>
        <Input
          name="email"
          value={email}
          placeholder="example@email.com"
          onChange={(event) => {
            setForm({
              ...form_data,
              [event.target.name]: event.target.value,
            });
          }}
          onBlur={() => setEmailTouched(true)}
          size="large"
          style={{
            borderRadius: '4px',
            border: '1px solid #c4c4c4',
          }}
          disabled={disabled}
        />
        {errors && errors.email && emailTouched && (
          <Text style={{ color: '#ff4d4f' }}>{errors.email}</Text>
        )}
      </Form.Item>
      <SubsectionTitle tooltip="Account password">Password</SubsectionTitle>
      <Form.Item>
        <div style={{ position: 'relative' }}>
          <Input
            name="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            placeholder="Type your password"
            onChange={(event) => {
              setForm({
                ...form_data,
                [event.target.name]: event.target.value,
              });
            }}
            size="large"
            style={{
              borderRadius: '4px',
              border: '1px solid #c4c4c4',
            }}
          />
          <TogglePasswordButton
            onClick={() => setShowPassword(!showPassword)}
            type="text"
          >
            {showPassword ? (
              <span>
                <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
              </span>
            ) : (
              <span>
                <EyeOutlined style={{ color: '#344ef3' }} /> Show
              </span>
            )}
          </TogglePasswordButton>
        </div>
      </Form.Item>
      {hasPasswordErrors ? (
        <Flexrow>
          <PasswordList style={{ marginRight: '110px' }}>
            <li style={{ color: !checkPasswordErrors('upper') && '#c1c2c2' }}>
              <Text
                style={{
                  color: checkPasswordErrors('upper') ? '#182429' : '#c1c2c2',
                  fontSize: '13px',
                }}
              >
                One uppercase character
              </Text>
            </li>
            <li style={{ color: !checkPasswordErrors('special') && '#c1c2c2' }}>
              <Text
                style={{
                  color: checkPasswordErrors('special') ? '#182429' : '#c1c2c2',
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                One special character
                <Text
                  style={{
                    color: checkPasswordErrors('special')
                      ? '#182429'
                      : '#c1c2c2',
                    fontSize: '10px',
                    paddingLeft: '4px',
                  }}
                >
                  (!,@,#,$,%,^,&,*)
                </Text>
              </Text>
            </li>
          </PasswordList>
          <PasswordList>
            <li style={{ color: !checkPasswordErrors('num') && '#c1c2c2' }}>
              <Text
                style={{
                  color: checkPasswordErrors('num') ? '#182429' : '#c1c2c2',
                  fontSize: '13px',
                }}
              >
                One number
              </Text>
            </li>
            <li style={{ color: !checkPasswordErrors('eight') && '#c1c2c2' }}>
              <Text
                style={{
                  color: checkPasswordErrors('eight') ? '#182429' : '#c1c2c2',
                  fontSize: '13px',
                }}
              >
                8 characters minimum
              </Text>
            </li>
          </PasswordList>
        </Flexrow>
      ) : (
        <PasswordMessageWrap background="#cefac3">
          <CheckCircleFill
            style={{ height: '16px', color: '#5fdf3e', paddingRight: '8.5px' }}
          />
          Nice! Your password is strong and secure.
        </PasswordMessageWrap>
      )}
      <SubsectionTitle tooltip="Repeat password">
        Verify password
      </SubsectionTitle>
      <Form.Item>
        <div style={{ position: 'relative' }}>
          <Input
            name="password_repeat"
            value={password_repeat}
            type={showRepeatPassword ? 'text' : 'password'}
            placeholder="Type again your password"
            onChange={(event) => {
              setForm({
                ...form_data,
                [event.target.name]: event.target.value,
              });
            }}
            onBlur={() => setRepeatPasswordTouched(true)}
            size="large"
            style={{
              borderRadius: '4px',
              border: '1px solid #c4c4c4',
            }}
          />
          <TogglePasswordButton
            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
            type="text"
          >
            {showRepeatPassword ? (
              <span>
                <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
              </span>
            ) : (
              <span>
                <EyeOutlined style={{ color: '#344ef3' }} /> Show
              </span>
            )}
          </TogglePasswordButton>
        </div>
      </Form.Item>
      {!hasPasswordErrors && hasRepeatPasswordError && repeatPasswordTouched && (
        <PasswordMessageWrap background="#ffe9e9">
          <Cancel
            style={{
              height: '16px',
              color: '#fd5f5d',
              paddingRight: '8.5px',
            }}
          />
          Oops! Passwords do not match.
        </PasswordMessageWrap>
      )}
      <Checkbox checked={consent} name="consent" onChange={onCheckboxChange}>
        <Text style={{ color: '#182429', fontSize: '13px' }}>
          I want receive updates from Mobito for marketing purposes.
        </Text>
      </Checkbox>
      <Flexrow top="10px" />
      <Checkbox
        checked={privacy_policy}
        name="privacy_policy"
        onChange={onCheckboxChange}
      >
        <Text style={{ color: '#182429', fontSize: '13px' }}>
          I agree to{' '}
          <Link
            to={{ pathname: 'https://www.mobito.io/privacy-policy' }}
            target="_blank"
            style={{
              fontWeight: 600,
              textDecoration: 'underline',
              color: '#182429',
            }}
          >
            Mobito’s Privacy Policy
          </Link>
          .
        </Text>
      </Checkbox>
      <NextStepButton
        size="large"
        disabled={!hasError}
        data-sitekey={RECAPTCHA_SITE_KEY}
        data-callback="handleClick"
        onClick={handleClick}
      >
        Next step <ArrowRight style={{ marginLeft: '10.5px', width: '22px' }} />
      </NextStepButton>
    </Form>
  );
};

export default Email;
