import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Notification from '../../Notification';

const { RAZZLE_BASE_URL } = process.env;
const token = Cookies.get('jwt') || '';

export const fetchDataRoom = async (dataRoomId) => {
  try {
    return await axios.get(`/api/data-rooms/${dataRoomId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchProjectId = async (dataRoomId) => {
  try {
    const response = await axios.get(
      `${RAZZLE_BASE_URL}/api/data-rooms/${dataRoomId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data.project_id;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDataProduct = async (id) => {
  try {
    const response = await axios.get(
      `${RAZZLE_BASE_URL}/api/data-placements/editable/${id}/origin`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    toast.error(
      <Notification
        type="error"
        text="An error occured, please try again later"
      />,
    );
  }
};

export const fetchDataProductOrganizationId = async (projectId) => {
  try {
    const response = await axios.get(
      `${RAZZLE_BASE_URL}/api/data-placements/editable/${projectId}/origin`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data.placement.organization_id;
  } catch (error) {
    console.log(error);
  }
};
