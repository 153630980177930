import React from "react";
import BackToTop from "react-back-to-top-button";
import styled from "styled-components";

import { ArrowUpShort } from "@styled-icons/bootstrap/ArrowUpShort";

const ScrollContainer = styled.div`
  padding: 6px 10px;
  border: 1px solid #ff7f19;
  background: #ff7f19;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  transition: all 0.2s ease-in;
  border-radius: 50%;
`;

const BackToTopButton = () => (
  <BackToTop
    showAt={100}
    speed={500}
    easing="easeInOutQuint"
    style={{ fontSize: "16px", zIndex: 999999 }}
  >
    <ScrollContainer>
      <ArrowUpShort size="22" style={{ color: "#fff" }} />
    </ScrollContainer>
  </BackToTop>
);

export default BackToTopButton;
