import styled from "styled-components"

export const Chip = styled.div`
  background: rgb(235, 235, 237);
  border-radius: 16.5px;
  border: 1px solid rgb(235, 235, 237);
  padding: 6px 12px;
  font-size: 14px;
  color: #182429;
  margin-left: 8px;
  margin-bottom: 8px;
`
