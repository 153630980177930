import { useMemo, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Typography, Divider, Menu } from 'antd';
import styled from 'styled-components';

import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';

import { Flexrow, Flexcol } from './Flexbox';
import UserAvatarSrc from '../images/user-avatar.svg';
import DashboardSrc from '../images/dashboard.svg';
import LogoutSrc from '../images/logout.svg';
import MarketplaceSrc from '../images/marketplace.svg';
import MySettingsSrc from '../images/my_settings.svg';
import instance from '../services/TokenValidator';

const { Text } = Typography;

const MenuItemWrap = styled(Menu.Item)`
  height: auto !important;
  margin-bottom: ${(props) => props.last && '34px !important'};
  & :hover {
    background-color: ${(props) => (props.disabled ? '#fff' : '#53f1eb1f')};
  }
`;

const { RAZZLE_BASE_URL } = process.env;

export const HeaderAccountMenu = (props) => {
  const { routeHandler, user, isUpdated } = props;
  const [isActive, setIsActive] = useState(false);
  const role = Cookies.get('role');
  const token = Cookies.get('jwt');
  const account = JSON.parse(Cookies.get('account'));

  const deploymentDomain =
    process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

  const onLogout = async () => {
    const logoutModalRes = await routeHandler('/login');

    await instance.post(`${process.env.RAZZLE_ACCOUNT_URL}/auth/logout`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (logoutModalRes || (!logoutModalRes && !isUpdated)) {
      Cookies.remove('jwt', { domain: deploymentDomain });
      Cookies.remove('user_id', { domain: deploymentDomain });
      Cookies.remove('email', { domain: deploymentDomain });
      Cookies.remove('organization_id', { domain: deploymentDomain });
      Cookies.remove('firstname', { domain: deploymentDomain });
      Cookies.remove('lastname', { domain: deploymentDomain });
      Cookies.remove('role', { domain: deploymentDomain });
      Cookies.remove('account', { domain: deploymentDomain });
      Cookies.remove('refreshToken', { domain: deploymentDomain });
      Cookies.remove('primaryContact', { domain: deploymentDomain });
      Cookies.remove('companyName', { domain: deploymentDomain });
      Cookies.remove('primeEmail', { domain: deploymentDomain });
    }
  };

  let randomColor = useMemo(() => '#');
  const getRandomColor = useMemo(() => {
    const letters = '0123456789ABCDEF';
    for (let i = 0; i < 6; i++) {
      randomColor += letters[Math.floor(Math.random() * 16)];
    }
    return randomColor + '54';
  }, []);

  const splitName = (name) => {
    return name
      .split(' ')
      .map((i) => i.charAt(0).toUpperCase())
      .slice(0, 2);
  };

  const paths = ['/', '/account', '/dashboard'];

  useEffect(() => {
    let href = window.location.href;
    href = href.slice(RAZZLE_BASE_URL.length);
    paths.forEach((path) => {
      if (href === path) setIsActive(path);
    });
  }, []);

  useEffect(() => {
    if (user) account.organization.name = user.organization.name;
    if (user) account.user.email = user.user.email;
  }, [user, account]);

  return (
    <>
      <Flexcol
        top="13px"
        center_align
        style={{ backgroundColor: '#fff', padding: '20px 50px 0px' }}
      >
        <img
          src={UserAvatarSrc}
          style={{
            backgroundColor: '#F0F1F2',
            borderRadius: '50%',
            marginBottom: '12px',
            width: '46px',
            height: '46px',
          }}
        />
        <Text
          style={{
            color: '#182A33',
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          {account.user.firstname} {account.user.lastname}
        </Text>
        <Text
          style={{
            color: '#182A33',
            fontSize: '13px',
            marginBottom: '8px',
          }}
        >
          {account.user.email}
        </Text>
        <Text
          style={{
            color: '#64737F',
            fontSize: '13px',
            marginBottom: '22px',
          }}
        >
          {account.user.businessRole}
        </Text>
      </Flexcol>
      <Menu
        style={{
          boxShadow: '2px 10px 8px rgba(24, 42, 51, 0.12)',
          height: 'auto !important',
        }}
      >
        <MenuItemWrap disabled>
          <Flexrow
            center_align
            style={{ padding: '4px 8px', borderRadius: '8px' }}
          >
            <div
              style={{
                width: '34px',
                height: '34px',
                backgroundColor: getRandomColor,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
              }}
            >
              <Text
                style={{
                  fontSize: '14px',
                  color: randomColor,
                  backgroundColor: 'initial',
                }}
              >
                {splitName(account.organization.name)}
              </Text>
            </div>
            <Flexcol style={{ backgroundColor: 'initial' }}>
              <Text
                style={{
                  color: '#182A33',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: 'initial',
                  backgroundColor: 'initial',
                  marginRight: '12px',
                }}
              >
                {account.organization.name}
              </Text>
              <Text
                style={{
                  color: role === 'ADMIN' ? '#fff' : '#182A33',
                  fontSize: '9px',
                  backgroundColor: role === 'ADMIN' ? '#182A33' : '#fff',
                  borderRadius: '2px',
                  lineHeight: 'initial',
                  width: 'fit-content',
                  padding: '2px 4px',
                  border: role === 'MEMBER' && '1px solid #182A33',
                  fontWeight: role === 'MEMBER' && '600',
                }}
              >
                {role}
              </Text>
            </Flexcol>
            <Flexrow grow />
            <CheckCircleFill
              style={{
                height: '16px',
                color: '#5fdf3e',
                paddingRight: '7px',
              }}
            />
            <Text
              style={{
                color: '#5FDF3E',
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: 'initial',
                backgroundColor: 'initial',
              }}
            >
              Active
            </Text>
          </Flexrow>
        </MenuItemWrap>
        <Divider />
        <MenuItemWrap disabled={isActive === '/'}>
          <Flexrow
            center_align
            onClick={() => routeHandler('/')}
            style={{ padding: '4px 8px', borderRadius: '8px' }}
          >
            <img src={MarketplaceSrc} style={{ marginRight: '10px' }} />
            <Text
              style={{
                color: '#182A33',
                fontSize: '14px',
                lineHeight: 'initial',
                backgroundColor: 'initial',
              }}
            >
              Marketplace
            </Text>
          </Flexrow>
        </MenuItemWrap>
        <MenuItemWrap disabled={isActive === '/dashboard'}>
          <Flexrow
            center_align
            onClick={() => routeHandler('/dashboard')}
            style={{ padding: '4px 8px', borderRadius: '8px' }}
          >
            <img src={DashboardSrc} style={{ marginRight: '10px' }} />
            <Text
              style={{
                color: '#182A33',
                fontSize: '14px',
                lineHeight: 'initial',
                backgroundColor: 'initial',
              }}
            >
              Dashboard
            </Text>
          </Flexrow>
        </MenuItemWrap>
        <MenuItemWrap disabled={isActive === '/account'}>
          <Flexrow
            center_align
            onClick={() => routeHandler('/account')}
            style={{ padding: '4px 8px', borderRadius: '8px' }}
          >
            <img src={MySettingsSrc} style={{ marginRight: '10px' }} />
            <Text
              style={{
                color: '#182A33',
                fontSize: '14px',
                lineHeight: 'initial',
                backgroundColor: 'initial',
              }}
            >
              My settings
            </Text>
          </Flexrow>
        </MenuItemWrap>
        <MenuItemWrap onClick={onLogout} last>
          <Flexrow
            center_align
            style={{ padding: '4px 8px', borderRadius: '8px' }}
          >
            <img src={LogoutSrc} style={{ marginRight: '10px' }} />
            <Text
              style={{
                color: '#182A33',
                fontSize: '14px',
                lineHeight: 'initial',
                backgroundColor: 'initial',
              }}
            >
              Log out
            </Text>
          </Flexrow>
        </MenuItemWrap>
      </Menu>
      <style>{`.ant-menu-item-disabled { cursor: default !important; }`}</style>
    </>
  );
};
