import {
  TermsAndConditionsSection,
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderSmall,
  TermsAndConditionsParagraph,
  TermsAndConditionsLink,
  TermsAndConditionsOrderedList,
  TermsAndConditionsEmptySpace,
} from '../../styles/termsAndConditions';
import {
  DefinitionsTerms,
  MarkeplaceAndServicesTerms,
  ClientsAccessRightTerms,
  ClientResponsibilitiesWarrantiesTerms,
  Term,
  PricingAndPaymentTerms,
  IntellectualPropertyTerms,
  ConfidentialityTerms,
  DataProtectionTerms,
  TerminationTerms,
  ConsequencesTerms,
  LiabilityTerms,
  ForceMajeureTerms,
  DisputesTerms,
  CompetentCourtLawTerms,
  MiscellaneousTerms,
} from './index';

export const TermsOfUse = () => {
  return (
    <TermsAndConditionsOrderedList>
      <TermsAndConditionsSection center>
        <TermsAndConditionsHeaderLarge>
          Terms of Use
        </TermsAndConditionsHeaderLarge>
      </TermsAndConditionsSection>
      <TermsAndConditionsSection>
        <TermsAndConditionsParagraph>
          These terms of use (“
          <TermsAndConditionsHeaderSmall>
            Terms of Use
          </TermsAndConditionsHeaderSmall>
          ”) govern the relationship between the Client and Mobito Technology
          SRL, with a registered seat at Chaussee de Boitsfort 48, 1050
          Brussels, Belgium, and VAT number 0747.793.289 (“Mobito”), as from the
          moment the Client accepts these Terms of Use during the registration
          process on the Platform or as from the moment the Client signs an
          Order Form for the Data Preparation Services. It is important that the
          Client reads through the Terms of Use and takes note of and accepts
          any obligations and restrictions herein. In case the Client does not
          agree with the Terms of Use, it cannot use the Platform and the
          Services that Mobito makes available through the Platform. The
          Services that the Client agrees to procure from and that Mobito agrees
          to provide are set forth in these Terms of Use and/or Order Forms that
          are effective and binding when signed by each party. The Terms of Use
          are deemed incorporated into and form a part of each Order Form for
          the Data Preparation Services.
          <TermsAndConditionsEmptySpace />
          In case of questions, please contact Mobito at{' '}
          <TermsAndConditionsLink href="mailto:legal@mobito.io" target="_blank">
            legal@mobito.io
          </TermsAndConditionsLink>{' '}
          prior to accepting these Terms of Use.
        </TermsAndConditionsParagraph>
      </TermsAndConditionsSection>
      <DefinitionsTerms></DefinitionsTerms>
      <MarkeplaceAndServicesTerms></MarkeplaceAndServicesTerms>
      <ClientsAccessRightTerms></ClientsAccessRightTerms>
      <ClientResponsibilitiesWarrantiesTerms></ClientResponsibilitiesWarrantiesTerms>
      <Term></Term>
      <PricingAndPaymentTerms></PricingAndPaymentTerms>
      <IntellectualPropertyTerms></IntellectualPropertyTerms>
      <ConfidentialityTerms></ConfidentialityTerms>
      <DataProtectionTerms></DataProtectionTerms>
      <TerminationTerms></TerminationTerms>
      <ConsequencesTerms></ConsequencesTerms>
      <LiabilityTerms></LiabilityTerms>
      <ForceMajeureTerms></ForceMajeureTerms>
      <DisputesTerms></DisputesTerms>
      <CompetentCourtLawTerms></CompetentCourtLawTerms>
      <MiscellaneousTerms></MiscellaneousTerms>
    </TermsAndConditionsOrderedList>
  );
};
