import styled, { css } from 'styled-components';
import { colors } from '../../values/colors';
import { Alarm } from 'styled-icons/material';
import { timestampConverter } from '../../utils/time';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Button,
  Typography,
  Menu,
  Tag,
  message as notifyMessage,
  Row,
  Col,
  Tooltip,
} from 'antd';
import instance from '../../services/TokenValidator';

//TODO create a parent component for all cards, looks like they have same styling.
//TODO split to smaller components, lots of these are used by other cards as well.
//const { Title: ATitle } = Typography

const isDev = process.env.RAZZLE_BASE_URL === 'http://localhost:3001';
const isQA = process.env.RAZZLE_BASE_URL === 'https://app-qa.mobito.io';
const isStaging = process.env.RAZZLE_BASE_URL === 'https://app-rc.mobito.io';

const { SubMenu } = Menu;

const Title = styled(Typography.Title)`
  line-height: unset !important;
  margin-bottom: unset !important;
`;

const SAlarmIcon = styled(Alarm)`
  color: white;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-bottom: 2px;
`;

const Wrap = styled(Col)`
  background-color: #ffffff;
  padding: 20px 28px;
  .ant-menu-submenu-title {
    line-height: 35px !important;
  }
  border-bottom: 2px solid ${colors['grey_1']};
  > * .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
  }
  ${(p) =>
    p.hide_arrow &&
    css`
      > * .ant-menu-submenu-arrow {
        color: transparent !important;
      }
    `}
`;

export const OpenDataCapsuleButton = styled.a`
  border-radius: 8px;
  padding: 5px 20px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
  &:disabled {
    color: #64737f;
    border: 1px solid #64737f;
    background: #fff;
    cursor: not-allowed;
  }
`;

const S3NewAccessRequestCard = (props) => {
  const token = Cookies.get('jwt');
  const currentUserRole = Cookies.get('role');
  const { request, dataRoomId } = props;
  const timestamp = new Date(request.created_at);
  const converted_timestamp = timestampConverter(timestamp);
  const [requestName, setRequestName] = useState('');

  const [status, setStatus] = useState(request.status);
  const [updatedDataRoomId, setDataRoomId] = useState(request.data_room_id);
  const [isLoading, setIsLoading] = useState(false);

  const isPending = status == 'PENDING';
  const isApproved = status == 'APPROVED';
  const isRejected = status == 'REJECTED';

  useEffect(() => {
    const fetchOrganization = async () => {
      const res = await instance.get(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations?organizationId=${request.organization_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res.status === 200) {
        setRequestName(res.data.name);
      }
    };
    fetchOrganization();
  }, []);

  const accessRequest = (status) =>
    axios.patch(
      `/api/access-requests/${request.id}`,
      {
        status: status,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

  const changeStatus = (status) => async () => {
    try {
      setIsLoading(true);
      const res = await accessRequest(status);
      setStatus(status);
      setDataRoomId(res.data.data_room_id);
      setIsLoading(false);

      notifyMessage.success('Project status updated successfully');
    } catch (error) {
      notifyMessage.error('Could not update status');
    }
  };

  const TitleView = (
    <Row align="middle" justify="space-between">
      <Col span={2}>
        <Title
          style={{ color: colors.orange_4, textDecoration: 'underline' }}
          level={5}
        >
          {requestName}
        </Title>
      </Col>
      <Col span={1}>
        <Tag color="#2db7f5">
          <SAlarmIcon />
          {converted_timestamp}
        </Tag>
      </Col>

      <Col span={8}>
        {isPending && (
          <Row>
            <Col>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                overlayStyle={{ width: '100%' }}
                placement="bottom"
                title={
                  currentUserRole === 'MEMBER' &&
                  'You do not have the access rights to perform this action.'
                }
              >
                <Button
                  onClick={changeStatus('APPROVED')}
                  type="primary"
                  disabled={currentUserRole === 'MEMBER'}
                >
                  {'Accept & create data capsule'}
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                overlayStyle={{ width: '100%' }}
                placement="bottom"
                title={
                  currentUserRole === 'MEMBER' &&
                  'You do not have the access rights to perform this action.'
                }
              >
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={changeStatus('REJECTED')}
                  type="danger"
                  disabled={currentUserRole === 'MEMBER'}
                >
                  Reject
                </Button>
              </Tooltip>
            </Col>
          </Row>
        )}
        {isApproved && !isLoading && (
          <OpenDataCapsuleButton
            href={
              isDev
                ? `http://localhost:4200/data-capsule/${
                    dataRoomId !== null ? dataRoomId : updatedDataRoomId
                  }`
                : isQA
                ? `http://platform-qa.mobito.io/data-capsule/${
                    dataRoomId !== null ? dataRoomId : updatedDataRoomId
                  }`
                : isStaging
                ? `http://platform-rc.mobito.io/data-capsule/${
                    dataRoomId !== null ? dataRoomId : updatedDataRoomId
                  }`
                : `http://platform.mobito.io/data-capsule/${
                    dataRoomId !== null ? dataRoomId : updatedDataRoomId
                  }`
            }
          >
            Open Data Capsule &gt;
          </OpenDataCapsuleButton>
        )}
        {isRejected && <Title level={5}>Rejected</Title>}
      </Col>
    </Row>
  );
  return (
    <Wrap hide_arrow={true} span={24}>
      <Menu mode="inline">
        <SubMenu key="sub1" title={TitleView} />
      </Menu>
    </Wrap>
  );
};

export default S3NewAccessRequestCard;
