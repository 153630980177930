const BASE_URL = process.env.RAZZLE_MOBITO_SYSTEMIC_BACKEND;

export const curlBuilder = (domain, endpoint) => {

  const requested_domain = domain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    .split('.')
    .join('.');

  const no_http_url = endpoint.endpoint
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    .split('.')
    .join('.');

  let location = `${BASE_URL}/public/proxy/${requested_domain}${no_http_url}`
  if ( endpoint.sample_request_query && endpoint.sample_request_query[0] == '?' ) {
    location += endpoint.sample_request_query
  }
  let out = `curl --request ${endpoint.http_method} --location '${location}'  --header 'Authorization: <your access key goes here>' --header 'Content-Type: application/json' `;
  if ( endpoint.http_method == 'POST' && endpoint.sample_request_body ) {
    out += `--data '${endpoint.sample_request_body}'`
  }
  return out;
}
