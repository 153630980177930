import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Button, Modal, Typography, Spin } from 'antd';
import { ArrowDownward } from 'styled-icons/material';
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft';
import { LoadingOutlined } from '@ant-design/icons';

import { scrollStyles } from '../../styles/scrollStyles';
import { Flexcol } from '../Flexbox';
import validate from '../validators/SignUpWizardValidator';
import TermsAndConditionsRegistration from '../../views/TermsAndConditionsRegistration';

const { Text } = Typography;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />
);

const ScrollContainer = styled.div`
  padding: 10px 14px;
  border: 1px solid #ff7f19;
  background: #ff7f19;
  position: absolute;
  bottom: 20%;
  right: 12%;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  transition: all 0.2s ease-in;
  border-radius: 50%;
`;

const AcceptContainer = styled.div`
  margin: 36px 0;
  justify-content: space-evenly;
  display: flex;
`;

const Wrap = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  ${scrollStyles}
`;

const BackWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 5px;
  color: #344ef3;
  cursor: pointer;
`;

const DeclineButton = styled.button`
  color: #344ef3;
  font-size: 15px;
  width: 50%;
`;

const AcceptButton = styled(Button)`
  background-color: #344ef3;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  width: 50%;
  height: inherit;
  box-shadow: none;
  &:hover,
  :active,
  :focus {
    background-color: #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
    color: #fff;
  }
  &:disabled {
    background-color: #fff;
    color: #c1c2c2;
    border: 1px solid #f3f3f4;
    cursor: not-allowed;
  }
`;

const ScrollButton = () => (
  <ScrollContainer>
    <ArrowDownward size="16" style={{ color: '#fff' }} />
  </ScrollContainer>
);

const decline_modal_content = (
  <Flexcol>
    <Text
      style={{
        color: '#182429',
        fontSize: '21px',
        fontWeight: 'bold',
        marginBottom: '12px',
      }}
    >
      Are you sure?
    </Text>
    <Text style={{ color: '#64737f', fontSize: '15px' }}>
      In order to create a Mobito account you need to accept the Terms &
      Conditions as displayed in the User Agreement. Are you sure you want to
      cancel your registration?
    </Text>
  </Flexcol>
);

const ModalButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #fff;
  color: #344ef3;
  font-size: 15px;
  padding: 10px 29px;
  height: initial;
`;

const Terms = (props) => {
  const [reachedBottom, setReachedBottom] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const invitation_id = queryParams.get('invitation');

  const handleClick = () => {
    let termsWrap = document.getElementById('terms-wrap');
    if (termsWrap) {
      termsWrap.scrollTo({
        top: termsWrap.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const {
    step_id,
    setErrors,
    setForm,
    form_data,
    navigation,
    isSignup,
    activeKey,
    errors,
  } = props;

  useEffect(() => {
    isSignup(false);
  }, []);

  const { password, password_repeat, email, company_name } = form_data;

  const { next, previous } = navigation;

  const goBack = () => {
    isSignup(true);
    activeKey(2);
    previous();
  };

  const handleSubmit = async () => {
    setForm({
      ...form_data,
      terms: true,
    });

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      let response;
      if (invitation_id) {
        response = await axios.post(
          `${process.env.RAZZLE_ACCOUNT_URL}/auth/register/invitation/${invitation_id}`,
          {
            password: password,
          },
        );
      } else {
        response = await axios.post(
          `${process.env.RAZZLE_ACCOUNT_URL}/auth/register`,
          {
            email: email,
            password: password,
            companyName: company_name,
          },
        );
      }

      setIsLoading(false);
      response.status === 200 && next();
    }

    next();
  };

  const handleDecline = () => {
    setIsModalVisible(true);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 200 < e.target.clientHeight;
    if (bottom) {
      setReachedBottom(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setErrors(validate(form_data, step_id));
  }, [password, password_repeat]);

  return (
    <Flexcol>
      <BackWrap onClick={goBack}>
        <ArrowLeft style={{ width: '17px', marginRight: '12px' }} /> Back
      </BackWrap>
      <Wrap onScroll={handleScroll} id="terms-wrap">
        <TermsAndConditionsRegistration />
        <button
          style={{ display: reachedBottom ? 'none' : 'contents' }}
          onClick={handleClick}
        >
          <ScrollButton />
        </button>
      </Wrap>
      <AcceptContainer>
        <DeclineButton onClick={() => handleDecline()}>Decline</DeclineButton>
        <Modal
          visible={isModalVisible}
          closable={true}
          centered={true}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <ModalButton key="back" onClick={() => setIsModalVisible(false)}>
              No, continue
            </ModalButton>,
            <ModalButton
              key="submit"
              type="primary"
              onClick={() => history.push('/')}
            >
              Yes, cancel
            </ModalButton>,
          ]}
        >
          {decline_modal_content}
        </Modal>
        <AcceptButton onClick={() => handleSubmit()} disabled={!reachedBottom}>
          {isLoading ? <Spin indicator={antIcon} /> : 'Accept & Sign up'}
        </AcceptButton>
      </AcceptContainer>
    </Flexcol>
  );
};

export default Terms;
