import { Upload, Typography } from 'antd';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { Flexrow } from '../../Flexbox';
import Notification from '../../Notification';

const { Text } = Typography;
const token = Cookies.get('jwt');

const SendAttachments = (props) => {
  const { imgSrc, threadItemId, setRefresh, setRefreshDrawer, isResolved } =
    props;

  const handleChange = async (info) => {
    if (info.fileList.length > 5) {
      toast.error(
        <Notification type="error" text="You can select up to 5 files!" />,
      );
    }

    if (info.file.size > 52428800) {
      toast.error(
        <Notification
          type="error"
          text="File must not exceed the limit of 50MB!"
        />,
      );
    }

    setRefresh((prevState) => !prevState);
    setRefreshDrawer((prevState) => !prevState);
  };

  const upload_props = {
    action: `/api/discussion-thread-attachments/item/${threadItemId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange: handleChange,
    name: 'attachments',
    multiple: true,
    maxCount: 5,
    showUploadList: false,
    disabled: isResolved,
  };

  return (
    <Upload {...upload_props}>
      <Flexrow
        center_align
        right="32px"
        bottom="20px"
        style={{ cursor: isResolved ? 'not-allowed' : 'pointer' }}
      >
        <img src={imgSrc} style={{ marginRight: 10 }} />
        <Text>Attachments</Text>
      </Flexrow>
    </Upload>
  );
};

export default SendAttachments;
