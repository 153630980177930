import { useState, useCallback, useEffect } from 'react';
import { Typography, Input, Divider, Spin, Switch } from 'antd';
import Cookies from 'js-cookie';
import debounce from 'lodash.debounce';

import {
  ChatContainer,
  QuestionContainer,
  QuestionTitleWrap,
  ThreadsWrap,
  ThreadContainer,
  QuestionInput,
  SpinContainer,
  AttachmentWrap,
} from '../assets/styled-components';
import {
  patchThreadItem,
  deleteDiscussionThread,
  deleteAttachment,
  patchDiscussionThreadStatus,
} from './chat-services';
import { initializeThreadItems } from '../helpers/initialize-thread-items';
import { draftItemId, draftItemContent } from '../helpers/draft-items-utils';
import { Flexrow, Flexcol } from '../../Flexbox';
import TrashCanSrc from '../../../images/trash_can_black.svg';
import TrashCanBlackSrc from '../../../images/trash_can_black_thick.svg';
import UserChatAvatarSrc from '../../../images/chat_avatar.svg';
import PdfSrc from '../../../images/pdf_file.svg';
import AttachmentSrc from '../../../images/attachment.svg';
import SendToSrc from '../../../images/send_to.svg';
import AddQuestionSrc from '../../../images/add_question.svg';
import ResolvedSrc from '../../../images/check_gray.svg';
import { SendThreadItem } from './SendThreadItem';
import { formatDate } from '../helpers/date-utils';
import SendAttachments from './SendAttachments';
import { user_types } from '../../../iso-shared/values/global';

const { RAZZLE_MOBITO_FRONTEND_S3_PUBLIC_ENDPOINT } = process.env;
const { Text, Title } = Typography;
const public_bucket = process.env.RAZZLE_MOBITO_FRONTEND_S3_PUBLIC_BUCKET;

const Chat = (props) => {
  const {
    discussionThreads,
    isLoading,
    setRefresh,
    setRefreshDrawer,
    setIsOpen,
    userType,
  } = props;
  const firstName = Cookies.get('firstname');
  const lastName = Cookies.get('lastname');
  const token = Cookies.get('jwt');
  const [allDiscussionThreads, setAllDiscussionThreads] = useState([]);
  const [threadItemContent, setThreadItemContent] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const attachments_length =
    allDiscussionThreads.length &&
    allDiscussionThreads
      .map((discussionThread) =>
        discussionThread.thread_items
          .filter((threadItem) => threadItem.status === 'PUBLISHED')
          .reduce(
            (acc, thread) =>
              (acc += thread.discussion_thread_attachments.length),
            0,
          ),
      )
      .reduce((a, b) => a + b, 0);

  useEffect(() => {
    setAllDiscussionThreads(discussionThreads);
  }, [discussionThreads]);

  const handlePatchThreadItem = async (content, threadItemId) => {
    setIsSaving(true);
    await patchThreadItem(token, content, threadItemId);
    setIsSaving(false);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handlePatchThreadItem, 600),
    [],
  );

  const deleteDiscussionThreadHandler = async (discussionThreadId) => {
    await deleteDiscussionThread(token, discussionThreadId);
    setAllDiscussionThreads(
      allDiscussionThreads.filter(
        (discussionThread) => discussionThread.id !== discussionThreadId,
      ),
    );
    setRefresh((prevState) => !prevState);
    setRefreshDrawer((prevState) => !prevState);
  };

  const deleteAttachmentHandler = async (attachmentId) => {
    await deleteAttachment(token, attachmentId);
    setRefresh((prevState) => !prevState);
    setRefreshDrawer((prevState) => !prevState);
  };

  useEffect(() => {
    const initializer = async () => {
      await initializeThreadItems(
        token,
        allDiscussionThreads,
        userType,
        setRefresh,
        setRefreshDrawer,
      );
      setRefresh((prevState) => !prevState);
      setRefreshDrawer((prevState) => !prevState);
    };

    initializer().catch(console.error);
  }, [isLoading]);

  const onResolveChange = async (checked, discussionThreadId) => {
    if (checked)
      await patchDiscussionThreadStatus(token, discussionThreadId, 'RESOLVED');
    else
      await patchDiscussionThreadStatus(token, discussionThreadId, 'WAITING');

    setRefresh((prevState) => !prevState);
    setRefreshDrawer((prevState) => !prevState);
  };

  return (
    <>
      <ChatContainer>
        {isLoading ? (
          <SpinContainer center_align center_content>
            <Spin size="large" />
          </SpinContainer>
        ) : (
          <>
            <Flexrow>
              <Flexcol style={{ width: '75%' }}>
                {allDiscussionThreads.length > 0 &&
                  allDiscussionThreads.map((discussionThread, index) => (
                    <QuestionContainer key={index}>
                      <QuestionTitleWrap center_align space_between>
                        <Flexcol>
                          <Text
                            style={{
                              color: '#182429',
                            }}
                          >
                            {discussionThread.category
                              .split(/(?=[A-Z])/)
                              .join(' ')}
                          </Text>
                          <Title
                            level={4}
                            style={{
                              margin: 0,
                              color: '#182429',
                            }}
                          >
                            {discussionThread.title}
                          </Title>
                        </Flexcol>
                        {discussionThread.thread_items.length > 1 &&
                          userType === user_types.CONSUMER && (
                            <Flexrow
                              center_align
                              space_between
                              style={{
                                backgroundColor: '#fff',
                                padding: '8px 12px',
                                width: 160,
                              }}
                            >
                              <Text style={{ marginRight: 22 }}>
                                {discussionThread.status === 'RESOLVED'
                                  ? 'Resolved'
                                  : 'Resolve'}
                              </Text>
                              <Switch
                                checked={discussionThread.status === 'RESOLVED'}
                                onChange={(e) =>
                                  onResolveChange(e, discussionThread.id)
                                }
                              />
                            </Flexrow>
                          )}
                        {userType === user_types.PROVIDER &&
                          discussionThread.status === 'RESOLVED' && (
                            <Flexrow center_align>
                              <img
                                src={ResolvedSrc}
                                style={{ marginRight: 8 }}
                              />
                              <Text>Resolved by consumer</Text>
                            </Flexrow>
                          )}
                        {discussionThread.thread_items.length === 1 &&
                          discussionThread.thread_items[0].status ===
                            'DRAFT' && (
                            <img
                              src={TrashCanSrc}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                deleteDiscussionThreadHandler(
                                  discussionThread.id,
                                )
                              }
                            />
                          )}
                      </QuestionTitleWrap>
                      <ThreadsWrap>
                        {discussionThread.thread_items
                          .filter(
                            (threadItem) => threadItem.status === 'PUBLISHED',
                          )
                          .map((threadItem, index) => (
                            <ThreadContainer
                              key={index}
                              isQuestion={threadItem.type === 'QUESTION'}
                            >
                              <Flexrow center_align space_between>
                                <Flexrow center_align>
                                  <img
                                    src={UserChatAvatarSrc}
                                    style={{
                                      marginRight: '12px',
                                    }}
                                  />
                                  <Text>{`${threadItem.user.firstname} ${
                                    threadItem.user.lastname
                                  } ${
                                    threadItem.user.firstname === firstName &&
                                    threadItem.user.lastname === lastName
                                      ? '(you)'
                                      : ''
                                  }`}</Text>
                                </Flexrow>
                                <Text>{formatDate(threadItem.created_at)}</Text>
                              </Flexrow>
                              <Flexrow
                                center_align
                                style={{ paddingLeft: 64, marginTop: 10 }}
                              >
                                <Text>Description:</Text>
                                <Text style={{ marginLeft: 16 }}>
                                  {threadItem.content}
                                </Text>
                              </Flexrow>
                              {threadItem.discussion_thread_attachments
                                .length ? (
                                <Flexcol top="12px">
                                  <Text>Attachment:</Text>
                                  {threadItem.discussion_thread_attachments.map(
                                    (attachment) => (
                                      <AttachmentWrap>
                                        <img
                                          src={PdfSrc}
                                          style={{
                                            marginRight: 8,
                                          }}
                                        />
                                        <Text underline>
                                          <a
                                            href={`${RAZZLE_MOBITO_FRONTEND_S3_PUBLIC_ENDPOINT}/${public_bucket}/data-rooms/${attachment.path}`}
                                            download={attachment.filename}
                                            target="_blank"
                                          >
                                            {attachment.filename}
                                          </a>
                                        </Text>
                                      </AttachmentWrap>
                                    ),
                                  )}
                                </Flexcol>
                              ) : null}
                            </ThreadContainer>
                          ))}
                      </ThreadsWrap>
                      <QuestionInput>
                        <Flexrow center_align>
                          <img
                            src={UserChatAvatarSrc}
                            style={{
                              marginRight: '12px',
                            }}
                          />
                          <Text>{firstName + ' ' + lastName + ' (you)'}</Text>
                        </Flexrow>
                        <Flexrow
                          center_align
                          style={{ borderBottom: '1px solid #000000' }}
                          top="5px"
                          left="40px"
                        >
                          <Input
                            placeholder="Type your question here"
                            bordered={false}
                            size="large"
                            value={
                              threadItemContent[
                                discussionThread.thread_items[0]?.id
                              ] ||
                              draftItemContent(discussionThread.thread_items)[0]
                            }
                            onChange={(e) => {
                              debouncedChangeHandler(
                                e.target.value,
                                draftItemId(discussionThread.thread_items)[0],
                              );
                              setThreadItemContent((prevState) => ({
                                ...prevState,
                                [discussionThread.thread_items[0]?.id]:
                                  e.target.value,
                              }));
                            }}
                            disabled={
                              discussionThread.status === 'RESOLVED'
                                ? true
                                : false
                            }
                          />
                          <SendAttachments
                            imgSrc={AttachmentSrc}
                            threadItemId={
                              draftItemId(discussionThread.thread_items)[0]
                            }
                            setRefresh={setRefresh}
                            setRefreshDrawer={setRefreshDrawer}
                            isResolved={discussionThread.status === 'RESOLVED'}
                          />
                          <SendThreadItem
                            text={
                              userType === user_types.CONSUMER
                                ? 'Send to provider'
                                : 'Send to consumer'
                            }
                            imgSrc={SendToSrc}
                            threadItemId={
                              draftItemId(discussionThread.thread_items)[0]
                            }
                            threadContent={
                              threadItemContent[
                                discussionThread.thread_items[0]?.id
                              ]
                            }
                            discussionThreadId={discussionThread.id}
                            setRefresh={setRefresh}
                            setRefreshDrawer={setRefreshDrawer}
                            setThreadItemContent={setThreadItemContent}
                            isSaving={isSaving}
                            userType={userType}
                            isResolved={discussionThread.status === 'RESOLVED'}
                          ></SendThreadItem>
                        </Flexrow>
                        <Flexrow top="12px" left="40px" wrap>
                          {discussionThread.thread_items
                            .filter(
                              (threadItem) => threadItem.status === 'DRAFT',
                            )
                            .map((threadItem) =>
                              threadItem.discussion_thread_attachments.map(
                                (attachment) => (
                                  <AttachmentWrap>
                                    <img
                                      src={PdfSrc}
                                      style={{
                                        marginRight: 8,
                                      }}
                                    />
                                    <Text underline>{attachment.filename}</Text>
                                    {threadItem.status === 'DRAFT' && (
                                      <img
                                        src={TrashCanBlackSrc}
                                        style={{
                                          marginLeft: 16,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          deleteAttachmentHandler(attachment.id)
                                        }
                                      />
                                    )}
                                  </AttachmentWrap>
                                ),
                              ),
                            )}
                        </Flexrow>
                      </QuestionInput>
                    </QuestionContainer>
                  ))}
              </Flexcol>
              <Flexrow>
                <Divider
                  type="vertical"
                  style={{
                    height: '100%',
                    borderLeft: '2px solid #64737f',
                    marginLeft: 38,
                    marginRight: 30,
                  }}
                />
                <Flexcol>
                  <Title
                    level={5}
                    style={{
                      margin: 0,
                      color: '#182429',
                    }}
                  >
                    Attachments
                  </Title>
                  {attachments_length ? (
                    <Flexcol top="12px">
                      {allDiscussionThreads.length > 0 &&
                        allDiscussionThreads.map((discussionThread) =>
                          discussionThread.thread_items
                            .filter(
                              (threadItem) => threadItem.status === 'PUBLISHED',
                            )
                            .map((threadItem) =>
                              threadItem.discussion_thread_attachments.map(
                                (attachment) => (
                                  <AttachmentWrap>
                                    <img
                                      src={PdfSrc}
                                      style={{
                                        marginRight: 8,
                                      }}
                                    />
                                    <Text underline>
                                      <a
                                        href={`${RAZZLE_MOBITO_FRONTEND_S3_PUBLIC_ENDPOINT}/${public_bucket}/data-rooms/${attachment.path}`}
                                        download={attachment.filename}
                                        target="_blank"
                                      >
                                        {attachment.filename}
                                      </a>
                                    </Text>
                                  </AttachmentWrap>
                                ),
                              ),
                            ),
                        )}
                    </Flexcol>
                  ) : (
                    <Text
                      level={4}
                      style={{
                        color: '#182429',
                      }}
                    >
                      {attachments_length} attachments
                    </Text>
                  )}
                </Flexcol>
              </Flexrow>
            </Flexrow>
            {userType === user_types.CONSUMER && (
              <Flexcol
                style={{
                  border: '2px dashed #000000',
                  padding: '28px 0px 40px',
                  width: '75%',
                  cursor: 'pointer',
                }}
                center_align
                onClick={() => setIsOpen(false)}
              >
                <Title
                  level={5}
                  style={{
                    marginBottom: 26,
                    color: '#182429',
                  }}
                >
                  Have more questions?
                </Title>
                <Flexrow center_align>
                  <img src={AddQuestionSrc} style={{ marginRight: 18 }} />
                  <Text>Add question item</Text>
                </Flexrow>
              </Flexcol>
            )}
          </>
        )}
      </ChatContainer>
      <style>{`.ant-switch-checked { background-color: #5FDF3E !important; }`}</style>
    </>
  );
};

export default Chat;
