import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const IntellectualPropertyTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        I
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        NTELLECTUAL{' '}
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>P</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ROPERTY</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              All Intellectual Property Rights related to the Platform shall be
              the exclusive property of Mobito or its licensors. There is no
              transfer of Intellectual Property Rights to the Client.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              All Intellectual Property Rights in the Data, if any, shall remain
              vested in the Data Provider. In the event the Data is not subject
              to Intellectual Property Rights, the Data Provider shall still be
              considered the owner of the Data. Mobito shall receive a license
              to handle such Data (including by storing, modifying, aggregating
              or taking any other action as permitted by this Agreement (such as
              handling Data to provide Data Preparation Services) for the
              purposes of this Agreement and for the duration of the Agreement.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default IntellectualPropertyTerms;
