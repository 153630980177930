import React, { useEffect } from "react";
import styled from "styled-components";
import { Typography, Switch } from "antd";

import { ArrowDownward } from "styled-icons/material";

import { scrollStyles } from "../../../styles/scrollStyles";
import { Flexrow } from "../../Flexbox";

const { Text } = Typography;

const Wrap = styled.div`
  padding: 25px 20px;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 100%;
`;

const LicenseWrap = styled.div`
  position: relative;
  background-color: #fff;
  text-align: center;
  padding: 15px;
  overflow-y: auto;
  height: 45vh;
  ${scrollStyles};
`;

const ScrollContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 165px;
  height: 38px;
  background: #fd7f2d;
  z-index: 1000;
  position: sticky;
  bottom: 20px;
  float: right;
`;

const ScrollButton = () => (
  <ScrollContainer middle="xs">
    <Text style={{ color: "#fff", fontSize: "12px", marginRight: "8px" }}>
      Scroll to bottom
    </Text>
    <ArrowDownward size="16" style={{ color: "#fff" }} />
  </ScrollContainer>
);

const LicenseAndAgreement = (props) => {
  const {
    license,
    handleScroll,
    reachedBottom,
    activateLicense,
    handleClick,
    handleLicense,
    endofPageRef,
    setReachedBottom,
  } = props;

  useEffect(() => {
    let licenseScrollContainer;
    if (typeof window !== "undefined") {
      licenseScrollContainer = document.getElementById(
        "license-scroll-container"
      );
      if (licenseScrollContainer) {
        if (
          licenseScrollContainer.scrollHeight ===
          licenseScrollContainer.clientHeight
        ) {
          setReachedBottom(true);
        }
      }
    }
  }, []);

  return (
    <Wrap>
      <LicenseWrap onScroll={handleScroll} id="license-scroll-container">
        <Text
          style={{
            color: "#192a33",
            fontSize: "18px",
            fontWeight: "600",
            display: "block",
            marginBottom: "18px",
          }}
        >
          Terms and Conditions
        </Text>
        <div
          id="license-textarea"
          style={{ textAlign: "left" }}
          dangerouslySetInnerHTML={{
            __html: license,
          }}
        ></div>
        {!reachedBottom && (
          <button style={{ display: "contents" }} onClick={handleClick}>
            <ScrollButton />
          </button>
        )}
        <Flexrow
          center_content
          top="12px"
          bottom="12px"
          style={{
            padding: "12px 0px",
            border: !activateLicense
              ? "1px solid #64737F"
              : "1px solid #CEFAC3",
            backgroundColor: !activateLicense ? "#fff" : "#CEFAC3",
          }}
        >
          <Switch onChange={handleLicense} checked={activateLicense} />
          <Text
            style={{
              color: "#64737F",
              fontSize: "13px",
              marginLeft: "16px",
            }}
          >
            Accept Terms & Conditions
          </Text>
        </Flexrow>
        <div>
          <div ref={endofPageRef} />
        </div>
      </LicenseWrap>
    </Wrap>
  );
};

export default LicenseAndAgreement;
