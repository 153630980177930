import React from 'react';
import { Col } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import {
  Title,
  Desc,
  StepperRow,
  StepperCircleIndex,
  StepperCircleCheck,
  StepperLine,
} from '../../wizards-components/styled-components';

const Step = (props) => {
  const isActive = props.current === props.index - 1;
  const isLast = props.index === props.total;
  const isValidOne = props.validOne;
  const isValidTwo = props.validTwo;
  const isValidThree = props.validThree;

  const opacityTwo = isValidOne && props.index == 2 ? true : false;
  const opacityThree = isValidTwo && props.index == 3 ? true : false;

  const stepperCircleHandler = () => {
    let stepperCircle;
    if (
      isValidOne === props.index ||
      isValidTwo === props.index ||
      isValidThree === props.index
    ) {
      return (stepperCircle = (
        <StepperCircleCheck active={isActive}>
          <Title size={22} color={isActive ? '#344ef3' : '#64737f'}>
            <CheckOutlined />
          </Title>
        </StepperCircleCheck>
      ));
    } else {
      return (stepperCircle = (
        <StepperCircleIndex
          active={isActive}
          style={
            opacityTwo ||
            opacityThree ||
            props.index == 1 ||
            props.index <= props.step
              ? { opacity: 1 }
              : { backgroundColor: '#e2e7ec', cursor: 'not-allowed' }
          }
        >
          <Title size={22} weight={isActive ? 'bold' : 'normal'} color={'#fff'}>
            {props.index}
          </Title>
        </StepperCircleIndex>
      ));
    }
  };

  return (
    <StepperRow onClick={props.change}>
      <Col
        span={18}
        style={
          opacityTwo ||
          opacityThree ||
          props.index == 1 ||
          props.index <= props.step
            ? { opacity: 1, cursor: 'pointer' }
            : { opacity: 0.5, cursor: 'not-allowed' }
        }
      >
        <Title size={15} weight="bold" color={isActive ? '#344ef3' : '#c1c2c2'}>
          {props.title}
        </Title>
        <Desc
          display="block"
          size={14}
          color={isActive ? '#64737f' : '#c1c2c2'}
        >
          {props.description}
        </Desc>
      </Col>
      <Col span={4} offset={2} style={{ flex: 0 }}>
        {stepperCircleHandler()}
        {!isLast && <StepperLine />}
      </Col>
    </StepperRow>
  );
};

export default Step;
