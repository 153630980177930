import React, { useState, useEffect } from 'react';
import { Flexrow } from '../../Flexbox';
import { Typography, Tooltip } from 'antd';
import {
  postDiscussionThread,
  deleteDiscussionThread,
} from '../chat/chat-services';
import styles from '../assets/CustomCheckbox.module.css';
import { strings } from '../assets/strings';
import { UnselectItemModal } from '../modals/UnselectItemModal';
import Cookies from 'js-cookie';

const { Title } = Typography;

export const GeneralQuestion = (props) => {
  const {
    title,
    selectable,
    readOnly,
    generalSelections,
    category,
    dataRoomId,
    setRefresh,
    setGeneralQuestionChecked,
  } = props;
  const [checked, setChecked] = useState(false);
  const [discussionThreadId, setDiscussionThreadId] = useState();
  const [removableItem, setRemovableItem] = useState(true);
  const [draftItem, setDraftItem] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const token = Cookies.get('jwt');

  const handleSelection = (discussionThreadId) => {
    if (checked) {
      deleteDiscussionThread(token, discussionThreadId)
        .then((res) => {
          setRefresh((prevState) => !prevState);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      postDiscussionThread(
        token,
        dataRoomId,
        strings.general_question.title,
        category,
      )
        .then((res) => {
          setDiscussionThreadId((discussionThreadId = res.data.id));
          setRefresh((prevState) => !prevState);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemovable = () => {
    setVisibleModal(!visibleModal);
  };

  useEffect(() => {
    if (generalSelections) {
      setChecked(
        generalSelections[0]?.checked ? generalSelections[0]?.checked : false,
      );
      setGeneralQuestionChecked(
        generalSelections[0]?.checked ? generalSelections[0]?.checked : false,
      );

      setRemovableItem(generalSelections[0]?.removable);
      setDraftItem(generalSelections[0]?.draft);
      setDiscussionThreadId(generalSelections[0]?.discussionThreadId);
    }
  }, [generalSelections]);

  return selectable && readOnly !== true ? (
    <div>
      <Flexrow center_align bottom="15px" left="0px">
        <Title
          level={4}
          style={{
            marginBottom: 0,
            marginLeft: 0,
            paddingRight: '35px',
            color: '#182a33',
          }}
        >
          {title}
        </Title>
        {removableItem === undefined && draftItem === undefined && (
          <input
            key={`cb-0`}
            className={styles.CheckboxRound}
            type="checkbox"
            id={'checkbox-0'}
            checked={checked}
            onChange={() => handleSelection(discussionThreadId)}
          />
        )}
        {removableItem === false && readOnly === undefined && (
          <Tooltip
            color="rgba(26, 51, 125, 1)"
            placement="bottom"
            title={strings.clarifications.tooltip_active_discussion_thread}
          >
            <span>
              <input
                key={`cb-1`}
                className={styles.CheckboxRound}
                type="checkbox"
                id={'checkbox-1'}
                checked={checked}
                disabled
              />
            </span>
          </Tooltip>
        )}
        {removableItem === true && readOnly === undefined && (
          <>
            {draftItem ? (
              <>
                <UnselectItemModal
                  discussionThreadId={discussionThreadId}
                  visibleModal={visibleModal}
                  setVisibleModal={setVisibleModal}
                  setRefresh={setRefresh}
                ></UnselectItemModal>
                <input
                  key={`cb-2`}
                  className={styles.CheckboxRound}
                  type="checkbox"
                  id={'checkbox-2'}
                  checked={checked}
                  onChange={() => handleRemovable()}
                />
              </>
            ) : (
              <input
                key={`cb-3`}
                className={styles.CheckboxRound}
                type="checkbox"
                id={'checkbox-3'}
                checked={checked}
                onChange={() => handleSelection(discussionThreadId)}
              />
            )}
          </>
        )}
        <span style={{ marginLeft: '8px' }}>
          {strings.general_question.description}
        </span>
      </Flexrow>
    </div>
  ) : (
    <Flexrow center_align bottom="15px" left="0px">
      <Title
        level={4}
        style={{
          marginBottom: 0,
          marginLeft: 0,
          paddingRight: '35px',
          color: '#182a33',
        }}
      >
        {title}
      </Title>
      {readOnly === true && (
        <>
          <input
            key={`cb-4`}
            className={styles.CheckboxRound}
            type="checkbox"
            id={'checkbox-4'}
            checked={checked}
            disabled
          />
          <span style={{ marginLeft: '8px' }}>
            {strings.general_question.description}
          </span>
        </>
      )}
    </Flexrow>
  );
};
