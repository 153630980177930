import {
  TermsAndConditionsContainer,
  TermsAndConditionsSection,
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsParagraph,
  TermsAndConditionsLink,
} from '../styles/termsAndConditions';
import { TermsPubliclyAvailble } from './terms/TermsPubliclyAvailable';
import { TermsOfUse } from './terms/TermsOfUse';

const TermsAndConditions = () => {
  return (
    <TermsAndConditionsContainer>
      <TermsAndConditionsSection center>
        <TermsAndConditionsHeaderLarge>
          Terms and Conditions for Publicly Available information
        </TermsAndConditionsHeaderLarge>
      </TermsAndConditionsSection>
      <TermsAndConditionsSection>
        <TermsAndConditionsParagraph>
          These Terms and Conditions govern your use of the{' '}
          <TermsAndConditionsLink href="https://mobito.io" target="_blank">
            https://mobito.io
          </TermsAndConditionsLink>{' '}
          ("Platform") when you are browsing publicly available information
          without having an account as either Data Provider or Data Customer on
          the Platform. If you do have an account, your use is governed by the
          relevant agreement concluded between you and Mobito, meaning Mobito
          Technology SRL, with a registered seat at Chaussee de Boitsfort 48,
          1050 Brussels, Belgium, and VAT number 0747.793.289.
        </TermsAndConditionsParagraph>
      </TermsAndConditionsSection>
      <TermsAndConditionsSection>
        <TermsPubliclyAvailble></TermsPubliclyAvailble>
        <TermsOfUse></TermsOfUse>
      </TermsAndConditionsSection>
    </TermsAndConditionsContainer>
  );
};

export default TermsAndConditions;
