import React, { useEffect, useContext, useState } from 'react';
import { Button, Modal, Table, Typography, Tooltip } from 'antd';
import styled from 'styled-components';

import { ArrowRightOutlined } from '@ant-design/icons';
import { FileEarmarkText } from '@styled-icons/bootstrap/FileEarmarkText';
import { Server } from '@styled-icons/bootstrap/Server';
import { CloudDownload } from '@styled-icons/bootstrap/CloudDownload';
import { FileSpreadsheet } from '@styled-icons/bootstrap/FileSpreadsheet';
import { GeoAlt } from '@styled-icons/bootstrap/GeoAlt';
import { Globe } from '@styled-icons/bootstrap/Globe';
import { HourglassBottom } from '@styled-icons/bootstrap/HourglassBottom';

import { Flexcol, Flexrow } from '../../../../components/Flexbox';
import ItemCard from '../../../../components/listing-components/ItemCard';
import { Chip } from '../../../../components/listing-components/Chip';
import { scrollStyles } from '../../../../styles/scrollStyles';
import TextModal from '../../../../components/listing-components/TextModal';
import { DataProductContext } from '../../../../helpers/DataProductWizardHelper';
import FrequencySrc from '../../../../images/frequency.svg';
import InfoBlackSrc from '../../../../images/info_black.svg';
import DataParametersModal from '../../../../components/listing-components/DataParametersModal';

const { Text } = Typography;

const DescriptionContainer = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  ${scrollStyles}
`;

const Chips = styled(Flexrow)`
  flex-wrap: wrap;
`;

const ItemCardChildList = styled.ul`
  padding-left: 26px;
`;

const ItemCardChildListItem = styled.li`
  color: #182429;
  font-size: 14px;
  font-weight: bold;
`;

const ItemCardChildListItemContent = styled.div`
  margin-bottom: 21px;
`;

const EditViewDataAttributes = styled.button`
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  width: fit-content;
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
`;

const ParametersButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  background: #fff !important;
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
`;

const OkModalButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    background-color: #fff !important;
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    cursor: not-allowed;
  }
`;

const FrequencyChips = styled(Flexrow)`
  background-color: #f3f3f4;
  border-radius: 100px;
  padding: 5px 16px;
  margin-right: 12px;
`;

const ItemCardChildTitle = styled.span`
  color: #182a33;
  font-size: 13px;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
  width: ${(props) => props.width && props.width};
`;

const ItemCardChildParameter = styled.span`
  display: block;
  width: ${(props) => props.width && props.width};
`;

const getScreenWidthHandler = () => {
  let width;
  if (screen.width <= 768) {
    return (width = '95vw');
  } else if (screen.width > 768 && screen.width <= 2560) {
    return (width = '75vw');
  } else if (screen.width > 2560) {
    return (width = '50vw');
  } else {
    return (width = '75vw');
  }
};

const nameTrimHandler = (attributeNames) => {
  const maxAttributeNames = 10;

  const filteredAttributeNames = attributeNames.slice(0, maxAttributeNames);

  if (attributeNames.length > maxAttributeNames) {
    return (
      filteredAttributeNames.join(', ') +
      ' + ' +
      (attributeNames.length - maxAttributeNames) +
      ' more ...'
    );
  } else {
    return filteredAttributeNames.join(', ');
  }
};

const tempNameTrimHandler = (name) => {
  let maxLength = 40;
  if (name?.length > maxLength) {
    return name.substring(0, maxLength) + '...';
  } else {
    return name;
  }
};

const MAX_DISPLAYED_PROJECT_DATA_SPECS_PARAMS = 5;

const columns = [
  {
    title: 'ATTRIBUTE',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'UNIT/PRECISION',
    dataIndex: 'unit_precision',
    key: 'unit_precision',
  },
];

const OverviewTab = (props) => {
  const { setHasContent, setTab } = props;
  const [isAttributesModalVisible, setIsAttributesModalVisible] =
    useState(false);
  const helper = useContext(DataProductContext);
  const projectDescription =
    helper.general_description.value || helper.project.placement.description;
  const projectDataSpecsVolume = helper.data_specs_data_volume.value;
  const projectDataSpecsSources = helper.data_specs_sources.value;
  const projectAttributesParams = helper.attributes.value;
  const projectDataGeoCoverage = helper.data_specs_geo_coverage.value;
  const projectDataLanguage = helper.data_specs_language.value;
  const projectDataAvailability = helper.data_specs_availability.value;
  const projectCaptureFrequency = helper.capture_frequency.value;
  const projectTransmissionFrequency = helper.transmission_frequency.value;
  const projectDataSpecsParams = helper.data_specs_params.value;
  const displayProjectDataSpecsParams = projectDataSpecsParams.slice(
    0,
    MAX_DISPLAYED_PROJECT_DATA_SPECS_PARAMS,
  );

  const checkProjectDataAvailability = (dataAvailability) =>
    projectDataAvailability[dataAvailability] != null;

  const checkObject = () => {
    if (
      Object.values(projectDataAvailability).filter((key) => key === null)
        .length === 3 ||
      Object.values(projectDataAvailability).length === 0
    ) {
      return false;
    }
    return true;
  };

  const isCaptureEmpty = Object.values(projectCaptureFrequency).every(
    (x) => x.key === '' || x.value === '',
  );

  const isTransmissionEmpty = Object.values(projectTransmissionFrequency).every(
    (x) => x.key === '' || x.value === '',
  );

  const hasContent =
    !!projectDescription ||
    !!projectDataSpecsVolume.length ||
    !!projectDataSpecsSources.length ||
    !!projectAttributesParams.length ||
    !!projectDataGeoCoverage.length ||
    !!projectDataLanguage.length ||
    !isCaptureEmpty ||
    !isTransmissionEmpty ||
    !!displayProjectDataSpecsParams.length ||
    checkObject();

  useEffect(() => {
    setHasContent((prevState) => {
      return { ...prevState, overview: hasContent };
    });
    if (!hasContent) {
      setTab('details');
    }
  }, []);

  const handleTextModal = () => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <TextModal
          name={helper.name.value}
          title="Data product description"
          text={projectDescription}
        />
      ),
      okText: 'Close',
      closable: true,
      centered: true,
      style: { paddingBottom: 0 },
      width: getScreenWidthHandler(),
    });
  };

  const handleAttributesOk = () => {
    setIsAttributesModalVisible(false);
  };

  const handleAttributesCancel = () => {
    setIsAttributesModalVisible(false);
  };

  const handleDataParametersModal = () => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <DataParametersModal
          name={helper.name.value}
          title="Data attributes"
          parameters={projectDataSpecsParams}
        />
      ),
      okText: 'Close',
      closable: true,
      centered: true,
      style: { paddingBottom: 0 },
      width: getScreenWidthHandler(),
    });
  };

  const continentStringFormat = (string) => {
    let splitString = string.toLowerCase().replace('_', ' ').split(' ');

    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join(' ');
  };

  return (
    <>
      <Modal
        title={
          <Text
            style={{
              fontWeight: 600,
              color: '#182429',
              fontSize: 17,
              paddingLeft: 16,
            }}
          >
            Data attributes
          </Text>
        }
        visible={isAttributesModalVisible}
        onOk={handleAttributesOk}
        onCancel={handleAttributesCancel}
        centered={true}
        style={{ paddingBottom: '0px' }}
        bodyStyle={{
          backgroundColor: '#F9FAFF',
          padding: '0px',
          height: '80vh',
          overflowY: 'auto',
        }}
        width="100%"
        destroyOnClose={true}
        footer={[
          <OkModalButton key="submit" onClick={handleAttributesOk}>
            OK
          </OkModalButton>,
        ]}
        zIndex={1000001}
      >
        <Flexcol style={{ padding: '16px 40px' }}>
          <div>
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <Flexrow space_between>
                    <Flexcol style={{ width: '48%' }}>
                      <Text style={{ color: '#757575', textSize: 13 }}>
                        DESCRIPTION
                      </Text>
                      <Text style={{ color: '#182429', textSize: 14 }}>
                        {record.description}
                      </Text>
                    </Flexcol>
                    <Flexcol style={{ width: '48%' }}>
                      <Text style={{ color: '#757575', textSize: 13 }}>
                        EXAMPLE
                      </Text>
                      <Text style={{ color: '#182429', textSize: 14 }}>
                        {record.example}
                      </Text>
                    </Flexcol>
                  </Flexrow>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
              }}
              dataSource={projectAttributesParams}
              pagination={false}
              bordered
            />
          </div>
        </Flexcol>
      </Modal>
      <Flexcol>
        {projectDescription && (
          <ItemCard
            title="Data product description"
            icon={<FileEarmarkText />}
            openTextModal={handleTextModal}
          >
            <DescriptionContainer
              dangerouslySetInnerHTML={{
                __html: projectDescription,
              }}
            ></DescriptionContainer>
          </ItemCard>
        )}
        {!!projectDataSpecsVolume.length && (
          <ItemCard title="Data size" icon={<Server />}>
            {projectDataSpecsVolume}
          </ItemCard>
        )}
        {!!projectDataSpecsSources.length && (
          <ItemCard title="Data sources" icon={<CloudDownload />}>
            <Chips>
              {projectDataSpecsSources.map((dataSource, index) => (
                <Chip key={index}>{dataSource}</Chip>
              ))}
            </Chips>
          </ItemCard>
        )}
        {!!projectAttributesParams.length ? (
          <ItemCard title="Data attributes" icon={<FileSpreadsheet />}>
            <Flexrow style={{ width: '60%' }}>
              <Text
                style={{
                  color: '#182429',
                  fontSize: 14,
                }}
              >
                {nameTrimHandler(
                  projectAttributesParams?.map((dataSpec) => dataSpec.name),
                )}
              </Text>
            </Flexrow>
            <Flexrow top="16px" />
            <EditViewDataAttributes
              type="button"
              onClick={() => setIsAttributesModalVisible(true)}
            >
              <Flexrow center_align>
                View data attributes
                <ArrowRightOutlined style={{ marginLeft: 12 }} />
              </Flexrow>
            </EditViewDataAttributes>
          </ItemCard>
        ) : (
          !!displayProjectDataSpecsParams.length && (
            <ItemCard title="Data attributes" icon={<FileSpreadsheet />}>
              <Flexrow center_align>
                <ItemCardChildTitle width="55%">Attribute</ItemCardChildTitle>
                <ItemCardChildTitle width="45%">
                  Update frequency
                </ItemCardChildTitle>
              </Flexrow>
              {displayProjectDataSpecsParams.map((dataParam, index) => (
                <Flexrow center_align key={index}>
                  <ItemCardChildParameter width="55%">
                    {tempNameTrimHandler(dataParam.name)}
                  </ItemCardChildParameter>
                  <ItemCardChildParameter width="45%">
                    {tempNameTrimHandler(dataParam.frequency)}
                  </ItemCardChildParameter>
                </Flexrow>
              ))}
              <ParametersButton onClick={() => handleDataParametersModal()}>
                See all parameters
              </ParametersButton>
            </ItemCard>
          )
        )}
        {(!isCaptureEmpty || !isTransmissionEmpty) && (
          <ItemCard title="Data frequency" icon={<img src={FrequencySrc} />}>
            {!isCaptureEmpty && (
              <>
                <Text
                  style={{ color: '#182429', fontWeight: 500, fontSize: 14 }}
                >
                  Capturing frequency:
                </Text>
                <Flexrow top="16px" />
                <Flexrow wrap>
                  {projectCaptureFrequency.map((freq, index) => (
                    <FrequencyChips center_align bottom="8px" key={index}>
                      <Text
                        style={{
                          color: '#182429',
                          fontSize: 14,
                          fontweight: 500,
                          lineHeight: 'initial',
                        }}
                      >
                        {freq.key} {freq.value}
                      </Text>
                      {freq.comment && (
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          placement="bottom"
                          title={freq.comment}
                        >
                          <img src={InfoBlackSrc} style={{ marginLeft: 24 }} />
                        </Tooltip>
                      )}
                    </FrequencyChips>
                  ))}
                </Flexrow>
              </>
            )}
            <Flexrow top="16px" />
            {!isTransmissionEmpty && (
              <>
                <Text
                  style={{ color: '#182429', fontWeight: 500, fontSize: 14 }}
                >
                  Transmission frequency:
                </Text>
                <Flexrow top="16px" />
                <Flexrow wrap>
                  {projectTransmissionFrequency.map((trans, index) => (
                    <FrequencyChips center_align bottom="8px" key={index}>
                      <Text
                        style={{
                          color: '#182429',
                          fontSize: 14,
                          fontweight: 500,
                          lineHeight: 'initial',
                        }}
                      >
                        {trans.key} {trans.value}
                      </Text>
                      {trans.comment && (
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          placement="bottom"
                          title={trans.comment}
                        >
                          <img src={InfoBlackSrc} style={{ marginLeft: 24 }} />
                        </Tooltip>
                      )}
                    </FrequencyChips>
                  ))}
                </Flexrow>
              </>
            )}
          </ItemCard>
        )}
        {!!projectDataGeoCoverage.length && (
          <ItemCard title="Geographical coverage" icon={<GeoAlt />}>
            <Chips>
              {projectDataGeoCoverage.map((dataGeoCoverage, index) => (
                <Chip key={index}>
                  {continentStringFormat(dataGeoCoverage)}
                </Chip>
              ))}
            </Chips>
          </ItemCard>
        )}
        {!!projectDataLanguage.length && (
          <ItemCard title="Language" icon={<Globe />}>
            <Chips>
              {projectDataLanguage.map((dataLanguage, index) => (
                <Chip key={index}>{dataLanguage}</Chip>
              ))}
            </Chips>
          </ItemCard>
        )}
        {checkObject() && (
          <ItemCard title="Temporal availability" icon={<HourglassBottom />}>
            <ItemCardChildList>
              {checkProjectDataAvailability('real_time') && (
                <>
                  <ItemCardChildListItem>Real time Data</ItemCardChildListItem>
                  <ItemCardChildListItemContent>
                    {projectDataAvailability['real_time']}
                  </ItemCardChildListItemContent>
                </>
              )}
              {checkProjectDataAvailability('historical') && (
                <>
                  <ItemCardChildListItem>Historical</ItemCardChildListItem>
                  <ItemCardChildListItemContent>
                    {projectDataAvailability['historical']}
                  </ItemCardChildListItemContent>
                </>
              )}
              {checkProjectDataAvailability('forecast') && (
                <>
                  <ItemCardChildListItem>Forecast Data</ItemCardChildListItem>
                  <ItemCardChildListItemContent>
                    {projectDataAvailability['forecast']}
                  </ItemCardChildListItemContent>
                </>
              )}
            </ItemCardChildList>
          </ItemCard>
        )}
      </Flexcol>
    </>
  );
};

export default OverviewTab;
