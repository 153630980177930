import React, { useContext } from "react";
import { Row, Col } from "antd";

import { DataRequestContext } from "../../helpers/DataRequestWizardHelper";
import { MainHeader } from "../wizards-components/styled-components";
import { BasicRequestDetails } from "./views/BasicRequestDetails";

export const DataRequestWizard = () => {
  const helper = useContext(DataRequestContext);
  const marginBottom = helper.published || helper.for_review;

  return (
    <div style={{ margin: "0 16px" }}>
      <MainHeader
        style={
          marginBottom ? { margin: "60px 0 0 0" } : { margin: "60px 0 54px 0" }
        }
      >
        <Row>
          <Col span={15} offset={3}>
            Create new data request
          </Col>
        </Row>
      </MainHeader>
      <BasicRequestDetails />
    </div>
  );
};
