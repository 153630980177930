import { Typography } from 'antd';
import Cookies from 'js-cookie';

import { Flexrow } from '../../Flexbox';
import { postThreadItem, publishThreadItem } from './chat-services';

const { Text } = Typography;

export const SendThreadItem = (props) => {
  const {
    text,
    imgSrc,
    discussionThreadId,
    threadItemId,
    threadContent,
    setRefresh,
    setRefreshDrawer,
    setThreadItemContent,
    isSaving,
    userType,
    isResolved,
  } = props;

  const token = Cookies.get('jwt');

  const publishDiscussionThreadHandler = async (
    threadItemId,
    threadContent,
    isResolved,
  ) => {
    if (!isResolved) {
      await publishThreadItem(token, threadItemId, threadContent, userType);
      setRefresh((prevState) => !prevState);
      setRefreshDrawer((prevState) => !prevState);
      setThreadItemContent('');
      await postThreadItem(token, '', discussionThreadId, userType);
    }
  };

  return (
    <>
      <Flexrow
        center_align
        bottom="10px"
        style={{
          width: 160,
          cursor: isSaving || isResolved ? 'not-allowed' : 'pointer',
        }}
        onClick={() =>
          publishDiscussionThreadHandler(
            threadItemId,
            threadContent,
            isResolved,
          )
        }
      >
        <img src={imgSrc} style={{ marginRight: 10 }} />
        <Text>{text}</Text>
      </Flexrow>
    </>
  );
};
