export const strings = {
  steps_strings: {
    next_step: 'Next step:',
    previous_step: 'Previous step:',
    previous: 'Previous',
    next: 'Next',
    submit: 'Submit',
  },
  consumer_steps_strings: {
    step_one: 'DATASET DETAILS',
    step_two: 'CLARIFICATIONS',
    step_three: 'CREATE REQUEST',
    step_four: 'CONFIRM & SEND',
    step_five: 'COMPLETE PURCHASE',
    step_six: 'ACCESS DATASET',
  },
  provider_steps_strings: {
    step_one: 'Chat with consumer',
    step_two: 'Receive offer',
  },
  data_room_header: {
    data_room: 'Data room:',
    between: 'Between:',
    need_help: 'Need help?',
    contact_mobito: 'Contact Mobito',
  },
  content_stepper_header: {
    how_does_it_work: 'How does this step work?',
  },
  dataset_details: {
    product_desc: 'Product description',
    attributes: 'Attributes',
    attributes_full_page: 'Full page view',
    attributes_standard: 'Standard attributes',
    geocoverage: 'Geocoverage',
    dataset_properties: 'Dataset properties',
    dataset_properties_data_size: 'DATA SIZE',
    dataset_properties_data_source: 'DATA SOURCE',
    dataset_properties_language: 'LANGUAGE',
    dataset_properties_delivery_method: 'DELIVERY METHOD',
    dataset_properties_format: 'FORMAT',
    attachments: 'Attachments',
  },
  clarifications: {
    consumer_title: 'Select items for questions',
    consumer_description: `If you need clarifications for this data product, select below the items
    you wish to add to your questions list. If you don't wont to clarifiy
    anything, you can skip this and move to the next step.`,
    provider_title: '',
    provider_description:
      'Here you chat with the Consumer. You receive questions regarding specific info items of your data offering (i.e an attribute), you reply and continue the discussion with the consumer in the chat that can be opened at the bottom of the page',
    tooltip_active_discussion_thread:
      'There is an active conversation in the chat about this item',
  },
  create_requests: {
    title: 'CREATE REQUESTS',
    headline: 'Create your requests',
    description:
      'Here you can create your data request and send it to the data provider in order to receive an offer. Create different requests in case the have different secifications (e.g. different attributes)',
    addRequest: 'Add request',
    addRequestHeadline: 'Add your first request',
  },
  create_request_wizard: {
    header: 'Create request',
    title: 'RFP OFFER',
    headline: 'Request #',
    completed: 'Completed',
    cancel: 'Cancel',
    create: 'Create',
    edit: 'View/Edit',
    geocoverage: {
      title: 'GEOCOVERAGE',
      subtitle: 'Which locations?',
      addLocations: 'Add locations >',
      description: 'Start by adding the specific locations for this package',
      header: 'Add locations',
    },
    attributes: {
      title: 'ATTRRIBUTES',
      subtitle: 'For which attributes?',
      addAttributes: 'Add attributes >',
      description: "You haven't added any attributes yet.",
      header: 'Add attributes',
    },
    volume: {
      title: 'VOLUME',
      subtitle: 'Which and how many assets?',
      addVolume: 'Add volume >',
      description: "You haven't added any assets yet.",
      header: 'Add volume',
    },
    timeframes: {
      title: 'TIMEFRAMES',
      subtitle: 'Add timeframes & delivery method',
      addTimeframes: 'Add timeframes >',
      description: "You haven't added more info yet.",
      header: 'Add timeframes',
    },
  },
  general_question: {
    title: 'General question',
    description: 'Ask general question',
  },
  modal_unselect_items: {
    title: 'Unselect item ?',
    ok_text: 'Yes, unselect',
    cancel_text: 'No, cancel',
    content:
      'There is a draft question about this item in the chat that will be deleted if you proceed. Are you sure you want to unselect it ?',
  },
  discussion_threads_categories: {
    attributes: 'Attributes',
    geocoverage: 'Geocoverage',
    datasetProperties: 'DatasetProperties',
    attachments: 'GeneralAttachments',
    generalAttributes: 'GeneralAttributes',
    generalGeocoverage: 'GeneralGeocoverage',
    generalDatasetProperties: 'GeneralDatasetProperties',
  },
};
