import axios from 'axios';

export const fetcher = async (url) =>
  axios(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((r) => r.data);

export const fetcherWithToken = async (url, token) =>
  axios(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((r) => r.data);

export const fetcherWithParams = (url) =>
  axios(url, {
    method: 'POST',
    data: JSON.stringify({ text: '__ALL__' }),
    headers: { 'Content-Type': 'application/json' },
  }).then((r) => r.data);

export const getAllModules = (url) =>
  axios(url, {
    method: 'POST',
    data: JSON.stringify({ text: '__ALL__' }),
    headers: { 'Content-Type': 'application/json' },
  }).then((r) => r.data);
