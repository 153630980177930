import ProductDescriptionModal from '../modals/ProductDescriptionModal';
import { getScreenWidthHandler } from '../helpers/getScreenWidthHandler';
import { Modal } from 'antd';

export const handleProductDescriptionModal = (productDescription) => {
  Modal.info({
    title: null,
    icon: null,
    content: (
      <ProductDescriptionModal
        name=""
        title="Product description"
        text={productDescription}
      />
    ),
    okText: 'Close',
    closable: true,
    centered: true,
    style: { paddingBottom: 0 },
    width: getScreenWidthHandler(),
  });
};
