import styled, { css } from 'styled-components';

const TooltipText = styled.div`
  display: flex;
  width: 100%;
  justify-content: inherit;
`;

const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 10px;
  visibility: hidden;
  white-space: nowrap;
  /* transition: visibility 0.1s, color 0.1s, background-color 0.1s, width 0.1s, padding 0.1s ease-in-out;
   &:before {
    content: '';
    width: 0;
    height: 0;
    left: 5px;
    top: -5px;
    position: absolute;
    border: 5px solid transparent;
    transform: rotate(135deg);
    transition: border 0.5s ease-in-out;
  } */
  > div {
    overflow: hidden;
  }
`;

const TooltipCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: inherit;
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.8);
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    /* &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8);
    } */
  }
`;

const Tooltip = (props) => {
  return (
    <>
      {props.disabled ? (
        <>{props.children}</>
      ) : (
        <TooltipCard disabled={props.disabled}>
          <TooltipText {...props}>{props.children}</TooltipText>
          <TooltipBox>{props.message}</TooltipBox>
        </TooltipCard>
      )}
    </>
  );
};

export default Tooltip;
