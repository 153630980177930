import { useState } from 'react';

const useFormStore = (initialValues) => {
  const [form_data, setForm] = useState(initialValues);
  const [errors, setErrors] = useState();

  return [errors, setErrors, form_data, setForm];
};

export default useFormStore;
