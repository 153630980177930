import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Flexrow } from '../Flexbox';
import { DataRoomHeaderWrap } from './assets/styled-components';
import { strings } from './assets/strings';

const { Title } = Typography;

const DataRoomHeader = (props) => {
  const { dataRoomName } = props;

  return (
    <DataRoomHeaderWrap center_align space_between>
      <Flexrow>
        <Flexrow center_align right="96px">
          <Title
            level={5}
            style={{ marginBottom: 0, marginRight: 10, color: '#182a33' }}
          >
            {strings.data_room_header.data_room}
          </Title>
          <Title level={4} style={{ margin: 0, color: '#182a33' }}>
            {dataRoomName}
          </Title>
        </Flexrow>
      </Flexrow>
    </DataRoomHeaderWrap>
  );
};

export default DataRoomHeader;
