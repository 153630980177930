import React, { useState, useEffect } from 'react';
import { Typography, Modal, Button, Radio } from 'antd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { Flexcol } from '../../components/Flexbox';
import Notification from '../../components/Notification';
import instance from '../../services/TokenValidator';

const { Text } = Typography;

const CancelButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background: #344ef3 !important;
    border: 1px solid #344ef3 !important;
    color: #fff !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    background-color: #fff !important;
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    cursor: not-allowed;
  }
`;

export const ChangeRoleModal = (props) => {
  const {
    setChangeRoleModalVisible,
    changeRoleModalVisible,
    memberEmail,
    memberRole,
    memberId,
    memberType,
    token,
    setUpdated,
    setSelectDefaultValue,
    setUserRole,
  } = props;
  const [role, setRole] = useState('');
  const currentUserEmail = Cookies.get('email');

  useEffect(() => {
    setRole(memberRole);
  }, [memberRole]);

  const handleOk = () => {
    setChangeRoleModalVisible(false);
  };

  const handleCancel = () => {
    setChangeRoleModalVisible(false);
  };

  const onRoleChange = (e) => {
    setRole(e.target.value);
  };

  const saveRole = async () => {
    const deploymentDomain =
    process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';
    try {
      if (memberType === 'USER') {
        await instance.patch(
          `${process.env.RAZZLE_ACCOUNT_URL}/organizations/users/role`,
          {
            userId: memberId,
            role: role,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await instance.patch(
          `${process.env.RAZZLE_ACCOUNT_URL}/invitations/${memberId}`,
          {
            role: role,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
      setUpdated((prevState) => !prevState);
      setSelectDefaultValue('All members');
      if (currentUserEmail === memberEmail) {
        Cookies.set('role', role, { domain: deploymentDomain });
        setUserRole(role);
      }
      toast.success(
        <Notification
          type="success"
          text={`Role changed for ${memberEmail}`}
        />,
      );
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Something went wrong! Please try again later."
        />,
      );
    }
    setChangeRoleModalVisible(false);
  };

  return (
    <Modal
      title={
        <Flexcol top="20px" bottom="20px" left="18px">
          <Text
            style={{
              color: '#182A33',
              fontSize: '19px',
              fontWeight: '600',
              marginBottom: '4px',
            }}
          >
            Set role
          </Text>
          <Text style={{ color: '#64737F', fontSize: '15px' }}>
            Set member role for {memberEmail}
          </Text>
        </Flexcol>
      }
      visible={changeRoleModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ borderRadius: '8px' }}
      centered={true}
      destroyOnClose={true}
      width="680px"
      footer={[
        <CancelButton key="back" onClick={handleCancel}>
          Cancel
        </CancelButton>,
        <SubmitButton
          key="submit"
          onClick={saveRole}
          disabled={memberRole === role}
        >
          Save changes
        </SubmitButton>,
      ]}
      zIndex={1000000}
    >
      <Radio.Group
        onChange={onRoleChange}
        value={role}
        style={{ marginLeft: '18px' }}
      >
        <Flexcol>
          <Flexcol bottom="22px">
            <Radio value="ADMIN">
              <Text
                style={{
                  color: '#182A33',
                  fontSize: '15px',
                  fontWeight: '500',
                  display: 'block',
                }}
              >
                Admin
              </Text>
              <Text style={{ color: '#182A33', fontSize: '14px' }}>
                Admins have full access rights without limitations
              </Text>
            </Radio>
          </Flexcol>
          <Flexcol bottom="22px">
            <Radio value="MEMBER">
              <Text
                style={{
                  color: '#182A33',
                  fontSize: '15px',
                  fontWeight: '500',
                  display: 'block',
                }}
              >
                Member
              </Text>
              <Text style={{ color: '#182A33', fontSize: '14px' }}>
                Members do not have user management rights, cannot pay for
                consumption and cannot accept consumer access requests
              </Text>
            </Radio>
          </Flexcol>
        </Flexcol>
      </Radio.Group>
    </Modal>
  );
};
