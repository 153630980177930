import styled from "styled-components";

export const TermsAndConditionsContainer = styled.div`
  text-align: justify;
`;

export const TermsAndConditionsSection = styled.div`
  text-align: ${(props) => props.center && "center"};
  padding: 12px;
`;

export const TermsAndConditionsListSection = styled.div`
  display: ${(props) => (props.block ? "block" : "inline-flex")};
  margin-left: ${(props) => (props.marginLeft ? "0px" : "8px")};
  padding-inline-start: ${(props) => (props.paddingLeft ? "40px" : "0px")};
  flex: ${(props) => props.flexPercentage && "80%"};
  width: ${(props) => props.width && props.width};
`;

export const TermsAndConditionsHeaderLarge = styled.h1`
  display: inline;
  font-size: ${(props) => (props.smaller ? "22px" : "28px")};
  margin-left: ${(props) => (props.marginLeft ? "8px" : "0px")};
  font-weight: bold;
`;

export const TermsAndConditionsHeaderMedium = styled.h2`
  display: inline;
  font-size: 16px;
  font-weight: bold;
`;

export const TermsAndConditionsHeaderSmall = styled.h3`
  display: inline;
  font-weight: 600;
`;

export const TermsAndConditionsHeaderXSmall = styled.h3`
  display: inline;
  font-weight: 600;
  font-size: 1em
`;

export const TermsAndConditionsParagraph = styled.p`
  display: inline;
  white-space: break-spaces;
`;

export const TermsAndConditionsOrderedList = styled.ol`
  list-style: none;
  counter-reset: item;
  padding-inline-start: ${(props) => props.noPadding && props.noPadding};
`;

export const TermsAndConditionsOrderedListEnglish = styled.ol`
  counter-reset: list;
`;

export const TermsAndConditionsOrderedListRoman = styled.ol`
  counter-reset: list;
`;

export const TermsAndConditionsOrderedListItem = styled.li`
  display: ${(props) => (props.flex ? "flex" : "block")};
  counter-increment: item;
  &:before {
    content: ${(props) =>
      props.inner ? "counters(item, '.') ' '" : "counters(item, '.') '. '"};
  }
  flex-wrap: ${(props) => (props.flexWrap ? "wrap" : "nowrap")};
`;

export const TermsAndConditionsOrderedListItemEnglish = styled.li`
  display: ${(props) => (props.flex ? "flex" : "block")};
  counter-increment: list;
  &:before {
    content: "(" counter(list, lower-alpha) ") ";
  }
`;

export const TermsAndConditionsOrderedListItemRoman = styled.li`
  display: ${(props) => (props.flex ? "flex" : "block")};
  counter-increment: list;
  &:before {
    content: "(" counter(list, lower-roman) ") ";
  }
`;

export const TermsAndConditionsEmptySpace = styled.div`
  height: 12px;
`;

export const TermsAndConditionsLink = styled.a`
  text-decoration: underline;
`;
