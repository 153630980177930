import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { deleteDiscussionThread } from '../chat/chat-services';
import { strings } from '../assets/strings';
import Cookies from 'js-cookie';

export const UnselectItemModal = (props) => {
  const { discussionThreadId, visibleModal, setVisibleModal, setRefresh } =
    props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const token = Cookies.get('jwt');

  const modalText = strings.modal_unselect_items.content;

  const handleOk = () => {
    setConfirmLoading(true);
    deleteDiscussionThread(token, discussionThreadId)
      .then(() => {
        setTimeout(() => {
          setConfirmLoading(false);
          setVisible(false);
          setVisibleModal(false);
        }, 500);
        setRefresh((prevState) => !prevState);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleModal(false);
  };

  useEffect(() => {
    setVisible(visibleModal);
  }, [visibleModal]);

  return (
    <>
      <Modal
        title={strings.modal_unselect_items.title}
        visible={visible}
        onOk={handleOk}
        okText={strings.modal_unselect_items.ok_text}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelText={strings.modal_unselect_items.cancel_text}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};
