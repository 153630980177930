import { getScreenWidthHandler } from '../helpers/getScreenWidthHandler';
import { Modal } from 'antd';
import { RequestWizard } from './RequestWizard';

export const OpenRequestWizard = () => {
  const onCancelHandler = () => {
    Modal.destroyAll();
  };

  return Modal.info({
    title: null,
    icon: null,
    content: <RequestWizard onCancelRequest={onCancelHandler} />,
    closable: true,
    centered: true,
    okButtonProps: { style: { display: 'none' } },
    style: { paddingBottom: 0, top: 65 },
    width: getScreenWidthHandler(),
    onCancel: { onCancelHandler },
  });
};
