import { useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Tabs } from 'antd';
import JSONTree from 'react-json-tree';
import { toast } from 'react-toastify';

import { Flexcol, Flexrow } from '../../../../components/Flexbox';
import { verb_chips } from './verb_chips';
import { json_tree_theme } from '../../../../values/json_tree_theme';
import Notification from '../../../../components/Notification';

const { TabPane } = Tabs;

const { Text, Title } = Typography;

const OptionText = styled(Text)`
  cursor: pointer;
  :hover {
    color: #344ef3;
  }
`;

const JsonWrap = styled(Flexcol)`
  padding: 16px;
  background-color: #272822;
  height: 300px;
  overflow: auto;
`;

export const EndpointDetails = ({ listing_name, endpoint }) => {
  const [expand, setExpand] = useState(false);
  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(<Notification type="success" text="Copied to clipboard" />);
  };

  let json_data_response = endpoint.sample_data;
  let json_data_request = endpoint.sample_request_body;

  try {
    json_data_response = JSON.parse(endpoint.sample_data);
  } catch (error) {}

  if (endpoint.http_method == 'POST') {
    try {
      json_data_request = JSON.parse(endpoint.sample_request_body);
    } catch (error) {}
  }

  const nameTrimHandler = (name) => {
    let maxLength = 70;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  return (
    <Flexcol>
      <Text> {listing_name} </Text>
      <Flexrow center_align>
        <Title style={{ marginTop: 8 }} level={5}>
          {nameTrimHandler(endpoint.endpoint)}
        </Title>
        <Flexrow left="12px" />
        {verb_chips[endpoint.http_method]}
      </Flexrow>
      <Tabs
        tabBarStyle={{ fontWeight: 'bold', marginTop: 12 }}
        defaultActiveKey="1"
      >
        <TabPane tab="Sample response" key="1">
          <Flexrow bottom="16px">
            <OptionText onClick={() => onCopy(endpoint.sample_data)}>
              Copy
            </OptionText>
            <Flexrow left="20px"></Flexrow>
            <OptionText onClick={() => setExpand(true)}>Expand all</OptionText>
            <Flexrow left="20px"></Flexrow>
            <OptionText onClick={() => setExpand(false)}>
              Collapse all
            </OptionText>
          </Flexrow>
          <JsonWrap>
            <JSONTree
              data={json_data_response}
              hideRoot
              shouldExpandNode={() => expand}
              theme={json_tree_theme}
              invertTheme={false}
            />
          </JsonWrap>
        </TabPane>
        <TabPane tab="Sample request" key="2">
          <Flexrow>
            <Title level={5}>Sample request query</Title>
          </Flexrow>
          <Flexrow bottom="16px">
            <OptionText onClick={() => onCopy(endpoint.sample_request_query)}>
              Copy
            </OptionText>
          </Flexrow>
          <Flexrow>
            <Text
              style={{
                backgroundColor: '#000',
                color: '#0098ff',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              {endpoint.sample_request_query}
            </Text>
          </Flexrow>
          {endpoint.http_method == 'POST' && (
            <>
              <Flexrow top="16px">
                <Title level={5}>Sample request body</Title>
              </Flexrow>
              <Flexrow bottom="16px">
                <OptionText
                  onClick={() => onCopy(endpoint.sample_request_body)}
                >
                  Copy
                </OptionText>
                <Flexrow left="20px"></Flexrow>
                <OptionText onClick={() => setExpand(true)}>
                  Expand all
                </OptionText>
                <Flexrow left="20px"></Flexrow>
                <OptionText onClick={() => setExpand(false)}>
                  Collapse all
                </OptionText>
              </Flexrow>
              <JsonWrap>
                <JSONTree
                  data={json_data_request}
                  hideRoot
                  shouldExpandNode={() => expand}
                  theme={json_tree_theme}
                  invertTheme={false}
                />
              </JsonWrap>
            </>
          )}
        </TabPane>
      </Tabs>
    </Flexcol>
  );
};
