import styled from "styled-components";
import {
  SAMPLE_FILE_ID,
  STORAGE_ID,
  API_LINK_ID,
  RAW_DATA_ID,
  data_placement_type_ids,
} from "../iso-shared/values/global";

const ListingTypeLabel = styled.div`
  background: ${(props) => props.background};
  border-radius: 10.5px;
  font-size: 11px;
  padding: 2px 8px;
  margin-left: 8px;
  color: ${(props) => (props.color ? props.color : "#000")};
  white-space: nowrap;
`;

export const listingType = (type_id) => {
  switch (type_id) {
    case SAMPLE_FILE_ID:
      return <ListingTypeLabel background={"#e6e9fd"}>sample</ListingTypeLabel>;
    case STORAGE_ID:
      return (
        <ListingTypeLabel background={"#f0f1f2"}>storage</ListingTypeLabel>
      );
    case API_LINK_ID:
      return (
        <ListingTypeLabel background={"#e8dffc"} color="#7540ee">
          Proxy API
        </ListingTypeLabel>
      );
    case RAW_DATA_ID:
      return <ListingTypeLabel background={"#ffefe2"}>file</ListingTypeLabel>;
    case data_placement_type_ids.s3_bucket:
      return (
        <ListingTypeLabel background={"#d9f5e5"} color="#2dc76d">
          S3 Bucket
        </ListingTypeLabel>
      );
    default:
      break;
  }
};
