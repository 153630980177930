import React from "react";
import { Typography } from "antd";

const { Text, Title } = Typography;

const TourStep = (props) => {
  const { step, title, mainText, length } = props;

  return (
    <div style={{ textAlign: "left" }}>
      <Text
        style={{ color: "#8F9197", fontSize: "10px", marginBottom: "10px" }}
      >
        STEP {step} OF {length}
      </Text>
      <Title style={{ color: "#182429", fontSize: "16px" }}>{title}</Title>
      <Text style={{ color: "#64737F", fontSize: "15px" }}>{mainText}</Text>
    </div>
  );
};

export default TourStep;
