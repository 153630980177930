import React, { useContext } from 'react';
import styled from 'styled-components';
import { DataProductContext } from '../../helpers/DataProductWizardHelper';
import { MainHeader } from '../wizards-components/styled-components';
import Steps from './components/Steps';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';

const StatusTag = styled.div`
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 13px;
  padding: 4px 11px;
  margin: 0 auto;
  width: fit-content;
`;

export const DataProductWizard = (props) => {
  const helper = useContext(DataProductContext);
  const marginBottom = helper.published || helper.for_review;

  const getStatus = () => {
    let statusTag;
    if (helper.for_review) {
      return (statusTag = (
        <StatusTag background="#fff3d6" borderColor="#ffc540">
          <ClockFill
            style={{
              height: '16px',
              color: '#ffc540',
              paddingRight: '7px',
            }}
          />
          Pending review
        </StatusTag>
      ));
    } else if (helper.published) {
      return (statusTag = (
        <StatusTag background="#cefac3" borderColor="#5fdf3e">
          <CheckCircleFill
            style={{ height: '16px', color: '#5fdf3e', paddingRight: '7px' }}
          />
          Published
        </StatusTag>
      ));
    }
  };

  return (
    <div style={{ margin: '0 16px' }}>
      <MainHeader
        style={
          marginBottom ? { margin: '60px 0 0 0' } : { margin: '60px 0 54px 0' }
        }
      >
        {helper.published || helper.for_review
          ? helper.name.value
          : 'Create new data product'}
      </MainHeader>
      {getStatus()}
      <Steps
        helper={helper}
        walkthroughOn={props.walkthroughOn}
        isS3={props.isS3}
      />
    </div>
  );
};
