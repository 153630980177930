import { postThreadItem } from '../chat/chat-services';

export const initializeThreadItems = async (
  token,
  discussionThreads,
  userType,
  setRefresh,
) => {
  let hasDraft;

  discussionThreads.map(async (discussionThread) => {
    if (discussionThread.status !== 'RESOLVED') {
      hasDraft = discussionThread.thread_items.filter(
        (threadItem) =>
          threadItem?.status === 'DRAFT' &&
          threadItem?.type ===
            (userType === 'PROVIDER' ? 'ANSWER' : 'QUESTION'),
      ).length;

      if (hasDraft !== 1) {
        await postThreadItem(token, '', discussionThread.id, userType);
        setRefresh((prevState) => !prevState);
      }
    }
  });
};
