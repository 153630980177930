export const getChildData = (obj, key) => obj[key];

export const cloneDeepLimited = (obj) => JSON.parse(JSON.stringify(obj));

export const setNativeValue = (element, value) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
};

export const readableBytes = (bytes) => {
  if (!parseInt(bytes)) return '0 bytes';
  let i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
};

export const titleCase = (sentence) => {
  let modified = sentence.toLowerCase().split(' ');
  for (let i = 0; i < modified.length; i++) {
    modified[i] = modified[i][0].toUpperCase() + modified[i].slice(1);
  }

  return modified.join(' ');
};

export const endpoint_curl_builder = (integration_method, base_url, resource_name, id, name, resource) => {
  const curl_objects = [];

  if (integration_method === 'STORAGE') {
    const curl_string = `curl --location --request GET '${base_url}/public/expose' --header 'Authorization: <your access key goes here>' --header 'Content-Type: application/json' --data-raw '{"resource_name": "${resource_name}", "filters": {}}'`;

    curl_objects.push({
      id,
      name,
      curl_string,
    });
  } else if (integration_method === 'PROXY') {
    const requested_api_endpoints = resource.proxy.platform_resource_proxy_api_endpoints;
    const requested_domain = resource.proxy.domain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    .split('.')
    .join('.');
    requested_api_endpoints.forEach((element) => {
      const no_http_url = element.endpoint
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        .split('.')
        .join('.');


      const curl_string = `curl --location --request ${element.http_method} '${base_url}/public/proxy/${requested_domain}${no_http_url}' --header 'Authorization: <your access key goes here>' --header 'Content-Type: application/json'`;

      curl_objects.push({
        id,
        name,
        curl_string,
      });
    });
  }

  return curl_objects;
};

export const raw_data_curl_builder = (base_url, id, name, storage_path) => {
  const filename = storage_path.split('/').splice(-1);

  const body = `'{"platform_resource_name": "${name}"}'`;

  const curl_string = `curl --location --request GET '${base_url}/public/file' --header 'Authorization: <your access key goes here>' --header 'Content-Type: application/json' --data-raw ${body}`;
  return {
    id,
    name,
    curl_string,
  };
};

export const limitFileName = (name, limit) => {
  limit = limit || 25;
  const name_arr = name.split('.')
  let ext = '';
  if (name_arr.length > 1) {
    ext = '.' + name_arr.pop()
  }
  name = name_arr.join('.')
  if (name.length > limit) {
    name = name.substr(0, limit) + '…'
  }
  name = name + ext
  return name
}