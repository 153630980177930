import React from "react";
import { Descriptions } from "antd";

import { Title, Desc } from "./styled-components";

const TitleAndDesc = (props) => {
  const { title, desc } = props;

  return (
    <Descriptions title={<Title size={21}>{title}</Title>}>
      <Descriptions.Item>
        <Desc size={15}>{desc}</Desc>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default TitleAndDesc;
