import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const ConsequencesTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        C
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ONSEQUENCES{' '}
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>O</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>F </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>T</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ERMINATION
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              As from the notice of termination, all sums owed to Mobito or the
              Data Provider will become due and payable.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              As from the effective date of termination, the access to the
              Platform will be terminated and the parties will cease using the
              other party’s Confidential Information, Data, and Intellectual
              Property Rights, unless in so far permitted by this Agreement.
              Further use of the Data by the Data Customer is dependent on the
              Data Agreement and the Order Form.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Upon termination, Mobito will cease providing the Services and
              promptly invoice the Client for any unpaid amounts owed, and the
              Client will pay Mobito for all Services rendered and expenses
              incurred prior to the effective date of termination.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              In the event that the Data Provider uses Mobito to store
              historical Data, the Data Provider will ensure it has downloaded
              and removed the Data prior to the effective date of termination
              or, in the event of immediate termination in accordance with this
              Agreement, shall be provided with reasonable time, (taking into
              account, among other factors, the size of the Data) as will be set
              out by Mobito, to download and remove the Data.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default ConsequencesTerms;
