import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, Typography, Tooltip } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { toast } from 'react-toastify';

import { Lock } from '@styled-icons/evil/Lock';
import { InfoCircle } from '@styled-icons/boxicons-solid/InfoCircle';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';

import LayoutRow from '../LayoutRow';
import { Flexcol, Flexrow } from '../Flexbox';
import PayToActivateImgSource from '../../images/PayToActivate.webp';
import Notification from '../../components/Notification';

import 'react-circular-progressbar/dist/styles.css';

const { Text } = Typography;

const CardWrap = styled.div`
  width: 70%;
  margin: ${(props) => (props.top ? `${props.top} auto` : 'auto')};
`;

const BuyMoreButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const PayToActivateButton = styled(Button)`
  background-color: #ff7f19;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background-color: #e56b0a;
    color: #fff;
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const StatusTag = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 14px;
  padding: 4px 11px;
  width: fit-content;
  height: 30px;
`;

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const ConsumptionModalRequest = (props) => {
  const [data, setData] = useState(null);
  const [percentage, setPercentage] = useState(null);

  const {
    imageUrl,
    name,
    setIsRequestModalVisible,
    isRequestModalVisible,
    resource_connection_id,
    openCheckout,
    status,
  } = props;

  const token = Cookies.get('jwt') || '';
  const currentUserRole = Cookies.get('role');

  const MINUTE_MS = 4000;

  useEffect(() => {
    const fetchEndpoints = async () => {
      let res = await axios
        .get(
          `/api/data-consumption-details?conn_id=${resource_connection_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .catch((error) => {
          if (error.response.status === 404) {
            toast.error(
              <Notification type="error" text="Unauthorized Access" />,
            );
            history.push('/not-found');
          }
        });

      setData(res.data);
      setPercentage(
        res.data &&
          res.data.requests &&
          res.data.requests.total &&
          res.data.requests.spent
          ? (
              (res.data.requests.spent * 100) /
              res.data.requests.total
            ).toFixed()
          : 0,
      );
    };
    fetchEndpoints();
  }, []);

  useEffect(() => {
    if (isRequestModalVisible) {
      const interval = setInterval(() => {
        const fetchEndpoints = async () => {
          let res = await axios
            .get(
              `/api/data-consumption-details?conn_id=${resource_connection_id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
            .catch((error) => {
              if (error.response.status === 404) {
                toast.error(
                  <Notification type="error" text="Unauthorized Access" />,
                );
                history.push('/not-found');
              }
            });

          setData(res.data);
          setPercentage(
            res.data &&
              res.data.requests &&
              res.data.requests.total &&
              res.data.requests.spent
              ? (
                  (res.data.requests.spent * 100) /
                  res.data.requests.total
                ).toFixed()
              : 0,
          );
        };
        fetchEndpoints();
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [isRequestModalVisible]);

  const handleOk = () => {
    setIsRequestModalVisible(false);
  };

  const handleCancel = () => {
    setIsRequestModalVisible(false);
  };

  const formatDate = (date) => {
    let current_datetime = new Date(date);
    let formatted_date =
      current_datetime.getDate() +
      ' ' +
      months[current_datetime.getMonth()] +
      ' ' +
      current_datetime.getFullYear();
    return formatted_date;
  };

  return (
    <Modal
      title={
        <LayoutRow>
          <Flexcol>
            <Flexrow center_align style={{ paddingTop: '10px' }}>
              <Flexrow center_align>
                <img
                  style={{ marginRight: '10px' }}
                  width="40"
                  height="40"
                  alt="mobility-module-image"
                  src={imageUrl}
                />
                <Text style={{ color: '#192A33', fontSize: '18px' }}>
                  {name ? name : 'No name'}
                </Text>
              </Flexrow>
            </Flexrow>
            <Flexrow center_align space_between>
              <Text
                style={{
                  marginTop: '18px',
                  color: '#192A33',
                  fontSize: '20px',
                  fontWeight: '600',
                }}
              >
                Consumption Analytics
              </Text>
              <Flexcol>
                {status === 'ACTIVE' ? (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                    zIndex={99999999}
                  >
                    <BuyMoreButton
                      onClick={() => openCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Buy more / Upgrade
                    </BuyMoreButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                    zIndex={99999999}
                  >
                    <PayToActivateButton
                      onClick={() => openCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Pay to activate
                    </PayToActivateButton>
                  </Tooltip>
                )}
                <Flexrow center_align top="10px">
                  <Lock style={{ height: '24px' }} />
                  <Text
                    style={{
                      color: '#6C7377',
                      fontSize: '9px',
                    }}
                  >
                    Secure payment process. Feel safe.
                  </Text>
                </Flexrow>
              </Flexcol>
            </Flexrow>
          </Flexcol>
        </LayoutRow>
      }
      visible={isRequestModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      style={{ paddingBottom: '0px' }}
      bodyStyle={{
        backgroundColor: '#F9FAFF',
        padding: '0px',
        height: '75vh',
        overflowY: 'auto',
      }}
      width="100%"
      footer={null}
      zIndex={1000000}
    >
      {status === 'ACTIVE' ? (
        <LayoutRow>
          <CardWrap top="20px">
            <Text
              style={{
                color: '#64737F',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Your current plan
            </Text>
            <Card
              style={{
                marginTop: '12px',
                borderRadius: '6px',
                boxShadow: '2px 4px 12px 0px #182a330f',
              }}
              headStyle={{ borderBottom: 'none' }}
              bodyStyle={{ padding: '0px 24px 24px' }}
              bordered={false}
              title={
                <Flexrow center_align>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                      fontWeight: '600',
                      marginRight: '12px',
                    }}
                  >
                    Pricing per request
                  </Text>
                  {percentage >= '90' && (
                    <StatusTag
                      background="#ffe9e9"
                      borderColor="#fd5f5d"
                      bottom="0px"
                    >
                      <ClockFill
                        style={{
                          height: '16px',
                          color: '#fd5f5d',
                          paddingRight: '7px',
                        }}
                      />
                      Expires soon
                    </StatusTag>
                  )}
                </Flexrow>
              }
            >
              <Card
                title={
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                    }}
                  >
                    Consumption progress
                  </Text>
                }
                extra={
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title="Number of API requests that remain available for you to use"
                    zIndex={1000001}
                  >
                    <InfoCircle
                      style={{
                        height: '18px',
                        color: '#64737F',
                      }}
                    />
                  </Tooltip>
                }
                headStyle={{
                  backgroundColor: '#F6F6F6',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
                style={{
                  borderRadius: '6px',
                  border: '1px solid #C9CED1',
                  marginRight: '20px',
                }}
                bordered={false}
              >
                <div
                  style={{
                    width: '220px',
                    height: '220px',
                    margin: '12px auto',
                  }}
                >
                  <CircularProgressbarWithChildren
                    styles={buildStyles({
                      pathColor:
                        percentage >= '90'
                          ? '#fd5f5d'
                          : percentage >= '45'
                          ? '#FFC540'
                          : '#5FDF3E',
                      trailColor: '#F6F6F6',
                    })}
                    maxValue={data && data.requests && data.requests.total}
                    value={
                      data && data.requests && data.requests.total
                        ? data.requests.total - data.requests.spent
                        : 0
                    }
                  >
                    <Text
                      style={{
                        fontSize: '24px',
                        color: '#192A33',
                        display: 'block',
                      }}
                    >
                      {data && data.requests && data.requests.total
                        ? data.requests.total - data.requests.spent
                        : 0}
                    </Text>
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#192A33',
                        display: 'block',
                      }}
                    >
                      requests
                    </Text>
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#192A33',
                      }}
                    >
                      remaining
                    </Text>
                  </CircularProgressbarWithChildren>
                </div>
              </Card>
            </Card>
          </CardWrap>
          <CardWrap top="50px">
            <Text
              style={{
                color: '#64737F',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Other limits
            </Text>
            <Card
              style={{
                marginTop: '12px',
                borderRadius: '6px',
                boxShadow: '2px 4px 12px 0px #182a330f',
              }}
              headStyle={{ borderBottom: 'none' }}
              bordered={false}
              title={null}
            >
              <Flexrow>
                <Card
                  title={
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#64737F',
                        fontWeight: '500',
                      }}
                    >
                      Per minute
                    </Text>
                  }
                  extra={
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      overlayStyle={{ maxWidth: 'fit-content' }}
                      placement="bottom"
                      title={
                        <div>
                          <strong>Total</strong>: The maximum total number of
                          API requests that you are allowed to do within a
                          minute (static)
                          <br />
                          <strong>Consumed</strong>: The number of API requests
                          that you have consumed over the last 60 seconds
                          (auto-updated)
                        </div>
                      }
                      zIndex={1000001}
                    >
                      <InfoCircle
                        style={{
                          height: '18px',
                          color: '#64737F',
                        }}
                      />
                    </Tooltip>
                  }
                  headStyle={{
                    backgroundColor: '#F6F6F6',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                  }}
                  style={{
                    borderRadius: '6px',
                    border: '1px solid #C9CED1',
                    marginRight: '20px',
                    width: '33%',
                  }}
                  bordered={false}
                >
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    Total:
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data && data.limits && data.limits.minute.limit
                      ? data.limits.minute.limit
                      : `Unlimited`}
                  </Text>
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    Consumed:
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data &&
                    data.limits &&
                    data.limits.minute &&
                    data.limits.minute.spent
                      ? data.limits.minute.spent
                      : `None`}
                  </Text>
                </Card>
                <Card
                  title={
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#64737F',
                        fontWeight: '500',
                      }}
                    >
                      Per hour
                    </Text>
                  }
                  extra={
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      overlayStyle={{ maxWidth: 'fit-content' }}
                      placement="bottom"
                      title={
                        <div>
                          <strong>Total</strong>: The maximum total number of
                          API requests that you are allowed to do within an hour
                          (static)
                          <br />
                          <strong>Consumed</strong>: The number of API requests
                          that you have consumed over the last 60 minutes
                          (auto-updated)
                        </div>
                      }
                      zIndex={1000001}
                    >
                      <InfoCircle
                        style={{
                          height: '18px',
                          color: '#64737F',
                        }}
                      />
                    </Tooltip>
                  }
                  headStyle={{
                    backgroundColor: '#F6F6F6',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                  }}
                  style={{
                    borderRadius: '6px',
                    border: '1px solid #C9CED1',
                    marginRight: '20px',
                    width: '33%',
                  }}
                  bordered={false}
                >
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    Total:
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data && data.limits && data.limits.hour.limit
                      ? data.limits.hour.limit
                      : `Unlimited`}
                  </Text>
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    Consumed:
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data &&
                    data.limits &&
                    data.limits.hour &&
                    data.limits.hour.spent
                      ? data.limits.hour.spent
                      : `None`}
                  </Text>
                </Card>
                <Card
                  title={
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#64737F',
                        fontWeight: '500',
                      }}
                    >
                      Per day
                    </Text>
                  }
                  extra={
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      overlayStyle={{ maxWidth: 'fit-content' }}
                      placement="bottom"
                      title={
                        <div>
                          <strong>Total</strong>: The maximum total number of
                          API requests that you are allowed to do within a day
                          (static)
                          <br />
                          <strong>Consumed</strong>: The number of API requests
                          that you have consumed over the last 24 hours
                          (auto-updated)
                        </div>
                      }
                      zIndex={1000001}
                    >
                      <InfoCircle
                        style={{
                          height: '18px',
                          color: '#64737F',
                        }}
                      />
                    </Tooltip>
                  }
                  headStyle={{
                    backgroundColor: '#F6F6F6',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                  }}
                  style={{
                    borderRadius: '6px',
                    border: '1px solid #C9CED1',
                    marginRight: '20px',
                    width: '33%',
                  }}
                  bordered={false}
                >
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    Total:
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data && data.limits && data.limits.day.limit
                      ? data.limits.day.limit
                      : `Unlimited`}
                  </Text>
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    Consumed:
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data &&
                    data.limits &&
                    data.limits.day &&
                    data.limits.day.spent
                      ? data.limits.day.spent
                      : `None`}
                  </Text>
                </Card>
              </Flexrow>
            </Card>
          </CardWrap>
          <CardWrap top="50px">
            <Text
              style={{
                color: '#64737F',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Payment history
            </Text>
            <Card
              style={{
                marginTop: '12px',
                borderRadius: '6px',
                boxShadow: '2px 4px 12px 0px #182a330f',
              }}
              headStyle={{ borderBottom: 'none' }}
              bordered={false}
              title={null}
            >
              <Flexrow>
                <Card
                  title={
                    <Flexrow space_between>
                      <Text
                        style={{
                          fontSize: '16px',
                          color: '#64737F',
                          fontWeight: '500',
                          width: '20%',
                        }}
                      >
                        Date
                      </Text>
                      <Text
                        style={{
                          fontSize: '16px',
                          color: '#64737F',
                          fontWeight: '500',
                          width: '20%',
                        }}
                      >
                        Requests
                      </Text>
                      <Text
                        style={{
                          fontSize: '16px',
                          color: '#64737F',
                          fontWeight: '500',
                          width: '20%',
                        }}
                      >
                        Price
                      </Text>
                      <Text
                        style={{
                          fontSize: '16px',
                          color: '#64737F',
                          fontWeight: '500',
                          width: '20%',
                        }}
                      >
                        Status
                      </Text>
                    </Flexrow>
                  }
                  headStyle={{
                    backgroundColor: '#F6F6F6',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                  }}
                  bodyStyle={{ padding: '0px' }}
                  style={{
                    borderRadius: '6px',
                    border: '1px solid #C9CED1',
                    marginRight: '20px',
                    width: '100%',
                  }}
                  bordered={false}
                >
                  <Flexcol>
                    {data &&
                      data.payments &&
                      data.payments
                        .sort(
                          (a, b) =>
                            new Date(b.verified_at) - new Date(a.verified_at),
                        )
                        .map((item, index) => (
                          <Flexrow
                            space_between
                            center_align
                            key={index}
                            style={{
                              padding: '16px 24px',
                              border: '1px solid #C9CED1',
                            }}
                          >
                            <Text
                              style={{
                                fontSize: '14px',
                                color: '#64737F',
                                width: '20%',
                              }}
                            >
                              {formatDate(item.verified_at)}
                            </Text>
                            <Text
                              style={{
                                fontSize: '14px',
                                color: '#64737F',
                                width: '20%',
                              }}
                            >
                              {item.requests} requests
                            </Text>
                            <Flexcol style={{ width: '20%' }}>
                              <Text
                                style={{
                                  fontSize: '14px',
                                  color: '#64737F',
                                }}
                              >
                                {item.price_total.toLocaleString()} €
                              </Text>
                              <Text
                                style={{
                                  fontSize: '12px',
                                  color: '#9FA7AD',
                                }}
                              >
                                {item.price.toLocaleString()} € / request
                              </Text>
                            </Flexcol>
                            <Text
                              style={{
                                width: '20%',
                              }}
                            >
                              <StatusTag
                                background="#cefac3"
                                borderColor="#5fdf3e"
                              >
                                <CheckCircleFill
                                  style={{
                                    height: '16px',
                                    color: '#5fdf3e',
                                    paddingRight: '7px',
                                  }}
                                />
                                Paid
                              </StatusTag>
                            </Text>
                          </Flexrow>
                        ))}
                  </Flexcol>
                </Card>
              </Flexrow>
            </Card>
          </CardWrap>
        </LayoutRow>
      ) : (
        <LayoutRow>
          <CardWrap top="20px">
            <Text
              style={{
                color: '#64737F',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              Your current plan
            </Text>
            <div style={{ position: 'relative', margin: 'auto', width: '80%' }}>
              <Flexcol
                center_align
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                  width: 'fit-content',
                }}
              >
                <Lock
                  style={{
                    height: '100px',
                    marginBottom: '26px',
                    color: '#fff',
                  }}
                />
                <Text
                  style={{
                    color: '#fff',
                    fontSize: '20px',
                    fontWeight: '700',
                    marginBottom: '18px',
                    display: 'block',
                  }}
                >
                  You have no consumption plans yet
                </Text>
                <Text
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: '600',
                    marginBottom: '26px',
                    display: 'block',
                  }}
                >
                  Please pay to activate
                </Text>
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title={
                    currentUserRole === 'MEMBER' &&
                    'You do not have the access rights to perform this action.'
                  }
                  zIndex={99999999}
                >
                  <PayToActivateButton
                    onClick={() => openCheckout()}
                    disabled={currentUserRole === 'MEMBER'}
                  >
                    Pay to activate
                  </PayToActivateButton>
                </Tooltip>
              </Flexcol>
              <img
                src={PayToActivateImgSource}
                alt="pay to activate"
                style={{
                  marginTop: '20px',
                  filter: 'brightness(0.5) blur(3px)',
                  width: '100%',
                }}
              />
            </div>
          </CardWrap>
        </LayoutRow>
      )}
    </Modal>
  );
};

export default ConsumptionModalRequest;
