import styled from "styled-components";
import { Link } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import { Spin } from "antd";
import useSWR from "swr";
import { TurnedIn, CompareArrows } from "styled-icons/material";
import { ThemeProvider } from "styled-components";
import Cookies from "js-cookie";

import { colors } from "../../values/colors";
import { SText } from "../styled-text/text";
import { SModuleImage } from "../module-image";
import { fetcherWithToken } from "../../utils/fetcher";
import { withoutColPadding } from "../../styles/grid.js";
import { getThumbnailURL } from "../../iso-shared/utils/getThumbnailUrl";
import { data_placement_type_ids } from "../../iso-shared/values/global";

//TODO uncomment and check consumption

const SCardContainer = styled(Row)`
  width: 100%;
  background-color: #ffffff;
  padding: 20px 28px;
  border-bottom: 2px solid ${colors["grey_1"]};
`;

const ConnectionCard = ({ project_id }) => {
  const token = Cookies.get("jwt");
  let { data: project, projectErrors } = useSWR(
    [`/api/data-connection-metadata?project_id=${project_id}`, token],
    fetcherWithToken
  );

  let { data: module } = useSWR(
    project_id
      ? [`/api/data-connection-metadata?project_id=${project_id}`, token]
      : null,
    fetcherWithToken
  );

  let { data: consumption } = useSWR(
    [`/api/data-placement-total-consumption?id=${project_id}`, token],
    fetcherWithToken
  );

  if (projectErrors) return <div>{projectErrors}</div>;

  if (!project)
    return (
      <div
        style={{
          backgroundColor: "#f9faff",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );

  const imageUrl = getThumbnailURL(project.image_url);

  return (
    <ThemeProvider theme={withoutColPadding}>
      <SCardContainer middle="xs">
        <Col xs={12}>
          <Row>
            <Col xs={1}>
              <SModuleImage
                width="46"
                height="46"
                alt="mobility-module-image"
                src={imageUrl}
              />
            </Col>
            <Col xs={3}>
              <Row>
                <SText text_16>
                  <Link to={`/data-product/${project.url_id}`} target="_blank">
                    {project.name}
                  </Link>
                </SText>
              </Row>
            </Col>
            <Col xs={3}>
              <Row>
                <SText text_16 black bold>
                  Category
                </SText>
              </Row>
              <Row middle="xs">
                <Col>
                  <TurnedIn size="16" style={{ color: colors["orange_4"] }} />
                  <SText text_12 semi medium>
                    {project.project_data_category.name}
                  </SText>
                </Col>
              </Row>
            </Col>
            <Col xs={3}>
              {project.type_id != data_placement_type_ids.s3_bucket && (
                <>
                  <Row middle="xs">
                    <SText text_16 black bold>
                      {`Consumption`}
                    </SText>
                  </Row>
                  <Row middle="xs">
                    <Col>
                      <CompareArrows
                        size="16"
                        style={{ color: colors["orange_4"], marginRight: 4 }}
                      />
                      <SText text_12 semi medium>
                        {consumption ? consumption.num_requests : null} requests
                      </SText>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </SCardContainer>
    </ThemeProvider>
  );
};

export default ConnectionCard;
