import { strings } from '../assets/strings';
import AttributesModal from '../modals/AttributesModal';
import { getScreenWidthHandler } from '../helpers/getScreenWidthHandler';
import { Modal } from 'antd';

export const handleAttributesModal = (attributesSelections, selectable) => {
  Modal.info({
    title: null,
    icon: null,
    content: (
      <AttributesModal
        name=""
        title={strings.dataset_details.attributes_standard}
        attributes={attributesSelections}
        selectable={selectable}
      />
    ),
    okText: 'Close',
    closable: true,
    centered: true,
    style: { paddingBottom: 0 },
    width: getScreenWidthHandler(),
  });
};
