import styled, { css } from 'styled-components';

export const cssMargins = (p) => {
  return css`
    ${p.top &&
    css`
      margin-top: ${p.top};
    `}
    ${p.left &&
    css`
      margin-left: ${p.left};
    `}
        ${p.right &&
    css`
      margin-right: ${p.right};
    `}
        ${p.bottom &&
    css`
      margin-bottom: ${p.bottom};
    `}
        ${p.padding &&
    css`
      padding: ${p.padding};
    `}
  `;
};

export const flexProps = (p) => {
  return css`
    ${p['self_start'] &&
    css`
      align-self: start;
    `}
    ${p['self_stretch'] &&
    css`
      align-self: stretch;
    `}
        ${p['center_align'] &&
    css`
      align-items: center;
    `}
        ${p['baseline_align'] &&
    css`
      align-items: baseline;
    `}
        ${p['stretch_align'] &&
    css`
      align-items: stretch;
    `}
        ${p['start_align'] &&
    css`
      align-items: flex-start;
    `}
        ${p['end_align'] &&
    css`
      align-items: flex-end;
    `}
        ${p['stretch_align'] &&
    css`
      align-items: stretch;
    `}
        ${p['space_between'] &&
    css`
      justify-content: space-between;
    `}
        ${p['space_around'] &&
    css`
      justify-content: space-around;
    `}
        ${p['center_content'] &&
    css`
      justify-content: center;
    `}
        ${p['end_content'] &&
    css`
      justify-content: flex-end;
    `}
        ${p['grow'] &&
    css`
      flex-grow: 1;
    `}
        ${p['self_stretch'] &&
    css`
      align-self: stretch;
    `}
        ${p['wrap'] &&
    css`
      flex-wrap: wrap;
    `}
  `;
};

export const flex_row_css = css`
  display: flex;
  flex-direction: row;
`;

export const flex_col_css = css`
  display: flex;
  flex-direction: column;
`;

export const Flexrow = styled.div`
  ${flex_row_css}
  ${(p) => cssMargins(p)} 
    ${(p) => flexProps(p)}
    ${(p) =>
    p.relative &&
    css`
      position: relative;
    `}
`;

export const Flexcol = styled(Flexrow)`
  flex-direction: column;
`;
