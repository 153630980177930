export default {
  text_12: '12px',
  text_16: '16px',
  text_20: '20px',
  text_24: '24px',
  text_28: '28px',
  text_32: '32px',
  text_36: '36px',
};
// export default {
//   text_12: '0.6vw',
//   text_16: '0.8vw',
//   text_20: '1vw',
//   text_24: '1.2vw',
//   text_28: '1.4000000000000001vw',
//   text_32: '1.6vw',
//   text_36: '1.7999999999999998vw',
// };
