import React from 'react';
import { Typography } from 'antd';

import { ClarificationsCardWrap } from '../assets/styled-components';
import ContentStepperHeader from '../ContentStepperHeader';
import { AddRequest } from './AddRequest';

const { Title, Text } = Typography;

export const CreateRequest = (props) => {
  const { title, headline, description } = props;

  return (
    <>
      <ContentStepperHeader
        title={title}
        content="You should update me with valid content"
      />
      <ClarificationsCardWrap>
        <Title level={4} style={{ marginBottom: 5, color: '#182a33' }}>
          {headline}
        </Title>
        <Text style={{ color: '#182a33' }}>{description}</Text>
      </ClarificationsCardWrap>
      <section
        className="create-requests-container"
        style={{
          backgroundColor: 'white',
          minHeight: '50vh',
          padding: '30px 60px',
        }}
      >
        <AddRequest></AddRequest>
      </section>
    </>
  );
};
