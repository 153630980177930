let MOCK_API_DEBOUNCE = false

export const mockApiDebounce = () => MOCK_API_DEBOUNCE = true

const deepCopy = v => JSON.parse(JSON.stringify(v))

export class AbstractWizardHelper {
  inputs = [];
  sections = [];
  sections_map = {}
  api_trigger_promises = []
  onError = null

  onChange() {}

  makeInput({init, name, required, apiTrigger, getCustomError}) {
    const _this = this

    class Input {
      value = init;
      saved_value = init;
      has_error = false;
      edited = false;

      constructor () {
        if(apiTrigger) {
          apiTrigger.section.inputs.push(this)
        }
      }

      getApiTrigger = () => apiTrigger

      setEditing = () => {
        this.saved_value = deepCopy(this.value)
      }

      cancelChanges = () => {
        this.value = this.saved_value
        this.edited = false
        this.checkErrors()
      }

      set = (v) => {
        this.value = v;
        this.trigger();
      };

      getName = () => name;

      checkErrors = () => {
        this.has_error = (required && !this.value) || (getCustomError && getCustomError(this.value))
        return this.has_error
      }

      trigger = () => {
        this.checkErrors();
        if ( !apiTrigger.section.editing ) {
          apiTrigger.trigger();
        } else {
          this.edited = true
        }
        _this.refresh()
      };
    }
    const out = new Input();
    this.inputs.push(out);
    return out;
  }

  makeSection() {
    const _this = this;
    class Section {
      editing = false
      saving_api_triggers_num = 0
      saving = false
      inputs = []
      edited() {
        return !!this.inputs.find(e => e.edited)
      }
      done() {
        this.saving_api_triggers_num --
        this.saving = !!this.saving_api_triggers_num;
        _this.refresh();
      }
      trigger() {
        this.saving_api_triggers_num ++
        this.saving = true;
        // apiTrigger.trigger(this);
        _this.refresh();
      }
      setEditing() {
        this.editing = true
        this.inputs.forEach(e => e.setEditing())
        _this.refresh()
      }
      cancelChanges = () => {
        this.inputs.forEach(e => e.cancelChanges())
        this.editing = false
        _this.refresh()
      }
      saveChanges = () => {
        this.editing = false
        const api_triggers = new Set()
        this.inputs.forEach(e => {
          if ( e.edited ) {
            e.edited = false
            api_triggers.add(e.getApiTrigger())
          }
        })
        api_triggers.forEach(e => e.fire())
      }
    }
    const section = new Section();
    this.sections.push(section);
    return section;
  }

  makeApiTrigger(section_name, poster) {
    const _this = this;
    class Trigger {
      section = null
      timeout = -1
      constructor () {
        if ( !_this.sections_map[section_name] ) {
          _this.sections_map[section_name] = _this.makeSection()
        }
        this.section = _this.sections_map[section_name]
      }

      fire = async () => {
        this.section.trigger()
        try {
          await poster();
          _this.onChange();
        } catch (error) {
          console.error(error);
          _this.onError && _this.onError(error)
          // message.error('An error occured, some changes are not saved');
        }
        this.section.done()
      }

      trigger = () => {
        if (MOCK_API_DEBOUNCE) {
          _this.api_trigger_promises.push(this.fire())
          return
        }
        if ( this.timeout == -1 ) {
          this.section.trigger()
        } else {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(async () => {
          this.timeout = -1
          try {
            // _this.updateErrors();
            await poster();
            _this.onChange();
          } catch (error) {
            console.error(error);
            _this.onError && _this.onError(error)
            // message.error('An error occured, some changes are not saved');
          }
          this.section.done()
        }, 600);
      };
    }

    return new Trigger();
  }

}