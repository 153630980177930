import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Select,
  Button,
  Divider,
  Dropdown,
  Menu,
  Spin,
  Tooltip,
} from 'antd';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { DotsHorizontalRounded } from '@styled-icons/boxicons-regular/DotsHorizontalRounded';

import { Flexcol, Flexrow } from '../components/Flexbox';
import BackArrowSrc from '../images/back_arrow.svg';
import DeleteSrc from '../images/delete.svg';
import AddMoreSrc from '../images/add_more.svg';
import EllipseSrc from '../images/Ellipse.png';
import StarSrc from '../images/Star.svg';
import { MEMBER_FILTER_OPTIONS } from '../iso-shared/values/global';
import { ChangeRoleModal } from '../page-components/Account/ChangeRoleModal';
import { AddNewMemberModal } from '../page-components/Account/AddNewMemberModal';
import Notification from '../components/Notification';
import { confirmModal } from '../views/ModalView';
import instance from '../services/TokenValidator';

const { Text } = Typography;
const { Option } = Select;

const AddButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: fit-content;
  padding: 9px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const CardWrap = styled(Flexrow)`
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: 1px solid #fff;
  width: 65%;
  padding: 25px;
  position: relative;
`;

const StatusTag = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 11px;
  width: fit-content;
  height: 30px;
  line-height: initial;
  color: ${(props) => props.color && props.color};
`;

const getStatusTag = (status) => {
  if (status === 'ACTIVE') {
    return (
      <StatusTag color="#5FDF3E">
        <CheckCircleFill
          style={{ height: '16px', color: '#5fdf3e', paddingRight: '7px' }}
        />
        Active
      </StatusTag>
    );
  } else if (status === 'PENDING') {
    return (
      <StatusTag color="#FFC540">
        <ClockFill
          style={{
            height: '16px',
            color: '#ffc540',
            paddingRight: '7px',
          }}
        />
        Pending
      </StatusTag>
    );
  } else if (status === 'REMOVED') {
    return (
      <StatusTag color="#FD5F5D">
        <img src={DeleteSrc} style={{ paddingRight: '7px', height: '16px' }} />
        Removed
      </StatusTag>
    );
  } else {
    return <div style={{ height: '30px' }} />;
  }
};

const splitName = (name) => {
  return name
    .split(' ')
    .map((i) => i.charAt(0).toUpperCase())
    .slice(0, 2);
};

const memberFilterOptions = (quantity) =>
  MEMBER_FILTER_OPTIONS.map((e, index) => (
    <Option key={e} value={e}>
      {`${e} (${quantity[index]})`}
    </Option>
  ));

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatDate = (date) => {
  let current_datetime = new Date(date);
  let formatted_date =
    current_datetime.getDate() +
    ' ' +
    months[current_datetime.getMonth()] +
    ' ' +
    current_datetime.getFullYear();
  return formatted_date;
};

const resend_invitation = {
  title: (
    <Text style={{ color: '#182A33', fontSize: '19px', fontWeight: '600' }}>
      Resend Invitation?
    </Text>
  ),
  content: (
    <Text style={{ color: '#64737F', fontSize: '15px' }}>
      Are you sure that you want to resend the invitation to the selected user?
    </Text>
  ),
  cancelText: 'No',
  okText: 'Yes, proceeed',
};

const remove_member = {
  title: (
    <Text style={{ color: '#182A33', fontSize: '19px', fontWeight: '600' }}>
      Remove User?
    </Text>
  ),
  content: (
    <Text style={{ color: '#64737F', fontSize: '15px' }}>
      You are about to remove the selected user from your team. <br />
      <br />
      Are you sure you want to proceed?
    </Text>
  ),
  cancelText: 'No',
  okText: 'Yes, proceeed',
};

const prime_contact = {
  title: (
    <Text style={{ color: '#182A33', fontSize: '19px', fontWeight: '600' }}>
      Update Primary contact
    </Text>
  ),
  content: (
    <Text style={{ color: '#64737F', fontSize: '15px' }}>
      You are about to set the selected member as Primary contact. This means
      that this member will be the one notified by Mobito for anything related
      to publishing data products or receiving access requests by consumers.
      <br />
      <br />
      (Note: Only one admin can be primary contact within the company)
      <br /> <br /> Are you sure you want to proceed?
    </Text>
  ),
  cancelText: 'No',
  okText: 'Yes, update primary contact',
};

export const CompanyUsers = (p) => {
  const { user, onSetTab, setUserRole } = p;
  const [changeRoleModalVisible, setChangeRoleModalVisible] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [allInvitations, setAllInvitations] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [addNewModalVisible, setAddNewModalVisible] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [randomColor, setRandomColor] = useState([]);
  const [selectDefaultValue, setSelectDefaultValue] = useState('All members');
  const [isLoading, setIsLoading] = useState(false);
  const [memberToChange, setMemberToChange] = useState({});
  const [prime, setPrime] = useState('');
  const [forceUpdated, setForceUpdated] = useState(false);
  const token = Cookies.get('jwt');
  const primaryContact = Cookies.get('primaryContact');
  const currentUserRole = Cookies.get('role');
  const currentUserEmail = Cookies.get('email');
  const isDeletable = allMembers.users?.length < 2;

  useEffect(() => {
    setIsLoading(true);
    const fetchInvitations = async () => {
      const res = await instance.get(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const inv_res = await instance.get(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations/invitations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setAllMembers(res.data);
      setAllInvitations(inv_res.data);
      setFilteredMembers({ users: res.data, invitations: inv_res.data });
      setQuantity([
        res.data.length + inv_res.data.length,
        res.data.length,
        inv_res.data.filter((i) => i.status === 'PENDING').length,
        inv_res.data.filter((i) => i.status === 'REMOVED').length,
      ]);
      setIsLoading(false);
    };

    fetchInvitations();
  }, [updated]);

  useEffect(() => {
    allMembers?.forEach((member) => {
      if (member.id === primaryContact) {
        setPrime(member.email);
      }
    });
  }, [allMembers]);

  useEffect(() => {
    if (forceUpdated) {
      toast.info(
        <Notification
          type="info"
          text={`${prime}  was automatically designated as the Primary contact.`}
        />,
      );
    }
  }, [prime]);

  useEffect(() => {
    filteredMembers.users?.forEach((user, index) => {
      let randomColor = '#';
      const letters = '0123456789ABCDEF';
      for (let i = 0; i < 6; i++) {
        randomColor += letters[Math.floor(Math.random() * 16)];
      }
      setRandomColor((prevState) => ({
        ...prevState,
        [index]: randomColor,
      }));
    });
    filteredMembers.invitations?.forEach((user, index) => {
      let randomColor = '#';
      const letters = '0123456789ABCDEF';
      for (let i = 0; i < 6; i++) {
        randomColor += letters[Math.floor(Math.random() * 16)];
      }
      setRandomColor((prevState) => ({
        ...prevState,
        [index]: randomColor,
      }));
    });
  }, [filteredMembers]);

  const handleMenuClick = async (
    e,
    memberId,
    memberEmail,
    memberRole,
    memberType,
  ) => {
    if (e.key === '1') {
      const confirmation = await confirmModal(prime_contact);
      const deploymentDomain =
        process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';
      if (confirmation) {
        try {
          await instance.patch(
            `${process.env.RAZZLE_ACCOUNT_URL}/organizations/primary-contact`,
            {
              primaryContactId: memberId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          toast.success(
            <Notification type="success" text="Primary contact updated!" />,
          );
          Cookies.set('primaryContact', memberId, { domain: deploymentDomain });
          setUpdated((prevState) => !prevState);
          setSelectDefaultValue('All members');
          setForceUpdated(false);
        } catch (error) {
          console.error(error);
          if (error.response)
            toast.error(
              <Notification type="error" text={error.response.data.message} />,
            );
        }
      }
    } else if (e.key === '2') {
      const confirmation = await confirmModal(resend_invitation);
      if (confirmation) {
        try {
          await instance.post(
            `${process.env.RAZZLE_ACCOUNT_URL}/invitations/${memberId}/email`,
            null,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          toast.success(
            <Notification type="success" text="Invite sent successfully!" />,
          );
          setForceUpdated(false);
        } catch (error) {
          console.error(error);
          if (error.response)
            toast.error(
              <Notification type="error" text={error.response.data.message} />,
            );
        }
      }
    } else if (e.key === '3') {
      setChangeRoleModalVisible(true);
      setMemberToChange({
        email: memberEmail,
        role: memberRole,
        id: memberId,
        type: memberType,
      });
      setForceUpdated(true);
    } else if (e.key === '4') {
      const confirmation = await confirmModal(remove_member);
      if (confirmation) {
        if (memberType === 'USER') {
          try {
            await instance.delete(
              `${process.env.RAZZLE_ACCOUNT_URL}/organizations/users/${memberId}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );
            setUpdated((prevState) => !prevState);
            setSelectDefaultValue('All members');
            setForceUpdated(true);
            toast.success(
              <Notification
                type="success"
                text="Invite removed successfully!"
              />,
            );
          } catch (error) {
            console.error(error);
            if (error.response)
              toast.error(
                <Notification
                  type="error"
                  text={error.response.data.message}
                />,
              );
          }
        } else {
          try {
            await instance.delete(
              `${process.env.RAZZLE_ACCOUNT_URL}/invitations/${memberId}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );
            setUpdated((prevState) => !prevState);
            setSelectDefaultValue('All members');
            setForceUpdated(true);
            toast.success(
              <Notification
                type="success"
                text="Invite removed successfully!"
              />,
            );
          } catch (error) {
            console.error(error);
            if (error.response)
              toast.error(
                <Notification
                  type="error"
                  text={error.response.data.message}
                />,
              );
          }
        }
      }
    }
  };

  const menu = (
    memberStatus,
    memberId,
    memberEmail,
    memberRole,
    memberType,
    isPrime,
  ) => (
    <Menu
      onClick={(e) =>
        handleMenuClick(e, memberId, memberEmail, memberRole, memberType)
      }
    >
      <Menu.Item
        key="1"
        style={{
          color:
            currentUserRole !== 'ADMIN' ||
            memberRole !== 'ADMIN' ||
            memberStatus !== 'ACTIVE' ||
            isPrime
              ? '#c1c2c2'
              : '#182A33',
          backgroundColor:
            (currentUserRole !== 'ADMIN' ||
              memberRole !== 'ADMIN' ||
              memberStatus !== 'ACTIVE' ||
              isPrime) &&
            '#f3f3f4',
        }}
        disabled={
          currentUserRole !== 'ADMIN' ||
          memberRole !== 'ADMIN' ||
          memberStatus !== 'ACTIVE' ||
          isPrime
        }
      >
        <Tooltip
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: '2px 4px 12px 0px #182a331f',
            fontSize: '13px',
            borderRadius: '4px',
          }}
          placement="bottom"
          title={
            currentUserRole === 'MEMBER' &&
            'You do not have the access rights to perform this action.'
          }
        >
          Set primary contact
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        key="2"
        style={{
          color:
            currentUserRole !== 'ADMIN' || memberStatus === 'ACTIVE'
              ? '#c1c2c2'
              : '#182A33',
          backgroundColor:
            (currentUserRole !== 'ADMIN' || memberStatus === 'ACTIVE') &&
            '#f3f3f4',
        }}
        disabled={currentUserRole !== 'ADMIN' || memberStatus === 'ACTIVE'}
      >
        <Tooltip
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: '2px 4px 12px 0px #182a331f',
            fontSize: '13px',
            borderRadius: '4px',
          }}
          placement="bottom"
          title={
            currentUserRole === 'MEMBER' &&
            'You do not have the access rights to perform this action.'
          }
        >
          Resend invitation
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{
          color:
            isDeletable ||
            currentUserRole !== 'ADMIN' ||
            memberStatus === 'REMOVED'
              ? '#c1c2c2'
              : '#182A33',
          backgroundColor:
            (isDeletable ||
              currentUserRole !== 'ADMIN' ||
              memberStatus === 'REMOVED') &&
            '#f3f3f4',
        }}
        disabled={
          isDeletable ||
          currentUserRole !== 'ADMIN' ||
          memberStatus === 'REMOVED'
        }
      >
        <Tooltip
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: '2px 4px 12px 0px #182a331f',
            fontSize: '13px',
            borderRadius: '4px',
          }}
          placement="bottom"
          title={
            currentUserRole === 'MEMBER' &&
            'You do not have the access rights to perform this action.'
          }
        >
          Change role
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        key="4"
        danger
        disabled={
          isDeletable ||
          currentUserEmail === memberEmail ||
          currentUserRole !== 'ADMIN' ||
          memberStatus === 'REMOVED'
        }
        style={{
          color:
            (isDeletable ||
              currentUserEmail === memberEmail ||
              currentUserRole !== 'ADMIN' ||
              memberStatus === 'REMOVED') &&
            '#c1c2c2',
          backgroundColor:
            (isDeletable ||
              currentUserEmail === memberEmail ||
              currentUserRole !== 'ADMIN' ||
              memberStatus === 'REMOVED') &&
            '#f3f3f4',
        }}
      >
        <Tooltip
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: '2px 4px 12px 0px #182a331f',
            fontSize: '13px',
            borderRadius: '4px',
          }}
          placement="bottom"
          title={
            currentUserRole === 'MEMBER' &&
            'You do not have the access rights to perform this action.'
          }
        >
          Remove from team
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const filterMembersHandler = (val) => {
    setSelectDefaultValue(val);

    const initialUsers = [...allMembers];
    const initialInvitations = [...allInvitations];

    if (val === 'All members')
      setFilteredMembers({
        users: initialUsers,
        invitations: initialInvitations,
      });

    if (val === 'Active') {
      setFilteredMembers({ users: initialUsers, invitations: [] });
    }

    if (val === 'Pending' || val === 'Removed') {
      const filtered = initialInvitations.filter(
        (i) => i.status === val.toUpperCase(),
      );

      setFilteredMembers({ users: [], invitations: filtered });
    }
  };

  return isLoading ? (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      <Spin size="large" />
    </div>
  ) : (
    <>
      <ChangeRoleModal
        setChangeRoleModalVisible={setChangeRoleModalVisible}
        changeRoleModalVisible={changeRoleModalVisible}
        memberEmail={memberToChange.email}
        memberRole={memberToChange.role}
        memberId={memberToChange.id}
        memberType={memberToChange.type}
        token={token}
        setUpdated={setUpdated}
        setSelectDefaultValue={setSelectDefaultValue}
        setUserRole={setUserRole}
      />
      <AddNewMemberModal
        setAddNewModalVisible={setAddNewModalVisible}
        addNewModalVisible={addNewModalVisible}
        companyName={user.organization.name}
        setUpdated={setUpdated}
        setSelectDefaultValue={setSelectDefaultValue}
      />
      <Flexcol style={{ width: '70%' }} center_align>
        <Flexrow space_between baseline_align style={{ width: '100%' }}>
          <Flexcol style={{ width: '100%', position: 'relative' }} top="72px">
            <Flexrow
              onClick={() => onSetTab('companySettings')()}
              center_align
              style={{ position: 'absolute', top: '-30px', cursor: 'pointer' }}
            >
              <img src={BackArrowSrc} style={{ width: '22px' }} />
              <button
                style={{
                  color: '#182429',
                  fontSize: '11px',
                  fontWeight: '500',
                  paddingLeft: '8px',
                }}
              >
                BACK
              </button>
            </Flexrow>
            <Text style={{ color: '#182429', fontSize: '28px' }}>People</Text>
            <Text style={{ color: '#64737F', fontSize: '15px' }}>
              Here you can view, edit and invite team members.
            </Text>
          </Flexcol>
          <Flexrow center_align id="people-all-members">
            <Text
              style={{ color: '#182429', fontSize: '15px', fontWeight: '500' }}
            >
              View:
            </Text>
            <Select
              defaultValue="All members"
              value={selectDefaultValue}
              style={{ margin: '0px 10px', width: '200px' }}
              size="large"
              onChange={filterMembersHandler}
            >
              {memberFilterOptions(quantity)}
            </Select>
          </Flexrow>
          <Flexrow center_align>
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              placement="bottom"
              title={
                currentUserRole === 'MEMBER' &&
                'You do not have the access rights to perform this action.'
              }
            >
              <AddButton
                onClick={() => setAddNewModalVisible(true)}
                id="people-add-member"
                disabled={currentUserRole === 'MEMBER'}
              >
                <img src={AddMoreSrc} style={{ marginRight: '12px' }} />
                Add new member
              </AddButton>
            </Tooltip>
          </Flexrow>
        </Flexrow>
        <Divider />
        {filteredMembers.users?.length > 0 &&
          filteredMembers.users.map((member, index) => (
            <CardWrap top="26px" center_align space_between key={index}>
              <Flexrow center_align style={{ width: '40%' }}>
                <div
                  style={{
                    position: 'relative',
                    width: '34px',
                    height: '34px',
                    backgroundColor: randomColor[index] + '54',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                    padding: '0px 14px',
                  }}
                >
                  {member.id === primaryContact && (
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title="Primary point of contact"
                    >
                      <img
                        src={EllipseSrc}
                        style={{
                          position: 'absolute',
                          top: '-4px',
                          left: '-1px',
                        }}
                      />
                    </Tooltip>
                  )}
                  <Text
                    style={{
                      fontSize: '14px',
                      color: randomColor[index],
                    }}
                  >
                    {member.firstname && member.lastname
                      ? splitName(`${member.firstname} ${member.lastname}`)
                      : splitName(member.email)}
                  </Text>
                </div>
                <Flexcol>
                  <Text style={{ color: '#182A33', fontSize: '14px' }}>
                    {member.firstname && member.lastname
                      ? `${member.firstname} ${member.lastname}`
                      : 'Not Available'}
                  </Text>
                  <Text
                    style={{
                      color: '#64737F',
                      fontSize: '12px',
                      wordBreak: 'break-all',
                    }}
                  >
                    {member.email}
                  </Text>
                </Flexcol>
              </Flexrow>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title={
                  member.id === primaryContact && 'Primary point of contact'
                }
              >
                <Text
                  style={{
                    color: member.role === 'ADMIN' ? '#fff' : '#182A33',
                    fontSize: '9px',
                    backgroundColor:
                      member.role === 'ADMIN' ? '#182A33' : '#fff',
                    borderRadius: '2px',
                    lineHeight: 'initial',
                    width: 'fit-content',
                    padding: '2px 4px',
                    border: member.role === 'MEMBER' && '1px solid #182A33',
                    fontWeight: member.role === 'MEMBER' && '600',
                  }}
                >
                  {member.id === primaryContact && (
                    <img
                      src={StarSrc}
                      style={{ marginRight: '4px', marginBottom: '2px' }}
                    />
                  )}
                  {member.role}
                </Text>
              </Tooltip>
              <Flexrow center_align right="40px" style={{ width: '35%' }}>
                <StatusTag color="#5FDF3E">
                  <CheckCircleFill
                    style={{
                      height: '16px',
                      color: '#5fdf3e',
                      paddingRight: '7px',
                    }}
                  />
                  Active
                </StatusTag>
                <Text style={{ color: '#64737F', fontSize: '12px' }}>
                  {`since: ${formatDate(member.createdAt)}`}
                </Text>
              </Flexrow>
              <Dropdown
                overlay={menu(
                  'ACTIVE',
                  member.id,
                  member.email,
                  member.role,
                  'USER',
                  member.id === primaryContact,
                )}
              >
                <DotsHorizontalRounded
                  id="people-dropdown"
                  style={{
                    width: '25px',
                    color: '#64737F',
                    position: 'absolute',
                    right: '30px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </Dropdown>
            </CardWrap>
          ))}
        {filteredMembers.invitations?.length > 0 &&
          filteredMembers.invitations.map((member, index) => (
            <CardWrap top="26px" center_align space_between key={index}>
              <Flexrow center_align style={{ width: '40%' }}>
                <div
                  style={{
                    width: '34px',
                    height: '34px',
                    backgroundColor: randomColor[index] + '54',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                    padding: '0px 14px',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '14px',
                      color: randomColor[index],
                    }}
                  >
                    {splitName(member.email)}
                  </Text>
                </div>
                <Flexcol>
                  <Text
                    style={{
                      color: '#182A33',
                      fontSize: '14px',
                      wordBreak: 'break-all',
                    }}
                  >
                    {member.email}
                  </Text>
                </Flexcol>
              </Flexrow>
              <Text
                style={{
                  color: member.role === 'ADMIN' ? '#fff' : '#182A33',
                  fontSize: '9px',
                  backgroundColor: member.role === 'ADMIN' ? '#182A33' : '#fff',
                  borderRadius: '2px',
                  lineHeight: 'initial',
                  width: 'fit-content',
                  padding: '2px 4px',
                  border: member.role === 'MEMBER' && '1px solid #182A33',
                  fontWeight: member.role === 'MEMBER' && '600',
                }}
              >
                {member.role}
              </Text>
              <Flexrow center_align right="40px" style={{ width: '35%' }}>
                {getStatusTag(member.status)}
                <Text style={{ color: '#64737F', fontSize: '12px' }}>
                  {`${
                    member.status === 'REMOVED' ? 'on' : 'since'
                  }: ${formatDate(
                    member.status === 'REMOVED'
                      ? member.updatedAt
                      : member.createdAt,
                  )}`}
                </Text>
              </Flexrow>
              <Dropdown
                overlay={menu(
                  member.status,
                  member.id,
                  member.email,
                  member.role,
                  'INVITATION',
                )}
              >
                <DotsHorizontalRounded
                  style={{
                    width: '25px',
                    color: '#64737F',
                    position: 'absolute',
                    right: '30px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </Dropdown>
            </CardWrap>
          ))}
        <div style={{ padding: '42px' }} />
      </Flexcol>
      <style>{`.ant-select-selector, .ant-modal-content { border-radius: 8px !important; }`}</style>
    </>
  );
};
