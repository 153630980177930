import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Typography, Form, Button, Input } from 'antd';
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft';

import FormLayout from '../FormLayout';
import SubsectionTitle from '../../components/wizards-components/SubsectionTitle';
import validate from '../validators/ForgotPasswordWizardValidator';
import { Flexcol } from '../Flexbox';

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

const { Text } = Typography;

const BackWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 5px;
  color: #344ef3;
  cursor: pointer;
`;

const ResetPasswordButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 36px;
  background-color: #344ef3;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: none;
  &:disabled {
    background-color: #fff;
    color: #c1c2c2;
    border: 1px solid #f3f3f4;
    cursor: not-allowed;
  }
  &:hover,
  :active,
  :focus {
    background-color: #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
    color: #fff;
  }
`;

const Email = (props) => {
  const [touched, setTouched] = useState(false);
  const { step_id, errors, setErrors, setForm, form_data, navigation } = props;
  const { email } = form_data;
  const { next } = navigation;
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setErrors(validate(form_data, step_id));
    let user_exists = await axios
      .get(
        `${
          process.env.RAZZLE_ACCOUNT_URL
        }/users/exists?email=${encodeURIComponent(email)}`,
      )
      .then((r) => r.data);
    if (!user_exists.exists) {
      setErrors({
        ...errors,
        email: 'User does not exist',
      });
    }
    if (Object.keys(errors).length === 0 && user_exists.exists) {
      await axios.post(
        `${process.env.RAZZLE_ACCOUNT_URL}/auth/password/recovery-email/${email}`,
      );
      next();
    }
  };

  useEffect(() => {
    setErrors(validate(form_data, step_id));
  }, [form_data]);

  return (
    <FormLayout
      title="Welcome to Mobito"
      subtitle="Your data ecosystem partner"
    >
      <Flexcol>
        <BackWrap onClick={goBack}>
          <ArrowLeft style={{ width: '17px', marginRight: '12px' }} /> Back
        </BackWrap>
        <Text
          style={{ color: '#182429', fontSize: '21px', textAlign: 'center' }}
        >
          Reset your password
        </Text>
        <Text
          style={{
            color: '#64737f',
            fontSize: '15px',
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          Worry not, happens to the best of us. We’ll email you
          <br /> instructions to recover your account.
        </Text>
        <Form>
          <SubsectionTitle tooltip="Enter the email of your account in order to receive instructions">
            Email
          </SubsectionTitle>
          <Form.Item>
            <Input
              name="email"
              value={email}
              placeholder="example@email.com"
              onChange={(event) => {
                setForm({
                  ...form_data,
                  [event.target.name]: event.target.value,
                });
              }}
              onBlur={() => setTouched(true)}
              size="large"
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
              }}
            />
            {errors && errors.email && touched && (
              <Text style={{ color: '#ff4d4f' }}>{errors.email}</Text>
            )}
          </Form.Item>
        </Form>
        <ResetPasswordButton
          className="g-recaptcha"
          data-sitekey={RECAPTCHA_SITE_KEY}
          data-callback="handleClick"
          onClick={handleClick}
          size="large"
        >
          Reset password
        </ResetPasswordButton>
      </Flexcol>
    </FormLayout>
  );
};

export default Email;
