import { Flexcol } from "../../components/Flexbox"
import { Input, Typography } from 'antd'
import { useState } from "react"

const { TextArea } = Input
const { Text } = Typography

export const DeclareInterestModal = p => {
  const { onMessage } = p
  const [interest_message, setInterestMessage] = useState('')
  const _onMessage = e => {
    onMessage(e.target.value)
    setInterestMessage(e.target.value)
  }
  return (
    <Flexcol>
      <Flexcol top='20px'/>
      <Text>
        Thank you for declaring interest in this data product. <br/>
        Please provide some details about the data you are looking for and how would you like to use them. <br/>
        The Mobito team will manage your request and come back to you shortly.
      </Text>
      <Flexcol top='20px'/>
      <TextArea 
        style={{ height: 200 }}
        onChange={_onMessage}
        value={interest_message}/>
    </Flexcol>
  )
}