import Header from '../../Header';
import HeaderWizard from './HeaderWizard';
import LayoutRow from '../../LayoutRow';
import { Divider } from 'antd';

const DashboardLayoutWizard = (props) => (
  <>
    <LayoutRow
      background={'#fff'}
      withShadow={props.withShadow}
      withBorder={props.withBorder}
    >
      <Header
        withNav={props.withNav}
        stepsFor="CREATE_DATA_PRODUCT"
        setWalkthrough={props.setWalkthrough}
      />
    </LayoutRow>
    <div style={{ backgroundColor: '#fff' }}>
      <Divider style={{ margin: '0 0 8px 0' }} />
    </div>
    <LayoutRow background={'#fff'} sticky>
      <HeaderWizard review={props} sticky isS3={props.isS3} />
    </LayoutRow>
    {props.children}
    <style>{`body { background-color: ${
      props.bodyColor && props.bodyColor
    };}`}</style>
  </>
);

export default DashboardLayoutWizard;
