import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { Tabs, Typography, Tooltip, Spin } from 'antd';
import JSONTree from 'react-json-tree';
import { toast } from 'react-toastify';
import axios from 'axios';

import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { HandIndex } from '@styled-icons/bootstrap/HandIndex';
import { NewTab } from '@styled-icons/icomoon/NewTab';

import CopyLinkSrc from '../../../../images/copy.svg';
import { Flexcol, Flexrow } from '../../../../components/Flexbox';
import { strings } from '../../values/strings';
import { scrollStyles } from '../../../../styles/scrollStyles';
import { json_tree_theme } from '../../../../values/json_tree_theme';
import { curlBuilder } from './curlBuilder';
import Notification from '../../../../components/Notification';
import LayoutRow from '../../../../components/LayoutRow';
import ShareListing from '../../../../components/listing-components/ShareListing';
import { useServerData } from '../../../../hooks/useServerData';
import { DataProductWizardHelper } from '../../../../helpers/DataProductWizardHelper';
import { getThumbnailURL } from '../../../../iso-shared/utils/getThumbnailUrl';

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const DocumentationDescriptionWrap = styled.div`
  color: #182429;
  margin-top: 22px;
  width: 65%;
`;

const DescriptionCardWrap = styled.div`
  background-color: #edeffb;
  border-radius: 8px;
  padding: 18px;
  margin-bottom: 8px;
`;

const EndpointCardWrap = styled.div`
  border: 1px solid #667480;
  border-radius: 8px;
  margin-bottom: 26px;
  z-index: 999;
`;

const CopyEndpointLink = styled(Flexrow)`
  background-color: #e2e2e2;
  color: #182a33;
  padding: 6px 14px;
  border-radius: 6px;
  cursor: pointer;
  z-index: 1;
`;

const OpenDocumentationButton = styled.a`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  padding: 10px !important;
  width: fit-content;
  display: block;
  margin-top: 22px;
  margin-left: 28px;
  &:hover {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const OptionText = styled(Text)`
  cursor: pointer;
  color: #182429 !important;
  :hover {
    color: #344ef3;
  }
`;

const EndpointsList = styled(Flexcol)`
  position: sticky;
  top: 216px;
  height: 50vh;
  overflow-y: auto;
  width: 29%;
  ${scrollStyles}
`;

const EndpointsListItem = styled(Text)`
  color: ${(props) => (props.activeEndpoint ? '#3755EF' : '#192A33')};
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 5px;
  border-radius: 4px;
  :hover {
    background-color: #f9faff;
  }
`;

const JsonWrap = styled(Flexcol)`
  padding: 16px;
  background-color: #272822;
  border-radius: 6px;
  margin: 0px 15px 10px 15px;
`;

const CurlScreen = styled.div`
  overflow: auto;
  position: relative;
  background-color: #182a33;
  padding: 43px;
  color: #0097ff;
  border-radius: 6px;
  margin: 0px 15px;
  ${scrollStyles}
`;

const onCopy = (text) => () => {
  navigator.clipboard.writeText(text);
  toast.success(<Notification type="success" text="Copied to clipboard" />);
};

const tryGetJson = (str) => {
  let out = str;
  try {
    out = JSON.parse(str);
  } catch (error) {}
  return out;
};

const DocumentationDescription = (props) => {
  const [expand, setExpand] = useState({});
  const [activeEndpoint, setActiveEndpoint] = useState({});
  const [key, setKey] = useState('1');
  const refs = useRef();
  refs.current = [];
  const { url_id } = props.match.params;
  const [, updator] = useState({});
  const server_project = useServerData('listing');
  const [project, setProject] = useState(server_project);

  const { RAZZLE_BASE_URL } = process.env;

  useEffect(() => {
    const fetchListing = async () => {
      if (!project) {
        let res;
        try {
          if (
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
              url_id,
            )
          ) {
            res = await axios.get(
              `/api/data-placements/editable/${url_id}/origin`,
            );
          } else {
            res = await axios.get(
              `/api/data-placements/editable/origin-by-url/${url_id}`,
            );
          }
        } catch (error) {
          toast.error(
            <Notification
              type="error"
              text="An error occured, please try again"
            />,
          );
          return;
        }
        setProject(res.data);
      }
    };
    fetchListing();
  }, []);

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const helper = new DataProductWizardHelper(
      () => updator({}),
      project.id,
      null,
      project,
    );
    helper.onError = (error) =>
      toast.error(<Notification type="error" text="Could not save changes" />);
    helper.fromProject(project);

    return helper;
  }, [!!project]);

  const imageUrl = helper && getThumbnailURL(helper.thumbnail.value.url);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const scrollHandler = () => {
    // for (let index = 0; index < refs.current.length; index++) {
    //   if (
    //     window.pageYOffset +
    //       window.innerHeight -
    //       refs.current[index].offsetHeight +
    //       180 >=
    //     refs.current[index].offsetTop
    //   ) {
    //     setActiveEndpoint({ [refs.current[index].id]: true });
    //   } else {
    //     setActiveEndpoint((prevState) => ({
    //       ...prevState,
    //       [refs.current[index].id]: false,
    //     }));
    //   }
    // }

    if (window.pageYOffset === 0) window.history.replaceState(null, null, ' ');
  };

  const expandHandler = (index, boolValue) => {
    setExpand((prevState) => {
      return { ...prevState, [index]: boolValue };
    });
  };

  const getKeyHandler = (key) => {
    setKey(key);
    if (key !== '3') {
      window.history.replaceState(null, null, ' ');
    }
  };

  const copyApiLink = (api) => {
    onCopy(`${RAZZLE_BASE_URL}/data-product/${url_id}/documentation#${api}`)();
  };

  const setHash = (api, index, load) => {
    if (index !== null) setActiveEndpoint({ [refs.current[index].id]: true });

    window.history.replaceState(null, null, ' ');
    window.location.hash = api;

    if (load) {
      setTimeout(() => {
        let apiCard = document.getElementById(api);
        window.scrollTo(0, apiCard.offsetTop - 160);
      }, 1000);
    } else {
      let apiCard = document.getElementById(api);
      window.scrollTo(0, apiCard.offsetTop - 160);
    }
  };

  useEffect(() => {
    if (window.location.href.includes('#')) {
      setKey('3');
      setHash(window.location.href.split('#')[1], null, true);
    }
  }, []);

  const nameTrimHandler = (name) => {
    let maxLength = 45;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  return !helper ? (
    <div
      style={{
        backgroundColor: '#f9faff',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <LayoutRow>
      <Flexcol top="40px" bottom="40px">
        <Flexrow
          center_align
          space_between
          style={{
            position: 'sticky',
            top: '0px',
            backgroundColor: '#fff',
            zIndex: '9999',
            paddingTop: '10px',
          }}
        >
          <Flexrow center_align>
            <img
              style={{ marginRight: '10px' }}
              width="40"
              height="40"
              alt="mobility-module-image"
              src={imageUrl}
            />
            <Text style={{ color: '#182429', fontSize: '18px' }}>
              {helper.name.value}
            </Text>
          </Flexrow>
          <ShareListing listingName={helper.name.value} row />
        </Flexrow>
        <Flexrow
          center_align
          style={{
            position: 'sticky',
            top: '50px',
            backgroundColor: '#fff',
            zIndex: '9999',
          }}
        >
          <Title style={{ marginTop: '18px', color: '#182429' }} level={4}>
            Documentation
          </Title>
        </Flexrow>
        <Tabs
          tabBarStyle={{
            fontWeight: 'bold',
            marginTop: 12,
            position: 'sticky',
            top: '105px',
            zIndex: '9999',
            backgroundColor: '#fff',
          }}
          defaultActiveKey="1"
          onChange={getKeyHandler}
          activeKey={key}
        >
          <TabPane tab="Authentication" key="1">
            <DescriptionCardWrap>
              <Flexrow center_align>
                <CheckCircleFill
                  style={{
                    height: '18px',
                    color: '#192A33',
                    marginRight: '10px',
                  }}
                />
                <Text
                  style={{
                    color: '#192A33',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  API key
                </Text>
              </Flexrow>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '14px',
                  display: 'block',
                  width: '65%',
                  marginTop: '10px',
                  marginLeft: '28px',
                }}
              >
                {strings.replace_api_key_description}
              </Text>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '14px',
                  display: 'block',
                  width: '65%',
                  marginTop: '10px',
                  marginLeft: '28px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <HandIndex
                  style={{
                    height: '18px',
                    color: '#3755EF',
                    marginRight: '10px',
                    transform: 'rotate(90deg)',
                  }}
                />
                <Text
                  style={{
                    color: '#3755EF',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                >
                  Find it by going:
                </Text>
                {strings.find_it_by_doing}
              </Text>
            </DescriptionCardWrap>
            <DescriptionCardWrap>
              <Flexrow center_align>
                <CheckCircleFill
                  style={{
                    height: '18px',
                    color: '#192A33',
                    marginRight: '10px',
                  }}
                />
                <Text
                  style={{
                    color: '#192A33',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  Please note:
                </Text>
              </Flexrow>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '14px',
                  display: 'block',
                  width: '65%',
                  marginTop: '10px',
                  marginLeft: '28px',
                }}
              >
                {strings.sample_files_and_documentation_description_part2}
              </Text>
            </DescriptionCardWrap>
          </TabPane>
          <TabPane tab="Response codes" key="2">
            <DescriptionCardWrap>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '14px',
                  display: 'block',
                  width: '65%',
                  marginLeft: '28px',
                }}
              >
                {strings.sample_files_and_documentation_description_part1}
              </Text>
            </DescriptionCardWrap>
            <DocumentationDescriptionWrap>
              <Text
                style={{
                  display: 'block',
                  marginBottom: '28px',
                }}
              >
                {strings.sample_files_and_documentation_description_part3}
              </Text>
              <ul>
                <li>
                  <Text style={{ fontWeight: '600' }}>Code 200</Text> indicates
                  a <Text underline>successful</Text> response
                </li>
                <li>
                  <Text style={{ fontWeight: '600' }}>Code 500</Text> indicates
                  an <Text underline>unsuccessful</Text> response
                </li>
                <li>
                  <Text style={{ fontWeight: '600' }}>Code 401</Text> indicates{' '}
                  <Text underline>unauthorized</Text> access
                </li>
                <li
                  style={{
                    marginBottom: '28px',
                  }}
                >
                  <Text style={{ fontWeight: '600' }}>Code 429</Text> indicates{' '}
                  <Text underline>too many requests</Text> error
                </li>
                <li>
                  <Text style={{ fontWeight: '600' }}>
                    In case of 429 error
                  </Text>
                  , a relevant message is provided to indicate the specific
                  limit that has been reached:
                  <ul style={{ listStyle: 'none' }}>
                    <li
                      style={{
                        marginTop: '28px',
                      }}
                    >
                      Per minute limit message:
                      <br />
                      {`{ error: "Limit: limit_minute failed" }`}
                    </li>
                    <br />
                    <li>
                      Per hour limit message:
                      <br />
                      {`{ error: "Limit: limit_hour failed" }`}
                    </li>
                    <br />
                    <li>
                      Per day limit message:
                      <br />
                      {`{ error: "Limit: limit_day failed" }`}
                    </li>
                  </ul>
                </li>
                <li
                  style={{
                    marginTop: '28px',
                  }}
                >
                  <Text style={{ fontWeight: '600' }}>
                    In case you reached the amount of 'X' purchased API requests
                  </Text>{' '}
                  or 'X' allowed API requests per month:
                  <ul style={{ listStyle: 'none' }}>
                    <li
                      style={{
                        marginTop: '28px',
                      }}
                    >
                      {`{ error: "No requests allowance, limit requests: X" }`}
                    </li>
                  </ul>
                </li>
              </ul>
            </DocumentationDescriptionWrap>
          </TabPane>
          <TabPane tab="Endpoints" key="3">
            <DescriptionCardWrap>
              <Flexrow center_align>
                <CheckCircleFill
                  style={{
                    height: '18px',
                    color: '#192A33',
                    marginRight: '10px',
                  }}
                />
                <Text
                  style={{
                    color: '#192A33',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  Use the cURL commands
                </Text>
              </Flexrow>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '14px',
                  display: 'block',
                  width: '65%',
                  marginTop: '10px',
                  marginLeft: '28px',
                }}
              >
                {strings.use_the_curl_commands}
              </Text>
            </DescriptionCardWrap>
            <DescriptionCardWrap>
              <Flexrow center_align>
                <CheckCircleFill
                  style={{
                    height: '18px',
                    color: '#192A33',
                    marginRight: '10px',
                  }}
                />
                <Text
                  style={{
                    color: '#192A33',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  Consult the documentation of the provider
                </Text>
              </Flexrow>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '14px',
                  display: 'block',
                  width: '65%',
                  marginTop: '10px',
                  marginLeft: '28px',
                }}
              >
                {strings.consult_the_documentation_of_the_provider}
              </Text>
              <OpenDocumentationButton
                href={helper.api_documentation_link.value}
                alt={helper.api_documentation_link.value}
                target="_blank"
              >
                View documentation of the provider
                <NewTab style={{ height: '18px', marginLeft: '16px' }} />
              </OpenDocumentationButton>
            </DescriptionCardWrap>
            <Text
              style={{
                color: '#192A33',
                fontSize: '16px',
                fontWeight: '600',
                marginBottom: '22px',
                marginTop: '48px',
                display: 'block',
                position: 'sticky',
                top: '176px',
              }}
            >
              Endpoints list
            </Text>
            <Flexrow space_between>
              <EndpointsList>
                {helper.endpoints.value.map((endpoint, index) => (
                  <Tooltip
                    color="#1a337d"
                    overlayStyle={{
                      display: endpoint.endpoint.length < 45 && 'none',
                    }}
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottomLeft"
                    title={endpoint.endpoint}
                  >
                    <EndpointsListItem
                      key={index}
                      activeEndpoint={activeEndpoint[endpoint.endpoint]}
                      onClick={() => setHash(endpoint.endpoint, index, false)}
                    >
                      {nameTrimHandler(endpoint.endpoint)}
                    </EndpointsListItem>
                  </Tooltip>
                ))}
              </EndpointsList>
              <Flexcol end_align style={{ width: '70%' }}>
                {helper.endpoints.value.map((endpoint, index) => {
                  const curl = curlBuilder(helper.domain.value, endpoint);
                  return (
                    <div
                      key={index}
                      id={endpoint.endpoint}
                      ref={addToRefs}
                      style={{ width: '100%' }}
                    >
                      <EndpointCardWrap>
                        <Flexrow
                          center_align
                          space_between
                          style={{
                            backgroundColor: '#F2F2F3',
                            borderBottom: '1px solid #667480',
                            borderRadius: '8px 8px 0px 0px',
                            padding: '14px',
                          }}
                        >
                          <Flexrow center_align>
                            <Text
                              style={{
                                color: '#fff',
                                fontSize: '14px',
                                fontWeight: '600',
                                backgroundColor: '#4DD6B6',
                                padding: '5px 10px',
                                borderRadius: '4px',
                                marginRight: '16px',
                              }}
                            >
                              {endpoint.http_method}
                            </Text>
                            <Tooltip
                              color="#1a337d"
                              overlayStyle={{
                                display:
                                  endpoint.endpoint.length < 45 && 'none',
                              }}
                              overlayInnerStyle={{
                                boxShadow: '2px 4px 12px 0px #182a331f',
                                fontSize: '13px',
                                borderRadius: '4px',
                              }}
                              placement="bottom"
                              title={endpoint.endpoint}
                            >
                              <Title
                                level={4}
                                style={{
                                  color: '#192A33',
                                  margin: 0,
                                  zIndex: 1,
                                }}
                              >
                                {nameTrimHandler(endpoint.endpoint)}
                              </Title>
                            </Tooltip>
                          </Flexrow>
                          <CopyEndpointLink
                            center_align
                            onClick={() => copyApiLink(endpoint.endpoint)}
                          >
                            <img
                              src={CopyLinkSrc}
                              style={{
                                height: '22px',
                                marginRight: '10px',
                                color: '#182A33',
                              }}
                              alt="Copy link"
                            />
                            Copy Endpoint link
                          </CopyEndpointLink>
                        </Flexrow>
                        <Text
                          style={{
                            color: '#667480',
                            fontWeight: '15px',
                            marginTop: '22px',
                            marginLeft: '18px',
                            display: 'block',
                          }}
                        >
                          {endpoint.description}
                        </Text>
                        <Title
                          level={5}
                          style={{
                            color: '#192A33',
                            marginLeft: '15px',
                            marginTop: '36px',
                          }}
                        >
                          cURL example
                        </Title>
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          placement="bottom"
                          title="Copy to clipboard"
                        >
                          <Text
                            style={{
                              color: '#192A33',
                              fontSize: '14px',
                              cursor: 'pointer',
                              marginLeft: '15px',
                              marginBottom: '10px',
                              display: 'block',
                              width: 'fit-content',
                            }}
                            onClick={onCopy(curl)}
                          >
                            Copy
                          </Text>
                        </Tooltip>
                        <CurlScreen>{curl}</CurlScreen>
                        <Flexrow top="16px" />
                        <Title
                          level={5}
                          style={{
                            color: '#192A33',
                            marginLeft: '15px',
                            marginTop: '36px',
                          }}
                        >
                          Sample response
                        </Title>
                        <Flexrow bottom="10px" left="15px">
                          <OptionText onClick={onCopy(endpoint.sample_data)}>
                            Copy
                          </OptionText>
                          <Flexrow left="20px"></Flexrow>
                          <OptionText
                            onClick={() => expandHandler(index, true)}
                          >
                            Expand all
                          </OptionText>
                          <Flexrow left="20px"></Flexrow>
                          <OptionText
                            onClick={() => expandHandler(index, false)}
                          >
                            Collapse all
                          </OptionText>
                        </Flexrow>
                        <JsonWrap>
                          <JSONTree
                            data={tryGetJson(endpoint.sample_data)}
                            hideRoot
                            shouldExpandNode={() => !!expand[index]}
                            theme={json_tree_theme}
                            invertTheme={false}
                          />
                        </JsonWrap>
                      </EndpointCardWrap>
                    </div>
                  );
                })}
              </Flexcol>
            </Flexrow>
          </TabPane>
        </Tabs>
      </Flexcol>
      <style>{`.ant-tabs { overflow: initial !important;}`}</style>
    </LayoutRow>
  );
};

export default DocumentationDescription;
