import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AccountContext } from '../hooks/AccountContext';
import { LoginRedirector } from '../services/LoginRedirector';
import Cookies from 'js-cookie';

export const makeAuthenticatedView = (View) => (props) => {
  const { isComplete } = useContext(AccountContext);
  const history = useHistory();
  const account = Cookies.get('account')
    ? JSON.parse(Cookies.get('account'))
    : '';
  const token = Cookies.get('jwt') ? Cookies.get('jwt') : '';

  useEffect(() => {
    if (!token) {
      LoginRedirector.toLogin(history);
    }
  }, [token]);

  const goToProfileHandler = () => {
    if (!isComplete.personal) {
      history.push('/account');
    } else if (!isComplete.company) {
      history.push({ pathname: '/account', state: { key: 'company' } });
    }
  };

  useEffect(() => {
    if (account) {
      if (!isComplete.personal || !isComplete.company) {
        goToProfileHandler();
      }
    }
  }, []);

  if (account) {
    return <View {...props} />;
  }
  return "Fetching user's credentials";
};
