import styled from "styled-components";
import { Typography, Divider } from "antd";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";

import LayoutRow from "./LayoutRow.jsx";
import { Flexcol, Flexrow } from "./Flexbox";
import background_marketplace_img from "../images/Signup_background.webp";

const { Text } = Typography;

const Container = styled.div`
  background-image: url(${background_marketplace_img});
  background-size: cover;
  background-position: center;
`;

const Wrap = styled.div`
  text-align: center;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #fff;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  margin-bottom: -80px;
  transform: translateY(-50%);
`;

const ActionButton = styled.button`
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  width: 100%;
  font-size: 14px;
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
`;

const ContactBanner = () => {
  const history = useHistory();

  const createProject = async () => {
    const new_id = uuid();
    history.push(`/project/data-wizard/${new_id}`);
  };

  const createRequestProject = async () => {
    const new_id = uuid();
    history.push(`/project/data-request-wizard/${new_id}`);
  };

  return (
    <Container>
      <LayoutRow>
        <Wrap>
          <Text
            style={{
              color: "#182429",
              fontSize: "20px",
              marginTop: "30px",
              marginBottom: "26px",
              display: "block",
            }}
          >
            Looking for something else?
          </Text>
          <Flexrow space_around padding="0 0 32px 0">
            <Flexcol id="homepage-fill-data-request">
              <Text
                style={{
                  color: "#515d64",
                  fontSize: "16px",
                  marginBottom: "24px",
                }}
              >
                Fill in a Data Request form
              </Text>
              <ActionButton onClick={() => createRequestProject()}>
                Request data
              </ActionButton>
            </Flexcol>
            <Text style={{ color: "#787993", fontSize: "14px" }}>
              <Divider
                style={{ transform: "rotate(90deg)", color: "#787993" }}
              />
              OR
              <Divider
                style={{ transform: "rotate(90deg)", color: "#787993" }}
              />
            </Text>
            <Flexcol id="homepage-fill-data-supply">
              <Text
                style={{
                  color: "#515d64",
                  fontSize: "16px",
                  marginBottom: "24px",
                }}
              >
                Fill in a Data Supply form
              </Text>
              <ActionButton onClick={() => createProject()}>
                Provide data
              </ActionButton>
            </Flexcol>
          </Flexrow>
        </Wrap>
      </LayoutRow>
    </Container>
  );
};

export default ContactBanner;
