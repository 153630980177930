import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Typography, Input, Select, Button, Form, Divider } from 'antd';
import Cookies from 'js-cookie';
import { mutate } from 'swr';
import { toast } from 'react-toastify';

import { UndoOutlined } from '@ant-design/icons';

import { AccountContext } from '../hooks/AccountContext';
import Notification from '../components/Notification';
import { Flexcol, Flexrow } from '../components/Flexbox';
import instance from '../services/TokenValidator';

const { Text } = Typography;
const { Option } = Select;

const Wrap = styled(Form)`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 32px;
  height: fit-content;
  width: 65%;
  margin-top: 26px;
`;

const SaveButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const business_roles = [
  'Business Analyst',
  'Business Developer',
  'Business Intelligence Analyst',
  'Chief Executive Officer (CEO)',
  'Chief Technology Officer (CTO)',
  'Data Analyst',
  'Data Scientist',
  'Information Officer',
  'Marketing Manager',
  'Operations Manager',
  'Product Manager',
  'Project Manager',
  'Public Sector Employee',
  'Researcher',
  'Sales Manager',
  'Software Engineer',
];

const business_role_options = business_roles.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

const required_rule = { required: true, message: 'Required field' };
const no_empty_space_and_min_length_rule = {
  pattern: /(.*[a-z]){3}/i,
  message: 'The value must be at least three characters long',
};
const num_regex = /\d/;

const noNumValidator = async (_, value) => {
  if (num_regex.test(value)) {
    throw new Error('Cannot have numbers');
  }
};

export const EditProfile = (p) => {
  const { user, setIsUpdated, setCanSave, canSave, reset } = p;
  const { setAccount, setIsComplete } = useContext(AccountContext);
  const [updates, updator] = useState({});
  const [saving, setSaving] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [form] = Form.useForm();
  const token = Cookies.get('jwt');
  const userId = Cookies.get('user_id');
  const currentUserRole = Cookies.get('role');
  const { firstname, lastname, business_role, other_business_role } =
    form.getFieldsValue();
  const account = JSON.parse(Cookies.get('account'));

  useEffect(() => {
    let business_role;
    let other_business_role = '';
    if (business_roles.includes(user.user.businessRole)) {
      business_role = user.user.businessRole;
    } else if (!user.user.businessRole) {
      business_role;
    } else {
      business_role = 'other';
      other_business_role = user.user.businessRole;
    }
    form.setFieldsValue({
      firstname: user.user.firstname,
      lastname: user.user.lastname,
      business_role,
      other_business_role,
    });
    updator({});

    setIsOther(business_role === 'other');

    if (!user.user.businessRole || !user.user.firstname || !user.user.lastname)
      toast.warning(
        <Notification
          type="warning"
          text="You will need to fill in your personal account details."
        />,
      );
  }, []);

  const can_save =
    updates.changed &&
    firstname &&
    !num_regex.test(firstname) &&
    lastname &&
    !num_regex.test(lastname) &&
    business_role &&
    (business_role !== 'other' || other_business_role) &&
    (firstname !== user.user.firstname ||
      lastname !== user.user.lastname ||
      (business_role !== 'other'
        ? business_role !== user.user.businessRole
        : other_business_role !== user.user.businessRole));

  useEffect(() => {
    setIsUpdated((prevState) => ({ ...prevState, profile: can_save }));
  }, [can_save]);

  const onSave = async () => {
    setSaving(true);
    const update_obj = {
      user: {
        firstname,
        lastname,
      },
      businessRole: {
        business_role:
          business_role != 'other' ? business_role : other_business_role,
        userId: userId,
        role: currentUserRole,
      },
    };

    const deploymentDomain =
      process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

    try {
      await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/users`,
        update_obj.user,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations/users/role`,
        {
          userId: update_obj.businessRole.userId,
          role: 'ADMIN',
          businessRole: update_obj.businessRole.business_role,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      mutate([`${process.env.RAZZLE_ACCOUNT_URL}/users`, token]);
      mutate([
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations/users/role`,
        token,
      ]);
      Cookies.set('firstname', firstname, { domain: deploymentDomain });
      Cookies.set('lastname', lastname, { domain: deploymentDomain });
      Cookies.set(
        'account',
        {
          ...account,
          user: {
            ...user.user,
            businessRole: update_obj.businessRole.business_role,
            firstname: update_obj.user.firstname,
            lastname: update_obj.user.lastname,
          },
        },
        { domain: deploymentDomain },
      );
      toast.success(<Notification type="success" text="Changes saved" />);
      setAccount({
        ...account,
        user: {
          ...user.user,
          businessRole: update_obj.businessRole.business_role,
          firstname: update_obj.user.firstname,
          lastname: update_obj.user.lastname,
        },
      });

      if (firstname && lastname && business_role)
        setIsComplete((prevState) => ({ ...prevState, personal: true }));
      updator({});
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Something went wrong, please try again later"
        />,
      );
    }
    setIsOther(business_role === 'other');
    setSaving(false);
    setIsUpdated((prevState) => ({ ...prevState, profile: false }));
    setCanSave((prevState) => ({ ...prevState, profile: false }));
  };

  useEffect(() => {
    if (canSave.profile) {
      onSave();
    }
  }, [canSave.profile]);

  useEffect(() => {
    if (reset.profile) {
      resetHandler();
    }
  }, [reset.profile]);

  const resetHandler = () => {
    form.setFieldsValue({
      firstname: user.user.firstname,
      lastname: user.user.lastname,
    });

    if (isOther) {
      form.setFieldsValue({
        business_role: 'other',
        other_business_role: user.user.businessRole,
      });
    } else {
      form.setFieldsValue({
        business_role: user.user.businessRole,
      });
    }

    updator({ changed: false });
  };

  return (
    <>
      <Flexcol style={{ width: '65%' }} center_align>
        <Flexcol style={{ width: '100%' }} top="72px">
          <Text style={{ color: '#182429', fontSize: '28px' }}>
            Edit personal information
          </Text>
          <Text style={{ color: '#64737F', fontSize: '15px' }}>
            Here you can edit your personal profile information.
          </Text>
          <Divider />
        </Flexcol>
        <Wrap
          onFieldsChange={() => updator({ changed: true })}
          form={form}
          initialValues={{
            firstname: user.user.firstname,
            lastname: user.user.lastname,
            business_role: user.user.businessRole,
          }}
        >
          <Flexrow center_align bottom="8px">
            <Text
              style={{
                color: '#182429',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
              First name
            </Text>
          </Flexrow>
          <Form.Item
            validateFirst
            name="firstname"
            rules={[
              required_rule,
              { validator: noNumValidator },
              no_empty_space_and_min_length_rule,
            ]}
            validateStatus={!user.user.firstname && !firstname && 'error'}
          >
            <Input
              disabled={saving}
              placeholder="Type your first name"
              size="large"
            />
          </Form.Item>
          <Flexrow center_align bottom="8px">
            <Text
              style={{
                color: '#182429',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
              Surname
            </Text>
          </Flexrow>
          <Form.Item
            validateFirst
            name="lastname"
            rules={[
              required_rule,
              { validator: noNumValidator },
              no_empty_space_and_min_length_rule,
            ]}
            validateStatus={!user.user.lastname && !lastname && 'error'}
          >
            <Input
              disabled={saving}
              placeholder="Type your last name"
              size="large"
            />
          </Form.Item>
          <Flexrow center_align bottom="8px">
            <Text
              style={{
                color: '#182429',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
              Job title
            </Text>
          </Flexrow>
          <Form.Item
            validateFirst
            name="business_role"
            rules={[required_rule]}
            validateStatus={!user.business_role && !business_role && 'error'}
          >
            <Select disabled={saving} placeholder="Select" size="large">
              {business_role_options}
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          {business_role == 'other' && (
            <Form.Item
              validateFirst
              name="other_business_role"
              rules={[required_rule, no_empty_space_and_min_length_rule]}
              validateStatus={
                !user.user.businessRole && !other_business_role && 'error'
              }
            >
              <Input
                placeholder="Please specify"
                disabled={saving}
                size="large"
              />
            </Form.Item>
          )}
          <Flexrow
            id="functional-buttons"
            center_align
            space_between
            top="26px"
          >
            {can_save ? (
              <Text
                id="reset-changes"
                style={{
                  color: '#344EF3',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={resetHandler}
              >
                <UndoOutlined />
                &nbsp; RESET CHANGES
              </Text>
            ) : (
              <Flexrow grow />
            )}
            <SaveButton
              id="save-changes"
              disabled={!can_save}
              onClick={onSave}
              loading={saving}
            >
              Save changes
            </SaveButton>
          </Flexrow>
        </Wrap>
        <div style={{ padding: '42px' }} />
      </Flexcol>
      <style>{`.ant-form-item-explain { color: #ff4d4f !important}`}</style>
    </>
  );
};
