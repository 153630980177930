import React, { useMemo, useContext, useEffect } from 'react';
import { Form, Input, Select, Upload } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import TitleAndDesc from '../../wizards-components/TitleAndDesc';
import SubsectionTitle from '../../wizards-components/SubsectionTitle';
import { SectionTitle } from '../../wizards-components/SectionTitle';

import { SectionCardContainer } from '../../wizards-components/styled-components';
import { strings } from '../values/strings';
import { DataProductContext } from '../../../helpers/DataProductWizardHelper';
import { Flexrow } from '../../Flexbox';
import useCategories from '../../../hooks/useCategories';
import { getThumbnailURL } from '../../../iso-shared/utils/getThumbnailUrl';
import Notification from '../../Notification';

const name_form_rules = [
  {
    required: true,
    message: 'Please input project name!',
  },
];

const BasicProductDetails = () => {
  const helper = useContext(DataProductContext);
  const form_fields = [{ name: ['project_name'], value: helper.name.value }];
  const { categories } = useCategories();
  const history = useHistory();

  const categories_options = useMemo(
    () =>
      categories.map((category) => {
        return {
          label: category.name,
          value: category.id,
        };
      }),
    [categories],
  );

  const imageUploadRequest = async (p) => {
    const data = new FormData();
    data.append('image', p.file);
    const url = URL.createObjectURL(p.file);
    helper.thumbnail.set({ url, data });
  };

  useEffect(async () => {
    if (history.location.state?.key === 's3') {
      const type = 's3_data';
      try {
        await axios.put(
          `/api/data-placements/editable/${helper.id}/type`,
          { type },
          {
            headers: { Authorization: `Bearer ${helper.token}` },
          },
        );
      } catch (error) {
        console.error(error);
        toast.error(<Notification type="error" text="Could not change type" />);
      }
    }
  }, []);

  return (
    <SectionCardContainer>
      <Form fields={form_fields}>
        <TitleAndDesc
          title="Required details"
          desc={strings.basic_section_desc}
        />
        <SectionTitle
          title="Basic details"
          saving={helper.sections_map.details.saving}
          tooltip={strings.basic_title_tooltip}
        />

        <SubsectionTitle tooltip="Short name of the Listing">
          Name
        </SubsectionTitle>
        <Form.Item name="project_name" rules={name_form_rules}>
          <Input
            onChange={(e) => helper.name.set(e.target.value)}
            placeholder="Type the name of your data product"
          />
        </Form.Item>

        <Flexrow top="16px" />

        <SubsectionTitle tooltip={strings.data_cat_tooltip}>
          Data category
        </SubsectionTitle>
        <Select
          options={categories_options}
          value={categories_options.length ? helper.category.value : null}
          onChange={(v) => helper.category.set(v)}
          style={{ width: '100%' }}
          placeholder="Select"
        />

        <Flexrow top="16px" />

        <SubsectionTitle title="Image" tooltip={strings.img_tooltip}>
          Image
        </SubsectionTitle>

        <Upload
          customRequest={imageUploadRequest}
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
        >
          <img
            src={getThumbnailURL(helper.thumbnail.value.url)}
            alt="avatar"
            style={{ width: '100%', backgroundColor: '#fff', padding: '12px' }}
          />
        </Upload>
      </Form>
    </SectionCardContainer>
  );
};

export default BasicProductDetails;
