import React from "react";
import { Bulb } from "@styled-icons/ionicons-outline/Bulb";
import { Row, Col } from "antd";

import { Title, Desc, Separator } from "./styled-components";
import Colors from "./colors";

const IntegrationHelper = (props) => {
  const { title, desc } = props;

  return (
    <Row>
      <Col span={24}>
        <Bulb style={{ color: Colors.description, height: "30px" }} />
      </Col>
      <Col span={24}>
        <Separator height={13} />
      </Col>
      <Col spoan={24}>
        <Title size={16} weight={600} color={Colors.description}>
          {title}
        </Title>
      </Col>
      <Col span={24}>
        <Separator height={13} />
      </Col>
      <Col span={24}>
        <Desc pre size={13}>
          {desc}
        </Desc>
      </Col>
    </Row>
  );
};

export default IntegrationHelper;
