import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  Col,
  Row,
  Typography,
  Input,
  Select,
  Button,
  Divider,
  Tooltip,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { KeyboardArrowLeft } from "styled-icons/material";
import { colors } from "../values/colors";
import styled from "styled-components";
import Header from "../components/Header";
import LayoutRow from "../components/LayoutRow";
import { SelectableListing } from "../components/SelectableListing";
import axios from "axios";
import { confirmModal } from "../views/ModalView";
import { toast } from "react-toastify";
import Notification from "../components/Notification";

const { RAZZLE_MOBITO_SYSTEMIC_BACKEND } = process.env;

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const Wrap = styled(Row)`
  margin: 32px 48px;
`;

const BackIcon = styled(KeyboardArrowLeft)`
  height: 32px;
  color: ${colors.grey_6};
  position: absolute;
  left: 32px;
  top: 15px;
  cursor: pointer;
`;

const TabContent = styled(Row)`
  margin: 12px 16px;
`;

const Box = styled(Row)`
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${colors.grey_2};
  padding: 20px 24px;
`;

const TopBar = styled(Row)`
  background-color: white;
  border-bottom: 1px solid ${colors.grey_2};
  padding: 12px 16px;
  position: relative;
`;

const DataConsumptionWizard = (props) => {
  const [listings, setListings] = useState([]);
  const [selected_listings, setSelectedListings] = useState({});
  const [selected_cat, setSelectedCat] = useState(null);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [checked_query, setCheckedQuery] = useState(false);
  const history = useHistory();
  const token = Cookies.get("jwt") || "";

  useEffect(() => {
    (async () => {
      const res = await axios.get(`/api/categories/`);
      setCategories(res.data);
    })();
  }, []);

  useEffect(() => {
    if (!token) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const res = await axios.get("api/metadata-search", {
        params: { text: search },
      });
      const listings = res.data.filter((e) => e.published_step != 2);
      setListings(listings);
      if (checked_query) {
        return;
      }
      setCheckedQuery(true);
      const params = new URLSearchParams(window.location.search);
      const query_listing_id = params.get("selected_listing");
      const query_listing =
        query_listing_id && listings.find((e) => e.id == query_listing_id);
      if (query_listing) {
        setSelectedListings({
          [query_listing_id]: query_listing,
        });
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [search]);

  const onListingSelect = (id) => () =>
    setSelectedListings({
      ...selected_listings,
      [id]: listings.find((e) => e.id == id),
    });

  const onListingDeselect = (id) => () => {
    const selected = { ...selected_listings };
    delete selected[id];
    setSelectedListings(selected);
  };

  const missing = [];
  !name && missing.push("name");
  !selected_cat && missing.push("category");
  !Object.keys(selected_listings).length && missing.push("selected listing");

  let missing_tooltip = null;
  if (missing.length) {
    missing_tooltip = "Required selections are missing: " + missing.join(", ");
  }

  const onSubmit = async () => {
    const confirmation = await confirmModal({
      title: "Important Note",
      content: (
        <>
          <p>
            You are about to send an access request to the providers of the
            selected marketplace listings.
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      ),
    });
    if (!confirmation) {
      return;
    }
    try {
      await axios.post(
        `/api/data-consumptions`,
        {
          category: selected_cat,
          listing_ids: Object.keys(selected_listings),
          listings: [],
          name,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(
        <Notification type="success" text="Consumption project created" />
      );
      history.push("/dashboard");
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Could not create consumption project"
        />
      );
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <LayoutRow background={"#ffffff"} withBorder>
            <Header />
          </LayoutRow>
          <TopBar justify="end">
            <BackIcon
              onClick={() =>
                history.push({ pathname: `/dashboard`, state: { key: "2" } })
              }
            />
            <Tooltip key="tooltip" placement="left" title={missing_tooltip}>
              <Button
                onClick={onSubmit}
                disabled={!!missing_tooltip}
                type="primary"
                size="large"
              >
                Submit request
              </Button>
            </Tooltip>
          </TopBar>
          <Wrap>
            <Tabs tabPosition="left">
              {/* details tab */}
              <TabPane tab="Project details" key="1">
                <TabContent>
                  <Col>
                    <Row>
                      <Title level={3}>Project details</Title>
                    </Row>
                    <Box>
                      <Col>
                        <Row>
                          <Col>
                            <ExclamationCircleOutlined />
                          </Col>
                          <Col style={{ marginLeft: 8 }}>
                            <Title level={5}>Give it a name *</Title>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Input
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              style={{ width: 450, marginLeft: 20 }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                          <Col>
                            <ExclamationCircleOutlined />
                          </Col>
                          <Col style={{ marginLeft: 8 }}>
                            <Title level={5}>
                              What is your project about *
                            </Title>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Select
                              value={selected_cat}
                              onChange={(id) => setSelectedCat(id)}
                              style={{ width: 450, marginLeft: 20 }}
                            >
                              {categories.map((e) => (
                                <Select.Option key={e.id} value={e.id}>
                                  {e.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      </Col>
                    </Box>
                  </Col>
                </TabContent>
              </TabPane>
              {/* listings tab */}
              <TabPane id="this tab" tab="Select listing" key="2">
                <TabContent>
                  <Col span={24}>
                    <Row>
                      <Col>
                        <Title level={3}>
                          Search and select at least one listing
                        </Title>
                      </Col>
                    </Row>
                    <Box style={{ width: 900 }}>
                      <Col span={24}>
                        <Row justify="center">
                          <Col span={10}>
                            <Input
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              placeholder="Search for a listing"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Title level={5}>Selected listings:</Title>
                          </Col>
                        </Row>
                        <Row gutter={[0, 32]}>
                          {Object.values(selected_listings).map((e) => (
                            <Col key={e.id} span={12}>
                              <SelectableListing
                                listing={e}
                                onClick={onListingDeselect(e.id)}
                                selected
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Divider />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Title level={5}>Other available listings:</Title>
                          </Col>
                        </Row>
                        <Row gutter={[0, 32]}>
                          {listings
                            .filter((e) => !selected_listings[e.id])
                            .map((e) => (
                              <Col key={e.id} span={12}>
                                <SelectableListing
                                  listing={e}
                                  onClick={onListingSelect(e.id)}
                                />
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Box>
                  </Col>
                </TabContent>
              </TabPane>
            </Tabs>
          </Wrap>
        </Col>
      </Row>
      <style>{`body { background-color: ${colors.grey_1};}`}</style>
    </>
  );
};

export default DataConsumptionWizard;
