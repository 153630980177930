export const strings = {
  step_one_title: "Read & accept the data license terms and conditions",
  step_one_desc: `This is the data license terms and conditions as specified by the data provider.
  It is the legal document that governs the relationship between you and the data provider and specifies under which terms you are eligible to use the data.
  
  Read carefully all the paragraphs and accept the terms & condition at the bottom of the text in order to proceed to the next step.`,
  step_two_per_package_title: "Select a pricing plan",
  step_two_per_package_desc: `Here you can see the different pricing plans that are set by the data provider.

  Each plan has a monthly price and API access requests limits.
  
  Select the plan that meets your needs and indicate how many months of access you would like to purchase.`,
  step_two_per_request_title: "Select a pricing plan",
  step_two_per_request_desc: `Here you can see the pricing plan and its API access request limits that are set by the data provider.

  Indicate how many API reqests you would like to purchase.`,
  step_two_free_title: "Free price plan with API access request limits",
  step_two_free_desc: `This is a Free price data product with API access request limits.

   Proceed to the next step and complete the process in order your unique API access key to be generated.`,
  step_three_title: "Payment details",
  step_three_desc: `Here you see the summary of the API access that you have chosen to purchase.

    Input your credit card details in order to proceed with the payment.`,
};
