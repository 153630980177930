import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: process.env.RAZZLE_BASE_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('jwt');
    if (token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const deploymentDomain =
      process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await refreshToken();
          Cookies.set('jwt', rs.data.jwt, { domain: deploymentDomain });
          instance.defaults.headers.common['x-access-token'] = rs.data.jwt;

          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  },
);

const refreshToken = () => {
  return instance.post(`${process.env.RAZZLE_ACCOUNT_URL}/auth/refresh`, {
    refreshToken: Cookies.get('refreshToken'),
  });
};

export default instance;
