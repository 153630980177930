import { Flexrow, Flexcol } from '../../Flexbox';
import { Typography, Tooltip } from 'antd';
import InfoSrc from '../../../images/attributes-info.svg';

const { Text } = Typography;

export const AttachmentsList = (props) => {
  const { attachments } = props;

  return (
    <Flexcol left="20px">
      {attachments?.map((item, index) => (
        <Flexrow key={index} center_align bottom="8px">
          <Text
            style={{
              color: '#182a33',
              fontSize: '15px',
            }}
          >
            {item.file_name}
          </Text>
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title={item?.description}
          >
            <img src={InfoSrc} style={{ marginLeft: 18 }} />
          </Tooltip>
        </Flexrow>
      ))}
    </Flexcol>
  );
};
