import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Typography, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { LeftOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { toast } from "react-toastify";

import { Flexrow } from "../../Flexbox";
import { confirmModal } from "../../../views/ModalView";
import {
  SubmitButton,
  PreviewButton,
  GoToDashBoardContainer,
  ButtonsContainer,
} from "../../wizards-components/styled-components";
import { DataRequestContext } from "../../../helpers/DataRequestWizardHelper";
import Notification from "../../Notification";

const { Title } = Typography;
const EditButton = PreviewButton;

const confirmation_content = {
  title: "Are you sure?",
  content: (
    <>
      <p>
        You are about to submit your data request for review from Mobito
        experts.
      </p>
      <p>Do you want to proceed?</p>
    </>
  ),
};

const HeaderWrap = styled.div`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  font-size: ${(props) => (props.show ? "16px" : "0px")};
  margin-left: 30px;
  transition: all 0.3s ease-in-out;
`;

const HeaderWizard = ({ no_back_button, sticky }) => {
  const token = Cookies.get("jwt");
  const helper = useContext(DataRequestContext);
  const history = useHistory();
  const [stickyHeader, setStickyHeader] = useState(false);

  if (helper.for_review) {
    return (
      <>
        <Flexrow grow />
        <Title style={{ marginBottom: 0, textAlign: "end" }} level={5}>
          Submitted for review
        </Title>
      </>
    );
  }

  const input_misses = helper.inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());

  const misses_tooltip = input_misses.length
    ? "Check the following inputs: " + input_misses.join(", ")
    : null;

  const confirmChangesSubmit = async (step) => {
    const confirmation = await confirmModal(confirmation_content);
    if (!confirmation) {
      return;
    }
    helper.submitting = true;
    helper.refresh();
    try {
      await axios.post(
        `/api/data-requests/editable/${helper.id}/publish-request`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      history.push({ pathname: `/dashboard`, state: { key: "3" } });
      toast.success(
        <Notification type="success" text="Your request is now under review" />
      );
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="An error occured, please try again later"
        />
      );
    }
  };

  const goToDashboard = () => {
    history.push({ pathname: `/dashboard`, state: { key: "3" } });
  };

  const global_saving = !!helper.sections.find((e) => e.saving || e.editing);

  const button_disabled = global_saving || helper.submitting;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setStickyHeader(window.pageYOffset > 180)
      );
    }
  }, []);

  return (
    <>
      <ButtonsContainer>
        {!no_back_button && (
          <GoToDashBoardContainer>
            <LeftOutlined
              onClick={goToDashboard}
              style={{
                marginRight: "16px",
                color: "#64737f",
                fontSize: "18px",
              }}
            />
          </GoToDashBoardContainer>
        )}
        <Flexrow center_align space_between style={{ width: "100%" }}>
          {sticky && (
            <HeaderWrap show={stickyHeader}>Create new data request</HeaderWrap>
          )}
          <Flexrow grow />
          <div style={{ display: "flex" }}>
            {helper.previewing ? (
              <EditButton
                onClick={() => {
                  helper.togglePreview();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <span>
                  <EditOutlined /> Edit
                </span>
              </EditButton>
            ) : (
              <PreviewButton
                id="request-preview-btn"
                onClick={() => {
                  helper.togglePreview();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disabled={button_disabled}
              >
                <span>
                  <EyeOutlined /> Preview
                </span>
              </PreviewButton>
            )}
            <Flexrow left="8px" />
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title={misses_tooltip}
            >
              <SubmitButton
                id="request-submit-btn"
                onClick={confirmChangesSubmit}
                disabled={button_disabled || !!misses_tooltip}
              >
                Submit
              </SubmitButton>
            </Tooltip>
          </div>
        </Flexrow>
      </ButtonsContainer>
      <style jsx global>
        {`
          #start-walkthrough {
            display: ${helper.previewing && "none"};
          }
        `}
      </style>
    </>
  );
};

export default HeaderWizard;
