import Header from "../components/Header"
import styled from "styled-components"
import { Typography } from 'antd'
import { Flexrow } from "../components/Flexbox"
import { colors } from "../values/colors"

const { Text, Title } = Typography

const HeaderWrap = styled.div`
    display: flex;
    background-color: white;
    justify-content: center;
    > div {
        flex: 1;
        max-width: 83.3%;
    }
`

const PageBody = styled(Flexrow)`
    flex: 1;
    background-color: ${colors.grey_1};
    padding-top: 32px;
`



export const NotFoundPage = () => {

  return (
    <>
      <HeaderWrap>
        <div>
          <Header withNav />
        </div>
      </HeaderWrap>
      <PageBody center_content>
        <Title level={3}>Page not found</Title>
      </PageBody>
    </>
  )
}