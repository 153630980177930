import React from 'react';

import TourStep from './TourStep';

const DASHBOARD_STEPS = [
  {
    target: '.dashboard-step',
    content: (
      <TourStep
        step={1}
        title="Your dashboard"
        mainText="Here you can view, edit or create your data products, consumptions
        and requests."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '.ant-tabs-nav-list > div',
    content: (
      <TourStep
        step={2}
        title="Your data products"
        mainText="Here you can view, edit or create your data products."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '.ant-tabs-nav-list > div + div',
    content: (
      <TourStep
        step={3}
        title="Your data consumptions"
        mainText="Here you can manage all your active data consumptions."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '.ant-tabs-nav-list > div + div + div',
    content: (
      <TourStep
        step={4}
        title="Your data requests"
        mainText="Here you can view edit or create your data requests in case you cannot find the data that you are looking for already listed in the marketplace."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '.my-profile-step',
    content: (
      <TourStep
        step={5}
        title="Your profile"
        mainText="Here you can find and edit your personal and company profile settings."
        length={5}
      />
    ),
    disableBeacon: true,
  },
];

const MY_SETTINGS_STEPS = [
  {
    target: '#my-personal-settings',
    content: (
      <TourStep
        step={1}
        title="My personal settings"
        mainText="Here you can edit your personal information."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#my-password',
    content: (
      <TourStep
        step={2}
        title="My password"
        mainText="Here you can change your personal account password."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#my-email',
    content: (
      <TourStep
        step={3}
        title="My email"
        mainText="Here you can change your personal account email."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#company-profile',
    content: (
      <TourStep
        step={4}
        title="Company Profile"
        mainText="Here you can update the company details and add members to your company account."
        length={4}
      />
    ),
    disableBeacon: true,
  },
];

const DATA_REQUEST_STEPS = [
  {
    target: '#request-overview-tab',
    content: (
      <TourStep
        step={1}
        title="Overview"
        mainText="Get an overview of the main specs of this data request."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#request-integration-details-tab',
    content: (
      <TourStep
        step={2}
        title="Integration details"
        mainText="See the details of how the consumer prefers to access the data."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#request-pricing-tab',
    content: (
      <TourStep
        step={3}
        title="Pricing"
        mainText="See the consumer’s available budget and preferred transaction type."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#request-data-availability',
    content: (
      <TourStep
        step={4}
        title="Declare Data Availability"
        mainText="Select to declare that you are interested to offer data that are matching this data request."
        length={5}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#share-listing',
    content: (
      <TourStep
        step={5}
        title="Share Listing"
        mainText="Select in case you want to copy the link of this data request or share it via email with your network."
        length={5}
      />
    ),
    disableBeacon: true,
  },
];

const DATA_PRODUCT_STEPS = [
  {
    target: '#product-overview-tab',
    content: (
      <TourStep
        step={1}
        title="Overview"
        mainText="Get an overview of the main data specs that are offered with this data product."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-integration-details-tab',
    content: (
      <TourStep
        step={2}
        title="Integration details"
        mainText="See the details of how you will access the data."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-docs-tab',
    content: (
      <TourStep
        step={3}
        title="Sample files & documentation"
        mainText="Download sample files and access the technical documentation."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-pricing-tab',
    content: (
      <TourStep
        step={4}
        title="Pricing"
        mainText="See the pricing details set by the data provider."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-request-access',
    content: (
      <TourStep
        step={5}
        title="Request Access"
        mainText="If you are interested in this data product, select to send an access request to the data provider."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#share-listing',
    content: (
      <TourStep
        step={6}
        title="Share Listing"
        mainText="Select in case you want to copy the link of this data product or share it via email with your network."
        length={6}
      />
    ),
    disableBeacon: true,
  },
];

const DATA_PRODUCT_LIGHT_STEPS = [
  {
    target: '#product-overview-tab',
    content: (
      <TourStep
        step={1}
        title="Overview"
        mainText="Get an overview of the main data specs that are offered with this data product."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-integration-details-tab',
    content: (
      <TourStep
        step={2}
        title="Integration details"
        mainText="See the details of how you will access the data."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-docs-tab',
    content: (
      <TourStep
        step={3}
        title="Sample files & documentation"
        mainText="Download sample files and access the technical documentation."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-pricing-tab',
    content: (
      <TourStep
        step={4}
        title="Pricing"
        mainText="See the pricing details set by the data provider."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-declare-interest',
    content: (
      <TourStep
        step={5}
        title="Declare Interest"
        mainText="Declare your interest in this data product and a Mobito expert will contact you as soon as possible to work on your request."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#share-listing',
    content: (
      <TourStep
        step={6}
        title="Share Listing"
        mainText="Select in case you want to copy the link of this data product or share it via email with your network."
        length={6}
      />
    ),
    disableBeacon: true,
  },
];

const HOMEPAGE_STEPS = [
  {
    target: '#homepage-filter',
    content: (
      <TourStep
        step={1}
        title="Filter listings"
        mainText="Here you can filter the listings that you want to see based on type, geographical coverage and data category."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '.ant-input-wrapper.ant-input-group',
    content: (
      <TourStep
        step={2}
        title="Search listings"
        mainText="Here you can search for specific listings."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#homepage-listing > div',
    content: (
      <TourStep
        step={3}
        title="Listing"
        mainText="Select a listing in order to see more details."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#homepage-fill-data-request',
    content: (
      <TourStep
        step={4}
        title="Request Data"
        mainText="Create a Data Request in case you cannot find the data that you are looking for."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#homepage-fill-data-supply',
    content: (
      <TourStep
        step={5}
        title="Provide Data"
        mainText="List your data in Mobito’s Marketplace by creating a Data Product."
        length={6}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#my-settings',
    content: (
      <TourStep
        step={6}
        title="My Dashboard"
        mainText="Go to your dashboard to manage all your data products, consumptions and data requests."
        length={6}
      />
    ),
    disableBeacon: true,
  },
];

const PUBLISHED_DATA_REQUEST_STEPS = [
  {
    target: '#published-edit-btn',
    content: (
      <TourStep
        step={1}
        title="Edit section"
        mainText="Select the section that you want to edit, make your changes and save them."
        length={2}
      />
    ),
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '#request-preview-and-submit',
    content: (
      <TourStep
        step={2}
        title="Preview and Submit"
        mainText="Here you can preview and submit your changes."
        length={2}
      />
    ),
    disableBeacon: true,
  },
];

const PUBLISHED_DATA_PRODUCT_STEPS = [
  {
    target: '#published-product-settings',
    content: (
      <TourStep
        step={1}
        title="Settings"
        mainText="Here you can edit your data product."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#published-edit-btn',
    content: (
      <TourStep
        step={2}
        title="Edit section"
        mainText="Select the section that you want to edit, make your changes and save them."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-preview-and-submit',
    content: (
      <TourStep
        step={3}
        title="Preview and Submit"
        mainText="Here you can preview and submit your changes."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#published-product-access-control',
    content: (
      <TourStep
        step={4}
        title="Access Control"
        mainText="Here you can manage all the access requests that you receive from consumers."
        length={4}
      />
    ),
    disableBeacon: true,
  },
];

const CREATE_DATA_REQUEST_STEPS = [
  {
    target: '.create-tooltip',
    content: (
      <TourStep
        step={1}
        title="Get help"
        mainText="Hover over this icon on each section to help you fill in the necessary information."
        length={3}
      />
    ),
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '#request-preview-btn',
    content: (
      <TourStep
        step={2}
        title="Preview"
        mainText="Select to get a preview on how your data request that you create will look like in the Marketplace."
        length={3}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#request-submit-btn',
    content: (
      <TourStep
        step={3}
        title="Submit"
        mainText="When you finish, submit your data request to Mobito."
        length={3}
      />
    ),
    disableBeacon: true,
  },
];

const CREATE_DATA_PRODUCT_STEPS = [
  {
    target: '#product-steps',
    content: (
      <TourStep
        step={1}
        title="Steps"
        mainText="Here are the steps that you need to follow in order to create your data product. Fill-in until step 3 for a fully integrated data product or until step 2 for a lighter version with only a description of your data offering."
        length={4}
      />
    ),
    disableBeacon: true,
    placement: 'right-start',
  },
  {
    target: '.create-tooltip',
    content: (
      <TourStep
        step={2}
        title="Get help"
        mainText="Hover over this icon on each section to help you fill in the necessary information."
        length={4}
      />
    ),
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '#product-preview-btn',
    content: (
      <TourStep
        step={3}
        title="Preview"
        mainText="Select to get a preview on how your data product that you create will look like in the Marketplace."
        length={4}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#product-submit-btn',
    content: (
      <TourStep
        step={4}
        title="Submit"
        mainText="When you finish, submit your data product for publishing in the Marketplace."
        length={4}
      />
    ),
    disableBeacon: true,
  },
];

const COMPANY_SETTINGS_STEPS = [
  {
    target: '#company-settings-account',
    content: (
      <TourStep
        step={1}
        title="Account details"
        mainText="Here you can update the details of your company."
        length={2}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#company-settings-people',
    content: (
      <TourStep
        step={2}
        title="People"
        mainText="Here you can add, remove and update the access rights of members that have access to your company account."
        length={2}
      />
    ),
    disableBeacon: true,
  },
];

const COMPANY_SETTINGS_PEOPLE_STEPS = [
  {
    target: '#people-all-members',
    content: (
      <TourStep
        step={1}
        title="Filter members"
        mainText="Filter the members based on the status of their account."
        length={3}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#people-add-member',
    content: (
      <TourStep
        step={2}
        title="Add new member"
        mainText="Send invitation to one or more members to join your company account and select their access rights."
        length={3}
      />
    ),
    disableBeacon: true,
  },
  {
    target: '#people-dropdown',
    content: (
      <TourStep
        step={3}
        title="Manage members"
        mainText="Manage the access of each member ."
        length={3}
      />
    ),
    disableBeacon: true,
  },
];

const walkthroughSteps = (props) => {
  let steps = [];

  switch (props) {
    case 'DASHBOARD':
      return (steps = DASHBOARD_STEPS);
    case 'MY_SETTINGS':
      return (steps = MY_SETTINGS_STEPS);
    case 'DATA_REQUEST':
      return (steps = DATA_REQUEST_STEPS);
    case 'DATA_PRODUCT':
      return (steps = DATA_PRODUCT_STEPS);
    case 'DATA_PRODUCT_LIGHT':
      return (steps = DATA_PRODUCT_LIGHT_STEPS);
    case 'HOMEPAGE':
      return (steps = HOMEPAGE_STEPS);
    case 'PUBLISHED_DATA_REQUEST':
      return (steps = PUBLISHED_DATA_REQUEST_STEPS);
    case 'PUBLISHED_DATA_PRODUCT':
      return (steps = PUBLISHED_DATA_PRODUCT_STEPS);
    case 'CREATE_DATA_REQUEST':
      return (steps = CREATE_DATA_REQUEST_STEPS);
    case 'CREATE_DATA_PRODUCT':
      return (steps = CREATE_DATA_PRODUCT_STEPS);
    case 'COMPANY_SETTINGS_STEPS':
      return (steps = COMPANY_SETTINGS_STEPS);
    case 'COMPANY_SETTINGS_PEOPLE_STEPS':
      return (steps = COMPANY_SETTINGS_PEOPLE_STEPS);
    default:
      return steps;
  }
};

export default walkthroughSteps;
