import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Input, Button, Form, Modal, Divider } from 'antd';
import Cookies from 'js-cookie';
import { LockFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';

import { UndoOutlined } from '@ant-design/icons';

import Notification from '../components/Notification';
import { Flexcol, Flexrow } from '../components/Flexbox';
import SubsectionTitle from '../components/wizards-components/SubsectionTitle';
import instance from '../services/TokenValidator';

const { Text } = Typography;

const Wrap = styled(Form)`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 32px;
  height: fit-content;
  width: 65%;
  margin-top: 26px;
`;

const ConfirmationButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 210px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const email_regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const required_rule = { required: true, message: 'Required field' };

const emailValidator = async (_, value) => {
  if (email_regex.test(value)) {
    return;
  } else {
    throw new Error('Please enter a valid email');
  }
};

const confirmRule = ({ getFieldValue }) => ({
  async validator(_, value) {
    if (getFieldValue('email1') != value) {
      throw new Error("Confirmation doesn't match new email");
    }
  },
});

export const EditEmail = (p) => {
  const { user, setIsUpdated, setCanSave, canSave, reset } = p;
  const [saving, setSaving] = useState(false);
  const [, updator] = useState({});
  const [form] = Form.useForm();
  const token = Cookies.get('jwt');
  const { email1, email2 } = form.getFieldsValue();

  const can_save =
    email1 &&
    email_regex.test(email1) &&
    email1 != user.user.email &&
    email2 == email1;

  useEffect(() => {
    setIsUpdated((prevState) => ({ ...prevState, email: can_save }));
  }, [can_save]);

  const notCurrentValidator = async (_, value) => {
    if (value == user.user.email) {
      throw new Error('Cannot be your current email');
    }
  };

  const onSave = async () => {
    setSaving(true);

    const deploymentDomain =
      process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

    try {
      const res = await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/auth/email`,
        {
          email: email1,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res.status === 200) {
        const response = await instance.post(
          `${process.env.RAZZLE_ACCOUNT_URL}/auth/refresh`,
          {
            refreshToken: Cookies.get('refreshToken'),
          },
        );
        Cookies.set('jwt', response.data.jwt, {
          domain: deploymentDomain,
        });
      }

      Modal.info({
        title: (
          <Text
            style={{ color: '#182A33', fontSize: '19px', fontWeight: '600' }}
          >
            Confirmation email sent
          </Text>
        ),
        icon: null,
        width: 460,
        content: (
          <Text style={{ color: '#64737F', fontSize: '15px' }}>
            We have sent an email to your account in order to confirm the change
            of your email
          </Text>
        ),
      });
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Could not process your request, please try again later"
        />,
      );
    }
    setSaving(false);
    setIsUpdated((prevState) => ({ ...prevState, email: false }));
    setCanSave((prevState) => ({ ...prevState, email: false }));
  };

  useEffect(() => {
    if (canSave.email) {
      onSave();
    }
  }, [canSave.email]);

  useEffect(() => {
    if (reset.email) {
      form.resetFields();
      updator({ changed: false });
    }
  }, [reset.email]);

  return (
    <>
      <Flexcol style={{ width: '65%' }} center_align>
        <Flexcol style={{ width: '100%' }} top="72px">
          <Text style={{ color: '#182429', fontSize: '28px' }}>
            Change email
          </Text>
          <Text style={{ color: '#64737F', fontSize: '15px' }}>
            Here you can change your email.
          </Text>
          <Divider />
        </Flexcol>
        <Wrap onFieldsChange={() => updator({})} form={form}>
          <SubsectionTitle tooltip="This is the current work email of your account">
            Current work email
          </SubsectionTitle>
          <Input
            suffix={<LockFilled />}
            style={{ marginBottom: 20 }}
            value={user.user.email}
            disabled
            size="large"
          />
          <SubsectionTitle tooltip="Enter the new work email of your account">
            New work email
          </SubsectionTitle>
          <Form.Item
            validateFirst
            name="email1"
            rules={[
              required_rule,
              { validator: emailValidator },
              { validator: notCurrentValidator },
            ]}
          >
            <Input disabled={saving} size="large" />
          </Form.Item>
          <SubsectionTitle tooltip="Enter again the new work email of your account">
            Confirm new work email
          </SubsectionTitle>
          <Form.Item
            dependencies={['email1']}
            validateFirst
            name="email2"
            rules={[required_rule, confirmRule]}
          >
            <Input disabled={saving} size="large" />
          </Form.Item>
          <Flexrow
            id="functional-buttons"
            center_align
            space_between
            top="26px"
          >
            {can_save ? (
              <Text
                id="reset-changes"
                style={{
                  color: '#344EF3',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  form.resetFields();
                  updator({ changed: false });
                }}
              >
                <UndoOutlined />
                &nbsp; RESET CHANGES
              </Text>
            ) : (
              <Flexrow grow />
            )}
            <ConfirmationButton
              id="save-changes"
              disabled={!can_save}
              onClick={onSave}
              loading={saving}
            >
              Send Confirmation Email
            </ConfirmationButton>
          </Flexrow>
        </Wrap>
        <div style={{ padding: '42px' }} />
      </Flexcol>
      <style>{`.ant-btn.ant-btn-primary { border-radius: 8px; border: 1px solid #344ef3; background-color: #344ef3; font-size: 15px; padding: 10px 29px; height: initial;}`}</style>
    </>
  );
};
