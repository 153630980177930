import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import axios from "axios";
import { ShapeIntersect } from "@styled-icons/fluentui-system-regular/ShapeIntersect";

import { Flexcol } from "../../../../components/Flexbox";
import ItemCard from "../../../../components/listing-components/ItemCard";
import { HomeProductListItem } from "../../../../components/HomeListItem";
import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";
import useCategories from "../../../../hooks/useCategories";

const SeeAllButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  background: #fff !important;
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
`;

const MoreTab = () => {
  const helper = useContext(DataProductContext);
  const [dataProducts, setDataProducts] = useState([]);
  const [productsLength, setProductsLength] = useState(0);
  const { categories } = useCategories();
  const history = useHistory();

  const category = categories.find((e) => e.id === helper.category.value);

  useEffect(() => {
    const fetchDataProducts = async () => {
      if (dataProducts.length === 0) {
        const res = await axios.get("/api/metadata-search");
        setProductsLength(
          res.data.filter(
            (e) => e.category_name === category.name && e.id !== helper.id
          )
        );
        const listings = res.data
          .filter(
            (e) => e.category_name === category.name && e.id !== helper.id
          )
          .slice(0, 3);
        setDataProducts(listings);
      }
    };
    fetchDataProducts();
  }, []);

  return (
    <Flexcol>
      <ItemCard
        title="Similar listings in this category"
        icon={<ShapeIntersect />}
      >
        {dataProducts?.map((module) => (
          <a
            style={{ paddingBottom: "20px" }}
            href={`/data-product/${module.url_id}`}
            target="_blank"
            key={module.id}
          >
            <HomeProductListItem module={module} noLink />
          </a>
        ))}
        {productsLength && productsLength.length > 3 && (
          <SeeAllButton
            onClick={() =>
              history.push({
                pathname: `/`,
                state: { filter: category && category.name },
              })
            }
          >
            See all from this category
          </SeeAllButton>
        )}
      </ItemCard>
      {/* <ItemCard
        title="Other listings from this provider"
        icon={<ShapeIntersect style={{ transform: "rotate(90deg)" }} />}
      ></ItemCard> */}
    </Flexcol>
  );
};

export default MoreTab;
