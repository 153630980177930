import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Typography, Button, Tooltip, Modal, Tabs, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Col, Row } from 'react-styled-flexboxgrid';
import { toast } from 'react-toastify';
import { BookmarkFill } from '@styled-icons/bootstrap/BookmarkFill';
import { Helmet } from 'react-helmet';
import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';
import { QuestionCircleFill } from '@styled-icons/bootstrap/QuestionCircleFill';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';

import { Flexcol, Flexrow } from '../../components/Flexbox';
import { PricingTab } from './tabs/PricingTab/index';
import OverviewTab from './tabs/OverviewTab/index';
import MoreTab from './tabs/MoreTab/index';
import SampleFilesAndDocumentation from './tabs/SampleFilesAndDocumentation/index';
import { DetailsTab } from './tabs/DetailsTab';
import listing_wall_img from '../../images/listing-wall.webp';
import { getThumbnailURL } from '../../iso-shared/utils/getThumbnailUrl';
import { AccountContext } from '../../hooks/AccountContext';
import { DataProductContext } from '../../helpers/DataProductWizardHelper';
import useCategories from '../../hooks/useCategories';
import { strings } from './values/strings';
import { DeclareInterestModal } from './DeclareInterestModal';
import { LoginRedirector } from '../../services/LoginRedirector';
import Notification from '../../components/Notification';
import ShareListing from '../../components/listing-components/ShareListing';
import StructuredData from './values/productStructuredData';
import { AppContext } from '../../hooks/AppContextProvider';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const OfferTypeWrap = styled(Flexrow)`
  border: 1px white solid;
  border-radius: 16px;
  padding: 2px 12px;
`;

const TabContent = styled(Flexcol)`
  width: 950px;
  padding-top: 40px;
`;

const BookmarkIcon = styled(BookmarkFill)`
  color: white;
  height: 20px;
`;

const Wall = styled(Flexcol)`
  position: relative;
  height: 312px;
  min-height: 312px;
  background-image: url(${listing_wall_img});
  background-size: cover;
`;

const WallRow = styled(Flexrow)`
  margin-top: 30px;
`;

const ImageWrap = styled.div`
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.06);
  height: 126px;
  width: 123px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 18px;
`;

const Thumbnail = styled.img`
  height: 100%;
`;

const AccessBox = styled(Flexcol)`
  background: white;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.06);
  padding: 20px;
`;

const TabsWrap = styled(Flexrow)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
`;

const TabsRow = styled(Flexrow)`
  width: 950px;
  justify-content: space-between;
`;

const TabItem = styled(Flexrow)`
  flex: 1;
  font-weight: bold;
  min-width: max-content;
  padding: 16px 32px;
  border-radius: 4px 4px 0px 0px;
  color: white;
  font-size: 15px;
  justify-content: center;
  transition: background-color 75ms, color 75ms;
  cursor: pointer;
  background-color: transparent;
  ${(p) =>
    p.selected &&
    css`
      color: rgb(0, 85, 153);
      background-color: #f9faff;
    `}
`;

const Content = styled(Flexrow)`
  flex: 1;
  justify-content: center;
  background-color: #f9faff;
`;

const CancelButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background: #344ef3 !important;
    border: 1px solid #344ef3 !important;
    color: #fff !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    background-color: #fff !important;
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    cursor: not-allowed;
  }
`;

const tab_views = {
  overview: OverviewTab,
  pricing: PricingTab,
  details: DetailsTab,
  docs: SampleFilesAndDocumentation,
  more: MoreTab,
};

export const ListingContent = (props) => {
  const { modal, project, setMdlTab, setReload, reload } = props;
  const helper = useContext(DataProductContext);
  const context = useContext(AppContext);
  const show_light = !helper.previewing && helper.published_step == 2;
  const token = Cookies.get('jwt') || '';
  const { categories } = useCategories();
  const interest_message = useMemo(() => ({ message: '' }));

  const category = categories.find((e) => e.id === helper.category.value);

  const [tab, setTab] = useState('overview');

  const [declared_interest, setDeclaredInterest] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [modalTab, setModalTab] = useState('1');

  const [isSelected, setIsSelected] = useState(false);

  const [consumptionName, setConsumptionName] = useState('');

  const [accessRequired, setAccessRequired] = useState(false);

  const { account, isComplete } = useContext(AccountContext) || {};

  const is_logged_in = account || null;

  const account_set = account && isComplete.personal && isComplete.company;

  const TabView = tab_views[tab] || (() => null);

  const [hasContent, setHasContent] = useState({});

  const [dataProducts, setDataProducts] = useState([]);

  const history = useHistory();

  const is_s3_data = helper.placement_type === 's3_data';

  useEffect(() => {
    if (!is_logged_in || (!show_light && !is_s3_data)) {
      return;
    }
    const fetch = async () => {
      const res = await axios.get(
        `/api/get-interest?placement_id=${helper.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setDeclaredInterest(res.data);
    };
    fetch();
  }, [is_logged_in, reload]);

  const onDeclareInterest = async () => {
    const body = {
      placement_id: helper.id,
      message: interest_message.message,
    };
    if (!is_logged_in) {
      history.push(`/data-consumption-wizard?selected_listing=${helper.id}`);
    } else {
      try {
        await axios.post(
          `/api/${
            is_s3_data
              ? `access-requests/project/${props.dataProductId}`
              : 'create-interest'
          }`,
          body,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        toast.success(<Notification type="success" text="Request sent" />);
        setDeclaredInterest(true);
        setReload(!reload);
      } catch (error) {
        console.error(error);
        toast.error(
          <Notification type="error" text={error.response.data.message} />,
        );
      }
    }
  };

  const goToProfileHandler = () => {
    if (!isComplete.personal) {
      history.push('/account');
    } else if (!isComplete.company) {
      history.push({ pathname: '/account', state: { key: 'company' } });
    }
  };

  const onDeclareInterestModal = () => {
    if (is_logged_in) {
      if (!account_set) {
        goToProfileHandler();
      } else {
        interest_message.message = '';
        Modal.confirm({
          title: 'Declare interest',
          icon: null,
          content: (
            <DeclareInterestModal
              onMessage={(v) => (interest_message.message = v)}
            />
          ),
          okText: 'Send',
          cancelText: 'Cancel',
          width: 800,
          closable: true,
          centered: true,
          bodyStyle: { height: '400px' },
          onOk: onDeclareInterest,
        });
      }
    } else {
      LoginRedirector.toLogin(history);
    }
  };

  useEffect(() => {
    const initial = document.documentElement.style.overflowY;
    document.documentElement.style.overflowY = 'scroll';
    return () => {
      document.documentElement.style.overflowY = initial;
    };
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onAccessCard = () => {
    if (is_logged_in) {
      if (!account_set) {
        goToProfileHandler();
      } else {
        setIsModalVisible(true);
      }
    } else {
      LoginRedirector.toLogin(history);
    }
  };

  const onAccessRequest = async () => {
    if (consumptionName === '') {
      toast.error(
        <Notification
          type="error"
          text="Consumption project name is required"
        />,
      );
      return;
    }

    if (props.dataProductType === 'S3 Bucket') {
      try {
        await axios.post(
          `/api/access-requests/project/${props.dataProductId}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        toast.success(
          <Notification
            type="success"
            text={
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#fff',
                }}
              >
                Your request has been sent and added to your{' '}
                <Text
                  onClick={() =>
                    history.push({
                      pathname: `/dashboard`,
                      state: { key: '2' },
                    })
                  }
                  style={{
                    textDecoration: 'underline',
                    color: '#344ef3',
                    cursor: 'pointer',
                  }}
                >
                  dashboard.
                </Text>
              </Text>
            }
          />,
          {
            autoClose: 12000,
            closeOnClick: true,
            closeButton: true,
          },
        );
        setIsModalVisible(false);
      } catch (error) {
        console.error(error);
        toast.error(
          <Notification
            type="error"
            text="Could not create consumption project"
          />,
        );
      }
    } else {
      try {
        await axios.post(
          `/api/data-consumptions`,
          {
            category: category && category.name,
            listing_ids: [helper.id],
            listings: [],
            name: consumptionName,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        toast.success(
          <Notification
            type="success"
            text={
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#fff',
                }}
              >
                Your request has been sent and added to your{' '}
                <Text
                  onClick={() =>
                    history.push({
                      pathname: `/dashboard`,
                      state: { key: '2' },
                    })
                  }
                  style={{
                    textDecoration: 'underline',
                    color: '#344ef3',
                    cursor: 'pointer',
                  }}
                >
                  dashboard.
                </Text>
              </Text>
            }
          />,
          {
            autoClose: 12000,
            closeOnClick: true,
            closeButton: true,
          },
        );
        setIsModalVisible(false);
        setAccessRequired(true);
        context.setDashboardProps({
          ...context.dashboard_props,
          accessRequired: true,
        });
        localStorage.setItem('new-consumption', 'new');
      } catch (error) {
        console.error(error);
        toast.error(
          <Notification
            type="error"
            text="Could not create consumption project"
          />,
        );
      }
    }
  };

  const imageUrl = getThumbnailURL(helper.thumbnail.value.url);

  const getTabProps = (name) => ({
    selected: name == tab,
    onClick: () => setTab(name),
  });

  let displayed_version = null;
  if (!helper.previewing && helper.published_step) {
    displayed_version = helper.published_step == 2 && (
      <Tooltip
        color="#1a337d"
        overlayInnerStyle={{
          boxShadow: '2px 4px 12px 0px #182a331f',
          fontSize: '13px',
          borderRadius: '4px',
          width: '300px',
        }}
        placement="bottom"
        title={strings.light_version_tooltip}
      >
        Light version
      </Tooltip>
    );
  }

  const removeHTMLTags = (str) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const addListing = () => {
    context.setDashboardProps({
      ...context.dashboard_props,
      selectedListings: {
        length: context.dashboard_props.selectedListings?.length + 1,
        listings: context.dashboard_props.selectedListings?.listings.concat(
          project.placement,
        ),
      },
      selected: {
        module: project.placement,
        isSelected: true,
      },
    });
    setIsSelected(true);
    setMdlTab('1');
  };

  const removeListing = () => {
    context.setDashboardProps({
      ...context.dashboard_props,
      selectedListings: {
        length: context.dashboard_props.selectedListings?.length - 1,
        listings: context.dashboard_props.selectedListings?.listings.filter(
          (item) => item.id !== project.placement.id,
        ),
      },
      selected: {
        module: project.placement,
        isSelected: false,
      },
    });
    setIsSelected(false);
  };

  useEffect(() => {
    let list = context.dashboard_props?.selectedListings?.listings.filter(
      (item) => item.id === project.placement.id,
    );

    if (list?.length === 1) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [project]);

  useEffect(() => {
    if (context?.dashboard_props?.accessRequired) {
      setAccessRequired(context.dashboard_props?.accessRequired);
    } else {
      setAccessRequired(false);
    }
  }, [context?.dashboard_props?.accessRequired]);

  const projectLicense = helper.license_type.value;
  const projectSampleFiles = helper.delivery_specs_sample_files.value;

  const docsHasContent =
    (!show_light && helper.placement_type == 'api') ||
    (!show_light && projectLicense) ||
    !!projectSampleFiles.length;

  useEffect(() => {
    const fetchDataProducts = async () => {
      if (dataProducts.length === 0) {
        const res = await axios.get('/api/metadata-search');
        const listings = res.data.filter(
          (e) => e.category_name === category?.name && e.id !== helper.id,
        );
        setDataProducts(listings);
      }
    };
    fetchDataProducts();
  }, []);

  useEffect(() => {
    setHasContent((prevState) => {
      return {
        ...prevState,
        docs: docsHasContent,
        more: !!dataProducts.length,
      };
    });
  }, [dataProducts]);

  return (
    <>
      {!modal && (
        <>
          <Helmet>
            <title>{helper.name.value}</title>
            <meta
              name="description"
              content={
                helper.general_description.value ||
                helper.project.placement.description
              }
            />
          </Helmet>
          <StructuredData
            productName={helper.name.value}
            productImage={imageUrl}
            productDescription={
              removeHTMLTags(helper.general_description.value) ||
              removeHTMLTags(helper.project.placement.description)
            }
            productUrl={process.env.RAZZLE_BASE_URL + history.location.pathname}
            productSpatialCoverage={helper.data_specs_geo_coverage.value}
          />
        </>
      )}
      <Modal
        title={
          <Text
            style={{
              color: '#182429',
              fontSize: '18px',
              fontWeight: '600',
              margin: '10px 0px',
              display: 'block',
            }}
          >
            {modalTab === '1' ? (
              'Before you proceed...'
            ) : (
              <Flexrow center_align>
                <ArrowBack
                  style={{
                    color: '#182429',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalTab('1')}
                />
                <Text style={{ marginLeft: '30px' }}>
                  What is a consumption project?
                </Text>
              </Flexrow>
            )}
          </Text>
        }
        visible={isModalVisible}
        onOk={onAccessRequest}
        onCancel={handleCancel}
        width={840}
        bodyStyle={{ height: '250px' }}
        footer={
          modalTab === '1'
            ? [
                <CancelButton key="back" onClick={handleCancel}>
                  Cancel
                </CancelButton>,
                <SubmitButton
                  key="submit"
                  onClick={onAccessRequest}
                  disabled={!consumptionName}
                >
                  OK, send request
                </SubmitButton>,
              ]
            : [
                <CancelButton key="back" onClick={() => setModalTab('1')}>
                  Back
                </CancelButton>,
              ]
        }
      >
        <Flexcol>
          <Tabs
            defaultActiveKey="1"
            activeKey={modalTab}
            tabBarStyle={{ display: 'none' }}
          >
            <TabPane key="1">
              <Text style={{ color: '#64737F', fontSize: '16px' }}>
                In order to request access, you need first to create a
                consumption project. You can manage your project later on at
                your dashboard.
              </Text>
              <Flexcol top="15px" />
              <Flexrow center_align>
                <QuestionCircleFill
                  style={{
                    color: '#344EF3',
                    height: '16px',
                  }}
                />
                <Text
                  style={{
                    marginLeft: '8px',
                    color: '#344EF3',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalTab('2')}
                >
                  What is a consumption project?
                </Text>
              </Flexrow>
              <Flexcol top="45px" />
              <Flexrow center_align>
                <Tooltip
                  title="Give a short (internal) name to your project. The name will not be shared with anyone outside your organisation."
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                >
                  <InfoCircle
                    style={{
                      color: '#182429',
                      height: '16px',
                    }}
                  />
                </Tooltip>
                <Text
                  style={{
                    marginLeft: '8px',
                    color: '#182429',
                    fontSize: '15px',
                  }}
                >
                  Consumption project name
                </Text>
              </Flexrow>
              <Flexcol top="10px" />
              <Input
                onChange={(e) => setConsumptionName(e.target.value)}
                value={consumptionName}
                placeholder="e.g. Engineering team data consumptions for vehicle trip simulations"
              />
              <Flexcol top="25px" />
            </TabPane>
            <TabPane key="2">
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '16px',
                  width: '85%',
                  display: 'block',
                  marginBottom: '25px',
                }}
              >
                Creating a data consumption project allows you to group together
                the consumption of various data products (e.g. per use case or
                per internal department).
              </Text>
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '16px',
                  width: '85%',
                  display: 'block',
                }}
              >
                This will allow you to manage your consumptions in an easier
                way.
              </Text>
            </TabPane>
          </Tabs>
        </Flexcol>
      </Modal>
      <Wall>
        <Row center="xs" style={{ marginLeft: 'unset', marginRight: 'unset' }}>
          <Col xs={10}>
            <WallRow>
              <ImageWrap>
                <Thumbnail src={imageUrl} />
              </ImageWrap>
              <Flexcol space_around left="30px" style={{ height: '126px' }}>
                <Flexrow center_align>
                  <Text style={{ color: 'white' }}>DATA PRODUCT</Text>
                  <Flexrow left="12px" />
                  {displayed_version && (
                    <OfferTypeWrap>
                      <Text style={{ color: 'white' }}>
                        {displayed_version}
                      </Text>
                    </OfferTypeWrap>
                  )}
                </Flexrow>
                <Title style={{ color: 'white', marginTop: 8 }} level={2}>
                  {helper.name.value}
                </Title>
                <Flexrow center_align>
                  <BookmarkIcon size={14} />
                  <Title
                    style={{
                      color: 'white',
                      marginBottom: 'unset',
                      marginLeft: 8,
                    }}
                    level={5}
                  >
                    {category && category.name}
                  </Title>
                </Flexrow>
              </Flexcol>
              <Flexrow grow />
              {!helper.previewing && !show_light && !is_s3_data && !modal && (
                <AccessBox>
                  {!accessRequired ? (
                    <>
                      <Text
                        style={{ textAlign: 'center', fontSize: '12px' }}
                        type="secondary"
                      >
                        If you are interested in this data product,
                        <br /> request access from the provider
                      </Text>
                      <Flexrow top="8px" />
                      <Button
                        onClick={onAccessCard}
                        style={{
                          backgroundColor: '#ff7f19',
                          color: 'white',
                          fontSize: '15px',
                        }}
                        size="large"
                        id="product-request-access"
                      >
                        Request Access
                      </Button>
                    </>
                  ) : (
                    <Text
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#182429',
                      }}
                    >
                      Your request has been sent and
                      <br /> added to your{' '}
                      <Text
                        onClick={() =>
                          history.push({
                            pathname: `/dashboard`,
                            state: { key: '2' },
                          })
                        }
                        style={{
                          textDecoration: 'underline',
                          color: '#344ef3',
                          cursor: 'pointer',
                        }}
                      >
                        dashboard.
                      </Text>
                      <Text
                        style={{
                          fontSize: '23px',
                          fontWeight: 'bold',
                          color: '#7E7E7E',
                          display: 'block',
                          textAlign: 'center',
                          marginTop: '24px',
                        }}
                      >
                        Request sent
                      </Text>
                    </Text>
                  )}
                  <ShareListing listingName={helper.name.value} />
                </AccessBox>
              )}
              {((!helper.previewing &&
                is_s3_data &&
                !declared_interest &&
                !modal) ||
                (show_light && !declared_interest)) && (
                <AccessBox>
                  <Text
                    style={{ textAlign: 'center', fontSize: '12px' }}
                    type="secondary"
                  >
                    Are you interested in this data product?
                  </Text>
                  <Flexrow top="8px" />
                  <Button
                    onClick={onDeclareInterestModal}
                    style={{
                      backgroundColor: '#ff7f19',
                      color: 'white',
                      fontSize: '15px',
                    }}
                    size="large"
                    id="product-declare-interest"
                  >
                    Declare interest
                  </Button>
                  <ShareListing listingName={helper.name.value} />
                </AccessBox>
              )}
              {declared_interest && !modal && (
                <AccessBox center_content>
                  <Text style={{ textAlign: 'center', fontSize: '14px' }}>
                    You have declared interest <br /> for this listing
                  </Text>
                  <ShareListing listingName={helper.name.value} />
                </AccessBox>
              )}
              {modal && (
                <AccessBox center_content>
                  {!isSelected ? (
                    <Button
                      onClick={addListing}
                      style={{
                        backgroundColor: '#344EF3',
                        color: '#fff',
                        width: '190px',
                        fontSize: '15px',
                      }}
                      size="large"
                    >
                      Select this listing
                    </Button>
                  ) : (
                    <Button
                      onClick={removeListing}
                      style={{
                        backgroundColor: '#fff',
                        color: '#344EF3',
                        width: '190px',
                        borderRadius: '8px',
                        border: '1px solid #344ef3',
                        fontSize: '15px',
                      }}
                      size="large"
                    >
                      Deselect this listing
                    </Button>
                  )}
                </AccessBox>
              )}
            </WallRow>
          </Col>
        </Row>
        <TabsWrap>
          <TabsRow>
            {hasContent.overview && (
              <TabItem {...getTabProps('overview')} id="product-overview-tab">
                Overview
              </TabItem>
            )}
            <TabItem
              {...getTabProps('details')}
              id="product-integration-details-tab"
            >
              Integration details
            </TabItem>
            {hasContent.docs && (
              <TabItem {...getTabProps('docs')} id="product-docs-tab">
                Sample files & documentation
              </TabItem>
            )}
            <TabItem {...getTabProps('pricing')} id="product-pricing-tab">
              Pricing
            </TabItem>
            {hasContent.more && (
              <TabItem {...getTabProps('more')} id="product-more-tab">
                More ...
              </TabItem>
            )}
          </TabsRow>
        </TabsWrap>
      </Wall>
      <Content center_content>
        <TabContent>
          <TabView
            setHasContent={setHasContent}
            setTab={setTab}
            modal={modal}
            isS3={props.dataProductType === 'S3 Bucket'}
          />
        </TabContent>
      </Content>
    </>
  );
};
