import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";

import LayoutRow from "../../components/LayoutRow";
import Header from "../../components/Header";
import { KeyboardArrowLeft } from "styled-icons/material";
import { Flexrow } from "../../components/Flexbox";
import { useServerData } from "../../hooks/useServerData";
import {
  DataRequestContext,
  DataRequestWizardHelper,
} from "../../helpers/DataRequestWizardHelper";
import { ListingContent } from "./ListingContent";
import { toast } from "react-toastify";
import Notification from "../../components/Notification";
import { RequestStickyHeader } from "../../components/StickyHeader";

export const RequestListing = (p) => {
  const { id, url_id } = p.match.params;

  const [, updator] = useState({});
  const [stickyHeader, setStickyHeader] = useState(false);

  const server_project = useServerData("listing");
  const [project, setProject] = useState(server_project);

  useEffect(() => {
    const fetchListing = async () => {
      if (!project) {
        let res;
        if (id) {
          res = await axios.get(`/api/data-requests/editable/${id}/origin`);
        } else {
          res = await axios.get(
            `/api/data-requests/editable/origin-by-url/${url_id}`
          );
        }
        setProject(res.data);
      }
    };
    fetchListing();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setStickyHeader(window.pageYOffset > 420)
      );
    }
  }, []);

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const helper = new DataRequestWizardHelper(
      () => updator({}),
      project.id,
      null,
      project
    );
    helper.onError = (error) =>
      toast.error(<Notification type="error" text={error.message} />);
    helper.fromProject(project);

    return helper;
  }, [!!project]);

  return (
    <DataRequestContext.Provider value={helper}>
      <LayoutRow background={"#ffffff"} withBorder>
        <Header stepsFor="DATA_REQUEST" />
      </LayoutRow>
      <Flexrow center_align style={{ height: 50 }}>
        <Flexrow left="150px" />
        <Link to="/">
          <KeyboardArrowLeft size="32" style={{ color: "#a8afb3" }} />
        </Link>
      </Flexrow>
      {helper && (
        <RequestStickyHeader
          show={stickyHeader}
          name={project?.request.title}
        />
      )}
      {helper ? <ListingContent /> : <Spin size="large" />}
    </DataRequestContext.Provider>
  );
};
