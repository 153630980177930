import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { TurnedIn } from 'styled-icons/material';
import { Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import 'lazysizes';

import { colors } from '../values/colors';
import { listingType } from './ListingTypeLabel';
import { getThumbnailURL } from '../iso-shared/utils/getThumbnailUrl';
import { Flexrow } from '../components/Flexbox';
import { scrollStyles } from '../styles/scrollStyles';

const { Text } = Typography;

const Wrap = styled.div`
  padding: 20px 0 20px 24px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  ${scrollStyles}
  &:hover {
    border: 1px solid rgb(100, 115, 127);
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.24);
    transition: all 250ms;
  }
`;

const LightVersionLabel = styled.div`
  border-radius: 10.5px;
  font-size: 12px;
  border: 1px solid rgb(4, 148, 221);
  padding: 2px 8px;
  margin-left: 8px;
  color: #0494dd;
  white-space: nowrap;
`;

const getScreenWidthHandler = () => {
  let maxLength;
  if (screen.width <= 1440) {
    return (maxLength = 50);
  } else if (screen.width > 1440 && screen.width < 1640) {
    return (maxLength = 60);
  } else if (screen.width >= 1640 && screen.width < 1840) {
    return (maxLength = 70);
  } else if (screen.width >= 1840 && screen.width < 1940) {
    return (maxLength = 80);
  } else {
    return (maxLength = 1000);
  }
};

const nameTrimHandler = (name) => {
  let maxLength = getScreenWidthHandler();
  if (name?.length > maxLength) {
    return name.substring(0, maxLength) + '...';
  } else {
    return name;
  }
};

export const HomeProductListItem = (props) => {
  const { module, noLink } = props;
  const imageUrl = getThumbnailURL(module.image_url);

  let lightVersionLabel = module.published_step === 2 && 'Light version';

  return noLink ? (
    <Wrap>
      <Row
        middle="xs"
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <img
          style={{ marginRight: 8, padding: '10px' }}
          width="83"
          height="84"
          alt="mobility-module-image"
          data-src={imageUrl}
          className="lazyload"
        />
        <Col xs={8} style={{ paddingLeft: 16 }}>
          <Row style={{ paddingLeft: 4, width: 'max-content' }} middle="xs">
            <Text
              className="list-item-type"
              style={{
                fontSize: '11px',
                color: '#0494dd',
                fontWeight: '600',
              }}
            >
              DATA PRODUCT
            </Text>
            {lightVersionLabel && (
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title="This listing is a high-level description of the data product. Integration details are yet to be provided."
              >
                <LightVersionLabel>{lightVersionLabel}</LightVersionLabel>
              </Tooltip>
            )}
            {listingType(module.type_id)}
          </Row>
          <Flexrow top="12px" />
          <Row style={{ paddingLeft: 4 }}>
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              placement="bottom"
              title={module.name}
            >
              <Text
                className="list-item-title"
                style={{
                  color: '#182429',
                  fontSize: '16px',
                  letterSpacing: '-0.5px',
                }}
              >
                {nameTrimHandler(module.name)}
              </Text>
            </Tooltip>
          </Row>
          <Flexrow top="12px" />
          <Row middle="xs" style={{ width: 'max-content' }}>
            <TurnedIn
              size="16"
              style={{ color: colors.grey_4, marginRight: '2px' }}
            />
            <Text
              className="list-item-category"
              style={{
                color: '#64737f',
                fontSize: '13px',
                letterSpacing: '-0.5px',
              }}
            >
              {module.category_name}
            </Text>
          </Row>
        </Col>
      </Row>
    </Wrap>
  ) : (
    <Link to={`/data-product/${module.url_id}`}>
      <Wrap>
        <Row
          middle="xs"
          style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <img
            style={{ marginRight: 8, padding: '10px' }}
            width="83"
            height="84"
            alt="mobility-module-image"
            data-src={imageUrl}
            className="lazyload"
          />
          <Col xs={8} style={{ paddingLeft: 16 }}>
            <Row style={{ paddingLeft: 4, width: 'max-content' }} middle="xs">
              <Text
                className="list-item-type"
                style={{
                  fontSize: '11px',
                  color: '#0494dd',
                  fontWeight: '600',
                }}
              >
                DATA PRODUCT
              </Text>
              {lightVersionLabel && (
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title="This listing is a high-level description of the data product. Integration details are yet to be provided."
                >
                  <LightVersionLabel>{lightVersionLabel}</LightVersionLabel>
                </Tooltip>
              )}
              {listingType(module.type_id)}
            </Row>
            <Flexrow top="12px" />
            <Row style={{ paddingLeft: 4 }}>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title={module.name}
              >
                <Text
                  className="list-item-title"
                  style={{
                    color: '#182429',
                    fontSize: '16px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  {nameTrimHandler(module.name)}
                </Text>
              </Tooltip>
            </Row>
            <Flexrow top="12px" />
            <Row middle="xs" style={{ width: 'max-content' }}>
              <TurnedIn
                size="16"
                style={{ color: colors.grey_4, marginRight: '2px' }}
              />
              <Text
                className="list-item-category"
                style={{
                  color: '#64737f',
                  fontSize: '13px',
                  letterSpacing: '-0.5px',
                }}
              >
                {module.category_name}
              </Text>
            </Row>
          </Col>
        </Row>
      </Wrap>
    </Link>
  );
};

export const HomeRequestListItem = ({ data_request }) => {
  const imageUrl = getThumbnailURL();

  return (
    <Link to={`/data-request/${data_request.url_id}`}>
      <Wrap>
        <Row
          middle="xs"
          style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <img
            style={{ marginRight: 8, padding: '10px' }}
            width="83"
            height="84"
            alt="mobility-module-image"
            data-src={imageUrl}
            className="lazyload"
          />
          <Col xs={8} style={{ paddingLeft: 16 }}>
            <Row style={{ paddingLeft: 4, width: 'max-content' }}>
              <Text
                className="list-item-type"
                style={{
                  fontSize: '11px',
                  color: '#eb609e',
                  fontWeight: '600',
                }}
              >
                DATA REQUEST
              </Text>
            </Row>
            <Flexrow top="12px" />
            <Row style={{ paddingLeft: 4 }}>
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title={data_request.title}
              >
                <Text
                  className="list-item-title"
                  style={{
                    color: '#182429',
                    fontSize: '16px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  {nameTrimHandler(data_request.title)}
                </Text>
              </Tooltip>
            </Row>
            <Flexrow top="12px" />
            <Row middle="xs" style={{ width: 'max-content' }}>
              <TurnedIn
                size="16"
                style={{ color: colors.grey_4, marginRight: '2px' }}
              />
              <Text
                className="list-item-category"
                style={{
                  color: '#64737f',
                  fontSize: '13px',
                  letterSpacing: '-0.5px',
                }}
              >
                {data_request.category_name}
              </Text>
            </Row>
          </Col>
        </Row>
      </Wrap>
    </Link>
  );
};
