import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Typography } from 'antd';
import { toast } from 'react-toastify';

import { Flexcol } from '../Flexbox';
import fly_image from '../../images/fly.svg';
import Notification from '../Notification';
import FormLayout from '../FormLayout';

const { Text } = Typography;

const Verification = ({ form_data, isVerification, resendVerification }) => {
  const history = useHistory();
  const userEmail =
    history.location.state?.email && history.location.state?.email;

  useEffect(() => {
    if (isVerification) {
      isVerification(true);
    }
  }, []);

  const handleSubmit = async (form_data) => {
    try {
      const response = await axios.post(
        `${process.env.RAZZLE_ACCOUNT_URL}/auth/verification-email/${
          form_data?.email ? form_data.email : userEmail
        }`,
      );
      if (response.status === 200) {
        toast.success(
          <Notification type="success" text="Email resent successfully!" />,
        );
      }
    } catch (error) {
      toast.error(
        <Notification type="error" text={error.response.data.message} />,
      );
    }
  };

  return resendVerification ? (
    <FormLayout
      title="Check your email"
      subtitle={
        'We have sent an email to ' +
        userEmail +
        '\nwith a link to activate your account.'
      }
      isVerification={true}
    >
      <Flexcol>
        <div>
          <Text style={{ color: '#fff', fontSize: '15px', marginRight: '4px' }}>
            Didn’t get the email?
          </Text>
          <Text
            style={{
              color: '#fff',
              fontSize: '15px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => handleSubmit(form_data)}
          >
            Resend the confirmation email.
          </Text>
        </div>
        <img src={fly_image} alt="fly" style={{ marginTop: '100px' }} />
      </Flexcol>
    </FormLayout>
  ) : (
    <Flexcol>
      <div>
        <Text style={{ color: '#fff', fontSize: '15px', marginRight: '4px' }}>
          Didn’t get the email?
        </Text>
        <Text
          style={{
            color: '#fff',
            fontSize: '15px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => handleSubmit(form_data)}
        >
          Resend the confirmation email.
        </Text>
      </div>
      <img src={fly_image} alt="fly" style={{ marginTop: '100px' }} />
    </Flexcol>
  );
};

export default Verification;
