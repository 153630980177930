export const API_LINK = 'API Link';
export const RAW_DATA = 'Raw Data';

export const ACTIVE_STATUS_ID = 'e442ff39-bb90-43a8-92cb-b1662d2a259e';
export const PENDING_STATUS_ID = '7163bce3-f8f1-4e84-9d5c-d76054eaa6cc';
export const REJECT_STATUS_ID = 'ac0c64a9-f868-444c-874f-049a9606861e';
export const PENDING_PAYMENT_STATUS_ID = 'd587d394-836a-4633-b532-7daec1cbb80b';
export const REVIEW = '956200a6-e7fa-452e-93c4-14589d26b82f';

export const access_key_status_ids = {
  active: ACTIVE_STATUS_ID,
  review: REVIEW,
  rejected: REJECT_STATUS_ID,
  pending_payment: PENDING_PAYMENT_STATUS_ID,
  pending_offline_process: '4fd8943a-9f63-4c01-8f21-5ffe63355708',
};

export const data_placement_status_ids = {
  active: '675d2659-3f87-4140-92c4-7e7fe6e28bdb',
  deleted: '89972411-f306-48cc-8e5f-a4fa67b94a40',
  archived: 'e3a65d19-86a0-4a10-9094-109ae254c1c7',
  pending: '7163bce3-f8f1-4e84-9d5c-d76054eaa6cc',
  draft: '416de93d-9e7a-4903-a65c-7cd6a84b76bb',
};

export const resource_status_ids = {
  active: 'bebe6815-6670-4a5d-8540-951c215a72bb',
  deleted: '3f56b81f-6feb-4ce8-96ab-7c71e12a7420',
  archived: '6ea0da73-9c33-461d-94b3-e211601f3aae',
  reviewing: 'e56c88f1-7f8e-465a-b9de-4ba29c0838e7',
};

export const resource_type_ids = {
  raw_data: 'fed6ab48-5924-4682-a488-9cab62aa781d',
  api_link: '8e8f5094-8df7-4479-b9d5-de236bb3d5fd',
  mobito_index: '7e103c10-f3d0-4534-a036-7bcc49d5afff',
  service_integration: '78912289-037f-4826-a3da-6d18cc6163a0',
  sample_file: '33ffb39b-7727-40a3-a494-b3adced133a1',
  storage: '9e8cc9aa-a897-4cc9-8b61-adec1b1edccf',
};

export const api_consumption_methods = {
  PULL: 'PULL',
  PUSH: 'PUSH',
};

export const api_integration_methods = {
  PROXY: 'PROXY',
  STORAGE: 'STORAGE',
};

export const ACTIVE_STATUS = 'ACTIVE';
export const PENDING_PAYMENT_STATUS = 'PENDING_PAYMENT';
export const REJECT_STATUS = 'REJECT';

export const PRICE_PER_REQUEST = 'PRICE_PER_REQUEST';
export const PRICE_PER_SIZE = 'PRICE_PER_SIZE';
export const PRICE_FREE = 'PRICE_FREE';

export const RAW_DATA_ID = 'fed6ab48-5924-4682-a488-9cab62aa781d';
export const API_LINK_ID = '8e8f5094-8df7-4479-b9d5-de236bb3d5fd';
export const STORAGE_ID = '9e8cc9aa-a897-4cc9-8b61-adec1b1edccf';
export const SERVICE_INTEGRATION_ID = '78912289-037f-4826-a3da-6d18cc6163a0';
export const SAMPLE_FILE_ID = '33ffb39b-7727-40a3-a494-b3adced133a1';
export const S3_DATA = '13bbf0af-8c8f-4554-8247-8de6643fa25c';

export const data_placement_type_ids = {
  raw_data: RAW_DATA_ID,
  api_link: API_LINK_ID,
  storage: STORAGE_ID,
  service_integration: SERVICE_INTEGRATION_ID,
  sample_file: SAMPLE_FILE_ID,
  s3_bucket: '13bbf0af-8c8f-4554-8247-8de6643fa25c',
};

export const data_placement_type_names = {
  [data_placement_type_ids.s3_bucket]: 'S3 Bucket',
  [data_placement_type_ids.api_link]: 'Proxy API',
  [data_placement_type_ids.storage]: 'Files',
};

export const CONNECTION = 'connection';
export const CONSUMPTION = 'consumption';

export const LISTING_TYPES_TO_FUNCTIONS = {
  [RAW_DATA_ID]: 'platform-resource-serve',
  [API_LINK_ID]: 'proxy',
  [STORAGE_ID]: 'expose',
  [SAMPLE_FILE_ID]: 'sample_file',
};

export const REVIEWING_RESOURCE_STATUS_ID =
  'e56c88f1-7f8e-465a-b9de-4ba29c0838e7';
export const API_LINK_TYPE_ID = '8e8f5094-8df7-4479-b9d5-de236bb3d5fd';

export const ENTER_BUTTON_CODE = 13;
export const ESC_BUTTON_CODE = 27;

export let SUPPORT_EMAIL = 'support@mobito.io';

export const tag_rules = {
  nullify: 'nullify',
  hash: 'hash',
  first_4: 'first_4',
  last_4: 'last_4',
};

export const tag_rule_names = {
  [tag_rules.nullify]: 'Nullify',
  [tag_rules.hash]: 'Hash',
  [tag_rules.first_4]: 'First 4 digits',
  [tag_rules.last_4]: 'Last 4 digits',
};

export const data_access_types = {
  one_off: '_one_off_',
  recurrent: '_recurrent_',
};

export const data_delivery_methods = {
  api: '_api_',
  file_sharing: '_file_sharing_',
};

export const INVITATION_STATUS = ['ACTIVE', 'PENDING', 'REMOVED'];

export const user_roles = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
};

export const user_types = {
  CONSUMER: 'CONSUMER',
  PROVIDER: 'PROVIDER',
};

export const data_product_access_request_status = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const discussion_thread_status = {
  ANSWERED: 'ANSWERED',
  WAITING: 'WAITING',
  RESOLVED: 'RESOLVED',
};

export const thread_item_types = {
  QUESTION: 'QUESTION',
  ANSWER: 'ANSWER',
};

export const thread_item_status = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const data_room_offer_request_status = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  PENDING_REVIEW_BY_PROVIDER: 'PENDING_REVIEW_BY_PROVIDER',
  REQUEST_RECEIVED: 'REQUEST_RECEIVED',
  PUBLISHED: 'PUBLISHED',
};

export const DATA_FORMATS = [
  'csv',
  'dbf',
  'json',
  'parquet',
  'geojson',
  'image',
  'kml',
  'mid',
  'mif',
  'pdf',
  'shp',
  'txt',
  'xls/xlsx',
  'xml',
  'yaml',
  'md',
  'word/doc',
];

export const continents = {
  AFRICA: 'AFRICA',
  ASIA: 'ASIA',
  EUROPE: 'EUROPE',
  NORTH_AMERICA: 'NORTH_AMERICA',
  SOUTH_AMERICA: 'SOUTH_AMERICA',
  OCEANIA: 'OCEANIA',
};

export const COUNTRIES = [
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Belarus',
  'Belgium',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Brazil',
  'Bulgaria',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Guatemala',
  'Guinea',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Korea, South',
  'Kosovo',
  'Latvia',
  'Lebanon',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malaysia',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Netherlands',
  'New Zealand',
  'North Macedonia',
  'Norway',
  'Pakistan',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'San Marino',
  'Saudi Arabia',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'Spain',
  'Sweden',
  'Switzerland',
  'Turkey',
  'USA',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Other',
];

export const INDUSTRIES = [
  'Automotive',
  'Car dealers',
  'Car rental / Leasing',
  'Car- sharing',
  'Consulting',
  'DaaS - Data as a Service',
  'Energy',
  'Environment / Weather',
  'Financial Services',
  'Fleet Management Services',
  'Insurance',
  'Logistics / Transportation',
  'Mapping',
  'Marketing / Advertising / Communication',
  'Micro- mobility',
  'Mobility Services',
  'Public Sector',
  'Railway',
  'Real Estate',
  'Retail',
  'Ride- hailing',
  'Road Infrastructure',
  'Telco',
  'Travel / Tourism',
  'Other',
];

export const MEMBER_FILTER_OPTIONS = [
  'All members',
  'Active',
  'Pending',
  'Removed',
];

export const MEMBER_ROLES = ['Admin', 'Member'];

export const TYPE_OPTIONS = [
  'Array',
  'Boolean',
  'Categorical',
  'Character',
  'DateTime',
  'Float',
  'Integer',
  'String',
];

export const CAPTURING_AND_TRANSMISSION_DROPDOWN_OPTIONS = [
  'Per second',
  'Per minute',
  'Per hour',
  'Per day',
  'Per week',
  'Per month',
  'Per year',
  'Per event',
];
