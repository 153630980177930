import React, { useEffect } from "react";
import LayoutRow from "../components/LayoutRow";
import Header from "../components/Header";
import TermsAndConditions from "../views/TermsAndConditions";
import { Row, Col } from "react-styled-flexboxgrid";
import { SText } from "../components/styled-text/text";

const Terms = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <LayoutRow style={{ height: "max-content" }}>
        <div style={{ marginBottom: 128 }}>
          <Header withNav />
        </div>
        <Row center="xs">
          <Col style={{ paddingBottom: 80 }} xs={10}>
            <TermsAndConditions />
          </Col>
        </Row>
      </LayoutRow>
    </>
  );
};

export default Terms;
