import { useState, useMemo, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Menu, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import styled from 'styled-components';

import { AccountContext } from '../hooks/AccountContext';
import Header from '../components/Header';
import { EditProfile } from '../views/EditProfile';
import { EditPassword } from '../views/EditPassword';
import { EditEmail } from '../views/EditEmail';
import { CompanySettings } from '../views/CompanySettings';
import { CompanyAccountDetails } from '../views/CompanyAccountDetails';
import { CompanyUsers } from '../views/CompanyUsers';
import LayoutRow from '../components/LayoutRow';
import { confirmModal } from '../views/ModalView';
import UserAvatarSrc from '../images/user-avatar.svg';
import ProfileAvatarSrc from '../images/profile-avatar.svg';
import PasswordAvatarSrc from '../images/password-avatar.svg';
import EmailAvatarSrc from '../images/email-avatar.svg';
import { Flexrow, Flexcol } from '../components/Flexbox';
import { scrollStyles } from '../styles/scrollStyles';

const { Text } = Typography;
const { SubMenu } = Menu;

const Content = styled(Flexrow)`
  background-color: #f9faff;
  height: 80vh;
  overflow-y: auto !important;
  ${scrollStyles}
`;

const SideBar = styled(Flexcol)`
  width: 260px;
  background-color: white;
`;

const SideBarProfile = styled(Flexcol)`
  padding: 20px 24px;
`;

const SideBarRow = styled(Flexrow)`
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#53F1EB' : '#fff')};
  padding: 6px;
  margin: 0px 16px 10px 16px;
  border-radius: 8px;
  &:hover {
    background-color: ${(props) => !props.isActive && '#53F1EB1F'};
  }
`;

const MenuItemWrap = styled(Menu.Item)`
  color: #182a33 !important;
  width: 95% !important;
  padding-left: 14px !important;
  margin: auto !important;
  background-color: ${(props) =>
    props.isActive ? '#53f1eb !important' : '#fff !important'};
  border-radius: 8px !important;
  &::after {
    border-right: none !important;
  }
  &:hover {
    background-color: ${(props) => !props.isActive && '#53f1eb1f !important'};
  }
`;

const tab_views = {
  personal: EditProfile,
  password: EditPassword,
  email: EditEmail,
  companySettings: CompanySettings,
  companyAccountDetails: CompanyAccountDetails,
  companyUsers: CompanyUsers,
};

const full_confirmation = {
  title: (
    <Text style={{ color: '#182A33', fontSize: '19px', fontWeight: '600' }}>
      Save changes?
    </Text>
  ),
  content: (
    <Text style={{ color: '#64737F', fontSize: '15px' }}>
      You have unsaved changes. Would you like to save them before leaving?
    </Text>
  ),
  cancelText: 'No',
  okText: 'Yes, save changes',
};

const initialState = { profile: false, password: false, email: false };

const splitName = (name) => {
  return name
    .split(' ')
    .map((i) => i.charAt(0).toUpperCase())
    .slice(0, 2);
};

export const Account = () => {
  const [tab, setTab] = useState('personal');
  const [stepFor, setStepFor] = useState('MY_SETTINGS');
  const [isUpdated, setIsUpdated] = useState(initialState);
  const [canSave, setCanSave] = useState(initialState);
  const [reset, setReset] = useState(initialState);
  const history = useHistory();
  const account = JSON.parse(Cookies.get('account'));

  let activeTab = history.location.state && history.location.state.key;

  const role = Cookies.get('role');
  const [userRole, setUserRole] = useState(role && role);

  const ContentView = tab_views[tab];

  const keyHandler = () => {
    let updatedKeys = Object.keys(isUpdated);

    let filteredKeys = updatedKeys.filter((key) => {
      return isUpdated[key];
    });

    return filteredKeys;
  };

  const onSetTab = (tab) => async () => {
    setReset(initialState);

    switch (tab) {
      case 'companySettings':
        setStepFor('COMPANY_SETTINGS_STEPS');
        break;
      case 'companyUsers':
        setStepFor('COMPANY_SETTINGS_PEOPLE_STEPS');
        break;
      default:
        setStepFor('MY_SETTINGS');
        break;
    }

    let updatedTab = keyHandler();
    if (isUpdated.profile || isUpdated.password || isUpdated.email) {
      const confirmation = await confirmModal(full_confirmation);
      if (!confirmation) {
        setReset((prevState) => ({ ...prevState, [updatedTab]: true }));
      } else {
        setCanSave((prevState) => ({ ...prevState, [updatedTab]: true }));
        setTab(tab);
      }
    } else {
      setReset((prevState) => ({ ...prevState, [updatedTab]: false }));
      setTab(tab);
    }
  };

  let randomColor = useMemo(() => '#');
  const getRandomColor = useMemo(() => {
    const letters = '0123456789ABCDEF';
    for (let i = 0; i < 6; i++) {
      randomColor += letters[Math.floor(Math.random() * 16)];
    }
    return randomColor + '54';
  }, []);

  useEffect(() => {
    if (activeTab) {
      onSetTab('companyAccountDetails')();
    }
  }, []);

  const nameTrimHandler = (name) => {
    let maxLength = 15;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  return (
    <>
      <LayoutRow background={'#fff'} withBorder>
        <Header
          withNav
          stepsFor={stepFor}
          onSetTab={onSetTab}
          isUpdated={isUpdated.profile || isUpdated.password || isUpdated.email}
          tab={tab}
          user={account}
        />
      </LayoutRow>
      <LayoutRow withShadow withBorder>
        <Text
          style={{
            color: '#182429',
            fontSize: '15px',
            fontWeight: '600',
            padding: '16px 0px',
            display: 'block',
            marginLeft: '-0.5rem',
          }}
        >
          My Settings
        </Text>
      </LayoutRow>
      {account && (
        <Content grow>
          <SideBar space_between>
            <div>
              <SideBarProfile center_align>
                <img
                  src={UserAvatarSrc}
                  style={{
                    backgroundColor: '#F0F1F2',
                    padding: '8px 8px 0px 8px',
                    borderRadius: '50%',
                    marginBottom: '12px',
                  }}
                />
                <Text
                  style={{
                    color: '#182A33',
                    fontSize: '15px',
                    fontWeight: '600',
                  }}
                >
                  {account.user.firstname} {account.user.lastname}
                </Text>
                <Text
                  style={{
                    color: '#182A33',
                    fontSize: '13px',
                    marginBottom: '8px',
                  }}
                >
                  {account.user.email}
                </Text>
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '13px',
                    marginBottom: '30px',
                  }}
                >
                  {account.user.businessRole}
                </Text>
              </SideBarProfile>
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '11px',
                  fontWeight: '600',
                  padding: '0px 16px',
                  marginBottom: '8px',
                  display: 'block',
                }}
              >
                {`MY PROFILE:`}
              </Text>
              <SideBarRow
                onClick={onSetTab('personal')}
                id="my-personal-settings"
                isActive={tab === 'personal'}
              >
                <img src={ProfileAvatarSrc} style={{ marginRight: '10px' }} />
                <Text
                  style={{
                    color: '#182A33',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  PERSONAL
                </Text>
              </SideBarRow>
              <SideBarRow
                onClick={onSetTab('password')}
                id="my-password"
                isActive={tab === 'password'}
              >
                <img src={PasswordAvatarSrc} style={{ marginRight: '10px' }} />

                <Text
                  style={{
                    color: '#182A33',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  PASSWORD
                </Text>
              </SideBarRow>
              <SideBarRow
                onClick={onSetTab('email')}
                id="my-email"
                isActive={tab === 'email'}
              >
                <img src={EmailAvatarSrc} style={{ marginRight: '10px' }} />

                <Text
                  style={{
                    color: '#182A33',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  EMAIL
                </Text>
              </SideBarRow>
            </div>
            <div style={{ marginBottom: '32px' }}>
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '11px',
                  fontWeight: '600',
                  padding: '0px 16px',
                }}
              >
                COMPANY PROFILE:
              </Text>
              <Menu onClick={() => onSetTab('companySettings')()} mode="inline">
                <SubMenu
                  key="sub1"
                  id="company-profile"
                  title={
                    <Flexrow center_align>
                      <div
                        style={{
                          width: '34px',
                          height: '34px',
                          backgroundColor: getRandomColor,
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '10px',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: '14px',
                            color: randomColor,
                          }}
                        >
                          {splitName(account.organization.name)}
                        </Text>
                      </div>
                      <Flexcol>
                        <Tooltip
                          color="#1a337d"
                          overlayInnerStyle={{
                            boxShadow: '2px 4px 12px 0px #182a331f',
                            fontSize: '13px',
                            borderRadius: '4px',
                          }}
                          placement="bottomLeft"
                          title={
                            account.organization.name.length > 15 &&
                            account.organization.name
                          }
                        >
                          <Text
                            style={{
                              color: '#182A33',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: 'initial',
                            }}
                          >
                            {nameTrimHandler(account.organization.name)}
                          </Text>
                        </Tooltip>
                        <Text
                          style={{
                            color: userRole === 'ADMIN' ? '#fff' : '#182A33',
                            fontSize: '9px',
                            backgroundColor:
                              userRole === 'ADMIN' ? '#182A33' : '#fff',
                            borderRadius: '2px',
                            lineHeight: 'initial',
                            width: 'fit-content',
                            padding: '2px 4px',
                            border:
                              userRole === 'MEMBER' && '1px solid #182A33',
                            fontWeight: userRole === 'MEMBER' && '600',
                          }}
                        >
                          {userRole}
                        </Text>
                      </Flexcol>
                    </Flexrow>
                  }
                >
                  <MenuItemWrap
                    key="1"
                    isActive={
                      tab === 'companySettings' ||
                      tab === 'companyAccountDetails' ||
                      tab === 'companyUsers'
                    }
                  >
                    COMPANY SETTINGS
                  </MenuItemWrap>
                </SubMenu>
              </Menu>
            </div>
          </SideBar>
          <Content center_content grow>
            <ContentView
              user={account}
              setIsUpdated={setIsUpdated}
              setCanSave={setCanSave}
              canSave={canSave}
              reset={reset}
              onSetTab={onSetTab}
              setUserRole={setUserRole}
            />
          </Content>
        </Content>
      )}
      <style>{`.ant-menu-submenu-arrow, .ant-menu-submenu-arrow:hover { color: #53F1EB !important; }`}</style>
    </>
  );
};
