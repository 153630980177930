import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const DataProtectionTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        D
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ATA </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>P</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ROTECTION</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito is committed to protecting personal data in a way that is
              compliant with the Applicable Data Protection Laws. Mobito will
              process the Client’s personal data that the Client provides in
              order to make use of the Platform and Services in accordance with
              the privacy policy.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              In case of Platform Services, the Data Provider shall ensure that
              the Data does not contain any personal data. In the event the Data
              does contain personal data, the Data Provider will notify Mobito
              hereof prior to listing or otherwise providing the Data for the
              purpose of the Services. Each party shall comply with the
              Applicable Data Protection Laws with respect to the processing of
              personal data, and Mobito shall, where legally required and upon
              the Data Provider’s request, enter into a data processing
              agreement.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              In case of the Data Preparation Services and in case personal data
              is in scope, Mobito can receive and process personal data for the
              benefit and on behalf of the Data Customer and in accordance with
              the instructions of the Data Customer. The parties agree that
              Mobito is the processor, and the Data Customer is the controller
              in respect of such processing of personal data related to the
              Agreement. Mobito shall, where legally required and upon the Data
              Customer’s request, enter into a data processing agreement. In so
              far requested by the Data Provider, Mobito and the Data Provider
              will also enter into an agreement where it is clear that Mobito
              acts on behalf of the Data Customer in gathering and collecting
              the personal data.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              If any changes or prospective changes to the Applicable Data
              Protection Laws result or will result in one or both parties not
              complying with the Applicable Data Protection Laws in relation to
              processing of personal data carried out under this Agreement, the
              parties shall use their best endeavours promptly to agree such
              variations to this Agreement as may be necessary to remedy such
              non-compliance.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The use of personal data of the Data Provider by the Data Customer
              shall be governed in the Data Agreement.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default DataProtectionTerms;
