import React, { useState, useEffect } from 'react';
import { Modal, Card, Typography, Tooltip } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { toast } from 'react-toastify';

import { InfoCircle } from '@styled-icons/boxicons-solid/InfoCircle';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';

import LayoutRow from '../LayoutRow';
import { Flexcol, Flexrow } from '../Flexbox';
import Notification from '../../components/Notification';

import 'react-circular-progressbar/dist/styles.css';

const { Text } = Typography;

const CardWrap = styled.div`
  width: 70%;
  margin: ${(props) => (props.top ? `${props.top} auto` : 'auto')};
`;

const StatusTag = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 14px;
  padding: 4px 11px;
  margin-bottom: 10px;
  width: fit-content;
  height: 30px;
`;

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const ConsumptionModalFree = (props) => {
  const {
    imageUrl,
    name,
    setIsFreeModalVisible,
    isFreeModalVisible,
    resource_connection_id,
  } = props;
  const [data, setData] = useState(null);
  const token = Cookies.get('jwt') || '';

  const MINUTE_MS = 4000;

  useEffect(() => {
    const fetchEndpoints = async () => {
      let res = await axios
        .get(
          `/api/data-consumption-details?conn_id=${resource_connection_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .catch((error) => {
          if (error.response.status === 404) {
            toast.error(
              <Notification type="error" text="Unauthorized Access" />,
            );
            history.push('/not-found');
          }
        });

      setData(res.data);
    };
    fetchEndpoints();
  }, []);

  useEffect(() => {
    if (isFreeModalVisible) {
      const interval = setInterval(() => {
        const fetchEndpoints = async () => {
          let res = await axios
            .get(
              `/api/data-consumption-details?conn_id=${resource_connection_id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
            .catch((error) => {
              if (error.response.status === 404) {
                toast.error(
                  <Notification type="error" text="Unauthorized Access" />,
                );
                history.push('/not-found');
              }
            });

          setData(res.data);
        };
        fetchEndpoints();
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [isFreeModalVisible]);

  const handleOk = () => {
    setIsFreeModalVisible(false);
  };

  const handleCancel = () => {
    setIsFreeModalVisible(false);
  };

  const formatDate = (date) => {
    let current_datetime = new Date(date);
    let formatted_date =
      current_datetime.getDate() +
      ' ' +
      months[current_datetime.getMonth()] +
      ' ' +
      current_datetime.getFullYear();
    return formatted_date;
  };

  return (
    <Modal
      title={
        <LayoutRow>
          <Flexcol>
            <Flexrow center_align style={{ paddingTop: '10px' }}>
              <Flexrow center_align>
                <img
                  style={{ marginRight: '10px' }}
                  width="40"
                  height="40"
                  alt="mobility-module-image"
                  src={imageUrl}
                />
                <Text style={{ color: '#192A33', fontSize: '18px' }}>
                  {name ? name : 'No name'}
                </Text>
              </Flexrow>
            </Flexrow>
            <Flexrow center_align space_between>
              <Text
                style={{
                  marginTop: '18px',
                  color: '#192A33',
                  fontSize: '20px',
                  fontWeight: '600',
                }}
              >
                Consumption Analytics
              </Text>
            </Flexrow>
          </Flexcol>
        </LayoutRow>
      }
      visible={isFreeModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      style={{ paddingBottom: '0px' }}
      bodyStyle={{
        backgroundColor: '#F9FAFF',
        padding: '0px',
        height: '80vh',
        overflowY: 'auto',
      }}
      width="100%"
      footer={null}
      zIndex={1000000}
    >
      <LayoutRow>
        <CardWrap top="20px">
          <Text
            style={{
              color: '#64737F',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Your current plan
          </Text>
          <Card
            style={{
              marginTop: '12px',
              borderRadius: '6px',
              boxShadow: '2px 4px 12px 0px #182a330f',
            }}
            headStyle={{ borderBottom: 'none' }}
            bodyStyle={{ padding: '0px 24px 24px' }}
            bordered={false}
            title={
              <Text
                style={{
                  fontSize: '24px',
                  color: '#192A33',
                  fontWeight: '600',
                }}
              >
                Free
              </Text>
            }
          >
            <Flexrow>
              <Flexcol style={{ width: '50%' }}>
                <Card
                  title={
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#64737F',
                        fontWeight: '500',
                      }}
                    >
                      Total requests
                    </Text>
                  }
                  extra={
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title="This is a Free access data product with no expiration date. See in the “Other limits” section below the access restrictions that are applied"
                      zIndex={1000001}
                    >
                      <InfoCircle
                        style={{
                          height: '18px',
                          color: '#64737F',
                        }}
                      />
                    </Tooltip>
                  }
                  headStyle={{
                    backgroundColor: '#F6F6F6',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                  }}
                  style={{
                    borderRadius: '6px',
                    border: '1px solid #C9CED1',
                    marginRight: '20px',
                    marginBottom: '20px',
                    height: '-webkit-fill-available',
                  }}
                  bordered={false}
                >
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#64737F',
                      display: 'block',
                    }}
                  >
                    {`From ${
                      data && data.start_date
                        ? formatDate(data.start_date)
                        : `No date`
                    }`}
                  </Text>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    Unlimited
                  </Text>
                </Card>
                <Card
                  title={
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#64737F',
                        fontWeight: '500',
                      }}
                    >
                      Total cost
                    </Text>
                  }
                  extra={
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title="This is a Free access data product with no cost"
                      zIndex={1000001}
                    >
                      <InfoCircle
                        style={{
                          height: '18px',
                          color: '#64737F',
                        }}
                      />
                    </Tooltip>
                  }
                  headStyle={{
                    backgroundColor: '#F6F6F6',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                  }}
                  style={{
                    borderRadius: '6px',
                    border: '1px solid #C9CED1',
                    marginRight: '20px',
                    height: '-webkit-fill-available',
                  }}
                  bordered={false}
                >
                  <StatusTag background="#cefac3" borderColor="#5fdf3e">
                    <CheckCircleFill
                      style={{
                        height: '16px',
                        color: '#5fdf3e',
                        paddingRight: '7px',
                      }}
                    />
                    Free
                  </StatusTag>
                  <Text
                    style={{
                      fontSize: '24px',
                      color: '#192A33',
                    }}
                  >
                    {data && data.total_price} €
                  </Text>
                </Card>
              </Flexcol>
              <Card
                title={
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                    }}
                  >
                    Consumption progress
                  </Text>
                }
                extra={
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title="The number of API requests that you have made in total for this data product since you gained access"
                    zIndex={1000001}
                  >
                    <InfoCircle
                      style={{
                        height: '18px',
                        color: '#64737F',
                      }}
                    />
                  </Tooltip>
                }
                headStyle={{
                  backgroundColor: '#F6F6F6',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
                style={{
                  borderRadius: '6px',
                  border: '1px solid #C9CED1',
                  width: '50%',
                }}
                bordered={false}
              >
                <div
                  style={{
                    width: '220px',
                    height: '220px',
                    margin: 'auto',
                  }}
                >
                  <CircularProgressbarWithChildren
                    styles={buildStyles({
                      pathColor: '#5FDF3E',
                      trailColor: '#5FDF3E',
                      backgroundColor: '#5FDF3E',
                    })}
                    background={true}
                    value={data && data.requests ? data.requests.spent : 0}
                  >
                    <Text
                      style={{
                        fontSize: '24px',
                        color: '#fff',
                        display: 'block',
                      }}
                    >
                      {data && data.requests ? data.requests.spent : 0}
                    </Text>
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#fff',
                        display: 'block',
                      }}
                    >
                      requests
                    </Text>
                    <Text
                      style={{
                        fontSize: '16px',
                        color: '#fff',
                      }}
                    >
                      consumed
                    </Text>
                  </CircularProgressbarWithChildren>
                </div>
              </Card>
            </Flexrow>
          </Card>
        </CardWrap>
        <CardWrap top="50px">
          <Text
            style={{
              color: '#64737F',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Other limits
          </Text>
          <Card
            style={{
              marginTop: '12px',
              borderRadius: '6px',
              boxShadow: '2px 4px 12px 0px #182a330f',
            }}
            headStyle={{ borderBottom: 'none' }}
            bordered={false}
            title={null}
          >
            <Flexrow>
              <Card
                title={
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                    }}
                  >
                    Per minute
                  </Text>
                }
                extra={
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    overlayStyle={{ maxWidth: 'fit-content' }}
                    placement="bottom"
                    title={
                      <div>
                        <strong>Total</strong>: The maximum total number of API
                        requests that you are allowed to do within a minute
                        (static)
                        <br />
                        <strong>Consumed</strong>: The number of API requests
                        that you have consumed over the last 60 seconds
                        (auto-updated)
                      </div>
                    }
                    zIndex={1000001}
                  >
                    <InfoCircle
                      style={{
                        height: '18px',
                        color: '#64737F',
                      }}
                    />
                  </Tooltip>
                }
                headStyle={{
                  backgroundColor: '#F6F6F6',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
                style={{
                  borderRadius: '6px',
                  border: '1px solid #C9CED1',
                  marginRight: '20px',
                  width: '33%',
                }}
                bordered={false}
              >
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#64737F',
                    display: 'block',
                  }}
                >
                  Total:
                </Text>
                <Text
                  style={{
                    fontSize: '24px',
                    color: '#192A33',
                  }}
                >
                  {data && data.limits && data.limits.minute.limit
                    ? data.limits.minute.limit
                    : `Unlimited`}
                </Text>
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#64737F',
                    display: 'block',
                  }}
                >
                  Consumed:
                </Text>
                <Text
                  style={{
                    fontSize: '24px',
                    color: '#192A33',
                  }}
                >
                  {data &&
                  data.limits &&
                  data.limits.minute &&
                  data.limits.minute.spent
                    ? data.limits.minute.spent
                    : `None`}
                </Text>
              </Card>
              <Card
                title={
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                    }}
                  >
                    Per hour
                  </Text>
                }
                extra={
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    overlayStyle={{ maxWidth: 'fit-content' }}
                    placement="bottom"
                    title={
                      <div>
                        <strong>Total</strong>: The maximum total number of API
                        requests that you are allowed to do within an hour
                        (static)
                        <br />
                        <strong>Consumed</strong>: The number of API requests
                        that you have consumed over the last 60 minutes
                        (auto-updated)
                      </div>
                    }
                    zIndex={1000001}
                  >
                    <InfoCircle
                      style={{
                        height: '18px',
                        color: '#64737F',
                      }}
                    />
                  </Tooltip>
                }
                headStyle={{
                  backgroundColor: '#F6F6F6',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
                style={{
                  borderRadius: '6px',
                  border: '1px solid #C9CED1',
                  marginRight: '20px',
                  width: '33%',
                }}
                bordered={false}
              >
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#64737F',
                    display: 'block',
                  }}
                >
                  Total:
                </Text>
                <Text
                  style={{
                    fontSize: '24px',
                    color: '#192A33',
                  }}
                >
                  {data && data.limits && data.limits.hour.limit
                    ? data.limits.hour.limit
                    : `Unlimited`}
                </Text>
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#64737F',
                    display: 'block',
                  }}
                >
                  Consumed:
                </Text>
                <Text
                  style={{
                    fontSize: '24px',
                    color: '#192A33',
                  }}
                >
                  {data &&
                  data.limits &&
                  data.limits.hour &&
                  data.limits.hour.spent
                    ? data.limits.hour.spent
                    : `None`}
                </Text>
              </Card>
              <Card
                title={
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                    }}
                  >
                    Per day
                  </Text>
                }
                extra={
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    overlayStyle={{ maxWidth: 'fit-content' }}
                    placement="bottom"
                    title={
                      <div>
                        <strong>Total</strong>: The maximum total number of API
                        requests that you are allowed to do within a day
                        (static)
                        <br />
                        <strong>Consumed</strong>: The number of API requests
                        that you have consumed over the last 24 hours
                        (auto-updated)
                      </div>
                    }
                    zIndex={1000001}
                  >
                    <InfoCircle
                      style={{
                        height: '18px',
                        color: '#64737F',
                      }}
                    />
                  </Tooltip>
                }
                headStyle={{
                  backgroundColor: '#F6F6F6',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
                style={{
                  borderRadius: '6px',
                  border: '1px solid #C9CED1',
                  marginRight: '20px',
                  width: '33%',
                }}
                bordered={false}
              >
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#64737F',
                    display: 'block',
                  }}
                >
                  Total:
                </Text>
                <Text
                  style={{
                    fontSize: '24px',
                    color: '#192A33',
                  }}
                >
                  {data && data.limits && data.limits.day.limit
                    ? data.limits.day.limit
                    : `Unlimited`}
                </Text>
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#64737F',
                    display: 'block',
                  }}
                >
                  Consumed:
                </Text>
                <Text
                  style={{
                    fontSize: '24px',
                    color: '#192A33',
                  }}
                >
                  {data &&
                  data.limits &&
                  data.limits.day &&
                  data.limits.day.spent
                    ? data.limits.day.spent
                    : `None`}
                </Text>
              </Card>
            </Flexrow>
          </Card>
        </CardWrap>
      </LayoutRow>
    </Modal>
  );
};

export default ConsumptionModalFree;
