import Cookies from "js-cookie";
import { Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Tooltip, Modal } from "antd";
import {
  InfoCircleOutlined,
  LeftOutlined,
  WarningFilled,
} from "@ant-design/icons";
import React, { useState, useContext } from "react";
import { DataProductContext } from "../helpers/DataProductWizardHelper";
import { Flexcol, Flexrow } from "./Flexbox";
import styled from "styled-components";
import { colors } from "../values/colors";
import { confirmModal } from "../views/ModalView";
import { toast } from "react-toastify";
import Notification from "./Notification";

const { Title, Text } = Typography;

const SubmitWrap = styled.div`
  position: relative;
  float: right;
`;

const SubmitInfoWrap = styled(Flexcol)`
  background-color: ${colors.yellow_2};
  border: 1px solid ${colors.yellow_3};
  position: absolute;
  top: 0px;
  left: -8px;
  right: -8px;
  bottom: -100px;
  padding: 4px;
  justify-content: flex-end;
`;

const HeaderWizard = ({ review, no_back_button }) => {
  const token = Cookies.get("jwt");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const helper = useContext(DataProductContext);
  const history = useHistory();
  if (helper.for_review) {
    return (
      <>
        <Flexrow grow />
        <Title style={{ marginBottom: 0, textAlign: "end" }} level={5}>
          {helper.published
            ? "Changes submitted for review"
            : "Submitted for review"}
        </Title>
      </>
    );
  }

  let { isWizardReview, project } = review;
  let disabledButton = false;
  let tooltipValues = [];
  let tooltipProxyValues = [];

  if (helper.published && !helper.cloned) {
    disabledButton = true;
  }

  if (project.proxy) {
    if (
      helper.inputs.find(
        (e) =>
          e.missing ||
          (e.has_error && (e.type === "proxy" || e.type === "generic")) ||
          e.value === "<p><br></p>"
      )
    ) {
      disabledButton = true;
    }
  } else {
    if (
      helper.inputs.find(
        (e) =>
          (e.missing && e.type === "generic") ||
          (e.has_error && e.type === "generic") ||
          e.value === "<p><br></p>"
      )
    ) {
      disabledButton = true;
    }
  }

  helper.inputs.map((element) => {
    if (project.proxy) {
      if (
        element.missing ||
        (element.has_error &&
          (element.type === "proxy" || element.type === "generic"))
      ) {
        tooltipProxyValues.push(element.getName());
        tooltipValues = [];
      } else if (element.value === "<p><br></p>") {
        tooltipProxyValues.push(element.getName());
        tooltipValues = [];
      }
    } else {
      if (
        (element.missing && element.type === "generic") ||
        (element.has_error && element.type === "generic")
      ) {
        tooltipValues.push(element.getName());
        tooltipProxyValues = [];
      } else if (element.value === "<p><br></p>") {
        tooltipValues.push(element.getName());
        tooltipProxyValues = [];
      }
    }
  });

  const previewListing = () => {
    const review_id =
      review.project.placement.clone_id || review.project.placement.id;
    history.push(`/project/data-review/${review_id}`);
  };

  const editProject = () => {
    if (review.project.placement.editing_id) {
      history.push(
        `/project/data-connection/${review.project.placement.editing_id}#settings`
      );
    } else {
      history.push(`/project/data-wizard/${review.project.placement.id}`);
    }
  };

  const cancelChanges = async () => {
    helper.submitting = true;
    helper.refresh();
    try {
      await axios.delete(`/api/data-placements/editable/${helper.id}/clone`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      helper.reset();
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="An error occured, please try again later"
        />
      );
    }
    helper.submitting = false;
    helper.refresh();
  };

  const confirmChangesSubmit = async () => {
    const confirmation = await confirmModal({
      title: "Important Note",
      content: (
        <>
          <p>
            You are about to submit your changes to the Mobito team for a
            review, before they become public.
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      ),
    });
    if (confirmation) {
      submitForReview();
    }
  };

  const submitForReview = async () => {
    setIsModalVisible(false);
    helper.submitting = true;
    helper.refresh();
    try {
      await axios.post(
        `/api/data-placements/editable/${helper.id}/publish-request`,
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      helper.for_review = true;
      if (helper.published) {
        helper.for_review = true;
        helper.refresh();
      } else {
        history.push(`/dashboard`);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="An error occured, please try again later"
        />
      );
    }
    helper.submitting = false;
    helper.refresh();
  };

  const prepareSubmit = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const goToDashboard = () => {
    history.push(`/dashboard`);
  };

  const global_saving = !!helper.sections.find((e) => e.saving || e.editing);

  const submit_disabled = helper.submitting || disabledButton || global_saving;
  return (
    <>
      {!no_back_button && (
        <LeftOutlined
          onClick={goToDashboard}
          style={{ marginTop: "18px", marginBottom: "8px", marginLeft: "52px" }}
        />
      )}
      <>
        {(!!tooltipValues.length || !!tooltipProxyValues.length) && (
          <Tooltip
            title={
              `Please check the following fields: ` +
              (tooltipValues.length > 0 ? tooltipValues : tooltipProxyValues)
            }
          >
            <span
              style={{
                marginTop: "12px",
                color: "red",
                float: "right",
                marginLeft: "12px",
              }}
            >
              <InfoCircleOutlined />
            </span>
          </Tooltip>
        )}
        <SubmitWrap>
          {!submit_disabled && helper.published && (
            <SubmitInfoWrap>
              <Flexrow>
                <WarningFilled style={{ marginTop: 4, marginRight: 4 }} />
                <Text>
                  Your changes need to be submitted for review in order to
                  become public
                </Text>
              </Flexrow>
            </SubmitInfoWrap>
          )}
          <Button
            disabled={submit_disabled}
            type="primary"
            style={{ marginTop: "8px", marginBottom: "8px", float: "right" }}
            onClick={() =>
              helper.published ? confirmChangesSubmit() : prepareSubmit()
            }
          >
            SUBMIT FOR REVIEW
          </Button>
        </SubmitWrap>
      </>
      {!isWizardReview ? (
        <Button
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            float: "right",
            marginRight: "20px",
          }}
          onClick={() => {
            previewListing();
          }}
          disabled={
            global_saving ||
            helper.submitting ||
            (helper.published && !helper.cloned)
          }
        >
          {helper.published ? "PREVIEW CHANGES" : "PREVIEW LISTING"}
        </Button>
      ) : (
        <Button
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            float: "right",
            marginRight: "20px",
          }}
          onClick={() => {
            editProject();
          }}
        >
          EDIT PROJECT
        </Button>
      )}
      {!isWizardReview && helper.published && (
        <Button
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            float: "right",
            marginRight: "20px",
          }}
          onClick={cancelChanges}
          disabled={global_saving || !helper.cloned || helper.submitting}
        >
          CANCEL CHANGES
        </Button>
      )}
      <Modal
        title="Important Note"
        visible={isModalVisible}
        onOk={submitForReview}
        onCancel={handleCancel}
      >
        <p>
          You are about to complete your data connection project creation and
          submit your project to Mobito team for a review before being
          integrated in the platform.
        </p>
        <p>Are you sure you want to proceed?</p>
      </Modal>
    </>
  );
};

export default HeaderWizard;
