import { CopyOutlined } from "@ant-design/icons";
import { Modal, Input, Button } from "antd";
import { useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import Notification from "./Notification";

const CopyButton = styled(Button)`
  margin-right: 12px;
`;

const Wrap = styled.div`
  display: flex;
  margin-top: 16px;
`;

const onCopy = (text) => () => {
  navigator.clipboard.writeText(text);
  toast.success(<Notification type="success" text="Copied to clipboard" />);
};

export const openKeyModal = (key) =>
  Modal.info({
    title: "Your API key",
    icon: null,
    content: <KeyModal access_key={key} />,
  });

const KeyModal = (p) => {
  const { access_key } = p;
  const ref = useRef();
  const onInputClick = () => {
    ref.current.setSelectionRange(0, access_key.length);
  };
  return (
    <Wrap>
      <CopyButton
        type="default"
        icon={<CopyOutlined />}
        onClick={onCopy(access_key)}
      />
      <Input onClick={onInputClick} ref={ref} width={400} value={access_key} />
    </Wrap>
  );
};
