import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Form, Tabs, Typography, Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import useStep from '../hooks/useStep';
import useForm from '../hooks/useFormStore';
import Email from '../components/signup/Email';
import Verification from '../components/signup/Verification';
import Terms from '../components/signup/Terms';

import FormLayout from '../components/FormLayout';
import { Flexcol } from '../components/Flexbox';
import SubsectionTitle from '../components/wizards-components/SubsectionTitle';
import { AccountContext } from '../hooks/AccountContext';
import { LoginRedirector } from '../services/LoginRedirector';
import Notification from '../components/Notification';

const { TabPane } = Tabs;
const { Text } = Typography;

const steps = [{ id: 'email' }, { id: 'terms' }, { id: 'verification' }];

const defaultData = {
  email: '',
  terms: false,
  password: '',
  password_repeat: '',
  privacy_policy: false,
  consent: true,
};

const login_header = (key) => {
  let isActive = key === '1';

  return (
    <Flexcol
      style={{
        textAlign: 'center',
        paddingTop: '32px',
      }}
    >
      <Text
        style={{
          fontSize: '21px',
          color: isActive ? '#344ef3' : '#182429',
          fontWeight: 100,
        }}
      >
        Log in
      </Text>
      <Text style={{ fontSize: '15px', color: '#64737f' }}>
        Returning visitor
      </Text>
    </Flexcol>
  );
};

const signup_header = (key) => {
  let isActive = key === '2';

  return (
    <Flexcol
      style={{
        textAlign: 'center',
        paddingTop: '32px',
      }}
    >
      <Text
        style={{
          fontSize: '21px',
          color: isActive ? '#344ef3' : '#182429',
          fontWeight: 100,
        }}
      >
        Sign up
      </Text>
      <Text style={{ fontSize: '15px', color: '#64737f' }}>
        First-time visitor
      </Text>
    </Flexcol>
  );
};

const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 36px;
  background-color: #344ef3;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: none;
  &:disabled {
    background-color: #fff;
    color: #c1c2c2;
    border: 1px solid #f3f3f4;
    cursor: not-allowed;
  }
  &:hover,
  :active,
  :focus {
    background-color: #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
    color: #fff;
  }
`;

const TogglePasswordButton = styled(Button)`
  position: absolute;
  top: -30px;
  right: 0;
  border: 0;
  box-shadow: none;
  padding: 0;
  height: initial;
  color: #344ef3;
  font-size: 13px;
  width: 60px;
  &:hover,
  :active,
  :visited,
  :focus {
    color: #344ef3;
    background: #fff;
  }
`;

const ForgotPasswordLink = styled(Link)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -32px;
`;

const email_form_rules = [
  {
    type: 'email',
    message: 'The input is not valid Email!',
  },
  {
    required: true,
    message: 'Please input your Email!',
  },
];

const password_form_rules = [
  {
    required: true,
    message: 'Please input your password!',
  },
];

const Login = ({ selectedTab }) => {
  const { setToken, setAccount, setIsComplete } = useContext(AccountContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [key, setKey] = useState(selectedTab);
  const [tabs, setTabs] = useState(true);
  const [isVerification, setIsVerification] = useState(false);
  const history = useHistory();
  const [errors, setErrors, form_data, setForm] = useForm(defaultData);
  let { step, navigation } = useStep({
    initialStep: 0,
    steps,
  });

  useEffect(
    () => () => {
      LoginRedirector.releasePath();
    },
    [],
  );

  let layout;
  let layoutTitle = 'Welcome to Mobito';
  let layoutSubtitle = 'Your data ecosystem partner';

  let { id: step_id } = step;

  const props = {
    step_id,
    errors,
    setErrors,
    form_data,
    setForm,
    navigation,
  };

  const form_fields = [
    { name: ['email'], value: email },
    { name: ['password'], value: password },
  ];

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = `${process.env.RAZZLE_ACCOUNT_URL}/auth/login`;
    const deploymentDomain =
      process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

    try {
      const res = await axios.post(url, {
        email,
        password,
      });
      const { jwt, organization, user } = res.data;
      toast.success(
        <Notification type="success" text="You are now logged in" />,
      );
      Cookies.set('user_id', res.data.user.id, { domain: deploymentDomain });
      Cookies.set('jwt', res.data.jwt, { domain: deploymentDomain });
      Cookies.set('email', res.data.user.email, { domain: deploymentDomain });
      Cookies.set('firstname', res.data.user.firstname, {
        domain: deploymentDomain,
      });
      Cookies.set('lastname', res.data.user.lastname, {
        domain: deploymentDomain,
      });
      Cookies.set('companyName', res.data.organization.name, {
        domain: deploymentDomain,
      });
      Cookies.set('role', res.data.user.role, { domain: deploymentDomain });
      Cookies.set('account', res.data, { domain: deploymentDomain });
      Cookies.set('refreshToken', res.data.refreshToken, {
        domain: deploymentDomain,
      });
      Cookies.set('organization_id', res.data.organization.id, {
        domain: deploymentDomain,
      });

      setAccount(res.data);
      setToken(jwt);
      if (!user.businessRole || !user.firstname || !user.lastname) {
        history.push('/account');
        setIsComplete((prevState) => ({ ...prevState, personal: false }));
      } else {
        LoginRedirector.retrievePath(history);
        setIsComplete({ personal: true, company: true });
      }

      if (
        !organization.address ||
        !organization.country ||
        !organization.name ||
        !organization.vat
      ) {
        history.push({ pathname: '/account', state: { key: 'company' } });
        setIsComplete((prevState) => ({ ...prevState, company: false }));
      } else {
        LoginRedirector.retrievePath(history);
        setIsComplete({ personal: true, company: true });
      }
    } catch (error) {
      if (error.response.status === 403) {
        try {
          const response = await axios.post(
            `${process.env.RAZZLE_ACCOUNT_URL}/auth/verification-email/${email}`,
          );
          if (response.status === 200) {
            history.push({
              pathname: '/resend-verification',
              state: { email: email },
            });
          }
        } catch (error) {
          toast.error(
            <Notification
              type="error"
              text={
                typeof error.response.data.message === 'string'
                  ? error.response.data.message
                  : error.response.data.message.join(', ')
              }
            />,
          );
        }
      } else {
        toast.error(
          <Notification
            type="error"
            text={
              typeof error.response.data.message === 'string'
                ? error.response.data.message
                : error.response.data.message.join(', ')
            }
          />,
        );
      }
      setEmail('');
      setPassword('');
      setToken('');
    }
  };

  const getKeyHandler = (key) => {
    setKey(key);
    if (key === '2') {
      history.push('/signup');
    } else {
      history.push('/login');
    }
  };

  const renderStep = () => {
    switch (step_id) {
      case 'email':
        return <Email {...props} />;
      case 'terms':
        return <Terms {...props} isSignup={setTabs} activeKey={setKey} />;
      case 'verification':
        return <Verification {...props} isVerification={setIsVerification} />;
      default:
        return null;
    }
  };

  if (isVerification) {
    layoutTitle = 'Check your email';
    layoutSubtitle =
      'We have sent an email to ' +
      form_data.email +
      '\nwith a link to activate your account.';
  }

  if (tabs) {
    layout = (
      <Tabs
        defaultActiveKey="1"
        centered
        onChange={getKeyHandler}
        tabBarStyle={{ marginBottom: '32px' }}
        activeKey={key.toString()}
      >
        <TabPane tab={login_header(key)} key="1">
          <Form fields={form_fields}>
            <SubsectionTitle tooltip="Company email">Email</SubsectionTitle>
            <Form.Item name="email" rules={email_form_rules}>
              <Input
                placeholder="example@email.com"
                onChange={handleEmailChange}
                size="large"
                style={{
                  borderRadius: '4px',
                  border: '1px solid #c4c4c4',
                  boxShadow: 'none',
                }}
              />
            </Form.Item>
            <SubsectionTitle tooltip="Account password">
              Password
            </SubsectionTitle>
            <Form.Item name="password" rules={password_form_rules}>
              <div style={{ position: 'relative' }}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Type your password"
                  onChange={handlePasswordChange}
                  size="large"
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #c4c4c4',
                    boxShadow: 'none',
                  }}
                />
                <TogglePasswordButton
                  onClick={() => setShowPassword(!showPassword)}
                  type="text"
                >
                  {showPassword ? (
                    <span>
                      <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
                    </span>
                  ) : (
                    <span>
                      <EyeOutlined style={{ color: '#344ef3' }} /> Show
                    </span>
                  )}
                </TogglePasswordButton>
              </div>
            </Form.Item>
            <LoginButton size="large" onClick={handleSubmit}>
              Log in
            </LoginButton>
          </Form>
          <ForgotPasswordLink to="/forgot-password">
            <Text
              style={{
                color: '#fff',
                textDecoration: 'underline',
                fontSize: '15px',
              }}
            >
              Forgot password?
            </Text>
          </ForgotPasswordLink>
        </TabPane>
        <TabPane tab={signup_header(key)} key="2">
          {renderStep()}
        </TabPane>
      </Tabs>
    );
  } else {
    layout = renderStep();
  }

  return (
    <FormLayout
      title={layoutTitle}
      subtitle={layoutSubtitle}
      isVerification={isVerification}
    >
      {layout}
    </FormLayout>
  );
};

export default Login;
