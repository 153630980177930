import React, { useEffect, useContext, useRef, useState } from "react";
import { Form, Row, Col, Input, Button, Typography, Modal } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import styled from "styled-components";

import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";
import {
  SectionCardContainer,
  Desc,
} from "../../../wizards-components/styled-components";
import { strings } from "../../values/strings";
import { PublishedSectionTitle } from "../../../wizards-components/SectionTitle";
import SubsectionTitle from "../../../wizards-components/SubsectionTitle";
import TitleAndDesc from "../../../wizards-components/TitleAndDesc";
import { tinyMCEEditorAPIKey } from "../../../../values/tinyMCEEditorAPIKey";
import { tinyInitConfig } from "../../values/tinyInitConfig";
import { Flexrow, Flexcol } from "../../../Flexbox";
import { scrollStyles } from "../../../../styles/scrollStyles";
import EditModeImgSrc from "../../../../images/edit_mode.svg";

const { Text } = Typography;

const LicenseContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  ${scrollStyles}
`;

const EditModeWrap = styled.div`
  border: ${(props) => props.editing && "3px solid #FF7F19"};
  position: ${(props) => props.editing && "relative"};
  padding: ${(props) => props.editing && "0px 32px 32px"};
  background-color: ${(props) => props.editing && "#fff"};
  z-index: ${(props) => props.editing && 2};
  margin: ${(props) => props.editing && `0 -42px ${props.marginBottom}`};
`;

const EditModeInfo = styled.div`
  background-color: #fff;
  position: sticky;
  top: 20px;
  transform: ${(props) => props.toRight && `translateX(${props.toRight}px)`};
  width: 320px;
  padding: 20px;
  &:before {
    content: "";
    position: absolute;
    height: 0px;
    width: 0px;
    top: 15px;
    left: -22px;
    border-width: 15px;
    border-color: transparent white transparent transparent;
    border-style: solid;
  }
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #344ef380;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 1500px;
`;

const ProceedButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #344ef3;
  color: #fff;
  font-size: 14px;
  padding: 6px 12px;
  height: initial;
`;

const CancelButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #fff;
  color: #344ef3;
  font-size: 14px;
  padding: 6px 12px;
  height: initial;
`;

const S3PublishedFullVersion = () => {
  const helper = useContext(DataProductContext);
  const form_ref = useRef();
  const hide_edits = helper.for_review || helper.submitting;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [width, setWidth] = useState(false);

  useEffect(() => {
    helper.setStepAccessed(3);
  }, []);

  const showModal = (helperValue) => {
    if (helperValue.edited()) setIsModalVisible(true);
    else helperValue.cancelChanges();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (helper.sections_map.license.editing) {
      helper.sections_map.license.cancelChanges();
    } else if (helper.sections_map.contactS3.editing) {
      helper.sections_map.contactS3.cancelChanges();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let fullS3Section = document.getElementById(
        "full-s3-version-card-container"
      );
      setWidth(fullS3Section.clientWidth - 21);
    }
  }, []);

  return (
    <>
      <Modal
        title={
          <Text style={{ color: "#182429", fontSize: "21px" }}>
            Are you sure?
          </Text>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        zIndex={1000001}
        footer={[
          <ProceedButton key="submit" type="primary" onClick={handleOk}>
            No, continue
          </ProceedButton>,
          <CancelButton key="back" onClick={handleCancel}>
            Yes, cancel
          </CancelButton>,
        ]}
      >
        <Text style={{ color: "#64737f", fontSize: "14px" }}>
          Your changes are not saved. Are you sure you want to cancel?
        </Text>
      </Modal>
      {(helper.sections_map.license.editing ||
        helper.sections_map.contactS3.editing) && <Overlay />}
      <SectionCardContainer id="full-s3-version-card-container">
        <Form ref={form_ref}>
          <TitleAndDesc title="Full version" desc={strings.full_version_desc} />
          <EditModeWrap
            editing={helper.sections_map.license.editing}
            marginBottom="-32px"
          >
            {helper.sections_map.license.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.license.saving}
              title="License"
              tooltip={
                <Desc pre color="#fff">
                  {strings.license_tooltip}
                </Desc>
              }
              editing={helper.sections_map.license.editing}
              startEditing={() => helper.sections_map.license.setEditing()}
              saveChanges={() => helper.sections_map.license.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.license.edited()}
              handleCancel={() => showModal(helper.sections_map.license)}
            />
            {helper.sections_map.license.editing ? (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.license_tooltip}
                    </Desc>
                  }
                >
                  License
                </SubsectionTitle>
                <Editor
                  apiKey={tinyMCEEditorAPIKey}
                  value={helper.license_type.value}
                  onEditorChange={(v) => helper.license_type.set(v)}
                  init={tinyInitConfig}
                />
              </>
            ) : (
              <>
                <SubsectionTitle
                  tooltip={
                    <Desc pre color="#fff">
                      {strings.license_tooltip}
                    </Desc>
                  }
                >
                  License
                </SubsectionTitle>
                <LicenseContainer
                  dangerouslySetInnerHTML={{
                    __html: helper.license_type.value,
                  }}
                ></LicenseContainer>
              </>
            )}
          </EditModeWrap>
          <Flexrow top="32px" />
          <EditModeWrap
            editing={helper.sections_map.contactS3.editing}
            marginBottom="-42px"
          >
            {helper.sections_map.contactS3.editing && (
              <EditModeInfo toRight={width}>
                <Flexrow start_align>
                  <img src={EditModeImgSrc} />
                  <Flexcol left="10px">
                    <Text
                      style={{
                        color: "#182429",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Edit mode
                    </Text>
                    <Text
                      style={{
                        color: "#64737F",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      You are in edit mode. Please save or cancel your changes.
                    </Text>
                  </Flexcol>
                </Flexrow>
              </EditModeInfo>
            )}
            <PublishedSectionTitle
              saving={helper.sections_map.contactS3.saving}
              title="Technical contact details"
              tooltip={
                <Desc pre color="#fff">
                  {strings.technical_contact_details_tooltip}
                </Desc>
              }
              editing={helper.sections_map.contactS3.editing}
              startEditing={() => helper.sections_map.contactS3.setEditing()}
              saveChanges={() => helper.sections_map.contactS3.saveChanges()}
              hideEdit={hide_edits}
              edited={helper.sections_map.contactS3.edited()}
              handleCancel={() => showModal(helper.sections_map.contactS3)}
            />
            {helper.sections_map.contactS3.editing ? (
              <Row justify="space-between">
                <Col span={11}>
                  <SubsectionTitle
                    tooltip={strings.technical_contact_details_name_tooltip}
                  >
                    {`Name & Surname`}
                  </SubsectionTitle>
                </Col>
                <Col span={11} offset={1}>
                  <SubsectionTitle
                    tooltip={strings.technical_contact_details_email_tooltip}
                  >
                    Email
                  </SubsectionTitle>
                </Col>
                <Col span={11}>
                  <Input
                    name="contact-s3-name"
                    onChange={(e) => helper.contactS3Name.set(e.target.value)}
                    value={helper.contactS3Name.value}
                    placeholder="e.g. John Doe"
                  />
                </Col>
                <Col span={11} offset={1}>
                  <Input
                    name="contact-s3-email"
                    onChange={(e) => helper.contactS3Email.set(e.target.value)}
                    value={helper.contactS3Email.value}
                    placeholder="e.g. your@email.com"
                  />
                </Col>
              </Row>
            ) : (
              <>
                <Row justify="space-between">
                  <Col span={11}>
                    <SubsectionTitle
                      tooltip={strings.technical_contact_details_name_tooltip}
                    >
                      {`Name & Surname`}
                    </SubsectionTitle>
                  </Col>
                  <Col span={11} offset={1}>
                    <SubsectionTitle
                      tooltip={strings.technical_contact_details_email_tooltip}
                    >
                      Email
                    </SubsectionTitle>
                  </Col>
                  <Col span={11}>
                    <Text>{helper.contactS3Name.value}</Text>
                  </Col>
                  <Col span={11} offset={1}>
                    <Text>{helper.contactS3Email.value}</Text>
                  </Col>
                </Row>
              </>
            )}
          </EditModeWrap>
        </Form>
      </SectionCardContainer>
    </>
  );
};

export default S3PublishedFullVersion;
