import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import MOBITO_ICON_WHITE_PATH from '../images/mobito-icon-white.svg';
import MOBITO_ICON_PATH from '../images/mobito-icon.svg';

const { Text } = Typography;

const Wrap = styled.div`
  margin-top: auto;
  padding: 8px 0;
  width: 100%;
  height: 52px;
  z-index: 1;
  box-shadow: 2px -4px 12px rgb(24 42 51 / 6%);
`;

const Footer = () => {
  return (
    <Wrap>
      <Row style={{ height: '100%', margin: '0px' }} center="xs" middle="xs">
        <Col xs={10}>
          <Row style={{ height: '100%' }} middle="xs" between="xs">
            <Col xs={7} style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{
                  marginRight: 20,
                  width: 16,
                  height: 16,
                  display: 'none',
                }}
                className="white-logo-visible"
                src={MOBITO_ICON_WHITE_PATH}
                alt="mobito logo"
              />
              <img
                style={{
                  marginRight: 20,
                  width: 16,
                  height: 16,
                  display: 'initial',
                }}
                className="black-logo-hidden"
                src={MOBITO_ICON_PATH}
                alt="mobito logo"
              />
              <Text
                className="footer-login-style"
                style={{ color: '#a8afb3', fontSize: '10px' }}
              >
                Copyright © {new Date().getFullYear()} Mobito Technology SRL.
                All Rights Reserved.
              </Text>
            </Col>
            <Col xs={5}>
              <Row end="xs" middle="xs">
                <Col>
                  <p
                    onClick={() =>
                      window.open('https://www.mobito.io/contact-us', '_blank')
                    }
                    className="footer-login-style"
                    style={{
                      color: '#182a33',
                      fontSize: '10px',
                      cursor: 'pointer',
                      margin: '0px',
                    }}
                  >
                    contact us
                  </p>
                </Col>
                <Col>
                  <Link
                    to="/terms"
                    target="_blank"
                    className="footer-login-style"
                    style={{
                      color: '#182a33',
                      fontSize: '10px',
                      display: 'block',
                    }}
                  >
                    {`terms & conditions`}
                  </Link>
                </Col>
                <Col>
                  <p
                    onClick={() =>
                      window.open(
                        'https://www.mobito.io/privacy-policy',
                        '_blank',
                      )
                    }
                    className="footer-login-style"
                    style={{
                      color: '#182a33',
                      fontSize: '10px',
                      cursor: 'pointer',
                      margin: '0px',
                    }}
                  >
                    privacy policy
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrap>
  );
};

export default Footer;
