import { getScreenWidthHandler } from '../helpers/getScreenWidthHandler';
import { Modal } from 'antd';
import { ExplanatoryStepModal } from './ExplanatoryStepModal';

export const handleExplanatoryStepModal = (title, content) => {
  Modal.info({
    title: null,
    icon: null,
    content: <ExplanatoryStepModal title={title} content={content} />,
    closable: true,
    centered: true,
    okButtonProps: { style: { display: 'none' } },
    style: { paddingBottom: 0 },
    width: getScreenWidthHandler(),
  });
};
