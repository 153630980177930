import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import { SText } from "../components/styled-text/text";

const RequestSummaryModule = ({ module, requestValues }) => {
  return (
    <>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Pricing
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {module.project.placement.hide_pricing ? (
              <p>-</p>
            ) : requestValues && requestValues.price ? (
              `${requestValues.price.toLocaleString()} euro / API request`
            ) : (
              "N/A euro / API request"
            )}
          </SText>
        </Col>
      </Row>
      {requestValues && requestValues.min_requests_purchased && (
        <Row style={{ padding: 10 }} between="xs">
          <Col>
            <SText text_16 semi medium>
              Minimum purchase
            </SText>
          </Col>
          <Col>
            <SText text_16 black extra_bold>
              {requestValues.min_requests_purchased.toLocaleString()} API
              requests
            </SText>
          </Col>
        </Row>
      )}
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 black bold>
            Request limits
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold></SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per minute
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {requestValues && requestValues.limit_minute
              ? requestValues.limit_minute.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per hour
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {requestValues && requestValues.limit_hour
              ? requestValues.limit_hour.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per day
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {requestValues && requestValues.limit_day
              ? requestValues.limit_day.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
    </>
  );
};

export default RequestSummaryModule;
