import {
  EMAIL_REGEX,
  AT_LEAST_ONE_UPPERCASE,
  AT_LEAST_ONE_NUMERIC,
  AT_LEAST_ONE_SPECIAL,
  AT_LEAST_EIGHT_CHARACTERS,
} from "../../utils/validation";

export default function validate(values, step) {
  let errors = {};
  let passwordErrors = [];

  if (step === "email") {
    if (!EMAIL_REGEX.test(values.email.toLowerCase()))
      errors.email = "Email format is not correct";
    if (values.email === "") errors.email = "Please input your Email!";
  }

  if (step === "password") {
    if (values.password != values.password_repeat)
      errors.password_repeat = "Password does not match";
    if (!AT_LEAST_ONE_UPPERCASE.test(values.password))
      passwordErrors.push("upper");
    if (!AT_LEAST_ONE_NUMERIC.test(values.password)) passwordErrors.push("num");
    if (!AT_LEAST_ONE_SPECIAL.test(values.password))
      passwordErrors.push("special");
    if (!AT_LEAST_EIGHT_CHARACTERS.test(values.password))
      passwordErrors.push("eight");
    errors = { ...errors, passwordErrors };
  }

  return errors;
}
