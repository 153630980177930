import axios from 'axios';
import { createContext } from 'react';
import { v4 as uuid } from 'uuid';
import { TreeTraversor } from '../utils/TreeTraversor';
import { data_placement_status_ids } from '../iso-shared/values/global';
import { toast } from 'react-toastify';
import Notification from '../components/Notification';
import Cookies from 'js-cookie';

export const PlacementContext = createContext(null);

const deepCopy = (v) => JSON.parse(JSON.stringify(v));

export class PlacementWizardHelper {
  error_msg = '';
  sections = [];
  inputs = [];
  published = false;
  for_review = false;
  cloned = false;
  submitting = false;

  constructor(refresh, id, token, project, reset) {
    this.refresh = refresh;
    this.reset = reset;
    this.id = id;
    this.token = token;
    this.project = project;
  }

  onChange() {
    if (this.published && !this.cloned) {
      this.cloned = true;
      this.refresh();
    }
  }

  makeInput(init, section, name, required, type, requiredValue, validator) {
    const _this = this;
    class Input {
      value = init;
      saved_value = init;
      missing = false;
      has_error = false;
      edited = false;
      type = type;

      setEditing = () => {
        this.saved_value = deepCopy(this.value);
      };

      cancelChanges = () => {
        this.value = this.saved_value;
        this.edited = false;
      };

      set = (v) => {
        this.value = v;
        this.trigger();
      };

      getName = () => name;

      validate = () => {
        if (Array.isArray(this.value) && requiredValue) {
          this.missing =
            (!!required && !this.value.every((e) => e[requiredValue])) ||
            (!!required &&
              this.getName() === 'Price' &&
              this.value.length === 0);

          if (
            this.value.filter((e) => e.charge_type === 'per_request').length > 0
          ) {
            this.getName = () => 'Price';
            this.missing =
              !!required && !this.value.every((e) => e[requiredValue]);
          }

          if (
            this.value.filter((e) => e.charge_type === 'per_month').length > 0
          ) {
            this.getName = () => 'Pricing package';
            this.missing =
              !!required &&
              !this.value.every(
                (e) => e[requiredValue] && this.value.every((e) => e['name']),
              );
          }

          if (this.value.filter((e) => e.charge_type === 'free').length > 0) {
            this.getName = () => 'Free limits';
            this.missing =
              !!required &&
              !this.value.every(
                (e) => e['limit_minute'] || e['limit_hour'] || e['limit_day'],
              );
          }
        } else {
          const lastDomainCharacter = /.*\/$/m;
          let isNotPublic = this.getName() === 'Api key' && this.value === '';
          let authMethod =
            this.getName() === 'Authentication method' && this.value === '';

          this.has_error =
            this.getName() === 'Api domain' &&
            this.value !== '' &&
            !this.value.match(lastDomainCharacter);

          if (authMethod || isNotPublic) {
            if (authMethod && isNotPublic) {
              this.getName = () => 'Auth method';
              this.getName = () => 'Api key';
            } else {
              authMethod ? (this.getName = () => 'Auth method') : 'Api key';
            }
            this.missing = !this.value;
          } else {
            this.missing = !!required && !this.value;
          }
        }
      };

      trigger = () => {
        if (!section.editing) {
          this.validate();
          section.trigger();
        } else {
          this.edited = true;
          _this.refresh();
        }
      };
    }
    const out = new Input();
    this.inputs.push(out);
    section.inputs.push(out);
    return out;
  }

  makeSection(apiTrigger) {
    const _this = this;
    class Section {
      editing = false;
      inputs = [];
      edited() {
        return !!this.inputs.find((e) => e.edited);
      }
      done() {
        this.saving = false;
        _this.refresh();
      }
      trigger() {
        this.saving = true;
        apiTrigger.trigger(this);
        _this.refresh();
      }
      setEditing() {
        this.editing = true;
        this.inputs.forEach((e) => e.setEditing());
        _this.refresh();
      }
      cancelChanges = () => {
        this.inputs.forEach((e) => e.cancelChanges());
        this.editing = false;
        _this.refresh();
      };
      saveChanges = () => {
        this.editing = false;
        this.trigger();
        this.inputs.forEach((e) => {
          e.edited = false;
          e.validate();
        });
      };
    }
    const section = new Section();
    this.sections.push(section);
    return section;
  }

  async updateErrors() {
    let last_error_msg = this.error_msg;
    if (this.project && this.project.proxy) {
      if (this.inputs.find((e) => e.has_error && e.type === 'proxy')) {
        this.error_msg = 'Validation error';
      } else if (this.inputs.find((e) => e.missing)) {
        this.error_msg = 'Missing Info';
      } else {
        this.error_msg = 'Pending Submission';
      }
    } else {
      if (this.inputs.find((e) => e.missing && e.type === 'generic')) {
        this.error_msg = 'Missing Info';
      } else {
        this.error_msg = 'Pending Submission';
      }
    }

    if (last_error_msg != this.error_msg) {
      const token = Cookies.get('jwt');
      try {
        await axios.put(
          '/api/update-errors',
          {
            msg: this.error_msg,
            id: this.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  makeApiTrigger(poster) {
    const _this = this;
    class Trigger {
      sections = new Set();
      trigger = (section) => {
        clearTimeout(this.timeout);
        this.sections.add(section);
        this.timeout = setTimeout(async () => {
          try {
            _this.updateErrors();
            await poster();
            _this.onChange();
          } catch (error) {
            console.error(error);
            toast.error(
              <Notification
                type="error"
                text="An error occured, please try again later"
              />,
            );
          }
          Array.from(this.sections).forEach((s) => s.done());
          this.sections = new Set();
        }, 600);
      };
    }

    return new Trigger();
  }

  // -------

  detailsApiTrigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-placements/editable/${this.id}`,
      {
        name: this.name.value,
        description: this.description.value,
        category_id: this.category.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  detailsSection = this.makeSection(this.detailsApiTrigger);

  name = this.makeInput(
    '',
    this.detailsSection,
    'Project name',
    true,
    'generic',
  );

  description = this.makeInput('', this.detailsSection);

  category = this.makeInput(
    null,
    this.detailsSection,
    'Project category',
    true,
    'generic',
  );

  // -------

  historicalApiTrigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-placements/editable/${this.id}`,
      {
        historical_data: this.historical_data.value,
        analytics: this.analytics.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  historicalSection = this.makeSection(this.historicalApiTrigger);

  historical_data = this.makeInput(null, this.historicalSection);

  analytics = this.makeInput(null, this.historicalSection);

  // -------

  apiDetailsApiTrigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-placements/editable/${this.id}/proxy`,
      {
        domain: this.domain.value,
        api_documentation_link: this.api_documentation_link.value,
        auth_method: this.auth_method.value,
        api_key: this.api_key.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  apiDetailsSection = this.makeSection(this.apiDetailsApiTrigger);

  domain = this.makeInput(
    '',
    this.apiDetailsSection,
    'Api domain',
    true,
    'proxy',
    null,
    true,
  );

  api_documentation_link = this.makeInput(
    '',
    this.apiDetailsSection,
    'Api documentation link',
    true,
    'proxy',
  );

  auth_method = this.makeInput(
    null,
    this.apiDetailsSection,
    'Authentication method',
    false,
    'proxy',
  );

  api_key = this.makeInput(
    null,
    this.apiDetailsSection,
    'Api key',
    false,
    'proxy',
  );

  // ------

  endpointsApiTrigger = this.makeApiTrigger(async () => {
    const endpoints = this.endpoints.value.map((d) => ({
      http_method: d.http_method,
      description: d.description,
      sample_data: d.sample_data,
      endpoint: d.endpoint,
      applied_tags: d.applied_tags,
    }));

    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-placements/editable/${this.id}/proxy/endpoints`,
      endpoints,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  endpointsSection = this.makeSection(this.endpointsApiTrigger);

  endpoints = this.makeInput(
    [],
    this.endpointsSection,
    'Endpoints',
    true,
    'proxy',
    'endpoint',
  );

  // ------

  contactApiTrigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    await axios.post(
      `/api/data-placements/editable/${this.id}/proxy/contact-details`,
      {
        contact_name: this.contactName.value,
        contact_email: this.contactEmail.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  contactSection = this.makeSection(this.contactApiTrigger);

  contactName = this.makeInput('', this.contactSection);

  contactEmail = this.makeInput('', this.contactSection);

  // ------

  packagesApiTrigger = this.makeApiTrigger(async () => {
    const packages = this.packages.value.map((e) => {
      const { form_id, ...props } = e;
      return { ...props };
    });

    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-placements/editable/${this.id}/pricing-bundles`,
      packages,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  packagesSection = this.makeSection(this.packagesApiTrigger);

  packages = this.makeInput(
    [],
    this.packagesSection,
    'Price',
    true,
    'proxy',
    'price',
  );

  // ------

  hidePricingApiTrigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    axios.put(
      `/api/data-placements/editable/${this.id}`,
      {
        hide_pricing: this.hide_pricing.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  hidePricingSection = this.makeSection(this.hidePricingApiTrigger);

  hide_pricing = this.makeInput(false, this.hidePricingSection);

  // ------

  // License

  licenseTrigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    axios.put(
      `/api/data-placements/editable/${this.id}`,
      {
        license_type: this.license_type.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  licenseSection = this.makeSection(this.licenseTrigger);

  license_type = this.makeInput(
    '',
    this.licenseSection,
    'License',
    true,
    'generic',
  );

  // ------

  // s3 contact details

  contactS3Trigger = this.makeApiTrigger(async () => {
    const token = Cookies.get('jwt');

    await axios.post(
      `/api/data-placements/editable/${this.id}/s3/contact-details`,
      {
        contact_name: this.contactS3Name.value,
        contact_email: this.contactS3Email.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  contactS3Section = this.makeSection(this.contactS3Trigger);

  contactS3Name = this.makeInput('', this.contactS3Section);

  contactS3Email = this.makeInput('', this.contactS3Section);

  // ------

  fromProject(project) {
    const { placement, limits, contact_details, proxy } = project;

    this.error_msg = placement.error_msg;

    this.name.value = placement.name;
    this.description.value = placement.description;
    this.category.value = placement.category_id;
    this.historical_data.value = placement.historical_data;
    this.analytics.value = placement.analytics;
    this.hide_pricing.value = placement.hide_pricing;
    this.license_type.value = placement.license_type;

    if (proxy) {
      this.domain.value = proxy.domain;
      this.api_documentation_link.value = proxy.api_documentation_link;
      this.auth_method.value = proxy.auth_method;
      this.api_key.value = proxy.api_key;
      let endpoints = proxy.platform_resource_proxy_api_endpoints.map((e) => {
        e.form_id = uuid();
        delete e.id;
        e.show = true;
        let can_apply_tags = false;
        try {
          const sample_json = JSON.parse(e.sample_data);
          if (TreeTraversor.canParse(sample_json)) {
            can_apply_tags = true;
          }
        } catch (error) {
          // do nothing
        }
        e.can_apply_tags = can_apply_tags;
        return e;
      });
      this.endpoints.value = [
        ...endpoints.filter((e) => e.in_origin),
        ...endpoints.filter((e) => !e.in_origin),
      ];
    }

    this.packages.value = limits.map((e) => ({
      ...e.json_payload,
      form_id: uuid(),
    }));
    if (contact_details) {
      this.contactName.value = contact_details.contact_name;
      this.contactEmail.value = contact_details.contact_email;
      this.contactS3Name.value = contact_details.contact_name;
      this.contactS3Email.value = contact_details.contact_email;
    }

    this.cloned = !!project.placement.clone_id;

    if (
      this.cloned ||
      placement.status_id == data_placement_status_ids.active
    ) {
      this.published = true;
    }

    if (placement.status_id == data_placement_status_ids.pending) {
      this.for_review = true;
    }

    this.inputs.forEach((e) => e.validate());
    this.updateErrors();
  }
}
