const tryGetObject = value => {
    if ( value && typeof(value) == 'object' ) {
        if ( Array.isArray(value) ) {
            return tryGetObject(value[0])
        } else {
            return value
        }
    }
}

export class TreeTraversor {
    jsonObj = null
    parent = null
    nodesTree = []

    constructor (jsonObj, parent_label) {
        if ( !TreeTraversor.canParse(jsonObj) ) {
            throw new Error('TreeTraversor should only be initialized with objects')
        }
        this.jsonObj = jsonObj
        this.parent_label = parent_label
        this.parseTree()
    }

    getLabel(key) {
        const label = this.parent_label ? this.parent_label + '->' + key : key
        return label
    }

    parseTree() {
        for (let key in this.jsonObj) {
            const key_value = tryGetObject(this.jsonObj[key])
            const node = {
                name: key,
                label: this.getLabel(key)
            }
            if ( key_value ) {
                node.tree = new TreeTraversor(key_value, node.label)
            }
            this.nodesTree.push(node)
        }
    }

}

TreeTraversor.canParse = jsonObj => {
    if ( !jsonObj || typeof(jsonObj) != 'object' || Array.isArray(jsonObj) ) {
        return false
    }
    return true
}


