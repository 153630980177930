import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const ForceMajeureTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        F
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ORCE </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>M</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>AJEURE</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Neither party shall be bound to fulfil any of its obligations
              under this Agreement if prevented from doing so due to Force
              Majeure.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Notice of a Force Majeure event shall be given in writing by the
              affected party to the other party as soon as it becomes aware of
              the occurrence. The affected party shall use all reasonable
              endeavours to prevent, avoid, overcome or mitigate the effects of
              such cause. Each party shall bear all of its own claims, losses,
              damages, costs and expenses suffered or incurred due to such Force
              Majeure event.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              If a Force Majeure event lasts longer than sixty (60) consecutive
              calendar days, either party shall have the right to terminate the
              Agreement or any part thereof, forthwith and without penalty. What
              has already been performed under the Agreement shall in that case
              be settled proportionally between the parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default ForceMajeureTerms;
