import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import useSWR from 'swr';
import Cookies from 'js-cookie';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ThemeProvider } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';
import { Button, Tooltip, Dropdown, Menu, Typography } from 'antd';

import { TurnedIn } from 'styled-icons/material';
import { DocumentText } from '@styled-icons/ionicons-solid/DocumentText';
import { Key } from '@styled-icons/boxicons-regular/Key';
import { DotsVerticalRounded } from '@styled-icons/boxicons-regular/DotsVerticalRounded';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { Delete } from '@styled-icons/typicons/Delete';
import { NewTab } from '@styled-icons/icomoon/NewTab';

import { colors } from '../../values/colors';
import { SText } from '../../components/styled-text/text';
import { Checkout } from './Checkout';
import { CheckoutFree } from './CheckoutFree';
import { withoutColPadding } from '../../styles/grid.js';
import { fetcherWithToken } from '../../utils/fetcher';
import { getThumbnailURL } from '../../iso-shared/utils/getThumbnailUrl';
import PackageCheckout from './PackageCheckout';
import { openKeyModal } from '../openKeyModal';
import ConsumptionModalRequest from './ConsumptionModalRequest';
import ConsumptionModalPackage from './ConsumptionModalPackage';
import ConsumptionModalFree from './ConsumptionModalFree';
import MaskedRulesModal from './MaskedRulesModal';
import { Flexrow, Flexcol } from '../Flexbox';
import { listingType } from '../ListingTypeLabel';
import MaskRulesImgSrc from '../../images/mask.svg';

const { Text } = Typography;

const Wrap = styled(Row)`
  background-color: #ffffff;
  padding: 20px 28px;
  border-bottom: 2px solid ${colors['grey_1']};
  display: flex;
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: 2px 4px 12px rgba(24, 42, 51, 0.06);
  position: relative;
  justify-content: space-between;
`;

const LightVersionLabel = styled.div`
  border-radius: 10.5px;
  font-size: 12px;
  border: 1px solid rgb(4, 148, 221);
  padding: 2px 8px;
  margin-left: 15px;
  color: #0494dd;
  white-space: nowrap;
`;

const BuyMoreButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const PayToActivateButton = styled(Button)`
  background-color: #ff7f19;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background-color: #e56b0a;
    color: #fff;
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const GenerateKeyButton = styled(Button)`
  border: 1px solid #344ef3;
  border-radius: 8px;
  color: #344ef3;
  font-size: 15px;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    color: #0c28dd;
    border: 1px solid #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  }
`;

const HoverWrap = styled(Flexcol)`
  &:hover {
    background-color: #ffeee1;
    border-radius: 8px;
    cursor: pointer;
  }
`;

const StatusTag = styled.div`
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: ${(props) => (props.textColor ? props.textColor : '#64737f')};
  font-size: 13px;
  padding: 4px 11px;
  margin: 0 auto;
  width: fit-content;
  cursor: ${(props) => props.cursor && 'pointer'};
  &:hover {
    background: ${(props) => props.hoverBackground && props.hoverBackground};
    border: 1px solid
      ${(props) => props.hoverBackground && props.hoverBackground};
  }
`;

const SourceTitle = styled(Text)`
  color: #182429;
  font-size: 18px;
  letter-spacing: -0.5px;
  cursor: pointer;
  white-space: pre;
  &:hover {
    text-decoration: underline;
    color: #344ef3 !important;
    font-weight: 700;
  }
`;

const stripePublic = process.env.RAZZLE_STRIPE_PUBLIC_KEY;

const SourceCard = ({ source, project_id, setApiKey }) => {
  const placement_id = source.id;
  const stripePromise = loadStripe(stripePublic);
  const token = Cookies.get('jwt') || '';
  const currentUserRole = Cookies.get('role');
  const [visible, setVisible] = useState(false);
  const [reqPayModalVisible, setReqPayModalVisible] = useState(false);
  const [packPayModalVisible, setPackPayModalVisible] = useState(false);
  const [freePayModalVisible, setFreePayModalVisible] = useState(false);
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
  const [isPackageModalVisible, setIsPackageModalVisible] = useState(false);
  const [isFreeModalVisible, setIsFreeModalVisible] = useState(false);
  const [endpoints, setEndpoints] = useState(null);
  const [savedRules, setSavedRules] = useState({});

  let lightVersionLabel = source.published_step === 2 && 'Light version';

  let { data: consumption } = useSWR(
    [`/api/data-total-consumption?id=${source.resource_connection_id}`, token],
    fetcherWithToken,
  );

  const { data: pricing_bundles } = useSWR(
    [`/api/data-placements/pricing-bundles?id=${placement_id}`, token],
    fetcherWithToken,
  );

  const charge_type = pricing_bundles && pricing_bundles[0].charge_type;

  const statusName = {
    PENDING_PAYMENT: (
      <Tooltip
        color="#1a337d"
        overlayInnerStyle={{
          boxShadow: '2px 4px 12px 0px #182a331f',
          fontSize: '13px',
          borderRadius: '4px',
        }}
        placement="bottom"
        title=""
      >
        <StatusTag background="#fff3d6" borderColor="#ffc540">
          <ClockFill
            style={{
              height: '16px',
              color: '#ffc540',
              paddingRight: '7px',
            }}
          />
          Payment pending
        </StatusTag>
      </Tooltip>
    ),
    ACTIVE: (
      <Tooltip
        color="#1a337d"
        overlayInnerStyle={{
          boxShadow: '2px 4px 12px 0px #182a331f',
          fontSize: '13px',
          borderRadius: '4px',
        }}
        placement="bottom"
        title=""
      >
        <StatusTag background="#cefac3" borderColor="#5fdf3e">
          <CheckCircleFill
            style={{ height: '16px', color: '#5fdf3e', paddingRight: '7px' }}
          />
          Active
        </StatusTag>
      </Tooltip>
    ),
    REJECT: (
      <Tooltip
        color="#1a337d"
        overlayInnerStyle={{
          boxShadow: '2px 4px 12px 0px #182a331f',
          fontSize: '13px',
          borderRadius: '4px',
        }}
        placement="bottom"
        title="Unfortunately, the data provider has rejected your access request. Please contact Mobito in order to source the right data for you"
      >
        <StatusTag background="#ffe9e9" borderColor="#fd5f5d">
          <Delete
            style={{
              height: '24px',
              color: '#fd5f5d',
              paddingRight: '2px',
            }}
          />
          Rejected by provider
        </StatusTag>
      </Tooltip>
    ),
    REVIEW: (
      <Tooltip
        color="#1a337d"
        overlayInnerStyle={{
          boxShadow: '2px 4px 12px 0px #182a331f',
          fontSize: '13px',
          borderRadius: '4px',
        }}
        placement="bottom"
        title="Your access request has been sent to the data provider for a review"
      >
        <StatusTag background="#fff3d6" borderColor="#ffc540">
          <ClockFill
            style={{
              height: '16px',
              color: '#ffc540',
              paddingRight: '7px',
            }}
          />
          Pending review by provider
        </StatusTag>
      </Tooltip>
    ),
  };

  const nameTrimHandler = (name) => {
    let maxLength = 30;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  const imageUrl = getThumbnailURL(source.image_url);

  const openPackageCheckout = () => {
    setVisible(false);
    setIsPackageModalVisible(false);
    setPackPayModalVisible(true);
  };

  const openCheckout = () => {
    setVisible(false);
    setIsRequestModalVisible(false);
    setReqPayModalVisible(true);
  };

  const drawerFree = () => {
    setVisible(false);
    setIsFreeModalVisible(false);
    setFreePayModalVisible(true);
  };

  const showMaskedRules = () => {
    setVisible(true);
  };

  useEffect(() => {
    const fetchEndpoints = async () => {
      let endpoints = await axios.get(
        `/api/data-placements/editable/${source.id}/origin`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      let maskRules = await axios.get(
        `/api/resource-connection/mask-rules?id=${source.resource_connection_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (endpoints && maskRules) {
        setEndpoints(
          endpoints.data.proxy.platform_resource_proxy_api_endpoints.sort((b) =>
            b.applied_tags === null ? 1 : -1,
          ),
        );
        setSavedRules(maskRules.data);
      }
    };
    fetchEndpoints();
  }, []);

  const handleMenuClick = () => {
    if (charge_type === 'per_request') {
      setIsRequestModalVisible(true);
    } else if (charge_type === 'per_month') {
      setIsPackageModalVisible(true);
    } else if (charge_type === 'free') {
      setIsFreeModalVisible(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Consumption Analytics</Menu.Item>
    </Menu>
  );

  const handleOnViewDocumentation = () => {
    window.open(
      `/data-product/${source.name
        .split(' ')
        .join('-')
        .toLowerCase()}/documentation`,
      '_blank',
    );
  };

  return (
    <ThemeProvider theme={withoutColPadding}>
      <Wrap middle="xs">
        <div style={{ display: 'flex', width: '35%', alignItems: 'center' }}>
          <img
            width="83"
            height="84"
            alt="mobility-module-image"
            src={imageUrl}
            style={{
              marginRight: '20px',
              padding: '10px',
            }}
          />
          <Flexcol
            style={{
              height: '100px',
            }}
          >
            <Flexrow center_align style={{ marginBottom: '12px' }}>
              <Text
                style={{
                  fontSize: '13px',
                  color: '#0494dd',
                  fontWeight: '600',
                }}
              >
                DATA PRODUCT
              </Text>
              {lightVersionLabel && (
                <LightVersionLabel>{lightVersionLabel}</LightVersionLabel>
              )}
              {listingType(source.type_id)}
            </Flexrow>
            <Flexrow
              center_align
              space_between
              style={{ marginBottom: '12px' }}
            >
              <Link to={`/data-product/${source.url_id}`} target="_blank">
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  overlayStyle={{ maxWidth: 'fit-content' }}
                  placement="bottom"
                  title={
                    <div>
                      {source.name}
                      <NewTab style={{ height: '16px', marginLeft: '12px' }} />
                    </div>
                  }
                >
                  <SourceTitle>{nameTrimHandler(source.name)}</SourceTitle>
                </Tooltip>
              </Link>
            </Flexrow>
            <Flexrow center_align>
              <TurnedIn
                size="16"
                style={{ color: '#c4c4c4', marginRight: '2px' }}
              />
              <Text
                style={{
                  color: '#64737f',
                  fontSize: '15px',
                  letterSpacing: '-0.5px',
                }}
              >
                {source['data_placement_project.project_data_category.name']}
              </Text>
            </Flexrow>
          </Flexcol>
        </div>
        <div
          style={{
            width: '35%',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title={
              <div>
                Opens in new tab
                <NewTab style={{ height: '16px', marginLeft: '12px' }} />
              </div>
            }
          >
            <HoverWrap
              center_align
              center_content
              style={{
                marginRight: '20px',
                padding: '5px',
              }}
              onClick={handleOnViewDocumentation}
            >
              <DocumentText
                size="24"
                style={{ color: '#182429', height: '36px' }}
              />
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '14x',
                  letterSpacing: '-0.5px',
                }}
              >
                Documentation
              </Text>
            </HoverWrap>
          </Tooltip>
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title="Open consumption analytics"
          >
            <HoverWrap
              center_align
              center_content
              style={{
                marginRight: '20px',
                padding: '5px',
              }}
              onClick={handleMenuClick}
            >
              <Text
                style={{
                  color: '#182429',
                  fontSize: '24px',
                  height: '36px',
                }}
              >
                {consumption ? consumption.num_requests : null}
              </Text>
              <Text style={{ color: '#64737f', fontSize: '14px' }}>
                requests consumed
              </Text>
            </HoverWrap>
          </Tooltip>
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title="See API key"
          >
            <HoverWrap
              center_align
              center_content
              style={{
                marginRight: '20px',
                padding: '5px',
                width: '60px',
              }}
              onClick={() => openKeyModal(source.access_key)}
            >
              <Key size="32" style={{ color: '#182429', height: '36px' }} />
              {source.access_key ? (
                <button style={{ padding: '0px' }}>
                  <Text style={{ color: '#64737F', fontSize: '14px' }}>
                    API key
                  </Text>
                </button>
              ) : (
                <Text style={{ color: '#64737F', fontSize: '14px' }}>-</Text>
              )}
            </HoverWrap>
          </Tooltip>
        </div>
        {source.status_name == 'ACTIVE' && (
          <div
            style={{
              display: 'flex',
              width: '30%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '40px',
            }}
          >
            <Flexcol center_align center_content right="10px">
              {charge_type == 'free' && Object.keys(savedRules).length > 0 && (
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title="View the masking rules of this data product."
                >
                  <StatusTag
                    background="#FFF3D680"
                    borderColor="#FFF3D680"
                    hoverBackground="#FFEAB9"
                    textColor="#F0A81C"
                    cursor
                    onClick={showMaskedRules}
                  >
                    <img
                      src={MaskRulesImgSrc}
                      style={{
                        paddingRight: '7px',
                      }}
                    />
                    View mask rules
                  </StatusTag>
                </Tooltip>
              )}
              {charge_type != 'free' && Object.keys(savedRules).length > 0 && (
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title="View the masking rules of this data product."
                >
                  <StatusTag
                    background="#FFF3D680"
                    borderColor="#FFF3D680"
                    hoverBackground="#FFEAB9"
                    textColor="#F0A81C"
                    cursor
                    onClick={showMaskedRules}
                  >
                    <img
                      src={MaskRulesImgSrc}
                      style={{
                        paddingRight: '7px',
                      }}
                    />
                    View mask rules
                  </StatusTag>
                </Tooltip>
              )}
            </Flexcol>
            <Flexcol center_align center_content>
              {charge_type !== 'free' && (
                <>
                  {charge_type == 'per_month' && (
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title={
                        currentUserRole === 'MEMBER' &&
                        'You do not have the access rights to perform this action.'
                      }
                    >
                      <BuyMoreButton
                        size="large"
                        onClick={() => openPackageCheckout()}
                        disabled={currentUserRole === 'MEMBER'}
                      >
                        Buy more
                      </BuyMoreButton>
                    </Tooltip>
                  )}
                  {charge_type == 'per_request' && (
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title={
                        currentUserRole === 'MEMBER' &&
                        'You do not have the access rights to perform this action.'
                      }
                    >
                      <BuyMoreButton
                        size="large"
                        onClick={() => openCheckout()}
                        disabled={currentUserRole === 'MEMBER'}
                      >
                        Buy more
                      </BuyMoreButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Flexcol>
            <Elements stripe={stripePromise}>
              {pricing_bundles && (
                <PackageCheckout
                  pricing_bundles={pricing_bundles}
                  source={source}
                  project_id={project_id}
                  setApiKey={setApiKey}
                  imageUrl={imageUrl}
                  openPackageCheckout={openPackageCheckout}
                  endpoints={endpoints}
                  savedRules={savedRules}
                  packPayModalVisible={packPayModalVisible}
                  setPackPayModalVisible={setPackPayModalVisible}
                />
              )}
            </Elements>
            <Elements stripe={stripePromise}>
              {pricing_bundles && (
                <Checkout
                  pricing_bundle={pricing_bundles[0]}
                  source={source}
                  project_id={project_id}
                  setApiKey={setApiKey}
                  imageUrl={imageUrl}
                  openCheckout={openCheckout}
                  endpoints={endpoints}
                  savedRules={savedRules}
                  reqPayModalVisible={reqPayModalVisible}
                  setReqPayModalVisible={setReqPayModalVisible}
                />
              )}
            </Elements>
            <MaskedRulesModal
              imageUrl={imageUrl}
              name={source.name}
              setVisible={setVisible}
              visible={visible}
              openCheckout={openCheckout}
              status={source.status_name}
              endpoints={endpoints}
              savedRules={savedRules}
              chargeType={charge_type}
            />
            <Flexcol>
              <Dropdown overlay={menu}>
                <DotsVerticalRounded
                  style={{
                    width: '25px',
                    color: '#64737F',
                    position: 'absolute',
                    right: '0px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </Dropdown>
            </Flexcol>
            {charge_type == 'per_request' && (
              <ConsumptionModalRequest
                imageUrl={imageUrl}
                name={source.name}
                status={source.status_name}
                openCheckout={openCheckout}
                resource_connection_id={source.resource_connection_id}
                setIsRequestModalVisible={setIsRequestModalVisible}
                isRequestModalVisible={isRequestModalVisible}
              />
            )}
            {charge_type == 'per_month' && (
              <ConsumptionModalPackage
                imageUrl={imageUrl}
                name={source.name}
                status={source.status_name}
                openPackageCheckout={openPackageCheckout}
                resource_connection_id={source.resource_connection_id}
                setIsPackageModalVisible={setIsPackageModalVisible}
                isPackageModalVisible={isPackageModalVisible}
              />
            )}
            {charge_type == 'free' && (
              <ConsumptionModalFree
                imageUrl={imageUrl}
                name={source.name}
                status={source.status_name}
                source={source}
                project_id={project_id}
                drawerFree={drawerFree}
                resource_connection_id={source.resource_connection_id}
                setIsFreeModalVisible={setIsFreeModalVisible}
                isFreeModalVisible={isFreeModalVisible}
              />
            )}
          </div>
        )}
        {source.status_name !== 'PENDING_PAYMENT' &&
          source.status_name !== 'ACTIVE' && (
            <div
              style={{
                display: 'flex',
                width: '30%',
                justifyContent: 'flex-end',
              }}
            >
              <SText text_16 black bold>
                {statusName[source.status_name]}
              </SText>
            </div>
          )}
        {source.status_name === 'PENDING_PAYMENT' && charge_type == 'free' && (
          <div
            style={{
              display: 'flex',
              width: '30%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '40px',
            }}
          >
            <Flexrow>
              <Flexcol center_align center_content right="10px">
                {Object.keys(savedRules).length > 0 && (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title="View the masking rules of this data product."
                  >
                    <StatusTag
                      background="#FFF3D680"
                      borderColor="#FFF3D680"
                      hoverBackground="#FFEAB9"
                      textColor="#F0A81C"
                      cursor
                      onClick={showMaskedRules}
                    >
                      <img
                        src={MaskRulesImgSrc}
                        style={{
                          paddingRight: '7px',
                        }}
                      />
                      View mask rules
                    </StatusTag>
                  </Tooltip>
                )}
              </Flexcol>
              <Flexcol>
                <GenerateKeyButton size="large" onClick={() => drawerFree()}>
                  Generate Key
                </GenerateKeyButton>
              </Flexcol>
              <Dropdown overlay={menu}>
                <DotsVerticalRounded
                  style={{
                    width: '25px',
                    color: '#64737F',
                    position: 'absolute',
                    right: '0px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </Dropdown>
              <CheckoutFree
                source={source}
                project_id={project_id}
                setApiKey={setApiKey}
                imageUrl={imageUrl}
                pricingBundle={pricing_bundles[0]}
                openCheckout={drawerFree}
                endpoints={endpoints}
                savedRules={savedRules}
                freePayModalVisible={freePayModalVisible}
                setFreePayModalVisible={setFreePayModalVisible}
              />
              <MaskedRulesModal
                imageUrl={imageUrl}
                name={source.name}
                setVisible={setVisible}
                visible={visible}
                openCheckout={drawerFree}
                status={source.status_name}
                endpoints={endpoints}
                savedRules={savedRules}
                chargeType={charge_type}
              />
              {charge_type == 'free' && (
                <ConsumptionModalFree
                  imageUrl={imageUrl}
                  name={source.name}
                  source={source}
                  project_id={project_id}
                  drawerFree={drawerFree}
                  resource_connection_id={source.resource_connection_id}
                  setIsFreeModalVisible={setIsFreeModalVisible}
                  isFreeModalVisible={isFreeModalVisible}
                />
              )}
            </Flexrow>
          </div>
        )}
        {source.status_name === 'PENDING_PAYMENT' &&
          charge_type == 'per_request' && (
            <div
              style={{
                display: 'flex',
                width: '30%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '40px',
              }}
            >
              <Flexrow>
                <Flexcol center_align center_content right="10px">
                  {Object.keys(savedRules).length > 0 && (
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title="View the masking rules of this data product."
                    >
                      <StatusTag
                        background="#FFF3D680"
                        borderColor="#FFF3D680"
                        hoverBackground="#FFEAB9"
                        textColor="#F0A81C"
                        cursor
                        onClick={showMaskedRules}
                      >
                        <img
                          src={MaskRulesImgSrc}
                          style={{
                            paddingRight: '7px',
                          }}
                        />
                        View mask rules
                      </StatusTag>
                    </Tooltip>
                  )}
                </Flexcol>
                <Flexcol>
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                  >
                    <PayToActivateButton
                      onClick={() => openCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Pay to Activate
                    </PayToActivateButton>
                  </Tooltip>
                </Flexcol>
                <Dropdown overlay={menu}>
                  <DotsVerticalRounded
                    style={{
                      width: '25px',
                      color: '#64737F',
                      position: 'absolute',
                      right: '0px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </Dropdown>
                <Elements stripe={stripePromise}>
                  {pricing_bundles && (
                    <Checkout
                      pricing_bundle={pricing_bundles[0]}
                      source={source}
                      project_id={project_id}
                      setApiKey={setApiKey}
                      imageUrl={imageUrl}
                      openCheckout={openCheckout}
                      endpoints={endpoints}
                      savedRules={savedRules}
                      reqPayModalVisible={reqPayModalVisible}
                      setReqPayModalVisible={setReqPayModalVisible}
                    />
                  )}
                </Elements>
                <MaskedRulesModal
                  imageUrl={imageUrl}
                  name={source.name}
                  setVisible={setVisible}
                  visible={visible}
                  openCheckout={openCheckout}
                  status={source.status_name}
                  endpoints={endpoints}
                  savedRules={savedRules}
                  chargeType={charge_type}
                />
                {charge_type == 'per_request' && (
                  <ConsumptionModalRequest
                    imageUrl={imageUrl}
                    name={source.name}
                    status={source.status_name}
                    openCheckout={openCheckout}
                    resource_connection_id={source.resource_connection_id}
                    setIsRequestModalVisible={setIsRequestModalVisible}
                    isRequestModalVisible={isRequestModalVisible}
                  />
                )}
              </Flexrow>
            </div>
          )}
        {source.status_name === 'PENDING_PAYMENT' &&
          charge_type == 'per_month' && (
            <div
              style={{
                display: 'flex',
                width: '30%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '40px',
              }}
            >
              <Flexrow>
                <Flexcol center_align center_content right="10px">
                  {Object.keys(savedRules).length > 0 && (
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title="View the masking rules of this data product."
                    >
                      <StatusTag
                        background="#FFF3D680"
                        borderColor="#FFF3D680"
                        hoverBackground="#FFEAB9"
                        textColor="#F0A81C"
                        cursor
                        onClick={showMaskedRules}
                      >
                        <img
                          src={MaskRulesImgSrc}
                          style={{
                            paddingRight: '7px',
                          }}
                        />
                        View mask rules
                      </StatusTag>
                    </Tooltip>
                  )}
                </Flexcol>
                <Flexcol>
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                  >
                    <PayToActivateButton
                      size="large"
                      onClick={() => openPackageCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Pay to Activate
                    </PayToActivateButton>
                  </Tooltip>
                </Flexcol>
                <Dropdown overlay={menu}>
                  <DotsVerticalRounded
                    style={{
                      width: '25px',
                      color: '#64737F',
                      position: 'absolute',
                      right: '0px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </Dropdown>
                <Elements stripe={stripePromise}>
                  {pricing_bundles && (
                    <PackageCheckout
                      pricing_bundles={pricing_bundles}
                      source={source}
                      project_id={project_id}
                      setApiKey={setApiKey}
                      imageUrl={imageUrl}
                      openPackageCheckout={openPackageCheckout}
                      endpoints={endpoints}
                      savedRules={savedRules}
                      packPayModalVisible={packPayModalVisible}
                      setPackPayModalVisible={setPackPayModalVisible}
                    />
                  )}
                </Elements>
                <MaskedRulesModal
                  imageUrl={imageUrl}
                  name={source.name}
                  setVisible={setVisible}
                  visible={visible}
                  openCheckout={openPackageCheckout}
                  status={source.status_name}
                  endpoints={endpoints}
                  savedRules={savedRules}
                  chargeType={charge_type}
                />
                {charge_type == 'per_month' && (
                  <ConsumptionModalPackage
                    imageUrl={imageUrl}
                    name={source.name}
                    status={source.status_name}
                    openPackageCheckout={openPackageCheckout}
                    resource_connection_id={source.resource_connection_id}
                    setIsPackageModalVisible={setIsPackageModalVisible}
                    isPackageModalVisible={isPackageModalVisible}
                  />
                )}
              </Flexrow>
            </div>
          )}
      </Wrap>
    </ThemeProvider>
  );
};

export default SourceCard;
