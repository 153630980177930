import React from "react";

import useStep from "../hooks/useStep";
import useForm from "../hooks/useFormStore";
import Email from "../components/forgot-password/Email";
import Notified from "../components/forgot-password/Notified";

const steps = [{ id: "email" }, { id: "notified" }];

const defaultData = { email: "" };

// Remove access for logged in users.

export const ForgotPassword = () => {
  const [errors, setErrors, form_data, setForm] = useForm(defaultData);
  let { step, navigation } = useStep({
    initialStep: 0,
    steps,
  });

  let { id: step_id } = step;

  const props = {
    step_id,
    errors,
    setErrors,
    form_data,
    setForm,
    navigation,
  };

  const renderStep = () => {
    switch (step_id) {
      case "email":
        return <Email {...props} />;
      case "notified":
        return <Notified {...props} />;
      default:
        return null;
    }
  };

  // TODO
  /* <Head> 
    <script src="https://www.google.com/recaptcha/api.js?render=reCAPTCHA_site_key" />
    </Head> */
  return renderStep();
};
