import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsHeaderSmall,
  TermsAndConditionsHeaderXSmall,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsLink,
} from '../../styles/termsAndConditions';

const PricingAndPaymentTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
        <TermsAndConditionsHeaderLarge marginLeft smaller>
          P
        </TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>RICING </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>ND </TermsAndConditionsHeaderMedium>
        <TermsAndConditionsHeaderLarge smaller>P</TermsAndConditionsHeaderLarge>
        <TermsAndConditionsHeaderMedium>AYMENT</TermsAndConditionsHeaderMedium>
        <TermsAndConditionsEmptySpace />
        <TermsAndConditionsOrderedList>
          <TermsAndConditionsHeaderSmall>General</TermsAndConditionsHeaderSmall>
          <TermsAndConditionsEmptySpace />
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                In consideration of the provision of the Platform and Services
                by Mobito, the Client agrees to pay the fees set forth on the
                Platform or in the relevant Order Form.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                All prices are in EUR and are exclusive of VAT and other taxes
                that may be imposed.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                The Client shall bear all banking and currency exchange charges
                including exchange fees and transfer fees.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                When Mobito offers the option for the Data Customer to pay via
                the Platform and the option for the Data Provider to pay and be
                paid via the Platform, Mobito will make use of a third party
                provider. The third party provider shall be responsible for
                ensuring the payment is executed. The third party payment
                provider’s terms and conditions will apply as from the moment
                the Client leaves the Platform and enters the third party
                payment provider’s environment.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                In case the Client opts to pay via invoice and notwithstanding
                anything to the contrary in the Order Form, Mobito shall issue
                the invoice as soon as possible, but no later than within
                fifteen (15) days following the end of respective calendar
                month. Invoices are due and payable within thirty (30) days
                after the invoicing date. In addition to any other legal
                remedies Mobito may have (e.g. right to late interest), Mobito
                shall, in case of late payment, be entitled to claim from the
                Client any sums it had to spend in order to receive payment
                (e.g. judicial or extrajudicial costs).
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsHeaderSmall>
            Payment by the Data Customer to Mobito (for Platform Services)
          </TermsAndConditionsHeaderSmall>
          <TermsAndConditionsEmptySpace />
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Payment to Mobito will be due as defined in the Data Listing or
                the applicable Order Form. In the event the Data Customer fails
                to meet the payment terms, the exchange of Data and the
                provision of the Services will be suspended until payment, as
                set out in the Data Agreement or Order Form, has been received.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsHeaderSmall>
            Payment by Mobito to the Data Provider (for Platform Services)
          </TermsAndConditionsHeaderSmall>
          <TermsAndConditionsEmptySpace />
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Mobito will, unless stated otherwise in the Order Form,
                compensate the Data Provider based on the pricing set by the
                Data Provider on a EURO/API request or other agreed upon unit or
                pricing scheme and the total underlying consumption of this
                Data. Consumption of this Data may occur directly or indirectly
                through the consumption of the offered Data. The Data Provider’s
                compensation (minus the margin) will take place on a monthly
                basis within the first fifteen (15) days of the start of the
                month for the preceding month
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsHeaderSmall>
            Margin charged by Mobito to the Data Provider (for Platform
            Services)
          </TermsAndConditionsHeaderSmall>
          <TermsAndConditionsEmptySpace />
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                In the event of consumption of Data Provider’s raw Data by a
                Data Customer, Mobito will charge {''}
                <TermsAndConditionsHeaderXSmall>
                  a margin equal to the percentage, as set out in the following
                  link: {''}
                </TermsAndConditionsHeaderXSmall>
                <TermsAndConditionsLink
                  href="https://www.mobito.io/pricing"
                  target="_blank"
                >
                  https://www.mobito.io/pricing
                </TermsAndConditionsLink>
                . For the avoidance of doubt, in any given completed
                transaction, the Data Provider will receive the full price of
                the Listing minus the margin, which will be earned by Mobito.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                Mobito reserves the right to modify the margin charged and the
                price of the Services. Any change to the margin and the price of
                the Services will be communicated in advance and such notice
                will also include when the new margin and new price of the
                Services will become effective. In the event the Data Provider
                does not agree with the changed margin or the modified prices of
                the Services, it will notify Mobito thereof prior to the new
                margin and new prices of the Services coming into effect.
                Provided no agreement can be reached, the Data Provider will
                have the right to terminate the Agreement, immediately, without
                any compensation for termination being due.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
          <TermsAndConditionsHeaderSmall>
            Payment of Data Provider Charges (for Platform Services)
          </TermsAndConditionsHeaderSmall>
          <TermsAndConditionsEmptySpace />
          <TermsAndConditionsOrderedListItem flex inner>
            <TermsAndConditionsListSection>
              <TermsAndConditionsParagraph>
                The Data Provider will be held to pay the amounts for the
                functionalities of every subscription tier that correspond to
                the chosen subscription model (set out on{' '}
                <TermsAndConditionsLink
                  href="https://mobito.io/pricing"
                  target="_blank"
                >
                  https://mobito.io/pricing
                </TermsAndConditionsLink>
                ). Unless agreed otherwise, the Data Provider will start from a
                free subscription model and have the option to upgrade on the
                Platform.
              </TermsAndConditionsParagraph>
            </TermsAndConditionsListSection>
          </TermsAndConditionsOrderedListItem>
        </TermsAndConditionsOrderedList>
      </TermsAndConditionsOrderedListItem>
  );
};

export default PricingAndPaymentTerms;
