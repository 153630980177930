import { Row, Col } from 'react-styled-flexboxgrid';
import useSWR from 'swr';
import Cookies from 'js-cookie';
import { Spin } from 'antd';

import { SText } from '../styled-text/text';
import { fetcherWithToken } from '../../utils/fetcher';
import AccessRequestCard from './AccessRequestCard';
import { data_placement_type_ids } from '../../iso-shared/values/global';
import S3AccessRequestCard from './S3AccessRequestCard';
import S3NewAccessRequestCard from './S3NewAccessRequestCard';

const AccessControl = ({ project_id }) => {
  const token = Cookies.get('jwt');

  let { data: requests, requestsErrors } = useSWR(
    [`/api/access-requests?project_id=${project_id}`, token],
    fetcherWithToken,
  );

  let { data: s3DataRequests, s3RequestsErrors } = useSWR(
    [`/api/access-requests/project/${project_id}`, token],
    fetcherWithToken,
  );

  let { data: module } = useSWR(
    project_id
      ? [`/api/data-connection-metadata?project_id=${project_id}`, token]
      : null,
    fetcherWithToken,
  );

  data_placement_type_ids;

  if (requestsErrors) return <div>{requestsErrors}</div>;
  if (s3RequestsErrors) return <div>{s3RequestsErrors}</div>;
  if (!requests || !s3DataRequests)
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row>
            <SText text_20 black extra_bold>
              {`Access Requests`}
            </SText>
          </Row>
          <Row>
            <SText text_16 black medium>
              {`Check new access requests, enable and disable access to consumer company accounts`}
            </SText>
          </Row>
        </Col>
      </Row>
      {requests.map((request) => {
        return (
          <Row key={request.id} style={{ marginTop: 12 }}>
            <Col xs={12}>
              {module &&
                module.type_id == data_placement_type_ids.s3_bucket && (
                  <S3AccessRequestCard request={request} />
                )}
              {module &&
                module.type_id != data_placement_type_ids.s3_bucket && (
                  <AccessRequestCard module={module} request={request} />
                )}
            </Col>
          </Row>
        );
      })}
      {s3DataRequests.map((s3DataRequest) => {
        return (
          <Row key={s3DataRequest.id} style={{ marginTop: 12 }}>
            <Col xs={12}>
              <S3NewAccessRequestCard
                request={s3DataRequest}
                dataRoomId={s3DataRequest.data_room_id}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default AccessControl;
