import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { STab, STabs, STabList, STabPanel } from '../components/tabs';
import React, { useState, useContext } from 'react';
import RequestSummaryModule from './RequestSummaryModule';
import FreeSummaryModule from './FreeSummaryModule';
import PackageSummaryModule from './PackageSummaryModule';
import { InsertDriveFile, CloudDownload } from 'styled-icons/material';
import { limitFileName } from '../utils/utils';
import { PlacementContext } from '../helpers/PlacementWizardHelper';
import { SText } from '../components/styled-text/text';
import { getDocFileURL } from '../iso-shared/utils/getDocFileUrl';
import { colors } from '../values/colors';
import { S3_DATA } from '../iso-shared/values/global';

const SRow = styled(Row)`
  padding: 10px;
`;

const SLCol = styled(Col)`
  margin-right: 10px;
`;

const SSummaryContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 12px 20px 0px rgba(210, 210, 210, 0.5);
  background: rgb(255, 255, 255);
`;

const Filename = styled((p) => <SText text_16 black extra_bold {...p} />)`
  :hover {
    color: ${colors.orange_4};
    text-decoration: underline;
  }
`;

const statusName = {
  '7163bce3-f8f1-4e84-9d5c-d76054eaa6cc': 'Under Review',
  '675d2659-3f87-4140-92c4-7e7fe6e28bdb': 'Published',
};

const SummaryWizard = ({ module }) => {
  const [isSummary, setIsSummary] = useState(true);
  const { project } = module;
  const helper = useContext(PlacementContext);
  const onChangeTab = async (value) => {
    value === 'summary' ? setIsSummary(true) : setIsSummary(false);
  };

  return (
    <SSummaryContainer>
      <STabs>
        <Row style={{ padding: 10 }}>
          <STabList>
            <STab
              style={
                isSummary
                  ? { textDecoration: 'underline', color: '#ff7f21', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
              onClick={() => onChangeTab('summary')}
            >
              <SText text_24 black extra_bold>
                Summary
              </SText>
            </STab>
          </STabList>
          {module.project.placement.type_id !== S3_DATA && (
            <STabList
              style={
                !isSummary
                  ? { textDecoration: 'underline', color: '#ff7f21', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
              onClick={() => onChangeTab('pricing')}
            >
              <SText text_24 black extra_bold>
                Pricing
              </SText>
            </STabList>
          )}
        </Row>
        {isSummary ? (
          <>
            <STabPanel>
              <Row style={{ padding: 10 }} between="xs">
                <Col>
                  <SText text_16 semi medium>
                    Status
                  </SText>
                </Col>
                <Col>
                  <SText text_16 black extra_bold>
                    {statusName[module.project.placement.status_id]
                      ? statusName[module.project.placement.status_id]
                      : module.project.placement.error_msg}
                  </SText>
                </Col>
              </Row>
              <Row style={{ padding: 10 }} between="xs">
                <Col>
                  <SText text_16 semi medium>
                    Listing Type
                  </SText>
                </Col>
                <Col>
                  <SText text_16 black extra_bold>
                    {module.project.proxy && `Proxy API`}
                    {module.project.files && `File`}
                    {module.project.sample_files && `S3 bucket`}
                  </SText>
                </Col>
              </Row>
              <Row style={{ padding: 10 }} between="xs">
                <Col>
                  <SText text_16 semi medium>
                    Historical data
                  </SText>
                </Col>
                <Col>
                  <SText text_16 black extra_bold>
                    {module.project.placement.historical_data ? `Yes` : `None`}
                  </SText>
                </Col>
              </Row>
              <Row style={{ padding: 10 }} between="xs">
                <Col>
                  <SText text_16 semi medium>
                    Analytics
                  </SText>
                </Col>
                <Col>
                  <SText text_16 black extra_bold>
                    {module.project.placement.analytics ? `Yes` : `None`}
                  </SText>
                </Col>
              </Row>
              <Row style={{ padding: 10 }} between="xs">
                <Col>
                  <SText text_16 semi medium>
                    License Type
                  </SText>
                </Col>
                <Col>
                  <Link to={`/license/${module.project.placement.id}`}>
                    <SText text_16 orange extra_bold underline>
                      license
                    </SText>
                  </Link>
                </Col>
              </Row>
              <Row style={{ padding: 10 }} between="xs">
                <Col>
                  <SText text_16 semi medium>
                    Documentation
                  </SText>
                </Col>
                <>
                  {module.project.placement.type_id !== S3_DATA && (
                    <Col>
                      <Link to={`/docs/${module.project.placement.id}`}>
                        <SText text_16 orange extra_bold underline>
                          see docs
                        </SText>
                      </Link>
                    </Col>
                  )}
                </>
                {module.project.placement.type_id === S3_DATA && (
                  <Col xs={12} style={{ paddingTop: 24 }}>
                    <>
                      {module.project.placement.type_id === S3_DATA &&
                        module.project.doc_files &&
                        module.project.doc_files.map((item) => {
                          return (
                            <Row style={{ marginBottom: '18px' }}>
                              <Col xs={1}>
                                <InsertDriveFile size="24" style={{ color: colors.orange_4 }} />
                              </Col>
                              <Col style={{ display: 'flex' }} xs={10}>
                                <a href={getDocFileURL(item.file_id)}>
                                  <Filename>{limitFileName(item.file_name)}</Filename>
                                </a>
                              </Col>
                              <Col xs={1}>
                                <a href={getDocFileURL(item.file_id)}>
                                  <CloudDownload size="24" style={{ color: colors.orange_4 }} />
                                </a>
                              </Col>
                            </Row>
                          );
                        })}
                    </>
                  </Col>
                )}
              </Row>
            </STabPanel>
            <STabPanel>
              <SRow between="lg">
                <SLCol>
                  <SText text_16 semi medium>
                    Test
                  </SText>
                </SLCol>
                <SText text_16 black extra_bold>
                  Test
                </SText>
              </SRow>
            </STabPanel>
          </>
        ) : helper &&
          helper.packages &&
          helper.packages.value[0] &&
          helper.packages.value[0].charge_type === 'per_request' ? (
          <RequestSummaryModule module={module} requestValues={helper.packages.value[0]} />
        ) : helper &&
          helper.packages &&
          helper.packages.value[0] &&
          helper.packages.value[0].charge_type === 'free' ? (
          <FreeSummaryModule freeValues={helper.packages.value[0]} />
        ) : helper &&
          helper.packages &&
          helper.packages.value[0] &&
          helper.packages.value[0].charge_type === 'per_month' ? (
          <PackageSummaryModule module={module} packageValues={helper.packages.value} />
        ) : null}
      </STabs>
    </SSummaryContainer>
  );
};

export default SummaryWizard;
