import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import useSWR from 'swr';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { Tabs, Typography, Spin, Tooltip, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { fetcherWithToken } from '../utils/fetcher';
import { colors } from '../values/colors';
import Header from '../components/Header';
import LayoutRow from '../components/LayoutRow';
import ProjectCard from '../components/dashboard/ProjectCard';
import CreateProjectCard from '../components/dashboard/CreateProjectCard';
import { data_placement_status_ids } from '../iso-shared/values/global';
import FlagImgSource from '../images/flag.svg';
import BuildingSrc from '../images/company_logo.svg';
import S3BucketSrc from '../images/s3_bucket.svg';
import ApiSrc from '../images/api.svg';
import { Flexrow, Flexcol } from '../components/Flexbox';

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const StartHereWrap = styled.div`
  background: #64737f00;
  border: 1px dashed #64737f;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  cursor: ${(props) => !props.connectionProject && 'pointer'};
  padding: 50px 0px;
  &:hover {
    background: #64737f0a;
  }
`;

const CreateNewButton = styled.div`
  background-color: #344ef3;
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  height: fit-content;
  color: #fff;
`;

const ProductsListItemImg = styled.img`
  margin-right: 12px;
  height: 16px;
`;

const ProductListItemHeader = styled.p`
  font-weight: 600;
  margin-bottom: 0;
`;

const ProductListItemSubHeader = styled.p`
  margin-bottom: 0;
`;

const ProductsListItem = styled(Flexrow)`
  padding: 12px;
  ${ProductListItemHeader} {
    color: #182429;
  }
  ${ProductListItemSubHeader} {
    color: #64737f;
  }
  ${ProductsListItemImg} {
    filter: brightness(0);
  }
  &:hover {
    ${ProductListItemHeader}, ${ProductListItemSubHeader} {
      color: #344ef3 !important;
    }
    ${ProductsListItemImg} {
      filter: brightness(1);
    }
  }
`;

const Dashboard = () => {
  const [connectionProjects, setConnectionProjects] = useState([]);
  const [dataRoomProjects, setDataRoomProjects] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState([]);
  const token = Cookies.get('jwt') || '';
  const account = JSON.parse(Cookies.get('account'));
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const onChange = (key) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get('/api/data-connections', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConnectionProjects(res.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetchDataRooms = async () => {
      const res = await axios.get(`/api/data-rooms/organization`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.data) {
        const consumerDataRooms = () => {
          const organizationId = Cookies.get('organization_id') || '';

          return res.data.filter((dataRoom) => {
            return dataRoom.consumer_id === organizationId;
          });
        };
        setDataRoomProjects(consumerDataRooms());
      }
    };
    fetchDataRooms();
  }, []);

  let { data: consumption_projects } = useSWR(
    ['/api/data-consumptions', token],
    fetcherWithToken,
  );

  let { data: request_projects } = useSWR(
    ['/api/data-requests', token],
    fetcherWithToken,
  );

  const deleteHandler = (id) => {
    const updatedProjects = connectionProjects.filter((p) => p.id !== id);
    setConnectionProjects(updatedProjects);
  };

  let activeKey = history.location.state && history.location.state.key;

  const newConsumption = localStorage.getItem('new-consumption');

  useEffect(() => {
    if (activeTabKey === '2') {
      localStorage.removeItem('new-consumption');
    }
    if (activeKey === '2') {
      setTimeout(() => {
        localStorage.removeItem('new-consumption');
      }, 3000);
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (
      !!connectionProjects ||
      !!consumption_projects ||
      !!request_projects ||
      !!dataRoomProjects
    )
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
  }, [
    connectionProjects,
    consumption_projects,
    request_projects,
    dataRoomProjects,
  ]);

  const createRequestProject = async () => {
    const new_id = uuid();
    history.push(`/project/data-request-wizard/${new_id}`);
  };

  const handleMenuClick = async ({ key }) => {
    const new_id = uuid();

    if (key === 's3') {
      history.push({
        pathname: `/project/data-wizard/${new_id}`,
        state: { key: key },
      });
    } else {
      history.push(`/project/data-wizard/${new_id}`);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="api">
        <ProductsListItem center_align>
          <ProductsListItemImg src={ApiSrc} />
          <Flexcol>
            <ProductListItemHeader>Proxy API</ProductListItemHeader>
            <ProductListItemSubHeader>
              Create a new Proxy API data product
            </ProductListItemSubHeader>
          </Flexcol>
        </ProductsListItem>
      </Menu.Item>
      <Menu.Item key="s3">
        <ProductsListItem center_align>
          <ProductsListItemImg src={S3BucketSrc} />
          <Flexcol>
            <ProductListItemHeader>S3 Bucket</ProductListItemHeader>
            <ProductListItemSubHeader>
              Create a new S3 Bucket data product
            </ProductListItemSubHeader>
          </Flexcol>
        </ProductsListItem>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <LayoutRow background={'#fff'} withBorder>
        <Header stepsFor="DASHBOARD" />
      </LayoutRow>
      <LayoutRow background={'#fff'} withShadow>
        <Text
          strong
          style={{
            margin: '18px 0px',
            color: '#182429',
            display: 'inline-block',
            fontSize: '15px',
            fontWeight: '600',
          }}
          className="dashboard-step"
        >
          Dashboard
        </Text>
        <img
          src={BuildingSrc}
          style={{ marginLeft: '32px', marginRight: '10px' }}
        />
        <Text
          style={{
            color: '#64737F',
            fontSize: '13px',
          }}
        >
          {account.organization.name}
        </Text>
      </LayoutRow>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: 'auto' }}>
          <Spin size="large" />
        </div>
      ) : (
        <LayoutRow>
          <Tabs
            defaultActiveKey={activeKey ? activeKey : '1'}
            style={{ margin: '48px 0' }}
            onChange={onChange}
          >
            <TabPane tab="Data products" key="1">
              <Flexrow
                top="40px"
                bottom="48px"
                style={{ borderBottom: '1px solid #182429' }}
                space_between
              >
                <Flexcol bottom="12px">
                  <Flexrow center_align bottom="22px">
                    <Text style={{ color: '#182429', fontSize: '28px' }}>
                      Company data products
                    </Text>
                    <Tooltip
                      color="#1a337d"
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottom"
                      title={`This dashboard is managed by ${account.organization.name}.`}
                    >
                      <img
                        src={BuildingSrc}
                        style={{ marginLeft: '32px', marginRight: '10px' }}
                      />
                      <Text
                        style={{
                          color: '#64737F',
                          fontSize: '13px',
                        }}
                      >
                        {account.organization.name}
                      </Text>
                    </Tooltip>
                  </Flexrow>
                  <Text style={{ color: '#64737f' }}>
                    Here you can view, edit or create your company's data
                    offerings.
                  </Text>
                </Flexcol>
                <Dropdown overlay={menu} placement="bottomRight">
                  <CreateNewButton>
                    Create new
                    <DownOutlined style={{ marginLeft: 12 }} />
                  </CreateNewButton>
                </Dropdown>
              </Flexrow>
              {connectionProjects?.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {connectionProjects &&
                    connectionProjects.map((connection_project) =>
                      connection_project.status_id ===
                      data_placement_status_ids.draft ? (
                        <ProjectCard
                          key={connection_project.id}
                          type={`data-wizard`}
                          id={connection_project.id}
                          name={connection_project.name}
                          status={connection_project.status_id}
                          categoryId={connection_project.category_id}
                          publishedStep={connection_project.published_step}
                          deleted={deleteHandler}
                        />
                      ) : (
                        <ProjectCard
                          key={connection_project.id}
                          type={`data-connection`}
                          id={connection_project.id}
                          name={connection_project.name}
                          status={connection_project.status_id}
                          categoryId={connection_project.category_id}
                          publishedStep={connection_project.published_step}
                          clone_status={connection_project.clone_status_id}
                          deleted={deleteHandler}
                        />
                      ),
                    )}
                </div>
              ) : (
                <StartHereWrap connectionProject>
                  <img
                    src={FlagImgSource}
                    alt="flag"
                    style={{ marginBottom: '16px' }}
                  />
                  <Title
                    level={2}
                    style={{ fontWeight: 'normal', fontSize: '24px' }}
                  >
                    Start here!
                  </Title>
                  <Text style={{ color: '#64737f', fontSize: '15px' }}>
                    Click on “Create new” button to create your first project.
                  </Text>
                </StartHereWrap>
              )}
            </TabPane>
            <TabPane tab="Data consumptions" key="2">
              <Flexcol
                top="40px"
                bottom="48px"
                style={{ borderBottom: '1px solid #182429' }}
              >
                <Flexrow center_align bottom="22px">
                  <Text style={{ color: '#182429', fontSize: '28px' }}>
                    Company data consumptions
                  </Text>
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={`This dashboard is managed by ${account.organization.name}.`}
                  >
                    <img
                      src={BuildingSrc}
                      style={{ marginLeft: '32px', marginRight: '10px' }}
                    />
                    <Text
                      style={{
                        color: '#64737F',
                        fontSize: '13px',
                      }}
                    >
                      {account.organization.name}
                    </Text>
                  </Tooltip>
                </Flexrow>
                <Text style={{ color: '#64737f', marginBottom: 12 }}>
                  Here you can view, edit or create your company's data demands.
                </Text>
              </Flexcol>
              {consumption_projects?.length > 0 ||
              dataRoomProjects?.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {dataRoomProjects &&
                    dataRoomProjects.map((dataRoom) => (
                      <ProjectCard
                        key={dataRoom.id}
                        type={`data-room`}
                        id={dataRoom.id}
                        name={dataRoom.name}
                      />
                    ))}
                  {consumption_projects &&
                    consumption_projects.map((consumption_project, index) =>
                      newConsumption && index === 0 ? (
                        <ProjectCard
                          key={consumption_project.id}
                          type={`data-consumption`}
                          id={consumption_project.id}
                          name={consumption_project.name}
                          categoryId={consumption_project.category_id}
                          newConsumption
                        />
                      ) : (
                        <ProjectCard
                          key={consumption_project.id}
                          type={`data-consumption`}
                          id={consumption_project.id}
                          name={consumption_project.name}
                          categoryId={consumption_project.category_id}
                        />
                      ),
                    )}
                  <Link to="/" style={{ width: 'calc(100% - 32px)' }}>
                    <CreateProjectCard
                      createTextType="consumption"
                      style={{ minHeight: '100%' }}
                    />
                  </Link>
                </div>
              ) : (
                <StartHereWrap onClick={() => history.push('/')}>
                  <img
                    src={FlagImgSource}
                    alt="flag"
                    style={{ marginBottom: '16px' }}
                  />
                  <Title
                    level={2}
                    style={{ fontWeight: 'normal', fontSize: '24px' }}
                  >
                    Start here!
                  </Title>
                  <Text style={{ color: '#64737f', fontSize: '15px' }}>
                    Click here to create your company's first data consumption
                  </Text>
                </StartHereWrap>
              )}
            </TabPane>
            <TabPane tab="Data requests" key="3">
              <Flexcol
                top="40px"
                bottom="48px"
                style={{ borderBottom: '1px solid #182429' }}
              >
                <Flexrow center_align bottom="22px">
                  <Text style={{ color: '#182429', fontSize: '28px' }}>
                    Company data requests
                  </Text>
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottom"
                    title={`This dashboard is managed by ${account.organization.name}.`}
                  >
                    <img
                      src={BuildingSrc}
                      style={{ marginLeft: '32px', marginRight: '10px' }}
                    />
                    <Text
                      style={{
                        color: '#64737F',
                        fontSize: '13px',
                      }}
                    >
                      {account.organization.name}
                    </Text>
                  </Tooltip>
                </Flexrow>
                <Text style={{ color: '#64737f', marginBottom: 12 }}>
                  Here you can view, edit or create your company's data
                  requests.
                </Text>
              </Flexcol>
              {request_projects?.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <a onClick={() => createRequestProject()}>
                    <CreateProjectCard
                      createTextType="request"
                      style={{ minHeight: '100%' }}
                    />
                  </a>
                  {request_projects &&
                    request_projects.map((request_project) => (
                      <ProjectCard
                        key={request_project.id}
                        type={
                          request_project.status_id ==
                          data_placement_status_ids.draft
                            ? 'data-request-wizard'
                            : 'data-request'
                        }
                        id={request_project.id}
                        name={request_project.title}
                        status={request_project.status_id}
                        categoryId={request_project.category_id}
                        isPublic={
                          !!request_project.listing_specs &&
                          request_project.listing_specs.is_public
                        }
                        clone_status={request_project.clone_status_id}
                      />
                    ))}
                </div>
              ) : (
                <StartHereWrap onClick={() => createRequestProject()}>
                  <img
                    src={FlagImgSource}
                    alt="flag"
                    style={{ marginBottom: '16px' }}
                  />
                  <Title
                    level={2}
                    style={{ fontWeight: 'normal', fontSize: '24px' }}
                  >
                    Start here!
                  </Title>
                  <Text style={{ color: '#64737f', fontSize: '15px' }}>
                    Click here to create your company's first data request
                  </Text>
                </StartHereWrap>
              )}
            </TabPane>
          </Tabs>
        </LayoutRow>
      )}
      <style>{`body { background-color: ${colors['ghost_white']};}`}</style>
    </>
  );
};

export default Dashboard;
