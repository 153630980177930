import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { SText } from '../styled-text/text';
import ConnectionCard from './ConnectionCard';
import ListingDetails from '../ListingDetails';
import { ModalContext } from '../../hooks/ModalProvider';

const Connections = ({ project_id}) => {
  //TODO: Fetch project relationships?!
  let { handleModal } = React.useContext(ModalContext);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row>
            <SText text_20 black extra_bold>
              Connected to marketplace
            </SText>
          </Row>
          <Row>
            <SText text_16 black medium>
              Your data are listed in the Marketplace in the following listings.
            </SText>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <SText text_16 black extra_bold>
              As plain data set
            </SText>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col xs={12}>
          <ConnectionCard  project_id={project_id}></ConnectionCard>
        </Col>
      </Row>
    </>
  );
};

export default Connections;
