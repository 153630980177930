import React from 'react';
import { Typography } from 'antd';

import { Flexcol, Flexrow } from '../../Flexbox';
import { ProductDescriptionModalContainer } from '../assets/styled-components';

const { Text, Title } = Typography;

const ProductDescriptionModal = (props) => {
  const { name, title, text } = props;

  return (
    <Flexcol>
      <Text style={{ color: '#182429' }}>{name}</Text>
      <Flexrow center_align>
        <Title style={{ marginTop: 8, color: '#182429' }} level={5}>
          {title}
        </Title>
      </Flexrow>
      <ProductDescriptionModalContainer
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></ProductDescriptionModalContainer>
    </Flexcol>
  );
};

export default ProductDescriptionModal;
