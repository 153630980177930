export const colors = {
  grey_1: "#f0f1f2",
  grey_2: "#e2e4e5",
  grey_3: "#e2e4e5",
  grey_4: "#c5cacc",
  grey_5: "",
  grey_6: "#8b9599",
  grey_7: "#6f7a80",
  grey_8: "#525f66",
  grey_9: "#35454d",
  grey_10: "#182a33",
  tirq_1: "#eafdfc",
  tirq_2: "#bffaf7",
  tirq_3: "#94f6f2",
  tirq_4: "#53f1eb",
  tirq_5: "",
  tirq_6: "#3eb5b0",
  tirq_7: "#2a7875",
  tirq_8: "#153c3b",
  blue_1: "#e6e9fd",
  blue_2: "#b3bdfa",
  blue_3: "#8090f7",
  blue_4: "#344ef3",
  blue_5: "",
  blue_6: "#273bb6",
  blue_7: "#1a2779",
  blue_8: "#141d5b",
  yellow_1: "#fff7e2",
  yellow_2: "#ffdf8c",
  yellow_3: "#ffdf8c",
  yellow_4: "#ffbe19",
  yellow_5: "",
  yellow_6: "#bf8f13",
  yellow_7: "#805f0d",
  yellow_8: "#403006",
  orange_0: "#fcf8f4",
  orange_1: "#ffefe2",
  orange_2: "#ffcfa9",
  orange_3: "#ffaf6f",
  orange_4: "#ff7f19",
  orange_5: "",
  orange_6: "#bf5f13",
  orange_7: "#80400d",
  orange_8: "#402006",
  ghost_white: "#f9faff",
};
