import { JSONLD, Product, Generic } from "react-structured-data";

const StructuredData = (props) => {
  const {
    productName,
    productImage,
    productDescription,
    productUrl,
    productSpatialCoverage,
  } = props;

  return (
    <>
      <JSONLD dangerouslyExposeHtml>
        <Product
          name={productName}
          image={productImage}
          description={productDescription}
        >
          <Generic
            type="offers"
            jsonldtype="Offer"
            schema={{
              url: productUrl,
              priceCurrency: "EUR",
              price: "0.00",
              availability: "https://schema.org/OnlineOnly",
            }}
          />
        </Product>
      </JSONLD>
      <JSONLD dangerouslyExposeHtml>
        <Generic
          type="dataset"
          jsonldtype="Dataset"
          schema={{
            name: productName,
            description: productDescription,
            url: productUrl,
            spatialCoverage: productSpatialCoverage,
          }}
        />
      </JSONLD>
    </>
  );
};

export default StructuredData;
