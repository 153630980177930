import {
  AT_LEAST_ONE_UPPERCASE,
  AT_LEAST_ONE_NUMERIC,
  AT_LEAST_ONE_SPECIAL,
  AT_LEAST_EIGHT_CHARACTERS,
  EMAIL_REGEX,
  ONLY_CHARACTERS,
  NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE,
} from '../../utils/validation';

export default function validate(values, step) {
  let errors = {};
  let passwordErrors = [];

  if (step === 'email') {
    if (values.company_name === '')
      errors.company_name = 'Please input Full Company Name!';
    if (!EMAIL_REGEX.test(values.email.toLowerCase()))
      errors.email = 'The input is not valid Email!';
    if (values.email === '') errors.email = 'Please input your Email!';
    if (!values.privacy_policy)
      errors.privacy_policy = 'Accept privacy policy to continue';
    if (values.password != values.password_repeat)
      errors.password_repeat = 'Passwords do not match';
    if (!AT_LEAST_ONE_UPPERCASE.test(values.password))
      passwordErrors.push('upper');
    if (!AT_LEAST_ONE_NUMERIC.test(values.password)) passwordErrors.push('num');
    if (!AT_LEAST_ONE_SPECIAL.test(values.password))
      passwordErrors.push('special');
    if (!AT_LEAST_EIGHT_CHARACTERS.test(values.password))
      passwordErrors.push('eight');
    errors = { ...errors, passwordErrors };
  }

  if (step === 'set_up_account') {
    if (!ONLY_CHARACTERS.test(values.first_name))
      errors.first_name = 'First name should only contain characters';
    if (!NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE.test(values.first_name))
      errors.first_name = 'First name must be at least three characters long';
    if (!values.first_name) errors.first_name = 'First name cannot be empty';
    if (!ONLY_CHARACTERS.test(values.last_name))
      errors.last_name = 'Last name should only contain characters';
    if (!NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE.test(values.last_name))
      errors.last_name = 'Last name must be at least three characters long';
    if (!values.last_name) errors.last_name = 'Last name cannot be empty';
    if (
      (!values.other_business_role && values.business_role === 'other') ||
      !values.business_role
    )
      errors.other_business_role = 'Business role cannot be empty';
    if (
      values.business_role === 'other' &&
      !NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE.test(values.other_business_role)
    )
      errors.other_business_role =
        'Business role must be at least three characters long';
    if (
      (!values.other_country && values.country === 'Other') ||
      !values.country
    )
      errors.other_country = 'Country cannot be empty';
    if (
      values.country === 'Other' &&
      !NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE.test(values.other_country)
    )
      errors.other_country = 'Country must be at least three characters long';
    if (!NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE.test(values.company_name))
      errors.company_name =
        'Company name must be at least three characters long';
    if (!values.company_name)
      errors.company_name = 'Company name cannot be empty';
    if (!values.company_address)
      errors.company_address = 'Company address cannot be empty';
    if (!values.vat) errors.vat = 'VAT cannot be empty';
  }

  if (step === 'edit_password') {
    if (values.new1 != values.new2)
      errors.password_repeat = 'Passwords do not match';
    if (!AT_LEAST_ONE_UPPERCASE.test(values.new1)) passwordErrors.push('upper');
    if (!AT_LEAST_ONE_NUMERIC.test(values.new1)) passwordErrors.push('num');
    if (!AT_LEAST_ONE_SPECIAL.test(values.new1)) passwordErrors.push('special');
    if (!AT_LEAST_EIGHT_CHARACTERS.test(values.new1) || !values.new1)
      passwordErrors.push('eight');
    errors = { ...errors, passwordErrors };
  }

  return errors;
}
