import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import { toast } from 'react-toastify';

import useUser from '../../hooks/useUser';
import { DataProductWizard } from '../../components/data-product-wizard/index';
import DashboardLayoutWizard from '../../components/data-product-wizard/views/DashboardLayoutWizard';
import { colors } from '../../values/colors';
import {
  DataProductContext,
  DataProductWizardHelper,
} from '../../helpers/DataProductWizardHelper';
import { ListingContent } from '../ProductListing/ListingContent';
import LayoutRow from '../../components/LayoutRow';
import Notification from '../../components/Notification';
import { data_placement_type_ids } from '../../iso-shared/values/global';

const DataProductPageWizard = (props) => {
  const { token } = useUser();
  const [, updator] = useState({});
  const { id } = props.match.params;
  const [isS3, setIsS3] = useState(props.location.state?.key === 's3');

  const [project, setProject] = useState(null);
  const [walkthroughOn, setWalkthroughOn] = useState(false);

  useEffect(() => {
    if (!project) {
      const fetcher = async () => {
        const res = await axios.get(`/api/data-placements/editable/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProject(res.data);
      };
      fetcher();
    }
  }, [project]);

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const reset = () => setProject(null);
    const refresh = () => updator({});

    const helper = new DataProductWizardHelper(
      refresh,
      id,
      token,
      project,
      reset,
    );

    helper.onError = (error) =>
      toast.error(<Notification type="error" text={error.message} />);
    helper.fromProject(project);

    return helper;
  }, [!!project]);

  const placement_type =
    project?.placement.type_id === data_placement_type_ids.api_link
      ? 'proxy'
      : 's3_data';

  useEffect(() => {
    if (props.location.state?.key === 's3') {
      return setIsS3(true);
    }

    if (placement_type === 's3_data') {
      setIsS3(true);
    } else {
      setIsS3(false);
    }
  }, [project]);

  if (!project)
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <DataProductContext.Provider value={helper}>
      <DashboardLayoutWizard
        project={project}
        isWizardReview={false}
        withShadow
        bodyColor={colors['ghost_white']}
        setWalkthrough={setWalkthroughOn}
        isS3={isS3}
      >
        {helper.previewing ? (
          <ListingContent helper={helper} />
        ) : (
          <LayoutRow>
            <DataProductWizard walkthroughOn={walkthroughOn} isS3={isS3} />
          </LayoutRow>
        )}
      </DashboardLayoutWizard>
    </DataProductContext.Provider>
  );
};

export default DataProductPageWizard;
