import React, { useState } from 'react';
import { Badge, Button, Collapse, Progress, Typography } from 'antd';
import { strings } from '../assets/strings';
import { Flexrow, Flexcol } from '../../Flexbox';
const { Panel } = Collapse;
const { Text, Title } = Typography;

export const CreateRequestStep = ({ goToTab }) => {
  const [activePanel, setActivePanel] = useState(1);

  const onPanelChange = (key) => {
    setActivePanel(key);
  };

  const goToTabHandler = (tabKey) => {
    goToTab(tabKey);
  };

  return (
    <>
      <Flexcol center_align>
        <Text
          style={{
            marginTop: '5px',
          }}
        >
          {strings.create_request_wizard.title}
        </Text>
        <Title
          level={4}
          style={{
            marginBottom: '20px',
            color: '#182429',
          }}
        >
          {strings.create_request_wizard.headline}
        </Title>
      </Flexcol>
      <Flexrow
        style={{
          marginBottom: '30px',
        }}
      >
        <Progress percent={10} />
      </Flexrow>
      <Collapse
        accordion
        bordered={true}
        defaultActiveKey={['1']}
        onChange={onPanelChange}
        className="site-collapse-custom-collapse"
        style={{ marginBottom: '125px' }}
      >
        <Panel
          header={
            <Flexrow center_align>
              <Badge
                count={1}
                style={{
                  backgroundColor: 'cyan',
                }}
              ></Badge>
              <span style={{ marginLeft: '15px' }}>
                {strings.create_request_wizard.geocoverage.title}
              </span>
            </Flexrow>
          }
          key="1"
          className="site-collapse-custom-panel"
          showArrow={false}
        >
          <Flexcol>
            <Flexrow style={{ paddingBottom: '15px' }}>
              <Title
                level={4}
                style={{
                  marginBottom: '10px',
                  color: '#182429',
                  paddingTop: '5px',
                }}
              >
                {strings.create_request_wizard.geocoverage.subtitle}
              </Title>
              <Button
                type="primary"
                style={{ marginLeft: 'auto' }}
                onClick={() => goToTabHandler('2')}
              >
                {strings.create_request_wizard.geocoverage.addLocations}
              </Button>
            </Flexrow>
            <Flexrow style={{ padding: '15px 0px' }}>
              <Text>
                {strings.create_request_wizard.geocoverage.description}
              </Text>
            </Flexrow>
          </Flexcol>
        </Panel>
        <Panel
          header={
            <Flexrow center_align>
              <Badge
                count={2}
                style={{
                  backgroundColor: 'magenta',
                }}
              ></Badge>
              <span style={{ marginLeft: '15px' }}>
                {strings.create_request_wizard.attributes.title}
              </span>
            </Flexrow>
          }
          key="2"
          className="site-collapse-custom-panel"
          showArrow={false}
        >
          <Flexcol>
            <Flexrow style={{ paddingBottom: '15px' }}>
              <Title
                level={4}
                style={{
                  marginBottom: '10px',
                  color: '#182429',
                  paddingTop: '5px',
                }}
              >
                {strings.create_request_wizard.attributes.subtitle}
              </Title>
              <Button type="primary" style={{ marginLeft: 'auto' }}>
                {strings.create_request_wizard.attributes.addAttributes}
              </Button>
            </Flexrow>
            <Flexrow style={{ padding: '15px 0px' }}>
              <Text>
                {strings.create_request_wizard.attributes.description}
              </Text>
            </Flexrow>
          </Flexcol>
        </Panel>
        <Panel
          header={
            <Flexrow center_align>
              <Badge
                count={3}
                style={{
                  backgroundColor: 'lime',
                }}
              ></Badge>
              <span style={{ marginLeft: '15px' }}>
                {strings.create_request_wizard.volume.title}
              </span>
            </Flexrow>
          }
          key="3"
          className="site-collapse-custom-panel"
          showArrow={false}
        >
          <Flexcol>
            <Flexrow style={{ paddingBottom: '15px' }}>
              <Title
                level={4}
                style={{
                  marginBottom: '10px',
                  color: '#182429',
                  paddingTop: '5px',
                }}
              >
                {strings.create_request_wizard.volume.subtitle}
              </Title>
              <Button type="primary" style={{ marginLeft: 'auto' }}>
                {strings.create_request_wizard.volume.addVolume}
              </Button>
            </Flexrow>
            <Flexrow style={{ padding: '15px 0px' }}>
              <Text>{strings.create_request_wizard.volume.description}</Text>
            </Flexrow>
          </Flexcol>
        </Panel>
        <Panel
          header={
            <Flexrow center_align>
              <Badge
                count={4}
                style={{
                  backgroundColor: 'gold',
                }}
              ></Badge>
              <span style={{ marginLeft: '15px' }}>
                {strings.create_request_wizard.timeframes.title}
              </span>
            </Flexrow>
          }
          key="4"
          className="site-collapse-custom-panel"
          showArrow={false}
        >
          <Flexcol>
            <Flexrow style={{ paddingBottom: '15px' }}>
              <Title
                level={4}
                style={{
                  marginBottom: '10px',
                  color: '#182429',
                  paddingTop: '5px',
                }}
              >
                {strings.create_request_wizard.timeframes.subtitle}
              </Title>
              <Button type="primary" style={{ marginLeft: 'auto' }}>
                {strings.create_request_wizard.timeframes.addTimeframes}
              </Button>
            </Flexrow>
            <Flexrow style={{ padding: '15px 0px' }}>
              <Text>
                {strings.create_request_wizard.timeframes.description}
              </Text>
            </Flexrow>
          </Flexcol>
        </Panel>
      </Collapse>
    </>
  );
};
