import React from "react";
import useUser from "../../hooks/useUser";
import { DataRequestWizard } from "../../components/data-request-wizard/index";
import { colors } from "../../values/colors";
import { useMemo, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { ListingContent } from "../RequestListing/ListingContent";
import LayoutRow from "../../components/LayoutRow";
import {
  DataRequestWizardHelper,
  DataRequestContext,
} from "../../helpers/DataRequestWizardHelper";
import DashboardLayoutWizard from "../../components/data-request-wizard/views/DashboardLayoutWizard";
import { toast } from "react-toastify";
import Notification from "../../components/Notification";

const DataRequestPageWizard = (props) => {
  const { token } = useUser();
  const [, updator] = useState({});
  const { id } = props.match.params;

  const [project, setProject] = useState(null);

  useEffect(() => {
    if (!project) {
      const fetcher = async () => {
        const res = await axios.get(`/api/data-requests/editable/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProject(res.data);
      };
      fetcher();
    }
  }, [project]);

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const reset = () => setProject(null);
    const refresh = () => updator({});

    const helper = new DataRequestWizardHelper(
      refresh,
      id,
      token,
      project,
      reset
    );
    helper.onError = (error) =>
      toast.error(<Notification type="error" text={error.message} />);
    helper.fromProject(project);

    return helper;
  }, [!!project]);

  if (!project)
    return (
      <div
        style={{
          backgroundColor: "#f9faff",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <DataRequestContext.Provider value={helper}>
      <DashboardLayoutWizard
        project={project}
        isWizardReview={false}
        withShadow
        bodyColor={colors["ghost_white"]}
      >
        {helper.previewing ? (
          <ListingContent helper={helper} />
        ) : (
          <LayoutRow>
            <DataRequestWizard />
          </LayoutRow>
        )}
      </DashboardLayoutWizard>
    </DataRequestContext.Provider>
  );
};

export default DataRequestPageWizard;
