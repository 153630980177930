import React, { useState } from 'react';
import { Button, Modal, Typography, Tooltip, Switch } from 'antd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { Bulb } from '@styled-icons/ionicons-outline/Bulb';

import { Flexcol, Flexrow } from '../Flexbox';
import { scrollStyles } from '../../styles/scrollStyles';
import { JsonTree } from './JsonTree';
import Notification from '../Notification';
import { getMaskedJson } from '../../iso-shared/utils/getMaskedJson/index';

const { Text, Title } = Typography;

const BuyMoreButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const PayToActivateButton = styled(Button)`
  background-color: #ff7f19;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background-color: #e56b0a;
    color: #fff;
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const GenerateKeyButton = styled(Button)`
  border: 1px solid #344ef3;
  border-radius: 8px;
  color: #344ef3;
  font-size: 15px;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    color: #0c28dd;
    border: 1px solid #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  }
`;

const EndpointsList = styled(Flexcol)`
  position: sticky;
  top: 60px;
  height: 50vh;
  overflow-y: auto;
  ${scrollStyles}
`;

const EndpointsListItem = styled(Text)`
  color: ${(props) => (props.activeEndpoint ? '#3755EF' : '#192A33')};
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 5px;
  border-radius: 4px;
  :hover {
    background-color: #f9faff;
  }
`;

const EndpointCardWrap = styled.div`
  border: 1px solid #667480;
  border-radius: 8px;
  margin-bottom: 26px;
  z-index: 999;
  background-color: #fff;
`;

const OptionText = styled(Text)`
  cursor: pointer;
  color: #182429 !important;
  :hover {
    color: #344ef3;
  }
`;

const JsonWrap = styled(Flexcol)`
  padding: 16px;
  background-color: #272822;
  border-radius: 6px;
  margin: 0px 15px 10px 15px;
`;

const onCopy = (text) => () => {
  navigator.clipboard.writeText(text);
  toast.success(<Notification type="success" text="Copied to clipboard" />);
};

const MaskedRulesModal = (props) => {
  const {
    imageUrl,
    name,
    setVisible,
    visible,
    openCheckout,
    status,
    endpoints,
    savedRules,
    notModal,
    chargeType,
  } = props;

  const [activeEndpoint, setActiveEndpoint] = useState({});
  const [jsonCollapse, setJsonCollapse] = useState({});
  const [onlyMasked, setOnlyMasked] = useState(false);

  const currentUserRole = Cookies.get('role');

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const activeEndpointHandler = (endpoint) => {
    document.getElementById(endpoint).scrollIntoView({
      behavior: 'smooth',
    });
    setActiveEndpoint({ [endpoint]: true });
  };

  const nameTrimHandler = (name) => {
    let maxLength = 45;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  const jsonTransformation = (sample_data, applied_tags, saved_rules) => {
    let rules = {};

    const treeData = sample_data && JSON.parse(sample_data);
    const applied_tags_endpoints = applied_tags && applied_tags;
    const mask_rules_configuration = saved_rules && saved_rules;

    if (
      applied_tags_endpoints &&
      applied_tags_endpoints['endpoint'].length > 0
    ) {
      let rule =
        mask_rules_configuration[applied_tags_endpoints['endpoint'][0]];
      return { Endpoint: rule };
    } else if (
      applied_tags_endpoints &&
      applied_tags_endpoints['data'].length > 0
    ) {
      for (let i = 0; i < applied_tags_endpoints['data'].length; i++) {
        let rule =
          mask_rules_configuration[applied_tags_endpoints['data'][i]['tag']];

        rules[applied_tags_endpoints['data'][i]['property']] = rule;
      }

      const maskedData = getMaskedJson(treeData, rules);

      return maskedData;
    } else {
      return treeData;
    }
  };

  const tryGetJson = (str) => {
    let out = str;
    try {
      out = JSON.parse(str);
    } catch (error) {}
    return out;
  };

  const onChange = () => {
    setOnlyMasked((prevState) => !prevState);
  };

  const maskedRulesTextHandler = (maskedRule) => {
    let transformedText;
    if (maskedRule === 'nullify') {
      return (transformedText = 'Nullified');
    } else if (maskedRule === 'hash') {
      return (transformedText = 'Hashed');
    } else if (maskedRule === 'first_4') {
      return (transformedText = 'First 4 digits');
    } else {
      return (transformedText = 'Last 4 digits');
    }
  };

  return notModal ? (
    endpoints && (
      <Flexrow space_between style={{ width: '100%' }}>
        {!onlyMasked ? (
          <>
            <Flexcol style={{ width: '20%' }}>
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '22px',
                  display: 'block',
                  position: 'sticky',
                  top: '20px',
                }}
              >
                Endpoints list
              </Text>
              <EndpointsList>
                {endpoints.map((endpoint, index) => (
                  <Tooltip
                    key={index}
                    color="#1a337d"
                    overlayStyle={{
                      display: endpoint.endpoint.length < 45 && 'none',
                    }}
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="bottomLeft"
                    title={endpoint.endpoint}
                  >
                    <EndpointsListItem
                      activeEndpoint={activeEndpoint[endpoint.endpoint]}
                      onClick={() => activeEndpointHandler(endpoint.endpoint)}
                    >
                      {endpoint.applied_tags ? (
                        <Flexrow center_align>
                          {nameTrimHandler(endpoint.endpoint)}
                          <Text
                            style={{
                              color: '#F0A81C',
                              fontSize: '11px',
                              marginLeft: '8px',
                            }}
                          >
                            ● Mask rules apply
                          </Text>
                        </Flexrow>
                      ) : (
                        nameTrimHandler(endpoint.endpoint)
                      )}
                    </EndpointsListItem>
                  </Tooltip>
                ))}
              </EndpointsList>
            </Flexcol>
            <Flexcol style={{ width: '50%' }}>
              {endpoints.map((endpoint, index) => {
                return (
                  <div
                    key={index}
                    id={endpoint.endpoint}
                    style={{ width: '100%' }}
                  >
                    <EndpointCardWrap>
                      <Flexrow
                        center_align
                        space_between
                        style={{
                          backgroundColor: '#F2F2F3',
                          borderBottom: '1px solid #667480',
                          borderRadius: '8px 8px 0px 0px',
                          padding: '14px',
                        }}
                      >
                        <Flexrow center_align>
                          <Text
                            style={{
                              color: '#fff',
                              fontSize: '14px',
                              fontWeight: '600',
                              backgroundColor: '#4DD6B6',
                              padding: '5px 10px',
                              borderRadius: '4px',
                              marginRight: '16px',
                            }}
                          >
                            {endpoint.http_method}
                          </Text>
                          <Tooltip
                            color="#1a337d"
                            overlayStyle={{
                              display: endpoint.endpoint.length < 45 && 'none',
                            }}
                            overlayInnerStyle={{
                              boxShadow: '2px 4px 12px 0px #182a331f',
                              fontSize: '13px',
                              borderRadius: '4px',
                            }}
                            placement="bottom"
                            title={endpoint.endpoint}
                          >
                            <Title
                              level={4}
                              style={{
                                color: '#192A33',
                                margin: 0,
                                zIndex: 1,
                              }}
                            >
                              {nameTrimHandler(endpoint.endpoint)}
                            </Title>
                          </Tooltip>
                        </Flexrow>
                      </Flexrow>
                      <Flexrow top="15px" />
                      <Title
                        level={5}
                        style={{
                          color: '#192A33',
                          marginLeft: '15px',
                        }}
                      >
                        Response
                      </Title>
                      <Flexrow bottom="10px" left="15px">
                        <OptionText onClick={onCopy(endpoint.sample_data)}>
                          Copy
                        </OptionText>
                        <Flexrow left="20px"></Flexrow>
                        <OptionText
                          onClick={() =>
                            setJsonCollapse((prevState) => ({
                              ...prevState,
                              [endpoint.id]: true,
                            }))
                          }
                        >
                          Expand all
                        </OptionText>
                        <Flexrow left="20px"></Flexrow>
                        <OptionText
                          onClick={() =>
                            setJsonCollapse((prevState) => ({
                              ...prevState,
                              [endpoint.id]: false,
                            }))
                          }
                        >
                          Collapse all
                        </OptionText>
                      </Flexrow>
                      <JsonWrap>
                        <JsonTree
                          tree={
                            endpoint.applied_tags
                              ? jsonTransformation(
                                  endpoint.sample_data,
                                  endpoint.applied_tags,
                                  savedRules,
                                )
                              : tryGetJson(endpoint.sample_data)
                          }
                          expand={
                            !!Object.keys(jsonCollapse).length
                              ? jsonCollapse[endpoint.id]
                              : true
                          }
                        />
                      </JsonWrap>
                    </EndpointCardWrap>
                  </div>
                );
              })}
            </Flexcol>
          </>
        ) : (
          <>
            <Flexcol style={{ width: '20%', height: '55vh' }}></Flexcol>
            <Flexcol
              style={{
                width: '50%',
                borderRadius: '6px 6px 0px 0px',
                borderRight: '1px solid #C9CED1',
                borderLeft: '1px solid #C9CED1',
                borderTop: '1px solid #C9CED1',
                boxShadow: '2px 4px 12px 0px #182a331f',
                height: 'fit-content',
              }}
            >
              <Flexrow
                space_between
                style={{
                  backgroundColor: '#F6F6F6',
                  padding: '16px 20px',
                  borderRadius: '6px 6px 0px 0px',
                  borderBottom: '1px solid rgb(201, 206, 209)',
                }}
              >
                <Text
                  style={{
                    fontSize: '16px',
                    color: '#64737F',
                    fontWeight: '500',
                    width: '33%',
                  }}
                >
                  Endpoint
                </Text>
                <Text
                  style={{
                    fontSize: '16px',
                    color: '#64737F',
                    fontWeight: '500',
                    width: '33%',
                    textAlign: 'center',
                  }}
                >
                  Property
                </Text>
                <Text
                  style={{
                    fontSize: '16px',
                    color: '#64737F',
                    fontWeight: '500',
                    width: '33%',
                    textAlign: 'right',
                  }}
                >
                  Mask rule
                </Text>
              </Flexrow>
              <Flexcol>
                {endpoints
                  .filter((endpoint) => endpoint.applied_tags)
                  .map((endpoint) =>
                    endpoint.applied_tags.data.map((mask, index) => (
                      <Flexrow
                        space_between
                        center_align
                        key={index}
                        style={{
                          padding: '16px 24px',
                          borderBottom: '1px solid #C9CED1',
                          backgroundColor: '#fff',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#64737F',
                            width: '33%',
                          }}
                        >
                          {endpoint.endpoint}
                        </Text>
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#64737F',
                            width: '33%',
                            textAlign: 'center',
                          }}
                        >
                          {mask.property}
                        </Text>
                        <Text
                          style={{
                            fontSize: '16px',
                            color: '#F0A81C',
                            fontWeight: '600',
                            width: '33%',
                            textAlign: 'right',
                          }}
                        >
                          {maskedRulesTextHandler(savedRules[mask.tag])}
                        </Text>
                      </Flexrow>
                    )),
                  )}
              </Flexcol>
            </Flexcol>
          </>
        )}
        <Flexcol start_align style={{ width: '20%' }}>
          <Bulb
            style={{
              color: '#64737F',
              height: '30px',
              marginBottom: '12px',
              position: 'sticky',
              top: '20px',
            }}
          />
          <Text
            style={{
              color: '#64737F',
              fontSize: '13px',
              fontWeight: '600',
              marginBottom: '12px',
              position: 'sticky',
              top: '66px',
            }}
          >
            View masking rules
          </Text>
          <Text
            style={{
              color: '#64737F',
              fontSize: '13px',
              whiteSpace: 'pre-line',
              position: 'sticky',
              top: '100px',
            }}
          >
            {`The data provider has selected to impose some masking rules on the data that considers to be "sensitive".

          Here you can view the specific property of each endpoint that a masking rule will be applied to.

          These rules will be applied to all your API requests.`}
          </Text>
          <Flexrow
            center_content
            top="12px"
            bottom="12px"
            style={{
              padding: '12px 0px',
              border: '1px solid #64737F',
              backgroundColor: '#fff',
              borderRadius: '4px',
              width: '100%',
              position: 'sticky',
              top: '302px',
            }}
          >
            <Text
              style={{
                color: '#64737F',
                fontSize: '13px',
                marginRight: '16px',
              }}
            >
              Show only masked items
            </Text>
            <Switch onChange={onChange} checked={onlyMasked} />
          </Flexrow>
        </Flexcol>
      </Flexrow>
    )
  ) : (
    <Modal
      title={
        <Flexcol left="50px">
          <Flexrow center_align style={{ paddingTop: '10px' }}>
            <img
              style={{ marginRight: '10px' }}
              width="40"
              height="40"
              alt="mobility-module-image"
              src={imageUrl}
            />
            <Text style={{ color: '#192A33', fontSize: '18px' }}>
              {name ? name : 'No name'}
            </Text>
          </Flexrow>
          <Text
            style={{
              marginTop: '18px',
              color: '#192A33',
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            View masking rules
          </Text>
        </Flexcol>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      style={{ paddingBottom: '0px' }}
      bodyStyle={{
        backgroundColor: '#F9FAFF',
        padding: '0px',
        height: '70vh',
        overflowY: 'auto',
      }}
      width="100%"
      destroyOnClose={true}
      footer={
        chargeType === 'free'
          ? [
              <Flexrow space_between left="74px">
                <Flexrow grow />
                <Flexrow end_content style={{ width: '40%' }}>
                  <GenerateKeyButton onClick={() => openCheckout()}>
                    Generate Key
                  </GenerateKeyButton>
                </Flexrow>
                <Flexrow grow />
              </Flexrow>,
            ]
          : status === 'ACTIVE'
          ? [
              <Flexrow space_between left="74px">
                <Flexrow grow />
                <Flexrow end_content style={{ width: '40%' }}>
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="right"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                    zIndex={99999999}
                  >
                    <BuyMoreButton
                      onClick={() => openCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Buy more
                    </BuyMoreButton>
                  </Tooltip>
                </Flexrow>
                <Flexrow grow />
              </Flexrow>,
            ]
          : [
              <Flexrow space_between left="74px">
                <Flexrow grow />
                <Flexrow end_content style={{ width: '40%' }}>
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="right"
                    title={
                      currentUserRole === 'MEMBER' &&
                      'You do not have the access rights to perform this action.'
                    }
                    zIndex={99999999}
                  >
                    <PayToActivateButton
                      onClick={() => openCheckout()}
                      disabled={currentUserRole === 'MEMBER'}
                    >
                      Pay to activate
                    </PayToActivateButton>
                  </Tooltip>
                </Flexrow>
                <Flexrow grow />
              </Flexrow>,
            ]
      }
      zIndex={1000000}
    >
      {endpoints && (
        <Flexrow space_between left="74px" right="74px">
          {!onlyMasked ? (
            <>
              <Flexcol style={{ width: '25%' }}>
                <Text
                  style={{
                    color: '#192A33',
                    fontSize: '16px',
                    fontWeight: '600',
                    marginBottom: '22px',
                    display: 'block',
                    position: 'sticky',
                    top: '20px',
                  }}
                >
                  Endpoints list
                </Text>
                <EndpointsList>
                  {endpoints.map((endpoint, index) => (
                    <Tooltip
                      key={index}
                      color="#1a337d"
                      overlayStyle={{
                        display: endpoint.endpoint.length < 45 && 'none',
                      }}
                      overlayInnerStyle={{
                        boxShadow: '2px 4px 12px 0px #182a331f',
                        fontSize: '13px',
                        borderRadius: '4px',
                      }}
                      placement="bottomLeft"
                      title={endpoint.endpoint}
                    >
                      <EndpointsListItem
                        activeEndpoint={activeEndpoint[endpoint.endpoint]}
                        onClick={() => activeEndpointHandler(endpoint.endpoint)}
                      >
                        {endpoint.applied_tags ? (
                          <Flexrow center_align>
                            {nameTrimHandler(endpoint.endpoint)}
                            <Text
                              style={{
                                color: '#F0A81C',
                                fontSize: '11px',
                                marginLeft: '8px',
                              }}
                            >
                              ● Mask rules apply
                            </Text>
                          </Flexrow>
                        ) : (
                          nameTrimHandler(endpoint.endpoint)
                        )}
                      </EndpointsListItem>
                    </Tooltip>
                  ))}
                </EndpointsList>
              </Flexcol>
              <Flexcol style={{ width: '40%', marginTop: '20px' }}>
                {endpoints.map((endpoint, index) => {
                  return (
                    <div
                      key={index}
                      id={endpoint.endpoint}
                      style={{ width: '100%' }}
                    >
                      <EndpointCardWrap>
                        <Flexrow
                          center_align
                          space_between
                          style={{
                            backgroundColor: '#F2F2F3',
                            borderBottom: '1px solid #667480',
                            borderRadius: '8px 8px 0px 0px',
                            padding: '14px',
                          }}
                        >
                          <Flexrow center_align>
                            <Text
                              style={{
                                color: '#fff',
                                fontSize: '14px',
                                fontWeight: '600',
                                backgroundColor: '#4DD6B6',
                                padding: '5px 10px',
                                borderRadius: '4px',
                                marginRight: '16px',
                              }}
                            >
                              {endpoint.http_method}
                            </Text>
                            <Tooltip
                              color="#1a337d"
                              overlayStyle={{
                                display:
                                  endpoint.endpoint.length < 45 && 'none',
                              }}
                              overlayInnerStyle={{
                                boxShadow: '2px 4px 12px 0px #182a331f',
                                fontSize: '13px',
                                borderRadius: '4px',
                              }}
                              placement="bottom"
                              title={endpoint.endpoint}
                            >
                              <Title
                                level={4}
                                style={{
                                  color: '#192A33',
                                  margin: 0,
                                  zIndex: 1,
                                }}
                              >
                                {nameTrimHandler(endpoint.endpoint)}
                              </Title>
                            </Tooltip>
                          </Flexrow>
                        </Flexrow>
                        <Flexrow top="15px" />
                        <Title
                          level={5}
                          style={{
                            color: '#192A33',
                            marginLeft: '15px',
                          }}
                        >
                          Response
                        </Title>
                        <Flexrow bottom="10px" left="15px">
                          <OptionText onClick={onCopy(endpoint.sample_data)}>
                            Copy
                          </OptionText>
                          <Flexrow left="20px"></Flexrow>
                          <OptionText
                            onClick={() =>
                              setJsonCollapse((prevState) => ({
                                ...prevState,
                                [endpoint.id]: true,
                              }))
                            }
                          >
                            Expand all
                          </OptionText>
                          <Flexrow left="20px"></Flexrow>
                          <OptionText
                            onClick={() =>
                              setJsonCollapse((prevState) => ({
                                ...prevState,
                                [endpoint.id]: false,
                              }))
                            }
                          >
                            Collapse all
                          </OptionText>
                        </Flexrow>
                        <JsonWrap>
                          <JsonTree
                            tree={
                              endpoint.applied_tags
                                ? jsonTransformation(
                                    endpoint.sample_data,
                                    endpoint.applied_tags,
                                    savedRules,
                                  )
                                : tryGetJson(endpoint.sample_data)
                            }
                            expand={
                              !!Object.keys(jsonCollapse).length
                                ? jsonCollapse[endpoint.id]
                                : true
                            }
                          />
                        </JsonWrap>
                      </EndpointCardWrap>
                    </div>
                  );
                })}
              </Flexcol>
            </>
          ) : (
            <>
              <Flexcol style={{ width: '25%' }}></Flexcol>
              <Flexcol
                style={{
                  width: '40%',
                  borderRadius: '6px 6px 0px 0px',
                  borderRight: '1px solid #C9CED1',
                  borderLeft: '1px solid #C9CED1',
                  borderTop: '1px solid #C9CED1',
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  marginTop: '20px',
                  height: 'fit-content',
                }}
              >
                <Flexrow
                  space_between
                  style={{
                    backgroundColor: '#F6F6F6',
                    padding: '16px 20px',
                    borderRadius: '6px 6px 0px 0px',
                    borderBottom: '1px solid rgb(201, 206, 209)',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                      width: '33%',
                    }}
                  >
                    Endpoint
                  </Text>
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                      width: '33%',
                      textAlign: 'center',
                    }}
                  >
                    Property
                  </Text>
                  <Text
                    style={{
                      fontSize: '16px',
                      color: '#64737F',
                      fontWeight: '500',
                      width: '33%',
                      textAlign: 'right',
                    }}
                  >
                    Mask rule
                  </Text>
                </Flexrow>
                <Flexcol>
                  {endpoints
                    .filter((endpoint) => endpoint.applied_tags)
                    .map((endpoint) =>
                      endpoint.applied_tags.data.map((mask, index) => (
                        <Flexrow
                          space_between
                          center_align
                          key={index}
                          style={{
                            padding: '16px 24px',
                            borderBottom: '1px solid #C9CED1',
                            backgroundColor: '#fff',
                          }}
                        >
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#64737F',
                              width: '33%',
                            }}
                          >
                            {endpoint.endpoint}
                          </Text>
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#64737F',
                              width: '33%',
                              textAlign: 'center',
                            }}
                          >
                            {mask.property}
                          </Text>
                          <Text
                            style={{
                              fontSize: '16px',
                              color: '#F0A81C',
                              fontWeight: '600',
                              width: '33%',
                              textAlign: 'right',
                            }}
                          >
                            {maskedRulesTextHandler(savedRules[mask.tag])}
                          </Text>
                        </Flexrow>
                      )),
                    )}
                </Flexcol>
              </Flexcol>
            </>
          )}
          <Flexcol start_align style={{ width: '25%' }}>
            <Bulb
              style={{
                color: '#64737F',
                height: '30px',
                marginBottom: '36px',
                position: 'sticky',
                top: '20px',
              }}
            />
            <Text
              style={{
                color: '#64737F',
                fontSize: '13px',
                fontWeight: '600',
                marginBottom: '12px',
                position: 'sticky',
                top: '66px',
              }}
            >
              View masking rules
            </Text>
            <Text
              style={{
                color: '#64737F',
                fontSize: '13px',
                whiteSpace: 'pre-line',
                position: 'sticky',
                top: '100px',
              }}
            >
              {`The data provider has selected to impose some masking rules on the data that considers to be "sensitive".

              Here you can view the specific property of each endpoint that a masking rule will be applied to.

              These rules will be applied to all your API requests.`}
            </Text>
            <Flexrow
              center_content
              top="12px"
              bottom="12px"
              style={{
                padding: '12px 0px',
                border: '1px solid #64737F',
                backgroundColor: '#fff',
                borderRadius: '4px',
                width: '80%',
                position: 'sticky',
                top: '302px',
              }}
            >
              <Text
                style={{
                  color: '#64737F',
                  fontSize: '13px',
                  marginRight: '16px',
                }}
              >
                Show only masked items
              </Text>
              <Switch onChange={onChange} checked={onlyMasked} />
            </Flexrow>
          </Flexcol>
        </Flexrow>
      )}
    </Modal>
  );
};

export default MaskedRulesModal;
