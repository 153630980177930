import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CompareArrows, Alarm } from 'styled-icons/material';
import {
  Button,
  Typography,
  message as notifyMessage,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import useSWR from 'swr';
import { Menu, Tag } from 'antd';

import { timestampConverter } from '../../utils/time';
import {
  access_key_status_ids,
  tag_rules,
  tag_rule_names,
} from '../../iso-shared/values/global';
import { fetcherWithToken } from '../../utils/fetcher';
import { colors } from '../../values/colors';
import instance from '../../services/TokenValidator';

const { SubMenu } = Menu;

const { Option } = Select;

const tag_rules_arr = [
  tag_rules.nullify,
  tag_rules.hash,
  tag_rules.first_4,
  tag_rules.last_4,
];

const rule_options = [
  <Option key={null} value="no_rule">
    No rule
  </Option>,
  ...tag_rules_arr.map((e) => (
    <Option key={e} value={e}>
      {tag_rule_names[e]}
    </Option>
  )),
];

const Title = styled(Typography.Title)`
  line-height: unset !important;
  margin-bottom: unset !important;
`;

const { Text } = Typography;

const SAlarmIcon = styled(Alarm)`
  color: white;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-bottom: 2px;
`;

const Wrap = styled(Col)`
  background-color: #ffffff;
  padding: 20px 28px;
  border-bottom: 2px solid ${colors['grey_1']};
  > * .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
  }
  ${(p) =>
    p.hide_arrow &&
    css`
      > * .ant-menu-submenu-arrow {
        color: transparent !important;
      }
    `}
`;

const AccessRequestCard = (props) => {
  const token = Cookies.get('jwt');
  const currentUserRole = Cookies.get('role');
  const { request, module } = props;
  const [requestName, setRequestName] = useState('');

  const timestamp = new Date(request.created_at);
  const converted_timestamp = timestampConverter(timestamp);
  const [key_status, setKeyStatus] = useState(
    request['platform_resource_access_key.status'],
  );
  const [appliedRules, setAppliedRules] = useState({});

  const is_review = key_status == access_key_status_ids.review;
  const is_accepted =
    key_status == access_key_status_ids.pending_payment ||
    key_status == access_key_status_ids.active;
  const is_rejected = key_status == access_key_status_ids.rejected;

  useEffect(() => {
    const fetchOrganization = async () => {
      const res = await instance.get(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations?organizationId=${request.organization_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (res.status === 200) {
        setRequestName(res.data.name);
      }
    };
    fetchOrganization();
  }, []);

  const { data: consumption } = useSWR(
    [`/api/data-total-consumption?id=${request.id}`, token],
    fetcherWithToken,
  );

  let { data: used_tags } = useSWR(
    [`/api/data-placements/placement-used-tags?id=${module.id}`, token],
    fetcherWithToken,
  );

  used_tags = used_tags || [];

  let { data: saved_rules } = useSWR(
    [`/api/resource-connection/mask-rules?id=${request.id}`, token],
    fetcherWithToken,
  );

  saved_rules = saved_rules || {};

  const displayed_rules = is_review ? appliedRules : saved_rules;

  const accessRequest = (status_id, rules_json) =>
    axios.put(
      '/api/access-request',
      {
        id: request['platform_resource_access_key.id'],
        connection_id: request.id,
        status: status_id,
        rules_json,
        email: request['email'],
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );

  const makeRulesJson = () => {
    const res = {};
    Object.entries(appliedRules)
      .filter((e) => e[1] != 'no_rule')
      .forEach((e) => (res[e[0]] = e[1]));
    if (Object.entries(res).length == 0) {
      return null;
    }
    return res;
  };

  const changeStatus = (status_id) => async () => {
    try {
      let rules_json;
      if (status_id == access_key_status_ids.pending_payment) {
        rules_json = makeRulesJson();
      }
      await accessRequest(status_id, rules_json);
      setKeyStatus(status_id);
      notifyMessage.success('Project status updated successfully');
    } catch (error) {
      notifyMessage.error('Could not update access key status');
    }
  };

  const onChangeRule = (tag) => (value) => {
    setAppliedRules({
      ...appliedRules,
      [tag]: value,
    });
  };

  const TitleView = (
    <Row align="middle" justify="space-between">
      <Col span={4}>
        <Title
          style={{ color: colors.orange_4, textDecoration: 'underline' }}
          level={5}
        >
          {requestName}
        </Title>
      </Col>
      <Col span={4}>
        <Tag color="#2db7f5">
          <SAlarmIcon />
          {converted_timestamp}
        </Tag>
      </Col>
      <Col span={4} style={{ position: 'relative' }}>
        <Row>
          <Col>
            <Title level={5}>Consumption</Title>
          </Col>
          <Col offset={1}>
            <CompareArrows
              size="16"
              style={{ color: colors['orange_4'], marginRight: 4 }}
            />
          </Col>
          <Col style={{ position: 'relative' }}>
            <Text style={{ position: 'absolute', top: 0, left: 0 }}>
              {consumption ? consumption.num_requests : 0} requests
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={6} offset={3}>
        {is_review && (
          <>
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              overlayStyle={{ width: '100%' }}
              placement="bottom"
              title={
                currentUserRole === 'MEMBER' &&
                'You do not have the access rights to perform this action.'
              }
            >
              <Button
                onClick={changeStatus(access_key_status_ids.pending_payment)}
                type="primary"
                disabled={currentUserRole === 'MEMBER'}
              >
                {used_tags.length ? 'Apply rules & Accept' : 'Accept'}
              </Button>
            </Tooltip>
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              overlayStyle={{ width: '100%' }}
              placement="bottom"
              title={
                currentUserRole === 'MEMBER' &&
                'You do not have the access rights to perform this action.'
              }
            >
              <Button
                onClick={changeStatus(access_key_status_ids.rejected)}
                type="danger"
                style={{ marginLeft: '8px' }}
                disabled={currentUserRole === 'MEMBER'}
              >
                Reject
              </Button>
            </Tooltip>
          </>
        )}
        {is_accepted && <Title level={5}>Accepted</Title>}
        {is_rejected && <Title level={5}>Rejected</Title>}
      </Col>
    </Row>
  );

  const SubView =
    (is_review || is_accepted) && used_tags.length ? (
      <>
        <Row style={{ marginTop: 12 }}>
          <Title level={5}>Applied masking rules</Title>
        </Row>
        <Row>
          <Col span={8} style={{ marginTop: 12 }}>
            {used_tags.map((e) => (
              <Row style={{ marginTop: 12 }} key={e}>
                <Row style={{ flex: 1 }}>
                  <Input disabled value={e} />
                </Row>
                <Row style={{ flex: 1, marginLeft: 32 }}>
                  <Select
                    disabled={!is_review || currentUserRole === 'MEMBER'}
                    onChange={onChangeRule(e)}
                    value={displayed_rules[e] || 'no_rule'}
                    style={{ width: '100%' }}
                  >
                    {rule_options}
                  </Select>
                </Row>
              </Row>
            ))}
          </Col>
        </Row>
      </>
    ) : null;

  return (
    <>
      <Wrap hide_arrow={!SubView} span={24}>
        <Menu mode="inline">
          <SubMenu key="sub1" title={TitleView}>
            {SubView}
          </SubMenu>
        </Menu>
      </Wrap>
      <style>{`.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title { height: auto !important; }`}</style>
    </>
  );
};

export default AccessRequestCard;
