import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const ClientsAccessRightTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        C
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>LIENT'S </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>CCESS </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>R</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>IGHT</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Subject to the Client’s compliance with the Agreement, Mobito
              grants the Client a limited, non-exclusive, non-transferable,
              non-sublicensable, revocable and royalty-free right of access to
              the Platform for the Client’s use of the Platform and Services and
              to use the functionalities as per the Client’s chosen
              subscription.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Client may designate one or more Authorized Users to manage
              the account. The Client remains responsible for all use that is
              made of the account of the Client by the Authorized Users or third
              parties. The Client shall ensure that the password to access the
              account shall remain secret and that sufficient security measures
              are taken to prevent any abuse by third parties. It is also
              prohibited to (i) bypass user identification or security of the
              Platform, network or account (this includes accessing account or
              Data not meant for the Client or its Authorized Users) or make use
              of tools that achieve the foregoing, (ii) execute or launch
              (Distributed) Denial of Services ((D)DoS) attacks.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Client may not use the Platform for illegal or irresponsible
              actions. The following is in any event (non-exhaustive)
              prohibited: (i) causing harm to minors, (ii) placing or
              transmitting Data that contain threats and/or encourages to
              harm/damage people, public safety or public health, institutions
              and or property, and/or Data that is violent, incites violence,
              threatens with violence, has a harassing content or contains hate
              generating comments, (iii) disrupting systems in the network
              (services or communications) and/or the Platform, (iv) making
              fraudulent offers, buying or selling fraudulent Data, goods or
              services or promoting scams, (v) collecting or using (personal)
              information, e-mail addresses; screen names; e-ID cards, payment
              card and/or credit card data or other user identification without
              the permission of the owner of the information, including but not
              limited to phishing, internet scamming, theft of passwords,
              spidering or harvesting, (vi) deliberately spreading viruses or
              introducing other types of malicious programs into the network or
              system which aim or threaten to harm the systems, software or data
              of third parties, (vii) sending, spreading or displaying any Data
              that infringes any Intellectual Property Rights of third parties,
              (ix) reselling the Data, re-enlisting the Data or otherwise using
              or distributing the Data in a manner that is not compliant with
              the Data Agreement, the Order Form or fair practices.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito shall be entitled to suspend the Client’s access to the
              Platform in case of serious or repeated violation by the Client of
              the terms set out in this article 3.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default ClientsAccessRightTerms;
