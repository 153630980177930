import React, { useState } from 'react';
import { Divider } from 'antd';
import { strings } from '../assets/strings';
import { CardWrap } from '../assets/styled-components';
import { GeneralQuestion } from '../components/GeneralQuestion';
import Geocoverage from '../components/Geocoverage';
import styles from '../assets/CardWrapHighlight.module.css';

export const GeocoverageOverall = (props) => {
  const {
    selectable,
    readOnly,
    generalSelections,
    geocoverageSelections,
    dataRoomId,
    setRefresh,
    geoDiscussionThreadsIds,
    geocoverageCheckedState,
  } = props;

  // Expose generalQuestionChecked state of general question to CardWrap to apply the correct style
  const [generalQuestionChecked, setGeneralQuestionChecked] = useState(false);

  return (
    <CardWrap
      className={
        generalQuestionChecked && selectable
          ? styles.checked
          : styles.notChecked
      }
    >
      <GeneralQuestion
        title={strings.dataset_details.geocoverage}
        selectable={selectable}
        readOnly={readOnly}
        generalSelections={generalSelections}
        category={strings.discussion_threads_categories.generalGeocoverage}
        dataRoomId={dataRoomId}
        setRefresh={setRefresh}
        setGeneralQuestionChecked={setGeneralQuestionChecked}
      ></GeneralQuestion>
      <Divider style={{ margin: '0', padding: '10px 0' }} />
      <Geocoverage
        geocoverageSelections={geocoverageSelections}
        geoDiscussionThreadsIds={geoDiscussionThreadsIds}
        geocoverageCheckedState={geocoverageCheckedState}
        selectable={selectable}
        readOnly={readOnly}
        dataRoomId={dataRoomId}
        setRefresh={setRefresh}
      />
    </CardWrap>
  );
};
