import React from "react";
import styled from "styled-components";
import { Tooltip, Tag, Button, Spin } from "antd";
import { InfoCircleOutlined, EditOutlined } from "@ant-design/icons";

import { Flexrow } from "../Flexbox";
import { Container, Title } from "./styled-components";

const saving_tag = (
  <Tag color="default" style={{ float: "right" }}>
    {" "}
    saving...{" "}
  </Tag>
);

const Overlay = styled.div`
  background-color: #f2f2f3;
  position: absolute;
  width: 100%;
  padding: 30px;
  left: 0;
  margin-top: ${(props) => props.editing && "-95px"};
`;

const TextButton = styled(Button)`
  padding: ${(props) => (props.editMode ? "3px 7px" : "0px")};
  height: initial;
  font-size: 15px;
  color: ${(props) => (props.editMode ? "#FF7F19" : "#344ef3")};
  background-color: ${(props) => props.editMode && "#fff"};
  border-radius: ${(props) => props.editMode && "8px"};
  border: ${(props) => props.editMode && "1px solid #FF7F19"};
  &:hover,
  :active,
  :focus {
    background-color: ${(props) => (props.editMode ? "#fff" : "transparent")};
    color: ${(props) => (props.editMode ? "#ff7f19" : "#344ef3")};
    border: ${(props) => props.editMode && "1px solid #e56b0a"};
    box-shadow: ${(props) => props.editMode && "2px 4px 12px 0px #182a331f"};
  }
`;

const SaveButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid ${(props) => (props.disabled ? "#f3f3f4" : "#FF7F19")};
  border-color: ${(props) => props.disabled && "#c1c2c2 !important"};
  background-color: #ff7f19;
  color: ${(props) => (props.disabled ? "#c1c2c2" : "#fff")};
  font-size: 15px;
  font-weight: 500;
  box-shadow: none;
  &:hover,
  :active,
  :focus {
    background-color: #e56b0a;
    color: #fff;
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px #182a331f;
  }
`;

export const SectionTitle = (props) => {
  const { title, tooltip, saving } = props;

  return (
    <>
      <Overlay />
      <Container padding="18px 0">
        <Tooltip
          className="create-tooltip"
          title={tooltip}
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: "2px 4px 12px 0px #182a331f",
            fontSize: "13px",
            borderRadius: "4px",
          }}
          placement="bottom"
        >
          <InfoCircleOutlined />
        </Tooltip>
        <Title size={16} margin={"0 0 0 8px"} weight="bold">
          {title}
        </Title>
        {saving && saving_tag}
      </Container>
    </>
  );
};

export const PublishedSectionTitle = (props) => {
  const {
    title,
    tooltip,
    saving,
    editing,
    startEditing,
    saveChanges,
    hideEdit,
    edited,
    handleCancel,
  } = props;

  return (
    <>
      <Overlay editing={editing} />
      <Container flex wrap align="center" padding="18px 0" editing={editing}>
        <Tooltip
          title={tooltip}
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: "2px 4px 12px 0px #182a331f",
            fontSize: "13px",
            borderRadius: "4px",
          }}
          placement="bottom"
        >
          <InfoCircleOutlined />
        </Tooltip>
        <Title size={16} margin={"0 0 0 8px"} weight="bold">
          {title}
        </Title>
        <Flexrow grow />
        {!hideEdit && !saving && !editing && (
          <TextButton
            id="published-edit-btn"
            onClick={startEditing}
            type="text"
            icon={<EditOutlined />}
          >
            Edit
          </TextButton>
        )}
        {editing && (
          <div style={{ display: "flex", position: "absolute", right: 0 }}>
            <TextButton editMode onClick={handleCancel} type="text">
              Cancel
            </TextButton>
            <Flexrow left="20px" />
            <SaveButton onClick={saveChanges} type="text" disabled={!edited}>
              Save
            </SaveButton>
          </div>
        )}
        {saving && (
          <div style={{ display: "flex", position: "absolute", right: 0 }}>
            <TextButton editMode onClick={handleCancel} type="text">
              Cancel
            </TextButton>
            <Flexrow left="20px" />
            <SaveButton type="text">
              <Spin style={{ lineHeight: "0" }} />
            </SaveButton>
          </div>
        )}
      </Container>
    </>
  );
};
