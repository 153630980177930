import styled from "styled-components"
import { Flexrow } from "../../../../components/Flexbox"

const GetWrap = styled(Flexrow)`
  height: 30px;
  width: 49px;
  justify-content: center;
  align-items: center;
  background-color: #daf7f0;
  border-radius: 4px;
  color: #44d7b6;
  font-size: 13px;
  font-weight: bold;
`

const PostWrap = styled(GetWrap)`
  background-color: #d6f3ff;
  color: #32c5ff;
`

const GET_EL = <GetWrap>GET</GetWrap>

const POST_EL = <PostWrap>POST</PostWrap>

export const verb_chips = {
  GET: GET_EL,
  POST: POST_EL
}
