import { API_LINK_ID, S3_DATA } from '../iso-shared/values/global';

export const DataProductType = (type_id) => {
  switch (type_id) {
    case API_LINK_ID:
      return 'Proxy API';

    case S3_DATA:
      return 'S3 Bucket';

    default:
      break;
  }
};
