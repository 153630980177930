import React from "react";
import { Typography } from "antd";
import styled from "styled-components";

import { Flexcol, Flexrow } from "../Flexbox";
import { scrollStyles } from "../../styles/scrollStyles";

const { Text, Title } = Typography;

const TableContainer = styled.div`
  max-height: 55vh;
  overflow-y: scroll;
  padding-right: 18px;
  ${scrollStyles}
`;

const ItemCardChildTitle = styled.span`
  color: #182a33;
  font-size: 13px;
  font-weight: bold;
  display: block;
  padding: 8px;
  width: 50%;
`;

const ItemCardChildParameter = styled.span`
  display: block;
  width: 50%;
  border: 1px solid #ccc;
  height: 60px;
  padding: 12px 16px;
  overflow-y: auto;
  ${scrollStyles}
`;

const DataParametersModal = (props) => {
  const { name, title, parameters } = props;

  return (
    <Flexcol>
      <Text style={{ color: "#182429" }}>{name}</Text>
      <Flexrow center_align>
        <Title style={{ marginTop: 8, color: "#182429" }} level={5}>
          {title}
        </Title>
      </Flexrow>
      <TableContainer>
        <Flexrow center_align style={{ backgroundColor: "#f5f5f5" }}>
          <ItemCardChildTitle>Attribute</ItemCardChildTitle>
          <ItemCardChildTitle>Update frequency</ItemCardChildTitle>
        </Flexrow>
        {parameters.map((dataParam, index) => (
          <Flexrow center_align key={index}>
            <ItemCardChildParameter>{dataParam.name}</ItemCardChildParameter>
            <ItemCardChildParameter>
              {dataParam.frequency}
            </ItemCardChildParameter>
          </Flexrow>
        ))}
      </TableContainer>
    </Flexcol>
  );
};

export default DataParametersModal;
