import JSONTree from 'react-json-tree';
import { Typography } from 'antd';
import styled from 'styled-components';

import { json_tree_theme } from '../../values/json_tree_theme';
import MaskRulesImgSrc from '../../images/mask.svg';
import { scrollStyles } from '../../styles/scrollStyles';

const { Text } = Typography;

const JsonWrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
  background-color: rgb(39, 40, 34);
  padding: 20px;
  ${scrollStyles}
`;

export function JsonTree(props) {
  return (
    <>
      <JsonWrap>
        <JSONTree
          data={props.tree}
          hideRoot={false}
          shouldExpandNode={() =>
            props.expand === undefined ? true : props.expand
          }
          theme={json_tree_theme}
          invertTheme={false}
          theme={{
            extend: json_tree_theme,
          }}
          labelRenderer={([key]) => (
            <>
              <span>
                <strong>{key}</strong>
              </span>
            </>
          )}
          valueRenderer={(raw, v, ...keyPath) => (
            <>
              {v === `___nullify` ? (
                <span
                  style={{
                    paddingLeft: '75%',
                    backgroundColor: '#FFFFFF33',
                    position: 'absolute',
                    right: '0',
                    left: '0',
                  }}
                >
                  <img src={MaskRulesImgSrc} />
                  <Text
                    style={{
                      color: '#F0A81C',
                      fontSize: '14px',
                      fontWeight: '600',
                      paddingLeft: '8px',
                    }}
                  >
                    Nullified
                  </Text>
                </span>
              ) : v === `___hash` ? (
                <span
                  style={{
                    paddingLeft: '75%',
                    backgroundColor: '#FFFFFF33',
                    position: 'absolute',
                    right: '0',
                    left: '0',
                  }}
                >
                  <img src={MaskRulesImgSrc} />
                  <Text
                    style={{
                      color: '#F0A81C',
                      fontSize: '14px',
                      fontWeight: '600',
                      paddingLeft: '8px',
                    }}
                  >
                    Hashed
                  </Text>
                </span>
              ) : v === `___first_4` ? (
                <span
                  style={{
                    paddingLeft: '75%',
                    backgroundColor: '#FFFFFF33',
                    position: 'absolute',
                    right: '0',
                    left: '0',
                  }}
                >
                  <img src={MaskRulesImgSrc} />
                  <Text
                    style={{
                      color: '#F0A81C',
                      fontSize: '14px',
                      fontWeight: '600',
                      paddingLeft: '8px',
                    }}
                  >
                    First 4 digits
                  </Text>
                </span>
              ) : v === `___last_4` ? (
                <span
                  style={{
                    paddingLeft: '75%',
                    backgroundColor: '#FFFFFF33',
                    position: 'absolute',
                    right: '0',
                    left: '0',
                  }}
                >
                  <img src={MaskRulesImgSrc} />
                  <Text
                    style={{
                      color: '#F0A81C',
                      fontSize: '14px',
                      fontWeight: '600',
                      paddingLeft: '8px',
                    }}
                  >
                    Last 4 digits
                  </Text>
                </span>
              ) : (
                <span style={{ padding: '4px' }}>
                  <strong>{v}</strong>{' '}
                </span>
              )}
            </>
          )}
        />
      </JsonWrap>
    </>
  );
}

export default JsonTree;
