const isObject = v => v && typeof(v) == 'object' && v.constructor == Object

const isArray = v => v && typeof(v) == 'object' && v.constructor == Array

export const getMaskedJson =  (value, rules, parent) => {
    parent = parent || ''
    if ( rules[parent] ) {
        return '___' + rules[parent]
    }
    if ( isObject(value) ) {
        const out = {}
        for (let key in value) {
            const prop = parent ? parent + '->' + key : key
            out[key] = getMaskedJson(value[key], rules, prop)
        }
        return out
    }
    if ( isArray(value) ) {
        return value.map(e => getMaskedJson(e, rules, parent))
    }
    return value
}
