import React from 'react';
import { Divider, Typography } from 'antd';
import { strings } from '../assets/strings';
import QuestionMarkSrc from '../../../images/question-mark.svg';
import { Flexcol, Flexrow } from '../../Flexbox';
const { Title } = Typography;

export const ExplanatoryStepModal = (props) => {
  const { title, content } = props;
  return (
    <Flexcol>
      <Flexrow center_align>
        <Flexcol>
          <img src={QuestionMarkSrc} style={{ marginRight: 18 }} />
        </Flexcol>
        <Flexcol>
          <Title level={5} style={{ margin: 0, color: '#182a33' }}>
            {strings.content_stepper_header.how_does_it_work}
          </Title>
          <Title style={{ margin: '5px 0px', color: '#182429' }} level={4}>
            {title}
          </Title>
        </Flexcol>
      </Flexrow>
      <Divider style={{ margin: 0 }} />
      <Flexrow center_align style={{ padding: '2rem' }}>
        {content}
      </Flexrow>
    </Flexcol>
  );
};
