import React, { useContext } from "react";
import {
  ProductCard,
  PricingColumn,
  Typography,
  TypographyTitle,
  TypographyPrice,
  TypographyDetails,
  TooltipIcon,
  TypographySection,
  RequestsColumn,
  RequestsSection,
} from "./pricing-components";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";

export const PackageTab = (p) => {
  const helper = useContext(DataProductContext);
  const hide_pricing = helper.hide_pricing.value;
  const packages = helper.packages.value;

  const { start, end } = p;
  const sliced_packages = packages.slice(start, end);

  return (
    <>
      <ProductCard>
        <PricingColumn></PricingColumn>

        {sliced_packages.map((e, i) => (
          <PricingColumn center_content key={i}>
            <TypographyTitle>{e.name}</TypographyTitle>
            {!hide_pricing && (
              <TypographyPrice>
                <TypographyDetails>€</TypographyDetails>
                {e.price.toLocaleString()}
                <TypographyDetails>/month</TypographyDetails>
              </TypographyPrice>
            )}
          </PricingColumn>
        ))}
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Monthly Allowance
            <Tooltip title={`Maximum number of API requests per month`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>

        {sliced_packages.map((e, i) => (
          <PricingColumn key={i}>
            <Typography>
              {e.requests ? `${e.requests.toLocaleString()} requests` : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Minimum purchase
            <Tooltip
              title={`Minimum number of months that you need to purchase`}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>

        {sliced_packages.map((e, i) => (
          <PricingColumn key={i}>
            <Typography>
              {e.min_packages_purchased
                ? `${e.min_packages_purchased} months`
                : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>

      <ProductCard>
        <RequestsColumn>
          <RequestsSection>Requests limits</RequestsSection>
        </RequestsColumn>

        {sliced_packages.map((e, i) => (
          <RequestsColumn key={i} />
        ))}
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per minute
            <Tooltip title={`Maximum number of API requests per minute`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>

        {sliced_packages.map((e, i) => (
          <PricingColumn key={i}>
            <Typography>
              {e.limit_minute
                ? `${e.limit_minute.toLocaleString()} requests`
                : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per hour
            <Tooltip title={`Maximum number of API requests per hour`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>

        {sliced_packages.map((e, i) => (
          <PricingColumn key={i}>
            <Typography>
              {e.limit_hour ? `${e.limit_hour.toLocaleString()} requests` : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per day
            <Tooltip title={`Maximum number of API requests per day`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>

        {sliced_packages.map((e, i) => (
          <PricingColumn key={i}>
            <Typography>
              {e.limit_day ? `${e.limit_day.toLocaleString()} requests` : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
    </>
  );
};
