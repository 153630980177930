export const HAS_STARTING_SLASH = /^(?!\/.*$).*/;
export const EMAIL_REGEX = new RegExp(
  /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/
);
export const PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
);
export const AT_LEAST_ONE_UPPERCASE = new RegExp("^(?=.*[A-Z])");
export const AT_LEAST_ONE_NUMERIC = new RegExp("(?=.*[0-9])");
export const AT_LEAST_ONE_SPECIAL = new RegExp("(?=.*[!@#$%^&*])");
export const AT_LEAST_EIGHT_CHARACTERS = new RegExp("(?=.{8,})");
export const ALPHA_WITH_SPACES_REGEX = new RegExp(
  /^[A-Za-z][A-Za-z'-]+([A-Za-z][A-Za-z'-]+)*/
);
export const ONLY_CHARACTERS = new RegExp(/^[a-zA-Z\s]*$/);
export const NO_EMPTY_SPACE_AND_MIN_LENGTH_RULE = /(.*[a-z]){3}/i;
export const URL_REGEX =
  /(https|http):\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,256}\b([-a-zA-Z0-9()@:%_+.~#?&=]*)?\//;

export const checkMimeType = (file) => {
  if (!file) return;
  const types = [
    "text/plain",
    "text/x-csv",
    "application/vnd.ms-excel",
    "application/csv",
    "application/x-csv",
    "text/csv",
    "text/comma-separated-values",
    "text/x-comma-separated-values",
    "text/tab-separated-values",
    "application/json",
    "",
  ];
  if (types.every((type) => file.type !== type)) {
    return false;
  }
  return true;
};

export const checkFileSize = (file) => {
  const unit = Math.floor(Math.log(file.size) / Math.log(1024));
  //here unit will be an integer (1 stand for bytes, 2 for kb, 3 for mb, ...)
  const size = parseFloat((file.size / Math.pow(1024, unit)).toFixed(2));
  //and maximum would be 5mb;
  return unit < 2 || (unit === 2 && size < 5);
};
