import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from 'antd';

import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { DeleteOutline } from '@styled-icons/typicons/DeleteOutline';

import { Flexcol, Flexrow } from '../../Flexbox';
import MobitoLogoSrc from '../../../images/mobito-new-logo.svg';

const { Text } = Typography;

const Wrap = styled.div`
  width: 100%;
`;

const OKButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const GoBackButton = styled.button`
  border-radius: 8px;
  border: 1px solid #344ef3;
  height: 41px;
  width: 89px;
  color: #344ef3;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background: #fff;
    box-shadow: 2px 4px 12px 0px #182a331f;
  }
`;

const ContactUsWrap = styled.div`
  background-color: #edeffb;
  padding: 18px;
  text-align: center;
  margin-bottom: 20px;
`;

const Completion = (props) => {
  const {
    paymentResult,
    sourceName,
    packageName,
    months,
    totalCost,
    email,
    prev,
    setSubmit,
    minRequests,
    requestPlan,
    freePlan,
    closeModal,
    setApiKey,
  } = props;

  return paymentResult === 'succeeded' || freePlan ? (
    <Wrap>
      <Flexcol
        center_align
        top="24px"
        bottom="24px"
        style={{ borderBottom: '1px solid #64737F' }}
      >
        <CheckCircle
          style={{ color: '#5FDF3E', height: '54px', marginBottom: '12px' }}
        />
        <Text
          style={{
            color: '#192A33',
            fontSize: '16px',
            fontWeight: '700',
            marginBottom: '34px',
          }}
        >
          {freePlan ? 'Process completed' : 'Payment succesfull'}
        </Text>
      </Flexcol>
      <Flexcol style={{ borderBottom: '1px solid #64737F' }} bottom="24px">
        <Text
          style={{
            color: '#192A33',
            fontSize: '15px',
            marginBottom: '24px',
          }}
        >
          {freePlan
            ? 'Thank you for gaining access to:'
            : 'Thank you for buying:'}
        </Text>
        <Text style={{ color: '#192A33', fontSize: '14px', fontWeight: '600' }}>
          Data product: <Text style={{ fontWeight: '400' }}>{sourceName}</Text>
        </Text>
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
            fontWeight: '600',
            marginBottom: freePlan && '24px',
          }}
        >
          Plan:{' '}
          <Text style={{ fontWeight: '400' }}>
            {requestPlan
              ? 'Pricing per request'
              : freePlan
              ? 'Free'
              : packageName}
          </Text>
        </Text>
        {!freePlan && (
          <Text
            style={{
              color: '#192A33',
              fontSize: '14px',
              fontWeight: '600',
              marginBottom: '24px',
            }}
          >
            {requestPlan ? 'Requests' : 'Months'}:{' '}
            <Text style={{ fontWeight: '400' }}>
              {requestPlan ? minRequests : months}
            </Text>
          </Text>
        )}
      </Flexcol>
      <Flexrow
        baseline_align
        style={{ borderBottom: '1px solid #64737F' }}
        bottom="34px"
      >
        <Text
          style={{
            color: '#192A33',
            fontSize: '13px',
            fontWeight: '700',
          }}
        >
          Total cost:
        </Text>
        <Text
          style={{
            color: '#192A33',
            fontSize: '22px',
            marginLeft: '4px',
            marginBottom: '24px',
          }}
        >
          {freePlan
            ? 0
            : totalCost.toLocaleString('el-GR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
          €
        </Text>
      </Flexrow>
      {!freePlan && (
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
            display: 'block',
            marginBottom: '42px',
          }}
        >{`A confirmation email and an invoice of your purchase has been sent to ${email}.`}</Text>
      )}
      <Flexrow center_content bottom="42px">
        <OKButton
          onClick={() => {
            closeModal();
            setApiKey(true);
          }}
        >
          OK, close
        </OKButton>
      </Flexrow>
      <ContactUsWrap>
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
          }}
        >
          For any questions or concerns, please contact us:{' '}
          <a href="mailto:connect@mobito.io">connect@mobito.io</a>
        </Text>
      </ContactUsWrap>
      <Flexrow center_content bottom="42px">
        <img src={MobitoLogoSrc} alt="mobito logo" style={{ width: '80px' }} />
      </Flexrow>
    </Wrap>
  ) : (
    <Wrap>
      <Flexcol
        center_align
        top="24px"
        bottom="24px"
        style={{ borderBottom: '1px solid #64737F' }}
      >
        <DeleteOutline
          style={{ color: '#FD5F5D', height: '72px', marginBottom: '12px' }}
        />
        <Text
          style={{
            color: '#192A33',
            fontSize: '16px',
            fontWeight: '700',
            marginBottom: '34px',
          }}
        >
          Hmm, something went wrong...
        </Text>
      </Flexcol>
      <Flexcol center_align bottom="42px">
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
            display: 'block',
          }}
        >
          Your transaction has been paused.
        </Text>
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
            display: 'block',
          }}
        >
          Your card was not billed.
        </Text>
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
            display: 'block',
            marginBottom: '42px',
          }}
        >
          Please go back and try again.
        </Text>
        <GoBackButton
          onClick={() => {
            prev();
            setSubmit(false);
          }}
        >
          <Flexrow center_align space_around>
            <ArrowLeftShort style={{ height: '20px' }} /> Back
          </Flexrow>
        </GoBackButton>
      </Flexcol>
      <ContactUsWrap>
        <Text
          style={{
            color: '#192A33',
            fontSize: '14px',
          }}
        >
          For any questions or concerns, please contact us:{' '}
          <a href="mailto:connect@mobito.io">connect@mobito.io</a>
        </Text>
      </ContactUsWrap>
      <Flexrow center_content bottom="42px">
        <img src={MobitoLogoSrc} alt="mobito logo" style={{ width: '80px' }} />
      </Flexrow>
    </Wrap>
  );
};

export default Completion;
