import Header from "./Header";
import HeaderWizard from "./HeaderWizard";
import { IntegrationLine } from "./data-connection-wizard/wizard-style-components/integration-wizard";
import LayoutRow from "./LayoutRow";

const DashboardLayoutWizard = (props) => (
  <>
    <LayoutRow
      background={"#ffffff"}
      withShadow={props.withShadow}
      withBorder={props.withBorder}
    >
      <Header withNav={props.withNav} />
      <IntegrationLine />
      <HeaderWizard review={props} />
    </LayoutRow>
    <LayoutRow>{props.children}</LayoutRow>

    <style>{`body { background-color: ${
      props.bodyColor && props.bodyColor
    };}`}</style>
  </>
);

export default DashboardLayoutWizard;
