import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import Cookies from 'js-cookie';
import { Select, Spin } from 'antd';
import { KeyboardArrowLeft } from 'styled-icons/material';
import { toast } from 'react-toastify';

import { fetcherWithToken } from '../../utils/fetcher';
import { colors } from '../../values/colors';
import Sources from '../../components/data-consumption/Sources';
import { AppContext } from '../../hooks/AppContextProvider';
import LayoutRow from '../../components/LayoutRow';
import Header from '../../components/Header';
import { Flexrow } from '../../components/Flexbox';
import { data_placement_status_ids } from '../../iso-shared/values/global';
import Notification from '../../components/Notification';

const DataConsumption = (props) => {
  const { id } = props.match.params;
  const history = useHistory();
  const token = Cookies.get('jwt') || '';
  const context = useContext(AppContext);

  useEffect(() => {
    context.setDashboardProps({
      ...context.dashboard_props,
      project_id: id,
    });
  }, [id]);

  if (!token)
    useEffect(() => {
      history.push('/login');
    });

  let { data: project, error } = useSWR(
    id ? [`/api/data-consumption?id=${id}`, token] : null,
    fetcherWithToken,
  );

  if (error?.response.status === 404) {
    toast.error(<Notification type="error" text="Unauthorized Access" />);
    history.push('/not-found');
  }

  const { data: projects } = useSWR(
    [
      window.location.pathname.indexOf('data-connection') >= 0
        ? `/api/data-connections`
        : `/api/data-consumptions`,
      token,
    ],
    fetcherWithToken,
  );

  const published_projects = useMemo(() => {
    if (!projects) {
      return [];
    }
    return projects.filter(
      (p) =>
        p.status_id == data_placement_status_ids.active ||
        p.status_id == data_placement_status_ids.pending,
    );
  }, [projects]);

  if (!projects || !project)
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

  const goToDashboard = () => {
    history.push({
      pathname: `/dashboard`,
      state: {
        key: '2',
      },
    });
  };

  const onProjectSelect = (id) => {
    window.location.replace('/project/data-consumption/' + id);
  };

  return (
    <>
      <LayoutRow background={'#ffffff'} withBorder>
        <Header />
      </LayoutRow>
      <LayoutRow
        background={'#ffffff'}
        withBorder
        style={{ padding: '12px 0px' }}
      >
        <Flexrow center_align style={{ padding: 12 }}>
          <KeyboardArrowLeft
            size="32"
            style={{ color: '#a8afb3', cursor: 'pointer', marginRight: '12px' }}
            onClick={goToDashboard}
          />
          <Select
            style={{ width: 300 }}
            value={project.id}
            onChange={onProjectSelect}
          >
            {published_projects.map((p) => (
              <Select.Option value={p.id} key={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Flexrow>
      </LayoutRow>
      <LayoutRow>
        <Sources project_id={project.id} project_name={project.name} />
      </LayoutRow>
      <style jsx global>
        {`
          body {
            background-color: ${colors['ghost_white']};
          }

          #start-walkthrough {
            display: none;
          }
        `}
      </style>
    </>
  );
};

export default DataConsumption;
