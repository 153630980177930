import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes, css } from 'styled-components';
import { Typography, Tooltip, Dropdown, Menu } from 'antd';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { Delete } from '@styled-icons/typicons/Delete';
import { Tag } from '@styled-icons/boxicons-solid/Tag';
import { Eye } from '@styled-icons/bootstrap/Eye';
import { EyeSlash } from '@styled-icons/bootstrap/EyeSlash';
import { Info } from '@styled-icons/entypo/Info';
import {
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { DotsVerticalRounded } from '@styled-icons/boxicons-regular/DotsVerticalRounded';
import useSWR from 'swr';
import { bounceIn } from 'react-animations';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import {
  data_placement_status_ids,
  access_key_status_ids,
} from '../../iso-shared/values/global';
import { Flexcol, Flexrow } from '../Flexbox';
import useCategories from '../../hooks/useCategories';
import { confirmModal } from '../../views/ModalView';
import Notification from '../Notification';
import { fetcherWithToken } from '../../utils/fetcher';

const isDev = process.env.RAZZLE_BASE_URL === 'http://localhost:3001';
const isQA = process.env.RAZZLE_BASE_URL === 'https://app-qa.mobito.io';
const isStaging = process.env.RAZZLE_BASE_URL === 'https://app-rc.mobito.io';

const { Text, Title } = Typography;

const SOuterContainer = styled.div`
  margin-bottom: ${(props) =>
    props.isConsumption || props.isDataRoom ? '25px' : '94px'};
  margin-left: 32px;
  position: relative;
  width: ${(props) => (props.isConsumption || props.isDataRoom) && '100%'};
`;

const bounceInAnimation = keyframes`${bounceIn}`;

const SInnerContainer = styled(Flexcol)`
  position: relative;
  border-radius: 8px;
  border: 1px solid #f0f1f2;
  box-shadow: 2px 4px 12px 0px #182a330f;
  background: #fff;
  height: ${(props) =>
    props.isConsumption || props.isDataRoom ? 'auto' : '280px'};
  width: ${(props) =>
    props.isConsumption || props.isDataRoom ? '100%' : '280px'};
  align-items: center;
  justify-content: ${(props) =>
    props.isConsumption || props.isDataRoom ? 'space-between' : 'space-evenly'};
  flex-direction: ${(props) =>
    (props.isConsumption || props.isDataRoom) && 'row'};
  animation: ${(props) =>
    props.isConsumption && props.isNew && css`1.5s ${bounceInAnimation}`};
`;

const StatusTag = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 13px;
  padding: 4px 11px;
  margin: 0 auto;
  width: fit-content;
  height: 30px;
`;

export const OpenButton = styled.button`
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  width: ${(props) => props.isProduct && '100%'};
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
  &:disabled {
    color: #64737f;
    border: 1px solid #64737f;
    background: #fff;
    cursor: not-allowed;
  }
`;

export const OpenDataCapsuleButton = styled.a`
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
  &:disabled {
    color: #64737f;
    border: 1px solid #64737f;
    background: #fff;
    cursor: not-allowed;
  }
`;

const Wrap = styled.div`
  color: #64737f;
  position: absolute;
  top: ${(props) => props.top && '-25px'};
  bottom: ${(props) => props.bottom && '-25px'};
  display: flex;
  align-items: center;
`;

const getStatusTag = (status_id) => {
  if (status_id == data_placement_status_ids.active) {
    return (
      <StatusTag background="#cefac3" borderColor="#5fdf3e">
        <CheckCircleFill
          style={{ height: '16px', color: '#5fdf3e', paddingRight: '7px' }}
        />
        Published
      </StatusTag>
    );
  } else if (status_id == data_placement_status_ids.pending) {
    return (
      <StatusTag background="#fff3d6" borderColor="#ffc540">
        <ClockFill
          style={{
            height: '16px',
            color: '#ffc540',
            paddingRight: '7px',
          }}
        />
        Pending review
      </StatusTag>
    );
  } else if (status_id == access_key_status_ids.rejected) {
    return (
      <StatusTag background="#ffe9e9" borderColor="#fd5f5d">
        <Delete
          style={{
            height: '24px',
            color: '#fd5f5d',
            paddingRight: '2px',
          }}
        />
        Rejected
      </StatusTag>
    );
  } else if (status_id == data_placement_status_ids.draft) {
    return (
      <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
        <Text style={{ color: '#fd5f5d', fontStyle: 'italic' }}>Draft</Text>
      </div>
    );
  } else {
    return <div style={{ height: '30px' }} />;
  }
};

const getCloneStatusTag = (status_id) => {
  if (!status_id) {
    return <div style={{ height: '30px' }} />;
  }
  if (status_id == data_placement_status_ids.pending) {
    return (
      <StatusTag background="#d4e3fe" borderColor="#3e77df">
        <Info
          style={{
            height: '16px',
            color: '#3e77df',
            paddingRight: '7px',
          }}
        />
        changes pending review
      </StatusTag>
    );
  }
  if (status_id == data_placement_status_ids.draft) {
    return (
      <StatusTag background="#d4e3fe" borderColor="#3e77df">
        <Info
          style={{
            height: '16px',
            color: '#3e77df',
            paddingRight: '7px',
          }}
        />
        changes not submitted
      </StatusTag>
    );
  }
};

const ProjectCard = (props) => {
  const {
    type,
    name,
    id,
    status,
    categoryId,
    publishedStep,
    isPublic,
    clone_status,
    deleted,
    newConsumption,
  } = props;

  const history = useHistory();

  const token = Cookies.get('jwt') || '';

  const { categories } = useCategories();

  const category = categories.find((e) => e.id === categoryId);

  const isDeletable = status !== data_placement_status_ids.active;

  const isConsumption = type === 'data-consumption';

  const isDataRoom = type === 'data-room';

  let { data: sources, error } = useSWR(
    [`/api/data-consumption-metadata?project_id=${id}`, token],
    fetcherWithToken,
  );

  if (isConsumption && error?.response.status === 404) {
    toast.error(<Notification type="error" text="Unauthorized Access" />);
    history.push('/not-found');
  }

  const nameTrimHandler = (name, length) => {
    let maxLength = length ? length : 20;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  const onClick = async ({ key }) => {
    if (key === 'edit') {
      history.push(`/project/${type}/${id}`);
    }
    if (key === 'delete') {
      const confirmation = await confirmModal({
        title: (
          <>
            Delete <Text strong>{name}</Text>?
          </>
        ),
        content: (
          <>
            <p>The data product will be deleted permanently.</p>
            <p>Are you sure you want to proceed?</p>
          </>
        ),
      });
      if (confirmation) {
        await axios.delete(`/api/products-delete/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        deleted(id);
        toast.success(
          <Notification
            type="success"
            text={`${name} deleted successfully!`}
          />,
        );
      }
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key={'edit'} type="edit" icon={<EditOutlined />}>
        Edit
      </Menu.Item>
      <Menu.Item
        key={'delete'}
        danger
        icon={<DeleteOutlined />}
        disabled={!isDeletable}
      >
        {!isDeletable ? (
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title={
              'Contact Mobito if you want to delete a published data product'
            }
          >
            Delete
          </Tooltip>
        ) : (
          'Delete'
        )}
      </Menu.Item>
    </Menu>
  );

  let randomColor = '#';
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    for (let i = 0; i < 6; i++) {
      randomColor += letters[Math.floor(Math.random() * 16)];
    }
    return randomColor + '54';
  };

  const splitName = (name) => {
    return name
      .split(' ')
      .map((i) => i.charAt(0).toUpperCase())
      .slice(0, 2);
  };

  if (!sources) return <div />;

  return (
    <SOuterContainer isConsumption={isConsumption} isDataRoom={isDataRoom}>
      {publishedStep === 2 && (
        <Wrap top>
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title="This listing is a high-level description of the data product. Integration details are yet to be provided."
          >
            <InfoCircleOutlined />
          </Tooltip>
          <Text style={{ marginLeft: '9px', color: '#64737f' }}>
            Light Version
          </Text>
        </Wrap>
      )}
      <SInnerContainer
        isConsumption={isConsumption}
        isDataRoom={isDataRoom}
        isNew={newConsumption}
      >
        {(type === 'data-wizard' || type === 'data-connection') && (
          <div
            style={{
              position: 'absolute',
              top: '12px',
              right: '4px',
              width: '20px',
            }}
          >
            <Dropdown overlay={menu} placement="bottomCenter" arrow>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <DotsVerticalRounded />
              </a>
            </Dropdown>
          </div>
        )}
        {isConsumption || isDataRoom ? (
          <>
            <Flexrow center_align style={{ width: '40%' }}>
              <div
                style={{
                  width: '84px',
                  height: '84px',
                  backgroundColor: getRandomColor(),
                  borderRadius: '10px',
                  margin: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '37px',
                    color: randomColor,
                  }}
                >
                  {splitName(name)}
                </Text>
              </div>
              <Flexcol center_content>
                <Text
                  style={{
                    color: '#182429',
                    fontSize: '13px',
                    fontWeight: '600',
                  }}
                >
                  {isConsumption ? 'DATA CONSUMPTION PROJECT' : 'DATA CAPSULE'}
                </Text>
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title={name}
                >
                  <Text
                    style={{
                      color: '#4A4A4A',
                      fontSize: '18px',
                      width: 'fit-content',
                    }}
                  >
                    {name && nameTrimHandler(name, 45)}
                  </Text>
                </Tooltip>
              </Flexcol>
            </Flexrow>
            {isConsumption && (
              <Flexcol center_align style={{ width: '20%' }}>
                <Text
                  style={{
                    color: '#182429',
                    fontSize: '24px',
                  }}
                >
                  {sources.length}
                </Text>
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '14px',
                  }}
                >
                  listing included
                </Text>
              </Flexcol>
            )}
          </>
        ) : (
          <>
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              placement="bottom"
              title={name ? name : 'Not available'}
            >
              <Title level={5} style={{ color: '#182429' }}>
                {name ? nameTrimHandler(name) : 'Not available'}
              </Title>
            </Tooltip>
            <Text style={{ color: '#64737f' }}>
              <Tag
                style={{
                  height: '16px',
                  transform: 'rotate(-90deg)',
                  color: '#c4c4c4',
                }}
              />
              {category ? category.name : 'Not available'}
            </Text>
            {getStatusTag(status)}
            {getCloneStatusTag(clone_status)}
          </>
        )}
        <div
          style={{
            width: isConsumption || isDataRoom ? '30%' : '90%',
            textAlign: (isConsumption || isDataRoom) && 'right',
            marginRight: (isConsumption || isDataRoom) && '65px',
          }}
        >
          {isDataRoom ? (
            <OpenDataCapsuleButton
              href={
                isDev
                  ? `http://localhost:4200/data-capsule/${id}`
                  : isQA
                  ? `http://platform-qa.mobito.io/data-capsule/${id}`
                  : isStaging
                  ? `http://platform-rc.mobito.io/data-capsule/${id}`
                  : `http://platform.mobito.io/data-capsule/${id}`
              }
            >
              Open Data Capsule
            </OpenDataCapsuleButton>
          ) : (
            <Link
              to={{
                pathname: `/project/${type}/${id}`,
              }}
            >
              <OpenButton
                isProduct={
                  type === 'data-wizard' ||
                  type === 'data-connection' ||
                  type === 'data-request-wizard' ||
                  type === 'data-request'
                }
              >
                {isConsumption ? 'Open project' : 'Open'}
              </OpenButton>
            </Link>
          )}
        </div>
      </SInnerContainer>
      {(type === 'data-request-wizard' || type === 'data-request') && (
        <Wrap bottom>
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title={
              isPublic
                ? 'Your data request is published in the marketplace.'
                : 'Your data request is not published in the marketplace.'
            }
          >
            {isPublic ? (
              <Eye style={{ width: '18px' }} />
            ) : (
              <EyeSlash style={{ width: '18px' }} />
            )}
          </Tooltip>
          <Text style={{ marginLeft: '9px', color: '#64737f' }}>
            {isPublic ? 'Public' : 'Private'}
          </Text>
        </Wrap>
      )}
    </SOuterContainer>
  );
};

export default ProjectCard;
