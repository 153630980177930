import styled from "styled-components";

import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { ArrowLeftShort } from "@styled-icons/bootstrap/ArrowLeftShort";

import { Flexcol, Flexrow } from "../../Flexbox";

export const ProductCard = styled(Flexrow)`
  align-content: center;
  background: rgb(255, 255, 255);
  border-radius: 8px;
`;

export const ProductContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 4px 12px 0px #182a330f;
`;

export const PricingColumn = styled(Flexcol)`
  width: 100%;
  border-right: ${(props) =>
    !props.noBorder ? "1px solid rgb(223, 229, 235)" : "1px solid #fff"};
  &:last-child {
    border-right: none;
  }
  border-bottom: 1px solid rgb(223, 229, 235);
  border-top: ${(props) =>
    props.selected && !props.notTop && "4px solid #CEFAC3"};
  border-right: ${(props) => props.selected && "4px solid #CEFAC3 !important"};
  border-left: ${(props) => props.selected && "4px solid #CEFAC3"};
`;

export const RequestsLimits = styled(Flexrow)`
  color: rgb(24, 42, 51);
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  width: 141px;
  font-family: poppins-semibold;
`;

export const Typography = styled.div`
  color: rgb(24, 42, 51);
  font-size: 14px;
  font-weight: normal;
  line-height: 60px;
  text-align: center;
  font-family: poppins-regular;
`;

export const TypographySection = styled.div`
  color: rgb(24, 42, 51);
  font-size: 14px;
  font-weight: normal;
  line-height: 60px;
  padding-left: 12px;
  font-family: poppins-regular;
`;

export const TypographyTitle = styled.span`
  color: rgb(24, 42, 51);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px 0px;
  border-left: ${(props) => !props.selected && "1px solid rgb(223, 229, 235)"};
  font-family: poppins-semibold;
`;

export const TypographyPrice = styled.span`
  color: rgb(23, 109, 177);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  margin-bottom: 8px;
  font-family: poppins-semibold;
`;

export const TypographyDetails = styled.span`
  color: rgb(23, 109, 177);
  font-family: poppins-regular;
  font-size: 12px;
  font-weight: normal;
  height: 20px;
  width: 48px;
  padding: 2px;
`;

export const TooltipIcon = styled.span`
  float: right;
  color: rgb(181 186 191);
  margin-right: 12px;
`;

export const RequestsColumn = styled(Flexcol)`
  width: 100%;
  background: rgb(243 243 243);
  font-weight: 600;
  border-bottom: 1px solid rgb(223, 229, 235);
  border-right: 1px solid rgb(223, 229, 235);
  &:last-child {
    border-right: none;
  }
  border-right: ${(props) => props.selected && "4px solid #CEFAC3 !important"};
  border-left: ${(props) => props.selected && "4px solid #CEFAC3"};
`;

export const RequestsSection = styled.div`
  color: rgb(24, 42, 51);
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  padding-left: 12px;
  font-family: poppins-semibold;
`;

export const LeftArrow = styled(ArrowLeftShort)`
  height: 40px;
  cursor: pointer;
  color: #344ef3 !important;
  border: 2px solid #344ef3;
  border-radius: 8px;
  margin-bottom: 6px;
  :hover {
    color: #fff !important;
    background-color: #344ef3;
  }
`;

export const RightArrow = styled(ArrowRightShort)`
  height: 40px;
  cursor: pointer;
  color: #344ef3 !important;
  border: 2px solid #344ef3;
  border-radius: 8px;
  margin-bottom: 6px;
  :hover {
    color: #fff !important;
    background: #344ef3;
  }
`;
