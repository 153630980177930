import React, { useContext } from "react";
import { PriceTag } from "@styled-icons/entypo/PriceTag";
import { ExchangeDollar } from "@styled-icons/remix-line/ExchangeDollar";

import { Flexcol } from "../../../../components/Flexbox";
import ItemCard from "../../../../components/listing-components/ItemCard";
import { DataRequestContext } from "../../../../helpers/DataRequestWizardHelper";

export const PricingTab = () => {
  const helper = useContext(DataRequestContext);

  const budget = helper.budget.value;
  const access_type = helper.accessType.value;

  return (
    <Flexcol>
      {budget && (
        <ItemCard title="Budget" icon={<PriceTag />}>
          {budget}
        </ItemCard>
      )}
      {access_type && (
        <ItemCard title="Transaction type" icon={<ExchangeDollar />}>
          {access_type === "_recurrent_"
            ? "Recurrent access"
            : access_type === "_one_off_"
            ? "One-off purchase"
            : access_type}
        </ItemCard>
      )}
    </Flexcol>
  );
};
