import styled from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';
import { colors } from '../../values/colors';
import { SText } from '../../components/styled-text/text';
import { TurnedIn } from 'styled-icons/material';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { withoutColPadding } from '../../styles/grid.js';
import { Link } from 'react-router-dom';
import { getThumbnailURL } from '../../iso-shared/utils/getThumbnailUrl';
import { access_key_status_ids } from '../../iso-shared/values/global';
import { Flexrow, Flexcol } from '../Flexbox';
import { listingType } from '../ListingTypeLabel';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { Delete } from '@styled-icons/typicons/Delete';

const { Text } = Typography;

const Wrap = styled(Row)`
  background-color: #ffffff;
  padding: 20px 28px;
  border-bottom: 2px solid ${colors['grey_1']};
  display: flex;
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: 2px 4px 12px rgba(24, 42, 51, 0.06);
  position: relative;
  justify-content: space-between;
`;

const LightVersionLabel = styled.div`
  border-radius: 10.5px;
  font-size: 12px;
  border: 1px solid rgb(4, 148, 221);
  padding: 2px 8px;
  margin-left: 15px;
  color: #0494dd;
  white-space: nowrap;
`;

const StatusTag = styled.div`
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 13px;
  padding: 4px 11px;
  margin: 0 auto;
  width: fit-content;
`;

const S3SourceCard = ({ source }) => {
  const imageUrl = getThumbnailURL(source.image_url);

  let lightVersionLabel = source.published_step === 2 && 'Light version';

  const nameTrimHandler = (name) => {
    let maxLength = 25;
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  };

  return (
    <ThemeProvider theme={withoutColPadding}>
      <Wrap middle="xs">
        <Flexrow center_align>
          <img
            width="83"
            height="84"
            alt="mobility-module-image"
            src={imageUrl}
            style={{ marginRight: '20px', padding: '10px' }}
          />
          <Flexcol
            style={{
              height: '100px',
            }}
          >
            <Flexrow center_align style={{ marginBottom: '12px' }}>
              <Text
                style={{
                  fontSize: '13px',
                  color: '#0494dd',
                  fontWeight: '600',
                }}
              >
                DATA PRODUCT
              </Text>
              {lightVersionLabel && (
                <LightVersionLabel>{lightVersionLabel}</LightVersionLabel>
              )}
              {listingType(source.type_id)}
            </Flexrow>
            <Flexrow
              center_align
              space_between
              style={{ marginBottom: '12px' }}
            >
              <Link to={`/data-product/${source.url_id}`} target="_blank">
                <Tooltip
                  color="#1a337d"
                  overlayInnerStyle={{
                    boxShadow: '2px 4px 12px 0px #182a331f',
                    fontSize: '13px',
                    borderRadius: '4px',
                  }}
                  placement="bottom"
                  title={source.name}
                >
                  <Text
                    style={{
                      color: '#182429',
                      fontSize: '18px',
                      letterSpacing: '-0.5px',
                    }}
                  >
                    {nameTrimHandler(source.name)}
                  </Text>
                </Tooltip>
              </Link>
            </Flexrow>
            <Flexrow center_align>
              <TurnedIn
                size="16"
                style={{ color: '#c4c4c4', marginRight: '2px' }}
              />
              <Text
                style={{
                  color: '#64737f',
                  fontSize: '15px',
                  letterSpacing: '-0.5px',
                }}
              >
                {source['data_placement_project.project_data_category.name']}
              </Text>
            </Flexrow>
          </Flexcol>
        </Flexrow>
        <Flexrow>
          <SText text_16 black bold>
            {source['platform_resource_access_key.status'] ==
            access_key_status_ids.pending_offline_process ? (
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title={
                  'Mobito team will contact you to arrange a call to discuss the details of your request as soon as possible.'
                }
              >
                <StatusTag background="#fff3d6" borderColor="#ffc540">
                  <ClockFill
                    style={{
                      height: '16px',
                      color: '#ffc540',
                      paddingRight: '7px',
                    }}
                  />
                  Pending offline progress
                </StatusTag>
              </Tooltip>
            ) : source['platform_resource_access_key.status'] ==
              access_key_status_ids.review ? (
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title="Your access request has been sent to the data provider for a review"
              >
                <StatusTag background="#fff3d6" borderColor="#ffc540">
                  <ClockFill
                    style={{
                      height: '16px',
                      color: '#ffc540',
                      paddingRight: '7px',
                    }}
                  />
                  Pending review by provider
                </StatusTag>
              </Tooltip>
            ) : source['platform_resource_access_key.status'] ==
              access_key_status_ids.rejected ? (
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title="Unfortunately, the data provider has rejected your access request. Please contact Mobito in order to source the right data for you"
              >
                <StatusTag background="#ffe9e9" borderColor="#fd5f5d">
                  <Delete
                    style={{
                      height: '24px',
                      color: '#fd5f5d',
                      paddingRight: '2px',
                    }}
                  />
                  Rejected by provider
                </StatusTag>
              </Tooltip>
            ) : source['platform_resource_access_key.status'] ==
              access_key_status_ids.pending_payment ? (
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title=""
              >
                <StatusTag background="#fff3d6" borderColor="#ffc540">
                  <ClockFill
                    style={{
                      height: '16px',
                      color: '#ffc540',
                      paddingRight: '7px',
                    }}
                  />
                  Payment pending
                </StatusTag>
              </Tooltip>
            ) : (
              <Tooltip
                color="#1a337d"
                overlayInnerStyle={{
                  boxShadow: '2px 4px 12px 0px #182a331f',
                  fontSize: '13px',
                  borderRadius: '4px',
                }}
                placement="bottom"
                title=""
              >
                <StatusTag background="#cefac3" borderColor="#5fdf3e">
                  <CheckCircleFill
                    style={{
                      height: '16px',
                      color: '#5fdf3e',
                      paddingRight: '7px',
                    }}
                  />
                  Active
                </StatusTag>
              </Tooltip>
            )}
          </SText>
        </Flexrow>
      </Wrap>
    </ThemeProvider>
  );
};

export default S3SourceCard;
