import React from 'react';
import { Typography } from 'antd';

import {
  CardWrap,
  ShowMoreButton,
  DescriptionContainer,
} from '../assets/styled-components';
import { strings } from '../assets/strings';
import { handleProductDescriptionModal } from '../modals/ProductDescriptionModalHandler';

const { Title } = Typography;

const ProductDescription = (props) => {
  const { productDescription } = props;

  return (
    <CardWrap>
      <Title level={4} style={{ marginBottom: 16, color: '#182a33' }}>
        {strings.dataset_details.product_desc}
      </Title>
      <DescriptionContainer
        dangerouslySetInnerHTML={{
          __html: productDescription,
        }}
      ></DescriptionContainer>
      <ShowMoreButton
        type="text"
        onClick={() => handleProductDescriptionModal(productDescription)}
      >
        show more
      </ShowMoreButton>
    </CardWrap>
  );
};

export default ProductDescription;
