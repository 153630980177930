import React, { useState, useEffect } from 'react';
import { Flexrow } from '../../Flexbox';
import { Chip } from '../assets/styled-components';
import { strings } from '../assets/strings';
import { CustomCheckbox } from '../components/CustomCheckbox';

const Geocoverage = (props) => {
  const {
    geocoverageSelections,
    geoDiscussionThreadsIds,
    geocoverageCheckedState,
    selectable,
    readOnly,
    dataRoomId,
    setRefresh,
  } = props;

  const [customSelectionChecked, setCustomSelectionChecked] = useState(false);

  useEffect(() => {}, [customSelectionChecked]);

  return (
    <Flexrow center_align style={{ width: '95%', flexWrap: 'wrap' }}>
      {geocoverageSelections?.map((item, index) => (
        <Chip
          key={index}
          style={{
            background:
              selectable && customSelectionChecked[index]
                ? 'rgb(230 247 255)'
                : 'rgb(235, 235, 237)',
            border:
              selectable && customSelectionChecked[index]
                ? '2px solid #1835CE'
                : '1px solid #49504F',
          }}
        >
          <CustomCheckbox
            key={index}
            selectionDiscussionThreadsIds={geoDiscussionThreadsIds}
            selectable={selectable}
            readOnly={readOnly}
            dataRoomId={dataRoomId}
            setRefresh={setRefresh}
            selectionIndex={index}
            selectionProperties={item}
            initialCheckedState={geocoverageCheckedState}
            category={strings.discussion_threads_categories.geocoverage}
            setCustomSelectionChecked={setCustomSelectionChecked}
          ></CustomCheckbox>
        </Chip>
      ))}
    </Flexrow>
  );
};

export default Geocoverage;
