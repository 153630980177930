import { Modal, Button } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ProceedButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #344ef3;
  color: #fff;
  font-size: 15px;
  padding: 10px 29px;
  height: initial;
`;

const CancelButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #344ef3;
  background-color: #fff;
  color: #344ef3;
  font-size: 15px;
  padding: 10px 29px;
  height: initial;
`;

export let confirmModal = ({ title, message, cancelText, okText }) => {};

export const ModalView = () => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState(null);
  const [cancelText, setCancelText] = useState(null);
  const [okText, setOkText] = useState(null);
  const [handlers, setHandlers] = useState({});
  useEffect(() => {
    confirmModal = ({ title, content, cancelText, okText }) =>
      new Promise((r) => {
        setContent(content);
        setTitle(title);
        setCancelText(cancelText);
        setOkText(okText);
        setHandlers({
          onOk: () => {
            setVisible(false);
            r(true);
          },
          onCancel: () => {
            setVisible(false);
            r(false);
          },
        });
        setVisible(true);
      });
  }, []);
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handlers.onCancel}
      bodyStyle={{ padding: '12px 24px 59px 24px' }}
      zIndex={1000001}
      footer={[
        <CancelButton key="back" onClick={handlers.onCancel}>
          {cancelText ? cancelText : 'Cancel'}
        </CancelButton>,
        <ProceedButton key="submit" type="primary" onClick={handlers.onOk}>
          {okText ? okText : 'OK, proceed'}
        </ProceedButton>,
      ]}
    >
      {content}
    </Modal>
  );
};
