import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

import { Home } from './page-components/Home';
import Login from './page-components/Login';
import Dashboard from './page-components/Dashboard';
import DataProductPageWizard from './page-components/Project/DataProductWizard';
import DataRequestPageWizard from './page-components/Project/DataRequestWizard';
import DataRequest from './page-components/Project/DataRequest';
import DataReview from './page-components/Project/DataReview';
import DataConsumption from './page-components/Project/DataConsumption';
import DataConnection from './page-components/Project/DataConnection';
import DataConsumptionWizard from './page-components/DataConsumptionWizard';
import { ProductListing } from './page-components/ProductListing';
import { RequestListing } from './page-components/RequestListing';
import DocumentationDescription from './page-components/ProductListing/tabs/SampleFilesAndDocumentation/DocumentationDescription';
import { Account } from './page-components/Account';
import { ForgotPassword } from './page-components/ForgotPassword';
import { VerifyToken } from './page-components/VerifyToken';
import Footer from './components/Footer';
import Terms from './page-components/Terms';
import { PasswordRecovery } from './page-components/PasswordRecovery';
import { ConfirmNewEmail } from './page-components/ConfirmNewEmail';
import PasswordUpdated from './components/forgot-password/PasswordUpdated';
import { NotFoundPage } from './page-components/NotFoundPage';
import { makeAuthenticatedView } from './views/makeAuthenticatedView';
import BackToTopButton from './components/BackToTopButton';
import DataRoom from './components/data-room/DataRoom';
import Verification from './components/signup/Verification';

import 'antd/dist/antd.css';
import './styles/main.css';
import 'react-json-pretty/themes/monikai.css';
import 'react-toastify/dist/ReactToastify.css';

const AuthDataRequestPageWizard = makeAuthenticatedView(DataRequestPageWizard);
const AuthDataProductPageWizard = makeAuthenticatedView(DataProductPageWizard);
const AuthDataConsumptionWizard = makeAuthenticatedView(DataConsumptionWizard);
const AuthDataRequest = makeAuthenticatedView(DataRequest);
const AuthDataReview = makeAuthenticatedView(DataReview);
const AuthDataConsumption = makeAuthenticatedView(DataConsumption);
const AuthDataConnection = makeAuthenticatedView(DataConnection);
const AuthAccount = makeAuthenticatedView(Account);
const AuthDashboard = makeAuthenticatedView(Dashboard);
const AuthDataRoom = makeAuthenticatedView(DataRoom);

const isProd = process.env.RAZZLE_BASE_URL === 'https://app.mobito.io';

const App = () => {
  const history = useHistory();

  if (isProd) {
    history.listen((location) => {
      window.gtag('set', 'page', location.pathname + location.search);
      window.gtag('send', 'pageview');
    });
  }

  return (
    <>
      <Helmet>
        <title>Mobito</title>
      </Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" render={() => <Login selectedTab={'1'} />} />
        <Route
          exact
          path="/signup"
          render={() => <Login selectedTab={'2'} />}
        />
        <Route
          exact
          path="/resend-verification"
          render={() => <Verification resendVerification />}
        />
        <Route exact path="/dashboard" component={AuthDashboard} />
        <Route
          exact
          path="/data-consumption-wizard"
          component={AuthDataConsumptionWizard}
        />
        <Route
          exact
          path="/project/data-wizard/:id"
          component={AuthDataProductPageWizard}
        />
        <Route
          exact
          path="/project/data-request-wizard/:id"
          component={AuthDataRequestPageWizard}
        />
        <Route
          exact
          path="/project/data-request/:id"
          component={AuthDataRequest}
        />
        <Route
          exact
          path="/project/data-review/:id"
          component={AuthDataReview}
        />
        <Route
          exact
          path="/project/data-consumption/:id"
          component={AuthDataConsumption}
        />
        <Route
          exact
          path="/project/data-connection/:id"
          component={AuthDataConnection}
        />
        <Route exact path="/module/:id" component={ProductListing} />
        <Route exact path="/data-product/:url_id" component={ProductListing} />
        <Route exact path="/data-request/:url_id" component={RequestListing} />
        <Route
          exact
          path="/data-product/:url_id/documentation"
          component={DocumentationDescription}
        />
        <Route exact path="/account" component={AuthAccount} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/verify-email/:verification_token"
          component={VerifyToken}
        />
        <Route
          exact
          path="/recover-password/:verification_token"
          component={PasswordRecovery}
        />
        <Route exact path="/confirm-new-email" component={ConfirmNewEmail} />
        <Route exact path="/password-updated" component={PasswordUpdated} />
        <Route
          exact
          path="/project/data-room/:data_room_id"
          component={AuthDataRoom}
        />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
      <BackToTopButton />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        closeButton={false}
      />
      <Footer />
    </>
  );
};

export default App;
