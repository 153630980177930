import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import styled from "styled-components";

import {
  Title,
  StepperCircleIndex,
  StepperCircleCheck,
} from "../wizards-components/styled-components";
import { Flexcol } from "../Flexbox";

const StepperLine = styled.div`
  border-bottom: 1px solid #c1c2c2;
  width: 100%;
  transform: translateY(-10px);
`;

const Step = (props) => {
  const isActive = props.current === props.index - 1;
  const isLast = props.index === props.total;
  const maskedValid = props.maskedValid && props.maskedValid;
  const licenseValid = props.licenseValid;
  const planValid = props.planValid;
  const paymentValid = props.paymentValid;
  const completionValid = props.completionValid;

  const opacityTwo = maskedValid && (maskedValid && props.index == 2 ? true : false);
  const opacityThree = licenseValid && props.index == (maskedValid ? 3 : 2) ? true : false;
  const opacityFour = planValid && props.index == (maskedValid ? 4 : 3) ? true : false;
  const opacityFive = paymentValid && props.index == (maskedValid ? 5 : 4) ? true : false;

  const stepperCircleHandler = () => {
    let stepperCircle;
    if (
      maskedValid && (maskedValid === props.index) ||
      licenseValid === props.index ||
      planValid === props.index ||
      paymentValid === props.index ||
      completionValid === props.index
    ) {
      return (stepperCircle = (
        <StepperCircleCheck active={isActive}>
          <Title size={22} color={isActive ? "#344ef3" : "#64737f"}>
            <CheckOutlined />
          </Title>
        </StepperCircleCheck>
      ));
    } else {
      return (stepperCircle = (
        <StepperCircleIndex
          active={isActive}
          style={
            opacityTwo && opacityTwo || opacityThree || opacityFour || opacityFive || props.index == 1
              ? { opacity: 1 }
              : { backgroundColor: "#e2e7ec", cursor: "not-allowed" }
          }
        >
          <Title size={22} weight={isActive ? "bold" : "normal"} color={"#fff"}>
            {props.index}
          </Title>
        </StepperCircleIndex>
      ));
    }
  };

  return (
    <>
      <Flexcol onClick={props.change} center_align style={{ width: "50%" }}>
        {stepperCircleHandler()}
        <Title size={15} weight="bold" color={isActive ? "#344ef3" : "#c1c2c2"} style={{ width: "max-content" }}>
          {props.title}
        </Title>
      </Flexcol>
      {!isLast && <StepperLine />}
    </>
  );
};

export default Step;
