import { useEffect, useState } from 'react';
import { Table, Typography, Divider, Tooltip } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';
import { UnselectItemModal } from '../modals/UnselectItemModal';
import { Flexrow, Flexcol } from '../../Flexbox';
import InfoSrc from '../../../images/attributes-info.svg';
import { CardWrap } from '../assets/styled-components';
import { strings } from '../assets/strings';
import { handleAttributesModal } from '../modals/AttributesModalHandler';
import { GeneralQuestion } from '../components/GeneralQuestion';
import styles from '../assets/CardWrapHighlight.module.css';
import {
  postDiscussionThread,
  deleteDiscussionThread,
} from '../chat/chat-services';
import Cookies from 'js-cookie';

const { Title } = Typography;

export const Attributes = (props) => {
  const {
    selectable,
    readOnly,
    generalSelections,
    attributesSelections,
    dataRoomId,
    setRefresh,
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [draftDiscussionThreadId, setDraftDiscussionThreadId] = useState();
  const [generalQuestionChecked, setGeneralQuestionChecked] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const token = Cookies.get('jwt');

  const CustomRow = (props) => {
    const rowRemovable = props.children[0]?.props?.record.removable;
    return (
      <>
        {!rowRemovable ? (
          <Tooltip
            color="rgba(26, 51, 125, 1)"
            placement="bottom"
            title={strings.clarifications.tooltip_active_discussion_thread}
            destroyTooltipOnHide="true"
          >
            <tr {...props} />
          </Tooltip>
        ) : (
          <tr {...props} />
        )}
      </>
    );
  };

  const columns = [
    selectable
      ? {
          title: 'ATTRIBUTE',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          render: (text) => <a>{text}</a>,
        }
      : {
          title: 'ATTRIBUTE',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
        },
    {
      title: 'UPDATE FREQUENCY',
      dataIndex: 'frequency',
      key: 'frequency',
    },
  ];

  const handleRemovable = () => {
    setVisibleModal(!visibleModal);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        postDiscussionThread(
          token,
          dataRoomId,
          record.name,
          strings.dataset_details.attributes,
        )
          .then((res) => {
            setRefresh((prevState) => !prevState);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (record.draft) {
          setDraftDiscussionThreadId(record.discussionThreadId);
          handleRemovable();
        }

        if (record.removable && !record.draft) {
          deleteDiscussionThread(token, record.discussionThreadId)
            .then((res) => {
              setRefresh((prevState) => !prevState);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    getCheckboxProps: (record) => ({
      showTooltip: record.removable === false,
      disabled: readOnly
        ? attributesSelections?.map((attribute) => attribute.name)
        : record.removable === false,
    }),
    hideSelectAll: true,
  };

  useEffect(() => {
    if (attributesSelections) {
      for (let index = 0; index < attributesSelections.length; index++) {
        attributesSelections[index].key = index + 1;
      }
    }
  }, [attributesSelections]);

  useEffect(() => {
    if (attributesSelections) {
      setSelectedRowKeys(
        attributesSelections
          ?.filter((attribute) => attribute.checked === true)
          .map((attribute) => attribute.key),
      );
    }
  }, [attributesSelections]);

  return (
    <CardWrap
      className={
        generalQuestionChecked && selectable
          ? styles.checked
          : styles.notChecked
      }
    >
      <Flexrow center_align space_between width="100%">
        <Flexcol>
          <GeneralQuestion
            title={strings.dataset_details.attributes}
            selectable={selectable}
            readOnly={readOnly}
            generalSelections={generalSelections}
            category={strings.discussion_threads_categories.generalAttributes}
            dataRoomId={dataRoomId}
            setRefresh={setRefresh}
            setGeneralQuestionChecked={setGeneralQuestionChecked}
          ></GeneralQuestion>
        </Flexcol>
        <Flexcol center_align>
          <Flexrow center_align>
            <ExpandOutlined
              style={{ marginRight: 18 }}
              onClick={() =>
                handleAttributesModal(attributesSelections, selectable)
              }
            />
            <Title level={5} style={{ marginBottom: 0, color: '#182a33' }}>
              {strings.dataset_details.attributes_full_page}
            </Title>
          </Flexrow>
        </Flexcol>
      </Flexrow>
      <Divider style={{ margin: '0', padding: '10px 0' }} />
      <Flexrow center_align bottom="10px">
        <Title level={5} style={{ marginBottom: 0, color: '#182a33' }}>
          {strings.dataset_details.attributes_standard}
        </Title>
        <img src={InfoSrc} style={{ marginLeft: 16 }} />
      </Flexrow>
      <UnselectItemModal
        discussionThreadId={draftDiscussionThreadId}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        setRefresh={setRefresh}
      ></UnselectItemModal>
      <Table
        rowSelection={selectable && { ...rowSelection }}
        columns={columns}
        dataSource={attributesSelections}
        scroll={{ x: 1500, y: 700 }}
        pagination={false}
        components={{
          body: {
            row: CustomRow,
          },
        }}
      />
    </CardWrap>
  );
};
