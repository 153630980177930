import React from 'react';
import styled, { css } from 'styled-components';
import TextColors from './text-colors';
import TextSizes from './text-sizes';
import TextWeights from './text-weights';
import Tooltip from './Tooltip';

const text_colors = Object.keys(TextColors);
const text_sizes = Object.keys(TextSizes);
const text_weights = Object.keys(TextWeights);

const Text = (props) => {
  const textRef = React.useRef(null);
  const ellipsis = props.ellipsis ? true : false;
  const [hasTooltip, setHasTooltip] = React.useState(false);

  //TODO: trigger in screen width change as well
  React.useEffect(() => {
    if (textRef.current) {
      setHasTooltip(textRef.current.offsetWidth < textRef.current.scrollWidth);
    }
  }, []);

  return (
    <>
      {ellipsis ? (
        <Tooltip disabled={!hasTooltip} {...props} message={props.children}>
          <TextContainer ref={textRef} {...props}>
            {props.children}
          </TextContainer>
        </Tooltip>
      ) : (
        <TextContainer {...props}>{props.children}</TextContainer>
      )}
    </>
  );
};

export const SText = styled(Text)``;

export const TextContainer = styled.span`
  ${(p) => {
    const color_prop = text_colors.find((prop) => p[prop]);
    return (
      color_prop &&
      css`
        color: ${TextColors[color_prop]};
      `
    );
  }}
  ${(p) => {
    const size_prop = text_sizes.find((prop) => p[prop]);
    return (
      size_prop &&
      css`
        font-size: ${TextSizes[size_prop]};
      `
    );
  }}
    ${(p) => {
    const weight_prop = text_weights.find((prop) => p[prop]);
    return (
      weight_prop &&
      css`
        font-weight: ${TextWeights[weight_prop]};
      `
    );
  }}
    ${(p) =>
    p.linebreaks &&
    css`
      white-space: pre-line;
    `}
    ${(p) =>
    p.center &&
    css`
      text-align: center;
    `}
    ${(p) =>
    p.left &&
    css`
      float: left;
      text-align: left;
    `}
    ${(p) =>
    p.right &&
    css`
      float: right;
      text-align: right;
    `}
    ${(p) =>
    p.inline &&
    css`
      display: inline-block;
    `}
    ${(p) =>
    p.underline &&
    css`
      text-decoration: underline;
    `}
    ${(p) =>
    p.ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    `}
    ${(p) =>
    p.reverseEllipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl;
      display: block;
      text-align: left;
    `}
    ${(p) =>
    p.uppercase &&
    css`
      text-transform: uppercase;
    `}
    ${(p) =>
    p.break &&
    css`
      word-break: break-word;
    `}
    ${(p) =>
    p.breakall &&
    css`
      word-break: break-all;
      overflow: hidden;
    `}
`;
