import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";

import { InfoCircleOutlined } from "@ant-design/icons";

import {
  ProductCard,
  PricingColumn,
  Typography,
  TypographyTitle,
  TypographyPrice,
  TypographyDetails,
  TooltipIcon,
  TypographySection,
  RequestsColumn,
  RequestsSection,
} from "./pricing-components";

const Wrap = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const RequestPaymentPlan = (props) => {
  const { _package } = props;

  return (
    <Wrap>
      <ProductCard>
        <PricingColumn>
          <TypographyTitle>Pricing per request</TypographyTitle>
          <TypographyPrice>
            <TypographyDetails>€</TypographyDetails>
            {_package.price?.toLocaleString()}
            <TypographyDetails>/request</TypographyDetails>
          </TypographyPrice>
        </PricingColumn>
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Minimum purchase
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Minimum number of API requests that you need to purchase"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.min_requests_purchased
              ? `${_package.min_requests_purchased.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>

      <ProductCard>
        <RequestsColumn>
          <RequestsSection>Requests limits</RequestsSection>
        </RequestsColumn>
        <RequestsColumn />
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per minute
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per minute"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.limit_minute
              ? `${_package.limit_minute.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per hour
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per hour"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.limit_hour
              ? `${_package.limit_hour.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per day
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per day"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.limit_day
              ? `${_package.limit_day.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
    </Wrap>
  );
};

export default RequestPaymentPlan;
