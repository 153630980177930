import React from "react";
import { Divider, Typography, Button } from "antd";
import styled from "styled-components";

import { Flexcol, Flexrow } from "../Flexbox";

const { Title } = Typography;

const ItemCardContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px #182a330f;
  width: 100%;
  margin-bottom: 26px;
`;

const ExpandButton = styled(Button)`
  color: #344ef3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  padding-right: calc(26px + 0.75em) !important;
  border: none !important;
  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
`;

const ItemCardChildrenContainer = styled(Flexcol)`
  padding: 21px 26px 0 26px;
  margin-bottom: 26px;
  color: #182a33;
  font-size: 14px;
`;

const IconWrap = styled.div`
  height: 32px;
  margin-right: 16px;
  color: #657480;
  & > svg {
    height: 100%;
  }
`;

const ItemCard = (props) => {
  const { title, icon, children, openTextModal } = props;

  return (
    <ItemCardContainer>
      <Flexrow top="22px" bottom="22px" left="26px" center_align>
        <IconWrap>{icon}</IconWrap>
        <Title style={{ margin: "unset", color: "#182429", fontSize: "14px" }}>
          {title}
        </Title>
        <Flexrow grow />
        {openTextModal && (
          <ExpandButton onClick={openTextModal}>Expand</ExpandButton>
        )}
      </Flexrow>
      <Divider style={{ margin: 0 }} />
      <ItemCardChildrenContainer>{children}</ItemCardChildrenContainer>
    </ItemCardContainer>
  );
};

export default ItemCard;
