import styled from "styled-components";
import { Input } from "antd";

import { scrollStyles } from "../styles/scrollStyles";

export const SampleBodyArea = styled(Input.TextArea)`
  background-color: #000;
  color: #0098ff;
  height: 200px !important;
`;

export const PublishedSampleRequestQueryArea = styled.div`
  max-width: 720px;
  color: #182a33;
  border: 1px solid #f8f8f8;
  overflow-x: auto;
  padding: 6px;
  max-height: 200px;
  white-space: pre;
  ${scrollStyles}
`;
