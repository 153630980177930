import useSWR from 'swr';
import { fetcher } from '../utils/fetcher';

const useCategories = () => {
  const { data, error } = useSWR('/api/categories', fetcher);

  const categories = data || [];
  const isPending = !data;
  return {
    categories,
    error,
    isPending,
  };
};

export default useCategories;
