import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { CheckCircleFill } from "@styled-icons/bootstrap/CheckCircleFill";
import { InfoCircle } from "@styled-icons/boxicons-solid/InfoCircle";
import { Warning } from "@styled-icons/entypo/Warning";
import { Delete } from "@styled-icons/typicons/Delete";

import { Flexrow } from "./Flexbox";

const { Text } = Typography;

export const Notification = (props) => {
  const { type, text } = props;
  const [messageStyle, setMessageStyle] = useState({});

  const messageIconHandler = () => {
    switch (type) {
      case "success":
        return setMessageStyle({
          icon: (
            <CheckCircleFill
              style={{
                height: "18px",
                color: "#fff",
                marginRight: "12px",
                marginLeft: "15px",
              }}
            />
          ),
          backgroundColor: "#5fdf3e",
        });
      case "info":
        return setMessageStyle({
          icon: (
            <InfoCircle
              style={{
                height: "18px",
                color: "#fff",
                marginRight: "12px",
                marginLeft: "15px",
              }}
            />
          ),
          backgroundColor: "#3e77df",
        });
      case "warning":
        return setMessageStyle({
          icon: (
            <Warning
              style={{
                height: "18px",
                color: "#fff",
                marginRight: "12px",
                marginLeft: "15px",
              }}
            />
          ),
          backgroundColor: "#ffc540",
        });
      case "error":
        return setMessageStyle({
          icon: (
            <Delete
              style={{
                height: "22px",
                color: "#fff",
                marginRight: "8px",
                marginLeft: "15px",
              }}
            />
          ),
          backgroundColor: "#fd5f5d",
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    messageIconHandler();
  }, []);

  return (
    <Flexrow center_align>
      {messageStyle.icon}
      <Text
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#fff",
        }}
      >
        {text}
      </Text>
    </Flexrow>
  );
};

export default Notification;
