import React from "react";
import { Col, Row, Typography, Tag, Tooltip } from "antd";
import { CheckCircle } from "styled-icons/fa-regular";
import styled, { css } from "styled-components";
import { colors } from "../values/colors";
import { getThumbnailURL } from "../iso-shared/utils/getThumbnailUrl";
import { data_placement_type_names } from "../iso-shared/values/global";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const Wrap = styled(Row)`
  height: 110px;
  width: 375px;
  background-color: white;
  border: 2px solid ${colors.grey_2};
  position: relative;
  padding: 0 16px;
  ${(p) =>
    p.selected &&
    css`
      background-color: ${colors.blue_1};
    `}
`;

const Img = styled.img`
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  height: 70px;
  width: 70px;
  border-radius: 4px;
  margin-right: 8px;
`;

const ListName = styled(Title)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 235px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const LinkText = styled(Text)`
  text-decoration: underline;
  color: ${colors.orange_4};
`;

const StyledIcon = styled(CheckCircle)`
  position: absolute;
  top: 8px;
  right: 12px;
  height: 16px;
  color: ${colors.grey_3};
  ${(p) =>
    p.selected &&
    css`
      color: #1aae9f;
    `}
`;

const StyledTag = styled(Tag)`
  position: absolute;
  bottom: 4px;
  right: 4px;
`;

export const SelectableListing = (p) => {
  const { selected, listing, onClick } = p;
  const { id, name, image_url, type_id, category_name, url_id } = listing;
  return (
    <Wrap selected={selected} align="middle">
      <StyledIcon selected={selected} />
      <StyledTag color="#1aae9f">
        {data_placement_type_names[type_id]}
      </StyledTag>
      <Col>
        <Img src={getThumbnailURL(image_url)} />
      </Col>
      <Col>
        <Row>
          <Col>
            <Tooltip title={name}>
              <ListName onClick={onClick} level={4}>
                {name}
              </ListName>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="secondary">{category_name}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to={`/data-product/${url_id}`}>
              <LinkText>show details</LinkText>
            </Link>
          </Col>
        </Row>
      </Col>
    </Wrap>
  );
};
