import React from 'react';
import { Typography, Table } from 'antd';

import { Flexcol, Flexrow } from '../../Flexbox';

const { Text, Title } = Typography;

const DataParametersModal = (props) => {
  const { name, title, attributes, selectable } = props;

  const columns = [
    selectable
      ? {
          title: 'ATTRIBUTE',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          render: (text) => <a>{text}</a>,
        }
      : {
          title: 'ATTRIBUTE',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
        },
    {
      title: 'UPDATE FREQUENCY',
      dataIndex: 'frequency',
      key: 'frequency',
    },
  ];

  return (
    <Flexcol>
      <Text style={{ color: '#182429' }}>{name}</Text>
      <Flexrow center_align>
        <Title style={{ marginTop: 8, color: '#182429' }} level={5}>
          {title}
        </Title>
      </Flexrow>
      <Table columns={columns} dataSource={attributes} pagination={false} />
    </Flexcol>
  );
};

export default DataParametersModal;
