import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { AccountContext } from './AccountContext';

const useUser = () => {
  const jwt = Cookies.get('jwt') || '';
  const account = JSON.parse(Cookies.get('account'));
  const history = useHistory();

  useEffect(() => {
    if (!jwt || !account) {
      history.push('/login');
    }
  }, [account]);

  const user_id = Cookies.get('user_id') || '';
  const email = Cookies.get('email') || '';

  return { email, token: jwt, user_id };
};

export default useUser;
