import { Typography } from 'antd';

import { Flexcol, Flexrow } from '../../Flexbox';
import AddQuestionSrc from '../../../images/add_question.svg';
import { strings } from '../assets/strings';
import { OpenRequestWizard } from './OpenRequestWizard';
const { Text, Title } = Typography;

export const AddRequest = () => {
  const onAddRequestClicked = () => {
    OpenRequestWizard();
  };
  return (
    <Flexcol
      className="add-request-container"
      style={{
        backgroundColor: 'rgba(246, 246, 246, 1)',
        border: '1px dashed #000000',
        borderRadius: '8px',
        padding: '5rem',
        marginBottom: '3rem',
      }}
      center_align
    >
      <div style={{ cursor: 'pointer' }} onClick={onAddRequestClicked}>
        <Title
          level={5}
          style={{
            marginBottom: 26,
            color: '#182429',
          }}
        >
          <em>{strings.create_requests.addRequestHeadline}</em>
        </Title>
        <Flexrow center_align>
          <img src={AddQuestionSrc} style={{ marginRight: 18 }} />
          <Text>{strings.create_requests.addRequest}</Text>
        </Flexrow>
      </div>
    </Flexcol>
  );
};
