import React, { useState } from 'react';
import { Divider } from 'antd';
import { CardWrap } from '../assets/styled-components';
import { strings } from '../assets/strings';
import { DatasetPropertiesList } from '../components/DatasetPropertiesList';
import { GeneralQuestion } from '../components/GeneralQuestion';
import styles from '../assets/CardWrapHighlight.module.css';

const DatasetProperties = (props) => {
  const {
    selectable,
    readOnly,
    generalSelections,
    datasetPropertiesSelections,
    dataRoomId,
    datasetPropertiesCheckedState,
    datasetPropertiesDiscussionThreadsIds,
    setRefresh,
  } = props;

  const [generalQuestionChecked, setGeneralQuestionChecked] = useState(false);

  return (
    <CardWrap
      className={
        generalQuestionChecked && selectable
          ? styles.checked
          : styles.notChecked
      }
    >
      <GeneralQuestion
        title={strings.dataset_details.dataset_properties}
        selectable={selectable}
        readOnly={readOnly}
        generalSelections={generalSelections}
        category={
          strings.discussion_threads_categories.generalDatasetProperties
        }
        dataRoomId={dataRoomId}
        setRefresh={setRefresh}
        setGeneralQuestionChecked={setGeneralQuestionChecked}
      ></GeneralQuestion>
      <Divider style={{ margin: '0', padding: '10px 0' }} />
      <DatasetPropertiesList
        datasetPropertiesSelections={datasetPropertiesSelections}
        datasetPropertiesDiscussionThreadsIds={
          datasetPropertiesDiscussionThreadsIds
        }
        datasetPropertiesCheckedState={datasetPropertiesCheckedState}
        selectable={selectable}
        readOnly={readOnly}
        dataRoomId={dataRoomId}
        setRefresh={setRefresh}
      ></DatasetPropertiesList>
    </CardWrap>
  );
};

export default DatasetProperties;
