import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Typography, Menu, Checkbox, Select, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import LazyLoad, { forceVisible } from 'react-lazyload';
import { Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import {
  HomeProductListItem,
  HomeRequestListItem,
} from '../components/HomeListItem';
import SearchBarIndexUI from '../components/SearchBarIndexUI';
import LayoutRow from '../components/LayoutRow.jsx';
import Header from '../components/Header';
import ContactBanner from '../components/ContactBanner';
import { useServerData } from '../hooks/useServerData';
import background_marketplace_img from '../images/Signup_background.webp';
import { Flexcol } from '../components/Flexbox';
import useCategories from '../hooks/useCategories';

const { Text } = Typography;
const { SubMenu } = Menu;

const HomepageWall = styled(Flexcol)`
  background-image: url(${background_marketplace_img});
  background-size: cover;
  background-position: right bottom;
  align-items: center;
`;

const DataTypeWrap = styled(Menu.Item)`
  background-color: #fff !important;
  margin: 0px !important;
  padding-left: 0px !important;
  padding: 0 0 0 28px !important;
  height: 50px !important;
  width: 100% !important;
  &::after {
    border-right: none !important;
  }
`;

const GeoCoverageWrap = styled(Menu.Item)`
  background-color: #fff !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-left: 0 !important;
  padding: 0 5px 0 5px !important;
  height: max-content !important;
  width: 100% !important;
  text-align: center;
  &::after {
    border-right: none !important;
  }
`;

const DataCategoryWrap = styled(Menu.Item)`
  background-color: #fff !important;
  margin: 0px !important;
  padding-left: 0 !important;
  padding: 0 0 0 28px !important;
  height: max-content !important;
  width: 100% !important;
  &::after {
    border-right: none !important;
  }
`;

const empty_filters = {
  types: [],
  coverage: [],
  categories: [],
};

export const Home = () => {
  const server_data_products = useServerData('data_products');
  const server_data_requests = useServerData('data_requests');
  const [data_products, setDataProducts] = useState(server_data_products);
  const [data_requests, setDataRequests] = useState(server_data_requests);
  const history = useHistory();
  const { categories } = useCategories();
  const [filters, setFilters] = useState(empty_filters);
  const [isLoading, setIsLoading] = useState(true);
  const [continents, setContinents] = useState([]);
  const [countries, setCountries] = useState([]);

  const has_filters =
    filters.types.length ||
    filters.coverage.length ||
    filters.categories.length;

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true);
      const res = await axios.get('/api/countries');
      const continents = [...new Set(res.data.map((item) => item.continent))];
      setCountries(res.data);
      setContinents(continents);
      setIsLoading(false);
    };
    fetchCountries();
  }, []);

  const continentStringFormat = (string) => {
    let splitString = string.toLowerCase().replace('_', ' ').split(' ');

    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join(' ');
  };

  const filtered_data_products = useMemo(() => {
    const { types, coverage, categories } = filters;
    if (types.length && !types.includes('data_offering')) {
      return [];
    }
    if (!coverage.length && !categories.length) {
      return data_products;
    }
    return data_products?.filter((e) => {
      if (coverage.length) {
        if (!e.data_specs) {
          return false;
        }

        const countriesInContinents = [];
        const continentOfCountry = [];
        let match;

        coverage.map((item) => {
          countries.filter((country) => {
            if (item === country.continent) {
              countriesInContinents.push(country.name);
            }
          });
        });

        coverage.map((item) => {
          countries.filter((country) => {
            if (item === country.name) {
              continentOfCountry.push(country.continent);
            }
          });
        });

        if (countriesInContinents.length) {
          match = countriesInContinents.find((c) =>
            e.data_specs.geo_coverage.includes(c),
          );
          if (!match) {
            match = coverage.find((c) => e.data_specs.geo_coverage.includes(c));
          }
          if (!match) {
            match = continentOfCountry.find((c) =>
              e.data_specs.geo_coverage.includes(c),
            );
          }
        } else {
          match = coverage.find((c) => e.data_specs.geo_coverage.includes(c));
          if (!match) {
            match = continentOfCountry.find((c) =>
              e.data_specs.geo_coverage.includes(c),
            );
          }
        }

        if (!match) {
          return false;
        }
      }
      if (categories.length) {
        if (!categories.includes(e.category_name)) {
          return false;
        }
      }
      return true;
    });
  }, [data_products, filters]);

  const filtered_data_requests = useMemo(() => {
    const { types, coverage, categories } = filters;
    if (types.length && !types.includes('data_demand')) {
      return [];
    }
    if (!coverage.length && !categories.length) {
      return data_requests;
    }
    return data_requests?.filter((e) => {
      if (coverage.length) {
        if (!e.data_specs) {
          return false;
        }

        const countriesInContinents = [];
        const continentOfCountry = [];
        let match;

        coverage.map((item) => {
          countries.filter((country) => {
            if (item === country.continent) {
              countriesInContinents.push(country.name);
            }
          });
        });

        coverage.map((item) => {
          countries.filter((country) => {
            if (item === country.name) {
              continentOfCountry.push(country.continent);
            }
          });
        });

        if (countriesInContinents.length) {
          match = countriesInContinents.find((c) =>
            e.data_specs.geo_coverage.includes(c),
          );
          if (!match) {
            match = coverage.find((c) => e.data_specs.geo_coverage.includes(c));
          }
          if (!match) {
            match = continentOfCountry.find((c) =>
              e.data_specs.geo_coverage.includes(c),
            );
          }
        } else {
          match = coverage.find((c) => e.data_specs.geo_coverage.includes(c));
          if (!match) {
            match = continentOfCountry.find((c) =>
              e.data_specs.geo_coverage.includes(c),
            );
          }
        }
        if (!match) {
          return false;
        }
      }
      if (categories.length) {
        if (!categories.includes(e.category_name)) {
          return false;
        }
      }
      return true;
    });
  }, [data_requests, filters]);

  useEffect(() => {
    const fetchDataProducts = async () => {
      if (!data_products) {
        setIsLoading(true);
        const res = await axios.get('api/metadata-search');
        setDataProducts(res.data);
      }
      setIsLoading(false);
    };
    fetchDataProducts();
  }, []);

  useEffect(() => {
    const fetchDataRequests = async () => {
      if (!data_requests) {
        setIsLoading(true);
        const res = await axios.get('api/requests-metadata-search');
        setDataRequests(res.data);
      }
      setIsLoading(false);
    };
    fetchDataRequests();
  }, []);

  const createProject = async () => {
    const new_id = uuid();
    history.push(`/project/data-wizard/${new_id}`);
  };

  const createRequestProject = async () => {
    const new_id = uuid();
    history.push(`/project/data-request-wizard/${new_id}`);
  };

  const setFilterValues = (field) => (values) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: values,
    }));
    forceVisible();
    window.scrollTo({ top: 350, behavior: 'smooth' });
  };

  const clearFilters = () => setFilters(empty_filters);

  useEffect(() => {
    let filter = history.location.state?.filter;
    if (filter && data_products) {
      setFilters((prevState) => ({
        ...prevState,
        categories: [history.location.state?.filter],
      }));
      forceVisible();
    }
    setTimeout(() => {
      history.replace();
    }, 500);
  }, [data_products]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <LayoutRow background={'#fff'} withBorder>
        <Header withNav stepsFor="HOMEPAGE" forceVisible={forceVisible} />
      </LayoutRow>
      <HomepageWall>
        <Text
          style={{
            color: '#fff',
            fontSize: '24px',
            fontWeight: '600',
            marginTop: '56px',
          }}
        >
          This is the Mobito Data Marketplace
        </Text>
        <Text
          style={{
            color: '#fff',
            fontSize: '24px',
            marginBottom: '48px',
          }}
        >
          Access external mobility data in a seamless way
        </Text>
        <SearchBarIndexUI
          setDataProducts={setDataProducts}
          setDataRequests={setDataRequests}
          setForceVisible={forceVisible}
        />
      </HomepageWall>
      <LayoutRow background="#f9faff">
        <Row style={{ margin: '48px 0px 200px', position: 'relative' }}>
          {!!has_filters && (
            <Text
              style={{
                position: 'absolute',
                top: '-30px',
                left: '145px',
                color: '#344ef3',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              onClick={clearFilters}
            >
              Clear filters <CloseOutlined style={{ paddingLeft: '12px' }} />
            </Text>
          )}
          <Col xs={2}>
            <Menu
              defaultOpenKeys={['sub1', 'sub2', 'sub3']}
              mode="inline"
              style={{
                width: '256px',
                borderRadius: '8px',
                boxShadow: '2px 4px 12px 0px rgba(24, 42, 51, 0.12)',
                position: 'sticky',
                top: '40px',
              }}
              id="homepage-filter"
            >
              <SubMenu
                key="sub1"
                title="Listing type"
                style={{ fontSize: '14px', fontWeight: '600' }}
              >
                <DataTypeWrap key="1">
                  <Checkbox.Group
                    value={filters.types}
                    onChange={setFilterValues('types')}
                  >
                    <Checkbox
                      value="data_offering"
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      Data product
                    </Checkbox>
                    <br />
                    <Checkbox
                      value="data_demand"
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      Data request
                    </Checkbox>
                  </Checkbox.Group>
                </DataTypeWrap>
              </SubMenu>
              <SubMenu
                key="sub2"
                title="Geographical coverage"
                style={{ fontSize: '14px', fontWeight: '600' }}
              >
                <GeoCoverageWrap key="2">
                  <Select
                    value={filters.coverage}
                    mode="multiple"
                    onChange={setFilterValues('coverage')}
                    style={{
                      width: '90%',
                      marginTop: '12px',
                      fontSize: '15px',
                      fontWeight: '400',
                    }}
                    placeholder="Select"
                  >
                    {continents.map((item) => (
                      <Select.Option
                        key={item}
                        value={item}
                        style={{ fontSize: '14px', fontWeight: '400' }}
                      >
                        {continentStringFormat(item)}
                      </Select.Option>
                    ))}
                    {countries.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={item.name}
                        style={{ fontSize: '14px', fontWeight: '400' }}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </GeoCoverageWrap>
              </SubMenu>
              <SubMenu
                key="sub3"
                title="Data category"
                style={{ fontSize: '14px', fontWeight: '600' }}
              >
                <DataCategoryWrap key="3">
                  <Checkbox.Group
                    value={filters.categories}
                    onChange={setFilterValues('categories')}
                  >
                    {categories.map((category) => (
                      <>
                        <Checkbox
                          key={category.id}
                          value={category.name}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {category.name}
                        </Checkbox>
                        <br />
                      </>
                    ))}
                  </Checkbox.Group>
                </DataCategoryWrap>
              </SubMenu>
            </Menu>
          </Col>
          {isLoading ? (
            <Col xs={9}>
              <Row center="xs" style={{ marginTop: '15%' }}>
                <Spin size="large" />
              </Row>
            </Col>
          ) : (
            <Col xsOffset={1} xs={9}>
              <Row id="homepage-listing">
                {filtered_data_products && (
                  <>
                    {filtered_data_products.map((module) => (
                      <Col xs={6} key={module.id} style={{ paddingBottom: 20 }}>
                        <LazyLoad height={200} once offset={100}>
                          <HomeProductListItem
                            key={module.id}
                            module={module}
                          />
                        </LazyLoad>
                      </Col>
                    ))}
                  </>
                )}
                {filtered_data_requests && (
                  <>
                    {filtered_data_requests.map((request) => (
                      <Col
                        xs={6}
                        key={request.id}
                        style={{ paddingBottom: 20 }}
                      >
                        <LazyLoad height={200} once offset={100}>
                          <HomeRequestListItem data_request={request} />
                        </LazyLoad>
                      </Col>
                    ))}
                  </>
                )}
                {!filtered_data_products?.length > 0 &&
                  !filtered_data_requests?.length > 0 && (
                    <Text
                      style={{
                        color: '#182429',
                        fontSize: '17px',
                        margin: '45px auto 60px',
                        textAlign: 'center',
                      }}
                    >
                      No results found. If you can’t find what you are looking
                      for,
                      <br />
                      <Text
                        style={{ cursor: 'pointer' }}
                        underline
                        onClick={() => createRequestProject()}
                      >
                        request data
                      </Text>{' '}
                      or{' '}
                      <Text
                        style={{ cursor: 'pointer' }}
                        underline
                        onClick={() => createProject()}
                      >
                        provide data
                      </Text>
                      .
                    </Text>
                  )}
              </Row>
            </Col>
          )}
        </Row>
      </LayoutRow>
      <ContactBanner />
    </>
  );
};
