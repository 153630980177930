import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsLink,
} from '../../styles/termsAndConditions';

const DisputesTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        D
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ISPUTES</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              In the event of a conflict between the Data Provider and the Data
              Customer, the Data Provider or the Data Customer have the right to
              file a complaint with Mobito at{' '}
              <TermsAndConditionsLink
                href="mailto:legal@mobito.io"
                target="_blank"
              >
                legal@mobito.io
              </TermsAndConditionsLink>
              . The complaint shall contain the reason for the complaint and the
              actions that the Data Provider or Data Customer might have already
              undertaken to resolve the conflict. Mobito will attempt to
              facilitate between the Data Provider and the Data Customer on the
              basis of the complaint and supporting information, without however
              guaranteeing that a solution can be reached. The ultimate
              responsibility for the conflict resolution shall remain with the
              Data Provider and Data Customer.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito cannot be held responsible for any disputes between the
              Data Provider and the Data Customer.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default DisputesTerms;
