import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-styled-flexboxgrid";
import { Dropdown, Menu, Button, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SModuleImage } from "../module-image";
import { SText } from "../styled-text/text";
import {
  TurnedIn,
  InsertDriveFile,
  CloudDownload,
} from "styled-icons/material";
import { colors } from "../../values/colors";
import SummaryWizard from "../../views/summary-wizard";
import { category_name } from "../../values/categories";
import Screen from "../../views/screen";
import { SCardContainer } from "../CardContainer";
import { RequestAccessCard } from "../module/RequestAccessCard";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import JSONPretty from "react-json-pretty";
import { data_placement_status_ids } from "../../iso-shared/values/global";
import { getSampleFileURL } from "../../iso-shared/utils/getSampleFileUrl";
import { getThumbnailURL } from "../../iso-shared/utils/getThumbnailUrl";
import { Card } from "../module/Card";
import { limitFileName } from "../../utils/utils";

const { Text } = Typography;

const Wrap = styled(Row)`
  width: 100%;
`;

const Filename = styled((p) => <SText text_16 black extra_bold {...p} />)`
  :hover {
    color: ${colors.orange_4};
    text-decoration: underline;
  }
`;

const ProjectDescContainer = styled.div`
  font-size: 20px;
  word-break: break-word;
`;

const SCircle = styled.div`
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const WizardReview = (props) => {
  const { project } = props;
  const history = useHistory();

  const endpoints =
    project.proxy && project.proxy.platform_resource_proxy_api_endpoints;

  const [selected_endpoint, setSelectedEndpoint] = useState(null);

  const imageUrl = getThumbnailURL(project.placement.image_url);

  const liveStatus = data_placement_status_ids.active;

  const endpoint =
    selected_endpoint &&
    endpoints.find((e) =>
      e.form_id ? e.form_id == selected_endpoint : e.id === selected_endpoint
    );

  const endpoint_name =
    endpoint && endpoint.http_method + " " + endpoint.endpoint;

  const token = Cookies.get("jwt") || "";

  const clickAccessCard = (id) => {
    if (token) {
      history.push(`/data-consumption-wizard?selected_listing=${id}`);
    } else {
      history.push("/login");
    }
  };

  const endpoints_menu = endpoints && (
    <Menu onClick={(e) => setSelectedEndpoint(e.key)}>
      {endpoints.map((e) => (
        <Menu.Item key={e.form_id || e.id}>
          {e.http_method} {e.endpoint}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Wrap between="xs" style={{ marginTop: "18px", marginBottom: "28px" }}>
      <Col xs={6}>
        <Row style={{ marginBottom: 38 }} top="xs">
          <SModuleImage
            width="112"
            height="112"
            alt="mobility-module-image"
            src={imageUrl}
          />
          <Col xs={12} md={8}>
            <Row>
              <SText text_32 black extra_bold ellipsis>
                {props.project.placement.name}
              </SText>
            </Row>
            <Row middle="xs">
              <Col xs={12}>
                <TurnedIn size="24" style={{ color: colors["orange_4"] }} />
                <SText text_12 semi bold>
                  {category_name[props.project.placement.category_id]}
                </SText>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <SText text_24 black bold>
            Description
          </SText>
        </Row>
        <Row style={{ marginBottom: 32 }}>
          <ProjectDescContainer
            dangerouslySetInnerHTML={{
              __html: props.project.placement.description,
            }}
          ></ProjectDescContainer>
        </Row>
        <Row>
          {endpoints_menu && (
            <Dropdown overlay={endpoints_menu}>
              <Button style={{ marginBottom: 20 }} size="large">
                {endpoint_name || "Select resource"} <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Row>
        <Row style={{ marginBottom: 32 }}>
          {endpoint && (
            <Row>
              <Col xs={12} style={{ width: "800px", marginBottom: "18px" }}>
                <Text style={{ fontSize: 20 }}>{endpoint.description}</Text>
                <div style={{ marginBottom: 12 }} />
                <Screen name={endpoint.endpoint}>
                  <JSONPretty
                    id="json-pretty"
                    data={endpoint.sample_data}
                  ></JSONPretty>
                </Screen>
              </Col>
            </Row>
          )}
        </Row>
        {/* file */}
        <Row>
          <Col xs={10}>
            {props.project.files &&
              props.project.files.map((item) => {
                return (
                  <SCardContainer style={{ marginBottom: "18px" }}>
                    <Row between="xs" middle="xs">
                      <Col xs={7}>
                        <Row start="xs">
                          <Col xs={1}>
                            <InsertDriveFile
                              size="24"
                              style={{ color: colors.orange_4 }}
                            />
                          </Col>
                          <Col style={{ display: "flex" }} xs={11}>
                            <SText text_20 black extra_bold ellipsis>
                              {item.filename}
                            </SText>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={5}></Col>
                    </Row>
                  </SCardContainer>
                );
              })}
          </Col>
        </Row>
      </Col>
      <Col xs={4}>
        <Row style={{ marginBottom: 28 }}>
          <Col xs={12}>
            <SummaryWizard module={props} />
          </Col>
        </Row>
        {props.project.sample_files && (
          <Row style={{ marginBottom: 28 }}>
            <Col xs={12}>
              <Card>
                <Row>
                  <SText text_24 black extra_bold>
                    Sample files
                  </SText>
                </Row>
                <Col style={{ paddingTop: 24 }}>
                  {props.project.sample_files.map((item) => (
                    <Row style={{ marginBottom: "18px" }}>
                      <Col xs={1}>
                        <InsertDriveFile
                          size="24"
                          style={{ color: colors.orange_4 }}
                        />
                      </Col>
                      <Col style={{ display: "flex" }} xs={10}>
                        <a href={getSampleFileURL(item.file_id)}>
                          <Filename>{limitFileName(item.file_name)}</Filename>
                        </a>
                      </Col>
                      <Col xs={1}>
                        <a href={getSampleFileURL(item.file_id)}>
                          <CloudDownload
                            size="24"
                            style={{ color: colors.orange_4 }}
                          />
                        </a>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            {props.project.placement.status_id === liveStatus && (
              <RequestAccessCard
                id={props.project.placement.id}
                onClick={() => clickAccessCard(props.project.placement.id)}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Wrap>
  );
};

export default WizardReview;
