import React from 'react';
import styled from 'styled-components';
import { Typography, Divider } from 'antd';

import { RightCircleOutlined } from '@ant-design/icons';

import { Flexcol, Flexrow } from '../components/Flexbox';

const { Text } = Typography;

const RightCircle = styled(RightCircleOutlined)`
  padding-right: 30px;
  font-size: 22px;
  color: #182a33;
`;

const CardWrap = styled(Flexrow)`
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: 1px solid #fff;
  width: 65%;
  cursor: pointer;
  &:hover {
    border: 1px solid #344ef3;
  }
  &:hover ${RightCircle} {
    color: #344ef3 !important;
  }
`;

export const CompanySettings = (props) => {
  const { onSetTab } = props;

  return (
    <Flexcol style={{ width: '65%' }} center_align>
      <Flexcol style={{ width: '100%' }} top="72px">
        <Text style={{ color: '#182429', fontSize: '28px' }}>
          Company settings
        </Text>
        <Text style={{ color: '#64737F', fontSize: '15px' }}>
          Here you can edit your company settings.
        </Text>
        <Divider />
      </Flexcol>
      <CardWrap
        top="26px"
        id="company-settings-account"
        center_align
        space_between
        onClick={() => onSetTab('companyAccountDetails')()}
      >
        <Text
          style={{ color: '#182A33', fontSize: '17px', padding: '26px 30px' }}
        >
          Account details
        </Text>
        <RightCircle />
      </CardWrap>
      <CardWrap
        id="company-settings-people"
        top="16px"
        center_align
        space_between
        onClick={() => onSetTab('companyUsers')()}
      >
        <Text
          style={{ color: '#182A33', fontSize: '17px', padding: '26px 30px' }}
        >
          People
        </Text>
        <RightCircle />
      </CardWrap>
    </Flexcol>
  );
};
