import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsOrderedListEnglish,
  TermsAndConditionsOrderedListItemEnglish,
} from '../../styles/termsAndConditions';

const ClientResponsibilitiesWarrantiesTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        C
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>LIENT </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>R</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ESPONSIBILITIES{' '}
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>A</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ND </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsHeaderLarge smaller>W</TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>ARRANTIES</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem inner flexWrap>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Client represents and warrants that:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsListSection block>
            <TermsAndConditionsOrderedListEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    the Authorized User or person accepting the Agreement has
                    the necessary authority or permission to enter into and
                    execute it on behalf of the Client;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    it complies with all applicable laws, in particular, if
                    applicable, the Applicable Data Protection Laws, when it
                    uses the Data;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    the listing/licensing or use of Data does not violate any
                    third party rights, including third party Intellectual
                    Property Rights;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    it shall respect the terms of the Data Agreement and the
                    Order Form.
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
            </TermsAndConditionsOrderedListEnglish>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem inner flexWrap>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Customer shall be responsible for:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsListSection block>
            <TermsAndConditionsOrderedListEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    verifying the suitability of the Data for its intended
                    purposes;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    verifying it has received all the information and advice
                    needed in order to enter into the Data Agreement with the
                    Data Provider;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
            </TermsAndConditionsOrderedListEnglish>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner flexWrap>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Provider warrants and represents that:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsListSection block>
            <TermsAndConditionsOrderedListEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    it has all rights, interest or title or all necessary
                    permissions and authorizations to list the Data on the
                    Platform and provide Mobito the permission to sub(license)
                    or resell the Data to the Data Customer for the Data
                    Preparation Services;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    the Data is suitable for the use that the Data Provider has
                    marketed it for or the use as specified in a Data Agreement;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    the Data is authentic, complete, correct and accurate, and
                    if this would no longer be the case, that it will update the
                    Data to make it authentic, complete, accurate and correct.
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
            </TermsAndConditionsOrderedListEnglish>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner flexWrap>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Provider shall:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsListSection block>
            <TermsAndConditionsOrderedListEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    provide the specifications of the Data, such as the type of
                    Data (e.g. historical and/or live Data), the format of the
                    Data, delivery method of the Data (e.g. via API or another
                    method), frequency of Data delivered; and pricing
                    information for the Data;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    provide a Data Agreement which shall be displayed on the
                    Platform by Mobito;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    provide its API documentation, if applicable;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    resolve any technical issues with the Data detected by
                    Mobito or the Data Customer in accordance with the Data
                    Provider’s service level agreements;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    provide the Data Customer support for issues arising from
                    the Data Provider’s Data placement as per what is set out in
                    the Data Agreement. In the event of a dispute between the
                    Data Provider and the Data Customer, the dispute resolution
                    procedure as set out in article 14 (Dispute resolution) will
                    apply;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    provide accurate information, update listings and respond to
                    any messages and requests;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    ensure that Mobito is provided with all necessary
                    circumstances, information and facts which are or could be
                    of importance in connection with the Data or Services agreed
                    upon without unnecessary delay.
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
            </TermsAndConditionsOrderedListEnglish>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner flexWrap>
          <TermsAndConditionsListSection width="95%">
            <TermsAndConditionsParagraph>
              Mobito is entitled to (i) suspend the Client’s access to the
              Platform and the Services, or (ii) limit the availability of Data
              on the Platform or (iii) delete Data from the Platform or (iv) to
              prevent Data from being accessed by Data Customers, if:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsListSection block>
            <TermsAndConditionsOrderedListEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    the Data does not meet the agreed specifications of the Data
                    on the Platform as set out in article 4.4. (a);
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    if Mobito has reason to believe the provided Data to be
                    illegal or in violation of the warranties as set out in this
                    article, or;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    if a third party or the Data Customer makes a complaint
                    about the Data;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    If the Data Provider is unresponsive to the requests of
                    Mobito and the users of the Platform;
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
              <TermsAndConditionsOrderedListItemEnglish flex inner>
                <TermsAndConditionsListSection>
                  <TermsAndConditionsParagraph>
                    If the Data Provider has breached article 4.7.
                  </TermsAndConditionsParagraph>
                </TermsAndConditionsListSection>
              </TermsAndConditionsOrderedListItemEnglish>
            </TermsAndConditionsOrderedListEnglish>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Without prejudice to Mobito’s right to claim damages or to other
              remedies, in case the Client violates these warranties or in case
              of a third party claim against Mobito due to violation of the
              warranties, the Client will indemnify, defend and hold Mobito
              harmless against any and all damages, costs, expenses, losses,
              fines, claims and suits.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Data Provider acknowledges and agrees that - for a period of
              twenty four (24) months following the date of a Data transaction
              with the Data Customer - all transactions relating to such Data or
              similar type of data with the Data Customer shall be conducted
              solely through the Platform. The Data Provider agrees that it will
              not attempt to circumvent the Platform during this period of
              twenty four (24) months by entering into any transaction of Data
              or similar type of data directly with the Data Customer outside of
              the Platform. Any attempt to do so shall be considered a breach of
              this Agreement unless the Data Provider compensates Mobito with a
              commission equal to the sum if the transaction had occurred
              through the Platform. In the event that Mobito determines that the
              Data Provider has engaged in any conduct that violates this
              article, Mobito may, in its sole discretion, charge a fixed sum of
              50.000,00 EUR to the Data Provider for each violation as
              compensation for the missed business opportunity and pursue any
              other legal remedies available.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default ClientResponsibilitiesWarrantiesTerms;
