import { API_LINK_ID, S3_DATA } from '../../../iso-shared/values/global';
import {
  fetchProjectId,
  fetchDataProduct,
} from '../services/data-room.service';

const deliveryMethod = (type_id) => {
  switch (type_id) {
    case API_LINK_ID:
      return 'Proxy API';
    case S3_DATA:
      return 'S3 Bucket';
    default:
      break;
  }
};

export const selectedDatasetPropertiesMapper = (availableDatasetProperties) => {
  return Object.entries(availableDatasetProperties).map((item) => {
    return {
      discussionThreadId: '',
      title: item,
      checked: false,
      removable: true,
      draft: false,
      status: item.status,
    };
  });
};

export const datasetDetailsMapper = async (dataRoomId) => {
  const projectId = await fetchProjectId(dataRoomId);
  const response = await fetchDataProduct(projectId);

  const availableAttributes = response.placement.data_specs.params.map(
    (item, index) => {
      return {
        name: item.name,
        frequency: item.frequency,
        key: index + 1,
        discussionThreadId: '',
        checked: false,
        removable: true,
        draft: false,
        status: item.status,
      };
    },
  );

  const availableGeocoverage = response.placement.data_specs.geo_coverage.map(
    (item) => {
      return {
        discussionThreadId: '',
        title: item,
        checked: false,
        removable: true,
        draft: false,
        status: item.status,
      };
    },
  );

  const availableDatasetProperties = {
    dataSize: response.placement.data_specs.data_volume,
    dataSource: response.placement.data_specs.sources.toString(),
    language: response.placement.data_specs.language.toString(),
    deliveryMethod: deliveryMethod(response.placement.type_id),
    format: response.placement.delivery_specs.format.toString(),
  };

  return {
    productDescription: response.placement.general_description,
    attributes: availableAttributes,
    geocoverage: availableGeocoverage,
    datasetProperties: selectedDatasetPropertiesMapper(
      availableDatasetProperties,
    ),
    availableDatasetProperties,
    attachments: response.sample_data,
  };
};
