import React, { useContext } from "react";
import Cookies from "js-cookie";
import { Typography, Tooltip, Dropdown, Menu } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  LeftOutlined,
  UserOutlined,
  DownOutlined,
  EyeOutlined,
  UndoOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ArrowCurveUpRight } from "@styled-icons/fluentui-system-filled/ArrowCurveUpRight";

import { DataProductContext } from "../../../helpers/DataProductWizardHelper";
import { Flexrow } from "../../Flexbox";
import { confirmModal } from "../../../views/ModalView";
import {
  Title as StyledTitle,
  SubmitMenuContainer,
  SubmitMenu,
  SubmitMenuItem,
  SubmitButton,
  PreviewButton,
  GoToDashBoardContainer,
  ButtonsContainer,
} from "../../wizards-components/styled-components";
import { toast } from "react-toastify";
import Notification from "../../Notification";

const { Title, Text } = Typography;
const EditButton = PreviewButton;

const light_confirmation = {
  title: (
    <p style={{ color: "#182429", fontSize: "21px", marginBottom: 0 }}>
      Are you sure?
    </p>
  ),

  content: (
    <>
      <p style={{ color: "#64737f", fontSize: "15px", marginBottom: 0 }}>
        You are about to submit your light offer for review from Mobito experts.
      </p>
      <p style={{ color: "#64737f", fontSize: "15px", marginBottom: 0 }}>
        Do you want to proceed?
      </p>
    </>
  ),
};

const full_confirmation = {
  title: (
    <p style={{ color: "#182429", fontSize: "21px", marginBottom: 0 }}>
      Are you sure?
    </p>
  ),
  content: (
    <>
      <p style={{ color: "#64737f", fontSize: "15px", marginBottom: 0 }}>
        You are about to submit your full offer for review from Mobito experts.
      </p>
      <p style={{ color: "#64737f", fontSize: "15px", marginBottom: 0 }}>
        Do you want to proceed?
      </p>
    </>
  ),
};

const PublishedHeaderWizard = ({ no_back_button }) => {
  const token = Cookies.get("jwt");
  const helper = useContext(DataProductContext);
  const history = useHistory();

  if (helper.for_review) {
    return (
      <>
        <Flexrow grow />
        <Title style={{ marginBottom: 0, textAlign: "end" }} level={5}>
          <SubmitButton disabled>Submitted</SubmitButton>
        </Title>
      </>
    );
  }

  const step_1_misses = helper.step_1_inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());
  const step_2_misses = helper.step_2_inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());
  const step_3_misses = helper.step_3_inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());

  const light_version_misses = [...step_1_misses, ...step_2_misses];
  const full_version_misses = [...light_version_misses, ...step_3_misses];

  const light_version_errors_tooltip = light_version_misses.length
    ? "Check the following inputs: " + light_version_misses.join(", ")
    : null;

  const full_version_errors_tooltip = full_version_misses.length
    ? "Check the following inputs: " + full_version_misses.join(", ")
    : null;

  const can_only_upgrade =
    helper.published_step == 2 &&
    !helper.cloned &&
    !full_version_errors_tooltip;

  const light_version_tooltip = !helper.cloned
    ? "No changes"
    : light_version_errors_tooltip;
  const full_version_tooltip =
    !can_only_upgrade && !helper.cloned
      ? "No changes"
      : full_version_errors_tooltip;

  const cancelChanges = async () => {
    helper.submitting = true;
    helper.refresh();
    try {
      await axios.delete(`/api/data-placements/editable/${helper.id}/clone`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      helper.reset();
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="An error occured, please try again later"
        />
      );
    }
    helper.submitting = false;
    helper.refresh();
  };

  const confirmChangesSubmit = async (step) => {
    const confirm_content = step == 2 ? light_confirmation : full_confirmation;
    const confirmation = await confirmModal(confirm_content);
    if (!confirmation) {
      return;
    }
    helper.submitting = true;
    helper.refresh();
    try {
      await axios.post(
        `/api/data-placements/editable/${helper.id}/publish-request`,
        { step },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (helper.published) {
        helper.for_review = true;
        helper.submitting = false;
        helper.refresh();
      } else {
        history.push({ pathname: `/dashboard`, state: { key: "1" } });
      }
      toast.success(
        <Notification type="success" text="Your offer is now under review" />
      );
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="An error occured, please try again later"
        />
      );
    }
  };

  const goToDashboard = () => {
    history.push({ pathname: `/dashboard`, state: { key: "1" } });
  };

  const handleMenuClick = (e) => {
    if (e.key == "light_version") {
      confirmChangesSubmit(2);
    } else {
      confirmChangesSubmit(3);
    }
  };

  const menu = (
    <SubmitMenuContainer>
      <Menu onClick={handleMenuClick}>
        {helper.published_step != 3 && (
          <SubmitMenuItem
            disabled={!!light_version_tooltip}
            key="light_version"
            icon={<UserOutlined />}
          >
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title={light_version_tooltip}
            >
              <SubmitMenu>
                <StyledTitle
                  color="#182429"
                  size={13}
                  weight="bold"
                  line="initial"
                >
                  Submit light version
                </StyledTitle>
                <StyledTitle color="#64737f" size={12} line="initial">
                  High-level description of your product.
                </StyledTitle>
              </SubmitMenu>
            </Tooltip>
          </SubmitMenuItem>
        )}
        <SubmitMenuItem
          disabled={!!full_version_tooltip}
          key="full_version"
          icon={<UserOutlined />}
        >
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: "2px 4px 12px 0px #182a331f",
              fontSize: "13px",
              borderRadius: "4px",
            }}
            placement="bottom"
            title={full_version_tooltip}
          >
            <SubmitMenu>
              <StyledTitle
                color="#182429"
                size={13}
                weight="bold"
                line="initial"
              >
                Submit full version
              </StyledTitle>
              <StyledTitle color="#64737f" size={12} line="initial">
                Fully integrated data product.
              </StyledTitle>
            </SubmitMenu>
          </Tooltip>
        </SubmitMenuItem>
      </Menu>
    </SubmitMenuContainer>
  );

  const global_saving = !!helper.sections.find((e) => e.saving || e.editing);

  const processing = helper.submitting || global_saving;
  const nothing_to_publish = !helper.cloned && !can_only_upgrade;

  return (
    <>
      <ButtonsContainer>
        {!no_back_button && (
          <GoToDashBoardContainer>
            <LeftOutlined
              onClick={goToDashboard}
              style={{
                marginRight: "16px",
                color: "#64737f",
                fontSize: "18px",
              }}
            />
          </GoToDashBoardContainer>
        )}
        <Flexrow center_align id="product-preview-and-submit">
          {helper.cloned && (
            <Text
              onClick={cancelChanges}
              style={{ fontSize: 16, cursor: "pointer" }}
            >
              <UndoOutlined />
              &nbsp; Reset changes
            </Text>
          )}
          <Flexrow left="16px" />

          <Flexrow style={{ position: "relative" }}>
            {helper.previewing ? (
              <EditButton onClick={helper.togglePreview}>
                <span>
                  <EditOutlined /> Edit
                </span>
              </EditButton>
            ) : (
              <PreviewButton
                onClick={helper.togglePreview}
                disabled={global_saving || helper.submitting}
              >
                <span>
                  <EyeOutlined /> Preview
                </span>
              </PreviewButton>
            )}
            <Flexrow left="16px" />
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              disabled={processing || nothing_to_publish}
            >
              <SubmitButton>
                {nothing_to_publish ? (
                  "Published"
                ) : (
                  <>
                    Submit <DownOutlined />
                  </>
                )}
              </SubmitButton>
            </Dropdown>
            {helper.cloned && (
              <Flexrow
                style={{
                  position: "absolute",
                  top: "70px",
                  textAlign: "right",
                  right: "0px",
                  width: "max-content",
                }}
              >
                <Text style={{ marginRight: "12px" }}>
                  Once you finish your changes, please
                  <br /> submit again your data product.
                </Text>
                <ArrowCurveUpRight style={{ height: "40px" }} />
              </Flexrow>
            )}
          </Flexrow>
        </Flexrow>
      </ButtonsContainer>
      <style jsx global>
        {`
          #start-walkthrough {
            display: ${helper.previewing && "none"};
          }
        `}
      </style>
    </>
  );
};

export default PublishedHeaderWizard;
