import React, { useState } from 'react';
import { Row, Col } from 'antd';

import ProxyUnpublishedFullVersion from '../views/Unpublished/ProxyUnpublishedFullVersion';
import S3UnpublishedFullVersion from '../views/Unpublished/S3UnpublishedFullVersion';
import ProxyPublishedFullVersion from '../views/Published/ProxyPublishedFullVersion';
import S3PublishedFullVersion from '../views/Published/S3PublishedFullVersion';
import BasicProductDetails from '../views/BasicProductDetails';
import LightVersion from '../views/LightVersion';
import PublishedBasicProductDetails from '../views/PublishedBasicProductDetails';
import PublishedLightVersion from '../views/PublishedLightVersion';

import { strings } from '../values/strings';
import IntegrationHelper from '../../wizards-components/IntegrationHelper';
import Step from '../components/Step';
import { StepperButton } from '../../wizards-components/styled-components';

const full_versions = {
  api: {
    unpublished: ProxyUnpublishedFullVersion,
    published: ProxyPublishedFullVersion,
  },
  s3_data: {
    unpublished: S3UnpublishedFullVersion,
    published: S3PublishedFullVersion,
  },
};

const getFullVersionContent = (type, published_step, for_review) => {
  const pub_key =
    published_step == 3 || for_review ? 'published' : 'unpublished';
  const Content = full_versions[type][pub_key];
  return <Content />;
};

const Steps = (props) => {
  const { helper, walkthroughOn, isS3 } = props;

  const step = helper.wizard_step ? helper.wizard_step : 1;

  const step_1_has_miss = !!helper.step_1_inputs.find((e) => e.has_error);
  const step_2_has_miss = !!helper.step_2_inputs.find((e) => e.has_error);
  const step_3_has_miss = !!helper.step_3_inputs.find((e) => e.has_error);

  const [current, setCurrent] = useState(0);

  const textOne = !step_1_has_miss ? 1 : null;
  const textTwo = !step_2_has_miss ? 2 : null;
  const textThree = !step_3_has_miss ? 3 : null;

  const isNextDisabled =
    (step === 1 && !step_1_has_miss) ||
    (step === 2 && !step_2_has_miss) ||
    current + 1 < step
      ? false
      : true;

  const getHelperTitle = (isPublished, isForReview, step, unpublishedTitle) => {
    let helperTitle;

    if (isForReview) {
      return (helperTitle = strings.data_product_under_review_title);
    } else if (isPublished && step === 3) {
      return (helperTitle = strings.full_version_published_title);
    } else if (isPublished && step === 2) {
      return (helperTitle = strings.light_version_published_title);
    } else {
      return (helperTitle = unpublishedTitle);
    }
  };

  const getHelperDescription = (
    isPublished,
    isForReview,
    step,
    unpublishedDesc,
  ) => {
    let helperDesc;

    if (isForReview) {
      return (helperDesc = strings.data_product_under_review_desc);
    } else if (isPublished && step === 3) {
      return (helperDesc = strings.full_version_published_desc);
    } else if (isPublished && step === 2) {
      return (helperDesc = strings.light_version_published_desc);
    } else {
      return (helperDesc = unpublishedDesc);
    }
  };

  const steps = [
    {
      id: 0,
      title: 'Required Details',
      description: 'Basic product details',
      content:
        helper.published || helper.for_review ? (
          <PublishedBasicProductDetails />
        ) : (
          <BasicProductDetails />
        ),
      helperTitle: getHelperTitle(
        helper.published,
        helper.for_review,
        helper.published_step,
        strings.basic_section_title,
      ),
      helperDesc: getHelperDescription(
        helper.published,
        helper.for_review,
        helper.published_step,
        strings.basic_section_helper_desc,
      ),
    },
    {
      id: 1,
      title: !isS3 ? 'Light version' : 'Full version',
      description: !isS3
        ? 'High-level description of your product'
        : 'Fully integrated product',
      content:
        helper.published || helper.for_review ? (
          <PublishedLightVersion isS3={isS3} />
        ) : (
          <LightVersion isS3={isS3} />
        ),
      helperTitle: getHelperTitle(
        helper.published,
        helper.for_review,
        helper.published_step,
        !isS3 ? strings.light_version_title : strings.full_version_title,
      ),
      helperDesc: getHelperDescription(
        helper.published,
        helper.for_review,
        helper.published_step,
        !isS3
          ? strings.light_version_helper_desc
          : strings.full_version_helper_desc,
      ),
    },
    !isS3 && {
      id: 2,
      title: 'Full version',
      description: 'Fully integrated product',
      content: getFullVersionContent(
        helper.placement_type,
        helper.published_step,
        helper.for_review,
      ),
      helperTitle: getHelperTitle(
        helper.published,
        helper.for_review,
        helper.published_step,
        strings.full_version_title,
      ),
      helperDesc: getHelperDescription(
        helper.published,
        helper.for_review,
        helper.published_step,
        strings.full_version_helper_desc,
      ),
    },
  ];

  const scrollToTop = () => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
    let forceScroll = document.getElementById('project-scroller');
    if (forceScroll) {
      forceScroll.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const next = () => {
    enableButton(current);
  };

  const prev = () => {
    setCurrent(current - 1);
    scrollToTop();
  };

  const stepChange = (v) => {
    setCurrent(v);
    scrollToTop();
  };

  const nextButton = (v) => {
    setCurrent(v + 1);
    scrollToTop();
  };

  const enableButton = (v) => {
    let currentStep = v + 1;

    if (currentStep > step) {
      if (currentStep === 1 && !step_1_has_miss) {
        nextButton(v);
      } else if (currentStep === 2 && !step_2_has_miss) {
        nextButton(v);
      }
    } else {
      nextButton(v);
    }
  };

  const enableStep = (v, c) => {
    let currentStep = c;

    if (currentStep > step) {
      if (currentStep === 2 && !step_1_has_miss) {
        stepChange(v);
      } else if (currentStep === 3 && !step_2_has_miss) {
        stepChange(v);
      }
    } else {
      stepChange(v);
    }
  };

  const onChange = (current) => {
    enableStep(current, current + 1);
  };

  return (
    <Row style={{ marginTop: 48, marginBottom: 48 }} justify="space-between">
      <Col span={5}>
        <div
          id="product-steps"
          style={{
            position: 'sticky',
            top: '60px',
            backgroundColor: walkthroughOn && '#f9faff',
            padding: '8px 12px 0px 0px',
          }}
        >
          {isS3
            ? steps
                .slice(0, 2)
                .map((item) => (
                  <Step
                    step={step}
                    key={item.title}
                    title={item.title}
                    description={item.description}
                    change={() => onChange(item.id)}
                    index={item.id + 1}
                    current={current}
                    total={isS3 ? steps.length - 1 : steps.length}
                    validOne={textOne}
                    validTwo={textTwo}
                    validThree={textThree}
                  />
                ))
            : steps.map((item) => (
                <Step
                  step={step}
                  key={item.title}
                  title={item.title}
                  description={item.description}
                  change={() => onChange(item.id)}
                  index={item.id + 1}
                  current={current}
                  total={steps.length}
                  validOne={textOne}
                  validTwo={textTwo}
                  validThree={textThree}
                />
              ))}
        </div>
      </Col>
      <Col span={12}>
        <div className="steps-content">{steps[current].content}</div>
        <Row
          style={{ marginTop: 50 }}
          justify={current === 0 ? 'end' : 'space-between'}
        >
          {current > 0 && (
            <Col span={6}>
              <StepperButton onClick={() => prev()}>Back</StepperButton>
            </Col>
          )}
          {current < steps.length - (isS3 ? 2 : 1) && (
            <Col span={6} style={{ textAlign: 'right' }}>
              <StepperButton
                onClick={() => next()}
                disabled={isNextDisabled}
                next
              >
                Next
              </StepperButton>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={5}>
        <div style={{ position: 'sticky', top: '60px' }}>
          <IntegrationHelper
            title={steps[current].helperTitle}
            desc={steps[current].helperDesc}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Steps;
