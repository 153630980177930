import React from "react";
import { SText } from "../styled-text/text";
import { Row } from "react-styled-flexboxgrid";

const TabContent = ({ name, icon, padding = "24px 16px" }) => {
  const Icon = icon; //This takes a styled-icon component.
  return (
    <div style={{ padding: padding }}>
      <Row center="xs">
        <Icon size="24" />
      </Row>
      <Row center="xs">
        <SText text_12 black medium linebreaks>
          {name}
        </SText>
      </Row>
    </div>
  );
};

export default TabContent;
