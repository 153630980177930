import styled from 'styled-components';
import { Add } from 'styled-icons/remix-fill';
import { Typography } from 'antd';

const { Text } = Typography;

const SAddIcon = styled(Add)`
  color: #64737f;
`;

const SOuterContainer = styled.div`
  border-radius: 8px;
  padding: ${(props) => (props.isConsumption ? '44px 0px' : '24px 28px')};
  border: 2px dashed #64737f;
  height: ${(props) => (props.isConsumption ? 'auto' : '280px')};
  width: ${(props) => (props.isConsumption ? '100%' : '280px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 32px;
  cursor: pointer;
`;

const CreateProjectCard = (props) => {
  const { createTextType } = props;
  const isConsumption = createTextType === 'consumption';

  return isConsumption ? (
    <SOuterContainer isConsumption={isConsumption}>
      <>
        <Text style={{ color: '#64737F', fontSize: '15px', fontWeight: '700' }}>
          Hungry for more data?
        </Text>
        <Text style={{ color: '#64737F', fontSize: '15px' }}>
          Explore our Marketplace
        </Text>
      </>
    </SOuterContainer>
  ) : (
    <SOuterContainer isConsumption={isConsumption}>
      <SAddIcon size="64" />
      <Text style={{ color: '#64737f', position: 'absolute', bottom: '34px' }}>
        Create data {createTextType}
      </Text>
    </SOuterContainer>
  );
};

export default CreateProjectCard;
