import React from 'react';
import { Typography } from 'antd';

import { ClarificationsCardWrap } from '../assets/styled-components';

const { Title, Text } = Typography;

const SelectItemsExplanation = (props) => {
  const { headline, description } = props;
  return (
    <ClarificationsCardWrap>
      <Title level={4} style={{ marginBottom: 5, color: '#182a33' }}>
        {headline}
      </Title>
      <Text style={{ color: '#182a33' }}>{description}</Text>
    </ClarificationsCardWrap>
  );
};

export default SelectItemsExplanation;
