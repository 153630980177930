import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Check } from "@styled-icons/entypo/Check";

import {
  ProductCard,
  PricingColumn,
  Typography,
  TypographyTitle,
  TypographyPrice,
  TypographyDetails,
  TooltipIcon,
  TypographySection,
  RequestsColumn,
  RequestsSection,
} from "./pricing-components";

const Wrap = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const SelectButton = styled.button`
  padding: 10px 25px;
  cursor: pointer;
  background: ${(props) => (props.selected ? "#CEFAC3" : "#fff")};
  border: ${(props) =>
    props.selected ? "1px solid #CEFAC3" : "1px solid #344ef3"};
  color: ${(props) => (props.selected ? "#64737F" : "#344ef3")};
  width: ${(props) => !props.isConsumption && "100%"};
  &:hover,
  :active,
  :focus {
    background: ${(props) => (props.selected ? "#CEFAC3" : "#344ef3")};
    border: ${(props) =>
      props.selected ? "1px solid #CEFAC3" : "1px solid #344ef3"};
    color: ${(props) => (props.selected ? "#64737F" : "#fff")};
  }
`;

const PackagePaymentPlan = (props) => {
  const { pricingBundles, start, end, setSelected, selected } = props;

  const sliced_packages = pricingBundles.slice(start, end);

  const setIsSelected = (name) => {
    return selected === name;
  };

  return (
    <Wrap>
      <ProductCard>
        <PricingColumn noBorder></PricingColumn>
        {sliced_packages.map((e, i) => (
          <PricingColumn
            center_content
            key={i}
            noBorder
            selected={setIsSelected(e.name)}
          >
            <TypographyTitle selected={setIsSelected(e.name)}>
              {e.name}
            </TypographyTitle>
            <TypographyPrice>
              <TypographyDetails>€</TypographyDetails>
              {parseInt(e.price).toLocaleString()}
              <TypographyDetails>/month</TypographyDetails>
            </TypographyPrice>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Monthly Allowance
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per month"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        {sliced_packages.map((e, i) => (
          <PricingColumn key={i} selected={setIsSelected(e.name)} notTop>
            <Typography>
              {e.requests ? `${e.requests.toLocaleString()} requests` : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Minimum purchase
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Minimum number of months that you need to purchase"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        {sliced_packages.map((e, i) => (
          <PricingColumn key={i} selected={setIsSelected(e.name)} notTop>
            <Typography>
              {e.min_packages_purchased
                ? `${e.min_packages_purchased} months`
                : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <RequestsColumn>
          <RequestsSection>Requests limits</RequestsSection>
        </RequestsColumn>

        {sliced_packages.map((e, i) => (
          <RequestsColumn key={i} selected={setIsSelected(e.name)} />
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per minute
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per minute"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        {sliced_packages.map((e, i) => (
          <PricingColumn key={i} selected={setIsSelected(e.name)} notTop>
            <Typography>
              {e.limit_minute
                ? `${e.limit_minute.toLocaleString()} requests`
                : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per hour
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per hour"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        {sliced_packages.map((e, i) => (
          <PricingColumn key={i} selected={setIsSelected(e.name)} notTop>
            <Typography>
              {e.limit_hour ? `${e.limit_hour.toLocaleString()} requests` : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per day
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per day"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        {sliced_packages.map((e, i) => (
          <PricingColumn key={i} selected={setIsSelected(e.name)} notTop>
            <Typography>
              {e.limit_day ? `${e.limit_day.toLocaleString()} requests` : "-"}
            </Typography>
          </PricingColumn>
        ))}
      </ProductCard>
      <ProductCard>
        <PricingColumn noBorder></PricingColumn>
        {sliced_packages.map((e, index) => (
          <PricingColumn
            key={index}
            noBorder
            selected={setIsSelected(e.name)}
            notTop
          >
            <SelectButton
              onClick={() => setSelected(e.name)}
              selected={setIsSelected(e.name)}
            >
              {setIsSelected(e.name) && (
                <Check
                  style={{
                    height: "18px",
                    marginRight: "10px",
                    color: "#5FDF3E",
                  }}
                />
              )}
              {setIsSelected(e.name) ? "Selected" : "Select"}
            </SelectButton>
          </PricingColumn>
        ))}
      </ProductCard>
    </Wrap>
  );
};

export default PackagePaymentPlan;
