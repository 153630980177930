import React, { useState, createRef, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { Typography, Steps, Tooltip, Modal } from 'antd';

import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { Bulb } from '@styled-icons/ionicons-outline/Bulb';

import LicenseAndAgreement from './components/LicenseAndAgreement';
import FreePaymentPlan from './components/FreePaymentPlan';
import Completion from './components/Completion';
import { Flexrow, Flexcol } from '../Flexbox';
import LayoutRow from '../LayoutRow';
import Step from './Step';
import { strings } from './components/values/strings';
import MaskedRulesModal from './MaskedRulesModal';

const { Text } = Typography;

const ButtonsWrap = styled(Flexrow)`
  position: relative;
  width: 65%;
  margin: auto;
  justify-content: ${(props) => props.justify && props.justify};
  height: 60px;
  align-items: center;
`;

const StepperButton = styled.button`
  border-radius: 8px;
  border: ${(props) =>
    props.disabled || props.next ? 'none' : '1px solid #344ef3'};
  height: 41px;
  width: 89px;
  color: ${(props) =>
    props.disabled ? '#c1c2c2' : props.next ? '#fff' : '#344ef3'};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: ${(props) =>
    props.disabled ? '#f3f3f4 ' : props.next ? '#344ef3' : '#fff'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) =>
      props.disabled ? '#f3f3f4' : props.next ? '#0c28dd' : '#fff'};
    box-shadow: ${(props) => !props.disabled && '2px 4px 12px 0px #182a331f'};
  }
`;

const StatusTag = styled.div`
  background: ${(props) => props.background && props.background};
  border-radius: 13.5px;
  border: 1px solid ${(props) => props.borderColor && props.borderColor};
  color: #64737f;
  font-size: 14px;
  padding: 4px 11px;
  font-weight: 500;
  margin-left: 10px;
`;

export function CheckoutFree(props) {
  const token = Cookies.get('jwt');
  const endofPageRef = createRef();
  const [current, setCurrent] = useState(0);
  const [activateLicense, setActivateLicense] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const hasMaskedRules = !!Object.keys(props.savedRules).length;

  const handleClick = () => {
    endofPageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleLicense = () => {
    setActivateLicense((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    await axios.post(
      '/api/source-free',
      {
        resource_connection_id: props.source.resource_connection_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    await axios.post(
      '/api/source',
      {
        resource_connection_id: props.source.resource_connection_id,
        project_name: props.source.name,
        isFree: true,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 200 < e.target.clientHeight;
    if (bottom) {
      setReachedBottom(true);
    } else {
      setReachedBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    setReachedBottom(false);
  };

  const submitHandler = () => {
    if (current === (hasMaskedRules ? 2 : 1)) {
      next();
      handleSubmit();
    } else {
      next();
    }
  };

  const handleOk = () => {
    props.setFreePayModalVisible(false);
  };

  const handleCancel = () => {
    props.setFreePayModalVisible(false);
    if (hasMaskedRules ? 3 : 2) {
      props.setApiKey(true);
    }
  };

  const steps = [
    hasMaskedRules && {
      id: 0,
      title: 'View data',
      content: (
        <MaskedRulesModal
          imageUrl={props.imageUrl}
          name={props.source.name}
          setVisible={props.setVisible}
          visible={props.visible}
          openCheckout={props.openCheckout}
          status={props.source.status_name}
          endpoints={props.endpoints}
          savedRules={props.savedRules}
          notModal
        />
      ),
    },
    {
      id: hasMaskedRules ? 1 : 0,
      title: 'Licence',
      content: (
        <LicenseAndAgreement
          license={props.source.license_type}
          handleScroll={handleScroll}
          reachedBottom={reachedBottom}
          activateLicense={activateLicense}
          handleClick={handleClick}
          handleLicense={handleLicense}
          endofPageRef={endofPageRef}
          setReachedBottom={setReachedBottom}
        />
      ),
      helperTitle: strings.step_one_title,
      helperDesc: strings.step_one_desc,
    },
    {
      id: hasMaskedRules ? 2 : 1,
      title: 'Plan',
      content: (
        <div style={{ width: '100%' }}>
          <FreePaymentPlan pricingBundle={props.pricingBundle} />
        </div>
      ),
      helperTitle: strings.step_two_free_title,
      helperDesc: strings.step_two_free_desc,
    },
    {
      id: hasMaskedRules ? 3 : 2,
      title: 'Completion',
      content: (
        <Completion
          sourceName={props.source.name ? props.source.name : 'No name'}
          freePlan
          closeModal={handleCancel}
          setApiKey={props.setApiKey}
        />
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={
          <Flexcol left="50px">
            <Flexrow center_align style={{ paddingTop: '10px' }}>
              <img
                style={{ marginRight: '10px' }}
                width="40"
                height="40"
                alt="mobility-module-image"
                src={props.imageUrl}
              />
              <Text style={{ color: '#192A33', fontSize: '18px' }}>
                {props.source.name ? props.source.name : 'No name'}
              </Text>
            </Flexrow>
            <Flexrow center_align top="18px">
              <Text
                style={{
                  color: '#192A33',
                  fontSize: '20px',
                  fontWeight: '600',
                }}
              >
                Payment process
              </Text>
              <StatusTag background="#cefac3" borderColor="#5fdf3e">
                Free
              </StatusTag>
            </Flexrow>
          </Flexcol>
        }
        visible={props.freePayModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        style={{ paddingBottom: '0px' }}
        bodyStyle={{
          backgroundColor: '#F9FAFF',
          padding: '0px',
          height: '70vh',
          overflowY: 'auto',
        }}
        width="100%"
        destroyOnClose={true}
        footer={[
          current !== (hasMaskedRules ? 3 : 2) && (
            <LayoutRow background="#fff">
              <ButtonsWrap justify={current === 0 ? 'end' : 'space-between'}>
                {current > 0 && (
                  <StepperButton onClick={() => prev()}>
                    <Flexrow center_align space_around>
                      <ArrowLeftShort style={{ height: '20px' }} /> Back
                    </Flexrow>
                  </StepperButton>
                )}
                {current < steps.length - 1 && (
                  <Tooltip
                    color="#1a337d"
                    overlayInnerStyle={{
                      boxShadow: '2px 4px 12px 0px #182a331f',
                      fontSize: '13px',
                      borderRadius: '4px',
                    }}
                    placement="top"
                    title={
                      current === (hasMaskedRules ? 1 : true) &&
                      !activateLicense
                        ? 'Please accept terms in order to proceed'
                        : null
                    }
                    zIndex={999999999}
                  >
                    <div>
                      <StepperButton
                        onClick={submitHandler}
                        next
                        disabled={
                          current === (hasMaskedRules ? 1 : 0) &&
                          !activateLicense
                        }
                      >
                        <Flexrow center_align space_around>
                          Next <ArrowRightShort style={{ height: '20px' }} />
                        </Flexrow>
                      </StepperButton>
                    </div>
                  </Tooltip>
                )}
              </ButtonsWrap>
            </LayoutRow>
          ),
        ]}
        zIndex={1000000}
      >
        <LayoutRow
          background={current === (hasMaskedRules ? 3 : 2) ? '#fff' : '#F9FAFF'}
        >
          <Flexrow style={{ width: '50%', margin: '20px auto' }}>
            <Steps current={current}>
              <Flexrow space_between center_align style={{ width: '100%' }}>
                {!hasMaskedRules
                  ? steps
                      .slice(1)
                      .map((item) => (
                        <Step
                          key={item.title}
                          title={item.title}
                          index={item.id + 1}
                          total={steps.length - 1}
                          current={current}
                          licenseValid={activateLicense ? 1 : null}
                          planValid={current >= 2 && 2}
                          paymentValid={current >= 2 && 3}
                        />
                      ))
                  : steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                        index={item.id + 1}
                        total={steps.length}
                        current={current}
                        maskedValid={
                          hasMaskedRules && (current >= 1 ? 1 : null)
                        }
                        licenseValid={
                          activateLicense ? (hasMaskedRules ? 2 : 1) : null
                        }
                        planValid={current > (hasMaskedRules ? 2 : 1) && 3}
                        paymentValid={current > (hasMaskedRules ? 2 : 1) && 4}
                      />
                    ))}
              </Flexrow>
            </Steps>
          </Flexrow>
          <Flexrow
            style={{
              margin: 'auto',
              width: current === 0 && hasMaskedRules ? '100%' : '65%',
            }}
          >
            {hasMaskedRules
              ? steps[current].content
              : steps[current + 1].content}
            {(hasMaskedRules
              ? steps[current].helperTitle
              : steps[current + 1].helperTitle) && (
              <Flexcol
                start_align
                style={{
                  position: 'absolute',
                  right: '30px',
                  width: '20%',
                }}
              >
                <Bulb
                  style={{
                    color: '#64737F',
                    height: '30px',
                    marginBottom: '12px',
                  }}
                />
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '13px',
                    fontWeight: '600',
                    marginBottom: '12px',
                  }}
                >
                  {hasMaskedRules
                    ? steps[current].helperTitle
                    : steps[current + 1].helperTitle}
                </Text>
                <Text
                  style={{
                    color: '#64737F',
                    fontSize: '13px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {hasMaskedRules
                    ? steps[current].helperDesc
                    : steps[current + 1].helperDesc}
                </Text>
              </Flexcol>
            )}
          </Flexrow>
        </LayoutRow>
      </Modal>
      <style>{`.ant-switch-checked { background-color: #5FDF3E; }`}</style>
    </div>
  );
}

export default CheckoutFree;
