import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Input, Button, Form, Divider } from 'antd';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  UndoOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { Cancel } from '@styled-icons/material/Cancel';

import Notification from '../components/Notification';
import { Flexcol, Flexrow } from '../components/Flexbox';
import validate from '../components/validators/SignUpWizardValidator';
import SubsectionTitle from '../components/wizards-components/SubsectionTitle';
import instance from '../services/TokenValidator';

const { Text } = Typography;

const Wrap = styled(Form)`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 32px;
  height: fit-content;
  width: 65%;
  margin-top: 26px;
`;

const SaveButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 140px;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const PasswordList = styled.ul`
  padding-inline-start: 22px;
  color: #344ef3;
`;

const TogglePasswordButton = styled(Button)`
  position: absolute;
  top: -30px;
  right: 0;
  border: 0;
  box-shadow: none;
  padding: 0;
  height: initial;
  color: #344ef3;
  font-size: 13px;
  width: 60px;
  &:hover,
  :active,
  :visited,
  :focus {
    color: #344ef3;
    background: #fff;
  }
`;

const PasswordMessageWrap = styled.div`
  background-color: ${(props) => props.background && props.background};
  color: #64737f;
  margin-top: -20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
`;

const required_rule = { required: true, message: 'Required field' };

export const EditPassword = (p) => {
  const { setIsUpdated, setCanSave, canSave, reset } = p;
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false);
  const [errors, setErrors] = useState(false);
  const [, updator] = useState({});
  const [form] = Form.useForm();
  const token = Cookies.get('jwt');
  const { password, new1, new2 } = form.getFieldsValue();

  const can_save = password && new1 && new2 == new1;

  useEffect(() => {
    setIsUpdated((prevState) => ({ ...prevState, password: can_save }));
  }, [can_save]);

  const onSave = async () => {
    if (new1 != new2) {
      toast.error(
        <Notification
          type="error"
          text="Password confirmation doesn't match new password"
        />,
      );
      return;
    }
    setSaving(true);
    try {
      await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/auth/password`,
        {
          currentPassword: password,
          newPassword: new1,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success(<Notification type="success" text="Changes saved" />);
      form.setFieldsValue({
        password: '',
        new1: '',
        new2: '',
      });
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Could not save changes, please check your credentials"
        />,
      );
    }
    setSaving(false);
    setIsUpdated((prevState) => ({ ...prevState, password: false }));
    setCanSave((prevState) => ({ ...prevState, password: false }));
  };

  useEffect(() => {
    if (canSave.password) {
      onSave();
    }
  }, [canSave.password]);

  useEffect(() => {
    if (reset.password) {
      form.resetFields();
      updator({ changed: false });
    }
  }, [reset.password]);

  const checkPasswordErrors = (value) => {
    let isValid = false;
    if (errors) {
      errors.passwordErrors.forEach((error) => {
        if (error === value) {
          isValid = true;
        }
      });
    }
    return isValid;
  };

  useEffect(() => {
    setErrors(validate({ new1, new2 }, 'edit_password'));
  }, [new1, new2]);

  let hasPasswordErrors = !!errors && errors.passwordErrors?.length > 0;
  let hasRepeatPasswordError = !!errors && errors.password_repeat;

  return (
    <Flexcol style={{ width: '65%' }} center_align>
      <Flexcol style={{ width: '100%' }} top="72px">
        <Text style={{ color: '#182429', fontSize: '28px' }}>
          Change password
        </Text>
        <Text style={{ color: '#64737F', fontSize: '15px' }}>
          Here you can change your password.
        </Text>
        <Divider />
      </Flexcol>
      <Wrap onFieldsChange={() => updator({})} form={form}>
        <Flexrow center_align bottom="10px">
          <SubsectionTitle
            tooltip="Enter the current password of your account"
            marginOff
          >
            Current password
          </SubsectionTitle>
          <Text
            style={{
              fontSize: '12px',
              fontWeight: '500',
              paddingLeft: '6px',
            }}
          >
            <Link to={'/forgot-password'}>Forgot password?</Link>
          </Text>
        </Flexrow>
        <Form.Item shouldUpdate name="password" rules={[required_rule]}>
          <div style={{ position: 'relative' }}>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Type your current password"
              size="large"
              disabled={saving}
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
                boxShadow: 'none',
              }}
            />
            <TogglePasswordButton
              onClick={() => setShowPassword(!showPassword)}
              type="text"
            >
              {showPassword ? (
                <span>
                  <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
                </span>
              ) : (
                <span>
                  <EyeOutlined style={{ color: '#344ef3' }} /> Show
                </span>
              )}
            </TogglePasswordButton>
          </div>
        </Form.Item>
        <SubsectionTitle tooltip="Enter the new password of your account">
          New password
        </SubsectionTitle>
        <Form.Item validateFirst name="new1">
          <div style={{ position: 'relative' }}>
            <Input
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Type your new password"
              size="large"
              disabled={saving}
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
                boxShadow: 'none',
              }}
            />
            <TogglePasswordButton
              onClick={() => setShowNewPassword(!showNewPassword)}
              type="text"
            >
              {showNewPassword ? (
                <span>
                  <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
                </span>
              ) : (
                <span>
                  <EyeOutlined style={{ color: '#344ef3' }} /> Show
                </span>
              )}
            </TogglePasswordButton>
          </div>
        </Form.Item>
        {hasPasswordErrors ? (
          <Flexrow>
            <PasswordList style={{ marginRight: '110px' }}>
              <li style={{ color: !checkPasswordErrors('upper') && '#c1c2c2' }}>
                <Text
                  style={{
                    color: checkPasswordErrors('upper') ? '#182429' : '#c1c2c2',
                    fontSize: '13px',
                  }}
                >
                  One uppercase character
                </Text>
              </li>
              <li
                style={{ color: !checkPasswordErrors('special') && '#c1c2c2' }}
              >
                <Text
                  style={{
                    color: checkPasswordErrors('special')
                      ? '#182429'
                      : '#c1c2c2',
                    fontSize: '13px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  One special character
                  <Text
                    style={{
                      color: checkPasswordErrors('special')
                        ? '#182429'
                        : '#c1c2c2',
                      fontSize: '10px',
                      paddingLeft: '4px',
                    }}
                  >
                    (!,@,#,$,%,^,&,*)
                  </Text>
                </Text>
              </li>
            </PasswordList>
            <PasswordList>
              <li style={{ color: !checkPasswordErrors('num') && '#c1c2c2' }}>
                <Text
                  style={{
                    color: checkPasswordErrors('num') ? '#182429' : '#c1c2c2',
                    fontSize: '13px',
                  }}
                >
                  One number
                </Text>
              </li>
              <li style={{ color: !checkPasswordErrors('eight') && '#c1c2c2' }}>
                <Text
                  style={{
                    color: checkPasswordErrors('eight') ? '#182429' : '#c1c2c2',
                    fontSize: '13px',
                  }}
                >
                  8 characters minimum
                </Text>
              </li>
            </PasswordList>
          </Flexrow>
        ) : (
          <PasswordMessageWrap background="#cefac3">
            <CheckCircleFill
              style={{
                height: '16px',
                color: '#5fdf3e',
                paddingRight: '8.5px',
              }}
            />
            Nice! Your password is strong and secure.
          </PasswordMessageWrap>
        )}
        <SubsectionTitle tooltip="Enter again the new password of your account">
          Confirm new password
        </SubsectionTitle>
        <Form.Item validateFirsts name="new2">
          <div style={{ position: 'relative' }}>
            <Input
              type={showRepeatPassword ? 'text' : 'password'}
              placeholder="Type again your new password"
              size="large"
              disabled={saving}
              onBlur={() => setRepeatPasswordTouched(true)}
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
                boxShadow: 'none',
              }}
            />
            <TogglePasswordButton
              onClick={() => setShowRepeatPassword(!showRepeatPassword)}
              type="text"
            >
              {showRepeatPassword ? (
                <span>
                  <EyeInvisibleOutlined style={{ color: '#344ef3' }} /> Hide
                </span>
              ) : (
                <span>
                  <EyeOutlined style={{ color: '#344ef3' }} /> Show
                </span>
              )}
            </TogglePasswordButton>
          </div>
        </Form.Item>
        {!hasPasswordErrors && hasRepeatPasswordError && repeatPasswordTouched && (
          <PasswordMessageWrap background="#ffe9e9">
            <Cancel
              style={{
                height: '16px',
                color: '#fd5f5d',
                paddingRight: '8.5px',
              }}
            />
            Oops! Passwords do not match.
          </PasswordMessageWrap>
        )}
        <Flexrow id="functional-buttons" center_align space_between top="26px">
          {can_save ? (
            <Text
              id="reset-changes"
              style={{ color: '#344EF3', fontSize: '14px', cursor: 'pointer' }}
              onClick={() => {
                form.resetFields();
                updator({ changed: false });
              }}
            >
              <UndoOutlined />
              &nbsp; RESET CHANGES
            </Text>
          ) : (
            <Flexrow grow />
          )}
          <SaveButton
            id="save-changes"
            disabled={!can_save}
            onClick={onSave}
            loading={saving}
          >
            Save changes
          </SaveButton>
        </Flexrow>
      </Wrap>
      <div style={{ padding: '42px' }} />
    </Flexcol>
  );
};
