import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

import Chat from '../chat/Chat';
import {
  DrawerContainer,
  OpenChatButton,
  DrawerAndChatContainer,
  Backdrop,
} from '../assets/styled-components';
import { getDiscussionThreads } from '../chat/chat-services';
import { Flexrow } from '../../Flexbox';
import ExpandSrc from '../../../images/expand.svg';
import MinimizeSrc from '../../../images/minimize.svg';
import Cookies from 'js-cookie';

const { Title } = Typography;

const Drawer = (props) => {
  const { discussionThreads, dataRoomId, userType, setRefresh } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedDiscussionThreads, setFetchedDiscussionThreads] = useState([]);
  const [refreshDrawer, setRefreshDrawer] = useState(false);
  const [bottomReached, setBottomReached] = useState(false);
  const token = Cookies.get('jwt');

  let discussionThreadsLength =
    discussionThreads &&
    Object.values(discussionThreads).reduce((acc, v) => acc + v.length, 0);

  let hasDiscussionThreads =
    discussionThreads &&
    Object.values(discussionThreads).some((key) => {
      if (key.length) {
        return true;
      }
    });

  const chatHandler = async () => {
    setIsOpen((prevState) => !prevState);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!isOpen) {
      setIsLoading(true);
      setFetchedDiscussionThreads(
        await getDiscussionThreads(token, dataRoomId),
      );
      window.scrollTo({ top: 1, behavior: 'smooth' });
      setIsLoading(false);
    } else {
      setRefresh((prevState) => !prevState);
    }
  };

  useEffect(async () => {
    setFetchedDiscussionThreads(await getDiscussionThreads(token, dataRoomId));
  }, [refreshDrawer]);

  useEffect(() => {
    if (!fetchedDiscussionThreads.length) setIsOpen(false);
  }, [fetchedDiscussionThreads.length]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setBottomReached(
          window.innerHeight + window.scrollY >= document.body.offsetHeight,
        ),
      );
    }
  }, []);

  return (
    <>
      <DrawerAndChatContainer isOpen={isOpen} bottom={bottomReached}>
        {isOpen && <Backdrop />}
        <DrawerContainer space_between center_align>
          <Flexrow center_align>
            <Title
              level={2}
              style={{
                margin: '0px 16px 0px 0px',
                color: '#182A33',
                fontWeight: 700,
              }}
            >
              {discussionThreadsLength}
            </Title>
            <Title
              level={4}
              style={{
                margin: 0,
                color: '#182A33',
              }}
            >
              question items
            </Title>
          </Flexrow>
          <OpenChatButton
            onClick={chatHandler}
            disabled={!hasDiscussionThreads && !fetchedDiscussionThreads.length}
            isOpen={isOpen}
          >
            {!isOpen ? (
              <Flexrow center_align>
                Open chat <img src={ExpandSrc} style={{ marginLeft: 16 }} />
              </Flexrow>
            ) : (
              <Flexrow center_align>
                Minimize chat
                <img src={MinimizeSrc} style={{ marginLeft: 16 }} />
              </Flexrow>
            )}
          </OpenChatButton>
        </DrawerContainer>
        {isOpen && fetchedDiscussionThreads && (
          <Chat
            discussionThreads={fetchedDiscussionThreads}
            isLoading={isLoading}
            setRefresh={setRefresh}
            setRefreshDrawer={setRefreshDrawer}
            setIsOpen={setIsOpen}
            userType={userType}
          />
        )}
      </DrawerAndChatContainer>
      <style>{`body { overflow-y: ${isOpen && 'hidden !important'}; }`}</style>
    </>
  );
};

export default Drawer;
