import axios from 'axios'
import { useState, useEffect } from 'react'

export const useStringOptions = type => {
  const [ options, setOptions ] = useState([])
  useEffect(() => {
    const fetcher = async () => {
      const res = await axios.get(`/api/string-options?type=${type}`)
      setOptions(res.data)
    }
    fetcher()
  }, [type])
  return options
}