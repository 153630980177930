import React from 'react';
import { Typography, Divider } from 'antd';

import { Flexrow } from '../Flexbox';
import QuestionMarkSrc from '../../images/question-mark.svg';
import { strings } from './assets/strings';
import { handleExplanatoryStepModal } from './modals/ExplanatoryStepModalHandler';

const { Title } = Typography;

const ContentStepperHeader = (props) => {
  const { title, content } = props;

  return (
    <>
      <Flexrow center_align space_between>
        <Title level={3} style={{ margin: '10px 0', color: '#182a33' }}>
          {title}
        </Title>
        <Flexrow center_align>
          <img
            src={QuestionMarkSrc}
            style={{ marginRight: 18, cursor: 'pointer' }}
            onClick={() => handleExplanatoryStepModal(title, content)}
          />
          <Title level={5} style={{ margin: 0, color: '#182a33' }}>
            {strings.content_stepper_header.how_does_it_work}
          </Title>
        </Flexrow>
      </Flexrow>
      <Divider style={{ margin: '0', padding: '10px 0' }} />
    </>
  );
};

export default ContentStepperHeader;
