import { Tag, Tooltip } from "antd";

import { limitFileName } from "../../../utils/utils";
import { Title } from "../../wizards-components/styled-components";

const parseProp = (text) => {
  text = text.replaceAll("->", ".");
  text = limitFileName(text, 18);
  return text;
};

export const PropertyTag = (props) => {
  const { onClose, tag, property } = props;

  return (
    <Tooltip title={property.replaceAll("->", ".")}>
      <Tag
        style={{ background: "#ebebed", borderRadius: "12.5px" }}
        closable={!!onClose}
        onClose={onClose}
      >
        <Title color="#182429" size={12} weight="bold">
          {tag}
        </Title>{" "}
        <Title color="#182429" size={12}>
          {parseProp(property)}
        </Title>
      </Tag>
    </Tooltip>
  );
};
