export const strings = {
  basic_section_desc:
    'In this first step, please fill in some basic data product details to get things started. All changes are auto-saved.',
  basic_title_tooltip:
    'Provide a description of your data product that promotes it in a smart way, with details about your company and the datasets that you offer.',
  data_cat_tooltip: 'Select a data category that represents your data offering',
  img_tooltip: 'Upload an image for the Listing (e.g. company logo)',
  light_version_desc:
    'Fill-in the following details so you can submit a high-level desription of your data. All changes are auto-saved.',
  full_version_desc:
    'This is the final step for creating your fully integrated data product. All changes are auto-saved.',
  basic_section_helper_desc:
    'Complete steps 1 and 2 to be able to publish a high-level description of your data product. No need to load your data onto our platform right away.',
  light_version_helper_desc: `Complete this step and you will be able to publish a high-level description of your data product. No need to load your data onto our platform right away.
    
    Remember to preview your listing to make sure it is just to your liking.`,
  full_version_helper_desc:
    'Complete this step and you will be able to publish a fully integrated data product. Remember to preview your listing to make sure it is just to your liking.',
  api_details_tooltip: 'Provide all the relevant technical details of your API',
  api_domain_tooltip: 'Input your API link Note: Make sure it ends with “/”',
  api_documentation_link_tooltip:
    'Input your public API documentation page. Consumers would need to consult your API documentation page in order to see the available query parameters, request body, URL path that may be required in order to be able to make requests for your endpoints’ data',
  public_api_tooltip: 'Disable in case your API does not have endpoints',
  api_key_tooltip:
    'This is the API key and the authentication method used in order the Mobito platform to be able to access your API Note: This API key is for Mobito only. It will not be shared with any consumer',
  method_tooltip:
    'Auth. method: Select “Query string” in case your API requires the API key to be placed in the query URL',
  api_endpoints_tooltip: `Fill-in details for each endpoint of your API.
    -> Endpoint: Fill-in your endpoint and select if it is a Get or a Post request
    -> Description: Fill-in a short description for this endpoint
    -> Sample response: Paste a json object of a sample response
      Follow the same steps for each endpoint.`,
  pricing_tooltip:
    'In this section you are required to set-up your pricing policy',
  pricing_method_tooltip: `-> Pricing per request:
    Select if you want to charge consumers based on the number of API requests that they will make
    
    -> Pricing per Package:
    Select if you want to create different monthly pricing tiers. Each tier can have different consumption limits per month
    
    -> Free:
    Select if you want to offer your data for free (e.g. in case you want to create a Trial Listing)`,
  pricing_per_request_price_tooltip: '€ per API request',
  pricing_per_request_minimum_purchase_tooltip:
    'Set a minimum number of API requests that consumers should purchase (e.g. 1000 API requests)',
  pricing_per_request_limits_tooltip:
    'Set a throttling mechanism for each consumer',
  hide_price_in_public_tooltip:
    'Use this option in case you want the pricing info not to be publicly disclosed, rather to be displayed only to approved by you consumers',
  pricing_per_package_name_tooltip: 'Set-up a name for the package',
  pricing_per_package_price_tooltip: 'Set-up a price for the package',
  pricing_per_package_minimum_purchase_tooltip:
    'Set a minimum number of API requests that consumers should purchase (e.g. 1000 API requests)',
  pricing_per_package_monthly_allowance_tooltip:
    'Set-up a maximum number of API requests that the consumer should be allowed to make within a month',
  license_tooltip: `Paste the Data License document.

    This document includes the Terms and Conditions that govern the relationship between you and the data consumer.
    
    Each consumer is mandatory to read the Data License document and approve its Terms & Conditions before they make a purchase.`,
  technical_contact_details_tooltip: `Fill-in the contact information of your window person that will handle any 
    technical issue that may occur with the integration of your API in Mobito’s platform.
    Note: This person will only be contacted by the Mobito team in case of technical issues. 
    The contact details will not be shared with external consumers`,
  select_tags_tooltip:
    'A pair for each selected tag and endpoint property will be added to your tag selection',
  data_product_under_review_desc:
    'You have submitted a full version of your data offering and it is currently under review by our experts. Changes cannot be made at this point.',
  full_version_published_desc:
    'The full version of your data product is published. If you wish to edit your information, remember to re-submit your product.',
  light_version_published_desc:
    'The light version of your data product is published. If you wish to edit your information or create a full version, remember to re-submit your product.',
  basic_section_title: 'Let’s start with the basics.',
  light_version_title: 'Submit a light version.',
  full_version_title: 'Submit a full version.',
  data_product_under_review_title: 'Your data product is under review',
  full_version_published_title: 'Full version is published',
  light_version_published_title: 'Light version is published',
  data_specs_title_tooltip:
    'Provide information of your data like sources, size, availability, geographical coverage, language and parameters.',
  data_specs_sources_tooltip:
    'Select sources of your data. Click on "other" and add your source if it is missing',
  data_specs_data_volume_tooltip: 'The size of the data provided',
  data_specs_availability_tooltip:
    'The type of coverage and the time period it covers.',
  data_specs_geo_coverage_tooltip: 'Covered countries or global coverage',
  data_specs_language_tooltip: 'Language of data description (e.g. parameters)',
  data_specs_params_tooltip:
    'Provide a list with the available data attributes and the relevant details',
  data_frequency_tooltip: `Capturing frequency: Frequency (eg. every minute) that a signal is captured in a device

  Transmission frequency: Frequency (eg. every minute) that a signal is transmitted to the cloud`,
  delivery_specs_title_tooltip:
    'Provide the delivery method,  format, pricing model, sample files and a general description.',
  delivery_specs_method_tooltip: `Select the data delivery method depending on how you want to offer your data:

    -> Proxy API: Mobito proxies your data and delivers it to consumers without data storing

    -> S3 Bucket: This option is mostly used in case you generate large volumes of data and provide them to consumers upon request. The data will be saved in Mobito's Amazon S3 buckets`,
  delivery_specs_format_tooltip:
    'Select format of your data. Click on "other" and add your format if it is missing',
  delivery_specs_pricing_model_tooltip: 'Description of the pricing model',
  delivery_specs_data_sample_tooltip: 'Provide a relevant data sample',
  delivery_specs_general_desc_tooltip:
    'Provide a description to clarify the benefits offered',
  api_authentication_title_tooltip:
    'Provide the method of authentication the api key or your public api.',
  api_endpoint_name_tooltip: 'The URL of the endpoint',
  api_endpoint_short_desc_tooltip: 'A short description of the endpoint',
  api_endpoint_sample_request_tooltip: 'A sample of the request query',
  api_endpoint_sample_response_tooltip: 'A sample of the response',
  api_endpoint_sample_request_body_tooltip: 'A sample of the request body',
  api_endpoints_tags_tooltip:
    'Tag one or more endpoint properties that you consider as sensitive data and you might want to apply a masking rule on when you receive a consumption request',
  technical_contact_details_name_tooltip:
    'The name and surname of the technician',
  technical_contact_details_email_tooltip: 'The email of the technician',
};
