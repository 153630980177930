import Header from '../components/Header';
import { Typography, Spin, Button } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { colors } from '../values/colors';

const { Text, Title } = Typography;

const HeaderWrap = styled.div`
  display: flex;
  background-color: white;
  justify-content: center;
  > div {
    flex: 1;
    max-width: 83.3%;
  }
`;

const TitleWrap = styled.div`
  align-self: center;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmButton = styled(Button)`
  background-color: ${colors.tirq_4};
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  /* border: 1px solid ${colors.tirq_6}; */
  /* :hover {
        background-color: ${colors.tirq_5};
    } */
`;

export const ConfirmNewEmail = () => {
  const [state, setState] = useState('loading');
  const deploymentDomain =
    process.env.NODE_ENV !== 'development' ? '.mobito.io' : 'localhost';

  useEffect(() => {
    const callApi = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
     
      try {
        const res = await axios.post(
          `${process.env.RAZZLE_ACCOUNT_URL}/auth/verify-email/${token}`,
          { token },
        );

        const { email } = res.data.user;
        if (Cookies.get('email')) {
          Cookies.set('email', email, { domain: deploymentDomain });
          Cookies.set('account', res.data, { domain: deploymentDomain });
        }
        setState('done');
      } catch (error) {
        setState('error');
        console.error(error);
      }
    };
    callApi();
  }, []);

  return (
    <>
      <HeaderWrap>
        <div>
          <Header withNav />
        </div>
      </HeaderWrap>
      {state == 'loading' && (
        <Spin tip="Loading..." style={{ marginTop: '5%' }} size="large" />
      )}
      {state == 'done' && (
        <TitleWrap>
          <Title level={1} style={{ fontWeight: 'normal' }}>
            Your new email is verified!
          </Title>
          <Link to="/">
            <ConfirmButton size="large">ENTER MOBITO</ConfirmButton>
          </Link>
        </TitleWrap>
      )}
      {state == 'error' && (
        <TitleWrap>
          <Text style={{ fontSize: 20 }} type="danger">
            This token is invalid or has expired
          </Text>
        </TitleWrap>
      )}
      <style>{`
                body { background-color: ${colors.grey_1};}
            `}</style>
    </>
  );
};
