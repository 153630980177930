import styled from 'styled-components';
import { Button } from 'antd';

import { Flexrow, Flexcol } from '../../Flexbox';
import { scrollStyles } from '../../../styles/scrollStyles';

export const StepperHeaderWrap = styled.div`
  border-bottom: 1px solid rgb(226, 228, 229);
  padding: 15px 80px;
`;

export const StepperContentWrap = styled.div`
  background-color: #efefef;
  padding: 20px 80px 60px;
  position: relative;
`;

export const StepperButtonsWrap = styled(Flexrow)`
  padding: 10px 80px;
  justify-content: ${(props) => props.justify && props.justify};
`;

export const StepperButton = styled.button`
  border-radius: 8px;
  border: ${(props) =>
    props.disabled || props.next ? 'none' : '1px solid #344ef3'};
  height: 35px;
  width: 115px;
  margin-left: 20px;
  color: ${(props) =>
    props.disabled ? '#c1c2c2' : props.next ? '#fff' : '#344ef3'};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: ${(props) =>
    props.disabled ? '#f3f3f4 ' : props.next ? '#344ef3' : '#fff'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) =>
      props.disabled ? '#f3f3f4' : props.next ? '#0c28dd' : '#fff'};
    box-shadow: ${(props) => !props.disabled && '2px 4px 12px 0px #182a331f'};
  }
`;

export const CardWrap = styled.div`
  background-color: #fff;
  padding: 15px 20px 15px 20px;
  border-radius: 8px;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '15px')};
  box-shadow: 2px 4px 12px 0px rgb(24 42 51 / 12%);
`;

export const ClarificationsCardWrap = styled.div`
  padding: 0px 15px 10px 0px;
  width: 100%;
`;

export const Chip = styled.div`
  background: rgb(235, 235, 237);
  border-radius: 16.5px;
  border: 1px solid rgb(235, 235, 237);
  padding: 6px 12px;
  font-size: 14px;
  color: #182429;
  margin-right: 14px;
  margin-bottom: 14px;
  width: fit-content;
`;

export const ShowMoreButton = styled(Button)`
  color: #182a33 !important;
  padding: 0 !important;
  font-weight: 700 !important;
  border-bottom: 1px solid #182a33 !important;
  height: auto !important;
`;

export const DataRoomHeaderWrap = styled(Flexrow)`
  border-bottom: 1px solid rgb(226, 228, 229);
  padding: 10px 80px;
`;

export const DescriptionContainer = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  ${scrollStyles}
  & :first-child {
    padding-right: 60vh;
  }
`;

export const ProductDescriptionModalContainer = styled.div`
  max-height: 55vh;
  overflow-y: scroll;
  padding-right: 18px;
  ${scrollStyles}
`;

export const DrawerContainer = styled(Flexrow)`
  background-color: #53f1eb;
  padding: 8px 100px;
  z-index: 2;
`;

export const OpenChatButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? '#f3f3f4' : props.isOpen ? 'transparent' : '#fff'};
  padding: 10px 40px;
  color: #182a33;
  border: ${(props) => props.isOpen && '1px solid #182a33'};
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const DrawerAndChatContainer = styled(Flexcol)`
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: ${(props) => (props.bottom && !props.isOpen ? '110px' : '0px')};
  width: 100%;
  transition: all 0.5s ease-in-out;
  height: ${(props) => (props.isOpen ? '100%' : '62px')};
  z-index: 2;
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.33);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
`;

export const ChatContainer = styled(Flexcol)`
  background-color: #fff;
  width: 100%;
  z-index: 2;
  padding: 22px 62px;
  overflow-y: auto;
  ${scrollStyles}
`;

export const QuestionContainer = styled(Flexcol)`
  border: 3px solid #000000;
  margin: 18px 0px 26px 0px;
`;

export const QuestionTitleWrap = styled(Flexrow)`
  background-color: #c4c4c4;
  padding: 5px 20px;
`;

export const ThreadsWrap = styled(Flexcol)`
  padding: 12px 32px;
  min-height: 200px;
`;

export const ThreadContainer = styled(Flexcol)`
  border: 1px solid #000000;
  padding: 20px 24px 36px 18px;
  margin-bottom: 12px;
  width: 90%;
  background-color: ${(props) => !props.isQuestion && '#CBCBCB'};
  align-self: ${(props) => !props.isQuestion && 'flex-end'};
`;

export const QuestionInput = styled.div`
  background-color: #f2f2f2;
  padding: 10px 25px 25px 15px;
`;

export const SpinContainer = styled(Flexrow)`
  width: 100%;
  height: 50%;
`;

export const AttachmentWrap = styled(Flexrow)`
  padding: 12px;
  border: 1px solid #000;
  background-color: #fff;
  margin-right: 6px;
  margin-bottom: 6px;
  width: fit-content;
`;
