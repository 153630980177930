import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import {
  CompareArrows,
  SupervisorAccount,
  Settings,
} from 'styled-icons/material';
import { Spin } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

import { fetcherWithToken } from '../../utils/fetcher';
import Connections from '../../components/data-connection/Connections';
import ProjectLayout from '../../components/project/ProjectLayout';
import TabContent from '../../components/project/TabContent';
import AccessControl from '../../components/data-connection/AccessControl';
import useUser from '../../hooks/useUser';
import {
  DataProductContext,
  DataProductWizardHelper,
} from '../../helpers/DataProductWizardHelper';
import { DataProductWizard } from '../../components/data-product-wizard';
import { NodePortal } from '../../components/NodePortal';
import PublishedHeaderWizard from '../../components/data-product-wizard/views/PublishedHeaderWizard';
import { Flexrow } from '../../components/Flexbox';
import { ListingContent } from '../ProductListing/ListingContent';
import Notification from '../../components/Notification';
import { data_placement_type_ids } from '../../iso-shared/values/global';

const DataConnection = (props) => {
  const { token } = useUser();
  const { id } = props.match.params;
  const history = useHistory();

  let { data: project, error } = useSWR(
    id ? [`/api/data-connection-metadata?project_id=${id}`, token] : null,
    fetcherWithToken,
  );

  if (error?.response.status === 404) {
    toast.error(<Notification type="error" text="Unauthorized Access" />);
    history.push('/not-found');
  }

  useEffect(() => {
    if (project) {
      if (window.location.hash == '#settings') {
        document.querySelector('[data-id=project-settings]').click();
      }
    }
  }, [!!project]);

  if (!project)
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <ProjectLayout project={project}>
        <div name="data-tabs">
          <div id="published-product-settings">
            <TabContent
              data-id="project-settings"
              name={'Settings'}
              icon={Settings}
            />
          </div>
          <TabContent name={'Marketplace \nListing'} icon={CompareArrows} />
          <div id="published-product-access-control">
            <TabContent
              name={'Access\nControl'}
              padding="20px 16px"
              icon={SupervisorAccount}
            />
          </div>
        </div>
        <div name="data-tab-contents">
          <div key="1">
            <EditableIntegrationWizard id={id} />
          </div>
          <div key="2" style={{ padding: '24px 32px' }}>
            <Connections project_id={id} />
          </div>
          <div key="3" style={{ padding: '24px 32px' }}>
            <AccessControl project_id={id} />
          </div>
        </div>
      </ProjectLayout>
    </>
  );
};

const EditableIntegrationWizard = (p) => {
  const { id } = p;
  const { token } = useUser();
  const [project, setProject] = useState(null);
  const [, updator] = useState({});
  const [version, setVersion] = useState(0);
  const [isS3, setIsS3] = useState('');

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }
    const helper = new DataProductWizardHelper(
      () => updator({}),
      id,
      token,
      project,
      () => setVersion(version + 1),
    );

    helper.onError = (error) =>
      toast.error(<Notification type="error" text="Could not save changes" />);
    helper.fromProject(project);
    return helper;
  }, [project]);

  useEffect(() => {
    setProject(null);
    const fetcher = async () => {
      const res = await axios.get(`/api/data-placements/editable/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProject(res.data);
    };
    fetcher();
  }, [version, id]);

  const placement_type =
    project?.placement.type_id === data_placement_type_ids.api_link
      ? 'proxy'
      : 's3_data';

  useEffect(() => {
    if (placement_type === 's3_data') {
      setIsS3(true);
    } else {
      setIsS3(false);
    }
  }, [project]);

  if (!project) {
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <DataProductContext.Provider value={helper}>
      {helper.previewing ? (
        <ListingContent helper={helper} />
      ) : (
        <DataProductWizard isS3={isS3} />
      )}
      <NodePortal id="placement-view-navbar">
        <Flexrow>
          <PublishedHeaderWizard
            no_back_button
            review={{ project: helper.project }}
          />
        </Flexrow>
      </NodePortal>
    </DataProductContext.Provider>
  );
};

export default DataConnection;
