import styled from "styled-components";
import { Row, Menu, Button } from "antd";

import Colors from "./colors";
import { css } from "styled-components";

export const SectionCardContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px #182a330f;
  padding: 42px;
`;

export const Container = styled.div`
  width: 100%;
  color: ${Colors.lightGray};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "22px")};
  margin-top: ${(props) => props.top && props.top};
  display: ${(props) => props.flex && "flex"};
  align-items: ${(props) => props.align && props.align};
  flex-wrap: ${(props) => props.wrap && "wrap"};
  padding: ${(props) => props.padding && props.padding};
  position: relative;
  margin-top: ${(props) => props.editing && "-95px"};
`;

export const MainHeader = styled.h1`
  color: ${Colors.title};
  font-size: 28px;
  font-weight: normal;
  text-align: center;
`;

export const Title = styled.span`
  display: ${(props) => (props.display ? props.display : "inline-block")};
  color: ${(props) => (props.color ? props.color : Colors.title)};
  font-size: ${(props) => props.size && `${props.size}px`};
  font-weight: ${(props) => props.weight && props.weight};
  margin: ${(props) => props.margin && props.margin};
  cursor: ${(props) => props.cursor && props.cursor};
  line-height: ${(props) => props.line && `${props.line}`};
`;

export const Desc = styled.span`
  color: ${(props) => (props.color ? props.color : Colors.description)};
  font-size: ${(props) => props.size && `${props.size}px`};
  white-space: ${(props) => props.pre && "pre-line"};
  display: ${(props) => props.display && props.display}; ;
`;

export const Separator = styled.div`
  height: ${(props) => props.height && `${props.height}px`};
`;

export const StepperRow = styled(Row)`
  cursor: pointer;
  margin-bottom: 94px;
  text-align: right;
`;

export const StepperButton = styled.button`
  border-radius: 8px;
  border: ${(props) =>
    props.disabled || props.next ? "none" : "1px solid #344ef3"};
  height: 41px;
  width: 89px;
  color: ${(props) =>
    props.disabled ? "#c1c2c2" : props.next ? "#fff" : "#344ef3"};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: ${(props) =>
    props.disabled ? "#f3f3f4 " : props.next ? "#344ef3" : "#fff"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: ${(props) =>
      props.disabled ? "#f3f3f4" : props.next ? "#0c28dd" : "#fff"};
    box-shadow: ${(props) => !props.disabled && "2px 4px 12px 0px #182a331f"};
  }
`;

export const StepperCircleIndex = styled.div`
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#344ef3" : "#c1c2c2")};
  justify-content: center;
  box-shadow: 2px 4px 12px 0px #182a331f;
  display: flex;
  align-items: center;
  height: 42px;
  width: 42px;
`;

export const StepperCircleCheck = styled.div`
  border: 2px solid ${(props) => (props.active ? "#344ef3" : "#64737f")};
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  justify-content: center;
  box-shadow: 2px 4px 12px 0px
    ${(props) => (props.active ? "#182a331f" : "transparent")};
  display: flex;
  align-items: center;
  height: 42px;
  width: 42px;
`;

export const StepperLine = styled.div`
  border-right: 1px solid #c1c2c2;
  height: calc(100% + 51px);
  width: 20px;
`;

export const SubmitMenuContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px #182a330f;
`;

export const SubmitMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubmitMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  line-height: normal;
  margin: 8px;
  height: 57px !important;
  color: #182429 !important;
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        background: #ffeee1;
        border-radius: 8px;
      }
    `}
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const SubmitButton = styled(Button)`
  background-color: #ff7f19;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 41px;
  width: 124px;
  &:hover,
  &:focus {
    background: #e56b0a;
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px #182a331f;
    color: #fff;
  }
`;

export const PreviewButton = styled(Button)`
  background-color: #fff;
  border-radius: 8px;
  color: #ff7f19;
  font-size: 15px;
  font-weight: 500;
  height: 41px;
  border: 1px solid #ff7f19;
  &:hover,
  &:focus {
    border: 1px solid #e56b0a;
    box-shadow: 2px 4px 12px 0px #182a331f;
    color: #ff7f19;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GoToDashBoardContainer = styled.div`
  border-right: 1px solid #e2e4e5;
  display: flex;
  align-items: center;
  height: 47px;
`;
