import React, { useContext } from "react";
import { CloudCheck } from "@styled-icons/bootstrap/CloudCheck";
import styled from "styled-components";
import { Modal } from "antd";

import { Extension } from "@styled-icons/material/Extension";
import { Link45deg } from "@styled-icons/bootstrap/Link45deg";

import { Flexcol, Flexrow } from "../../../../components/Flexbox";
import { Chip } from "../../../../components/listing-components/Chip";
import ItemCard from "../../../../components/listing-components/ItemCard";
import { EndpointView } from "./EndpointView";
import { EndpointDetails } from "./EndpointDetails";
import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";

const Chips = styled(Flexrow)`
  flex-wrap: wrap;
`;

const getScreenWidthHandler = () => {
  let width;
  if (screen.width <= 768) {
    return (width = "95vw");
  } else if (screen.width > 768 && screen.width <= 2560) {
    return (width = "75vw");
  } else if (screen.width > 2560) {
    return (width = "50vw");
  } else {
    return (width = "75vw");
  }
};

export const DetailsTab = () => {
  const helper = useContext(DataProductContext);
  const delivery_specs_format = helper.delivery_specs_format.value;
  const endpoints = helper.endpoints.value;
  const show_light = !helper.previewing && helper.published_step == 2;

  const handleOnViewEndpoint = (e) => () => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <EndpointDetails listing_name={helper.name.value} endpoint={e} />
      ),
      okText: "close",
      centered: true,
      closable: true,
      style: { paddingBottom: 0 },
      width: getScreenWidthHandler(),
    });
  };

  return (
    <Flexcol>
      <ItemCard title="Delivery method" icon={<CloudCheck />}>
        {helper.placement_type == "api" ? "API" : "S3 Bucket"}
      </ItemCard>
      {!!delivery_specs_format.length && (
        <ItemCard title="Format" icon={<Extension />}>
          <Chips>
            {delivery_specs_format.map((v) => (
              <Chip key={v}>{v}</Chip>
            ))}
          </Chips>
        </ItemCard>
      )}
      {!show_light && !!endpoints.length && (
        <ItemCard title="API endpoints" icon={<Link45deg />}>
          {endpoints.map((e) => (
            <EndpointView
              onViewDetails={handleOnViewEndpoint(e)}
              key={e.form_id}
              endpoint={e}
            />
          ))}
        </ItemCard>
      )}
    </Flexcol>
  );
};
