import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import LayoutRow from '../../components/LayoutRow';
import Header from '../../components/Header';
import { ProductStickyHeader } from '../../components/StickyHeader';
import { KeyboardArrowLeft } from 'styled-icons/material';
import { Flexrow } from '../../components/Flexbox';
import { Spin, Typography } from 'antd';
import { useServerData } from '../../hooks/useServerData';
import {
  DataProductContext,
  DataProductWizardHelper,
} from '../../helpers/DataProductWizardHelper';
import axios from 'axios';
import { ListingContent } from './ListingContent';
import { toast } from 'react-toastify';
import Notification from '../../components/Notification';
import { DataProductType } from '../../helpers/DataProductHelper';

const { Title } = Typography;

export const ProductListing = (p) => {
  const { id, url_id } = p.match.params;

  const [, updator] = useState({});
  const [stickyHeader, setStickyHeader] = useState(false);
  const [reload, setReload] = useState(false);

  const server_project = useServerData('listing');
  const server_project_not_found = useServerData('listing_not_found');
  const [project, setProject] = useState(server_project);
  const [not_found, stetNotFound] = useState(!!server_project_not_found);

  useEffect(() => {
    const fetchListing = async () => {
      if (!project) {
        let res;
        try {
          if (id) {
            res = await axios.get(`/api/data-placements/editable/${id}/origin`);
          } else {
            res = await axios.get(
              `/api/data-placements/editable/origin-by-url/${url_id}`,
            );
          }
        } catch (error) {
          console.error(error);
          if (error.isAxiosError && error.response.status == 404) {
            stetNotFound(true);
          } else {
            toast.error(
              <Notification
                type="error"
                text="An error occured, please try again"
              />,
            );
          }
          return;
        }
        setProject(res.data);
      }
    };
    fetchListing();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setStickyHeader(window.pageYOffset > 420),
      );
    }
  }, []);

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const helper = new DataProductWizardHelper(
      () => updator({}),
      project.id,
      null,
      project,
    );
    helper.onError = (error) =>
      toast.error(<Notification type="error" text="Could not save changes" />);
    helper.fromProject(project);

    return helper;
  }, [!!project]);

  const mainView = () => {
    if (not_found) {
      return (
        <Flexrow grow center_content>
          <Title level={3}>Listing not found</Title>
        </Flexrow>
      );
    }
    if (helper) {
      return (
        <ListingContent
          dataProductId={project?.placement?.id}
          dataProductType={DataProductType(project?.placement?.type_id)}
          setReload={setReload}
          reload={reload}
        />
      );
    }
    return <Spin size="large" />;
  };

  return (
    <DataProductContext.Provider value={helper}>
      <LayoutRow background={'#fff'} withBorder>
        <Header
          stepsFor={
            helper?.published_step === 2 ? 'DATA_PRODUCT_LIGHT' : 'DATA_PRODUCT'
          }
        />
      </LayoutRow>
      <Flexrow center_align style={{ height: 50 }}>
        <Flexrow left="150px" />
        <Link to="/">
          <KeyboardArrowLeft size="32" style={{ color: '#a8afb3' }} />
        </Link>
      </Flexrow>
      {helper && (
        <ProductStickyHeader
          show={stickyHeader}
          name={project?.placement.name}
          setReload={setReload}
          reload={reload}
        />
      )}
      {mainView()}
    </DataProductContext.Provider>
  );
};
