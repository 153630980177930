import { TreeSelect } from "antd";

const { TreeNode } = TreeSelect;

const getTreeNode = (node) => {
  const { name, label, tree } = node;
  return (
    <TreeNode key={label} value={label} title={name}>
      {tree ? tree.nodesTree.map(getTreeNode) : null}
    </TreeNode>
  );
};

export const EndpointTreeSelect = (props) => {
  const { traversor, value, onChange } = props;

  return (
    <TreeSelect
      showSearch
      style={{ width: "100%" }}
      value={value}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Select endpoint properties"
      allowClear
      multiple
      onChange={onChange}
    >
      <TreeNode value="/" title="/">
        {traversor.nodesTree.map(getTreeNode)}
      </TreeNode>
    </TreeSelect>
  );
};
