import React from 'react';
import axios from 'axios';
import { Typography } from 'antd';
import { toast } from 'react-toastify';

import { Flexcol } from '../Flexbox';
import fly_image from '../../images/fly.svg';
import FormLayout from '../FormLayout';
import Notification from '../Notification';

const { Text } = Typography;

const Notified = ({ form_data }) => {
  const { email } = form_data;

  const handleSubmit = async (form_data) => {
    const response = await axios.post(
      `${process.env.RAZZLE_ACCOUNT_URL}/auth/verification-email/${form_data.email}`,
    );
    if (response.status === 200) {
      toast.success(
        <Notification type="success" text="Email resent successfully!" />,
      );
    }
  };

  const subtitle =
    'We have sent an email to ' +
    email +
    '\nwith a link to reset your password.';

  return (
    <FormLayout
      title="Check your email"
      subtitle={subtitle}
      isVerification={true}
    >
      <Flexcol>
        <div>
          <Text style={{ color: '#fff', fontSize: '15px', marginRight: '4px' }}>
            Didn’t get the email?
          </Text>
          <Text
            style={{
              color: '#fff',
              fontSize: '15px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => handleSubmit(form_data)}
          >
            Resend the confirmation email.
          </Text>
        </div>
        <img src={fly_image} alt="fly" style={{ marginTop: '100px' }} />
      </Flexcol>
    </FormLayout>
  );
};

export default Notified;
