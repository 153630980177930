let origin_path = null

export const LoginRedirector = {
  toLogin: history => {
    origin_path = window.location.pathname + window.location.search + window.location.hash
    history.push('/login')
  },
  retrievePath: history => {
    const out = origin_path
    origin_path = null
    history.push(out || '/')
  },
  releasePath: () => {
    origin_path = null
  }
}