import React, { useEffect, useState } from 'react';
import {
  postDiscussionThread,
  deleteDiscussionThread,
} from '../chat/chat-services';
import { Tooltip } from 'antd';
import { UnselectItemModal } from '../modals/UnselectItemModal';
import { strings } from '../assets/strings';
import styles from '../assets/CustomCheckbox.module.css';
import Cookies from 'js-cookie';

export const CustomCheckbox = (props) => {
  const {
    selectionDiscussionThreadsIds,
    initialCheckedState,
    selectable,
    readOnly,
    dataRoomId,
    setRefresh,
    selectionIndex,
    selectionProperties,
    category,
    setCustomSelectionChecked,
  } = props;

  // Collect the checked checkboxes
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [discussionThreadIds, setDiscussionThreadIds] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const token = Cookies.get('jwt');

  // Gets triggered when a checkbox get's checked/unchecked and toggles the state of the checkbox
  const handleCheckboxChange = (position, title, discussionThreadId) => {
    const checkedPosition = checkedCheckboxes[position];

    if (checkedPosition) {
      deleteDiscussionThread(token, discussionThreadId)
        .then((res) => {
          setRefresh((prevState) => !prevState);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      postDiscussionThread(token, dataRoomId, title, category)
        .then((res) => {
          setDiscussionThreadIds(
            ...selectionDiscussionThreadsIds,
            (selectionDiscussionThreadsIds[position] = res.data.id),
          );
          setRefresh((prevState) => !prevState);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemovable = () => {
    setVisibleModal(!visibleModal);
  };

  useEffect(() => {
    setCheckedCheckboxes([...initialCheckedState]);
    setCustomSelectionChecked([...initialCheckedState]);
    setDiscussionThreadIds([...selectionDiscussionThreadsIds]);
  }, [initialCheckedState]);

  return (
    <>
      {readOnly === true && (
        <input
          key={`cb-${selectionIndex}-${category}`}
          className={styles.CheckboxRound}
          type="checkbox"
          id={`checkbox-${selectionIndex}`}
          checked={checkedCheckboxes[selectionIndex]}
          value={selectionProperties.title}
          name={selectionProperties.title}
          disabled
        />
      )}

      {selectable && !selectionProperties.removable && readOnly === undefined && (
        <Tooltip
          color="rgba(26, 51, 125, 1)"
          placement="bottom"
          title={strings.clarifications.tooltip_active_discussion_thread}
          destroyTooltipOnHide="true"
        >
          <span>
            <input
              key={`cb-${selectionIndex}-${category}`}
              className={styles.CheckboxRound}
              type="checkbox"
              id={`checkbox-${selectionIndex}`}
              checked={checkedCheckboxes[selectionIndex]}
              value={selectionProperties.title}
              name={selectionProperties.title}
              disabled
            />
          </span>
        </Tooltip>
      )}

      {selectable && selectionProperties.removable && readOnly === undefined && (
        <>
          {selectionProperties.draft ? (
            <>
              <UnselectItemModal
                discussionThreadId={selectionProperties.discussionThreadId}
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                setRefresh={setRefresh}
              ></UnselectItemModal>
              <input
                key={`cb-${selectionIndex}-${category}`}
                className={styles.CheckboxRound}
                type="checkbox"
                id={`checkbox-${selectionIndex}`}
                checked={checkedCheckboxes[selectionIndex]}
                value={selectionProperties.title}
                name={selectionProperties.title}
                onChange={() => handleRemovable()}
              />
            </>
          ) : (
            <input
              key={`cb-${selectionIndex}-${category}`}
              className={styles.CheckboxRound}
              type="checkbox"
              id={`checkbox-${selectionIndex}`}
              checked={checkedCheckboxes[selectionIndex]}
              value={selectionProperties.title}
              name={selectionProperties.title}
              onChange={() =>
                handleCheckboxChange(
                  selectionIndex,
                  selectionProperties.title,
                  selectionDiscussionThreadsIds[selectionIndex],
                )
              }
            />
          )}
        </>
      )}

      <label style={{ marginLeft: 8 }} id={`checkbox-${selectionIndex}`}>
        <strong>{selectionProperties.title}</strong>
      </label>
    </>
  );
};
