import React, { useEffect, useState } from "react";
import axios from "axios";
import { Input } from "antd";
import { MagnifyingGlass } from "@styled-icons/open-iconic/MagnifyingGlass";

const { Search } = Input;

const { RAZZLE_BASE_URL } = process.env;

const SearchBarIndexUI = (p) => {
  const { setDataProducts, setDataRequests, setForceVisible, small } = p;
  const [search_value, setSearchValue] = useState("");

  useEffect(() => {
    let canceled = false;
    const updateLists = async () => {
      if (setDataProducts) {
        const res = await axios.get(`${RAZZLE_BASE_URL}/api/metadata-search`, {
          params: { text: search_value },
        });
        if (!canceled) {
          setDataProducts(res.data);
        }
      }
      if (setDataRequests) {
        const res = await axios.get(
          `${RAZZLE_BASE_URL}/api/requests-metadata-search`,
          {
            params: { text: search_value },
          }
        );
        if (!canceled) {
          setDataRequests(res.data);
        }
      }
    };
    const timeout = setTimeout(updateLists, 200);
    return () => {
      canceled = true;
      clearTimeout(timeout);
    };
  }, [search_value]);

  return (
    <>
      <Search
        onChange={(e) => {
          setSearchValue(e.target.value);
          setForceVisible && setForceVisible();
        }}
        enterButton={
          <MagnifyingGlass style={{ height: small ? "16px" : "20px" }} />
        }
        allowClear
        style={{
          borderRadius: "8px",
          marginBottom: small ? "25px" : "56px",
          width: small ? "30%" : "40%",
          fontSize: small ? "15px" : "18px",
        }}
        size="large"
      />
      <style>
        {`.ant-input.ant-input-lg {
        font-size: ${small ? "15px" : "18px"};
        padding: ${small ? "8px 4px !important" : "15px 30px !important"};
      }
      .ant-input-group-addon {
        background-color: #fff;
        padding: 0 24px !important;
        border: ${small ? "1px solid #64737F !important" : "0 !important"};
        border-bottom-right-radius: ${small && "8px !important"};
        border-top-right-radius: ${small && "8px !important"};
        border-bottom-left-radius: ${small && "0px !important"};
        border-top-left-radius: ${small && "0px !important"};
        border-left: ${small && "0px !important"};
      }
      .ant-input-search-large .ant-input-search-button {
        background-color: #344ef3;
        border-radius: 8px !important;
        width: 73px;
      }
      .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border: 0 !important;
        box-shadow: none;
        border-bottom-right-radius: ${small && "0px"};
        border-top-right-radius: ${small && "0px"};
        border-bottom-left-radius: ${small && "8px"};
        border-top-left-radius: ${small && "8px"};
        border-top: ${small && "1px solid #64737F !important"};
        border-bottom: ${small && "1px solid #64737F !important"};
        border-left: ${small && "1px solid #64737F !important"};
        border-right: ${small && "0px"};
      }
      .ant-input-clear-icon.anticon svg {
        width: ${small ? "18px" : "25px"};
        height: ${small ? "18px" : "25px"};
      }
      `}
      </style>
    </>
  );
};

export default SearchBarIndexUI;
