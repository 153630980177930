export const getScreenWidthHandler = () => {
  let width;
  if (screen.width <= 768) {
    return (width = '95vw');
  } else if (screen.width > 768 && screen.width <= 2560) {
    return (width = '75vw');
  } else if (screen.width > 2560) {
    return (width = '50vw');
  } else {
    return (width = '75vw');
  }
};
