import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Typography } from "antd";
import { TurnedIn } from "styled-icons/material";
import { CheckCircleFill } from "@styled-icons/octicons/CheckCircleFill";
import { Circle } from "@styled-icons/bootstrap/Circle";

import { listingType } from "./ListingTypeLabel";
import { getThumbnailURL } from "../iso-shared/utils/getThumbnailUrl";
import { colors } from "../values/colors";
import { Flexrow } from "../components/Flexbox";
import { scrollStyles } from "../styles/scrollStyles";
import { AppContext } from "../hooks/AppContextProvider";

const { Text } = Typography;

const Wrap = styled.div`
  position: relative;
  padding: 20px 0 20px 24px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  border: ${(props) => props.isSelected && "2px solid #344ef3"};
  ${scrollStyles}
  &:hover {
    border: 2px solid #344ef3;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.24);
    transition: all 250ms;
  }
`;

const ModuleTitle = styled(Text)`
  color: #182429;
  font-size: 16px;
  letter-spacing: -0.5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #344ef3 !important;
    font-weight: 700;
  }
`;

const MarketplaceModule = (props) => {
  const { module, setModalTab, setSelectedProject } = props;
  const imageUrl = getThumbnailURL(module.image_url);
  const [isSelected, setIsSelected] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    context.setDashboardProps({
      ...context.dashboard_props,
      selected: { module: module, isSelected: isSelected },
    });
  }, [isSelected]);

  const selectedToggle = () => {
    setIsSelected((prevState) => !prevState);
  };

  useEffect(() => {
    if (module.id === context.dashboard_props.selected?.module.id) {
      setIsSelected(context.dashboard_props.selected?.isSelected);
    }
    if (context.dashboard_props.selectedListings.length === 0) {
      setIsSelected(false);
    }
  }, [context.dashboard_props]);

  return (
    <Wrap onClick={() => selectedToggle()} isSelected={isSelected}>
      {isSelected ? (
        <CheckCircleFill
          style={{
            height: "18px",
            position: "absolute",
            right: "10px",
            top: "10px",
            color: "#344EF3",
          }}
        />
      ) : (
        <Circle
          style={{
            height: "18px",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        />
      )}
      <Row wrap={false}>
        <img
          style={{ marginRight: 8, padding: "10px" }}
          width="83"
          height="84"
          alt="mobility-module-image"
          src={imageUrl}
        />
        <Col xs={20} style={{ paddingLeft: 16 }}>
          <Row style={{ paddingLeft: 4, width: "max-content" }} align="middle">
            <Text
              style={{
                fontSize: "11px",
                color: "#0494dd",
                fontWeight: "600",
              }}
            >
              DATA PRODUCT
            </Text>
            {listingType(module.type_id)}
          </Row>
          <Flexrow top="12px" />
          <Row style={{ paddingLeft: 4 }}>
            <ModuleTitle
              onClick={(e) => {
                e.stopPropagation();
                setModalTab("2");
                setSelectedProject(module.id);
              }}
            >
              {module.name}
            </ModuleTitle>
          </Row>
          <Flexrow top="12px" />
          <Row align="middle">
            <TurnedIn
              size="16"
              style={{ color: colors.grey_4, marginRight: "2px" }}
            />
            <Text
              style={{
                color: "#64737f",
                fontSize: "13px",
                letterSpacing: "-0.5px",
              }}
            >
              {module.category_name}
            </Text>
          </Row>
        </Col>
      </Row>
    </Wrap>
  );
};

export default MarketplaceModule;
