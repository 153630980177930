import React, { useContext, useReducer, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Dropdown, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { InfoSquare } from '@styled-icons/bootstrap/InfoSquare';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { HeaderAccountMenu } from './HeaderAccountMenu';
import mobito_logo from '../images/mobito-new-logo.svg';
import { AccountContext } from '../hooks/AccountContext';
import walkthroughSteps from './walkthroughSteps';
import { Flexrow, Flexcol } from './Flexbox';
import UserAvatarSrc from '../images/user-avatar.svg';

const { Text } = Typography;

const SNav = styled(Col)`
  float: right;
  > * {
    margin: 0 0 0 28px;
  }
`;

const SLogo = styled.div`
  float: left;
  cursor: pointer;
`;

const SHeader = styled(Row)`
  margin-top: 16px;
  padding-bottom: 16px;
`;

const ActionButton = styled.button`
  border-radius: 8px;
  padding: 10px 25px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #344ef3;
  color: #344ef3;
  width: 100%;
  font-size: 15px;
  &:hover,
  :active,
  :focus {
    background: #344ef3;
    border: 1px solid #344ef3;
    color: #fff;
  }
`;

const Backdrop = styled.div`
  background-color: #18242954;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 9;
`;

const INITIAL_STATE = {
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  key: new Date(),
};

const StickyHeader = styled(Flexrow)`
  background-color: #182a33;
  justify-content: center;
  position: relative;
  margin-right: -10.4rem;
  margin-left: -10.4rem;
`;

const GoToProfileButton = styled.button`
  position: absolute;
  right: 125px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 4px 55px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #182a33;
    background-color: #fff;
  }
`;

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

const Header = ({
  stepsFor,
  isReview,
  setWalkthrough,
  onSetTab,
  isUpdated,
  tab,
  user,
  forceVisible,
}) => {
  const { account, isComplete } = useContext(AccountContext) || {};
  let email = Cookies.get('email');
  let firstname = Cookies.get('firstname');
  const [isOpen, setIsOpen] = useState(false);
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const history = useHistory();
  const [completed, setCompleted] = useState(true);
  const [isAccount, setIsAccount] = useState(false);
  const is_logged_in = (account && account.user.id) || null;

  useEffect(() => {
    setCompleted(isComplete);
  }, [isComplete]);

  useEffect(() => {
    if (user) email = user.user.email;
  }, [user, account]);

  const callback = (data) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  if (setWalkthrough) {
    if (tourState.stepIndex === 0) {
      setWalkthrough(true);
    } else {
      setWalkthrough(false);
    }
  }

  const startTour = () => {
    if (forceVisible) forceVisible();
    dispatch({ type: 'RESTART' });
  };

  const routeHandler = (route) => {
    if (isUpdated) {
      onSetTab(tab)();
    } else {
      history.push(route);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.pathname.indexOf('account') === -1) {
        setIsAccount(true);
      } else {
        setIsAccount(false);
      }
    }
  }, []);

  const goToProfileHandler = () => {
    if (!completed.personal) {
      history.push('/account');
    } else if (!completed.company) {
      history.push({ pathname: '/account', state: { key: 'company' } });
    }
  };

  return (
    <>
      <div>
        <Joyride
          showSkipButton={true}
          {...tourState}
          callback={callback}
          steps={walkthroughSteps(stepsFor)}
          scrollOffset={400}
          styles={{
            options: {
              overlayColor: '#344EF380',
            },
            buttonSkip: {
              color: '#344EF3',
              fontSize: '15px',
              padding: '10px 24px',
            },
            buttonNext: {
              backgroundColor: '#344EF3',
              color: '#344EF3',
              fontSize: '15px',
              color: '#fff',
              borderRadius: '8px',
              padding: '10px 24px',
            },
            buttonBack: {
              backgroundColor: '#fff',
              color: '#344EF3',
              border: '1px solid #344EF3',
              fontSize: '15px',
              borderRadius: '8px',
              padding: '10px 24px',
            },
          }}
          locale={{
            last: 'OK, got it',
            skip: 'Exit',
          }}
        />
      </div>
      <SHeader middle="xs">
        <Col xs={6}>
          <SLogo onClick={() => routeHandler('/')}>
            <img src={mobito_logo} alt="mobito logo" width="82" height="25" />
          </SLogo>
        </Col>
        <Col xs={6}>
          <Row end="xs">
            <Col x={6}>
              <SNav>
                <Row id="navigation-row" middle="xs">
                  {stepsFor && !isReview && (
                    <InfoSquare
                      id="start-walkthrough"
                      style={{
                        height: '20px',
                        color: '#182429',
                        marginRight: '28px',
                        cursor: 'pointer',
                      }}
                      onClick={startTour}
                    />
                  )}
                  {email ? (
                    <>
                      <Col>
                        <Row center="xs">
                          <Dropdown
                            placement="bottomCenter"
                            overlay={
                              <HeaderAccountMenu
                                user={user}
                                routeHandler={routeHandler}
                                isUpdated={isUpdated}
                              />
                            }
                            onVisibleChange={() => setIsOpen(!isOpen)}
                          >
                            <Flexrow
                              className="my-profile-step"
                              center_align
                              id="my-settings"
                            >
                              <img
                                src={UserAvatarSrc}
                                style={{
                                  backgroundColor: '#F0F1F2',
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  marginRight: '10px',
                                }}
                              />
                              <Flexcol>
                                <Text
                                  style={{
                                    color: '#182A33',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {firstname ? `Welcome, ` + firstname : email}
                                </Text>
                              </Flexcol>
                              <DownOutlined
                                style={{
                                  marginLeft: '10px',
                                  color: '#53f1eb',
                                  fontSize: '12px',
                                }}
                              />
                            </Flexrow>
                          </Dropdown>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>
                        <Link to="/login">
                          <ActionButton>Login / Signup</ActionButton>
                        </Link>
                      </Col>
                    </>
                  )}
                </Row>
              </SNav>
            </Col>
          </Row>
        </Col>
      </SHeader>
      {is_logged_in && (!completed.personal || !completed.company) && (
        <StickyHeader>
          <Text
            style={{
              fontSize: '14px',
              color: '#fff',
              padding: '12px 0px',
            }}
          >
            {`Your ${
              !completed.personal
                ? 'personal information is'
                : 'company details are'
            } incomplete. Please complete your profile.`}
          </Text>
          {isAccount && (
            <GoToProfileButton onClick={goToProfileHandler}>
              Go to Profile
            </GoToProfileButton>
          )}
        </StickyHeader>
      )}
      {isOpen && <Backdrop />}
    </>
  );
};

export default Header;
