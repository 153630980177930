import { colors } from "../values/colors";
import { Col, Row } from "react-styled-flexboxgrid";

const LayoutRow = (props) => (
  <Row
    className={props.className}
    style={{
      backgroundColor: props.background,
      borderBottom: props.withBorder ? `1px solid ${colors["grey_2"]}` : ``,
      boxShadow: props.withShadow
        ? `2px 4px 12px 0px rgba(24, 42, 51, 0.06)`
        : ``,
      marginRight: "unset",
      marginLeft: "unset",
      position: props.sticky && "sticky",
      top: props.sticky && 0,
      zIndex: props.sticky && 99,
    }}
  >
    <Col
      xs={12}
      style={{ boxShadow: props.sticky && "2px 12px 12px 0px #182a330f" }}
    >
      <Row center="xs">
        <Col xs={props.full ? 12 : 10}>{props.children}</Col>
      </Row>
    </Col>
  </Row>
);

export default LayoutRow;
