import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
} from '../../styles/termsAndConditions';

const LiabilityTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        L
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>IABILITY</TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito shall take all reasonable efforts to provide the Client
              with access to the Platform and Services in accordance with this
              Agreement. The liability which Mobito may incur derives from a
              reasonable effort obligation (inspanningsverbintenis/obligation de
              moyen) and the Client will have to provide proper proof of such
              liability.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              A party’s liability for direct damages caused by an attributable
              shortcoming in the fulfilment of the Agreement is limited, per
              contract year and per Order Form, to maximum the yearly value of
              the Order Form. In case the Agreement is concluded between Mobito
              and the Data Provider, the yearly value of the Order Form means
              the Data Provider Charges, the compensation paid by Mobito to the
              Data Provider for each licensing of Data to a Data Customer and
              the fees paid by the Data Provider to Mobito for the rendering of
              the Services, as stated in the applicable Order Form. In case the
              Agreement is concluded between Mobito and the Data Customer, the
              yearly value of the Agreement is the total amount paid by the Data
              Customer, as stated in the applicable Order Form or the Platform.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Under no circumstances shall Mobito be liable for (i) indirect,
              incidental or consequential damages, including but not limited to
              financial or commercial losses, loss of profit, increase of
              general expenses, lost savings, diminished goodwill, damages
              resulting from business interruption or interruption of operation,
              damages resulting from claims of consumers of the Client,
              disruptions in planning, loss of anticipated profit, loss of
              capital, loss of consumers, missed opportunities, loss of data,
              loss of advantages, or corruption and/or loss of files resulting
              from the performance of the present Agreement, (ii) damages
              resulting from a fault or negligence of the Client, (iii) damage
              resulting from the Data, (iv) compensation of any direct and
              indirect damages caused by the use of the result of the Services
              or Platform, and (v) all third party claims brought against the
              Client (including claims brought against the Data Provider by the
              Data Customer).
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito shall not be liable for any loss of Data if the Data is not
              provided in the form prescribed by the Agreement. Mobito is not
              responsible for any content or information of external websites,
              which can be accessed via electronic references ("links") on the
              Platform. Furthermore, Mobito cannot be held liable whatsoever for
              the use of the Data by third parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The Client must inform Mobito in writing should it become aware of
              any event that may lead to the latter's liability and of any
              disadvantage and/or loss, within the shortest possible time and at
              the latest within thirty (30) calendar days from the occurrence of
              this event, disadvantage or loss. In the event of failure to make
              the written notification, Mobito reserves the right to reject any
              liability.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Nothing in this Agreement shall exclude or limit the liability of
              either party to the other party for wilful misconduct, fraud,
              gross negligence, bodily harm or any other circumstances where
              liability may not be limited under any applicable law.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default LiabilityTerms;
