import { useContext } from 'react'
import ServerDataContext from '../ServerDataContext'

export const useServerData = field => {
    let context_data = useContext(ServerDataContext)
    if ( typeof(__SERVER_DATA__) != 'undefined' ) {
        const out = __SERVER_DATA__[field]
        delete __SERVER_DATA__[field]
        return out
    }
    if (context_data) {
        return context_data[field]
    }
}