import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

import { FileEarmarkText } from '@styled-icons/bootstrap/FileEarmarkText';
import { Server } from '@styled-icons/bootstrap/Server';
import { CloudDownload } from '@styled-icons/bootstrap/CloudDownload';
import { FileSpreadsheet } from '@styled-icons/bootstrap/FileSpreadsheet';
import { GeoAlt } from '@styled-icons/bootstrap/GeoAlt';
import { Globe } from '@styled-icons/bootstrap/Globe';
import { HourglassBottom } from '@styled-icons/bootstrap/HourglassBottom';
import { Info } from '@styled-icons/entypo/Info';

import { Flexcol, Flexrow } from '../../../../components/Flexbox';
import ItemCard from '../../../../components/listing-components/ItemCard';
import { Chip } from '../../../../components/listing-components/Chip';
import { scrollStyles } from '../../../../styles/scrollStyles';
import TextModal from '../../../../components/listing-components/TextModal';
import DataParametersModal from '../../../../components/listing-components/DataParametersModal';
import { useContext } from 'react';
import { DataRequestContext } from '../../../../helpers/DataRequestWizardHelper';

const DescriptionContainer = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  ${scrollStyles}
`;

const ParametersButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  background: #fff !important;
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
`;

const Chips = styled(Flexrow)`
  flex-wrap: wrap;
`;

const ItemCardChildTitle = styled.span`
  color: #182a33;
  font-size: 13px;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
  width: ${(props) => props.width && props.width};
`;

const ItemCardChildParameter = styled.span`
  display: block;
  width: ${(props) => props.width && props.width};
`;

const ItemCardChildList = styled.ul`
  padding-left: 26px;
`;

const ItemCardChildListItem = styled.li`
  color: #182429;
  font-size: 14px;
  font-weight: bold;
`;

const ItemCardChildListItemContent = styled.div`
  margin-bottom: 21px;
`;

const getScreenWidthHandler = () => {
  let width;
  if (screen.width <= 768) {
    return (width = '95vw');
  } else if (screen.width > 768 && screen.width <= 2560) {
    return (width = '75vw');
  } else if (screen.width > 2560) {
    return (width = '50vw');
  } else {
    return (width = '75vw');
  }
};

const nameTrimHandler = (name) => {
  let maxLength = 40;
  if (name?.length > maxLength) {
    return name.substring(0, maxLength) + '...';
  } else {
    return name;
  }
};

const MAX_DISPLAYED_PROJECT_DATA_SPECS_PARAMS = 5;

const OverviewTab = () => {
  const helper = useContext(DataRequestContext);
  const projectDescription = helper.usecaseDescription.value;
  const projectAdditionalInfo = helper.additionalInfo.value;
  const projectDataSpecsVolume = helper.dataVolume.value;
  const projectDataSpecsSources = helper.sources.value;
  const projectDataSpecsParams = helper.parameters.value;
  const projectDataGeoCoverage = helper.geoCoverage.value;
  const projectDataLanguage = helper.languages.value;
  const projectDataAvailability = helper.availability.value;
  const displayProjectDataSpecsParams = projectDataSpecsParams.slice(
    0,
    MAX_DISPLAYED_PROJECT_DATA_SPECS_PARAMS,
  );

  const checkProjectDataAvailability = (dataAvailability) =>
    projectDataAvailability[dataAvailability] != null;

  const checkObject = () => {
    if (
      Object.values(projectDataAvailability).filter((key) => key === null)
        .length === 3 ||
      Object.values(projectDataAvailability).length === 0
    ) {
      return false;
    }
    return true;
  };

  const handleTextModal = (title, text) => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <TextModal name={helper.title.value} title={title} text={text} />
      ),
      okText: 'Close',
      closable: true,
      centered: true,
      style: { paddingBottom: 0 },
      width: getScreenWidthHandler(),
    });
  };

  const handleDataParametersModal = () => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <DataParametersModal
          name={helper.title.value}
          title="Data attributes"
          parameters={projectDataSpecsParams}
        />
      ),
      okText: 'Close',
      closable: true,
      centered: true,
      style: { paddingBottom: 0 },
      width: getScreenWidthHandler(),
    });
  };

  const continentStringFormat = (string) => {
    let splitString = string.toLowerCase().replace('_', ' ').split(' ');

    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join(' ');
  };

  return (
    <Flexcol>
      {projectDescription && (
        <ItemCard
          title="Use case description"
          icon={<FileEarmarkText />}
          openTextModal={() =>
            handleTextModal('Use case description', projectDescription)
          }
        >
          <DescriptionContainer
            dangerouslySetInnerHTML={{
              __html: projectDescription,
            }}
          ></DescriptionContainer>
        </ItemCard>
      )}
      {projectAdditionalInfo && (
        <ItemCard
          title="Additional information"
          icon={<Info />}
          openTextModal={() =>
            handleTextModal('Additional information', projectAdditionalInfo)
          }
        >
          <DescriptionContainer
            dangerouslySetInnerHTML={{
              __html: projectAdditionalInfo,
            }}
          ></DescriptionContainer>
        </ItemCard>
      )}
      {!!projectDataSpecsVolume.length && (
        <ItemCard title="Data size" icon={<Server />}>
          {projectDataSpecsVolume}
        </ItemCard>
      )}
      {!!projectDataSpecsSources.length && (
        <ItemCard title="Data sources" icon={<CloudDownload />}>
          <Chips>
            {projectDataSpecsSources.map((dataSource, index) => (
              <Chip key={index}>{dataSource}</Chip>
            ))}
          </Chips>
        </ItemCard>
      )}
      {!!displayProjectDataSpecsParams.length && (
        <ItemCard title="Data attributes" icon={<FileSpreadsheet />}>
          <Flexrow center_align>
            <ItemCardChildTitle width="55%">Attribute</ItemCardChildTitle>
            <ItemCardChildTitle width="45%">
              Update frequency
            </ItemCardChildTitle>
          </Flexrow>
          {displayProjectDataSpecsParams.map((dataParam, index) => (
            <Flexrow center_align key={index}>
              <ItemCardChildParameter width="55%">
                {nameTrimHandler(dataParam.name)}
              </ItemCardChildParameter>
              <ItemCardChildParameter width="45%">
                {nameTrimHandler(dataParam.frequency)}
              </ItemCardChildParameter>
            </Flexrow>
          ))}
          <ParametersButton onClick={() => handleDataParametersModal()}>
            See all parameters
          </ParametersButton>
        </ItemCard>
      )}
      {!!projectDataGeoCoverage.length && (
        <ItemCard title="Geographical coverage" icon={<GeoAlt />}>
          <Chips>
            {projectDataGeoCoverage.map((dataGeoCoverage, index) => (
              <Chip key={index}>{continentStringFormat(dataGeoCoverage)}</Chip>
            ))}
          </Chips>
        </ItemCard>
      )}
      {!!projectDataLanguage.length && (
        <ItemCard title="Language" icon={<Globe />}>
          <Chips>
            {projectDataLanguage.map((dataLanguage, index) => (
              <Chip key={index}>{dataLanguage}</Chip>
            ))}
          </Chips>
        </ItemCard>
      )}
      {checkObject() && (
        <ItemCard title="Temporal availability" icon={<HourglassBottom />}>
          <ItemCardChildList>
            {checkProjectDataAvailability('real_time') && (
              <>
                <ItemCardChildListItem>Real time Data</ItemCardChildListItem>
                <ItemCardChildListItemContent>
                  {projectDataAvailability['real_time']}
                </ItemCardChildListItemContent>
              </>
            )}
            {checkProjectDataAvailability('historical') && (
              <>
                <ItemCardChildListItem>Historical</ItemCardChildListItem>
                <ItemCardChildListItemContent>
                  {projectDataAvailability['historical']}
                </ItemCardChildListItemContent>
              </>
            )}
            {checkProjectDataAvailability('forecast') && (
              <>
                <ItemCardChildListItem>Forecast Data</ItemCardChildListItem>
                <ItemCardChildListItemContent>
                  {projectDataAvailability['forecast']}
                </ItemCardChildListItemContent>
              </>
            )}
          </ItemCardChildList>
        </ItemCard>
      )}
    </Flexcol>
  );
};

export default OverviewTab;
