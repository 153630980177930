import React, { createContext, useState } from 'react';

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [dashboard_props, setDashboardProps] = useState(() => {});

  return (
    <AppContext.Provider
      value={{
        dashboard_props,
        setDashboardProps,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
