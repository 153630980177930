import axios from 'axios';
import { createContext } from 'react';
import { v4 as uuid } from 'uuid';

import { TreeTraversor } from '../utils/TreeTraversor';
import {
  data_placement_status_ids,
  data_placement_type_ids,
} from '../iso-shared/values/global';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const lastDomainCharacter = /.*\/$/m;

const nonEmptyArrayValidator = (value) => !value.length;

let MOCK_API_DEBOUNCE = false;
let MOCK_API_URL = '';

export const mockApiDebounce = () => (MOCK_API_DEBOUNCE = true);

export const mockApiUrl = (url) => (MOCK_API_URL = url);

export const DataProductContext = createContext(null);

const deepCopy = (v) => {
  if (v) {
    return JSON.parse(JSON.stringify(v));
  }
};

export class DataProductWizardHelper {
  api_trigger_promises = [];
  error_msg = '';
  sections = [];
  inputs = [];
  published = false;
  for_review = false;
  cloned = false;
  submitting = false;
  sections_map = {};
  wizard_step = 0;
  published_step = 0;
  placement_type = 'api'; // || 's3_data'
  previewing = false;
  step_1_inputs = [];
  step_2_inputs = [];
  step_3_inputs = [];
  onError = null;

  constructor(refresh, id, token, project, reset) {
    this.refresh = refresh;
    this.reset = reset;
    this.id = id;
    this.token = token;
    this.project = project;
  }

  togglePreview = () => {
    this.previewing = !this.previewing;
    this.refresh();
  };

  setStepAccessed = async (step) => {
    if (this.wizard_step >= step) {
      return;
    }
    this.wizard_step = step;
    this.refresh();
    const token = Cookies.get('jwt');

    await axios.put(
      `/api/data-placements/editable/${this.id}`,
      {
        wizard_step: step,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  };

  onChange() {
    if (this.published && !this.cloned) {
      this.cloned = true;
      this.refresh();
    }
  }

  makeInput({ init, name, required, apiTrigger, getCustomError }) {
    const _this = this;

    class Input {
      value = init;
      saved_value = init;
      has_error = false;
      edited = false;

      constructor() {
        if (apiTrigger) {
          apiTrigger.section.inputs.push(this);
        }
      }

      reset = () => (this.value = init);

      getApiTrigger = () => apiTrigger;

      setEditing = () => {
        this.saved_value = deepCopy(this.value);
      };

      cancelChanges = () => {
        this.value = this.saved_value;
        this.edited = false;
        this.checkErrors();
      };

      set = (v) => {
        this.value = v;
        this.trigger();
      };

      getName = () => name;

      checkErrors = () => {
        this.has_error =
          (required && !this.value) ||
          (getCustomError && getCustomError(this.value));
        return this.has_error;
      };

      trigger = () => {
        this.checkErrors();
        if (!apiTrigger.section.editing) {
          apiTrigger.trigger();
        } else {
          this.edited = true;
        }
        _this.refresh();
      };
    }
    const out = new Input();
    this.inputs.push(out);
    return out;
  }

  makeSection() {
    const _this = this;
    class Section {
      editing = false;
      saving_api_triggers_num = 0;
      saving = false;
      inputs = [];
      edited() {
        return !!this.inputs.find((e) => e.edited);
      }
      done() {
        this.saving_api_triggers_num--;
        this.saving = !!this.saving_api_triggers_num;
        _this.refresh();
      }
      trigger() {
        this.saving_api_triggers_num++;
        this.saving = true;
        // apiTrigger.trigger(this);
        _this.refresh();
      }
      setEditing() {
        this.editing = true;
        this.inputs.forEach((e) => e.setEditing());
        _this.refresh();
      }
      cancelChanges = () => {
        this.inputs.forEach((e) => e.cancelChanges());
        this.editing = false;
        _this.refresh();
      };
      saveChanges = () => {
        this.editing = false;
        const api_triggers = new Set();
        this.inputs.forEach((e) => {
          if (e.edited) {
            e.edited = false;
            api_triggers.add(e.getApiTrigger());
          }
        });
        api_triggers.forEach((e) => e.fire());
      };
    }
    const section = new Section();
    this.sections.push(section);
    return section;
  }

  makeApiTrigger(section_name, poster) {
    const _this = this;
    class Trigger {
      section = null;
      timeout = -1;
      constructor() {
        if (!_this.sections_map[section_name]) {
          _this.sections_map[section_name] = _this.makeSection();
        }
        this.section = _this.sections_map[section_name];
      }

      fire = async () => {
        this.section.trigger();
        try {
          await poster();
          _this.onChange();
        } catch (error) {
          console.error(error);
          _this.onError && _this.onError(error);
          // message.error('An error occured, some changes are not saved');
        }
        this.section.done();
      };

      trigger = () => {
        if (MOCK_API_DEBOUNCE) {
          _this.api_trigger_promises.push(this.fire());
          return;
        }
        if (this.timeout == -1) {
          this.section.trigger();
        } else {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(async () => {
          this.timeout = -1;
          try {
            // _this.updateErrors();
            await poster();
            _this.onChange();
          } catch (error) {
            console.error(error);
            _this.onError && _this.onError(error);
            // message.error('An error occured, some changes are not saved');
          }
          this.section.done();
        }, 600);
      };
    }

    return new Trigger();
  }

  // -------

  thumbApiTrigger = this.makeApiTrigger('details', async () => {
    const data = this.thumbnail.value.data;
    delete this.thumbnail.value.data;
    const token = Cookies.get('jwt');

    await axios.post(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}/thumbnail`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  thumbnail = this.makeInput({
    init: {},
    apiTrigger: this.thumbApiTrigger,
  });

  detailsApiTrigger = this.makeApiTrigger('details', async () => {
    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      {
        name: this.name.value,
        category_id: this.category.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  name = this.makeInput({
    init: '',
    name: 'Project name',
    required: true,
    apiTrigger: this.detailsApiTrigger,
  });

  category = this.makeInput({
    init: null,
    name: 'Project category',
    required: true,
    apiTrigger: this.detailsApiTrigger,
  });

  // -------

  apiDetailsApiTrigger = this.makeApiTrigger('apiDetails', async () => {
    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}/proxy`,
      {
        domain: this.domain.value,
        api_documentation_link: this.api_documentation_link.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  domain = this.makeInput({
    init: '',
    name: 'Api domain',
    required: true,
    apiTrigger: this.apiDetailsApiTrigger,
    getCustomError: (value) => !value.match(lastDomainCharacter),
  });

  api_documentation_link = this.makeInput({
    init: '',
    name: 'Api documentation link',
    required: true,
    apiTrigger: this.apiDetailsApiTrigger,
  });

  // ------

  apiAuthenticationTrigger = this.makeApiTrigger(
    'apiAuthentication',
    async () => {
      const token = Cookies.get('jwt');

      await axios.put(
        `${MOCK_API_URL}/api/data-placements/editable/${this.id}/proxy`,
        {
          auth_method: this.auth_method.value,
          api_key: this.api_key.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  api_key = this.makeInput({
    init: null,
    name: 'Api key',
    apiTrigger: this.apiAuthenticationTrigger,
  });

  auth_method = this.makeInput({
    init: null,
    name: 'Authentication method',
    getCustomError: (value) => this.api_key.value != null && !value,
    apiTrigger: this.apiAuthenticationTrigger,
  });

  // ------

  endpointsApiTrigger = this.makeApiTrigger('endpoints', async () => {
    const endpoints = this.endpoints.value?.map((d) => ({
      http_method: d.http_method,
      description: d.description,
      sample_data: d.sample_data,
      endpoint: d.endpoint,
      applied_tags: d.applied_tags,
      sample_request_query: d.sample_request_query,
      sample_request_body: d.sample_request_body,
    }));

    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}/proxy/endpoints`,
      endpoints,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  // endpointsSection = this.makeSection(this.endpointsApiTrigger);

  endpoints = this.makeInput({
    init: [],
    name: 'Endpoints',
    apiTrigger: this.endpointsApiTrigger,
    getCustomError: (value) => {
      const missing_entry = value.find((o) => !o.endpoint);
      return !!missing_entry;
    },
  });

  // ------

  contactApiTrigger = this.makeApiTrigger('contact', async () => {
    const token = Cookies.get('jwt');

    await axios.post(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}/proxy/contact-details`,
      {
        contact_name: this.contactName.value,
        contact_email: this.contactEmail.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  contactName = this.makeInput({
    init: '',
    apiTrigger: this.contactApiTrigger,
  });

  contactEmail = this.makeInput({
    init: '',
    apiTrigger: this.contactApiTrigger,
  });

  // ------

  packagesApiTrigger = this.makeApiTrigger('packages', async () => {
    const packages = this.packages.value.map((e) => {
      const { form_id, ...props } = e;
      return { ...props };
    });

    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}/pricing-bundles`,
      packages,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  packages = this.makeInput({
    init: [],
    name: 'Price',
    required: true,
    apiTrigger: this.packagesApiTrigger,
    getCustomError: (value) => {
      if (!value.length) {
        return true;
      }
      if (value[0].price <= 0) {
        return true;
      }
      if (value[0].charge_type == 'per_request') {
        const missing = value.find((e) => !e.price);
        return !!missing;
      }
      if (value[0].charge_type == 'per_month') {
        const missing = value.find((e) => !e.price || !e.name);
        return !!missing;
      }
    },
  });

  // ------

  hidePricingApiTrigger = this.makeApiTrigger('packages', async () => {
    const token = Cookies.get('jwt');
    axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      {
        hide_pricing: this.hide_pricing.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  hide_pricing = this.makeInput({
    init: false,
    apiTrigger: this.hidePricingApiTrigger,
  });

  // ------

  // License

  licenseTrigger = this.makeApiTrigger('license', async () => {
    const token = Cookies.get('jwt');
    axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      {
        license_type: this.license_type.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  license_type = this.makeInput({
    init: '',
    name: 'License',
    required: true,
    apiTrigger: this.licenseTrigger,
  });

  // ------

  // s3 contact details

  contactS3Trigger = this.makeApiTrigger('contactS3', async () => {
    const token = Cookies.get('jwt');
    await axios.post(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}/s3/contact-details`,
      {
        contact_name: this.contactS3Name.value,
        contact_email: this.contactS3Email.value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  contactS3Name = this.makeInput({
    init: '',
    apiTrigger: this.contactS3Trigger,
  });

  contactS3Email = this.makeInput({
    init: '',
    apiTrigger: this.contactS3Trigger,
  });

  // ------

  // data specs

  dataSpecsApiTrigger = this.makeApiTrigger('dataSpecs', async () => {
    const data_specs = {
      sources: this.data_specs_sources.value,
      data_volume: this.data_specs_data_volume.value,
      availability: this.data_specs_availability.value,
      geo_coverage: this.data_specs_geo_coverage.value,
      language: this.data_specs_language.value,
      params: this.data_specs_params.value.map((e) => ({
        key: e.key,
        name: e.name,
        type: e.type,
        description: e.description,
        unit_precision: e.unit_precision,
        example: e.example,
      })),
    };
    const token = Cookies.get('jwt');
    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      { data_specs },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  data_specs_sources = this.makeInput({
    init: [],
    apiTrigger: this.dataSpecsApiTrigger,
    getCustomError: nonEmptyArrayValidator,
    name: 'Data sources',
  });

  data_specs_data_volume = this.makeInput({
    init: '',
    apiTrigger: this.dataSpecsApiTrigger,
    name: 'Data volume',
    required: true,
  });

  data_specs_availability = this.makeInput({
    init: {},
    apiTrigger: this.dataSpecsApiTrigger,
  });

  data_specs_geo_coverage = this.makeInput({
    init: [],
    apiTrigger: this.dataSpecsApiTrigger,
    getCustomError: nonEmptyArrayValidator,
    name: 'Geo coverage',
  });

  data_specs_language = this.makeInput({
    init: [],
    apiTrigger: this.dataSpecsApiTrigger,
    getCustomError: nonEmptyArrayValidator,
    name: 'Language',
  });

  data_specs_params = this.makeInput({
    init: [],
    apiTrigger: this.dataSpecsApiTrigger,
  });

  // ------

  // attributes

  dataAttributesApiTrigger = this.makeApiTrigger('dataAttributes', async () => {
    const attributes = this.attributes.value;
    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      { attributes },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  attributes = this.makeInput({
    init: [],
    apiTrigger: this.dataAttributesApiTrigger,
    getCustomError: nonEmptyArrayValidator,
    name: 'Data attributes',
  });

  // ------

  // capture frequency

  captureFrequencyApiTrigger = this.makeApiTrigger(
    'captureFrequency',
    async () => {
      const capture_frequency = this.capture_frequency.value;
      const token = Cookies.get('jwt');

      await axios.put(
        `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
        { capture_frequency },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  capture_frequency = this.makeInput({
    init: [],
    apiTrigger: this.captureFrequencyApiTrigger,
    getCustomError: (value) => {
      let has_error = false;
      value?.map((val) => {
        if (!val.key || !val.value) {
          has_error = true;
        }
      });
      return has_error;
    },
    name: 'Capturing frequency',
  });

  // ------

  // transmission frequency

  transmissionFrequencyApiTrigger = this.makeApiTrigger(
    'transmissionFrequency',
    async () => {
      const transmission_frequency = this.transmission_frequency.value;
      const token = Cookies.get('jwt');

      await axios.put(
        `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
        { transmission_frequency },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  transmission_frequency = this.makeInput({
    init: [],
    apiTrigger: this.transmissionFrequencyApiTrigger,
    getCustomError: (value) => {
      let has_error = false;
      value?.map((val) => {
        if (!val.key || !val.value) {
          has_error = true;
        }
      });
      return has_error;
    },
    name: 'Transmission frequency',
  });

  // ------

  // pricing model

  pricingModelApiTrigger = this.makeApiTrigger('dataDelivery', async () => {
    const body = {
      pricing_model: this.pricing_model.value,
    };
    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  pricing_model = this.makeInput({
    init: '',
    apiTrigger: this.pricingModelApiTrigger,
  });

  // ------

  // general description

  generalDescriptionApiTrigger = this.makeApiTrigger(
    'dataDelivery',
    async () => {
      const body = {
        general_description: this.general_description.value,
      };
      const token = Cookies.get('jwt');

      await axios.put(
        `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  general_description = this.makeInput({
    init: '',
    apiTrigger: this.generalDescriptionApiTrigger,
    required: true,
    name: 'Description',
  });

  // ------

  // delivery specs

  deliverySpecsApiTrigger = this.makeApiTrigger('dataDelivery', async () => {
    const delivery_specs = {
      format: this.delivery_specs_format.value,
    };
    const token = Cookies.get('jwt');

    await axios.put(
      `${MOCK_API_URL}/api/data-placements/editable/${this.id}`,
      { delivery_specs },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  delivery_specs_format = this.makeInput({
    init: [],
    apiTrigger: this.deliverySpecsApiTrigger,
    getCustomError: nonEmptyArrayValidator,
    name: 'Delivery format',
  });

  // ------

  // sample files

  sampleFilesApiTrigger = this.makeApiTrigger('dataDelivery', async () => {
    const sample_files = this.delivery_specs_sample_files.value;
    const actions = [];
    const token = Cookies.get('jwt');

    sample_files.forEach((e) => {
      if (e.form_data && !e.deleted) {
        const form_data = e.form_data;
        delete e.form_data;
        actions.push(async () => {
          const res = await axios.post(
            `${MOCK_API_URL}/api/data-placements/editable/${this.id}/sample-data`,
            form_data,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
          delete e.uploading;
          e.id = res.data.id;
          e.file_id = res.data.file_id;
          this.refresh();
        });
      }
      if (e.deleted && !e.form_data) {
        actions.push(async () => {
          await axios.delete(
            `${MOCK_API_URL}/api/data-placements/editable/${this.id}/sample-data?file_id=${e.file_id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        });
      }
      if (e.edited && !e.deleted) {
        delete e.edited;
        actions.push(async () => {
          await axios.put(
            `${MOCK_API_URL}/api/data-placements/editable/${this.id}/sample-data-description?file_id=${e.file_id}`,
            {
              description: e.description,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        });
      }
    });
    this.delivery_specs_sample_files.value = sample_files.filter(
      (e) => !e.deleted,
    );
    for (let i = 0; i < actions.length; i++) {
      await actions[i]();
    }
  });

  delivery_specs_sample_files = this.makeInput({
    init: [],
    apiTrigger: this.sampleFilesApiTrigger,
    name: 'Data Sample',
    required: true,
    getCustomError: nonEmptyArrayValidator,
  });

  // ------

  fromProject(project) {
    const history = useHistory();
    const isS3 = history.location.state && history.location.state.key === 's3';

    const { placement, limits, contact_details, proxy, sample_data } = project;

    this.placement_type = isS3
      ? 's3_data'
      : placement.type_id == data_placement_type_ids.api_link
      ? 'api'
      : 's3_data';

    this.wizard_step = placement.wizard_step;
    this.published_step = placement.published_step;

    this.id = placement.id;
    this.project = project;

    this.error_msg = placement.error_msg;

    this.thumbnail.value = {
      url: placement.image_url,
    };

    this.name.value = placement.name;
    this.category.value = placement.category_id;
    this.hide_pricing.value = placement.hide_pricing;
    this.license_type.value = placement.license_type;

    this.delivery_specs_sample_files.value = sample_data;

    const attributes = placement.attributes || [];
    this.attributes.value = attributes || [];

    const capture_frequency = placement.capture_frequency || [];
    this.capture_frequency.value = capture_frequency || [];

    const transmission_frequency = placement.transmission_frequency || [];
    this.transmission_frequency.value = transmission_frequency || [];

    const data_specs = placement.data_specs || {};
    this.data_specs_sources.value = data_specs.sources || [];
    this.data_specs_data_volume.value = data_specs.data_volume || '';
    this.data_specs_availability.value = data_specs.availability || {};
    this.data_specs_geo_coverage.value = data_specs.geo_coverage || [];
    this.data_specs_language.value = data_specs.language || [];

    if (!data_specs.params || !data_specs.params.length) {
      data_specs.params = [];
    }

    this.data_specs_params.value =
      data_specs.params.map((e) => ({
        id: uuid(),
        key: e.key,
        name: e.name,
        type: e.type,
        description: e.description,
        unit_precision: e.unit_precision,
        example: e.example,
      })) || [];

    const delivery_specs = placement.delivery_specs || {};
    this.delivery_specs_format.value = delivery_specs.format || [];

    this.pricing_model.value = placement.pricing_model;
    this.general_description.value = placement.general_description;

    if (proxy && !isS3) {
      this.domain.value = proxy.domain;
      this.api_documentation_link.value = proxy.api_documentation_link;
      this.auth_method.value = proxy.auth_method;
      this.api_key.value = proxy.api_key;
      let endpoints = proxy.platform_resource_proxy_api_endpoints.map((e) => {
        e.form_id = uuid();
        delete e.id;
        e.show = false;
        let can_apply_tags = false;
        try {
          const sample_json = JSON.parse(e.sample_data);
          if (TreeTraversor.canParse(sample_json)) {
            can_apply_tags = true;
          }
        } catch (error) {
          // do nothing
        }
        e.can_apply_tags = can_apply_tags;
        return e;
      });
      this.endpoints.value = [
        ...endpoints.filter((e) => e.in_origin),
        ...endpoints.filter((e) => !e.in_origin),
      ];
    }

    this.packages.value = limits.map((e) => ({
      ...e.json_payload,
      form_id: uuid(),
    }));
    if (contact_details) {
      this.contactName.value = contact_details.contact_name;
      this.contactEmail.value = contact_details.contact_email;
      this.contactS3Name.value = contact_details.contact_name;
      this.contactS3Email.value = contact_details.contact_email;
    }

    this.cloned = !!project.placement.clone_id;

    if (
      this.cloned ||
      placement.status_id == data_placement_status_ids.active
    ) {
      this.published = true;
    }

    if (placement.status_id == data_placement_status_ids.pending) {
      this.for_review = true;
    }

    this.inputs.forEach((e) => e.checkErrors());
    // this.updateErrors();
    this.step_1_inputs = [this.name, this.category];

    this.step_2_inputs = [
      this.data_specs_sources,
      this.data_specs_geo_coverage,
      this.data_specs_language,
      this.general_description,
      this.delivery_specs_format,
      this.delivery_specs_sample_files,
      this.data_specs_data_volume,
      this.attributes,
      this.capture_frequency,
      this.transmission_frequency,
    ];

    if (proxy && !isS3) {
      this.step_3_inputs = [
        this.domain,
        this.api_documentation_link,
        this.api_key,
        this.auth_method,
        this.endpoints,
        this.packages,
        this.license_type,
      ];
    }
  }
}

export default { DataProductWizardHelper, mockApiDebounce, mockApiUrl };
