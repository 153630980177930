import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

import SetUpAccount from '../components/signup/SetUpAccount';
import useForm from '../hooks/useFormStore';

const default_data = {
  user_id: '',
  first_name: '',
  last_name: '',
  business_role: '',
};

export const VerifyToken = () => {
  const user_id = Cookies.get('user_id');
  const user_token = Cookies.get('jwt');
  const user_email = Cookies.get('email');
  const company_name = Cookies.get('companyName');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors, form_data, setForm] = useForm({
    ...default_data,
    company_name,
    user_id,
  });
  const { verification_token } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const invitation_id = queryParams.get('invitationId');
  const props = {
    step_id: 'set_up_account',
    errors,
    setErrors,
    form_data,
    setForm,
    disabled,
    verification_token,
    invitation_id,
    user_email,
  };

  useEffect(() => {
    if (invitation_id) {
      setDisabled(true);
      const fetchUserInfo = async () => {
        await axios
          .get(`${process.env.RAZZLE_ACCOUNT_URL}/invitations/${invitation_id}`)
          .then((res) => {
            setForm({
              ...form_data,
              company_name: res.data.organization.name,
              company_address: res.data.organization.address,
              country: res.data.organization.country,
              vat: res.data.organization.vat,
              industry: res.data.organization.industry,
              description: res.data.organization.description,
            });
          });
        setLoading(false);
      };
      fetchUserInfo();
    }

    if (verification_token) {
      const verifyUser = async () => {
        try {
          await axios
            .post(
              `${process.env.RAZZLE_ACCOUNT_URL}/auth/verify-email/${verification_token}`,
            )
            .then((res) => {
              if (res.status === 201) {
                const deploymentDomain =
                  process.env.NODE_ENV !== 'development'
                    ? '.mobito.io'
                    : 'localhost';
                Cookies.set('jwt', res.data.jwt, {
                  domain: deploymentDomain,
                });
                Cookies.set('user_id', res.data.user.id, {
                  domain: deploymentDomain,
                });
              }
            });
        } catch (error) {
          console.error(error);
        }
      };

      verifyUser();
    }
  }, [invitation_id]);

  useEffect(() => {
    if (!user_id || !user_token || !user_email || !verification_token) {
      history.push('/login');
    }
    if (verification_token) {
      let url = `/verify-email/${verification_token}`;
      if (invitation_id) url = `${url}?invitationId=${invitation_id}`;
      history.push(url);
    }
    if (!invitation_id) {
      setLoading(false);
    }
  }, []);

  return loading ? null : <SetUpAccount {...props} />;
};
