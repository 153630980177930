import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import React, { useState } from 'react';
import { SText } from '../components/styled-text/text';

const FreeSummaryModule = ({ freeValues }) => {
  return (
    <>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Pricing
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            Free
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 black bold>
            Request limits
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold></SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per minute
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {freeValues && freeValues.limit_minute ? freeValues.limit_minute : 'N/A'}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per hour
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {freeValues && freeValues.limit_hour ? freeValues.limit_hour : 'N/A'}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per day
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {freeValues && freeValues.limit_day ? freeValues.limit_day : 'N/A'}
          </SText>
        </Col>
      </Row>
    </>
  );
};

export default FreeSummaryModule;
