import styled from 'styled-components';
import { Typography, Divider } from 'antd';

import { Flexcol, Flexrow } from '../../../../components/Flexbox';
import { verb_chips } from './verb_chips';

const { Text } = Typography;

const Wrap = styled(Flexcol)`
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid rgb(100, 115, 127);
  align-self: stretch;
  & + & {
    margin-top: 8px;
  }
`;

const nameTrimHandler = (name) => {
  let maxLength = 70;
  if (name?.length > maxLength) {
    return name.substring(0, maxLength) + '...';
  } else {
    return name;
  }
};

export const EndpointView = ({ endpoint, onViewDetails }) => {
  const verb_chip = verb_chips[endpoint.http_method];
  return (
    <Wrap>
      <Flexrow left="16px" right="16px" center_align bottom="8px">
        {verb_chip}
        <Flexrow left="12px" />
        <Text>{nameTrimHandler(endpoint.endpoint)}</Text>
        <Flexrow grow />
        <Text
          onClick={onViewDetails}
          type="secondary"
          style={{ cursor: 'pointer', color: '#344ef3' }}
        >
          View samples
        </Text>
      </Flexrow>
      <Divider style={{ margin: 'unset' }} />
      <Flexrow left="16px" top="8px">
        <Text type="secondary">{endpoint.description}</Text>
      </Flexrow>
    </Wrap>
  );
};
