import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Typography } from 'antd';
import styled from 'styled-components';

import { Files } from '@styled-icons/bootstrap/Files';
import { Award } from '@styled-icons/bootstrap/Award';
import { Folder } from '@styled-icons/bootstrap/Folder';
import { FileEarmark } from '@styled-icons/bootstrap/FileEarmark';
import { Download } from '@styled-icons/bootstrap/Download';
import { NewTab } from '@styled-icons/icomoon/NewTab';

import { Flexcol, Flexrow } from '../../../../components/Flexbox';
import ItemCard from '../../../../components/listing-components/ItemCard';
import { strings } from '../../values/strings';
import TextModal from '../../../../components/listing-components/TextModal';
import { getSampleDataURL } from '../../../../iso-shared/utils/getSampleDataUrl';
import { scrollStyles } from '../../../../styles/scrollStyles';
import { DataProductContext } from '../../../../helpers/DataProductWizardHelper';

const { Text } = Typography;

const ViewDocumentationButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  background: #fff !important;
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
`;

const LicenseContainer = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  ${scrollStyles}
`;

const getScreenWidthHandler = () => {
  let width;
  if (screen.width <= 768) {
    return (width = '95vw');
  } else if (screen.width > 768 && screen.width <= 2560) {
    return (width = '75vw');
  } else if (screen.width > 2560) {
    return (width = '50vw');
  } else {
    return (width = '75vw');
  }
};

const SampleFilesAndDocumentation = (props) => {
  const helper = useContext(DataProductContext);
  const projectSampleFiles = helper.delivery_specs_sample_files.value;
  const projectLicense = helper.license_type.value;
  const endpoints = helper.endpoints.value;
  const show_light = !helper.previewing && helper.published_step == 2;
  const { url_id } = useParams();
  const { modal, isS3 } = props;

  const handleOnViewDocumentation = () => {
    window.open(
      `/data-product/${
        url_id
          ? url_id
          : modal
          ? helper.name.value.split(' ').join('-').toLowerCase()
          : helper.id
      }/documentation`,
      '_blank',
    );
  };

  const handleTextModal = () => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <TextModal
          name={helper.name.value}
          title="Licence"
          text={projectLicense}
        />
      ),
      okText: 'Close',
      closable: true,
      centered: true,
      style: { paddingBottom: 0 },
      width: getScreenWidthHandler(),
    });
  };

  return (
    <Flexcol>
      {!show_light && helper.placement_type == 'api' && (
        <ItemCard title="Documentation" icon={<Files />}>
          {strings.sample_files_and_documentation_instructions}
          <ViewDocumentationButton
            disabled={!endpoints.length}
            onClick={handleOnViewDocumentation}
          >
            View documentation
            <NewTab style={{ height: '18px', marginLeft: '16px' }} />
          </ViewDocumentationButton>
        </ItemCard>
      )}
      {!show_light &&
        projectLicense &&
        !isS3 &&
        !helper.placement_type === 's3_data' && (
          <ItemCard
            title="Licence"
            icon={<Award />}
            openTextModal={handleTextModal}
          >
            <LicenseContainer
              dangerouslySetInnerHTML={{
                __html: projectLicense,
              }}
            ></LicenseContainer>
          </ItemCard>
        )}
      {!!projectSampleFiles.length && (
        <ItemCard title="Sample files" icon={<Folder />}>
          {projectSampleFiles.map((sampleFile) => (
            <Flexcol
              bottom="20px"
              style={{ borderBottom: '1px solid #dfe5eb', paddingBottom: 12 }}
              key={sampleFile.id}
            >
              <Flexrow center_align>
                <FileEarmark style={{ height: '20px' }} />
                <Text
                  style={{
                    color: '#182a33',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    marginLeft: '12px',
                    marginBottom: 0,
                  }}
                >
                  {sampleFile.file_name}
                </Text>
                <Flexrow grow />
                <a href={getSampleDataURL(sampleFile.file_id)} target="_blank">
                  <Download
                    style={{
                      height: '20px',
                      color: '#344ef3',
                      cursor: 'pointer',
                    }}
                  />
                </a>
              </Flexrow>
              {sampleFile.description && (
                <Flexrow left="4px" top="8px">
                  <Text style={{ fontSize: 14 }} type="secondary">
                    {sampleFile.description}
                  </Text>
                </Flexrow>
              )}
            </Flexcol>
          ))}
        </ItemCard>
      )}
    </Flexcol>
  );
};

export default SampleFilesAndDocumentation;
