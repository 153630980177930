export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatDate = (date) => {
  let current_datetime = new Date(date);
  let formatted_date =
    current_datetime.getDate() +
    ' ' +
    months[current_datetime.getMonth()] +
    ' ' +
    current_datetime.getFullYear() +
    ', ' +
    current_datetime.getHours() +
    ':' +
    current_datetime.getMinutes();
  return formatted_date;
};
