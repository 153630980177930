export const draftItemContent = (threadItems) => {
  return threadItems
    .filter((threadItem) => threadItem.status === 'DRAFT')
    .map((threadItem) => threadItem?.content);
};

export const draftItemId = (threadItems) => {
  return threadItems
    .filter((threadItem) => threadItem.status === 'DRAFT')
    .map((threadItem) => threadItem?.id);
};
