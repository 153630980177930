import Header from "../../Header";
// import HeaderWizard from "./HeaderWizard";
import LayoutRow from "../../LayoutRow";
import { Divider } from "antd";
import { Flexcol, Flexrow } from "../../Flexbox";
import HeaderWizard from "./HeaderWizard";

const DashboardLayoutWizard = (props) => (
  <>
    <LayoutRow
      background={"#fff"}
      withShadow={props.withShadow}
      withBorder={props.withBorder}
    >
      <Header withNav={props.withNav} stepsFor="CREATE_DATA_REQUEST" />
    </LayoutRow>
    <div style={{ backgroundColor: "#fff" }}>
      <Divider style={{ margin: "0 0 8px 0" }} />
    </div>
    <LayoutRow background={"#fff"} sticky>
      <HeaderWizard sticky />
    </LayoutRow>
    {props.children}
    <style>{`body { background-color: ${
      props.bodyColor && props.bodyColor
    };}`}</style>
  </>
);

export default DashboardLayoutWizard;
