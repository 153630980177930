import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Typography, Button, Modal } from 'antd';
import { BookmarkFill } from '@styled-icons/bootstrap/BookmarkFill';

import { PricingTab } from './tabs/PricingTab/index';
import OverviewTab from './tabs/OverviewTab/index';
import { IntegrationDetailsTab } from './tabs/IntegrationDetailsTab';

import { Flexcol, Flexrow } from '../../components/Flexbox';
import demand_listing__wall_img from '../../images/DemandListing_Background.webp';
import { AccountContext } from '../../hooks/AccountContext';
import { DataRequestContext } from '../../helpers/DataRequestWizardHelper';
import useCategories from '../../hooks/useCategories';
import { DeclareDataRequestInterestModal } from './DeclareDataRequestInterestModal';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Notification from '../../components/Notification';
import axios from 'axios';
import { LoginRedirector } from './../../services/LoginRedirector';
import ShareListing from '../../components/listing-components/ShareListing';

const { Title, Text } = Typography;

const TabContent = styled(Flexcol)`
  width: 950px;
  padding-top: 40px;
`;

const BookmarkIcon = styled(BookmarkFill)`
  color: white;
  height: 20px;
`;

const Wall = styled(Flexcol)`
  position: relative;
  height: 312px;
  min-height: 312px;
  background-image: url(${demand_listing__wall_img});
  background-size: cover;
`;

const WallRow = styled(Flexrow)`
  margin-top: 30px;
  margin-left: 120px;
  margin-right: 120px;
`;

const AccessBox = styled(Flexcol)`
  background: white;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.06);
  padding: 20px;
`;

const TabsWrap = styled(Flexrow)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
`;

const TabsRow = styled(Flexrow)`
  width: 950px;
  justify-content: space-between;
`;

const TabItem = styled(Flexrow)`
  flex: 1;
  font-weight: bold;
  min-width: max-content;
  padding: 16px 32px;
  border-radius: 4px 4px 0px 0px;
  color: white;
  font-size: 15px;
  justify-content: center;
  transition: background-color 75ms, color 75ms;
  cursor: pointer;
  background-color: transparent;
  ${(p) =>
    p.selected &&
    css`
      color: rgb(0, 85, 153);
      background-color: #f9faff;
    `}
`;

const Content = styled(Flexrow)`
  flex: 1;
  justify-content: center;
  background-color: #f9faff;
`;

const tab_views = {
  overview: OverviewTab,
  details: IntegrationDetailsTab,
  pricing: PricingTab,
};

export const ListingContent = () => {
  const helper = useContext(DataRequestContext);

  const { categories } = useCategories();

  const [declared_interest, setDeclaredInterest] = useState(false);
  const interest_message = useMemo(() => ({ message: '' }));

  const category = categories.find((e) => e.id === helper.category.value);

  const [tab, setTab] = useState('overview');

  const { account, token, isComplete } = useContext(AccountContext) || {};

  const is_logged_in = account || null;

  const account_set = account && isComplete.personal && isComplete.company;

  const TabView = tab_views[tab] || (() => null);

  const history = useHistory();

  useEffect(() => {
    const initial = document.documentElement.style.overflowY;
    document.documentElement.style.overflowY = 'scroll';
    return () => {
      document.documentElement.style.overflowY = initial;
    };
  }, []);

  useEffect(() => {
    if (window.location.hash == '#declare-availability') {
      if (!token) {
        LoginRedirector.toLogin(history);
      }
      if (!is_logged_in) {
        return;
      }
      onDeclareInterestModal();
    }
  }, [is_logged_in]);

  useEffect(() => {
    if (!is_logged_in) {
      return;
    }
    const fetch = async () => {
      const token = Cookies.get('jwt');
      const res = await axios.get(
        `/api/get-request-interest?data_request_id=${helper.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setDeclaredInterest(res.data);
    };
    fetch();
  }, [is_logged_in]);

  const onDeclareInterest = async () => {
    const token = Cookies.get('jwt');
    try {
      await axios.post(
        `/api/create-request-interest`,
        {
          data_request_id: helper.id,
          message: interest_message.message,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success(<Notification type="success" text="Request sent" />);
      setDeclaredInterest(true);
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Could not send request, please try again later"
        />,
      );
    }
  };

  const goToProfileHandler = () => {
    if (!isComplete.personal) {
      history.push('/account');
    } else if (!isComplete.company) {
      history.push({ pathname: '/account', state: { key: 'company' } });
    }
  };

  const onDeclareInterestModal = () => {
    if (is_logged_in) {
      if (!account_set) {
        goToProfileHandler();
      } else {
        interest_message.message = '';
        Modal.confirm({
          title: 'Declare interest',
          icon: null,
          content: (
            <DeclareDataRequestInterestModal
              onMessage={(v) => (interest_message.message = v)}
            />
          ),
          okText: 'Send',
          cancelText: 'Cancel',
          width: 800,
          closable: true,
          centered: true,
          bodyStyle: { height: '400px' },
          onOk: onDeclareInterest,
        });
      }
    } else {
      window.location.hash = '#declare-availability';
      LoginRedirector.toLogin(history);
    }
  };

  const getTabProps = (name) => ({
    selected: name == tab,
    onClick: () => setTab(name),
  });

  return (
    <>
      <Wall>
        <WallRow>
          <Flexcol left="30px">
            <Flexrow center_align>
              <Text style={{ color: 'white' }}>DATA REQUEST</Text>
              <Flexrow left="12px" />
            </Flexrow>
            <Title style={{ color: 'white', marginTop: 8 }} level={2}>
              {helper.title.value}
            </Title>
            <Flexrow center_align>
              <BookmarkIcon size={14} />
              <Title
                style={{ color: 'white', marginBottom: 'unset', marginLeft: 8 }}
                level={5}
              >
                {category && category.name}
              </Title>
            </Flexrow>
          </Flexcol>
          <Flexrow grow />
          {!helper.previewing && !declared_interest && (
            <AccessBox>
              <Text
                style={{ textAlign: 'center', fontSize: '12px' }}
                type="secondary"
              >
                Do you have data relevant to this request?
              </Text>
              <Flexrow top="8px" />
              <Button
                onClick={onDeclareInterestModal}
                style={{
                  backgroundColor: '#ff7f19',
                  color: 'white',
                  fontSize: '15px',
                }}
                size="large"
                id="request-data-availability"
              >
                Declare Data Availability
              </Button>
              <ShareListing listingName={helper.title.value} req />
            </AccessBox>
          )}
          {declared_interest && (
            <AccessBox center_content>
              <Text style={{ textAlign: 'center', fontSize: '14px' }}>
                You have declared data availability <br /> for this data request
              </Text>
              <ShareListing listingName={helper.title.value} req />
            </AccessBox>
          )}
        </WallRow>
        <TabsWrap>
          <TabsRow>
            <TabItem {...getTabProps('overview')} id="request-overview-tab">
              Overview
            </TabItem>
            <TabItem
              {...getTabProps('details')}
              id="request-integration-details-tab"
            >
              Integration details
            </TabItem>
            <TabItem {...getTabProps('pricing')} id="request-pricing-tab">
              Pricing
            </TabItem>
          </TabsRow>
        </TabsWrap>
      </Wall>
      <Content center_content>
        <TabContent>
          <TabView />
        </TabContent>
      </Content>
    </>
  );
};
