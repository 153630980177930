import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { Title } from './styled-components';
import Colors from './colors';

export const Wrap = styled.div`
  width: ${(props) => (props.marginOff ? 'fit-content' : '100%')};
  color: ${Colors.lightGray};
`;

const SubsectionTitle = (props) => {
  const { tooltip, marginOff } = props;

  return (
    <Wrap marginOff={marginOff}>
      {tooltip && (
        <Tooltip
          title={tooltip}
          color="#1a337d"
          overlayInnerStyle={{
            boxShadow: '2px 4px 12px 0px #182a331f',
            fontSize: '13px',
            borderRadius: '4px',
          }}
          placement="bottom"
        >
          <InfoCircleOutlined />
        </Tooltip>
      )}
      <Title
        size={15}
        margin={
          marginOff ? '0 0 0 8px' : tooltip ? '0 0 10px 8px' : '0 0 10px 0'
        }
        weight="bold"
      >
        {props.children}
      </Title>
    </Wrap>
  );
};

export default SubsectionTitle;
