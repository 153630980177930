import React from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";

import { Flexcol } from "../Flexbox";
import key_image from "../../images/key.svg";
import FormLayout from "../FormLayout";

const { Text } = Typography;

const PasswordUpdated = () => {
  const history = useHistory();
  
  return (
    <FormLayout
      title="Password updated"
      subtitle="Your password has been successsfully updated."
      isVerification={true}
    >
      <Flexcol center_align>
        <Text
          style={{
            color: "#fff",
            fontSize: "15px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => history.push("/login")}
        >
          Return to login
        </Text>
        <img src={key_image} alt="key" style={{ marginTop: "100px" }} />
      </Flexcol>
    </FormLayout>
  );
};

export default PasswordUpdated;
