import React, { useEffect, useState } from "react";
import { LeftArrow, RightArrow, ProductContainer } from "./pricing-components";
import { Skeleton } from "antd";
import { Flexcol, Flexrow } from "../../../../components/Flexbox";
import { PackageTab } from "./PackageTab";
import { RequestTab } from "./RequestTab";
import { FreeTab } from "./FreeTab";
import ItemCard from "../../../../components/listing-components/ItemCard";
import { Payments } from "@styled-icons/material/Payments";
import { useContext } from "react";
import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";

const default_pricing_text =
  "Pricing will be defined with each individual data customer based on the specification of the data request.";

export const PricingTab = () => {
  const helper = useContext(DataProductContext);
  const packages = helper.packages.value;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [activeSkeleton, setActiveSkeleton] = useState(false);

  const show_light = !helper.previewing && helper.published_step == 2;
  const priceType =
    packages.length && !show_light ? packages[0].charge_type : null;

  useEffect(() => {
    setTimeout(() => setActiveSkeleton(false), 150);
  }, [start, end]);

  const increment = () => {
    if (packages.length > end) {
      setActiveSkeleton(true);

      setStart(start + 3);
      setEnd(end + 3);
    }
  };

  const decrement = () => {
    if (end != 3) {
      setActiveSkeleton(true);

      setStart(start - 3);
      setEnd(end - 3);
    }
  };

  return (
    <>
      {!priceType && (
        <ItemCard title="Pricing scheme" icon={<Payments />}>
          <div
            dangerouslySetInnerHTML={{
              __html: helper.pricing_model.value || default_pricing_text,
            }}
          />
        </ItemCard>
      )}
      <Flexrow>
        {priceType === "per_month" && packages && packages.length > 3 && (
          <Flexcol style={{ marginTop: "48px" }}>
            <LeftArrow onClick={decrement} />
          </Flexcol>
        )}
        <Flexcol style={{ margin: "auto", marginBottom: "26px" }}>
          {priceType === "per_month" &&
            (activeSkeleton ? (
              <Flexrow style={{ width: "600px", height: "1000px" }}>
                <Skeleton active />
              </Flexrow>
            ) : (
              <ProductContainer>
                <PackageTab start={start} end={end} />
              </ProductContainer>
            ))}

          {priceType === "per_request" && (
            <ProductContainer>
              <RequestTab />
            </ProductContainer>
          )}

          {priceType === "free" && (
            <ProductContainer>
              <FreeTab />
            </ProductContainer>
          )}
        </Flexcol>

        {priceType === "per_month" && packages && packages.length > 3 && (
          <Flexcol style={{ marginTop: "44px" }}>
            <RightArrow onClick={increment} />
          </Flexcol>
        )}

        {!priceType && (
          <ProductContainer>
            <span></span>
          </ProductContainer>
        )}
      </Flexrow>
    </>
  );
};
