import React, { useEffect, useContext, useCallback, createRef } from "react";
import styled, { keyframes } from "styled-components";
import { Col, Row } from "react-styled-flexboxgrid";

import { ESC_BUTTON_CODE } from "../../iso-shared/values/global";
import { ModalContext } from "../../hooks/ModalProvider";
import { scrollStyles } from "../../styles/scrollStyles";

const fadeIn = keyframes`from { opacity: 0; }`;

const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000000;
`;

const Dialog = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 20px auto;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20%;
  max-width: 98%;
  height: 95vh;
  overflow-y: auto;
  z-index: 999;
  ${scrollStyles}
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 15px 0 0;
  z-index: 1001;
  font-size: 30px;
  cursor: pointer;
  color: "#182429";
`;

const ContentContainer = styled.section`
  width: 100%;
  height: 100%;
`;

const Modal = () => {
  const { modalContent, handleModal, modal, setModalBottomReached } =
    useContext(ModalContext);
  const modalContainerRef = createRef();

  const handleEscKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;

      if (keyCode === ESC_BUTTON_CODE && modal) {
        handleModal();
        setModalBottomReached(false);
      }
    },
    [modal]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleEscKeyPress);
    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [handleEscKeyPress]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 200 < e.target.clientHeight;
    if (bottom) {
      setModalBottomReached(true);
    }
  };

  useEffect(() => {
    let noScroll;

    if (modalContainerRef.current) {
      noScroll =
        modalContainerRef.current.scrollHeight ===
          modalContainerRef.current.clientHeight ||
        modalContainerRef.current.scrollHeight -
          modalContainerRef.current.scrollTop -
          200 <
          modalContainerRef.current.clientHeight;
    }

    if (noScroll) {
      setModalBottomReached(true);
    }
  }, [modal]);

  if (modal) {
    return (
      <Overlay style={{ background: "rgba(0,0,0,0.8)" }}>
        <Row>
          <Col xs={9}>
            <Dialog>
              <Row>
                <Col xsOffset={11} xs={1}>
                  <CloseButton
                    onClick={() => {
                      handleModal();
                      setModalBottomReached(false);
                    }}
                  >
                    &times;
                  </CloseButton>
                </Col>
              </Row>
              <ContentContainer onScroll={handleScroll} ref={modalContainerRef}>
                {modalContent}
              </ContentContainer>
            </Dialog>
          </Col>
        </Row>
      </Overlay>
    );
  } else return null;
};

export default Modal;
