import { Card } from "./Card";
import { Row } from "react-styled-flexboxgrid";
import Cookies from "js-cookie";
import { fetcherWithToken } from "../../utils/fetcher";
import useSWR from "swr";
import { SButton } from "../styled-button";
import { Spin } from "antd";
import { SText } from "../styled-text/text";

export const RequestAccessCard = ({ id, onClick }) => {
  const token = Cookies.get("jwt") || "";
  const { data: consumptionProjects } = useSWR(
    token ? [`/api/consuming-by-user?placement_id=${id}`, token] : null,
    fetcherWithToken
  );

  if (!consumptionProjects && token)
    return (
      <div
        style={{
          backgroundColor: "#f9faff",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );

  const consumptionProjectString =
    consumptionProjects &&
    consumptionProjects.map((source) => source.name).join(", ");

  return (
    <>
      <Card>
        <Row>
          <SText text_24 black extra_bold>
            Interested in this listing?
          </SText>
        </Row>
        <>
          <Row
            style={{
              padding: "14px 0px",
              borderBottom: "1px solid rgb(226, 228, 229)",
            }}
          >
            {consumptionProjects && consumptionProjects.length > 0 ? (
              <SText text_12 semi medium>
                {`These data are already consumed by ${consumptionProjectString} projects`}
              </SText>
            ) : (
              <SText text_12 semi medium>
                To get access to this Marketplace listing, you need to log-in or
                create an account.
              </SText>
            )}
          </Row>
          <Row center="xs" style={{ padding: "18px 0px" }}>
            <SButton onClick={onClick}>
              <SText text_16 black bold>
                Request access
              </SText>
            </SButton>
          </Row>
        </>
      </Card>
    </>
  );
};
