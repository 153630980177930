import React, { useState } from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import { IntegrationSelect } from "../components/data-connection-wizard/wizard-style-components/integration-wizard";
import { SText } from "../components/styled-text/text";

const PackageSummaryModule = ({ module, packageValues }) => {
  const [packageObj, setPackageObj] = useState(
    packageValues[0] && packageValues[0].json_payload
      ? packageValues[0].json_payload
      : packageValues
      ? packageValues[0]
      : null
  );

  const options = packageValues.map((packageValue) => {
    return {
      label:
        (packageValue && packageValue.name) ||
        (packageValue &&
          packageValue.json_payload &&
          packageValue.json_payload.name),
      value:
        (packageValue && packageValue.name) ||
        (packageValue &&
          packageValue.json_payload &&
          packageValue.json_payload.name),
    };
  });

  const onChangeSelect = (value) => {
    packageValues.map(async (packageValue) => {
      if (
        packageValue.json_payload &&
        packageValue.json_payload.name === value
      ) {
        await setPackageObj(packageValue.json_payload);
      } else if (packageValue && packageValue.name === value) {
        await setPackageObj(packageValue);
      }
    });
  };

  return (
    <>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Package
          </SText>
        </Col>
        <Col>
          <IntegrationSelect
            name="project_category_id"
            placeholder="Select a category"
            options={options}
            onChange={onChangeSelect}
            size="large"
            defaultValue={
              packageValues[0].name || packageValues[0].json_payload.name
            }
          />
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Price
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {module.project.placement.hide_pricing ? (
              <p>-</p>
            ) : packageObj && packageObj.price ? (
              `${packageObj.price.toLocaleString()} euro / month`
            ) : (
              "N/A euro / month"
            )}
          </SText>
        </Col>
      </Row>
      {packageObj && packageObj.min_packages_purchased && (
        <Row style={{ padding: 10 }} between="xs">
          <Col>
            <SText text_16 semi medium>
              Minimum purchase
            </SText>
          </Col>
          <Col>
            <SText text_16 black extra_bold>
              {packageObj.min_packages_purchased} months
            </SText>
          </Col>
        </Row>
      )}
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Monthly allowance
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {packageObj && packageObj.requests
              ? packageObj.requests.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 black bold>
            Request limits
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold></SText>
        </Col>
      </Row>

      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per minute
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {packageObj && packageObj.limit_minute
              ? packageObj.limit_minute.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per hour
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {packageObj && packageObj.limit_hour
              ? packageObj.limit_hour.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
      <Row style={{ padding: 10 }} between="xs">
        <Col>
          <SText text_16 semi medium>
            Per day
          </SText>
        </Col>
        <Col>
          <SText text_16 black extra_bold>
            {packageObj && packageObj.limit_day
              ? packageObj.limit_day.toLocaleString()
              : "N/A"}
          </SText>
        </Col>
      </Row>
    </>
  );
};

export default PackageSummaryModule;
