import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsHeaderSmall,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsLink,
} from '../../styles/termsAndConditions';

const DefinitionsTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        D
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        EFINITIONS
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The terms with capital letters shall have the meaning as set out
              in this article 1, unless the context explicitly states otherwise:
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Agreement
              </TermsAndConditionsHeaderSmall>{' '}
              means an agreement entered into between Mobito and the Client that
              consists of the combination of these Terms of Use, the privacy
              policy, the cookie policy and, if applicable, any Order Form(s)
              for the provision of the Data Preparation Services by Mobito, as
              well as any amendments to them signed by each party;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Applicable Data Protection Laws
              </TermsAndConditionsHeaderSmall>{' '}
              means (a) Regulation (EU) 2016 of the European Parliament and of
              the Council from 27 April 2016 on the protection of natural
              persons with regard to the processing of personal data and on the
              free movement of such data and the repeal of Directive 95/46/EC,
              (b) the Belgian Law of July, 30th 2018 on the protection of
              natural persons with regard to the processing of personal data,
              (c) other applicable data protection and privacy laws and
              regulations applicable to the processing of personal data (in case
              laws from outside the European Economic Area apply and this has an
              impact on Mobito, the Client will notify Mobito of such laws and
              their consequences) and (d) any changes to or the replacement of
              the previous legislation, if applicable;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Authorized User(s)
              </TermsAndConditionsHeaderSmall>{' '}
              means the end-user(s) of the Platform as designated by the Client
              who has/have access to the Platform;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Client
              </TermsAndConditionsHeaderSmall>{' '}
              means (i) the Data Customer and/or the Data Provider, or (ii) the
              party making use of the platform without engaging in Data
              transactions;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Confidential Information
              </TermsAndConditionsHeaderSmall>{' '}
              means any personal data and/or know how, data, samples, technical
              or business information, and other materials, whether in
              electronic, written, oral or other form that is related to this
              Agreement (excluding the Data);
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data
              </TermsAndConditionsHeaderSmall>{' '}
              means the data made available to the Platform by the Data Provider
              or provided by the Data Provider to Mobito for the Data
              Preparation Services;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Agreement
              </TermsAndConditionsHeaderSmall>{' '}
              means (i) for the Platform Services, the agreement that will be
              concluded between the Data Provider and the Data Customer, which
              sets out the rights, obligations and restrictions, including the
              license, relating to the use of the Data by the Data Customer; or
              (ii) for the Data Preparation Services, the agreement that will be
              passed on to the Data Customer by Mobito;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Customer
              </TermsAndConditionsHeaderSmall>{' '}
              means the legal entity that is (i) authorised to use the Data
              offered by the Data Provider for use subject to the Data
              Agreement, or (ii) procuring the Data Preparation Services from
              Mobito under an Order Form pursuant to the Agreement;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Listing
              </TermsAndConditionsHeaderSmall>{' '}
              means the information made available by the Data Provider,
              including a description of the specific Data, Data samples,
              pricing, Data Agreement and associated documentation;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Preparation Services
              </TermsAndConditionsHeaderSmall>{' '}
              means the Data operations support, which includes Data
              aggregation, standardisation, transformations, derivative insight
              generation and consulting services;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Promotional Services
              </TermsAndConditionsHeaderSmall>{' '}
              means the provision of promotional activities for the Data
              Provider, such as the creation and broadcasting of marketing
              materials (e.g. interviews, brochures, etc.) or running paid
              advertisement campaigns;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Provider
              </TermsAndConditionsHeaderSmall>{' '}
              means the legal entity wishing to (i) make use of the Platform to
              provide Data for licensing to the Data Customers, subject to the
              Data Agreement, (ii) procure the relevant Data Promotional
              Services from Mobito under an Order Form pursuant to the
              Agreement, and (iii) utilise the Data exchange management tools of
              the Platform;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Data Provider Charges
              </TermsAndConditionsHeaderSmall>{' '}
              means the charges paid by the Data Provider to Mobito, if any;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Force Majeure
              </TermsAndConditionsHeaderSmall>{' '}
              means the situation in which the performance of the Agreement is
              made impossible for one of the parties, in whole or in part and
              temporarily or permanently, beyond the control of the party or
              parties. Without limitation, the following events are deemed to
              constitute Force Majeure: governmental decision; measures, act or
              omission (e.g. delay or failure to issue, or withdrawal of any
              license, permit or authorization), war, hostilities, act of the
              public enemy, riots, terrorist attacks, strike, civil commotion,
              sabotage, natural disasters, unfavorable weather conditions,
              earthquakes, fire, flood, lightening, hurricanes, explosion,
              epidemics, pandemics, quarantine restrictions, power failures,
              failures of the internet, the unavailability of servers of third
              parties, hacking, (distributed) denial of service attacks,
              viruses, delay or failure from a subcontractor or third party
              supplier due to Force Majeure as defined hereinabove;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Intellectual Property Rights
              </TermsAndConditionsHeaderSmall>{' '}
              means all copyrights and related rights, software rights, database
              rights, trade secrets, know-how, trademarks, trade names, service
              marks, domain names, patents, design rights, and other rights,
              including any kinds of developments, amendments, customizations,
              documentation, data and testing methodologies and including the
              right to register or apply for any of the foregoing;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Order Form
              </TermsAndConditionsHeaderSmall>{' '}
              means an order form, statement of work or written document
              pursuant to which the Data Customer orders Data Preparation
              Services or the Data Provider orders Data Promotional Services
              from Mobito, and which has been executed by the parties. This
              Order Form describes (i) for the Data Preparation Services, the
              scope of the Data Preparation services, the Data to be accessed
              and the Data Agreement that Mobito transfers to the Data Customer
              or (ii) for the Data Promotional Services, the scope of such Data
              Promotional Services;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Platform
              </TermsAndConditionsHeaderSmall>{' '}
              means{' '}
              <TermsAndConditionsLink href="https://mobito.io" target="_blank">
                https://mobito.io
              </TermsAndConditionsLink>{' '}
              and all related (sub)domains that are used for the provision of
              Services;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Platform Services
              </TermsAndConditionsHeaderSmall>{' '}
              means the provision of access tools in the Platform (e.g. to help
              companies evaluate, source and access external Data and
              communicate with Data Providers), the distribution of Data (e.g.
              including Data access management, Data anonymization and Data
              monetization functionalities) and the provision of maintenance
              and/or support services;
              <TermsAndConditionsEmptySpace />
              <TermsAndConditionsHeaderSmall>
                Services
              </TermsAndConditionsHeaderSmall>{' '}
              means the Platform Services, the Data Preparation Services and the
              Data Promotional Services performed by Mobito under the terms of
              this Agreement
              <TermsAndConditionsEmptySpace />
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default DefinitionsTerms;
