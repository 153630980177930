import styled from "styled-components";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Flexcol, Flexrow } from "../../../../components/Flexbox";

export const ProductCard = styled(Flexrow)`
  align-content: center;
  width: 950px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
`;

export const ProductContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 4px 12px 0px #182a330f;
`;

export const PricingColumn = styled(Flexcol)`
  width: 950px;
  border-right: 1px solid rgb(223, 229, 235);
  &:last-child {
    border-right: none;
  }
  border-bottom: 1px solid rgb(223, 229, 235);
`;

export const RequestsLimits = styled(Flexrow)`
  color: rgb(24, 42, 51);
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  width: 141px;
  font-family: poppins-semibold;
`;

export const Typography = styled.div`
  color: rgb(24, 42, 51);
  font-size: 14px;
  font-weight: normal;
  line-height: 60px;
  text-align: center;
  font-family: poppins-regular;
`;

export const TypographySection = styled.div`
  color: rgb(24, 42, 51);
  font-size: 14px;
  font-weight: normal;
  line-height: 60px;
  padding-left: 12px;
  font-family: poppins-regular;
`;

export const TypographyTitle = styled.span`
  color: rgb(24, 42, 51);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: poppins-semibold;
`;

export const TypographyPrice = styled.span`
  color: rgb(23, 109, 177);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  margin-bottom: 8px;
  font-family: poppins-semibold;
`;

export const TypographyDetails = styled.span`
  color: rgb(23, 109, 177);
  font-family: poppins-regular;
  font-size: 12px;
  font-weight: normal;
  height: 20px;
  width: 48px;
  padding: 2px;
`;

export const TooltipIcon = styled.span`
  float: right;
  color: rgb(181 186 191);
  margin-right: 12px;
`;

export const RequestsColumn = styled(Flexcol)`
  width: 950px;
  background: rgb(243 243 243);
  font-weight: 600;
  border-bottom: 1px solid rgb(223, 229, 235);
  border-right: 1px solid rgb(223, 229, 235);
  &:last-child {
    border-right: none;
  }
`;

export const RequestsSection = styled.div`
  color: rgb(24, 42, 51);
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  padding-left: 12px;
  font-family: poppins-semibold;
`;

export const LeftArrow = styled(LeftCircleOutlined)`
  font-size: 18px;
  cursor: pointer;
  color: rgb(23, 109, 177) !important;
`;

export const RightArrow = styled(RightCircleOutlined)`
  font-size: 18px;
  cursor: pointer;
  color: rgb(23, 109, 177) !important;
`;
