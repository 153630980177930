import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import axios from 'axios';
import { Typography, Menu, Checkbox, Select, Spin } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Delete } from '@styled-icons/fluentui-system-regular/Delete';
import { ArrowUpShort } from '@styled-icons/bootstrap/ArrowUpShort';

import MarketplaceModule from '../MarketplaceModule';
import SearchBarIndexUI from '../SearchBarIndexUI';
import { AppContext } from '../../hooks/AppContextProvider';
import { useStringOptions } from '../../hooks/useStringOptions';
import useCategories from '../../hooks/useCategories';
import { scrollStyles } from '../../styles/scrollStyles';
import { getThumbnailURL } from '../../iso-shared/utils/getThumbnailUrl';

const { Text } = Typography;
const { SubMenu } = Menu;

const Wrap = styled.div`
  padding: 35px 50px 0px;
  height: 70vh;
  overflow-y: auto;
  ${scrollStyles};
`;

const GeoCoverageWrap = styled(Menu.Item)`
  background-color: #fff !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-left: 0 !important;
  padding: 0 5px 0 5px !important;
  height: max-content !important;
  text-align: center;
  &::after {
    border-right: none !important;
  }
`;

const DataCategoryWrap = styled(Menu.Item)`
  background-color: #fff !important;
  margin: 0px !important;
  padding-left: 0 !important;
  padding: 0 0 0 28px !important;
  height: max-content !important;
  &::after {
    border-right: none !important;
  }
`;

const ScrollContainer = styled.div`
  padding: 6px 10px;
  border: 1px solid #ff7f19;
  background: #ff7f19;
  position: absolute;
  bottom: 15%;
  right: 20px;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  transition: all 0.2s ease-in;
  border-radius: 50%;
`;

const ScrollButton = () => (
  <ScrollContainer>
    <ArrowUpShort size="22" style={{ color: '#fff' }} />
  </ScrollContainer>
);

const empty_filters = {
  coverage: [],
  categories: [],
};

const Marketplace = (props) => {
  const { projectName, setModalTab, setSelectedProject, modalTab } = props;
  const [modules, setModules] = useState(null);
  const context = useContext(AppContext);
  const { categories } = useCategories();
  const [filters, setFilters] = useState(empty_filters);
  const [selectedListings, setSelectedListings] = useState([]);
  const selectedModule = context.dashboard_props.selected;
  const topOfPageRef = createRef();
  const [reachedTop, setReachedTop] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [continents, setContinents] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true);
      const res = await axios.get('/api/countries');
      const continents = [...new Set(res.data.map((item) => item.continent))];
      setCountries(res.data);
      setContinents(continents);
      setIsLoading(false);
    };
    fetchCountries();
  }, []);

  const continentStringFormat = (string) => {
    let splitString = string.toLowerCase().replace('_', ' ').split(' ');

    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join(' ');
  };

  let { RAZZLE_BASE_URL } = process.env;

  const has_filters = filters.coverage.length || filters.categories.length;

  const deselectListing = (id) => {
    setSelectedListings((prevState) =>
      prevState.filter((item) => item.id !== id),
    );

    context.setDashboardProps({
      ...context.dashboard_props,
      selected: {
        module: selectedListings.find((item) => item.id === id),
        isSelected: false,
      },
    });
  };

  useEffect(() => {
    if (context.dashboard_props.selectedListings?.length === 0) {
      setSelectedListings([]);
    }
  }, [context.dashboard_props.selectedListings?.length]);

  useEffect(() => {
    let updatedList = [...selectedListings];

    if (selectedModule?.isSelected) {
      updatedList.push(selectedModule.module);
      updatedList = updatedList.filter((item, pos) => {
        return updatedList.indexOf(item) == pos;
      });

      setSelectedListings(updatedList);
    } else {
      setSelectedListings(
        updatedList.filter((item) => item.id !== selectedModule.module.id),
      );
    }
  }, [selectedModule, modules]);

  useEffect(() => {
    context.setDashboardProps({
      ...context.dashboard_props,
      selectedListings: {
        length: selectedListings.length,
        listings: selectedListings,
      },
    });
  }, [selectedListings, modules]);

  useEffect(() => {
    const fetchModules = async () => {
      if (!modules) {
        setIsLoading(true);
        const res = await axios.get(`${RAZZLE_BASE_URL}/api/metadata-search`);
        const listings = res.data.filter((e) => e.published_step != 2);
        setModules(listings);
      }
      setIsLoading(false);
    };
    fetchModules();
  }, []);

  const filtered_data_products = useMemo(() => {
    const { coverage, categories } = filters;
    if (!coverage.length && !categories.length) {
      return modules;
    }
    return modules.filter((e) => {
      if (coverage.length) {
        if (!e.data_specs) {
          return false;
        }
        const match = coverage.find((c) =>
          e.data_specs.geo_coverage.includes(c),
        );
        if (!match) {
          return false;
        }
      }
      if (categories.length) {
        if (!categories.includes(e.category_name)) {
          return false;
        }
      }
      return true;
    });
  }, [modules, filters]);

  const setFilterValues = (field) => (values) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: values,
    }));
  };

  const clearFilters = () => setFilters(empty_filters);

  useEffect(() => {
    topOfPageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [modalTab]);

  const handleScroll = (e) => {
    const top = e.target.scrollTop <= 200;
    if (top) {
      setReachedTop(true);
    } else {
      setReachedTop(false);
    }
  };

  useEffect(() => {
    let marketplaceWrap = document.getElementById('marketplace-wrap');
    if (marketplaceWrap) {
      marketplaceWrap.addEventListener('scroll', handleScroll);
    }
    return () => marketplaceWrap.removeEventListener('scroll', handleScroll);
  });

  const handleClick = () => {
    topOfPageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Wrap id="marketplace-wrap">
      <div ref={topOfPageRef} />
      <Text
        style={{
          color: '#64737F',
          fontSize: '20px',
          display: 'block',
          marginBottom: '65px',
        }}
      >
        Search our marketplace and add more listings to
        <br />{' '}
        <Text
          style={{
            color: '#64737F',
            fontWeight: '600',
          }}
        >
          {projectName}
        </Text>{' '}
        consumption project.
      </Text>
      <SearchBarIndexUI
        setDataProducts={(modules) =>
          setModules(modules.filter((e) => e.published_step != 2))
        }
        small
      />
      {selectedListings.length > 0 && (
        <Row
          middle="xs"
          style={{
            padding: '25px 0px',
            borderTop: '1px solid #64737F',
            borderBottom: '1px solid #64737F',
          }}
        >
          <Text
            style={{ color: '#64737F', fontSize: '18px', marginRight: '25px' }}
          >
            Selected listings:
          </Text>
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '85%' }}>
            {selectedListings.map((item) => (
              <Row
                middle="xs"
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  marginRight: '18px',
                  marginBottom: '10px',
                  padding: '10px 5px',
                  border: '1px solid #DBDBDB',
                }}
              >
                <img
                  style={{ marginRight: 8, padding: '5px' }}
                  width="42"
                  height="42"
                  src={getThumbnailURL(item.image_url)}
                />
                <Text
                  style={{
                    color: '#182429',
                    fontSize: '16px',
                    marginRight: '25px',
                  }}
                >
                  {item.name}
                </Text>
                <Delete
                  style={{
                    height: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={() => deselectListing(item.id)}
                />
              </Row>
            ))}
          </div>
        </Row>
      )}
      <Row style={{ margin: '48px 0px 48px', position: 'relative' }}>
        {!!has_filters && (
          <Text
            style={{
              position: 'absolute',
              top: '-30px',
              left: '145px',
              color: '#344ef3',
              fontSize: '15px',
              cursor: 'pointer',
            }}
            onClick={clearFilters}
          >
            Clear filters <CloseOutlined style={{ paddingLeft: '12px' }} />
          </Text>
        )}
        <Col xs={2}>
          <Menu
            defaultOpenKeys={['sub1', 'sub2']}
            mode="inline"
            style={{
              width: '256px',
              borderRadius: '8px',
              boxShadow: '2px 4px 12px 0px rgba(24, 42, 51, 0.12)',
              position: 'sticky',
              top: '40px',
            }}
          >
            <SubMenu
              key="sub1"
              title="Geographical coverage"
              style={{ fontSize: '16px', fontWeight: '600' }}
            >
              <GeoCoverageWrap key="1">
                <Select
                  value={filters.coverage}
                  mode="multiple"
                  onChange={setFilterValues('coverage')}
                  style={{
                    width: '90%',
                    marginTop: '12px',
                    fontSize: '15px',
                    fontWeight: '400',
                  }}
                  placeholder="Select"
                >
                  {continents.map((item) => (
                    <Select.Option
                      key={item}
                      value={item}
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      {continentStringFormat(item)}
                    </Select.Option>
                  ))}
                  {countries.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.name}
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </GeoCoverageWrap>
            </SubMenu>
            <SubMenu
              key="sub2"
              title="Data category"
              style={{ fontSize: '16px', fontWeight: '600' }}
            >
              <DataCategoryWrap key="2">
                <Checkbox.Group
                  value={filters.categories}
                  onChange={setFilterValues('categories')}
                >
                  {categories.map((category) => (
                    <>
                      <Checkbox
                        key={category.id}
                        value={category.name}
                        style={{ fontSize: '15px', fontWeight: '400' }}
                      >
                        {category.name}
                      </Checkbox>
                      <br />
                    </>
                  ))}
                </Checkbox.Group>
              </DataCategoryWrap>
            </SubMenu>
          </Menu>
        </Col>
        {isLoading ? (
          <Col xs={9}>
            <Row center="xs" style={{ marginTop: '15%' }}>
              <Spin size="large" />
            </Row>
          </Col>
        ) : (
          <Col xsOffset={1} xs={9}>
            <Row id="homepage-listing">
              {filtered_data_products &&
                filtered_data_products.map((module) => (
                  <Col xs={6} key={module.id} style={{ paddingBottom: 20 }}>
                    <MarketplaceModule
                      module={module}
                      setModalTab={setModalTab}
                      setSelectedProject={setSelectedProject}
                    />
                  </Col>
                ))}
              {!filtered_data_products?.length > 0 && (
                <Text
                  style={{
                    color: '#182429',
                    fontSize: '17px',
                    margin: '45px auto 60px',
                    textAlign: 'center',
                  }}
                >
                  No results found.
                </Text>
              )}
            </Row>
          </Col>
        )}
      </Row>
      <button
        style={{ display: reachedTop ? 'none' : 'contents' }}
        onClick={handleClick}
      >
        <ScrollButton />
      </button>
    </Wrap>
  );
};

export default Marketplace;
