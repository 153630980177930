import styled, { css } from "styled-components";
import { colors } from "../values/colors";

export const SButton = styled.button`
  background: ${colors.tirq_4};
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
  padding: 10px 25px;
  cursor: pointer;
  text-transform: uppercase;
  color: #000;
  ${(p) =>
    p.light &&
    css`
    background: #ffffff;
    border: border: 1px solid ${colors.tirq_6};
  `}
  ${(p) =>
    p.disabled &&
    css`
      background: ${colors.tirq_4};
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
