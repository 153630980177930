import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";

import { InfoCircleOutlined } from "@ant-design/icons";

import {
  ProductCard,
  PricingColumn,
  Typography,
  TypographyTitle,
  TypographyPrice,
  TypographyDetails,
  TooltipIcon,
  TypographySection,
  RequestsColumn,
  RequestsSection,
} from "./pricing-components";

const Wrap = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const FreePaymentPlan = (props) => {
  const { pricingBundle } = props;

  return (
    <Wrap>
      <ProductCard>
        <PricingColumn>
          <TypographyTitle>Free</TypographyTitle>
          <TypographyPrice>
            <TypographyDetails>€</TypographyDetails>0
            <TypographyDetails></TypographyDetails>
          </TypographyPrice>
        </PricingColumn>
      </ProductCard>

      <ProductCard>
        <RequestsColumn>
          <RequestsSection>Requests limits</RequestsSection>
        </RequestsColumn>

        <RequestsColumn />
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per minute
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per minute"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {pricingBundle.limit_minute
              ? `${pricingBundle.limit_minute.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per hour
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per hour"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {pricingBundle.limit_hour
              ? `${pricingBundle.limit_hour.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per day
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: "2px 4px 12px 0px #182a331f",
                fontSize: "13px",
                borderRadius: "4px",
              }}
              placement="bottom"
              title="Maximum number of API requests per day"
              zIndex={999999999}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {pricingBundle.limit_day
              ? `${pricingBundle.limit_day.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
    </Wrap>
  );
};

export default FreePaymentPlan;
