import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { ModalView } from './views/ModalView';
import AppContextProvider from './hooks/AppContextProvider';
import { ModalProvider } from './hooks/ModalProvider';
import { AccountProvider } from './hooks/AccountContext';

const app_el = (
  <>
    <AccountProvider>
      <ModalProvider>
        <AppContextProvider>
          <BrowserRouter>
              <App />
          </BrowserRouter>
        </AppContextProvider>
      </ModalProvider>
    </AccountProvider>
    <ModalView/>
  </>
)

const root_el = document.getElementById('root');

if (typeof(__HAS_SSR__) != 'undefined') {
  hydrate( app_el, root_el );
} else {
  render( app_el, root_el );
}



if (module.hot) {
  module.hot.accept();
}
