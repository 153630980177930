import React from "react";
import { Typography } from "antd";
import styled from "styled-components";

import { Flexcol, Flexrow } from "../Flexbox";
import { scrollStyles } from "../../styles/scrollStyles";

const { Text, Title } = Typography;

const TextContainer = styled.div`
  max-height: 55vh;
  overflow-y: scroll;
  padding-right: 18px;
  ${scrollStyles}
`;

const TextModal = (props) => {
  const { name, title, text } = props;

  return (
    <Flexcol>
      <Text style={{ color: "#182429" }}>{name}</Text>
      <Flexrow center_align>
        <Title style={{ marginTop: 8, color: "#182429" }} level={5}>
          {title}
        </Title>
      </Flexrow>
      <TextContainer
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></TextContainer>
    </Flexcol>
  );
};

export default TextModal;
