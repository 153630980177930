import {
  Button,
  Menu,
  Input,
  Select,
  Switch,
  Typography,
  InputNumber,
} from "antd";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text, Link } = Typography;

const { TextArea } = Input;
const { SubMenu } = Menu;

export const IntegrationBoards = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(226, 228, 229);
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background-color: #ffffff;
`;

export const IntegrationLine = styled.div`
  background: rgb(226, 228, 229);
  border-radius: 0px;
  margin-top: 8;
  height: 1px;
`;

export const IntegrationButton = styled(Button)`
  margin-top: 18px;
  border-radius: 24px;
  border: 1px solid rgb(83, 241, 235);
  height: 107px;
  width: 142px;
  background: #e2e4e5;
  border: 1px solid rgb(255, 127, 25);
`;

// width: 800px;
export const IntegrationMenu = styled(Menu)`
  width: 600px;
  &.ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: transparent !important;
  }
`;

export const IntegrationSubmenu = styled(SubMenu)`
  .ant-menu-sub.ant-menu-inline {
    background: #fff;
  }
`;

export const IntegrationPublic = styled.div`
  border-radius: 24px;
  border: 1px solid rgb(240, 241, 242);
  margin-top: 18px;
  padding: 18px;
  margin-bottom: 18px;
`;

export const IntegrationInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid rgb(226, 228, 229);
  height: 49px;
`;

export const IntegrationInputNumber = styled(InputNumber)`
  border-radius: 8px;
  border: 1px solid rgb(226, 228, 229);
  height: 49px;
  width: 100%;
`;

export const IntegrationSelect = styled(Select)`
  height: 49px;
`;

export const IntegrationInfoCircleOutlined = styled(InfoCircleOutlined)``;

export const IntegrationSwitch = styled(Switch)`
  float: right;
  margin-top: -12px;
`;

export const IntegrationAddButton = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(226, 228, 229);
  padding: 16px;
`;
export const IntegrationTextArea = styled(TextArea)`
  color: #0097ff;
  background-color: black;
  width: "600px";
  height: "200px";
  margintop: "8px";
  marginbottom: "8px";
`;
export const IntegrationScreen = styled.div`
  color: #0097ff;
  background-color: black;
  width: "600px";
  height: "200px";
  margintop: "8px";
  marginbottom: "8px";
`;
