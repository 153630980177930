import React, { useMemo, useContext, useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  AutoComplete,
  Typography,
  Radio,
  Space,
} from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { tinyMCEEditorAPIKey } from '../../../values/tinyMCEEditorAPIKey';
import { tinyInitConfig } from '../values/tinyInitConfig';
import TitleAndDesc from '../../wizards-components/TitleAndDesc';
import SubsectionTitle from '../../wizards-components/SubsectionTitle';
import { SectionTitle } from '../../wizards-components/SectionTitle';
import IntegrationHelper from '../../wizards-components/IntegrationHelper';

import {
  SectionCardContainer,
  Container,
  Desc,
} from '../../wizards-components/styled-components';
import { strings } from '../values/strings';
import { DataRequestContext } from '../../../helpers/DataRequestWizardHelper';
import { Flexrow } from '../../Flexbox';
import useCategories from '../../../hooks/useCategories';
import { useStringOptions } from '../../../hooks/useStringOptions';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../../../values/colors';
import { v4 as uuid } from 'uuid';
import {
  data_access_types,
  data_delivery_methods,
  DATA_FORMATS,
} from '../../../iso-shared/values/global';

const { Text } = Typography;

const { TextArea } = Input;

const AddText = styled(Text)`
  color: ${colors.blue_4};
  cursor: pointer;
`;

const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  color: ${colors.grey_6};
`;

const name_form_rules = [
  {
    required: true,
    message: 'Please input project name!',
  },
];

export const BasicRequestDetails = () => {
  const helper = useContext(DataRequestContext);
  const [other_sources_preselect, setOtherSourcesPreselect] = useState(false);
  const LANGUAGES = useStringOptions('languages');
  const DATA_ATTRIBUTES = useStringOptions('data_attribute');
  const [continents, setContinents] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const res = await axios.get('/api/countries');
      const continents = [...new Set(res.data.map((item) => item.continent))];
      setCountries(res.data);
      setContinents(continents);
    };
    fetchCountries();
  }, []);

  const continentStringFormat = (string) => {
    let splitString = string.toLowerCase().replace('_', ' ').split(' ');

    for (let i = 0; i < splitString.length; i++) {
      splitString[i] =
        splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join(' ');
  };

  const { categories } = useCategories();

  const categories_options = useMemo(
    () =>
      categories.map((category) => {
        return {
          label: category.name,
          value: category.id,
        };
      }),
    [categories],
  );

  // #region data sources
  const DATA_SOURCES = useStringOptions('data_source');

  const DATA_SOURCES_SET = useMemo(() => {
    const out = new Set();
    DATA_SOURCES.forEach((e) => out.add(e));
    return out;
  }, [DATA_SOURCES]);

  const sources_set = new Set();
  const data_sources = helper.sources.value;
  data_sources.forEach((v) => sources_set.add(v));
  const other_data_sources = data_sources.filter(
    (e) => !DATA_SOURCES_SET.has(e),
  );

  const onSourceSelect = (v, checked) => () => {
    let new_sources = [...data_sources];
    if (checked) {
      new_sources.push(v);
    } else {
      new_sources = new_sources.filter((e) => e != v);
    }
    helper.sources.set(new_sources);
  };

  const onOtherSourcesPreselectChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setOtherSourcesPreselect(true);
    } else {
      setOtherSourcesPreselect(false);
      helper.sources.set(data_sources.filter((e) => DATA_SOURCES_SET.has(e)));
    }
  };

  const onOtherSourcesChange = (v) => {
    helper.sources.set([
      ...data_sources.filter((e) => DATA_SOURCES_SET.has(e)),
      ...v,
    ]);
  };

  const other_sources_checked =
    !!other_data_sources.length || other_sources_preselect;

  //#endregion

  // #region availability
  const getAvailability = (type) => helper.availability.value[type];
  const isAvailabilityChecked = (type) => getAvailability(type) != null;

  const onAvailabilityCheck = (type) => (e) => {
    helper.availability.set({
      ...helper.availability.value,
      [type]: e.target.checked ? '' : null,
    });
  };

  const onAvailabilityInput = (type) => (e) => {
    helper.availability.set({
      ...helper.availability.value,
      [type]: e.target.value,
    });
  };

  // #endregion

  // #region params

  const onParamName = (param) => (v) => {
    param.name = v;
    helper.parameters.trigger();
  };

  const onParamFreq = (param) => (e) => {
    param.frequency = e.target.value;
    helper.parameters.trigger();
  };

  const onParamDelete = (param) => () => {
    helper.parameters.set(helper.parameters.value.filter((e) => e != param));
  };

  const onAddParameter = () => {
    helper.parameters.set([
      ...helper.parameters.value,
      {
        id: uuid(),
        name: '',
        frequency: '',
      },
    ]);
  };
  // #endregion

  // #region delivery format

  const [other_formats_preselect, setOtherFormatsPreselect] = useState(false);

  const DATA_FORMATS_SET = useMemo(() => {
    const out = new Set();
    DATA_FORMATS.forEach((e) => out.add(e));
    return out;
  }, [DATA_FORMATS]);

  const formats_set = new Set();
  const data_formats = helper.deliveryFormat.value;
  data_formats.forEach((v) => formats_set.add(v));
  const other_data_formats = data_formats.filter(
    (e) => !DATA_FORMATS_SET.has(e),
  );

  const onFormatSelect = (v, checked) => () => {
    let new_formats = [...data_formats];
    if (checked) {
      new_formats.push(v);
    } else {
      new_formats = new_formats.filter((e) => e != v);
    }
    helper.deliveryFormat.set(new_formats);
  };

  const onOtherFormatsPreselectChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setOtherFormatsPreselect(true);
    } else {
      setOtherFormatsPreselect(false);
      helper.deliveryFormat.set(
        data_formats.filter((e) => DATA_FORMATS_SET.has(e)),
      );
    }
  };

  const onOtherFormatsChange = (v) => {
    helper.deliveryFormat.set([
      ...data_formats.filter((e) => DATA_FORMATS_SET.has(e)),
      ...v,
    ]);
  };

  const other_formats_checked =
    !!other_data_formats.length || other_formats_preselect;

  // #endregion

  const form_fields = [{ name: ['project_name'], value: helper.title.value }];

  let access_radio_value = null;
  if (Object.values(data_access_types).includes(helper.accessType.value)) {
    access_radio_value = helper.accessType.value;
  } else if (helper.accessType.value != null) {
    access_radio_value = 'other';
  }

  const onAccessRadioChange = (e) => {
    const type = e.target.value;
    if (type == 'other') {
      helper.accessType.set('');
    } else {
      helper.accessType.set(type);
    }
  };

  return (
    <Row>
      <Col span={15} offset={3}>
        <SectionCardContainer
          style={{ marginBottom: '120px', position: 'relative' }}
        >
          <Form fields={form_fields}>
            <TitleAndDesc
              title="Required details"
              desc={strings.basic_section_desc}
            />
            <SectionTitle
              title="Basic details"
              saving={helper.sections_map.details.saving}
              tooltip={strings.details_tooltip}
            />

            <SubsectionTitle
              tooltip={
                <Desc pre color="#fff">
                  {strings.name_tooltip}
                </Desc>
              }
            >
              What data are you looking for?
            </SubsectionTitle>
            <Form.Item name="project_name" rules={name_form_rules}>
              <Input
                value={helper.title.value}
                onChange={(e) => helper.title.set(e.target.value)}
                placeholder="Give a detailed title to your data request"
              />
            </Form.Item>

            <Flexrow top="16px" />

            <SubsectionTitle tooltip={strings.category_tooltip}>
              Data category
            </SubsectionTitle>
            <Select
              options={categories_options}
              value={categories_options.length ? helper.category.value : null}
              onChange={(v) => helper.category.set(v)}
              style={{ width: '100%' }}
            />

            <Flexrow top="22px" />

            <SubsectionTitle
              tooltip={
                <Desc pre color="#fff">
                  {strings.use_case_tooltip}
                </Desc>
              }
            >
              {strings.usecase_title}
            </SubsectionTitle>
            <Editor
              apiKey={tinyMCEEditorAPIKey}
              value={helper.usecaseDescription.value}
              onEditorChange={(v) => helper.usecaseDescription.set(v)}
              init={tinyInitConfig}
            />
            <Flexrow top="22px" />
            <SectionTitle
              title="Data specs"
              saving={helper.sections_map.dataSpecs.saving}
              tooltip={strings.dataspecs_tooltip}
            />
            <SubsectionTitle>{strings.sources_title}</SubsectionTitle>
            {!!DATA_SOURCES.length && (
              <Container>
                <Row>
                  {DATA_SOURCES.map((v) => (
                    <Col key={v} span={12}>
                      <Checkbox
                        checked={sources_set.has(v)}
                        key={v}
                        onChange={onSourceSelect(v, !sources_set.has(v))}
                      >
                        {v}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
                <Flexrow>
                  <Checkbox
                    checked={other_sources_checked}
                    onChange={onOtherSourcesPreselectChange}
                  >
                    Other
                  </Checkbox>
                </Flexrow>
                {other_sources_checked && (
                  <Flexrow top="8px">
                    <Select
                      value={other_data_sources}
                      style={{ width: '100%' }}
                      mode="tags"
                      onChange={onOtherSourcesChange}
                      tokenSeparators={[',']}
                    ></Select>
                  </Flexrow>
                )}
              </Container>
            )}
            <Flexrow top="22px" />
            <SubsectionTitle>{strings.volume_title}</SubsectionTitle>
            <TextArea
              style={{ height: 120 }}
              value={helper.dataVolume.value}
              placeholder={strings.volume_placeholder}
              onChange={(e) => helper.dataVolume.set(e.target.value)}
            />
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.temporal_tooltip}>
              Temporal availability
            </SubsectionTitle>

            <Checkbox
              checked={isAvailabilityChecked('real_time')}
              onChange={onAvailabilityCheck('real_time')}
            >
              Real-time/Near real-time
            </Checkbox>
            {isAvailabilityChecked('real_time') && (
              <>
                <Flexrow top="8px" />
                <Input
                  value={getAvailability('real_time')}
                  onChange={onAvailabilityInput('real_time')}
                  placeholder="e.g. 4 days"
                />
              </>
            )}
            <Flexrow top="12px" />

            <Checkbox
              checked={isAvailabilityChecked('historical')}
              onChange={onAvailabilityCheck('historical')}
            >
              Historical
            </Checkbox>
            {isAvailabilityChecked('historical') && (
              <>
                <Flexrow top="8px" />
                <Input
                  value={getAvailability('historical')}
                  onChange={onAvailabilityInput('historical')}
                  placeholder="e.g. 4 days"
                />
              </>
            )}
            <Flexrow top="12px" />

            <Checkbox
              checked={isAvailabilityChecked('forecast')}
              onChange={onAvailabilityCheck('forecast')}
            >
              Forecast
            </Checkbox>
            {isAvailabilityChecked('forecast') && (
              <>
                <Flexrow top="8px" />
                <Input
                  value={getAvailability('forecast')}
                  onChange={onAvailabilityInput('forecast')}
                  placeholder="e.g. 4 days"
                />
              </>
            )}
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.geocoverage_tooltip}>
              {strings.coverage_title}
            </SubsectionTitle>
            <Select
              mode="multiple"
              value={helper.geoCoverage.value}
              onChange={helper.geoCoverage.set}
              style={{ width: '100%' }}
              placeholder="Select geographical coverage"
            >
              {continents.map((item) => (
                <Select.Option
                  key={item}
                  value={item}
                  style={{ fontSize: '14px', fontWeight: '400' }}
                >
                  {continentStringFormat(item)}
                </Select.Option>
              ))}
              {countries.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.name}
                  style={{ fontSize: '14px', fontWeight: '400' }}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.languages_tooltip}>
              {strings.languages_title}
            </SubsectionTitle>
            <Select
              mode="multiple"
              onChange={helper.languages.set}
              value={helper.languages.value}
              style={{ width: '100%' }}
              placeholder="Select language"
            >
              {LANGUAGES.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.parameters_tooltip}>
              {strings.params_title}
            </SubsectionTitle>
            {helper.parameters.value.map((p) => (
              <Row align="middle" style={{ marginBottom: 8 }} key={p.id}>
                <Col span={14}>
                  <AutoComplete
                    value={p.name}
                    filterOption
                    onChange={onParamName(p)}
                    style={{ width: '100%' }}
                  >
                    {DATA_ATTRIBUTES.map((e) => (
                      <AutoComplete.Option key={e} value={e}>
                        {e}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                </Col>
                <Col span={7} offset={1}>
                  <Input
                    value={p.frequency}
                    onChange={onParamFreq(p)}
                    placeholder="e.g. 10Hz"
                  />
                </Col>
                <Col span={1} offset={1}>
                  <DeleteIcon onClick={onParamDelete(p)} />
                </Col>
              </Row>
            ))}
            <AddText onClick={onAddParameter}>
              <PlusOutlined /> add more
            </AddText>
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.access_type_tooltip}>
              {strings.access_type_title}
            </SubsectionTitle>
            <Radio.Group
              onChange={onAccessRadioChange}
              value={access_radio_value}
            >
              <Space direction="vertical">
                <Radio value={data_access_types.one_off}>
                  One-off purchase
                </Radio>
                <Radio value={data_access_types.recurrent}>
                  Recurrent access
                </Radio>
                <Radio value="other">Other</Radio>
              </Space>
            </Radio.Group>
            {access_radio_value == 'other' && (
              <Input
                value={helper.accessType.value}
                onChange={(e) => helper.accessType.set(e.target.value)}
                placeholder="Describe type of access"
              />
            )}
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.budget_tooltip}>
              {strings.budget_title}
            </SubsectionTitle>
            <Input
              value={helper.budget.value}
              onChange={(e) => helper.budget.set(e.target.value)}
              placeholder="e.g. 50k"
            />
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.delivery_method_tooltip}>
              {strings.delivery_method_title}
            </SubsectionTitle>
            <Radio.Group
              onChange={(e) => helper.deliveryMethod.set(e.target.value)}
              value={helper.deliveryMethod.value}
            >
              <Space direction="vertical">
                <Radio value={data_delivery_methods.api}>API</Radio>
                <Radio value={data_delivery_methods.file_sharing}>
                  File sharing
                </Radio>
              </Space>
            </Radio.Group>
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.format_tooltip}>
              {strings.delivery_format_title}
            </SubsectionTitle>
            <Container>
              <Row>
                {DATA_FORMATS.map((v) => (
                  <Col span={12} key={v}>
                    <Checkbox
                      checked={formats_set.has(v)}
                      key={v}
                      onChange={onFormatSelect(v, !formats_set.has(v))}
                    >
                      {v}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
              <Flexrow>
                <Checkbox
                  checked={other_formats_checked}
                  onChange={onOtherFormatsPreselectChange}
                >
                  Other
                </Checkbox>
              </Flexrow>
              {other_formats_checked && (
                <Flexrow top="8px">
                  <Select
                    value={other_data_formats}
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={onOtherFormatsChange}
                    tokenSeparators={[',']}
                  ></Select>
                </Flexrow>
              )}
            </Container>
            <Flexrow top="22px" />
            <SubsectionTitle
              tooltip={
                <Desc pre color="#fff">
                  {strings.additional_tooltip}
                </Desc>
              }
            >
              {strings.additional_info_title}
            </SubsectionTitle>
            <Editor
              apiKey={tinyMCEEditorAPIKey}
              value={helper.additionalInfo.value}
              onEditorChange={(v) => helper.additionalInfo.set(v)}
              init={tinyInitConfig}
            />
            <Flexrow top="22px" />
            <SubsectionTitle tooltip={strings.ispublic_tooltip}>
              {strings.is_public_title}
            </SubsectionTitle>
            <Radio.Group
              onChange={(e) => helper.isPublic.set(e.target.value)}
              value={helper.isPublic.value}
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Form>
        </SectionCardContainer>
      </Col>
      <Col span={5} offset={1}>
        <div style={{ position: 'sticky', top: '60px' }}>
          <IntegrationHelper
            title={strings.integration_helper_title}
            desc={strings.integration_helper_desc}
          />
        </div>
      </Col>
    </Row>
  );
};
