import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Visibility } from 'styled-icons/material';
import { AppContext } from '../components/AppContextProvider';
import { SText } from '../components/styled-text/text';
import { colors } from '../values/colors';
import { scrollStyles } from '../styles/scrollStyles';

const TabContent = styled(Col)`
  flex: auto;
  background-color: ${colors['grey_10']};
  padding: 43px;
  color: rgb(0, 151, 255);
  overflow-x: auto;
  ${scrollStyles}
`;

const SCircle = styled.div`
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const Wrap = styled.div`
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
`;

const Screen = ({ name, onPreview, children }) => {
  return (
    <Wrap>
      <Row style={{ background: colors.grey_8 }} center="xs" middle="xs">
        <Col xs={1}>
          <Row middle="xs" between="xs">
            <SCircle color={'#ff5750'} />
            <SCircle color={'#ffc12d'} />
            <SCircle color={'#29cb42'} />
          </Row>
        </Col>
        <Col xs={10}>
          <Row middle="xs">
            <Col xsOffset={1} xs={5} style={{ background: colors.grey_10 }}>
              <Row style={{ padding: '4px' }}>
                <SText text_12 white medium ellipsis>
                  {name}
                </SText>
              </Row>
            </Col>
            {onPreview && (
              <Col xsOffset={1} xs={5} style={{ padding: 0 }}>
                <button onClick={onPreview}>
                  <SText text_12 white medium right>
                    {`preview `}
                    <Visibility size="16" />
                  </SText>
                </button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <TabContent lg={12}>
          <SText text_16 bold grey_1 break linebreaks>
            {children}
          </SText>
        </TabContent>
      </Row>
    </Wrap>
  );
};

export default Screen;
