import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsOrderedListEnglish,
  TermsAndConditionsOrderedListItemEnglish,
} from '../../styles/termsAndConditions';

const ConfidentialityTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        C
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ONFIDENTIALITY
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Each party must keep all Confidential Information received from
              the other party in the performance of this Agreement confidential
              and may only use the Confidential Information for the purposes of
              this Agreement.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The parties may only disclose Confidential Information to those of
              their employees, agents and subcontractors who need to know such
              Confidential Information to conduct their obligations pursuant to
              the Agreement. Each party is responsible for its employees, agents
              and subcontractors. The parties may not disclose the Confidential
              Information to third parties without written consent of the other
              party.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem inner flexWrap>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The following are not considered to be Confidential Information:
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
          <TermsAndConditionsOrderedListEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  information obtained in a lawful manner from a third party not
                  bound by any confidentiality obligation or secrecy;
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  information already known to a party before its disclosure in
                  the context of this Agreement;
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  information independently developed by a party, without
                  breaching this Agreement;
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
            <TermsAndConditionsOrderedListItemEnglish flex inner>
              <TermsAndConditionsListSection>
                <TermsAndConditionsParagraph>
                  information which entered the public domain without the
                  intervention or fault of the party that received it.
                </TermsAndConditionsParagraph>
              </TermsAndConditionsListSection>
            </TermsAndConditionsOrderedListItemEnglish>
          </TermsAndConditionsOrderedListEnglish>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              In the event that a party is required by a law or decision of a
              regulatory, administrative or otherwise competent body to disclose
              Confidential Information, such party will notify the other party
              of such request, if permitted, in order to allow the said party to
              take any measures necessary to prevent or limit disclosure.
              Disclosure of Confidential Information will in any event be
              limited to what is strictly necessary for such party to comply and
              will inform the other party that it has made a disclosure as soon
              as it is permitted.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default ConfidentialityTerms;
