import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import TextColors from './styled-text/text-colors';

export const STabs = styled(Tabs)`
  font-size: 12px;
  width: 100%;
`;

export const STabList = styled(TabList)`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 12px 0px;
`;

export const STab = styled(Tab)`
  user-select: none;
  cursor: arrow;
  color: ${TextColors['semi']};
  margin-right: 36px;

`;

export const STabPanel = styled(TabPanel)``;
