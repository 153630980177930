import _ from 'lodash';
import { DatasetPropertiesEnum } from '../enums/DatasetProperties.enum.js';

const mapSelectedItems = (selectedItems, availableItems) => {
  const availableItemsDeepCopy = _.cloneDeep(availableItems);
  let selections = [];

  if (availableItemsDeepCopy) {
    availableItemsDeepCopy.map((item, index) => {
      if (Array.isArray(item.title)) {
        return (selections[index] = {
          discussionThreadId: item.discussionThreadId,
          title: DatasetPropertiesEnum[item.title[0]],
          value: item.title[1],
          checked:
            item.title[0] === selectedItems[index]?.title
              ? selectedItems[index]?.checked
              : item.checked,
          removable: item.removable,
          draft: item.draft,
        });
      }

      return (selections[index] = {
        discussionThreadId: item.discussionThreadId,
        title: item.title,
        checked:
          item.title === selectedItems[index]?.title
            ? selectedItems[index]?.checked
            : item.checked,
        removable: item.removable,
        draft: item.draft,
      });
    });
  }

  if (selections.length > 0) {
    selectedItems
      .map((selectedItem) => selectedItem)
      .filter((selectedItem) => {
        selections.filter((selection, ind) => {
          if (selection.title === selectedItem.title) {
            selections[ind].discussionThreadId =
              selectedItem.discussionThreadId;
            selections[ind].checked = true;
            selections[ind].removable = selectedItem.removable;
            selections[ind].draft = selectedItem.draft;
          }
        });
      });
  }

  return selections;
};

const mapSelectedAttributes = (selectedItems, availableItems) => {
  const availableItemsDeepCopy = _.cloneDeep(availableItems);
  let selections = [];

  if (availableItemsDeepCopy) {
    availableItemsDeepCopy.map((item, index) => {
      return (selections[index] = {
        name: item.name,
        frequency: item.frequency,
        key: index + 1,
        discussionThreadId: '',
        checked: false,
        removable: true,
        draft: false,
      });
    });
  }

  if (selections.length > 0) {
    selectedItems
      .map((selectedItem) => selectedItem)
      .filter((selectedItem) => {
        selections.filter((selection, ind) => {
          if (selection.name === selectedItem.name) {
            selections[ind].discussionThreadId =
              selectedItem.discussionThreadId;
            selections[ind].checked = true;
            selections[ind].removable = selectedItem.removable;
            selections[ind].draft = selectedItem.draft;
          }
        });
      });
  }

  return selections;
};

export const markSelectedItems = (selectedItems, availableItems) => {
  let selections = [];

  selections = mapSelectedItems(selectedItems, availableItems);
  return selections;
};

export const markSelectedAttributes = (selectedItems, availableItems) => {
  let selections = [];

  selections = mapSelectedAttributes(selectedItems, availableItems);
  return selections;
};
