import React, { useState } from 'react';
import { Typography, Modal, Button, Select, Form } from 'antd';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { Flexcol, Flexrow } from '../../components/Flexbox';
import { MEMBER_ROLES } from '../../iso-shared/values/global';
import TrashCanSrc from '../../images/trash_can.svg';
import { EMAIL_REGEX } from '../../utils/validation';
import Notification from '../../components/Notification';
import instance from '../../services/TokenValidator';

const { Text } = Typography;
const { Option } = Select;

const CancelButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background: #344ef3 !important;
    border: 1px solid #344ef3 !important;
    color: #fff !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    background: #f3f3f4 !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }
`;

const AddMoreButton = styled.div`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${(props) =>
    props.disabled ? '1px dashed #f3f3f4' : '1px dashed #c4c4c4'};
  border-radius: 4px;
  font-size: 15px;
  color: ${(props) => (props.disabled ? '#c1c2c2' : '#344ef3')};
  padding: 6px 18px;
  &:hover {
    background: #64737f0a;
    border: 1px dashed #64737f;
  }
`;

const memberRoles = MEMBER_ROLES.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

export const AddNewMemberModal = (props) => {
  const {
    setAddNewModalVisible,
    addNewModalVisible,
    companyName,
    setUpdated,
    setSelectDefaultValue,
  } = props;
  const [invitations, setInvitations] = useState({});
  const [invitationsArray, setInvitationsArray] = useState([]);
  const [role, setRole] = useState('');
  const [moreInvitations, setMoreInvitations] = useState({});
  const [form] = Form.useForm();
  const token = Cookies.get('jwt');

  const handleOk = async () => {
    let invitationsWithRole = invitations.map((inv) => {
      return {
        ...inv,
        role: role.toLocaleUpperCase(),
      };
    });

    let moreInvitationsWithRole = [];

    if (Object.keys(moreInvitations).length) {
      moreInvitationsWithRole = moreInvitations.map((inv) =>
        moreInvitationsWithRole.concat(inv),
      );
    }

    const allInvitations = moreInvitationsWithRole
      ? moreInvitationsWithRole.concat(invitationsWithRole)
      : invitationsWithRole;

    allInvitations.flat().forEach(async (invitation) => {
      try {
        await instance.post(
          `${process.env.RAZZLE_ACCOUNT_URL}/invitations`,
          invitation,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setUpdated((prevState) => !prevState);
        setSelectDefaultValue('All members');
        toast.success(
          <Notification
            type="success"
            text={`${
              invitations.length === 1 ? 'Invite' : 'Invites'
            } sent successfully!`}
          />,
        );
      } catch (error) {
        console.error(error);
        if (error.response)
          toast.error(
            <Notification type="error" text={error.response.data.message} />,
          );
      }
    });

    setAddNewModalVisible(false);
    setInvitations({});
    setMoreInvitations({});
    setRole('');
    setInvitationsArray([]);
  };

  const handleCancel = () => {
    setAddNewModalVisible(false);
    setInvitations({});
    setMoreInvitations({});
    setRole('');
    setInvitationsArray([]);
  };

  const handleSelectChange = (emails) => {
    for (let index = 0; index < emails.length; index++) {
      const email = emails[index];
      if (!EMAIL_REGEX.test(email.toLowerCase())) {
        setInvitationsArray((prevState) =>
          prevState.filter((em) => em !== email),
        );
        toast.warning(
          <Notification type="warning" text={`${email} has wrong format!`} />,
        );
      } else {
        const email = emails.map((email) => {
          return {
            email: email,
          };
        });
        setInvitations(email);
        setInvitationsArray((prevState) =>
          prevState
            .filter((em) => em !== email[index].email)
            .concat(email[index].email),
        );
      }
    }

    if (!emails.length) {
      setInvitations({});
      setInvitationsArray([]);
    }
  };

  const checkRoles = () => {
    const moreInvitationsKeys = Object.keys(moreInvitations);
    let isValid = false;

    for (let i = 0; i < moreInvitationsKeys.length; i++) {
      for (let j in moreInvitationsKeys[i]) {
        if (!moreInvitations[i]?.[j]?.role || !moreInvitations[i]?.[j]?.email) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const onValuesChange = () => {
    let moreInvitationsFormated = form.getFieldsValue();

    moreInvitationsFormated = moreInvitationsFormated.new_members_list.map(
      (inv, index) =>
        inv?.email.map((email) => {
          if (!EMAIL_REGEX.test(email.toLowerCase())) {
            form.setFields([
              {
                name: ['new_members_list', index, 'email'],
                value: inv.email.filter((inv) => inv !== email),
              },
            ]);
            toast.warning(
              <Notification
                type="warning"
                text={`${email} has wrong format!`}
              />,
            );
          } else {
            return {
              email: email,
              role: inv.role && inv.role.toLocaleUpperCase(),
            };
          }
        }),
    );

    setMoreInvitations(moreInvitationsFormated);
  };

  const handleRoleChange = (role) => {
    setRole(role);
  };

  let isDisabled = !Object.keys(invitations).length || !role || checkRoles();

  return (
    <Modal
      title={
        <Flexcol top="20px" bottom="20px" left="18px">
          <Text
            style={{
              color: '#182A33',
              fontSize: '19px',
              fontWeight: '600',
              marginBottom: '4px',
            }}
          >
            Add new member
          </Text>
          <Text style={{ color: '#64737F', fontSize: '15px' }}>
            Invite team members to {companyName}
          </Text>
        </Flexcol>
      }
      visible={addNewModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ borderRadius: '8px' }}
      centered={true}
      destroyOnClose={true}
      width="680px"
      footer={[
        <CancelButton key="back" onClick={handleCancel}>
          Cancel
        </CancelButton>,
        <SubmitButton key="submit" onClick={handleOk} disabled={isDisabled}>
          OK
        </SubmitButton>,
      ]}
    >
      <Flexcol left="18px" right="18px">
        <Flexrow bottom="20px" start_align>
          <Flexcol style={{ width: '66.5%', marginRight: '18px' }}>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Type email and press Enter"
              onChange={handleSelectChange}
              allowClear={true}
              open={false}
              size="large"
              value={invitationsArray}
            />
          </Flexcol>
          <Select
            placeholder="Select role"
            onChange={handleRoleChange}
            size="large"
            style={{ width: '180px' }}
          >
            {memberRoles}
          </Select>
        </Flexrow>
        <Form
          form={form}
          onValuesChange={onValuesChange}
          name="add_new_member_form"
          autoComplete="off"
        >
          <Form.List name="new_members_list">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Flexrow key={field.key} start_align bottom="20px">
                    <Flexrow start_align style={{ width: '100%' }}>
                      <Flexcol style={{ width: '66.5%', marginRight: '18px' }}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          style={{
                            marginBottom: '0px',
                            width: '100%',
                            marginRight: '18px',
                          }}
                        >
                          <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Type email and press Enter"
                            allowClear={true}
                            open={false}
                            size="large"
                          />
                        </Form.Item>
                      </Flexcol>
                      <Form.Item
                        {...field}
                        name={[field.name, 'role']}
                        style={{ marginBottom: '0px' }}
                      >
                        <Select
                          placeholder="Select role"
                          size="large"
                          style={{ width: '180px' }}
                        >
                          {memberRoles}
                        </Select>
                      </Form.Item>
                    </Flexrow>
                    <img
                      src={TrashCanSrc}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '16px',
                        marginTop: '10px',
                      }}
                      onClick={() => remove(field.name)}
                    />
                  </Flexrow>
                ))}
                <Form.Item style={{ marginBottom: '0px' }}>
                  <AddMoreButton
                    onClick={() => !isDisabled && add()}
                    disabled={isDisabled}
                  >
                    + Add more
                  </AddMoreButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Flexcol>
    </Modal>
  );
};
