import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Typography, Tooltip, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  LeftOutlined,
  UserOutlined,
  DownOutlined,
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { DataProductContext } from '../../../helpers/DataProductWizardHelper';
import { Flexrow } from '../../Flexbox';
import { confirmModal } from '../../../views/ModalView';
import {
  Title as StyledTitle,
  SubmitMenuContainer,
  SubmitMenu,
  SubmitMenuItem,
  SubmitButton,
  PreviewButton,
  GoToDashBoardContainer,
  ButtonsContainer,
} from '../../wizards-components/styled-components';
import Notification from '../../Notification';

const { Title } = Typography;
const EditButton = PreviewButton;

const light_confirmation = {
  title: 'Are you sure?',
  content: (
    <>
      <p>
        You are about to submit your light offer for review from Mobito experts.
      </p>
      <p>Do you want to proceed?</p>
    </>
  ),
};

const full_confirmation = {
  title: 'Are you sure?',
  content: (
    <>
      <p>
        You are about to submit your full offer for review from Mobito experts.
      </p>
      <p>Do you want to proceed?</p>
    </>
  ),
};

const HeaderWrap = styled.div`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  font-size: ${(props) => (props.show ? '16px' : '0px')};
  margin-left: 30px;
  transition: all 0.3s ease-in-out;
`;

const HeaderWizard = ({ no_back_button, sticky, isS3 }) => {
  const token = Cookies.get('jwt');
  const helper = useContext(DataProductContext);
  const history = useHistory();
  const [stickyHeader, setStickyHeader] = useState(false);

  if (helper.for_review) {
    return (
      <>
        <Flexrow grow />
        <Title style={{ marginBottom: 0, textAlign: 'end' }} level={5}>
          {helper.published
            ? 'Changes submitted for review'
            : 'Submitted for review'}
        </Title>
      </>
    );
  }

  const step_1_misses = helper.step_1_inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());
  const step_2_misses = helper.step_2_inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());
  const step_3_misses = helper.step_3_inputs
    .filter((e) => e.has_error)
    .map((e) => e.getName());

  const light_version_misses = [...step_1_misses, ...step_2_misses];
  const full_version_misses = [...light_version_misses, ...step_3_misses];

  const light_version_tooltip = light_version_misses.length
    ? 'Check the following inputs: ' + light_version_misses.join(', ')
    : null;

  const full_version_tooltip = full_version_misses.length
    ? 'Check the following inputs: ' + full_version_misses.join(', ')
    : null;

  const confirmChangesSubmit = async (step) => {
    const confirm_content = step == 2 ? light_confirmation : full_confirmation;
    const confirmation = await confirmModal(confirm_content);
    if (!confirmation) {
      return;
    }
    helper.submitting = true;
    helper.refresh();
    try {
      await axios.post(
        `/api/data-placements/editable/${helper.id}/publish-request`,
        { step },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (helper.published) {
        helper.for_review = true;
        helper.submitting = false;
        helper.refresh();
      } else {
        history.push({ pathname: `/dashboard`, state: { key: '1' } });
      }
      toast.success(
        <Notification type="success" text="Your offer is now under review" />,
      );
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="An error occured, please try again later"
        />,
      );
    }
  };

  const goToDashboard = () => {
    history.push({ pathname: `/dashboard`, state: { key: '1' } });
  };

  const handleMenuClick = (e) => {
    if (e.key == 'light_version') {
      confirmChangesSubmit(2);
    } else {
      confirmChangesSubmit(3);
    }
  };

  const menu = (
    <SubmitMenuContainer>
      <Menu onClick={handleMenuClick}>
        {helper.published_step != 3 && !isS3 && (
          <SubmitMenuItem
            disabled={!!light_version_tooltip}
            key="light_version"
            icon={<UserOutlined />}
          >
            <Tooltip
              color="#1a337d"
              overlayInnerStyle={{
                boxShadow: '2px 4px 12px 0px #182a331f',
                fontSize: '13px',
                borderRadius: '4px',
              }}
              placement="bottom"
              title={light_version_tooltip}
            >
              <SubmitMenu>
                <StyledTitle
                  color="#182429"
                  size={13}
                  weight="bold"
                  line="initial"
                >
                  Submit light version
                </StyledTitle>
                <StyledTitle color="#64737f" size={12} line="initial">
                  High-level description of your product.
                </StyledTitle>
              </SubmitMenu>
            </Tooltip>
          </SubmitMenuItem>
        )}
        <SubmitMenuItem
          disabled={!!full_version_tooltip}
          key="full_version"
          icon={<UserOutlined />}
        >
          <Tooltip
            color="#1a337d"
            overlayInnerStyle={{
              boxShadow: '2px 4px 12px 0px #182a331f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            placement="bottom"
            title={full_version_tooltip}
          >
            <SubmitMenu>
              <StyledTitle
                color="#182429"
                size={13}
                weight="bold"
                line="initial"
              >
                Submit full version
              </StyledTitle>
              <StyledTitle color="#64737f" size={12} line="initial">
                Fully integrated data product.
              </StyledTitle>
            </SubmitMenu>
          </Tooltip>
        </SubmitMenuItem>
      </Menu>
    </SubmitMenuContainer>
  );

  const global_saving = !!helper.sections.find((e) => e.saving || e.editing);

  const button_disabled =
    global_saving || helper.submitting || helper.wizard_step < 1;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setStickyHeader(window.pageYOffset > 180),
      );
    }
  }, []);

  return (
    <>
      <ButtonsContainer>
        {!no_back_button && (
          <GoToDashBoardContainer>
            <LeftOutlined
              onClick={goToDashboard}
              style={{
                marginRight: '16px',
                color: '#64737f',
                fontSize: '18px',
              }}
            />
          </GoToDashBoardContainer>
        )}
        <Flexrow center_align space_between style={{ width: '100%' }}>
          {sticky && (
            <HeaderWrap show={stickyHeader}>Create new data product</HeaderWrap>
          )}
          <Flexrow grow />
          <div style={{ display: 'flex' }}>
            {helper.previewing ? (
              <EditButton
                onClick={() => {
                  helper.togglePreview();
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <span>
                  <EditOutlined /> Edit
                </span>
              </EditButton>
            ) : (
              <PreviewButton
                onClick={() => {
                  helper.togglePreview();
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                disabled={button_disabled}
                id="product-preview-btn"
              >
                <span>
                  <EyeOutlined /> Preview
                </span>
              </PreviewButton>
            )}
            <Flexrow left="8px" />
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              disabled={button_disabled}
            >
              <SubmitButton id="product-submit-btn">
                Submit <DownOutlined />
              </SubmitButton>
            </Dropdown>
          </div>
        </Flexrow>
      </ButtonsContainer>
      <style jsx global>
        {`
          #start-walkthrough {
            display: ${helper.previewing && 'none'};
          }
        `}
      </style>
    </>
  );
};

export default HeaderWizard;
