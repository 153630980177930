import dummy_image_avatar from "../../images/avatar.svg";

const public_bucket = process.env.RAZZLE_MOBITO_FRONTEND_S3_PUBLIC_BUCKET;
const public_endpoint = process.env.RAZZLE_MOBITO_FRONTEND_S3_PUBLIC_ENDPOINT;

export const getThumbnailURL = (name) => {
  if (!name) {
    return dummy_image_avatar;
  }
  if (name.startsWith("https") || name.startsWith("blob")) {
    return name;
  }
  if (public_endpoint) {
    return `${public_endpoint}/${public_bucket}/thumbnails/${name}`;
  } else {
    return `https://${public_bucket}.s3.amazonaws.com/thumbnails/${name}`;
  }
};
