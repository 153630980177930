import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Typography, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { colors } from "../../../values/colors";
import { Flexrow } from "../../Flexbox";

const { Text } = Typography;

const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  color: ${colors.grey_6};
  font-size: 16px;
`;

const Top = styled(Flexrow)`
  border: 1px solid ${colors.grey_4};
  border-radius: 4px 4px 0 0;
  height: 42px;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const Bottom = styled(Flexrow)`
  border: 1px solid ${colors.grey_4};
  border-top: none;
  border-radius: 0 0 4px 4px;
  height: 42px;
  align-items: center;
  padding-left: 8px;
`;

export const SampleFileRow = (p) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    name,
    description,
    onDelete,
    onDescriptionChange,
    uploading,
    editing,
  } = p;

  useEffect(() => {
    if (editing) {
      setIsEditing(uploading);
      setTimeout(() => {
        setIsEditing(false);
      }, 2000);
    } else {
      setIsEditing(uploading);
    }
  }, []);

  return (
    <>
      <Top>
        <Text>{name}</Text>
        <Flexrow grow />
        {isEditing ? (
          <Spin style={{ lineHeight: 0 }} />
        ) : (
          <DeleteIcon onClick={onDelete} />
        )}
      </Top>
      <Bottom bottom="12px">
        <Input
          disabled={isEditing}
          onChange={(e) => onDescriptionChange(e.target.value)}
          value={description}
          bordered={false}
          placeholder="Sample file short description"
        />
      </Bottom>
    </>
  );
};
