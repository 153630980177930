import axios from 'axios';
import { toast } from 'react-toastify';
import { user_types } from '../../../iso-shared/values/global';

import Notification from '../../Notification';

const { RAZZLE_BASE_URL } = process.env;

export const postDiscussionThreads = async (
  token,
  dataRoomId,
  discussionThreads,
) => {
  Object.keys(discussionThreads).map((key) => {
    discussionThreads[key].map(async (val) => {
      if (val.length)
        try {
          await axios.post(
            `${RAZZLE_BASE_URL}/api/discussion-threads`,
            {
              data_room_id: dataRoomId,
              title: val.split('_')[0],
              category: key,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } catch (error) {
          toast.error(
            <Notification
              type="error"
              text="An error occured, please try again later"
            />,
          );
        }
    });
  });
};

export const getDiscussionThreads = async (token, dataRoomId, status) => {
  try {
    const response = await axios.get(
      `${RAZZLE_BASE_URL}/api/discussion-threads/room/${dataRoomId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      { ...(status && status) },
    );

    return response.data.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at),
    );
  } catch (error) {
    toast.error(
      <Notification
        type="error"
        text="An error occured, please try again later"
      />,
    );
  }
};

export const deleteDiscussionThread = async (token, discussionThreadId) => {
  try {
    await axios.delete(`/api/discussion-threads/${discussionThreadId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(<Notification type="success" text="Question item removed" />);
  } catch (error) {
    toast.error(
      <Notification
        type="error"
        text={
          error
            ? error.response.data.message
            : 'Could not send request, please try again later'
        }
      />,
    );
  }
};

export const postThreadItem = async (
  token,
  content,
  discussionThreadId,
  userType,
) => {
  const threadItemType =
    userType === user_types.CONSUMER ? 'question' : 'answer';
  try {
    const response = await axios.post(
      `${RAZZLE_BASE_URL}/api/thread-items/${threadItemType}`,
      {
        discussion_thread_id: discussionThreadId,
        content: content,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    toast.error(
      <Notification
        type="error"
        text="An error occured, please try again later"
      />,
    );
  }
};

export const patchThreadItem = async (token, content, threadItemId) => {
  try {
    await axios.patch(
      `${RAZZLE_BASE_URL}/api/thread-items/${threadItemId}`,
      {
        content: content,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {
    toast.error(
      <Notification
        type="error"
        text="An error occured, please try again later"
      />,
    );
  }
};

export const publishThreadItem = async (
  token,
  threadItemId,
  threadContent,
  userType,
) => {
  const threadItemNotificationType =
    userType === user_types.CONSUMER ? 'Question' : 'Answer';
  try {
    await axios.patch(
      `/api/thread-items/${threadItemId}/PUBLISHED`,
      {
        content: threadContent,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    toast.success(
      <Notification
        type="success"
        text={`${threadItemNotificationType} sent`}
      />,
    );
  } catch (error) {
    console.error(error);
    toast.error(
      <Notification
        type="error"
        text="Could not send request, please try again later"
      />,
    );
  }
};

export const postDiscussionThread = async (
  token,
  dataRoomId,
  title,
  category,
) => {
  try {
    return await axios.post(
      `${RAZZLE_BASE_URL}/api/discussion-threads`,
      {
        data_room_id: dataRoomId,
        title: title,
        category: category,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {
    console.error(error);
    toast.error(
      <Notification
        type="error"
        text="Could not send request, please try again later"
      />,
    );
  }
};

export const postAttachments = async (token, attachments, threadItemId) => {
  try {
    await axios.post(
      `/api/discussion-thread-attachments/item/${threadItemId}`,
      {
        attachments: attachments,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    toast.success(<Notification type="success" text="Request sent" />);
  } catch (error) {
    console.error(error);
    toast.error(
      <Notification
        type="error"
        text="Could not send request, please try again later"
      />,
    );
  }
};

export const deleteAttachment = async (token, attachmentId) => {
  try {
    await axios.delete(`/api/discussion-thread-attachments/${attachmentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(<Notification type="success" text="Attachment deleted" />);
  } catch (error) {
    console.error(error);
    toast.error(
      <Notification
        type="error"
        text="Could not send request, please try again later"
      />,
    );
  }
};

export const patchDiscussionThreadStatus = async (
  token,
  discussionThreadId,
  status,
) => {
  try {
    await axios.patch(
      `/api/discussion-threads/${discussionThreadId}`,

      {
        status: status,
      },

      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    toast.success(<Notification type="success" text="Status changed" />);
  } catch (error) {
    console.error(error);

    toast.error(
      <Notification
        type="error"
        text="Could not send request, please try again later"
      />,
    );
  }
};
