export const strings = {
  sample_files_and_documentation_instructions:
    "Data product documentation that consists of authentication, response codes, curl commands, url link to provider’s documentation and instructions",
  replace_api_key_description:
    "Use the API key provided by Mobito in all your API requests.",
  find_it_by_doing: `Dashboard   →   My consumption projects   →   Open project (copy API key)`,
  use_the_curl_commands:
    "Below is a list of endpoints that are provided from this API and the respective cURL commands to use in order to access each endpoint’s data",
  consult_the_documentation_of_the_provider:
    "Consult the documentation of the provider in order to fully structure your API request. Look for the available request parameters, request body and/or URL path.",
  sample_files_and_documentation_description_part1:
    "All API responses are provided in JSON format.",
  sample_files_and_documentation_description_part2:
    "Do not use the authentication that may be included in the provider’s API documentation.",
  sample_files_and_documentation_description_part3:
    "Mobito uses these response codes to indicate the success or failure of an API request.",
  light_version_tooltip:
    "This listing is a high-level description of the data product, without any integration and commercial details.",
};
