import { css } from 'styled-components';
import { colors } from '../values/colors';

export const scrollStyles = css`
  ::-webkit-scrollbar {
    width: 0.75em;
    height: 0.75em;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.grey_1};
    border-radius: 24px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.grey_7};
    border-radius: 24px;
  }
`;
