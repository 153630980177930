import {
  TermsAndConditionsHeaderLarge,
  TermsAndConditionsHeaderMedium,
  TermsAndConditionsParagraph,
  TermsAndConditionsOrderedList,
  TermsAndConditionsOrderedListItem,
  TermsAndConditionsEmptySpace,
  TermsAndConditionsListSection,
  TermsAndConditionsLink,
} from '../../styles/termsAndConditions';

const MiscellaneousTerms = () => {
  return (
    <TermsAndConditionsOrderedListItem>
      <TermsAndConditionsHeaderLarge marginLeft smaller>
        M
      </TermsAndConditionsHeaderLarge>
      <TermsAndConditionsHeaderMedium>
        ISCELLANEOUS
      </TermsAndConditionsHeaderMedium>
      <TermsAndConditionsEmptySpace />
      <TermsAndConditionsOrderedList>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              This Agreement constitutes the entire agreement between the
              parties and replaces all of the parties’ previous understandings,
              agreements and proposals, whether orally or in writing, relating
              to the subject matter of this Agreement. Deviations and additions
              are only valid when they have been agreed between parties,
              including by way of Mobito providing the revised Agreement for the
              Client’s approval via the Platform.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Unless otherwise agreed and without prejudice to any laws that
              might prescribe a language for certain proceedings, the language
              used for any disputes arising from the Agreement, an amendment or
              a termination of this Agreement as well as any other kind of
              communication between the Client and Mobito will be in English.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              The nullity of any article or part of an article under this
              Agreement will not affect the validity of the rest of the article,
              or the other clauses of the Agreement. Parties will make every
              effort to replace the invalid article with a valid one with the
              same, or largely the same, economic impact as the invalid article.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Neither this Agreement nor the rights or obligations arising from
              it may be wholly or partly transferred without the express written
              consent of both parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Neither party to this Agreement shall be deemed to have waived any
              right or claim under this Agreement or in relation to a breach of
              the other party, unless this waiver has been expressly
              communicated in writing. Even if a party, in the application of
              this paragraph, waives a specific right or claim under this
              Agreement, such waiver can never be interpreted as a waiver of any
              other right or claim under this Agreement even if both cases
              demonstrate large similarities. All legal remedies provided in the
              Agreement are cumulative and in addition to any other legal
              remedies available to the parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              Mobito shall have the right to use the name, trademark, or logo of
              the data partners (i.e. the Clients) for promotional or marketing
              purposes. If any data partner objects to the use of their name,
              trademark, or logo by Mobito, it should notify Mobito by sending
              an email to {''}
              <TermsAndConditionsLink
                href="mailto:legal@mobito.io"
                target="_blank"
              >
                legal@mobito.io
              </TermsAndConditionsLink>
              .
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              All notifications, requests and other communication under this
              Agreement (excluding everyday operational communications) shall be
              in writing by registered letter with proof of receipt to the
              address indicated above or in the Order Form, or in another
              conventional method of communication agreed between the Parties.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
        <TermsAndConditionsOrderedListItem flex inner>
          <TermsAndConditionsListSection>
            <TermsAndConditionsParagraph>
              All provisions of the Agreement which are expressly marked to
              survive the termination or expiration of the Agreement, as well as
              all provisions of the Agreement which aim to enforce or execute
              the Agreement after the termination or expiration of the
              Agreement, shall survive the Agreement and will remain in full
              force.
            </TermsAndConditionsParagraph>
          </TermsAndConditionsListSection>
        </TermsAndConditionsOrderedListItem>
      </TermsAndConditionsOrderedList>
    </TermsAndConditionsOrderedListItem>
  );
};

export default MiscellaneousTerms;
