import React, { useEffect, useContext, useRef } from "react";
import { Form, Row, Col, Input } from "antd";
import { Editor } from "@tinymce/tinymce-react";

import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";
import { SectionCardContainer, Desc } from "../../../wizards-components/styled-components";
import { strings } from "../../values/strings";
import { SectionTitle } from "../../../wizards-components/SectionTitle";
import SubsectionTitle from "../../../wizards-components/SubsectionTitle";
import TitleAndDesc from "../../../wizards-components/TitleAndDesc";
import { tinyMCEEditorAPIKey } from "../../../../values/tinyMCEEditorAPIKey";
import { tinyInitConfig } from "../../values/tinyInitConfig";
import { Flexrow } from "../../../Flexbox";

const S3UnpublishedFullVersion = () => {
  const helper = useContext(DataProductContext);
  const form_ref = useRef();

  useEffect(() => {
    helper.setStepAccessed(3);
  }, []);

  return (
    <SectionCardContainer>
      <Form ref={form_ref}>
        <TitleAndDesc title="Full version" desc={strings.full_version_desc} />
        <SectionTitle
          saving={helper.sections_map.license.saving}
          title="License"
          tooltip={
            <Desc pre color="#fff">
              {strings.license_tooltip}
            </Desc>
          }
        />
        <SubsectionTitle
          tooltip={
            <Desc pre color="#fff">
              {strings.license_tooltip}
            </Desc>
          }
        >
          License
        </SubsectionTitle>
        <Editor
          apiKey={tinyMCEEditorAPIKey}
          value={helper.license_type.value}
          onEditorChange={(v) => helper.license_type.set(v)}
          init={tinyInitConfig}
        />
        <Flexrow top="32px" />
        <SectionTitle
          saving={helper.sections_map.contact.saving}
          title="Technical contact details"
          tooltip={
            <Desc pre color="#fff">
              {strings.technical_contact_details_tooltip}
            </Desc>
          }
        />
        <Row justify="space-between">
          <Col span={11}>
            <SubsectionTitle
              tooltip={strings.technical_contact_details_name_tooltip}
            >
              {`Name & Surname`}
            </SubsectionTitle>
          </Col>
          <Col span={11} offset={1}>
            <SubsectionTitle
              tooltip={strings.technical_contact_details_email_tooltip}
            >
              Email
            </SubsectionTitle>
          </Col>
          <Col span={11}>
            <Input
              name="contact-s3-name"
              onChange={(e) => helper.contactS3Name.set(e.target.value)}
              value={helper.contactS3Name.value}
              placeholder="e.g. John Doe"
            />
          </Col>
          <Col span={11} offset={1}>
            <Input
              name="contact-s3-email"
              onChange={(e) => helper.contactS3Email.set(e.target.value)}
              value={helper.contactS3Email.value}
              placeholder="e.g. your@email.com"
            />
          </Col>
        </Row>
      </Form>
    </SectionCardContainer>
  );
};

export default S3UnpublishedFullVersion;
