export const strings = {
  basic_section_desc:
    "Please fill-in as many details as possible about your data request in order to match it with the correct data supply. All changes are auto-saved.",
  usecase_title: "What is your use case? Why do you need this data?",
  sources_title: "Are you looking for data from specific sources?",
  volume_title: "What is the data volume that you are looking for?",
  coverage_title: "What is the geographical coverage you are interested in?",
  languages_title: "In which languages you want the data to be in?",
  params_title:
    "What are the key data attributes and their respective update frequency?",
  access_type_title: "What type of transaction are you interested in?",
  budget_title: "What is your budget and its restrictions?",
  delivery_method_title: "What is your prefered delivery method?",
  delivery_format_title: "What is your prefered delivery format?",
  additional_info_title: "Anything to add?",
  is_public_title: "Publish data request in marketplace",
  // tooltips
  details_tooltip: "Please provide a basic description of your data request",
  name_tooltip: `A strong title would be:

  -> Vehicle probe data for traffic analytics in Belgium
  
  -> Micromobility trips for usage analytics in UK`,
  category_tooltip: "Select the category of data that you look for",
  use_case_tooltip: `Provide a description of your use case. How will you use the data? What benefit will it bring to your business/product?
    
    e.g. traffic estimation`,
  dataspecs_tooltip:
    "Please provide more details on the data that you look for",
  temporal_tooltip: "Please mention the period that you are interested in",
  geocoverage_tooltip: "Please define countries of interest",
  access_type_tooltip:
    "Are you interested in an one-off transaction or in a recurring data exchange?",
  languages_tooltip: "Languages of data description (e.g. parameters)",
  parameters_tooltip: "e.g. Acceleration, speed, breaking",
  budget_tooltip:
    "Please provide a budget range that you have available to purchase this data",
  delivery_method_tooltip:
    "How would you prefer to receive the data? Via API or via a file sharing method?",
  format_tooltip: "In which format you prefer the data to be?",
  additional_tooltip: `Do you have any other preferences with regards to this data?
    
    e.g. preferred format or standard schema`,
  ispublic_tooltip:
    "Do you want to publish your data in the marketplace and make it available for anyone to see it?",
  // placeholders
  volume_placeholder: "e.g. 2 months of driving data, 50k vehicles",
  // integration helper
  integration_helper_title: "Create a Data Request",
  integration_helper_desc: `Please provide some information describing the data set that you are looking for. We will use it, to source the right data for you. 

  Remember to preview your data request before submitting it. Publish your request in the marketplace in order to increase the chances of successful mathcmaking.`,
};
