import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import styled from "styled-components";

import signup_background_path from "../images/Signup_background.webp";
import mobito_logo from "../images/logo_white.svg";
import { Flexcol } from "./Flexbox";

const { Text } = Typography;

const Wrap = styled.div`
  height: 100%;
`;

const FormWrap = styled.div`
  width: 40vw;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 4px 12px 0px #182a3333;
  padding-right: 36px;
  padding-left: 36px;
  position: relative;
  @media (max-width: 1440px) {
    width: 50%;
  }
`;

const FormLayout = (props) => {
  let formWrap = <FormWrap>{props.children}</FormWrap>;

  if (props.isVerification) formWrap = <div>{props.children}</div>;

  return (
    <>
      <Wrap>
        <Flexcol center_align top="69px" bottom="168px">
          <Link to="/">
            <img
              style={{
                width: 147,
                height: 45,
                marginBottom: "37px",
              }}
              src={mobito_logo}
              alt="my image"
            />
          </Link>
          <Text style={{ color: "#fff", fontSize: "28px", fontWeight: "600" }}>
            {props.title}
          </Text>
          <Text
            style={{
              color: "#fff",
              fontSize: "28px",
              marginBottom: "28px",
              textAlign: "center",
              whiteSpace: "pre",
            }}
          >
            {props.subtitle}
          </Text>
          {formWrap}
        </Flexcol>
      </Wrap>
      <style>
        {`
        #root { 
          background-image: url(${signup_background_path}); 
          background-size: cover;
          }
          
          .footer-login-style {
            color:#fff !important;
          } 
          
          .white-logo-visible {
          display: initial !important;
          } 

          .black-logo-hidden {
          display: none !important
          }`}
      </style>
    </>
  );
};

export default FormLayout;
