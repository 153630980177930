import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataRoomHeader from './DataRoomHeader';
import Stepper from './Stepper';
import { Flexrow } from '../Flexbox';
import { fetchDataRoom } from './services/data-room.service';
import { Spin } from 'antd';
import { assignUserType } from './helpers/assign-user-type';

const DataRoom = () => {
  const { data_room_id } = useParams();
  const [dataRoom, setDataRoom] = useState('');
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const fetcher = async () => {
      try {
        setLoading(true);
        const response = await fetchDataRoom(data_room_id);

        if (response) {
          const consumerId = response.data?.consumer.id;
          const providerId = response.data?.provider.id;
          setDataRoom(response.data);
          setUserType(assignUserType(consumerId, providerId));
          setTimeout(() => {
            setLoading(false);
          }, 200);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetcher();
  }, []);

  return (
    <>
      {loading ? (
        <Flexrow
          center_align
          style={{ paddingTop: '20%', left: '50%', position: 'relative' }}
        >
          <Spin tip="Loading data capsule..."></Spin>
        </Flexrow>
      ) : (
        <>
          {userType && dataRoom && (
            <>
              <DataRoomHeader dataRoomName={dataRoom?.name} />
              <Stepper userType={userType} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default DataRoom;
