import React, { useState } from 'react';
import { Typography, Divider } from 'antd';

import { Flexrow, Flexcol } from '../../Flexbox';
import { strings } from '../assets/strings';
import { CustomCheckbox } from '../components/CustomCheckbox';

const { Text } = Typography;

export const DatasetPropertiesList = (props) => {
  const {
    datasetPropertiesSelections,
    datasetPropertiesDiscussionThreadsIds,
    datasetPropertiesCheckedState,
    selectable,
    readOnly,
    dataRoomId,
    setRefresh,
  } = props;

  const [customSelectionChecked, setCustomSelectionChecked] = useState(false);

  return (
    <Flexrow style={{ flexWrap: 'wrap', maxWidth: '1440px' }}>
      {datasetPropertiesSelections?.map((item, index) => (
        <Flexrow left="10px" bottom="10px" key={index}>
          <Flexrow
            style={{
              maxWidth: '480px',
              width: '360px',
              backgroundColor:
                selectable &&
                customSelectionChecked[index] &&
                'rgb(230 247 255)',
            }}
            space_between
            key={index}
          >
            <Flexcol>
              <Flexrow center_align>
                <CustomCheckbox
                  key={index}
                  selectionDiscussionThreadsIds={
                    datasetPropertiesDiscussionThreadsIds
                  }
                  selectable={selectable}
                  readOnly={readOnly}
                  dataRoomId={dataRoomId}
                  setRefresh={setRefresh}
                  selectionIndex={index}
                  selectionProperties={item}
                  initialCheckedState={datasetPropertiesCheckedState}
                  category={
                    strings.discussion_threads_categories.datasetProperties
                  }
                  setCustomSelectionChecked={setCustomSelectionChecked}
                ></CustomCheckbox>
              </Flexrow>
              <Flexrow center_align>
                <Text
                  style={{
                    color: '#182a33',
                    fontSize: '15px',
                    marginLeft: selectable ? '26px' : '8px',
                    maxWidth: '360px',
                  }}
                >
                  {item.value}
                </Text>
              </Flexrow>
            </Flexcol>
          </Flexrow>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              borderLeft: '2px solid #64737f',
              display: index === 2 ? 'none' : 'block',
            }}
          />
        </Flexrow>
      ))}
    </Flexrow>
  );
};
