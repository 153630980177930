import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const NodePortal = p => {
    const { id } = p
    const [ element, setElement ] = useState(null)

    useEffect(() => {
        const el = document.getElementById(id)
        setElement(el)
    }, [])

    if ( !element ) {
        return null
    }

    return createPortal(
        p.children,
        element
    );
}