// TODO: Update these categories
export const category_name = {
  '6c7f7a9f-0e67-40c9-8853-33cb256227a2': 'Pedestrian Information',
  'b466716b-3d14-4e72-8bb6-29b52978e2b4': 'Contextual Information',
  'c821564e-b290-431e-b226-862fd497d3c2': 'Traffic',
  '908afb46-22a4-4a2e-ad63-cd0bacef0cce': 'Mobility Operators',
  '0aefe2dc-d8da-4f25-9dec-76b7f46803d7': 'Public Transport',
  '3fbfe2dc-d8da-4f25-9dec-76b7f46803d7': 'Road data',
  'e1f48e4e-01ac-4e8d-b829-3c6172a19e6d': 'Driving data',
  'ae9b23f8-98d1-45b3-87bf-05e0224cb1b0': 'Stations',
  'd4fe4922-0f95-4119-93a9-d99a3c4c10ba': 'Parking',
  '025e3691-14a6-4e75-90d7-c41a9ab24f06': 'Environment',
  '3bad6aa3-dabc-46c4-bce8-e3191d6b3b5b': 'Other',
};
