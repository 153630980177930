import { user_types } from '../../../iso-shared/values/global';
import Cookies from 'js-cookie';

export const assignUserType = (consumerId, providerId) => {
  const organizationId = Cookies.get('organization_id') || '';

  if (organizationId === consumerId) {
    return user_types.CONSUMER;
  }

  if (organizationId === providerId) {
    return user_types.PROVIDER;
  }
};
