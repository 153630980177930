import React from "react";
import {
  ProductCard,
  PricingColumn,
  Typography,
  TypographyTitle,
  TypographyPrice,
  TypographyDetails,
  TooltipIcon,
  TypographySection,
  RequestsColumn,
  RequestsSection,
} from "./pricing-components";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { DataProductContext } from "../../../../helpers/DataProductWizardHelper";

export const RequestTab = () => {
  const helper = useContext(DataProductContext);

  const _package = helper.packages.value[0];
  const hide_pricing = helper.hide_pricing.value;

  return (
    <>
      <ProductCard>
        <PricingColumn>
          <TypographyTitle>Pricing per request</TypographyTitle>
          {!hide_pricing && (
            <TypographyPrice>
              <TypographyDetails>€</TypographyDetails>
              {_package.price?.toLocaleString()}
              <TypographyDetails>/request</TypographyDetails>
            </TypographyPrice>
          )}
        </PricingColumn>
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Minimum purchase
            <Tooltip
              title={`Minimum number of API requests that you need to purchase`}
            >
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.min_requests_purchased
              ? `${_package.min_requests_purchased.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>

      <ProductCard>
        <RequestsColumn>
          <RequestsSection>Requests limits</RequestsSection>
        </RequestsColumn>
        <RequestsColumn />
      </ProductCard>

      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per minute
            <Tooltip title={`Maximum number of API requests per minute`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.limit_minute
              ? `${_package.limit_minute.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per hour
            <Tooltip title={`Maximum number of API requests per hour`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.limit_hour
              ? `${_package.limit_hour.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
      <ProductCard>
        <PricingColumn>
          <TypographySection>
            Per day
            <Tooltip title={`Maximum number of API requests per day`}>
              <TooltipIcon>
                <InfoCircleOutlined />
              </TooltipIcon>
            </Tooltip>
          </TypographySection>
        </PricingColumn>
        <PricingColumn>
          <Typography>
            {_package.limit_day
              ? `${_package.limit_day.toLocaleString()} requests`
              : "-"}
          </Typography>
        </PricingColumn>
      </ProductCard>
    </>
  );
};
