import { getDiscussionThreads } from '../chat/chat-services';
import {
  thread_item_status,
  thread_item_types,
} from '../../../iso-shared/values/global';

const categories = [
  'Attributes',
  'Geocoverage',
  'DatasetProperties',
  'GeneralAttachments',
  'GeneralAttributes',
  'GeneralGeocoverage',
  'GeneralDatasetProperties',
];

export const selectedItemsMapper = async (token, dataRoomId) => {
  let selected = {
    Attributes: [],
    Geocoverage: [],
    DatasetProperties: [],
    Attachments: [],
    GeneralAttributes: [],
    GeneralGeocoverage: [],
    GeneralDatasetProperties: [],
  };

  const discussionThreads = await getDiscussionThreads(token, dataRoomId);

  categories.map((category) => {
    return discussionThreads
      .filter((discussionThread) => {
        return discussionThread.category === category;
      })
      .map((discussionThread) => {
        // Check if at least one thread item has status PUBLISHED or type ANSWER
        // A discussion thread can be deleted only if all thread items have status DRAFT and type QUESTION
        const is_unremovable = discussionThread.thread_items.some(
          (item) =>
            item.status === thread_item_status.PUBLISHED ||
            item.type === thread_item_types.ANSWER,
        );

        // Check if at least one thread item has status DRAFT or content has value
        const has_draft = discussionThread.thread_items.some(
          (item) =>
            item.status === thread_item_status.DRAFT && item.content !== '',
        );

        switch (category) {
          case 'Attributes':
            selected.Attributes = [
              ...selected.Attributes,
              {
                name: discussionThread.title,
                frequency: '',
                key: '',
                discussionThreadId: discussionThread.id,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          case 'Geocoverage':
            selected.Geocoverage = [
              ...selected.Geocoverage,
              {
                discussionThreadId: discussionThread.id,
                title: discussionThread.title,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          case 'DatasetProperties':
            selected.DatasetProperties = [
              ...selected.DatasetProperties,
              {
                discussionThreadId: discussionThread.id,
                title: discussionThread.title,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          case 'GeneralAttachments':
            selected.Attachments = [
              ...selected.Attachments,
              {
                discussionThreadId: discussionThread.id,
                title: discussionThread.title,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          case 'GeneralAttributes':
            selected.GeneralAttributes = [
              ...selected.GeneralAttributes,
              {
                discussionThreadId: discussionThread.id,
                title: discussionThread.title,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          case 'GeneralGeocoverage':
            selected.GeneralGeocoverage = [
              ...selected.GeneralGeocoverage,
              {
                discussionThreadId: discussionThread.id,
                title: discussionThread.title,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          case 'GeneralDatasetProperties':
            selected.GeneralDatasetProperties = [
              ...selected.GeneralDatasetProperties,
              {
                discussionThreadId: discussionThread.id,
                title: discussionThread.title,
                checked: true,
                removable: !is_unremovable,
                draft: has_draft,
                status: discussionThread.status,
              },
            ];
            break;
          default:
            break;
        }
      });
  });
  return selected;
};
