import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Cookies from 'js-cookie';
import { Typography, Button, Modal, Tabs, Spin } from 'antd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';

import SourceCard from './SourceCard';
import Marketplace from './Marketplace';
import { data_placement_type_ids } from '../../iso-shared/values/global';
import S3SourceCard from './S3SourceCard';
import { AppContext } from '../../hooks/AppContextProvider';
import { confirmModal } from '../../views/ModalView';
import Notification from '../Notification';
import { Flexcol } from '../Flexbox';
import { ListingContent } from '../../page-components/ProductListing/ListingContent';
import {
  DataProductContext,
  DataProductWizardHelper,
} from '../../helpers/DataProductWizardHelper';

const { Text } = Typography;
const { TabPane } = Tabs;

const CancelButton = styled(Button)`
  border-radius: 8px !important;
  border: 1px solid #344ef3 !important;
  color: #344ef3 !important;
  font-size: 15px !important;
  width: 140px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover {
    background: #344ef3 !important;
    border: 1px solid #344ef3 !important;
    color: #fff !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 8px !important;
  color: #fff !important;
  border: 1px solid #344ef3 !important;
  font-size: 15px !important;
  background: #344ef3 !important;
  width: 205px !important;
  padding: 9px 0px !important;
  height: auto !important;
  &:hover,
  :active,
  :focus {
    background: #0c28dd !important;
    box-shadow: 2px 4px 12px 0px #182a331f !important;
  }
  &:disabled {
    background-color: #fff !important;
    color: #c1c2c2 !important;
    border: 1px solid #f3f3f4 !important;
    cursor: not-allowed;
  }
`;

const Sources = ({ project_id, project_name }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState(false);
  const context = useContext(AppContext);
  const [sources, setSources] = useState([]);
  const selected = context.dashboard_props?.selectedListings?.length;
  const [modalTab, setModalTab] = useState('1');
  const token = Cookies.get('jwt') || '';
  const [, updator] = useState({});
  const [project, setProject] = useState('');

  useEffect(() => {
    const fetch = async () => {
      const res = await axios
        .get(`/api/data-consumption-metadata?project_id=${project_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.error(
              <Notification type="error" text="Unauthorized Access" />,
            );
            history.push('/not-found');
          }
        });

      setSources(res.data);
      setApiKey(false);
    };
    fetch();
  }, [isModalVisible, apiKey, project_id]);

  if (!sources)
    return (
      <div
        style={{
          backgroundColor: '#f9faff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

  const handleCancel = () => {
    setIsModalVisible(false);
    context.setDashboardProps({
      ...context.dashboard_props,
      selectedListings: {
        length: 0,
        listings: [],
      },
    });
    setModalTab('1');
  };

  const onAdd = async () => {
    const confirmation = await confirmModal({
      title: 'Important Note',
      content: (
        <>
          <p>
            You are about to send an access request to the provider
            {context.dashboard_props.selectedListings?.length > 1
              ? 's'
              : ''} of{' '}
            {context.dashboard_props.selectedListings?.listings.map(
              (item, index) => (
                <b key={index}>
                  {item.name}
                  {context.dashboard_props.selectedListings?.length ===
                  index + 1
                    ? ''
                    : ','}{' '}
                </b>
              ),
            )}
            marketplace listing.
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      ),
    });
    if (!confirmation) {
      return;
    }
    try {
      await axios.post(
        '/api/sources',
        {
          listings: context.dashboard_props.selectedListings?.listings,
          consumption_id: project_id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      toast.success(
        <Notification
          type="success"
          text={`Access request${selected === 1 ? '' : 's'} ${
            selected === 1 ? 'has' : 'have'
          } been sent successfully.`}
        />,
      );
      setIsModalVisible(false);
      context.setDashboardProps({
        ...context.dashboard_props,
        selectedListings: {
          length: 0,
          listings: [],
        },
      });
    } catch (error) {
      console.error(error);
      toast.error(
        <Notification
          type="error"
          text="Could not create consumption connection"
        />,
      );
    }
  };

  const setSelectedProject = async (id) => {
    let res;
    try {
      if (id) {
        res = await axios.get(`/api/data-placements/editable/${id}/origin`);
      } else {
        res = await axios.get(
          `/api/data-placements/editable/origin-by-url/${url_id}`,
        );
      }
    } catch (error) {
      console.error(error);
      if (error.isAxiosError && error.response.status == 404) {
        stetNotFound(true);
      } else {
        toast.error(
          <Notification
            type="error"
            text="An error occured, please try again"
          />,
        );
      }
      return;
    }
    setProject(res.data);
  };

  const helper = useMemo(() => {
    if (!project) {
      return null;
    }

    const helper = new DataProductWizardHelper(
      () => updator({}),
      project.id,
      null,
      project,
    );
    helper.onError = () =>
      toast.error(<Notification type="error" text="Could not save changes" />);
    helper.fromProject(project);

    return helper;
  }, [project]);

  const mainView = () => {
    if (helper) {
      return <ListingContent modal project={project} setMdlTab={setModalTab} />;
    }
    return (
      <Row middle="xs" center="xs" style={{ height: '70vh' }}>
        <Spin size="large" />
      </Row>
    );
  };

  return (
    <>
      <Modal
        title={
          <Text
            style={{
              color: '#182429',
              fontSize: '20px',
              fontWeight: '600',
              margin: '35px 0px 35px 50px',
              display: 'block',
            }}
          >
            {modalTab === '1' ? (
              'Add more listings'
            ) : (
              <ArrowBack
                style={{
                  color: '#182429',
                  height: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => setModalTab('1')}
              />
            )}
          </Text>
        }
        visible={isModalVisible}
        onOk={onAdd}
        onCancel={handleCancel}
        centered={true}
        style={{ paddingBottom: '0px' }}
        bodyStyle={{
          backgroundColor: '#F9FAFF',
          padding: '0px',
          maxHeight: '70vh',
          overflowY: 'auto',
        }}
        zIndex={1000000}
        width="100%"
        footer={
          modalTab === '1'
            ? [
                <CancelButton key="back" onClick={handleCancel}>
                  Cancel
                </CancelButton>,
                <SubmitButton
                  key="submit"
                  onClick={onAdd}
                  disabled={selected === 0}
                >
                  {selected === 0
                    ? 'Add a listing'
                    : `Add ${selected} listing${selected === 1 ? '' : 's'}`}
                </SubmitButton>,
              ]
            : [
                <CancelButton key="back" onClick={() => setModalTab('1')}>
                  Back
                </CancelButton>,
              ]
        }
      >
        <Flexcol>
          <Tabs
            defaultActiveKey="1"
            activeKey={modalTab}
            tabBarStyle={{ display: 'none' }}
          >
            <TabPane key="1">
              <Marketplace
                projectName={project_name}
                setModalTab={setModalTab}
                setSelectedProject={setSelectedProject}
                modalTab={modalTab}
              />
            </TabPane>
            <TabPane key="2">
              <DataProductContext.Provider value={helper}>
                {mainView()}
              </DataProductContext.Provider>
            </TabPane>
          </Tabs>
        </Flexcol>
      </Modal>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '55px',
          marginTop: '60px',
        }}
      >
        <Text style={{ fontSize: '28px', color: '#182429' }}>
          {project_name}
        </Text>
        <Text style={{ fontSize: '15px', color: '#64737F' }}>
          Data consumption project
        </Text>
      </div>
      <div style={{ marginTop: 12 }}>
        {sources.map((source, index) => {
          return (
            <Row key={index}>
              <Col xs={12}>
                {source.type_id != data_placement_type_ids.s3_bucket && (
                  <SourceCard
                    source={source}
                    project_id={project_id}
                    setApiKey={setApiKey}
                  ></SourceCard>
                )}
                {source.type_id == data_placement_type_ids.s3_bucket && (
                  <S3SourceCard
                    source={source}
                    project_id={project_id}
                    setApiKey={setApiKey}
                  ></S3SourceCard>
                )}
              </Col>
            </Row>
          );
        })}
      </div>
      <style>
        {`
      .ant-modal-body::-webkit-scrollbar {
        width: 0.75em;
        height: 0.75em;
      }
      .ant-modal-body::-webkit-scrollbar-track {
        background: #f0f1f2;
        border-radius: 24px;
      }
      .ant-modal-body::-webkit-scrollbar-thumb {
        background: #6f7a80;
        border-radius: 24px;
      }
    `}
      </style>
    </>
  );
};

export default Sources;
