import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { Form, Button, Input, Typography, Select } from 'antd';

import FormLayout from '../FormLayout';
import SubsectionTitle from '../../components/wizards-components/SubsectionTitle';
import { Flexcol, Flexrow } from '../Flexbox';
import validate from '../validators/SignUpWizardValidator';
import { COUNTRIES, INDUSTRIES } from '../../iso-shared/values/global';
import instance from '../../services/TokenValidator';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 36px;
  background-color: #344ef3;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: none;
  &:disabled {
    background-color: #fff;
    color: #c1c2c2;
    border: 1px solid #f3f3f4;
    cursor: not-allowed;
  }
  &:hover,
  :active,
  :focus {
    background-color: #0c28dd;
    box-shadow: 2px 4px 12px 0px rgba(24, 42, 51, 0.12);
    color: #fff;
  }
`;

const business_roles = [
  'Business Analyst',
  'Business Developer',
  'Business Intelligence Analyst',
  'Chief Executive Officer (CEO)',
  'Chief Technology Officer (CTO)',
  'Data Analyst',
  'Data Scientist',
  'Information Officer',
  'Marketing Manager',
  'Operations Manager',
  'Product Manager',
  'Project Manager',
  'Public Sector Employee',
  'Researcher',
  'Sales Manager',
  'Software Engineer',
];

const business_role_options = business_roles.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

const country_options = COUNTRIES.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

const industry_options = INDUSTRIES.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

const SetUpAccount = (props) => {
  const {
    step_id,
    errors,
    setErrors,
    setForm,
    form_data,
    disabled,
    verification_token,
    invitation_id,
    user_email,
  } = props;
  const [touched, setTouched] = useState(false);
  const [otherRole, setOtherRole] = useState(false);
  const token = Cookies.get('jwt') || '';
  const user_id = Cookies.get('user_id') || '';
  const role = Cookies.get('role');
  const history = useHistory();

  const formFieldsHandler = (fieldName, fieldValue) => {
    if (
      (fieldName === 'business_role' && fieldValue === 'other') ||
      ((fieldName === 'country' || fieldName === 'industry') &&
        fieldValue === 'Other')
    ) {
      setOtherRole((prevState) => ({ ...prevState, [fieldName]: true }));
    }

    if (
      (fieldName === 'business_role' && fieldValue !== 'other') ||
      ((fieldName === 'country' || fieldName === 'industry') &&
        fieldValue !== 'Other')
    ) {
      setOtherRole((prevState) => ({ ...prevState, [fieldName]: false }));
    }

    setForm({
      ...form_data,
      [fieldName]: fieldValue,
    });
  };

  useEffect(() => {
    setErrors(validate(form_data, step_id));
  }, [form_data]);

  let hasErrors = !!errors && Object.keys(errors).length === 0;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validate(form_data, step_id));

    if (hasErrors) {
      const user_response = await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/users`,
        {
          firstname: form_data.first_name,
          lastname: form_data.last_name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      let organization_response;

      if (!invitation_id) {
        organization_response = await instance.patch(
          `${process.env.RAZZLE_ACCOUNT_URL}/organizations`,
          {
            name: form_data.company_name,
            address: form_data.company_address,
            vat: form_data.vat,
            description: form_data.description,
            industry:
              form_data.industry != 'Other'
                ? form_data.industry
                : form_data.other_industry,
            country:
              form_data.country != 'Other'
                ? form_data.country
                : form_data.other_country,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
      }

      const business_role_response = await instance.patch(
        `${process.env.RAZZLE_ACCOUNT_URL}/organizations/users/role`,
        {
          userId: user_id,
          role: invitation_id ? role : 'ADMIN',
          businessRole:
            form_data.business_role !== 'other'
              ? form_data.business_role
              : form_data.other_business_role,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (
        user_response.status === 200 &&
        (!invitation_id ? organization_response.status === 200 : true) &&
        business_role_response.status === 200
      ) {
        history.push('/login');
      }
    }
  };

  return (
    <FormLayout
      title="One last step…"
      subtitle="Set up your account to get started"
    >
      <Flexcol>
        <Text style={{ color: '#182429', fontSize: '24px', marginTop: '50px' }}>
          Tell us about you
        </Text>
        <Form>
          <Flexcol top="36px">
            <Flexrow>
              <SubsectionTitle>First name</SubsectionTitle>
              <Flexrow right="14px" />
              <SubsectionTitle>Last name</SubsectionTitle>
            </Flexrow>
            <Flexrow>
              <Form.Item style={{ width: '100%', marginRight: '14px' }}>
                <Input
                  name="first_name"
                  placeholder="Type your first name"
                  onChange={(event) =>
                    formFieldsHandler(event.target.name, event.target.value)
                  }
                  onBlur={() =>
                    setTouched({ ...touched, ['first_name']: true })
                  }
                  size="large"
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #c4c4c4',
                    marginRight: '14px',
                  }}
                />
                {errors && errors.first_name && touched['first_name'] && (
                  <Text style={{ color: '#ff4d4f' }}>{errors.first_name}</Text>
                )}
              </Form.Item>
              <Flexrow grow />
              <Form.Item style={{ width: '100%' }}>
                <Input
                  name="last_name"
                  placeholder="Type your last name"
                  onChange={(event) =>
                    formFieldsHandler(event.target.name, event.target.value)
                  }
                  onBlur={() => setTouched({ ...touched, ['last_name']: true })}
                  size="large"
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #c4c4c4',
                  }}
                />
                {errors && errors.last_name && touched['last_name'] && (
                  <Text style={{ color: '#ff4d4f' }}>{errors.last_name}</Text>
                )}
              </Form.Item>
            </Flexrow>
          </Flexcol>
          <SubsectionTitle>Job title</SubsectionTitle>
          <Form.Item>
            <Select
              name="business_role"
              size="large"
              placeholder="Select"
              onChange={(value) => formFieldsHandler('business_role', value)}
            >
              {business_role_options}
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          {otherRole.business_role && (
            <Form.Item>
              <Input
                name="other_business_role"
                placeholder="E.g. Product manager"
                onChange={(event) =>
                  formFieldsHandler(event.target.name, event.target.value)
                }
                onBlur={() =>
                  setTouched({ ...touched, ['other_business_role']: true })
                }
                size="large"
                style={{
                  borderRadius: '4px',
                  border: '1px solid #c4c4c4',
                }}
              />
              {errors &&
                errors.other_business_role &&
                touched['other_business_role'] && (
                  <Text style={{ color: '#ff4d4f' }}>
                    {errors.other_business_role}
                  </Text>
                )}
            </Form.Item>
          )}
        </Form>
        <Text style={{ color: '#182429', fontSize: '24px', marginTop: '26px' }}>
          Company details
        </Text>
        <Text
          style={{
            color: '#182429',
            fontSize: '20px',
            margin: '10px 0px 42px 0px',
          }}
        >
          You can complete and change this later
        </Text>
        <Form>
          <SubsectionTitle tooltip="Provide the full legal name of your company">
            Full company name
          </SubsectionTitle>
          <Form.Item>
            <Input
              name="company_name"
              placeholder="Type your company name"
              defaultValue={form_data.company_name}
              onChange={(event) =>
                formFieldsHandler(event.target.name, event.target.value)
              }
              onBlur={() => setTouched({ ...touched, ['company_name']: true })}
              size="large"
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
              }}
              disabled={disabled}
            />
            {errors && errors.company_name && touched['company_name'] && (
              <Text style={{ color: '#ff4d4f' }}>{errors.company_name}</Text>
            )}
          </Form.Item>
          <SubsectionTitle tooltip="Provide the full address where your company is incorporated and registered">
            Full company address
          </SubsectionTitle>
          <Form.Item>
            <Input
              name="company_address"
              placeholder="e.g. Panepistimiou 61, Athina 105 64"
              defaultValue={form_data.company_address}
              onChange={(event) =>
                formFieldsHandler(event.target.name, event.target.value)
              }
              onBlur={() =>
                setTouched({ ...touched, ['company_address']: true })
              }
              size="large"
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
              }}
              disabled={disabled}
            />
            {errors && errors.company_address && touched['company_address'] && (
              <Text style={{ color: '#ff4d4f' }}>{errors.company_address}</Text>
            )}
          </Form.Item>
          <SubsectionTitle tooltip="Provide the country where your company is incorporated and registered">
            Country
          </SubsectionTitle>
          <Form.Item>
            <Select
              name="country"
              size="large"
              showSearch
              placeholder="Search to select country"
              defaultValue={form_data.country}
              onChange={(value) => formFieldsHandler('country', value)}
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disabled}
            >
              {country_options}
            </Select>
          </Form.Item>
          {otherRole.country && (
            <Form.Item>
              <Input
                name="other_country"
                placeholder="Please specify"
                defaultValue={form_data.other_country}
                onChange={(event) =>
                  formFieldsHandler(event.target.name, event.target.value)
                }
                onBlur={() =>
                  setTouched({ ...touched, ['other_country']: true })
                }
                size="large"
                style={{
                  borderRadius: '4px',
                  border: '1px solid #c4c4c4',
                }}
                disabled={disabled}
              />
              {errors && errors.other_country && touched['other_country'] && (
                <Text style={{ color: '#ff4d4f' }}>{errors.other_country}</Text>
              )}
            </Form.Item>
          )}
          <SubsectionTitle tooltip="Provide your company's VAT number">
            VAT
          </SubsectionTitle>
          <Form.Item>
            <Input
              name="vat"
              placeholder="Enter company's VAT number"
              defaultValue={form_data.vat}
              onChange={(event) =>
                formFieldsHandler(event.target.name, event.target.value)
              }
              onBlur={() => setTouched({ ...touched, ['vat']: true })}
              size="large"
              style={{
                borderRadius: '4px',
                border: '1px solid #c4c4c4',
              }}
              disabled={disabled}
            />
            {errors && errors.vat && touched['vat'] && (
              <Text style={{ color: '#ff4d4f' }}>{errors.vat}</Text>
            )}
          </Form.Item>
          <SubsectionTitle tooltip="Provide your company's activity industry sector">
            Industry (optional)
          </SubsectionTitle>
          <Form.Item>
            <Select
              name="industry"
              size="large"
              placeholder="Select industry"
              defaultValue={form_data.industry}
              onChange={(value) => formFieldsHandler('industry', value)}
              disabled={disabled}
            >
              {industry_options}
            </Select>
          </Form.Item>
          {otherRole.industry && (
            <Form.Item>
              <Input
                name="other_industry"
                placeholder="Please specify"
                defaultValue={form_data.other_industry}
                onChange={(event) =>
                  formFieldsHandler(event.target.name, event.target.value)
                }
                onBlur={() =>
                  setTouched({ ...touched, ['other_industry']: true })
                }
                size="large"
                style={{
                  borderRadius: '4px',
                  border: '1px solid #c4c4c4',
                }}
                disabled={disabled}
              />
            </Form.Item>
          )}
          <SubsectionTitle tooltip="Provide a short description of your company describing your main activity">
            Company description (optional)
          </SubsectionTitle>
          <Form.Item>
            <TextArea
              name="description"
              defaultValue={form_data.description}
              rows={4}
              onChange={(event) =>
                formFieldsHandler(event.target.name, event.target.value)
              }
              disabled={disabled}
            />
          </Form.Item>
        </Form>
        <SubmitButton onClick={handleSubmit} disabled={!hasErrors} size="large">
          Let’s do this!
        </SubmitButton>
      </Flexcol>
    </FormLayout>
  );
};

export default SetUpAccount;
